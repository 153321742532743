import { UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";
import { CreditForecastViewModel } from "../model/credit-forecast.viewmodel";
import Dictionary from "~/components/shared/dictionary/dictionary";
import { ICreditsMarketPrice } from "~/models/shared/credits-market/credits-market-price.model";
import { CurrencyPipe } from "@angular/common";
import { CreditForecastsFormComponent } from "./credit-forecasts-form.component";
import { TextboxFormatterComponent } from "./textbox-formatter.component";
import { NumberType } from "~/classes/shared/type-alias";

export class CreditForecastsForm {
	public form!: UntypedFormGroup;
	public formatters!: Dictionary<TextboxFormatterComponent>;

	constructor(private forecasts: CreditForecastViewModel[], private creditsMarketPrices: Dictionary<ICreditsMarketPrice>, private currencyPipe: CurrencyPipe) {
		this.formatters = new Dictionary<TextboxFormatterComponent>();
    this.initializeForm();
	}
  
  public static CreditsMarketId: string = "creditsMarket";

	private initializeForm(): void {
		const controls: any = {};

		controls[CreditForecastsForm.CreditsMarketId] = new UntypedFormControl();

		for (const element of this.forecasts) {
			const item = element;

      this.AddFormatter(CreditForecastsForm.getCreditsId(item.rowId));
			controls[CreditForecastsForm.getCreditsId(item.rowId)] = new UntypedFormControl(this.numberWithCommas(item.credits));

			const fundingAmount = new UntypedFormControl(this.numberWithCommas(item.fundingAmount, {maximumFractionDigits: 0}));
			fundingAmount.disable();
			controls[CreditForecastsForm.getFundingAmountId(item.rowId)] = fundingAmount;

			controls[CreditForecastsForm.getYearId(item.rowId)] = new UntypedFormControl(item.year, Validators.required);
			
      this.AddFormatter(CreditForecastsForm.getPriceId(item.rowId)); 
      controls[CreditForecastsForm.getPriceId(item.rowId)] = new UntypedFormControl(this.numberWithCommas(item.pricePerTonne));

      this.AddFormatter(CreditForecastsForm.getPrepaymentId(item.rowId));
			controls[CreditForecastsForm.getPrepaymentId(item.rowId)] = new UntypedFormControl(this.numberWithCommas(item.prepayment));

      this.AddFormatter(CreditForecastsForm.getOpExId(item.rowId));
			controls[CreditForecastsForm.getOpExId(item.rowId)] = new UntypedFormControl(this.numberWithCommas(item.opEx));

      this.AddFormatter(CreditForecastsForm.getPriceFloorId(item.rowId));
			controls[CreditForecastsForm.getPriceFloorId(item.rowId)] = new UntypedFormControl(this.numberWithCommas(item.priceFloor));
			
      this.AddFormatter(CreditForecastsForm.getDiscountRateId(item.rowId));
      controls[CreditForecastsForm.getDiscountRateId(item.rowId)] = new UntypedFormControl(this.numberWithCommas(item.discountRate));

      this.AddFormatter(CreditForecastsForm.getDiscretionarySpendId(item.rowId));
			controls[CreditForecastsForm.getDiscretionarySpendId(item.rowId)] = new UntypedFormControl(this.numberWithCommas(item.discretionarySpend));

      this.AddFormatter(CreditForecastsForm.getNonDiscretionarySpendId(item.rowId));
			controls[CreditForecastsForm.getNonDiscretionarySpendId(item.rowId)] = new UntypedFormControl(this.numberWithCommas(item.nonDiscretionarySpend));

      this.AddFormatter(CreditForecastsForm.getNonObligatedCreditsId(item.rowId));
			controls[CreditForecastsForm.getNonObligatedCreditsId(item.rowId)] = new UntypedFormControl(this.numberWithCommas(item.nonObligatedCredits));

			controls[CreditForecastsForm.getMarketPriceId(item.rowId)] = new UntypedFormControl(CreditForecastsForm.getMarketPrice(item.year, this.creditsMarketPrices, this.currencyPipe));
			controls[CreditForecastsForm.getMarketPriceId(item.rowId)].disable();
		}

		this.form = new UntypedFormGroup(controls);
	}

	public updateRow(item: CreditForecastViewModel) {
		const controls = this.form.controls;

		if (!this.isUndefinedOrNull(item.credits)) {
			controls[CreditForecastsForm.getCreditsId(item.rowId)].setValue(this.numberWithCommas(item.credits));
		}

		if (item.fundingAmount) {
			controls[CreditForecastsForm.getFundingAmountId(item.rowId)].setValue(this.numberWithCommas(item.fundingAmount));
		}

		if (!this.isUndefinedOrNull(item.pricePerTonne)) {
			controls[CreditForecastsForm.getPriceId(item.rowId)].setValue(this.numberWithCommas(item.pricePerTonne));
		}

		if (!this.isUndefinedOrNull(item.prepayment)) {
			controls[CreditForecastsForm.getPrepaymentId(item.rowId)].setValue(this.numberWithCommas(item.prepayment));
		}

		if (!this.isUndefinedOrNull(item.opEx)) {
			controls[CreditForecastsForm.getOpExId(item.rowId)].setValue(this.numberWithCommas(item.opEx));
		}

		if (!this.isUndefinedOrNull(item.priceFloor)) {
			controls[CreditForecastsForm.getPriceFloorId(item.rowId)].setValue(this.numberWithCommas(item.priceFloor));
		}

		if (!this.isUndefinedOrNull(item.discountRate)) {
			controls[CreditForecastsForm.getDiscountRateId(item.rowId)].setValue(this.numberWithCommas(item.discountRate));
		}

		if (!this.isUndefinedOrNull(item.discretionarySpend)) {
			controls[CreditForecastsForm.getDiscretionarySpendId(item.rowId)].setValue(this.numberWithCommas(item.discretionarySpend));
		}

		if (!this.isUndefinedOrNull(item.nonDiscretionarySpend)) {
			controls[CreditForecastsForm.getNonDiscretionarySpendId(item.rowId)].setValue(this.numberWithCommas(item.nonDiscretionarySpend));
		}

		if (!this.isUndefinedOrNull(item.nonObligatedCredits)) {
			controls[CreditForecastsForm.getNonObligatedCreditsId(item.rowId)].setValue(this.numberWithCommas(item.nonObligatedCredits));
		}
	}

  private AddFormatter(key: string): TextboxFormatterComponent {
		const sdk = new TextboxFormatterComponent();
		sdk.validCharacters = "currency";
		if (this.formatters.containsKey(key)) {
			this.formatters.removeItem(key);
		}
		this.formatters.add(key, sdk);
		return sdk;
	}

  private isUndefinedOrNull(value: NumberType): boolean {
    if (value !== undefined && value != null) {
      return false;
    }

    return true;
  }

	private numberWithCommas(x: number | null | undefined, options?: any): string | undefined {
		if (!x) {
			return undefined;
		}

		return x.toLocaleString("en-US", options);
	}

	public addRow(prices: Dictionary<ICreditsMarketPrice>, year?: number, source?: CreditForecastViewModel): CreditForecastViewModel[] {
		const latestYear = this.getLatestYear(year);

		const newRow = CreditForecastViewModel.getNewCreditForecastModel(this.forecasts, latestYear, year);
		this.forecasts = [...this.forecasts, newRow];

		const rowYear = !year ? newRow.year : year;
		this.form.addControl(CreditForecastsForm.getYearId(newRow.rowId), new UntypedFormControl(rowYear, Validators.required));
		
    this.AddFormatter(CreditForecastsForm.getPriceId(newRow.rowId)); 
    this.form.addControl(CreditForecastsForm.getPriceId(newRow.rowId), new UntypedFormControl(source ? this.numberWithCommas(source.pricePerTonne) : ''));

    this.AddFormatter(CreditForecastsForm.getCreditsId(newRow.rowId));
		this.form.addControl(CreditForecastsForm.getCreditsId(newRow.rowId), new UntypedFormControl(source ? this.numberWithCommas(source.credits) : ''));

		const fundingAmount = new UntypedFormControl(source ? this.numberWithCommas(source.fundingAmount) : '');
		fundingAmount.disable();
		this.form.addControl(CreditForecastsForm.getFundingAmountId(newRow.rowId), fundingAmount);

    this.AddFormatter(CreditForecastsForm.getPrepaymentId(newRow.rowId));
		this.form.addControl(CreditForecastsForm.getPrepaymentId(newRow.rowId), new UntypedFormControl(source ? this.numberWithCommas(source.prepayment) : ''));

    this.AddFormatter(CreditForecastsForm.getOpExId(newRow.rowId));
		this.form.addControl(CreditForecastsForm.getOpExId(newRow.rowId), new UntypedFormControl(source ? this.numberWithCommas(source.opEx) : ''));

    this.AddFormatter(CreditForecastsForm.getPriceFloorId(newRow.rowId));
		this.form.addControl(CreditForecastsForm.getPriceFloorId(newRow.rowId), new UntypedFormControl(source ? this.numberWithCommas(source.priceFloor) : ''));
		
    this.AddFormatter(CreditForecastsForm.getDiscountRateId(newRow.rowId));
    this.form.addControl(CreditForecastsForm.getDiscountRateId(newRow.rowId), new UntypedFormControl(source ? this.numberWithCommas(source.discountRate) : ''));

    this.AddFormatter(CreditForecastsForm.getDiscretionarySpendId(newRow.rowId));
		this.form.addControl(CreditForecastsForm.getDiscretionarySpendId(newRow.rowId), new UntypedFormControl(source ? this.numberWithCommas(source.discretionarySpend) : ''));

    this.AddFormatter(CreditForecastsForm.getNonDiscretionarySpendId(newRow.rowId));
		this.form.addControl(CreditForecastsForm.getNonDiscretionarySpendId(newRow.rowId), new UntypedFormControl(source ? this.numberWithCommas(source.nonDiscretionarySpend) : ''));

    this.AddFormatter(CreditForecastsForm.getNonObligatedCreditsId(newRow.rowId));
		this.form.addControl(CreditForecastsForm.getNonObligatedCreditsId(newRow.rowId), new UntypedFormControl(source ? this.numberWithCommas(source.nonObligatedCredits) : ''));

		const marketPriceControl = new UntypedFormControl(CreditForecastsForm.getMarketPrice(rowYear, prices, this.currencyPipe));
		this.form.addControl(CreditForecastsForm.getMarketPriceId(newRow.rowId), marketPriceControl);
		marketPriceControl.disable();

		return this.forecasts;
	}

	private getLatestYear(year?: number): number {
		let latestYear = new Date().getFullYear();
		if (!year) {
			if (this.forecasts.length > 0) {
				latestYear = Math.max(...this.forecasts.map(o => o.year)) + 1;
			}
		}

		return latestYear;
	}

	public deleteRow(rowId: string): CreditForecastViewModel[] {
		this.forecasts = this.forecasts.filter((u) => u.rowId !== rowId);

		this.form.removeControl(CreditForecastsForm.getYearId(rowId));
		this.form.removeControl(CreditForecastsForm.getPriceId(rowId));
		this.form.removeControl(CreditForecastsForm.getCreditsId(rowId));
		this.form.removeControl(CreditForecastsForm.getPrepaymentId(rowId));
		this.form.removeControl(CreditForecastsForm.getOpExId(rowId));
		this.form.removeControl(CreditForecastsForm.getPriceFloorId(rowId));
		this.form.removeControl(CreditForecastsForm.getDiscountRateId(rowId));
		this.form.removeControl(CreditForecastsForm.getDiscretionarySpendId(rowId));
		this.form.removeControl(CreditForecastsForm.getNonDiscretionarySpendId(rowId));
		this.form.removeControl(CreditForecastsForm.getNonObligatedCreditsId(rowId));
		this.form.removeControl(CreditForecastsForm.getMarketPriceId(rowId));

		return this.forecasts;
	}

	public static getMarketPrice(year: number | null | undefined, prices: Dictionary<ICreditsMarketPrice>, currencyPipe: CurrencyPipe): string | undefined | null {
		if (!prices || !year) {
			return;
		}
		const price = prices.getItem(CreditForecastsFormComponent.getYearId(year))?.pricePerTonne;
		return currencyPipe.transform(price, 'USD', 'symbol', '1.0-2');
	}

  public onKeyDown(event, key: string | null) {
    if (!key) {
      return;
    }
		const sdk = this.formatters.getItem(key);
		if (sdk) {
			sdk.onKeyDown(event);
		}
	}

	public onKeyUp(event, key: string | null) {
    if (!key) {
      return;
    }
		const sdk = this.formatters.getItem(key);

		if (sdk) {
			sdk.onKeyUp(event);
		}
	}

	public onBlur(event, key: string | null) {
    if (!key) {
      return;
    }
		const sdk = this.formatters.getItem(key);

		if (sdk) {
			sdk.onBlur(event);
		}
	}
  
	public static getYearId(rowId: string): string {
		return `${CreditForecastsForm.KEY_YEAR}-${rowId}`;
	}

	public static getPriceId(rowId: string): string {
		return `${CreditForecastsForm.KEY_PRICE}-${rowId}`;
	}

	public static getCreditsId(rowId: string): string {
		return `${CreditForecastsForm.KEY_CREDITS}-${rowId}`;
	}

	public static getFundingAmountId(rowId: string): string {
		return `${CreditForecastsForm.KEY_FUNDING_AMOUNT}-${rowId}`;
	}

	public static getPrepaymentId(rowId: string): string {
		return `${CreditForecastsForm.KEY_PREPAYMENT}-${rowId}`;
	}

	public static getOpExId(rowId: string): string {
		return `${CreditForecastsForm.KEY_OPEX}-${rowId}`;
	}

	public static getPriceFloorId(rowId: string): string {
		return `${CreditForecastsForm.KEY_PRICE_FLOOR}-${rowId}`;
	}

	public static getDiscountRateId(rowId: string): string {
		return `${CreditForecastsForm.KEY_DISCOUNT_RATE}-${rowId}`;
	}

	public static getDiscretionarySpendId(rowId: string): string {
		return `${CreditForecastsForm.KEY_DISCRETIONARY_SPEND}-${rowId}`;
	}

	public static getNonDiscretionarySpendId(rowId: string): string {
		return `${CreditForecastsForm.KEY_NON_DISCRETIONARY_SPEND}-${rowId}`;
	}

	public static getNonObligatedCreditsId(rowId: string): string {
		return `${CreditForecastsForm.KEY_NON_OBLIGATED_CREDITS}-${rowId}`;
	}

	public static getMarketPriceId(rowId: string): string {
		return `${CreditForecastsForm.KEY_MARKET_PRICE}-${rowId}`;
	}

  public static KEY_YEAR = "year";
  public static KEY_PRICE = "pricePerTonne";
  public static KEY_CREDITS = "credits";
  public static KEY_FUNDING_AMOUNT = "fundingAmount";
  public static KEY_MARKET_PRICE = "marketPrice";
  public static KEY_PREPAYMENT = "prepayment";
  public static KEY_OPEX = "opEx";
  public static KEY_PRICE_FLOOR = "priceFloor";
  public static KEY_DISCOUNT_RATE = "discountRate";
  public static KEY_DISCRETIONARY_SPEND = "discretionarySpend";
  public static KEY_NON_DISCRETIONARY_SPEND = "nonDiscretionarySpend";
  public static KEY_NON_OBLIGATED_CREDITS = "nonObligatedCredits";
  public static KEY_DELETE = "delete";

  public static TYPE_NUMBER = "number";
  public static TYPE_MARKET_PRICE = "market-price";
  public static TYPE_FUNDING_AMOUNT = "funding-amount"

  public static COLUMNS_SCHEMA = [
	  {
		  key: CreditForecastsForm.KEY_YEAR,
		  type: CreditForecastsForm.TYPE_NUMBER,
		  label: "Year",
		  hint: ""
	  },
	  {
		  key: CreditForecastsForm.KEY_CREDITS,
		  type: CreditForecastsForm.TYPE_NUMBER,
		  label: "Obligated Credits",
		  hint: ""
	  },
	  {
		  key: CreditForecastsForm.KEY_PRICE,
		  type: CreditForecastsForm.TYPE_NUMBER,
		  label: "Fixed Price ($/tonne)",
		  hint: ""
	  },
	  {
		  key: CreditForecastsForm.KEY_FUNDING_AMOUNT,
		  type: CreditForecastsForm.TYPE_FUNDING_AMOUNT,
		  label: "Funding Amount ($)",
		  hint: ""
	  },
	  {
		  key: CreditForecastsForm.KEY_MARKET_PRICE,
		  type: CreditForecastsForm.TYPE_MARKET_PRICE,
		  label: "Market Price ($/tonne)"
	  },
	  {
		  key: CreditForecastsForm.KEY_PREPAYMENT,
		  type: CreditForecastsForm.TYPE_NUMBER,
		  label: "Prepayment (Non-Capital) ($)",
		  hint: ""
	  },
	  {
		  key: CreditForecastsForm.KEY_OPEX,
		  type: CreditForecastsForm.TYPE_NUMBER,
		  label: "OpEx/MRV ($)",
		  hint: ""
	  },
	  {
		  key: CreditForecastsForm.KEY_PRICE_FLOOR,
		  type: CreditForecastsForm.TYPE_NUMBER,
		  label: "Price Floor ($/tonne)",
		  hint: ""
	  },
	  {
		  key: CreditForecastsForm.KEY_DISCOUNT_RATE,
		  type: CreditForecastsForm.TYPE_NUMBER,
		  label: "Discount Rate (%)",
		  hint: ""
	  },
	  {
		  key: CreditForecastsForm.KEY_DISCRETIONARY_SPEND,
		  type: CreditForecastsForm.TYPE_NUMBER,
		  label: "Discretionary Spend ($)",
		  hint: ""
	  },
	  {
		  key: CreditForecastsForm.KEY_NON_DISCRETIONARY_SPEND,
		  type: CreditForecastsForm.TYPE_NUMBER,
		  label: "Non-Discretionary Spend ($)",
		  hint: ""
	  },
	  {
		  key: CreditForecastsForm.KEY_NON_OBLIGATED_CREDITS,
		  type: CreditForecastsForm.TYPE_NUMBER,
		  label: "Non-Obligated Credits",
		  hint: ""
	  },
	  {
		  key: CreditForecastsForm.KEY_DELETE,
		  type: "deleteButton",
		  label: ""
	  }
  ]
}