<div class="options">
    <div class="label">Options</div>
    <div class="icons">
        <div *ngFor="let option of options" [title]="option.title" class="icon material-icons-outlined" (click)="setWindow(option)">{{ option.icon }}</div>
    </div>
</div>
<div *ngIf="showWindow" class="window">
    <div class="header">
        <span class="title">{{ optionTitle }}</span>
        <span title="Close" class="icon material-icons-outlined close" (click)="close()">close</span>
    </div>
    <div class="component">
        <ng-container [ngComponentOutlet]="optionsComponent.type" [ndcDynamicInputs]="optionsComponent.inputs" [ndcDynamicOutputs]="optionsComponent.outputs"></ng-container>
    </div>
</div>

