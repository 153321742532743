import { Component, Input } from "@angular/core";
import { ProjectScopeSummary } from "../models/project-scope-summary";
import { groupByProperty } from "~/classes/shared/group-by";
import { DeveloperProfile } from "~/services/shared/developers-screening/models/developer-summary";

@Component({
    selector: 'developer-info',
    templateUrl: './developer-info.component.html',
    styleUrls: ['./developer-info.component.scss']
})
export class DeveloperInfoComponent  {
    @Input() developer!: DeveloperProfile;
    public isLoading: boolean = true;
    public registries: string[] = [];
    public projectScopeSummaryByRegistry: ProjectScopeSummary[] = [];

    public async ngOnChanges() { 
        await this.loadData();
    }

    private async loadData(): Promise<void> {
        if (Object.keys(this.developer).length === 0) {
            return;
        }
        
        const projects = this.developer.projects;
        this.registries = Array.from(new Set<string>(projects.map(element => element.registry)));
        this.registries.sort(); // NOSONAR its an array of strings
        const projectsByScope = groupByProperty(projects, "scope");

        for (const scopedProjects of projectsByScope) {
            const scope = scopedProjects.key;
            const projects = scopedProjects.items;
            let registries = {};
            const scopedProjectsByRegistry = groupByProperty(projects, "registry");
            for (const registry of scopedProjectsByRegistry) {
                registries[registry.key] = registry.items.length;
            }
            this.projectScopeSummaryByRegistry.push(new ProjectScopeSummary(scope, registries));
        }
        this.projectScopeSummaryByRegistry.sort((a, b) => a.total < b.total ? 1 : -1);
        this.isLoading = false;
    }
   
    public sumRow(row: any): number {
        let sum = 0;
        this.registries.forEach(element => sum += (row.registries[element] ?? 0));

        return sum;
    }

    public sumTotal(): number {
        let sum = 0;
        for (const row of this.projectScopeSummaryByRegistry) {
            sum += this.sumRow(row);
        }

        return sum;
    }

    public sumRegistry(registry: string): number{
        let sum = 0;
        for (const row of this.projectScopeSummaryByRegistry) { 
            sum += (row._registries[registry] ?? 0);
        }

        return sum;
    }
}