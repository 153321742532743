import { Component, EmbeddedViewRef, EventEmitter, Inject, Input, OnInit, Output, TemplateRef, ViewChild, ViewContainerRef } from '@angular/core';
import { CreditInventoryViewModel } from '~/pages/projects/project-detail/credits/models/credits-inventory.model';
import { BehaviorSubject, Observable, take } from "rxjs";
import { SDKDataGridDataset, SDKDataGridOptions } from 'sdk-datagrid';

import { FormatterService } from '~/services/shared/formatter.service';
import { CreditInventory, CreditInventoryAccount, TransactionJournal } from '~/services/shared/credit-inventory/models/credit-inventory.model';
import { CreditTransferDto, CreditTransferViewModel, RetireCreditsDto } from '../credit-transfer-form/credit-transfer-form.model';
import { Observe } from '~/classes/shared/observe.decorator';
import { DOCUMENT, DatePipe } from '@angular/common';
import { CreditTransferFormComponent } from '../credit-transfer-form/credit-transfer-form.component';
import { UserPermissionsService } from '~/services/shared/user-permissions.service';

@Component({
	selector: 'ccms-credit-issuance-table',
	templateUrl: './credit-issuance-table.component.html',
	styleUrls: ['./credit-issuance-table.component.scss']
})
export class CreditIssuanceTableComponent implements OnInit {
	private _creditTransferFormRef!: any;

	@Input() data: CreditInventoryViewModel[] = [];
	@Observe('data') data$!: Observable<CreditInventoryViewModel[]>;

	@Input() isLoading = false;
	@Observe('isLoading') isLoading$!: Observable<boolean>;

	@Input() isEdit = false;
	@Observe('isEdit') isEdit$!: Observable<boolean>;

	@Output() transferCredits = new EventEmitter<CreditsTransferEvent>();
	@Output() retireCredits = new EventEmitter<CreditsTransferEvent>();
	@Output() rowChanged = new EventEmitter<CreditInventoryViewModel>();
	@Output() rowAdded = new EventEmitter<CreditInventoryViewModel>();
	@Output() undoRowAdded = new EventEmitter<CreditInventoryViewModel>();
	@Output() loadData = new EventEmitter();

	//#region SdkGrid

	@ViewChild('dateIssued') dateIssuedTemplate!: TemplateRef<any>;
	@ViewChild('serialNumber') serialNumberTemplate!: TemplateRef<any>;
	@ViewChild('creditsIssued') creditsIssuedTemplate!: TemplateRef<any>;
	@ViewChild('vintage') vintageTemplate!: TemplateRef<any>;
	@ViewChild('unitPrice') unitPriceTemplate!: TemplateRef<any>;
	@ViewChild('actionRight') actionRightTemplate!: TemplateRef<any>;

	@ViewChild('creditTransferForm') creditTransferForm!: TemplateRef<any>;

	private _transferCreditViewModel = new BehaviorSubject<CreditTransferViewModel | null>(null);
	transferCreditViewModel$ = this._transferCreditViewModel.asObservable();

  public canEdit: boolean = false;
  
	readonly columns: any[] = [
		{
			Name: 'dateIssued',
			DisplayName: 'Date Issued',
			formatter: (value: any) => this.formatterService.formatDate(value, "MM/dd/yyyy"),
			isVisible: true,
			allowEdit: true,
			dataTemplate: () => this.dateIssuedTemplate
		},
		{
			Name: 'serialNumber',
			DisplayName: 'Serial Number',
			isVisible: true,
			allowEdit: false,
			dataTemplate: () => this.serialNumberTemplate
		},
		{
			Name: 'creditsIssued',
			DisplayName: 'Credits Issued',
			formatter: (value: any) => this.formatterService.formatNumber(value),
			isVisible: true,
			dataTemplate: () => this.creditsIssuedTemplate
		},
		{
			Name: 'creditsAvailable',
			DisplayName: 'Credits Available',
			formatter: (value: any) => this.formatterService.formatNumber(value),
			isVisible: true,
			allowEdit: false
		},
		{
			Name: 'vintage',
			DisplayName: 'Vintage',
			isVisible: true,
			allowEdit: true,
			dataTemplate: () => this.vintageTemplate
		},
		{
			Name: 'unitPrice',
			DisplayName: 'Unit Price',
			formatter: (value: any) => this.formatterService.formatCurrency(value),
			isVisible: true,
			allowEdit: true,
			dataTemplate: () => this.unitPriceTemplate
		},
		{
			Name: 'Edit',
			DisplayName: ' ',
			actionSide: "right",
			actionTemplate: () => this.actionRightTemplate 
		}
	];

	public readonly gridOptions: SDKDataGridOptions = {
		columnSettings: false,
		filtering: false,
		sorting: false,
		formulas: false,
		charts: false,
		export: false,
		expandableRows: true,
		selectableRows: false,
		autoClosePanel: false
	};

	public readonly datasets: SDKDataGridDataset[] = [
		{
			Title: "Credit Issuance History",
			DbName: "projects/{id}/credit-inventories",
		}
	];

	//#endregion

	constructor(
		private formatterService: FormatterService,
		private viewContainerRef: ViewContainerRef,
    private readonly userPermissionService: UserPermissionsService,
		@Inject(DOCUMENT) private readonly document
	) {
		// Intentionall blank
	}

  async ngOnInit() {
    this.canEdit = await this.userPermissionService.canUpdateProjects();
  }

	public hasError(data: any, property: string): boolean {
		return data.errors && property in data.errors;
	}

	public addInventory() {
		const newEntry = {
			id: -1,
			serialNumber: this.generateDefaultSerialNumber(),
			dateIssued: new Date(),
			creditsIssued: 0,
			vintage: (new Date()).getFullYear(),
			unitPrice: null
		} as CreditInventoryViewModel;
		this.data = this.data.concat([newEntry]);
		this.rowAdded.emit(newEntry);
	}

	public undoAddInventory(data: any) {
		this.data = this.data.filter(item => item != data);
		this.undoRowAdded.emit(data);
	}

	private generateDefaultSerialNumber() {
		const datePipe = new DatePipe('en-US');
        const formattedDate = datePipe.transform(new Date(), 'yyyyMMddHHmmss');
		return `temp-${formattedDate}`;
	}

	public setDateIssued(context: CreditInventoryViewModel, newValue: any) {
		const date = new Date(newValue);
		const ms = Date.UTC(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate());
		context.dateIssued = new Date(ms);
		this.rowChanged.emit(context);
	}

	public setSerialNumber(context: CreditInventoryViewModel, newValue: any) {
		context.serialNumber = newValue;
		this.rowChanged.emit(context);
	}

	public setCreditsIssued(context: CreditInventoryViewModel, newValue: any) {
		context.creditsIssued = newValue;
		this.rowChanged.emit(context);
	}

	public setVintage(context: CreditInventoryViewModel, newValue: any) {
		context.vintage = newValue;
		this.rowChanged.emit(context);
	}

	public setUnitPrice(context: CreditInventoryViewModel, newValue: any) {
		if (newValue == "") {
			newValue = null;
		}
		context.unitPrice = newValue;
		this.rowChanged.emit(context);
	}

	public initializeCreditTransfer(
		creditInventory: CreditInventory,
		fromAccount: CreditInventoryAccount,
		transferType: 'Transfer' | 'Retire'
	) {
		this.resetCreditTransfer();
		const container = this.document.createElement('div');
		const detailTableEl = this.document
			.querySelector(`[title="${creditInventory.serialNumber}"] table tbody`);
		const accountIndex = creditInventory.accounts.indexOf(fromAccount);
		this._creditTransferFormRef = detailTableEl.insertRow(accountIndex + 1);
		const tableCell = this._creditTransferFormRef.insertCell();
		tableCell.setAttribute('colspan', 4);
		tableCell.appendChild(container);

		const form = this.viewContainerRef.createComponent(CreditTransferFormComponent);
		const formElement = (form.hostView as EmbeddedViewRef<any>).rootNodes[0] as HTMLElement;
		form.instance.viewModel = {
			inventoryId: creditInventory.id,
			fromAccount: fromAccount.name,
			maxAmount: fromAccount.balance,
			canEdit: true,
			transferType: transferType
		} as CreditTransferViewModel;
		form.instance.cancel.pipe(take(1)).subscribe(() => {
			this.onCancelEditMode();
		});
		form.instance.save.pipe(take(1)).subscribe((data) => {
			this.notifyCreditsTransfer(creditInventory, data, transferType);
		});
		container.appendChild(formElement);
	}

	private notifyCreditsTransfer(
		creditInventory: CreditInventory,
		data: CreditTransferDto | RetireCreditsDto,
		transferType: 'Transfer' | 'Retire'
	) {
		const callback = (tx: TransactionJournal) => {
			this.updateCreditInventory(creditInventory, tx, transferType);
			this.resetCreditTransfer();
			if (this.isLoading) {
				this.isLoading = false;
			}
		};
		let event = this.transferCredits;
		if (transferType === 'Retire') {
			event = this.retireCredits;
		}
		event.emit({ data, callback });
	}

	private updateCreditInventory(
		creditInventory: CreditInventory,
		tx: TransactionJournal,
		txType: 'Transfer' | 'Retire'
	) {
		const creditAccountName = tx.creditAccount;
		const creditAccount = creditInventory.accounts.find((account) => {
			return account.name.toUpperCase() === creditAccountName.toUpperCase();
		});

		if (creditAccount) {
			creditAccount.balance -= tx.amount;
		}

		if (txType === 'Retire') {
			creditInventory.creditsAvailable -= tx.amount;
		} else {
			const debitAccountName = tx.debitAccount;
			const debitAccount = creditInventory.accounts.find((account) => {
				return account.name.toUpperCase() === debitAccountName.toUpperCase();
			});
			if (debitAccount) {
				debitAccount.balance += tx.amount;
			}
			else {
				creditInventory.accounts.push({
					name: debitAccountName,
					description: 'New inventory account.',
					balance: tx.amount
				});
			}
		}
	}

	private resetCreditTransfer() {
		if (this._creditTransferFormRef) {
			this._creditTransferFormRef.remove();
			this._creditTransferFormRef = null;
		}
	}

	private onCancelEditMode() {
		this.resetCreditTransfer();
		this.data = this.data.filter(item => item.id > 0);
	}
}

export interface CreditsTransferEvent {
	data: CreditTransferDto | RetireCreditsDto,
	callback: (tx: TransactionJournal) => void
}