<div class="market-popup">
	<dialog-cancel-button (onCancel)="close()"></dialog-cancel-button>
	<div class="content-title" mat-dialog-title>{{ market?.title }}</div>
	<ccms-ets-status [status]="status"></ccms-ets-status>
	<mat-dialog-content>
		<div class="scroll-descr">
			<p class="content-intro">{{ market?.description }}</p>
		</div>
	</mat-dialog-content>
	<div class="save-button">
		<ccms-icon-button class="small arrow" (click)="viewDetails()" aria-label="View details">
			Details
		</ccms-icon-button>
	</div>
</div>