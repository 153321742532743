import { Component, ViewChild } from '@angular/core';
import { Subject, firstValueFrom, throttleTime } from 'rxjs';
import { TodoItemFlatNode, TreeChecklistComponent } from '~/components/shared/tree-checklist/tree-checklist.component';
import { BaseComponent } from '~/pages/base.component';
import { getMapFacets, updateExpansionNodes, updateMapFacets } from './state/policy-tracker-map.repository';
import { SelectionChange } from '@angular/cdk/collections';
import { PolicyTrackerMapService } from '~/services/shared/policy-tracker/policy-tracker-map.service';
import { PolicyTrackerQuery } from '~/models/shared/policy-tracker/policy-tracker-query.model';
import { PolicyTree } from '~/services/shared/policy-tracker/tree.constants';
import { CarbonMarketType, CarbonMarketTypeCredits, CarbonMarketTypeEts, CarbonMarketTypeTax } from '~/models/shared/policy-tracker/carbon-market-type';
import { CarbonMarketStatus, CarbonMarketStatusInforce, CarbonMarketStatusUnderConsideration, CarbonMarketStatusUnderDevelopment } from '~/models/shared/policy-tracker/carbon-market-status';
import { JurisdictionType, JurisdictionTypeNational, JurisdictionTypeRegional, JurisdictionTypeSubnational } from '~/models/shared/policy-tracker/jurisdiction-type';

@Component({
    selector: 'policy-tracker',
    templateUrl: './policy-tracker.component.html',
    styleUrls: ['./policy-tracker.component.scss']
})
export class PolicyTrackerComponent extends BaseComponent {
    @ViewChild('treeCheckList', { static: true }) treeCheckList : TreeChecklistComponent | undefined;
 
    public title: string = "Policy Tracker";
    public isLoading: boolean = true;

    private redrawSubject$ = new Subject<void>();
    
    constructor(private _policyTrackerMapService: PolicyTrackerMapService) {
        super();
    }

    public async ngOnInit() {
      await super.ngOnInit();

      const throttledRedrawCall$ = this.redrawSubject$.pipe(throttleTime(500));
      throttledRedrawCall$.subscribe(() => {
        this.redraw();
      });
  
      this.treeCheckList?.toggleExpanded.subscribe(
        {
          next: v => {
            this.treeListToggleExpanded(v);
          }
        }
      )
      
      const state = await firstValueFrom(getMapFacets());   
      if (state.nodeExpansion?.length > 0) {
        state.nodeExpansion.forEach(s => {
          this.treeCheckList?.toggleExpandNode(s);
        })
      } else {
        this.treeCheckList?.getActualNodes().forEach(node => {
          node.isExpanded = true;
          this.treeCheckList?.toggleExpandNode(node);
          this.treeListToggleExpanded(node);
        });
      }
  
      this.treeCheckList?.checklistSelection.changed.subscribe(
        {
          next: v => {
            this.treeListChanged(v);
          }
        }
      );
  
      const facets = await firstValueFrom(getMapFacets());
      if (facets.selectedFacets.length == 0) {
        facets.defaultFacets.forEach(x => {
          this.treeCheckList?.branchSelectionToggleExternal(x.item);
        });
      } else {
        facets.selectedFacets.forEach(x => {
          this.treeCheckList?.branchSelectionToggleExternal(x.item);
        });
      }

      this.treeCheckList?.initBranches();
    }

  private async treeListToggleExpanded(node: TodoItemFlatNode) {
    const state = await firstValueFrom(getMapFacets());

    let found: TodoItemFlatNode | null = null;
    state.nodeExpansion.forEach(element => {
      if (element.item === node.item) {
        found = element;
        found.isExpanded = node.isExpanded;
      } 
    });

    if (!found) {
      state.nodeExpansion.push(node);
    }

    updateExpansionNodes(state.nodeExpansion);
  }

  private async treeListChanged(event: SelectionChange<TodoItemFlatNode>) {
    const state = await firstValueFrom(getMapFacets());

    event.added.forEach(element => {
      const found = state.selectedFacets.find(x => x.item === element.item);
      if (!found) {
        state.selectedFacets.push(element);
      }
    });
    
    event.removed.forEach(element => {
      const found = state.selectedFacets.find(x => x.item === element.item);
      if (found) {
        const index = state.selectedFacets.indexOf(found, 0);
        if (index > -1) {
          state.selectedFacets.splice(index, 1);
        }
      }
    });

    updateMapFacets(state.selectedFacets);

    this.redrawSubject$.next();
  }

  private redraw() {
    this._policyTrackerMapService.requestRedraw(this.getQuery());
  }

  private getQuery(): PolicyTrackerQuery {
    const query = new PolicyTrackerQuery();
    this.treeCheckList?.checklistSelection.selected.forEach(s => {
      const marketType = this.getMarketTypeByName(s.item);
      if (marketType) {
        query.marketTypes.push(marketType);
      }
      const marketStatus = this.getMarketStatusByName(s.item);
      if (marketStatus) {
        query.marketStatuses.push(marketStatus);
      }
      const jurisdictionType = this.getJurisdictionTypeByName(s.item);
      if (jurisdictionType) {
        query.jurisdictionType.push(jurisdictionType);
      }
      if (s.item === PolicyTree.ROOT_ARTICLE6) {
        query.article6 = true;
      }
    })

    return query;
  }

  public getMarketTypeByName(name: string): CarbonMarketType | null {
    switch (name) {
      case PolicyTree.LEAF_MARKET_ETS:
        return CarbonMarketTypeEts.getInstance();
      case PolicyTree.LEAF_MARKET_TAX:
        return CarbonMarketTypeTax.getInstance();
      case PolicyTree.LEAF_MARKET_CREDITS:
        return CarbonMarketTypeCredits.getInstance();
    }

    return null;
  }

  public getMarketStatusByName(name: string): CarbonMarketStatus | null {
    switch (name) {
      case PolicyTree.LEAF_STATUS_INFORCE:
        return CarbonMarketStatusInforce.getInstance();
      case PolicyTree.LEAF_STATUS_UNDER_DEVELOPMENT:
        return CarbonMarketStatusUnderDevelopment.getInstance();
      case PolicyTree.LEAF_STATUS_UNDER_CONSIDERATION:
        return CarbonMarketStatusUnderConsideration.getInstance();
    }

    return null;
  }

  public getJurisdictionTypeByName(name: string): JurisdictionType | null {
    switch (name) {
      case PolicyTree.LEAF_JURISDICTION_NATIONAL:
        return JurisdictionTypeNational.getInstance();
      case PolicyTree.LEAF_JURISDICTION_SUBNATIONAL:
        return JurisdictionTypeSubnational.getInstance();
      case PolicyTree.LEAF_JURISDICTION_REGIONAL:
        return JurisdictionTypeRegional.getInstance();
    }

    return null;
  }
}
