import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnChanges, SimpleChanges } from "@angular/core";
import { CountryCpi } from "~/models/shared/policy-tracker/country-cpi.model";
import { CountryService } from "~/services/shared/country.service";
import { TransparencyCpiService } from "~/services/shared/policy-tracker/transparency-cpi.service";


@Component({
  selector: 'ccms-transparency-cpi',
  templateUrl: './transparency-cpi.component.html',
  styleUrls: ['./transparency-cpi.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TransparencyCpiComponent implements OnChanges {
  @Input()
  countryId!: number;

  constructor(
    private cd: ChangeDetectorRef,
    private _countryService: CountryService,
    private _transparencyService: TransparencyCpiService) {}
  
  public cpi!: CountryCpi;
    
  ngOnChanges(changes: SimpleChanges): void {
    this.loadView();
  }

  private async loadView() {
    if (!this.countryId) {
      return;
    }

    const country = await this._countryService.getCountryById(this.countryId);
    if (country) {
      const cpis = await this._transparencyService.getCountryCpi(country.alpha3Code);
      this.cpi = cpis[0];

      this.cd.detectChanges();
    }
  }
}