<div class="header">Corruption Perception Index</div>
<div class="cpi">
	<div>
		<div class="metric-title">{{cpi?.year}} Rank</div>
		<div *ngIf="cpi" class="metric">
			<span class="bold-value">{{cpi?.rank}}</span>/180
		</div>
		<div *ngIf="!cpi" class="metric">
			<span>NA</span>
		</div>
	</div>
	<div>
		<div class="metric-title">{{cpi?.year}} Score</div>
		<div *ngIf="cpi" class="metric">
			<span class="bold-value">{{cpi?.score}}</span>/100
		</div>
		<div *ngIf="!cpi" class="metric">
			<span>NA</span>
		</div>
	</div>
</div>