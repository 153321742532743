<mat-form-field style="width:250px;" appearance="outline">
    <mat-label>{{ label }}<span *ngIf="required" class="text-danger">*</span></mat-label>
    <input type="text" matInput class="no-brand"
        placeholder="Enter account name..."
        aria-label="metaregistry project ID"
        (input)="keyword$.next($event.target.value)"
        (focus)="$event.target.select()"
        [value]="value"
        [matAutocomplete]="auto">
    <mat-autocomplete #auto="matAutocomplete" (optionSelected)="writeValue($event.option.value)">
        <mat-option *ngIf="showHint" [value]="" disabled>
            <mat-spinner *ngIf="isLoading">Please wait...</mat-spinner>
            <span *ngIf="!isLoading">
                Enter keyword to filter list...
            </span>
        </mat-option>
        <mat-option *ngFor="let option of options$ | async" [value]="option" [title]="option">
            {{ option }}
        </mat-option>
    </mat-autocomplete>
</mat-form-field>