import { Component, ElementRef, Input, OnChanges, ViewChild } from '@angular/core';

@Component({
	selector: 'sdk-google-map',
	templateUrl: './sdk-google-map.component.html'
})

export class SDKGoogleMapComponent implements OnChanges {
	@Input() height: string = "";
	@Input() width: string = "";
	@Input() latitude: string = "";
	@Input() longitude: string = "";
	@Input() location: string = "";
	@Input() zoom: string = "4";
	@Input() isLoading: string = "false";

	@ViewChild("map") map: ElementRef | undefined;

	public _isLoading: boolean = false;
	public _height: string = "";
	public _width: string = "";

	public ngOnChanges(changes: any) {
		if (changes.latitude || changes.longitude || changes.location || changes.zoom) {
			if (changes.latitude !== "" || changes.longitude !== "" || changes.location !== "") {
				this.loadMap();
			}
		}
	}

	private loadMap() {
		if (this.isLoading === "true") this._isLoading = true;

		if (this.height !== "" && this.width === "") {
			this._height = this.height;
			this._width = this.height;
		}
		else if (this.height === "" && this.width !== "") {
			this._height = this.width;
			this._width = this.width;
		}
		else if (this.height !== "" && this.width !== "") {
			this._height = this.height;
			this._width = this.width;
		} else {
			this._height = "300px";
			this._width = "300px";
		}

		let src: string = "";

		if (this.latitude && this.latitude !== "" && this.longitude && this.longitude !== "") {
			src = `<iframe width="100%" height="100%" frameborder="0" scrolling="no" marginheight="0" marginwidth="0" src="https://maps.google.com/maps?width=100%25&amp;height=100%25&amp;hl=en&amp;q=${this.latitude},${this.longitude}&amp;t=&amp;z=${this.zoom}&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"></iframe>`;
		} else if (this.location && this.location !== "") {
			src = `<iframe width="100%" height="100%" frameborder="0" scrolling="no" marginheight="0" marginwidth="0" src="https://maps.google.com/maps?width=100%25&amp;height=100%25&amp;hl=en&amp;q=${encodeURI(this.location)}&amp;t=&amp;z=${this.zoom}&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"></iframe>`;
		} else {
			src = `<div style="display: flex; width: 100%; height: 100%; align-items: center;"><div style="width: 100%; text-align: center;">Insufficient data.</div></div>`;
		}

		setTimeout(() => {
			this.map!.nativeElement.innerHTML = src ?? "";
		}, 100);

		setTimeout(() => {
			this._isLoading = false;
		}, 500);
	}
}
