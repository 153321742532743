import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";

export class CreditTransferFormGroup extends UntypedFormGroup {
    readonly canEdit: boolean;
    readonly maxAmount: number;
    readonly fromAccount = this.get('fromAccount') as UntypedFormControl;
    readonly toAccount = this.get('toAccount') as UntypedFormControl;
    readonly amount = this.get('amount') as UntypedFormControl;
    readonly transferType: 'Transfer' | 'Retire'

    constructor(
        readonly initial: CreditTransferViewModel = {} as CreditTransferViewModel,
        readonly fb = new UntypedFormBuilder()
    ) {
        super(fb.group({
            fromAccount: [initial?.fromAccount, [Validators.required]],
            toAccount: [initial?.toAccount],
            amount: [initial?.amount, [Validators.required, Validators.min(1)]]
        }).controls);
        this.fromAccount.disable();
        this.canEdit = initial.canEdit;
        this.transferType = initial.transferType;
        
        if (this.transferType == 'Transfer') {
            this.toAccount.addValidators(Validators.required);
        }
        if (!this.canEdit) {
            this.amount.disable();
        }

        this.maxAmount = initial.maxAmount ?? -1;
        if (this.maxAmount > 0) {
            this.amount.addValidators(Validators.max(this.maxAmount));
        }
    }
}

export interface CreditTransferViewModel {
    inventoryId: number;
    fromAccount: string;
    toAccount: string;
    amount: number;
    canEdit: boolean;
    maxAmount: number;
    transferType: 'Transfer' | 'Retire'
}

export interface CreditTransferDto {
    inventoryId: number;
    fromAccount: string;
    toAccount: string;
    amount: number;
}

export interface RetireCreditsDto {
    inventoryId: number;
    account: string;
    amount: number;
}