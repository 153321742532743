import { Component, ElementRef, Input, OnChanges, OnDestroy, ViewChild } from '@angular/core';

import Chart, { BarElement, ChartDataset } from 'chart.js/auto';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { Dictionary, groupBy } from 'lodash'; 
import zoomPlugin from 'chartjs-plugin-zoom';
import { IIssuance } from '~/models/shared/common';
import { MetaRegistry } from '~/models/shared/metaregistry';

@Component({
    selector: 'metaregistry-detail-credits-graph',
    templateUrl: './metaregistry-detail-credits-graph.component.html',
    styleUrls: ['./metaregistry-detail-credits-graph.component.scss']
})

export class MetaRegistryDetailCreditsGraphComponent implements OnChanges, OnDestroy {
    @Input() data: any;
    @Input() project: any;
    @ViewChild('metaregistryDetailChart') chartCanvas: ElementRef<HTMLElement> | undefined;
    private _chart: Chart | undefined;
    private fontSize: number = 10;
    private defaultBarWidth: number = 124.0;

    constructor() {
        this.fontSize = 10;
        this.defaultBarWidth = 62.0;
        Chart.register(ChartDataLabels);
        Chart.register(zoomPlugin);
    }

    //******************************************************************************
    //  Page Life-cycle Methods
    //******************************************************************************
    public ngOnChanges(args: any) {
        if (args.data?.currentValue) {
            this._chart = this.localCreateChart(this.chartCanvas!);
            
            this.renderChart(this._chart, args.data.currentValue);
        }
    }

    ngOnDestroy(): void {
      if (this._chart) {
        this._chart.destroy();
      }
    }

    //******************************************************************************
    //  Private Methods
    //******************************************************************************
    private renderChart(chart: Chart, data: IIssuance[]) {
        if (!chart) {
            return;
        }
        let years: number[] = Array.from(new Set([
            ...data
                .filter((element: any) => !isNaN(Date.parse(element?.issuanceDate)))
                .map((element: any) => new Date(element.issuanceDate).getFullYear()),
            ...data
                .filter((element: any) => !isNaN(Date.parse(element?.retirementDate)))
                .map((element: any) => new Date(element.retirementDate).getFullYear())]));
        years.sort((a, b) => a - b);
        chart.data.labels = years;
        const rollup = groupBy(data, 'action');
        let retiredData: any[] = [];
        let issuedData: any[] = [];
        let assignedData: any[] = [];
        let cancelledData: any[] = [];
        years.forEach(year => { 

            let retiredAnnualSum = 0;
            let cancelledAnnualSum = 0;
            let assignedAnnualSum = 0;

            if (rollup['RETIRED'] != undefined) {
                retiredAnnualSum = rollup['RETIRED']
                    .filter((element: any) => !isNaN(Date.parse(element?.retirementDate)))
                    .filter((element: any) => new Date(element.retirementDate).getFullYear() == year)
                    .reduce((acc, item: any) => acc + item.quantity, 0);
                retiredData.push({ x: year, y: retiredAnnualSum });
            }
          
            if (rollup['ASSIGNED']) {
                assignedAnnualSum = rollup['ASSIGNED']
                    .filter((element: any) => !isNaN(Date.parse(element?.retirementDate)))
                    .filter((element: any) => new Date(element.retirementDate).getFullYear() == year)
                    .reduce((acc, item: any) => acc + item.quantity, 0);
                assignedData.push({ x: year, y: assignedAnnualSum });
            }
            if (rollup['CANCELLED']) {
                cancelledAnnualSum = rollup['CANCELLED']
                    .filter((element: any) => !isNaN(Date.parse(element?.retirementDate)))
                    .filter((element: any) => new Date(element.retirementDate).getFullYear() == year)
                    .reduce((acc, item: any) => acc + item.quantity, 0);
                cancelledData.push({ x: year, y: cancelledAnnualSum });
            }

            if (rollup['ISSUED'] != undefined) {
                let issuedAnnualSum = this.calculateIssuance(rollup, year, this.project, retiredAnnualSum, cancelledAnnualSum, assignedAnnualSum);
                issuedData.push({ x: year, y: issuedAnnualSum });
            }
        });
        retiredData.sort((a, b) => a.x - b.x);
        issuedData.sort((a, b) => a.x - b.x);
        assignedData.sort((a, b) => a.x - b.x);
        cancelledData.sort((a, b) => a.x - b.x);

        let retired: ChartDataset = {
            label: 'Retired',
            data: retiredData,
            backgroundColor: '#0066B2',
            order: 1,
            yAxisID: "y",
            datalabels: {
                labels: {
                    value: {
                        color: 'white',
                        align: 'center',
                        anchor: 'center',
                        formatter: function (value, context) {
                            return value.y > 0 ? value.y.toLocaleString() : null;
                        }
                    }
                }
            }
        };
        let issuance: ChartDataset = {
            label: 'Issuance',
            data: issuedData,
            backgroundColor: '#BA3093',
            order: 2,
            yAxisID: "y",
            datalabels: {
                labels: {
                    value: {
                        color: 'white',
                        align: 'center',
                        anchor: 'center',
                        formatter: function (value, context) {
                            return value.y > 0 ? value.y.toLocaleString() : null;
                        }
                    }
                }
            }
        };
        let assigned: ChartDataset = {
            label: 'Assigned',
            data: assignedData,
            backgroundColor: 'grey',
            order: 3,
            yAxisID: "y",
            datalabels: {
                labels: {
                    value: {
                        color: 'white',
                        align: 'center',
                        anchor: 'center',
                        formatter: function (value, context) {
                            return value.y > 0 ? value.y.toLocaleString() : null;
                        }
                    }
                }
            }
        };
        let cancelled: ChartDataset = {
            label: 'Cancelled',
            data: cancelledData,
            backgroundColor: '#009DD9',
            order: 4,
            yAxisID: "y",
            datalabels: {
                labels: {
                    value: {
                        color: 'white',
                        align: 'center',
                        anchor: 'center',
                        formatter: function (value, context) {
                            return value.y > 0 ? value.y.toLocaleString() : null;
                        }
                    }
                }
            }
        };
      
        chart.data.datasets.push(retired);
        chart.data.datasets.push(issuance);
        chart.data.datasets.push(assigned);
        chart.data.datasets.push(cancelled);
        chart.update();
    }

    private localCreateChart(chartCanvas: ElementRef<HTMLElement>): Chart {

        let chart = this.createChart("Credits", "", "Credits",
            chartCanvas.nativeElement.id, false, false);
      
        return chart;
    }

    private createChart(yAxisTitle: string, xAxisTitle: string, chartTitle: string, canvasId: string, stackx: boolean = true, stacky: boolean = true): Chart {
        return new Chart(canvasId, {
            type: 'bar',
            options: {
                responsive: true,
                scales: {
                    y: {
                        ticks: {
                            callback: function (value, _index, _values) {
                                return value.toLocaleString('en-US');
                            }
                        },
                        bounds: 'ticks',
                        stacked: stacky
                    },
                    x: {
                        bounds: 'data',
                        stacked: stacky,
                        title: {
                            text: xAxisTitle,
                            display: true,
                            font: {
                                weight: 'bold',
                                family: "GothamNarrowBold, Arial, sans- serif",
                                size: 14
                            },
                            color: '#212529'
                        }
                    }
                },
                interaction: {
                    mode: 'point'
                },
                plugins: {
                    legend: {
                        display: true,
                        position: 'bottom'
                    },
                    title: {
                        display: true,
                        text: chartTitle,
                        font: {
                            weight: 'bold',
                            family: "GothamNarrowBold, Arial, sans- serif",
                            size: 14
                        },
                        color: '#212529'
                    },
                    tooltip: {
                        callbacks: {
                            label: (context): string => {
                                return context.formattedValue;
                            },
                            title: (context): string => {
                                return context[0].dataset.label!;
                            }
                        }
                    },
                    datalabels: {
                     
                        font:  (context) => {
                            const chart = context.chart;
                            const meta = chart.getDatasetMeta(context.datasetIndex);
                            const model: BarElement = meta.data[context.dataIndex] as BarElement;
                            
                            // passing true you can get the final value of element property
                            // at the end of animation
                            const { width } = model.getProps(['width'], true);
                            const size = Math.round(this.fontSize * (width / this.defaultBarWidth));
                           
                            
                            return {
                                size: size,
                                family: "Arial, sans- serif"
                            }
                        }
                    
                    },
                    zoom: {
                        pan: {
                            enabled: true,
                            mode: 'x',
                            onPan: ({ chart }) => {
                            },
                        },
                        zoom: {
                            wheel: {
                                enabled: true,
                            },
                            pinch: {
                                enabled: false
                            },
                            mode: 'x',
                            onZoomComplete: ({ chart }) => {
                                console.log(chart.chartArea);
                             }
                        },
                        limits: {
                            x: {min: 'original', max: 'original'}
                        },
                    }
                },
            },
            data: {
                datasets: []
            }
        });
    }

    private calculateIssuance(rollup: Dictionary<IIssuance[]>, currentYear: number, project: MetaRegistry, retired: number,
        cancelled: number, assigned: number) {
        
        let issued = (rollup['ISSUED'] ?? [])
            .filter((element: any) => !isNaN(Date.parse(element?.issuanceDate)))
            .filter((element: any) => new Date(element.issuanceDate).getFullYear() == currentYear)
            .reduce((acc, item: any) => acc + item.quantity, 0);

        if (project.registry.toUpperCase() != 'VCS' && project.registry.toUpperCase() != 'ACORN') {
            return issued;
        }
        else {
            return (issued + retired + assigned + cancelled);
        }
    }


}




