import { Injectable } from '@angular/core';

import { RequestParameters } from '~/models/shared/request-parameters';
import { FilterInfo } from '~/models/shared/filterInfo';
import { HttpService } from '../../core/http.service';
import { SupplyTradingInventorySummaryDto } from './models/supplytrading-inventory-summary-dto';

@Injectable()
export class SupplyTradingInventoryService {

    constructor(
        private httpService: HttpService,
    ) { }

    public async getInventory(parameters: RequestParameters): Promise<any> {
        try {
            return await this.httpService.Post("/RightAngleInventory/Search", parameters).then((data: any) => {
                return data;
            });
        } catch (error: any) {
            throw error;
        }
    }

    public async getTotals(): Promise<any> {
        try {
            return await this.httpService.Get("/RightAngleInventory/Amounts").then((data: any) => {
                return data;
            });
        } catch (error: any) {
            throw error;
        }
    }

    public getSummary(): Promise<SupplyTradingInventorySummaryDto> {
        let requestUri = '/RightAngleInventory/summary';
        return this.httpService.Get(requestUri);
    }

    public async getFilters(fieldNames: string[]): Promise<FilterInfo[]> {
        try {
            let queryString = "";
            fieldNames.forEach((fieldName) => {
                queryString = queryString === "" ? `fields=${fieldName}` : `${queryString}&fields=${fieldName}`;
            });
            const response = await this.httpService.Get(`/RightAngleInventory/filters?${queryString}`);
            return response;
        } catch (error: any) {
            throw error;
        }
    }
}