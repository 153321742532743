import { Injectable } from '@angular/core';

import { HttpService } from '../core/http.service';
import { Observable, firstValueFrom, from, lastValueFrom, map, shareReplay } from 'rxjs';
import { ICountry } from '~/models/shared/location';


@Injectable()
export class CountryService {

    constructor(
        private httpService: HttpService,
    ) { }

    private countriesCache$: Observable<ICountry[]> | undefined;
    
    public async getCountries(): Promise<ICountry[]> {
        return await firstValueFrom(this.getCountriesAll());
    }

    private requestListCountries(): Observable<ICountry[]> {
      return from(this.httpService.Get("/countries"));
    }

    private getCountriesAll(): Observable<ICountry[]> {  
      if (!this.countriesCache$) {
        this.countriesCache$ = this.requestListCountries().pipe(
          shareReplay(1)
        );
      }
    
      return this.countriesCache$;
    }

    public async getCountryMap():  Promise<Map<number, ICountry>> {
      const countries = await this.getCountries();

      const countryMap = new Map<number, ICountry>();
    
      countries.forEach((country: ICountry) => {
        countryMap.set(country.id, country);
      });
    
      return countryMap;
    }

    public async getCountryById(id: number): Promise<ICountry | undefined> {
      const countries = await firstValueFrom(this.getCountriesAll().pipe(map(x => x)));

      return countries.find(country => country.id === id);
    }

    public async getCountryByAlpha3Code(alpha3Code: string): Promise<ICountry | undefined> {
      const countries = await lastValueFrom(this.getCountriesAll().pipe(map(x => x)));

      return countries.find(country => country.alpha3Code === alpha3Code);
    }
}