import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { ICvxProject } from "~/models/shared/cvxproject";
import { ProjectService } from "~/services/shared/projects/project.service";
import { SustainabilityService } from "~/services/shared/sustainability.service";


@Component({
    selector: 'project-add-notes',
    templateUrl: './project-add-notes.component.html',
    styleUrls: ['./project-add-notes.component.scss']
})

export class ProjectAddNotes implements OnInit, AfterViewInit {
    public isLoading: boolean = true;
    public projectStatusList: any = [];
    public projectDNPStatusList: any = [];
    public sustainabilityList: any = [];

    @Input() project: any;
    @Output() changeEvent: EventEmitter<ICvxProject> = new EventEmitter();
    @Output() saveDataEvent: EventEmitter<boolean> = new EventEmitter();

    constructor(private projectService: ProjectService,
        private sustainabilityService: SustainabilityService) {

    }

    async ngOnInit(): Promise<void> {
        await this.loadData();
    }
    private async loadData(): Promise<void> {
        try {
            await this.projectService.getStatuses().then((data: any) => {
                this.projectStatusList = data;
            });
            await this.projectService.getDNPStatuses().then((data: any) => {
                this.projectDNPStatusList = data;
            });
            await this.sustainabilityService.getTypes().then((data: any) => {
                this.sustainabilityList = data;
                this.sustainabilityList.forEach((goal: any) => {
                    goal.selected = false;
                })
            });

        }
        catch (error: any) {
            console.error(error);
        }
        finally {
            this.isLoading = false;
        }
    }
    ngAfterViewInit(): void {
        console.warn("nothing implemented");
    }

    public infoChange(event: any) {
        if (event) {
          this.project[event.field] = this.stringToNumber(event.value);
        }
    }

    public setNotes(field: any, event: any) {
        if (field) {
            this.project[field] = event.target.value;
        }
    }

    private stringToNumber(input: string): number {
      const withoutCommas = input.replace(/,/g, '');
      return parseFloat(withoutCommas);
    }

}

