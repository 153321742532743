<div class="sdk-datagrid-window">
    <div class="header">
        <span class="title">Export</span>
        <span title="Close" class="sdk-icon close" (click)="close()">close</span>
    </div>
    <div class="content no-actions">
        <div class="no-box">
            <div class="section">
                <div style="display: inline-block;">
                    <input class="data-option" type="checkbox" [checked]="includeColumns" (change)="includeColumns = !includeColumns">
                    <span class="data-label" (click)="includeColumns = !includeColumns">Include Column changes</span>
                </div>
            </div>
            <div class="section">
                <div style="display: inline-block;">
                    <input class="data-option" type="checkbox" [checked]="includeFilters" (change)="includeFilters = !includeFilters">
                    <span class="data-label" (click)="includeFilters = !includeFilters">Include Filters</span>
                </div>
            </div>
            <div class="section">
                <div style="display: inline-block;">
                    <input class="data-option" type="checkbox" [checked]="includeSorting" (change)="includeSorting = !includeSorting">
                    <span class="data-label" (click)="includeSorting = !includeSorting">Include Sorting</span>
                </div>
            </div>
        </div>
    </div>
    <div class="footer">
        <button class="button" type="button" (click)="apply()">Export</button>
    </div>
</div>
