import { Component, EventEmitter, Input, Output, AfterViewInit } from '@angular/core';
import { Observable } from "rxjs";
import { Observe } from '~/classes/shared/observe.decorator';
import { 
	CreditTransferDto, 
	CreditTransferFormGroup, 
	CreditTransferViewModel, 
	RetireCreditsDto
} from '~/pages/projects/project-detail/credits/credit-transfer-form/credit-transfer-form.model';

@Component({
	selector: 'ccms-credit-transfer-form',
	templateUrl: './credit-transfer-form.component.html',
	styleUrls: ['./credit-transfer-form.component.scss']
})
export class CreditTransferFormComponent implements AfterViewInit {
	@Input() viewModel!: CreditTransferViewModel;
	@Observe('viewModel') viewModel$!: Observable<CreditTransferViewModel>;

	@Output() save = new EventEmitter<CreditTransferDto | RetireCreditsDto>();
	@Output() cancel = new EventEmitter();

	form = new CreditTransferFormGroup();

	ngAfterViewInit(): void {
		this.viewModel$.subscribe({
			next: (value) => {
				this.form = new CreditTransferFormGroup(value ?? undefined);
			}
		});
	}

	onCancel() {
		this.cancel.emit();
	}

	onSave() {
		if (this.viewModel.transferType == 'Transfer') {
			this.transferCredits();
		}
		else if (this.viewModel.transferType == 'Retire') {
			this.retireCredits();
		}
		this.save.emit();
	}
	
	private transferCredits() {
		const context = this.form;
		const data = {
			inventoryId: context.initial.inventoryId,
			fromAccount: context.fromAccount.value,
			toAccount: context.toAccount.value,
			amount: context.amount.value
		} as CreditTransferDto;
		this.save.emit(data);
	}
	
	private retireCredits() {
		const context = this.form;
		const data = {
			inventoryId: context.initial.inventoryId,
			account: context.initial.fromAccount,
			amount: context.amount.value
		} as RetireCreditsDto;
		this.save.emit(data);
	}
}
