export class SDKDataGridSettingsData {
    showNote?: boolean = false
    columnBadge?: string = "";
    filterBadge?: string = "";
    sortBadge?: string = "";
    formulaBadge?: string = "";
    chartBadge?: string = "";
    columns?: any = [];
    filters?: any = [];
    sorts?: any = [];
    formulas?: any = [];
    chart?: any = "";
}

export class SDKDataGridSettings {
    Name: string = "";
    Data: SDKDataGridSettingsData = {};
}
