<div class="sdk-datagrid-window">
    <div class="header">
        <span class="title">Columns</span>
        <span title="Close" class="sdk-icon close" (click)="close()">close</span>
    </div>
    <div class="actions">
        <div class="all-select">
            <input type="checkbox" class="select" [checked]="allVisible" (change)="toggleAllColumns($event)" />
            <span>All</span>
        </div>
        <button class="button button-right" type="button" (click)="groupVisibleColumns()">Group Visible</button>
    </div>
    <div cdkDropList class="content" (cdkDropListDropped)="drop($event)">
        <div class="box" *ngFor="let column of columnList" cdkDragBoundary=".list" cdkDrag>
            <div class="select">
                <input type="checkbox" [checked]="column.isVisible" (change)="toggleColumn($event, column)" />
            </div>
            <div class="name">
                <input (input)="setFriendlyName($event, column)" [value]="column.FriendlyName === '' ? getColumnOriginalName(column) : column.FriendlyName" />
            </div>
            <div class="move" cdkDragHandle>
                <span class="sdk-icon">drag_handle</span>
            </div>
        </div>
    </div>
    <div class="footer">
        <div class="reset" (click)="reset()">Reset</div>
        <button class="button" type="button" (click)="apply()">Apply</button>
    </div>
</div>
