import { Component, Input, ChangeDetectionStrategy } from '@angular/core';

import { AlertType } from '~/models/shared/errors/error-alert-type.enum';

@Component({
  selector: 'ccms-alert',
  templateUrl: './error-alert.component.html',
  styleUrls: ['./error-alert.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ErrorAlertComponent {
  @Input()
  public type: AlertType;

  @Input()
  public message: string;

  @Input()
  public iconClass!: string | string[];

  constructor() {
    this.type = AlertType.PRIMARY;
    this.message = "";
  }
}