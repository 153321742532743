<div class="content">
    <div class="data">
        <tabs [tabs]="tabs"
              [share]="true"
              [urlParams]="urlParams"
              [activeTab]="activeTab"
              [beforeTabChange]="confirmTabChange.bind(this)"
              (messageEvent)="showMessage($event)">
        </tabs>
    </div>
</div>
