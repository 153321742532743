import { Component, EventEmitter, Input, Output } from '@angular/core';
import { BaseComponent } from '~/pages/base.component';
import { SignPostReport } from '~/services/shared/powerbi/models/signposts.models';
import { PowerBiReportService } from '~/services/shared/powerbi/report.service';

@Component({
    selector: 'ccms-powerbi-navigator',
    templateUrl: './powerbi-report-navigator.component.html',
    styleUrls: ['./powerbi-report-navigator.component.scss']
})

export class ReportNavigatorComponent extends BaseComponent {
    constructor(private service: PowerBiReportService) {
        super();
    }
    @Input() reportName: string = "";
    @Input() title: string = "";
    @Input() selectedId: string = "";
    @Output() selectedValueChanged = new EventEmitter<string>();
    
    public reportList: SignPostReport[] = [];
    public directionIcon: string = "keyboard_double_arrow_left";
    public expanded: boolean = true;


    public async ngOnInit() {
        await super.ngOnInit();
        
        this.service.getReportConfig(this.reportName).then((data: SignPostReport[] | null) => {
            if (data) {
                this.reportList = data;
            }
        });

        console.log(this.reportList);
    }

    public handleClick(event: any, item: string) {
        this.selectedId = item;
        event.stopPropagation();
        console.log(this.selectedId);
        this.selectedValueChanged.emit(this.selectedId);
        
    }

    public toggleExpand() {
        this.expanded = !this.expanded;
        this.directionIcon = this.directionIcon === "keyboard_double_arrow_right" ? "keyboard_double_arrow_left" : "keyboard_double_arrow_right";
    }
}