import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { Clone } from '~/classes/shared/clone';
import { FileUploadComponent, FileUploadComponentOptions, FileUploadContext } from '~/components/shared/file-upload/file-upload.component';
import { FileUtility } from '~/components/shared/file-upload/file.utility';
import { GeoCoordinates } from '~/components/shared/map-viewer/geo-coordinate';
import { MapDataSource } from '~/components/shared/map-viewer/map-viewer.component';
import { ICvxProject } from '~/models/shared/cvxproject';
import { ProjectShapefile, ProjectShapefileCreateInfo } from '~/services/shared/projects/models/project-shape-file.model';
import { ProjectShapefileService } from '~/services/shared/projects/project-shapefile.service';

@Component({
    selector: 'project-detail-location',
    templateUrl: './project-detail-location.component.html',
    styleUrls: ['./project-detail-location.component.scss']
})
export class ProjectDetailLocationComponent {
    @Input() project!: ICvxProject;
    @Input() isEdit: boolean = false;
    @Input() saveData: boolean = false;
    @Input() portfolioId: string | undefined = undefined;
    @Output() changeEvent: EventEmitter<any> = new EventEmitter();
    @Output() saveDataEvent: EventEmitter<any> = new EventEmitter();
    @ViewChild(FileUploadComponent) mapFilesUploadControl!: FileUploadComponent;

    private _primaryShapefile: FileUploadContext<File | ProjectShapefile> | null = null;
    private _currentPrimaryShapefile?: FileUploadContext<File | ProjectShapefile> | null = null;

    public isLoading: boolean = false;
    public editContext: any;
    public mapUploaderInitialFileList: Array<FileUploadContext<ProjectShapefile>> = [];

    public showImage: boolean = false;
    public showMap: boolean = false;
    public mapLocation!: GeoCoordinates | string | null;
    public place!: string | null;

    public location: any = null;
    public _site: any = null;
    public mapDataSources: MapDataSource[] = [];
    public selectedMapDataSources: MapDataSource[] = [];
    public projectShapefileList: Array<FileUploadContext<ProjectShapefile>> = [];
    public get hasShapefiles() {
        return this.mapDataSources?.length > 0;
    }

    public readonly fileUploadOptions: Partial<FileUploadComponentOptions> = {
        allowedFileTypes: [
            'application/json', 
            '.zip'],
        multiple: true
    };

    constructor(
        private readonly _projectShapesService: ProjectShapefileService
    ) {
        // Intentionally blank
    }

    //******************************************************************************
    //  Page Life-cycle Methods
    //******************************************************************************

    public ngOnInit() {
        if (!this.project?.id) {
            return;
        }
        this.mapLocation = this.parseMapLocation(this.project);
        this.getShapefiles();
    }
    
    public async ngOnChanges() {
        if (this.saveData) {
            this.saveDataEvent.emit(false);
            await this
                .updateShapeFiles()
                .then(changed => {
                    this.mapLocation = this.parseMapLocation(this.project);
                    if (changed) {
                        return this.getShapefiles();
                    }
                    return Promise.resolve();
                });
        }
        else if (this.isEdit) {
            this.isLoading = true;
            this.editContext = Clone.deepCopy(this.project);
            this.location =  Clone.deepCopy(this.project.location);
            this._site =  Clone.deepCopy(this.project.site);
    
            this.changeEvent.emit(this.editContext);

            this.isLoading = false;
        }
    }

    //******************************************************************************
    //  Public Methods
    //******************************************************************************

    public addressChange(field: any, value: any) {
        this.location[field] = value;
        this.infoChange({ field: "location", value: this.location });
    }

    public canSetAsDefaultShapefile(fileContext: FileUploadContext<File | ProjectShapefile>) {
        return !this.isDefaultShapefile(fileContext);
    }

    public downloadShapefile(idx: number) {
        if (!this.mapDataSources || this.mapDataSources.length === 0) {
            console.warn("No map files to download.");
            return;
        }

        if (idx >= this.mapDataSources.length) {
            console.warn("Map file index is out of bound.");
            return;
        }

        const mapFile = this.projectShapefileList[idx];
        const mapData = this.mapDataSources[idx].data;
        const fileName = `${this.project.name}_${mapFile.file.name}`.replace(/ /g, '_');
        // Step 1: Create a Blob from the string  
        const blob = new Blob([JSON.stringify(mapData)], { type: 'application/json' });
        // Step 2: Create a URL for the Blob  
        const blobUrl: string = window.URL.createObjectURL(blob);

        // Step 3: Create an Anchor Element  
        const downloadLink: HTMLAnchorElement = document.createElement('a');
        downloadLink.href = blobUrl;
        downloadLink.download = fileName;

        // Step 4: Trigger the Download  
        document.body.appendChild(downloadLink);
        downloadLink.click();

        // Step 5: Clean Up  
        document.body.removeChild(downloadLink);
        window.URL.revokeObjectURL(blobUrl);
    }

    public isDefaultShapefile(fileContext: FileUploadContext<File | ProjectShapefile>) {
        const file = fileContext.file;
        return 'isPrimary' in file && file.isPrimary;
    }

    public siteChange(field: any, value: any) {
        this._site[field] = value;
        this.infoChange({ field: "site", value: this._site });
    }

    public infoChange(event: any) {
        this.editContext[event.field] = event.value;
        this.changeEvent.emit(this.editContext);
    }

    public isMapFileVisible(idx: number) {
        if (idx >= this.mapDataSources.length) {
            console.warn("Shapefile index out of bound.");
            return;
        }

        const shapefile = this.mapDataSources[idx];
        return this.selectedMapDataSources.includes(shapefile);
    }

    public onFileUploaderRemoveFile(file: FileUploadContext<ProjectShapefile>) {
        if (this._currentPrimaryShapefile == file) {
            this._currentPrimaryShapefile = null;
        }
    }

    public onFileUploaderReset() {

    }

    public toggleShapefile(idx: number) {
        if (idx >= this.mapDataSources.length) {
            console.warn("Shapefile index out of bound.");
            return;
        }

        const shapefile = this.mapDataSources[idx];
        const current = [ ...this.selectedMapDataSources ];
        const selectedIndex = this.selectedMapDataSources.indexOf(shapefile);
        if (selectedIndex > -1) {
            current.splice(selectedIndex, 1);
        }
        else {
            current.push(shapefile);
        }
        this.selectedMapDataSources = [ ...current ];
    }

    //******************************************************************************
    //  Private Methods
    //******************************************************************************
  
    //#region Internals

    private async getShapefiles() {
        this._currentPrimaryShapefile = null;
        this.selectedMapDataSources = [];
        const projectShapesService = this._projectShapesService;
        const result = await projectShapesService
            .getShapefiles(this.project.id);
        this.projectShapefileList = result.map(shapefile => {
            const fileContext = new FileUploadContext<ProjectShapefile>(shapefile);
            if (shapefile.isPrimary) {
                this._primaryShapefile = fileContext;
                this._currentPrimaryShapefile = fileContext;
            }
            return fileContext;
        });
        this.mapDataSources = result.map(s => {
            const nameOnly = s.name.substring(0, s.name.indexOf('.'))
            return {
                id: s.id.toString(),
                name: nameOnly,
                type: 'geojson',
                data: JSON.parse(s.data)
            } as MapDataSource
        });
        this.mapUploaderInitialFileList = Clone.deepCopy(this.projectShapefileList);
        if (this._primaryShapefile) {
            const primaryShapefile = this._primaryShapefile.file as ProjectShapefile;
            const primaryDataSource = this.mapDataSources.find(ds => ds.id === primaryShapefile.id.toString());
            if (primaryDataSource) {
                this.selectedMapDataSources = [ primaryDataSource ];
            }
        }
        if (this.selectedMapDataSources.length == 0) {
            this.selectedMapDataSources = [ ...this.mapDataSources ];
        }
    }

    private parseMapLocation(project: ICvxProject): GeoCoordinates | string | null {
        if (project?.site?.latitude && project?.site?.longitude) {
            const site = project.site;
            this.place = this.parseProjectPlace(project);
            return new GeoCoordinates(site.latitude, site.longitude);
        }

        this.place = this.parseProjectPlace(project);

        return null;
    }

    private parseProjectPlace(project: ICvxProject): string | null { 
      if (project.location) {
        const location = project.location;
        if (location.streetAddress || location.addressLocality || location.addressRegion || location.postalCode) {
          return [
            location.addressCountry.name,
            location.streetAddress,
            location.addressLocality,
            location.addressRegion,
            location.postalCode
          ].join(',');
        }
        if (location.addressCountry?.name) {
          return location.addressCountry.name;
        }
      } 

      return null;
    }

    private readFile(file: File): Promise<string> {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = () => resolve(reader.result as string);
            reader.onerror = () => reject(reader.error);
            reader.readAsText(file);
        });
    }

    private async updateShapeFiles() {
        const oldFileList = this.projectShapefileList;
        const newFileList = this.mapFilesUploadControl.fileList;
        const uploadQueue = newFileList.filter(f => f.canUpload);
        const deleteQueue = oldFileList
            .filter(fileContext => !newFileList.includes(fileContext))
            .map(fileContext => fileContext.file.id);
            
        const projectId = this.project.id;
        const projectService = this._projectShapesService;
        const tasks: Promise<any>[] = [];
        if (deleteQueue.length > 0) {
            tasks.push(projectService.deleteShapefiles(projectId, deleteQueue));
        }
        const geoJsonFiles: Array<File> = [];
        uploadQueue.forEach((q) => {
            if (!(q.file instanceof File)) {
                return;
            }
            const file = q.file;
            const fileType = file.type.toLowerCase();
            if (FileUtility.isZippedFile(fileType)) {
                tasks.push(projectService.addShapefile(projectId, file));
                return;
            }
            geoJsonFiles.push(file);
        });
        if (geoJsonFiles.length > 0) {
            const data = await this.parseJsonFiles(geoJsonFiles);
            tasks.push(projectService.addGeoJsonFiles(projectId, data));
        }
        await Promise.all(tasks);

        /** Get primary shape file. If it is set in new file, then this will be null.
         * Else, we manually update existing file and set it as primary. */
        /** Only update primary state if file is an existing entity. If it is a new entity, then it is handle as part of insert. */
        if (this._currentPrimaryShapefile && !(this._currentPrimaryShapefile.file instanceof File)) {
            const currentPrimaryShapefile = this._currentPrimaryShapefile;
            const file = currentPrimaryShapefile!.file as ProjectShapefile;
            const shapefileId = file.id;
            await projectService.updateShapefile(projectId, {
                id: shapefileId,
                isPrimary: file.isPrimary
            });
        }
        /** Return bool to indicate if there were changes */
        return deleteQueue.length > 0
            || uploadQueue.length > 0
            || this._primaryShapefile != this._currentPrimaryShapefile;
    }

    private async parseJsonFiles(files: Array<File>): Promise<ProjectShapefileCreateInfo[]> {
        const parseData: Promise<ProjectShapefileCreateInfo>[] = [];

        files.forEach((file) => {
            parseData.push(this.readFile(file).then(fileContent => {
                return {
                    projectId: this.project.id,
                    data: fileContent,
                    name: file.name,
                    isPrimary: file['isPrimary']
                } as ProjectShapefileCreateInfo;
            }));
        });
        return await Promise.all(parseData);
    }

    public setDefaultShapefile(fileContext: FileUploadContext<File | ProjectShapefile>) {
        this.projectShapefileList.forEach(fileContext => {
            fileContext.file.isPrimary = false;
        });
        if (this._currentPrimaryShapefile) {
            this._currentPrimaryShapefile.file['isPrimary'] = false;
        }
        fileContext.file['isPrimary'] = true;
        this._currentPrimaryShapefile = fileContext;
    }

    //#endregion
}