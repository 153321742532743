import { Component, Input } from "@angular/core";
import { DeveloperProfile } from "~/services/shared/developers-screening/models/developer-summary";

@Component({
    selector: 'developer-summary',
    templateUrl: './developer-summary.component.html',
    styleUrls: ['./developer-summary.component.scss']
})
export class DeveloperSummaryComponent {
    @Input() developer!: DeveloperProfile;
}