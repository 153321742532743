<div class="market-popup">
  <div class="header">
    <div class="content-title" mat-dialog-title>Message</div>
    <dialog-cancel-button mat-dialog-close></dialog-cancel-button>
  </div>
  <div class="content">
    <div class="scroll-descr">
			<p class="content-intro">{{ data.message }}</p>
		</div>
  </div>
  <div class="close-button">
    <ccms-icon-button class="small" mat-dialog-close>
      Close
    </ccms-icon-button>
  </div>
</div>