import { Component, ViewChild, OnInit, TemplateRef, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';

import { SDKDataGridDataset, SDKDataGridOptions, SDKDataGridSettings, Filters, SDKDataGridCustomFilter, FilterType } from 'sdk-datagrid';

import { AppComponent } from '~/app.component';

import { AppSettingsService } from '~/services/core/appsettings.service';
import { FormatterService } from '~/services/shared/formatter.service';
import { ProjectService } from '~/services/shared/projects/project.service';
import { CountryService } from '~/services/shared/country.service';

import { RequestParameters } from '~/models/shared/request-parameters';
import { ICvxProject, ProjectConstants } from '~/models/shared/cvxproject';
import { InvestmentService } from '~/services/shared/investment.service';

import { AppSettings } from '~/models/core/appsettings';
import { UserPermissionsService } from '~/services/shared/user-permissions.service';
import { Observable, firstValueFrom, lastValueFrom, mergeMap, of, take } from 'rxjs';
import { GridName } from '~/services/shared/state/grid/grid-name.enum';
import { getGridPager, arraysAreEqual, setGridPager } from '~/services/shared/state/grid/grid-pager.repository';
import { ExportOptionComponent } from '~/components/shared/export-option/export-option.component';
import { ProjectExportWindowComponent } from './export/window/project-export-window.component';
import { buildParametersFilters, buildParametersSorts } from './project-helpers';
import { FilterInfo } from '~/models/shared/filterInfo';
import { SettingsGrid } from '~/services/shared/grid/models/settings-grid.model';
import { GridHandlerService } from '~/services/shared/grid/grid-handler.service';
import { ProjectPriorityScoreCardInputModel } from '~/services/shared/projects/models/project-priority-score-card';
import { ProjectPortfolioService } from '~/services/shared/projects/project-portfolio.service';
import { UserInfo } from '~/models/shared/users/user-info';
import CcmsServerError from '~/models/shared/errors/ccms-server-error';
import { ProjectPortfolioLink } from '~/services/shared/projects/models/project-portfolio.model';

@Component({
    selector: 'project-grid',
    templateUrl: './project-grid.component.html',
    styleUrls: ['./project-grid.component.scss']
})
export class ProjectGridComponent implements OnInit, SettingsGrid  {
    @Output() setMessageEvent: EventEmitter<any> = new EventEmitter();

    @ViewChild('dataTemplate') dataTemplate!: TemplateRef<any>;
    @ViewChild('dateTemplate') dateTemplate!: TemplateRef<any>;
    @ViewChild('descriptionEditTemplate') descriptionEditTemplate!: TemplateRef<any>;
    @ViewChild('developerTemplate') developerTemplate!: TemplateRef<any>;
    @ViewChild('developerEditTemplate') developerEditTemplate!: TemplateRef<any>;
    @ViewChild('statusTemplate') statusTemplate!: TemplateRef<any>;
    @ViewChild('statusEditTemplate') statusEditTemplate!: TemplateRef<any>;
    @ViewChild('contactEditTemplate') contactEditTemplate!: TemplateRef<any>;
    @ViewChild('legalLeadEditTemplate') legalLeadEditTemplate!: TemplateRef<any>;
    @ViewChild('originatorEditTemplate') originatorEditTemplate!: TemplateRef<any>;
    @ViewChild('termSheetLeadEditTemplate') termSheetLeadEditTemplate!: TemplateRef<any>;
    @ViewChild('negotiatorEditTemplate') negotiatorEditTemplate!: TemplateRef<any>;
    @ViewChild('technicalLeadEditTemplate') technicalLeadEditTemplate!: TemplateRef<any>;
    @ViewChild('projectTypeTemplate') projectTypeTemplate!: TemplateRef<any>;
    @ViewChild('projectTypeEditTemplate') projectTypeEditTemplate!: TemplateRef<any>;
    
    @ViewChild('countryTemplate') countryTemplate!: TemplateRef<any>;
    @ViewChild('countryEditTemplate') countryEditTemplate!: TemplateRef<any>;
    @ViewChild('methodologyTemplate') methodologyTemplate!: TemplateRef<any>;
    @ViewChild('methodologyEditTemplate') methodologyEditTemplate!: TemplateRef<any>;
    @ViewChild('ownerTemplate') ownerTemplate!: TemplateRef<any>;
    @ViewChild('ownerEditTemplate') ownerEditTemplate!: TemplateRef<any>;
    @ViewChild('executionStartDateTemplate') executionStartDateTemplate!: TemplateRef<any>;
    @ViewChild('ndaExecutionDateTemplate') ndaExecutionDateTemplate!: TemplateRef<any>;
    @ViewChild('actionRight') actionRight!: TemplateRef<any>;
    @ViewChild('customerProductTemplate') customerProductTemplate!: TemplateRef<any>;
    @ViewChild('customerProductEditTemplate') customerProductEditTemplate!: TemplateRef<any>;
    @ViewChild('investmentTypeTemplate') investmentTypeTemplate!: TemplateRef<any>;
    @ViewChild('investmentTypeEditTemplate') investmentTypeEditTemplate!: TemplateRef<any>;
    @ViewChild('technicalReviewStatusTemplate') technicalReviewStatusTemplate!: TemplateRef<any>;
    @ViewChild('technicalReviewStatusEditTemplate') technicalReviewStatusEditTemplate!: TemplateRef<any>;
    @ViewChild('technicalReviewRecommendationTemplate') technicalReviewRecommendationTemplate!: TemplateRef<any>;
    @ViewChild('technicalReviewRecommendationEditTemplate') technicalReviewRecommendationEditTemplate!: TemplateRef<any>;
    @ViewChild('wsjfBusinessValueTemplate') wsjfBusinessValueTemplate!: TemplateRef<any>;
    @ViewChild('wsjfBusinessValueEditTemplate') wsjfBusinessValueEditTemplate!: TemplateRef<any>;
    @ViewChild('wsjfTimeCriticalityTemplate') wsjfTimeCriticalityTemplate!: TemplateRef<any>;
    @ViewChild('wsjfTimeCriticalityEditTemplate') wsjfTimeCriticalityEditTemplate!: TemplateRef<any>;
    @ViewChild('wsjfRiskReductionTemplate') wsjfRiskReductionTemplate!: TemplateRef<any>;
    @ViewChild('wsjfRiskReductionEditTemplate') wsjfRiskReductionEditTemplate!: TemplateRef<any>;
    @ViewChild('wsjfEstimatedEffortTemplate') wsjfEstimatedEffortTemplate!: TemplateRef<any>;
    @ViewChild('wsjfEstimatedEffortEditTemplate') wsjfEstimatedEffortEditTemplate!: TemplateRef<any>;
    @ViewChild('wsjfScoreTemplate') wsjfScoreTemplate!: TemplateRef<any>;
    @ViewChild('wsjfScoreEditTemplate') wsjfScoreEditTemplate!: TemplateRef<any>;
    @ViewChild('projectPortfolioIdTemplate') projectPortfolioIdTemplate!: TemplateRef<any>;
    @ViewChild('projectPortfolioIdEditTemplate') projectPortfolioIdEditTemplate!: TemplateRef<any>;
    @ViewChild('projectTypeRatingEdit') projectTypeRatingEdit!: TemplateRef<any>;
    @ViewChild('geographyRatingEdit') geographyRatingEdit!: TemplateRef<any>;
    @ViewChild('developerRatingEdit') developerRatingEdit!: TemplateRef<any>;
    @ViewChild('fungibilityRatingEdit') fungibilityRatingEdit!: TemplateRef<any>;
    @ViewChild('scaleRatingEdit') scaleRatiscaleRatingEditg!: TemplateRef<any>;
    @ViewChild('economicsRatingEdit') economicsRatingEdit!: TemplateRef<any>;
    @ViewChild('initialScreeningPriorityEdit') initialScreeningPriorityEdit!: TemplateRef<any>;
    @ViewChild('priorityWithEconomicsEdit') priorityWithEconomicsEdit!: TemplateRef<any>;

    public appSettings: AppSettings = new AppSettings();
    public entity = "CvxProject";
    public isLoading: boolean = true;
    public footnote: string = "";
    public MAXRECORDS: number = 1000;
    public activeView: string = "projects/search";
    public datasets: SDKDataGridDataset[] = [
        {
            Title: "Projects",
            DbName: "projects/search",
        }
    ];
    public data: ICvxProject[] | null = null;
    public columns: any[] = [
        { Name: 'priority', DisplayName: 'ADO Priority', FilterMultiSelect: true, FilterValues: [], isVisible: true },
        { Name: 'name', DisplayName: 'Project Name', isVisible: true, required: true, requiredColor: 'var(--red)', width: '400px' },
        { Name: 'cvxProjectDeveloper', DisplayName: 'Developer', isVisible: true, required: true, requiredColor: 'var(--red)', width: '300px', dataTemplate: () => this.developerTemplate, editTemplate: () => this.developerEditTemplate },
        { Name: 'status', DisplayName: 'Status', FilterMultiSelect: true, FilterValues: [], isVisible: true, required: true, requiredColor: 'var(--red)', dataTemplate: () => this.statusTemplate, editTemplate: () => this.statusEditTemplate },
        { Name: 'contact', DisplayName: 'Commercial Mgr', isVisible: true, required: true, requiredColor: 'var(--red)', width: '300px', editTemplate: () => this.contactEditTemplate },
        { Name: 'cvxProjectType', DisplayName: 'Project Type', required: true, requiredColor: 'var(--red)', dataTemplate: () => this.projectTypeTemplate, editTemplate: () => this.projectTypeEditTemplate, isVisible: true },
        { Name: 'investmentType', DisplayName: 'Investment Type', FilterMultiSelect: true, FilterValues: [], dataTemplate: () => this.investmentTypeTemplate, editTemplate: () => this.investmentTypeEditTemplate, isVisible: true },
        { Name: 'projectStatusNotes', DisplayName: 'Status Notes', width: '400px', dataTemplate: () => this.dataTemplate, isVisible: true },


        { Name: 'description', DisplayName: 'Description', required: false, width: '400px', dataTemplate: () => this.dataTemplate, isVisible: false, editTemplate: () => this.descriptionEditTemplate },
        { Name: 'country', DisplayName: 'Country', FilterMultiSelect: true, FilterValues: [], required: true, requiredColor: 'var(--red)', isVisible: false, dataTemplate: () => this.countryTemplate, editTemplate: () => this.countryEditTemplate },
        { Name: 'originator', DisplayName: 'Originator', isVisible: false, width: '300px', dataTemplate: () => this.dataTemplate, editTemplate: () => this.originatorEditTemplate },
        { Name: 'legalLead', DisplayName: 'Legal Lead', isVisible: false, width: '300px', editTemplate: () => this.legalLeadEditTemplate },
        { Name: 'owners', DisplayName: 'Owner', isVisible: false, dataTemplate: () => this.ownerTemplate, editTemplate: () => this.ownerEditTemplate },
        { Name: 'operator', DisplayName: 'Operator', width: '300px', isVisible: false },
        { Name: 'totalCreditsForecast', DisplayName: 'Credits Forecast (MM)', isVisible: false, validCharacters: 'decimal', pattern: "1.0-2", formatter: (value: any) => this.formatterService.formatNumber(value, "1.0-2") },
        { Name: 'fundingOrSpending', DisplayName: 'Total Funding/Spending (MM)', validCharacters: 'decimal', pattern: "1.0-2", formatter: (value: any) => this.formatterService.formatNumber(value, "1.0-2"), isVisible: false },
        { Name: 'executionStartDate', DisplayName: 'Execution Start Date', validCharacters: 'calendar', pattern: 'MM/DD/YYYY', formatter: (value: any) => this.formatterService.formatDate(value, "MM/dd/yyyy"), dataTemplate: () => this.executionStartDateTemplate, isVisible: false },
        { Name: 'ndaExecutionDate', DisplayName: 'NDA Execution Date', validCharacters: 'calendar', pattern: 'MM/DD/YYYY', formatter: (value: any) => this.formatterService.formatDate(value, "MM/dd/yyyy"), dataTemplate: () => this.ndaExecutionDateTemplate, isVisible: false },
        { Name: 'firstYearOfIssuance', DisplayName: 'First Year of Issuance', isVisible: false, validCharacters: 'custom', pattern: '^([0-9]{0,4}|\\s*)$' },
        { Name: 'verifier', DisplayName: 'Verifier', width: '300px', isVisible: false },
        { Name: 'methodology', DisplayName: 'Methodology', FilterMultiSelect: true, FilterValues: [], 
            dataTemplate: () => this.methodologyTemplate, 
            editTemplate: () => this.methodologyEditTemplate,
            isVisible: false },
        { Name: 'metaRegistryProjectId', DisplayName: 'Meta Registry', isVisible: false },
        { Name: 'barriers', DisplayName: 'Barriers', width: '400px', dataTemplate: () => this.dataTemplate, isVisible: false },
        { Name: 'notes', DisplayName: 'Notes', width: '400px', dataTemplate: () => this.dataTemplate, isVisible: false },
        { Name: 'product', DisplayName: 'Customer Product', FilterMultiSelect: true, FilterValues: [], dataTemplate: () => this.customerProductTemplate, editTemplate: () => this.customerProductEditTemplate, isVisible: false },
        { Name: 'negotiator', DisplayName: 'Negotiator', width: '300px', dataTemplate: () => this.dataTemplate, isVisible: false, editTemplate: () => this.negotiatorEditTemplate },
        { Name: 'technicalLead', DisplayName: 'Technical Lead', width: '300px', dataTemplate: () => this.dataTemplate, isVisible: false, editTemplate: () => this.technicalLeadEditTemplate },
        { Name: 'technicalReviewStatus', DisplayName: 'Technical Review Status', FilterMultiSelect: true, FilterValues: [], dataTemplate: () => this.technicalReviewStatusTemplate, editTemplate: () => this.technicalReviewStatusEditTemplate, isVisible: false },
        { Name: 'technicalReviewRecommendation', DisplayName: 'Technical Review Recommendation', dataTemplate: () => this.technicalReviewRecommendationTemplate, editTemplate: () => this.technicalReviewRecommendationEditTemplate, isVisible: false },
        { Name: 'projectPortfolioId', DisplayName: 'Epic ID', dataTemplate: () => this.projectPortfolioIdTemplate, editTemplate: () => this.projectPortfolioIdEditTemplate }, 
        { Name: 'lastUpdated', DisplayName: 'Last Updated', allowEdit: false, formatter: (value: any) => this.formatterService.formatDate(value, "MM/dd/yyyy"), isVisible: false },
        { Name: 'Edit', DisplayName: 'Edit', actionSide: "right", actionTemplate: () => this.actionRight, isVisible: false },
        { Name: 'termSheetLead', DisplayName: 'Term Sheet Lead', dataTemplate: () => this.dataTemplate, isVisible: false, editTemplate: () => this.termSheetLeadEditTemplate },
        { Name: 'externalTechResource', DisplayName: 'External Tech Resource', dataTemplate: () => this.dataTemplate, isVisible:  false },
        { Name: 'maximumAccessLifetimeVolume', DisplayName: 'Max Access Volume (MTCO2e)', dataTemplate: () => this.dataTemplate, validCharacters: 'decimal', pattern: "1.0-2", formatter: (value: any) => this.formatterService.formatNumber(value, "1.0-2"), isVisible:  false },
        { Name: 'contractedLifetimeVolume', DisplayName: 'Contracted Lifetime Volume (MTCO2e)', validCharacters: 'decimal', pattern: "1.0-2", formatter: (value: any) => this.formatterService.formatNumber(value, "1.0-2"), isVisible:  false },
        { Name: 'projectLife', DisplayName: 'Project Life Years', dataTemplate: () => this.dataTemplate, validCharacters: 'custom', pattern: '^[0-9]{0,4}$', isVisible:  false },
        
        /** Priority Score Card */
        { Name: 'projectTypeRating', DisplayName: 'Project Type Rating', editTemplate: () => this.projectTypeRatingEdit, isVisible: false },
        { Name: 'geographyRating', DisplayName: 'Geography/Customer Rating', editTemplate: () => this.geographyRatingEdit, isVisible: false },
        { Name: 'developerRating', DisplayName: 'Developer Rating', editTemplate: () => this.developerRatingEdit, isVisible: false },
        { Name: 'fungibilityRating', DisplayName: 'Offset Optionality/Fungibility', editTemplate: () => this.fungibilityRatingEdit, isVisible: false },
        { Name: 'scaleRating', DisplayName: 'Scale Rating', editTemplate: () => this.scaleRatiscaleRatingEditg, isVisible: false },
        { Name: 'economicsRating', DisplayName: 'Economics Rating', editTemplate: () => this.economicsRatingEdit, isVisible: false },
        { Name: 'initialScreeningPriority', DisplayName: 'Initial Screening Priority', editTemplate: () => this.initialScreeningPriorityEdit, isVisible: false },
        { Name: 'priorityWithEconomics', DisplayName: 'Priority w/Economics', editTemplate: () => this.priorityWithEconomicsEdit, isVisible: false },

        { Name: 'id', DisplayName: 'ID', allowEdit: false, isVisible: false },
    ];

    public customFilters: SDKDataGridCustomFilter[] = [
        { Name: "keyword", DisplayName: "* Keyword Search", Type: FilterType.Textbox, FilterTypes: [Filters.Contains, Filters.NotContains] }
    ]

    // Settings grid interface Implementation:
    public uniqueIdentifier = "projects.components.project-grid.project-grid";
    public maxDescriptionLength = ProjectConstants.MaxProjectDescriptionLength;
    public allSettings: SDKDataGridSettings[] = [];
    public multiSelectFilters: FilterInfo[] = [];
    public errorHanlder = (message, error) => {
        console.error(`${message} ${error?.message}`);
    }
    
    public parseMethodologyTitle(data: any) {
        if (data.methodology) {
            const methodology = data.methodology;
            const header = `[${methodology.registry.toUpperCase()}] ${methodology.name}`;
            return methodology.description
                ? `${header}: ${methodology.description}`
                : header;
        }

        return '';
    }

    public parseMethodologyDisplayText(data: any) {
        if (data.methodology) {
            const methodology = data.methodology;
            return `[${methodology.registry.toUpperCase()}] ${methodology.name}`;
        }
        return "--";
    }

    public formulas: any[] = [
        {
            ID: 'totalCreditsForecast',
            Name: 'totalCreditsForecast',
            Format: 2,
            Operation: 'Sum',
            Value: 0
        },
        {
            ID: 'fundingOrSpending',
            Name: 'fundingOrSpending',
            Format: 2,
            Operation: 'Sum',
            Value: 0
        }
    ];

    public gridOptions: SDKDataGridOptions = {
        settings: true,
        columnSettings: true,
        filtering: true,
        sorting: true,
        formulas: true,
        charts: false,
        export: false,
        expandableRows: false,
        selectableRows: false,
        autoClosePanel: true
    };

    /**
     * This object is used for change tracking. Do not
     * bind any control to this object. Instead, use structuredClone
     * to make copy of it.
     * See https://developer.mozilla.org/en-US/docs/Web/API/structuredClone
     */
    private _viewModel: ICvxProject[] | null = null;
    public filterTypes: any = [Filters.Equals, Filters.NotEquals, Filters.Contains ];
    public editRowIndex: number = -1;
    public fullData: ICvxProject[] | null = null;
    public dbPage: number = 0;
    public dbTotal: number = 0;
    public error: string = "";

    public projectTypeList: any = [];
    public projectStatusList: any = [];
    public projectStatusCategoryList: string[] = [];
    public countryList: any = [];
    public customerProductList: any = [];
    public technicalReviewRecommendationList: any = [];
    public technicalReviewStatusList: any = [];
    public investmentTypeList: any = [];

    public canUpdate = false;
    public canDelete = false;
    public globalLocation: any = {
        name: "Global",
        id: -1,
        alpha2Code: "",
        alpha3Code: "",
        regionCode: -1,
        regionName: "Global",
        latLongCoords: null,
        countryRisk: null,
        region: null,
        subRegion: null
    };

    public currentEvent: any = null;
    public projectDNPStatusList: any[] = [];

    public exportOptionComponent = ExportOptionComponent;
    public exportWindowComponent = ProjectExportWindowComponent;
    public userSelectionList: UserInfo[] = [];

    constructor(
        private readonly app: AppComponent,
        private readonly appSettingsService: AppSettingsService,
        private readonly router: Router,
        private readonly userPermissionService: UserPermissionsService,
        private readonly formatterService: FormatterService,
        private readonly projectService: ProjectService,
		private readonly projectPortfolioService: ProjectPortfolioService,
        private readonly countryService: CountryService,
        private readonly investmentService: InvestmentService,
        private readonly gridHandlerService: GridHandlerService
    ) { }

    //******************************************************************************
    //  Page Life-cycle Methods
    //******************************************************************************
    async ngOnInit() {
        this.appSettings = await this.appSettingsService.getSettings();
        await this.getPermissions();
        await this.loadMultiSelectFilters();
        this.gridHandlerService.loadSettings(this, [
            '/assets/datagrids/priority-tool.json'
        ]);
        await Promise.all([
            this.loadProjectTypeList(),
            this.loadProjectStatusList(),
            this.loadCountryList(),
            this.loadObjectLists()
        ]);
    }

    //******************************************************************************
    //  Public Methods
    //******************************************************************************
    public async loadData(event: any = null) {
        this.isLoading = true;
        this.currentEvent = event;

        let page = await this.getCurrentPageFromSettings(event);
        let parameters: RequestParameters = this.buildParameters(event, page);
        let fullLoad: boolean = false;

        if (event && (event.chart !== undefined || event.export !== undefined)) {
            fullLoad = true;

            parameters.Paging = {
                pageSize: 999999,
                currentPage: 1
            };
        }

        try {
            const data = await this.projectService.searchProjects(parameters);
            if (data) {
                const viewModel = this.extendResults(data.results.values);
                this._viewModel = structuredClone(viewModel);
                await this.loadMultiSelectFilters();
                if (fullLoad) {
                    this.fullData = viewModel;
                } else {
                    this.resetVariables();

                    this.data = viewModel;
                    this.dbPage = parseInt(data.results.metadata.currentPage);
                    this.dbTotal = parseInt(data.results.metadata.totalCount);
                    this.error = "";

                    setGridPager(GridName.Projects, data.results.metadata.currentPage, event?.rows, event?.filters);
                }

                this.footnote = `Project data as of ${this.formatterService.formatDate(new Date(), "dd MMM yyyy hh:mm:ss (z)")}`;
            }
           
        } catch (error: any) {
            this.error = error.message;
        }

        this.isLoading = false;
    }

    public showDetail(record: any) {
        if (record && this.editRowIndex === -1) {
            this.router.navigate(["/projects/detail"], { state: { projectId: record.data.id } });
        }
    }

    public editProject(index: any) {
        this.editRowIndex = index;
    }

    public saveProject(activeRow: any) {
      const missingFields = this.getMissingFields(activeRow);
  
      if (missingFields !== '') {
          this.showMissingFieldsAlert(missingFields);
      } else {
          this.save(activeRow);
      }
    }
  
    private getMissingFields(activeRow: any): string {
      let missingFields = "";
  
      for (let key of Object.keys(activeRow)) {
          if (this.isMissingField(activeRow, key)) {
              const ndx = this.columns.findIndex((c: any) => c.Name === key);
              missingFields += this.setMissingFields(ndx);
          }
      }
  
      return missingFields;
    }
  
    private isMissingField(activeRow: any, key: string): boolean {
      const ndx = this.columns.findIndex((c: any) => c.Name === key);
      const value = this.setValue(activeRow, key);
      const isMissing = ndx > -1 && (!value || value === '');
      let isRequired = this.columns[ndx]?.required ?? false;

      if (activeRow?.status?.name?.toLowerCase() == "lead") {
          isRequired = false;
      }
  
      if (key === "country" && activeRow.isGlobalLocation) {
          return false;
      }
  
      return isMissing && isRequired;
    }
  
    private showMissingFieldsAlert(missingFields: string) {
      this.app.alertTitle = "Missing Required Fields";
      this.app.alertMessage = `Please provide the following fields before saving:<br />${missingFields}`;
      this.app.showAlert = true;
    }
  
    public deleteProject(rowItem: any) {
        this.app.alertTitle = "Delete Project";
        this.app.alertMessage = `Are you sure you want to delete the "<strong>${rowItem.name}</strong>" project?`;
        this.app.alertContinueText = "Yes";
        this.app.alertCancelText = "No";
        this.app.alertContinue = () => {
            this.isLoading = true;
            this.app.showAlert = false;

            this.projectService.deleteProject(rowItem.id).then(async (results: boolean) => {
                if (results) {
                    await this.loadData(this.currentEvent);

                    this.setMessageEvent.emit({ text: `Project "${rowItem.name}" has been deleted.`, style: "color: white; background-color: rgb(118, 146, 49);" });
                } else {
                    this.setMessageEvent.emit({ text: `Error deleting project "${rowItem.name}".`, style: "color: white; background-color: rgb(151, 0, 46);" });
                }
            });
        };
        this.app.alertCancel = () => { this.app.showAlert = false; };
        this.app.showAlert = true;
    }

    public cancelEdit() {
        this.editRowIndex = -1;
    }

    public setProjectType(rowItem: any, event: any) {
        if (event.target.value === "") {
            rowItem.cvxProjectType = {};
        } else {
            let data = event.target.value.split(",");
            let typeNdx: any = this.projectTypeList.findIndex((t: any) => t.name === data[0]);
            let subtypeNdx: any = this.projectTypeList[typeNdx].cvxProjectTypes.findIndex((s: any) => s.id === parseInt(data[1]));

            rowItem.cvxProjectType = {
                "id": this.projectTypeList[typeNdx].cvxProjectTypes[subtypeNdx].id,
                "name": this.projectTypeList[typeNdx].cvxProjectTypes[subtypeNdx].name,
                "cvxProjectScopeName": this.projectTypeList[typeNdx].longName,
                "cvxProjectScope": {
                    "longName": this.projectTypeList[typeNdx].longName,
                    "id": typeNdx + 1,
                    "name": this.projectTypeList[typeNdx].name
                }
            };
        }
    }

    public setDescription(rowItem: any, event: any) {
        rowItem.description = event.target.value;
    }

    public setStatus(rowItem: any, event: any) {
        if (event.target.value === "") {
            rowItem.status = {};
        } else {
            let status = JSON.parse(event.target.value);
            rowItem.status = status;
        }
    }

    public setOtherReason(rowItem: any, event: any) {
        if (event.target.value === "") {
            rowItem.didNotPursueReasonOther = null;
        } else {
            rowItem.didNotPursueReasonOther = event.target.value;
        }
    }

    public setDNPStatus(rowItem: any, event: any) {
        if (event.target.value === "") {
            rowItem.didNotPursueReason = {};
        } else {
            rowItem.didNotPursueReason = JSON.parse(event.target.value);
        }
    }

    public setMethodology(context: any, methodologyId: number) {
        context.methodologyId = methodologyId;
    }

    public async setCountry(rowItem: any, event: any) {
        if (event.target.value === "") {
            rowItem.location.addressCountry = {};
            rowItem.country = {};
        } else {
            const selected = JSON.parse(event.target.value);
            if (selected.id == this.globalLocation.id) {
                rowItem.location.addressCountry = null;
                rowItem.country = null;
                rowItem.isGlobalLocation = true;
            }
            else {
                const country = await this.countryService.getCountryByAlpha3Code(selected.alpha3Code);
                rowItem.location.addressCountry = country;
                rowItem.country = country;
                rowItem.isGlobalLocation = null;
            }
            
        }
    }

    public setOwner(rowItem: any, id: number, property: string, event: any) {
        let ownerNdx = rowItem.owners.findIndex((r: any) => r.accountId === id);

        if (ownerNdx > -1) {
            rowItem.owners[ownerNdx][property] = event;
        }
    }

    public addOwner(rowItem: any) {
        let id: number = 1;
        let ids = rowItem.owners.sort((a: any, b: any) => {
            if (a.accountId < b.accountId) {
                return -1;
            }
            if (a.accountId > b.accountId) {
                return 1;
            }

            return 0;
        });

        if (ids.length > 0) {
            id = parseInt(ids[ids.length - 1].accountId) + 1;
        }

        rowItem.owners.push(
            {
                "accountId": id,
                "accountName": "",
                "percentOwned": 0
            }
        );
    }

    public deleteOwner(rowItem: any, id: number) {
        rowItem.owners = rowItem.owners.filter((r: any) => r.accountId !== id);
    }

    public saveSettings(event: SDKDataGridSettings[]) {
        this.gridHandlerService.saveSettings(this, event);
    }

    public getPriorityScoreCardToolTip(type: 'projectType' | 'geography' | 'developer' | 'fungibility' | 'scale' | 'economics') {
        return this.getPriorityRatingDescriptions(type)
            .map((str, idx) => `${idx + 1} - ${str}`).join('\n');
    }

    public getPriorityRatingDescriptions(type: 'projectType' | 'geography' | 'developer' | 'fungibility' | 'scale' | 'economics') {
        /**
         * These values should be orderd by value (index = 0 means a rating of 1, etc...)
         */
        if (type === 'projectType') {
            return [
                'Removal or Avoidance for Regulator set Compliance',
                'Removal for VCM and Tech Avoidance',
                'NBS Avoidance in VCM'
            ];
        }
        if (type === 'geography') {
            return [
                'CVX Compliance Country or Article 6 to meet CVX Compliance',
                'VCM where CVX does business or potential Article 6',
                'VCM where CVX does not do business, CVX risk assessment, and/or not on Offsets map'
            ];
        }
        if (type === 'developer') {
            return [
                'Implementation capability (resources secured -boots on the ground), experienced team (internal or external) with registry submital of carbon offsets projects (registered projects), and sophistication',
                'Implementation capability (resources secured -boots on the ground), experienced team (internal or external) of carbon offsets projects',
                'Experienced team (internal or external) with no registry submital of carbon offsets projects (no registered projects)'
            ];
        }
        if (type === 'fungibility') {
            return [
                'Multiple VCM or Compliance use',
                'Multiple VCM or Compliance use potential',
                'NBS Avoidance in  VCM'
            ];
        }
        if (type === 'scale') {
            return [
                'Material impact of compliance need',
                'Minimal impact of compliance need compliance + VCM greater than 200Kpa or 2.5mMT total',
                'VCM vol less than 200Kpa or 2.5MMT total'
            ];
        }
        if (type === 'economics') {
            return [
                'greater than 15%',
                'between 10% -15%',
                'less than 10%'
            ];
        }
        return [];
    }

    //******************************************************************************
    //  Private Methods
    //******************************************************************************
    private async getCurrentPageFromSettings(event: any = null) {
      const pagerSettings = await firstValueFrom(getGridPager(GridName.Projects));
      if (pagerSettings) {
        if (arraysAreEqual(pagerSettings.filters, event?.filters) && pagerSettings.pageSize === event?.rows) {
          return pagerSettings.pageIndex;
        } 
      }

      return 1;
    }
    
    private extendResults(values: ICvxProject[]): ICvxProject[] {
        values.forEach(value => {
            value.country = value?.location?.addressCountry;
            value.projectTypeRating = value.priorityScoreCard?.projectType;
            value.geographyRating = value.priorityScoreCard?.geography;
            value.developerRating = value.priorityScoreCard?.developer;
            value.fungibilityRating = value.priorityScoreCard?.fungibility;
            value.scaleRating = value.priorityScoreCard?.scale;
            value.economicsRating = value.priorityScoreCard?.economics;
            value.initialScreeningPriority = value.priorityScoreCard?.priority;
            value.priorityWithEconomics = value.priorityScoreCard?.priorityWithEconomics;
        });

      return values;
    }

    private async getPermissions() {
        this.canUpdate = await this.userPermissionService.canUpdateProjects();
        this.canDelete = await this.userPermissionService.canDeleteProjects();

        if (!this.canUpdate) {
          let ndx = this.columns.findIndex((column: any) => column.Name === "Edit");
          if (ndx > -1) {
            this.columns[ndx] = {};
          }
      }
    }

    private buildParameters(event: any, page: number | null): any {

        let parameters: RequestParameters = new RequestParameters();

        parameters.Paging = {
            pageSize: this.MAXRECORDS,
            currentPage: 1
        };

        parameters.SortingOptions = [];
        parameters.Terms = [];

        if (event) {
            this.buildParametersPage(event, page, parameters);
            this.buildParametersRows(event, parameters);
            buildParametersSorts(event, parameters);
            buildParametersFilters(event, parameters);
        }

        return parameters;
    }

    private buildParametersPage(event: any, page: number | null, parameters: any) {
      if ((page && page > 0) && parseInt(event.page) === 1) {
        parameters.Paging.currentPage = page;
      } else if (event.page) {
          parameters.Paging.currentPage = parseInt(event.page);
      }
    }

    private buildParametersRows(event: any, parameters: any) {
        parameters.Paging.pageSize = this.MAXRECORDS;
    }

    private resetVariables() {
        this.data = null;
        this.fullData = null;
        this.dbPage = 0;
        this.dbTotal = 0;
        this.error = "";
    }

    private async loadProjectTypeList(): Promise<void> {
        try {
            await this.projectService.getTypes().then((data: any) => {
                this.projectTypeList = data;
            });
        } catch (error: any) {
            this.projectTypeList = [];
        }
    }

    private async loadProjectStatusList(): Promise<void> {
        try {
            await this.projectService.getStatuses().then((data: any) => {
                this.projectStatusList = data;
                this.projectStatusCategoryList = this.projectService.extractUniqueStatusCategories(this.projectStatusList);
            });
            await this.projectService.getDNPStatuses().then((data: any) => { 
                this.projectDNPStatusList = data;
            });
        } catch (error: any) {
            this.projectStatusList = [];
            this.projectStatusCategoryList = [];
            this.projectDNPStatusList = [];
        }
    }

    private async loadCountryList() {
        try {
            await this.countryService.getCountries().then((data: any) => {
                this.countryList = data;
                if (this.countryList.find(element => element.id == this.globalLocation.id) == undefined) {
                    this.countryList.unshift(this.globalLocation);
                }
            });
        } catch (error: any) {
            this.countryList = [];
        }
    }

    private async loadObjectLists(): Promise<void>{
        try {
            await this.projectService.getProducts().then((data: any) => {
                this.customerProductList = data;
            });
            await this.projectService.getTechnicalReviewRecommendations().then((data: any) => { 
                this.technicalReviewRecommendationList = data;
            });
            await this.projectService.getTechnicalReviewStatuses().then((data: any) => { 
                this.technicalReviewStatusList = data;
            });
            await this.investmentService.getTypes().then((data: any) => this.investmentTypeList = data);
        }
        catch (error: any) {
            this.customerProductList = [];
            this.technicalReviewRecommendationList = [];
            this.technicalReviewStatusList = [];
        }
    }

    private setValue(activeRow: any, key: any) {
        let value = activeRow[key];

        if (key === "status" && activeRow.status) value = activeRow.status.name;
        if (key === "cvxProjectType" && activeRow.cvxProjectType) value = activeRow.cvxProjectType.name;
        if (key === "country" && activeRow.country) value = activeRow.country.name;
       
        return value;
    }

    private setMissingFields(ndx: number) {
        let missingFields: string = "";

        if (this.columns[ndx].FriendlyName && this.columns[ndx].FriendlyName !== '') {
            missingFields += `<br />- ${this.columns[ndx].FriendlyName}`;
        } else if (this.columns[ndx].DisplayName && this.columns[ndx].DisplayName !== '') {
            missingFields += `<br />- ${this.columns[ndx].DisplayName}`;
        } else {
            missingFields += `<br />- ${this.columns[ndx].Name}`;
        }

        return missingFields;
    }

    private async save(rowItem: any) {
        this.isLoading = true;

        if (!rowItem.isGlobalLocation && rowItem?.location?.addressCountry?.alpha3Code != null) {
            rowItem.location.addressCountry = { alpha3Code: rowItem.location.addressCountry.alpha3Code };
        }
        rowItem.firstYearOfIssuance = parseInt(rowItem.firstYearOfIssuance);

        let project = rowItem as unknown as ICvxProject;

        let warning = false;
        try {
            await Promise.all([
                this.projectService.saveProject(project).then(result => {
                    if (result.warnings && result.warnings.length > 0) {
                        this.setMessageEvent.emit({ text: `Project "${project.name}" saved with warning.<br>${result.warnings.join(" ")}`, style: "color: white; background-color: rgb(118, 146, 49); height: 60px;", timer: 20 });
                        warning = true;
                    }
                }),
                this.saveProjectPortfolio(project),
                this.savePriorityScoreCard(project)
            ]).then(async () => {
                await this.loadData(this.currentEvent);

                this.editRowIndex = -1;
                if (!warning) {
                    this.setMessageEvent.emit({ text: `Project "${project.name}" has been saved.`, style: "color: white; background-color: rgb(118, 146, 49);" });
                }
                await this.loadMultiSelectFilters();
            })

        } catch (error: any) {
            this.isLoading = false;
            const ccmsError = new CcmsServerError(error);

            this.app.displayAlert("rror saving Project Data", ccmsError.errors);
        }
    }

    private saveProjectPortfolio(project: ICvxProject) {
        const originalProject = this._viewModel?.find(p => p.id == project.id);
        if (!originalProject) {
            return Promise.reject(new Error(`Cannot find project with ID '${project.id}'`));
        }

        const projectId = project.id;
        const oldPortfolioId = originalProject.projectPortfolioId;
        const newPortfolioId = project.projectPortfolioId;
        if (newPortfolioId == 'NEW') {
            return this.addProjectPortfolio(projectId);
        }
        else if (newPortfolioId) {
            return this.linkProjectPortfolio(projectId, newPortfolioId);
        }
        else if (oldPortfolioId && !newPortfolioId) {
            return this.unlinkProjectPortfolio(oldPortfolioId);
        }
        return Promise.resolve();
    }

    private addProjectPortfolio(projectId: number): Promise<ProjectPortfolioLink>{
        const originalProject = this._viewModel?.find(p => p.id == projectId);
        if (!originalProject) {
            return Promise.reject(new Error(`Cannot find project with ID '${projectId}'`));
        }

		const portfolioService = this.projectPortfolioService;
        const oldPortfolioId = originalProject.projectPortfolioId;
        let result: Observable<ProjectPortfolioLink>;
		if (oldPortfolioId) {
			result = portfolioService.unlinkProjectPortfolio(oldPortfolioId)
				.pipe(
					take(1), 
					mergeMap(() => portfolioService.linkProjectPortfolio(projectId, '')));
		}
		else {
			result = portfolioService.linkProjectPortfolio(projectId, '');
		}
        return lastValueFrom(result);
    }

    private linkProjectPortfolio(projectId: number, newPortfolioId: string): Promise<ProjectPortfolioLink> {
        const originalProject = this._viewModel?.find(p => p.id == projectId);
        if (!originalProject) {
            return Promise.reject(new Error(`Cannot find project with ID '${projectId}'`));
        }

		const portfolioService = this.projectPortfolioService;
        const oldPortfolioId = originalProject.projectPortfolioId;
        let result: Observable<ProjectPortfolioLink>;
        if (oldPortfolioId) {
			if (newPortfolioId == oldPortfolioId) {
                result = of({
                    projectId: projectId,
                    portfolioId: oldPortfolioId
                });
			}
			else {
                result = portfolioService.unlinkProjectPortfolio(oldPortfolioId)
                    .pipe(
                        take(1), 
                        mergeMap(() => portfolioService.linkProjectPortfolio(projectId, newPortfolioId)));
            }
		}
		else {
			result = portfolioService.linkProjectPortfolio(projectId, newPortfolioId);
		}
        return lastValueFrom(result);
    }

    private unlinkProjectPortfolio(portfolioId: string) {
        return lastValueFrom(this.projectPortfolioService.unlinkProjectPortfolio(portfolioId));
    }

    private savePriorityScoreCard(project: ICvxProject) {
        const hasRating = project.projectTypeRating
            ?? project.geographyRating
            ?? project.developerRating
            ?? project.fungibilityRating
            ?? project.scaleRating
            ?? project.economicsRating;
        if (!hasRating) {
            return Promise.resolve();
        }
        const inputModel = {
            projectType: project.projectTypeRating,
            geography: project.geographyRating,
            developer: project.developerRating,
            fungibility: project.fungibilityRating,
            scale: project.scaleRating,
            economics: project.economicsRating
        } as ProjectPriorityScoreCardInputModel;
        return this.projectService.setPriorityScoreCard(project.id, inputModel);
    }

    public setProduct(rowItem: any, event: any) {
        if (event.target.value === "") {
            rowItem.product = {};
        } else {
            rowItem.product = JSON.parse(event.target.value);
        }
    }

    public userSelectionChange(field: string, rowItem: any, event: any) {
        let id: string | null = null;
        let name: string | null = null;

        if (event) {
            const user: UserInfo = event;
            id = user.id;
            name = user.displayName;
        }

        rowItem[`${field}Id`] = id;
        rowItem[field] = name;
    }


    public setInvestmentType(rowItem: any, event: any) {
        if (event.target.value === "") {
            rowItem.investmentType = {};
        } else {
            rowItem.investmentType = JSON.parse(event.target.value);
        }
    }

    public setTechnicalReviewStatus(rowItem: any, event: any) {
        if (event.target.value === "") {
            rowItem.technicalReviewStatus = {};
        } else {
            rowItem.technicalReviewStatus = JSON.parse(event.target.value);
        }
    }

    public setTechnicalReviewRecommendation(rowItem: any, event: any) {
        if (event.target.value === "") {
            rowItem.technicalReviewRecommendation = {};
        } else {
            rowItem.technicalReviewRecommendation = JSON.parse(event.target.value);
        }
    }

    private async loadMultiSelectFilters() {
        try {
            const multiSelectColumns: any[] = this.columns.filter(column => column.FilterMultiSelect);
            this.multiSelectFilters = await this.projectService.getFilters(multiSelectColumns.map(column => column.Name));

            multiSelectColumns?.forEach((multiSelectColumn) => {
                const filter = this.multiSelectFilters.find(filterInfo => filterInfo.fieldName.toLowerCase() === multiSelectColumn.Name.toLowerCase());
                const filterValues = filter?.filterValues;
                multiSelectColumn.FilterValues.length = 0;
                filterValues?.forEach(value => multiSelectColumn.FilterValues.push(value));
            });

        } catch (error: any) {
            console.error("Error loading filters: " + error.message);
        }
    }
}
