import { Component, TemplateRef, ViewChild, ViewContainerRef } from "@angular/core";

import Chart from "chart.js/auto";
import zoomPlugin from 'chartjs-plugin-zoom';

import * as _ from "lodash";

import { Variables } from "~/classes/core/user/variables";
import { ForecastSummaryService } from '~/services/shared/forecast-summary.service';
import { IForecastSummaryModel } from "~/models/shared/common";
import { ProjectChartsFiltersComponent } from "~/pages/projects/components/project-charts/components/filters/project-charts-filters.component";

@Component({
	selector: 'project-credit-charts',
	templateUrl: './credit-charts.component.html',
	styleUrls: ['./credit-charts.component.scss']
})
export class ProjectCreditChartsComponent {
	@ViewChild('template', { static: true }) template: TemplateRef<unknown> | undefined;

	public isLoading: boolean = true;
	public closeOptionsWindow: boolean = false;

	public options = [
		{
			title: "Filters",
			icon: "filter_alt",
			type: <any>ProjectChartsFiltersComponent,
			inputs: {
				values: {
					status: [],
					scope: "",
					region: ""
				}
			},
			outputs: {
				'applyEvent': async (event: any) => {
					this.closeOptionsWindow = true;

					this.setFilters(event);

					Variables.set("Project.Charts.Credits", event);

					await this.loadCharts();

					this.closeOptionsWindow = false;
				},
			}
		}
	];

	private _currentStatus: number[] | undefined = [];
	private _currentScope: string | undefined = "";
	private _currentRegion: string | undefined = "";
	private barChart: any = null;
	private pieChart: any = null;

	private _charts: Chart[] = [];
	private readonly _colors = new Map<string, string>([
		['Agriculture', '#0066B2'],
		['CarbonCaptureAndStorage', 'yellow'],
		['ChemicalProcesses', 'red'],
		['ForestryAndLandUse', 'green'],
		['HouseholdAndCommunity', 'orange'],
		['IndustrialManufacturing', 'purple'],
		['RenewableEnergy', '#347B98'],
		['Transportation', '#092834'],
		['WasteManagement', '#F5FFCC'],
		['Unavailable', '#002C3D'],
		['Africa', 'yellow'],
		['Americas', '#0066B2'],
		['Asia', 'green'],
		['Europe', 'red'],
		['Oceania', 'purple'],
		['Advanced Refrigerants', 'red'],
		['Afforestation/Reforestation', 'green']
	]);

	constructor(
		private forecastSummaryService: ForecastSummaryService,
		private viewContainerRef: ViewContainerRef
	) {
		Chart.register(zoomPlugin);
	}

	public ngOnInit(): void {
		this.viewContainerRef.createEmbeddedView(this.template!);
		this.viewContainerRef.element.nativeElement.remove();
	}

	public async ngAfterViewInit() {
		let options = Variables.get("Project.Charts.Credits");

		if (!options) {
			options = {
				status: [],
				scope: "",
				region: ""
			}
		}

		this.setFilters(options);

		this._charts = await this.initializeCharts();

		await this.loadCharts();
	}

	private destroyCharts() {
		if (this.barChart) this.barChart.destroy();
		if (this.pieChart) this.pieChart.destroy();
	}

	private setFilters(source: any) {
		if (source.status && source.status.length > 0) {
			this._currentStatus = [];

			source.status.forEach((status: any) => {
				this._currentStatus?.push(parseInt(status.id));
			});
		} else {
			this._currentStatus = undefined;
		}
		this._currentScope = source.scope;
		this._currentRegion = source.region;

		this.options.filter((option: any) => option.inputs?.hasOwnProperty('values')).forEach((option: any) => {
			option.inputs.values.status = source.status;
			option.inputs.values.scope = source.scope;
			option.inputs.values.region = source.region;
		});
	}

	private async initializeCharts(): Promise<Array<Chart>> {
		this.destroyCharts();

		let charts: Array<any> = [];
		let combinedPieChartElements = [document.getElementById("creditSumRegion")];
		let combinedBarChartElements = [document.getElementById("creditSumProjectScope")];

		//initialize charts
		combinedPieChartElements.forEach(element => {
			let barCanvas = element as HTMLCanvasElement;
			if (barCanvas == null) {
				return;
			}
			this.pieChart = new Chart(barCanvas, {
				type: 'pie',
				data: {
					datasets: [],
					labels: []
				},
				options: {
					plugins: {
						title: {
							display: true,
							font: {
								weight: 'bold',
								family: "GothamNarrowBold, Arial, sans- serif",
								size: 14
							},
							color: '#212529'
						},
						tooltip: {
							callbacks: {
								label: (context): string => {
									return context.formattedValue;
								}
							}
						},
						legend: {
							position: 'bottom'
						}
					},
				}
			});
			charts.push(this.pieChart);
		});

		combinedBarChartElements.forEach(element => {
			let lineCanvas = element as HTMLCanvasElement;
			if (lineCanvas == null) {
				return;
			}
			this.barChart = new Chart(lineCanvas, {
				type: 'bar',
				data: {
					datasets: [],
					labels: []
				},
				options: {
					maintainAspectRatio: false,
					scales: {
						y:
						{
							ticks: {
								callback: function (v) {
									return v.toLocaleString('en-US');
								}
							},
							stacked: true
						},
						x:
						{
							stacked: true
						}
					},
					interaction: {
						mode: 'index'
					},
					plugins: {
						title: {
							display: true,
							font: {
								weight: 'bold',
								family: "GothamNarrowBold, Arial, sans- serif",
								size: 14
							},
							color: '#212529'
						},
						tooltip: {
							intersect: false,
							callbacks: {
								label: (context): string => {
									if (context.formattedValue === '0') {
										return "";
									}
									const label = context.dataset.label;
									return label + ": " + context.formattedValue;
								},
								footer: (context): string => {
									let total = 0;
									context.forEach(x => {
										let value: any = x.parsed;
										total = total + value.y;
									});
									return 'Total: ' + total.toLocaleString('en-US');
								}
							}
						},
						legend: {
							position: 'bottom'
						}
					}
				}
			});
			charts.push(this.barChart);
		});

		charts.forEach(chart => { chart.options.maintainAspectRatio = false; chart.update(); })

		return charts;
	}

	private async loadCharts() {
		this.isLoading = true;

		await this.loadScope();
		await this.loadRegion();

		this.isLoading = false;
	}

	private async loadScope() {
		let chart: any = document.getElementById('creditSumProjectScope') as HTMLCanvasElement;
		let chartLabel: any = chart.getAttribute("data-chart-label");

		await this.forecastSummaryService.getCreditForecastByScope(this._currentScope, this._currentStatus).then((result: IForecastSummaryModel[]) => {
			this.updateStackedBarChart(result, this._charts.find((c: Chart) => c.canvas.id == 'creditSumProjectScope'), chartLabel);
		});
	}

	private async loadRegion() {
		let chart: any = document.getElementById('creditSumRegion') as HTMLCanvasElement;
		let chartLabel: any = chart.getAttribute("data-chart-label");

		await this.forecastSummaryService.getCreditForecastByRegion(this._currentRegion, this._currentStatus).then((result: IForecastSummaryModel[]) => {
			this.updatePieChart(result, this._charts.find((c: Chart) => c.canvas.id == 'creditSumRegion'), chartLabel);
		});
	}

	private updateStackedBarChart(result: IForecastSummaryModel[], chart?: Chart, dataSetLabel?: string): void {
		if (!chart) {
			return;
		}

		chart.data.datasets = [];
		chart.data.labels = [];
		chart.options.plugins!.title!.text = dataSetLabel;
		chart.update();
		let years = [... new Set(result.map(element => element.year).sort((a, b) => a - b))];
		let dataByScope = _.groupBy(result, (element: IForecastSummaryModel) => element.description);
		let scopes = [... new Set(result.map(element => element.description))];

		chart.data.labels = years;

		scopes.forEach((k) => {
			let values: any[] = [];
			let scopeByYear = _.groupBy(dataByScope[k], (element: IForecastSummaryModel) => element.year);
			years.forEach(year => {
				if (scopeByYear[year] !== undefined) {
					values.push(({ x: year, y: scopeByYear[year].reduce((sum, current) => sum + current.amount, 0) }));
				}
				else {
					values.push(({ x: year, y: 0 }));
				}
			});

			let dataset: any = {
				data: values,
				label: this.separateWords(k),
				backgroundColor: this._colors.has(k) ? this._colors.get(k) : '#009DD9',
			};

			chart.data.datasets.push(dataset);
		});

		chart.update();
	}

	private updatePieChart(result: IForecastSummaryModel[], chart?: Chart, dataSetLabel?: string): void {
		if (!chart) {
			return;
		}
		chart.data.datasets.pop();
		chart.data.labels = [];
		chart.options.plugins!.title!.text = dataSetLabel;
		let keys = [... new Set(result.map(element => element.description))];
		let data = _.groupBy(result, (element: IForecastSummaryModel) => element.description);
		let dataset: any = {
			backgroundColor: [],
			data: []
		};
		keys.forEach((k) => {
			let values = _.uniqWith(data[k], (a, b) => a.year === b.year && a.amount === b.amount);
			dataset.data.push(values.reduce((sum, current) => sum + current.amount, 0));
			if (this._colors.has(k)) {
				dataset.backgroundColor.push(this._colors.get(k));
			}
			else {
				dataset.backgroundColor.push('#009DD9');
			}
		});
		chart.data.labels = keys;
		chart.data.datasets.push(dataset);
		chart.update();
	}

	private separateWords(inputString: string): string {
		return inputString.split(/(?=[A-Z])/).map(word => word.toLowerCase() === "and" ? word.toLowerCase() : word).join(" ");
	}
}