import { Component, EventEmitter, Input, Output } from "@angular/core";
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from "@angular/forms";
import { WsjfScoreCardFormModel } from "./wsjf-score-card-form.model";
import { environment } from '@env/environment';

@Component({
    selector: 'ccms-wsjf-score-card-form',
    templateUrl: './wsjf-score-card-form.component.html',
    styleUrls: ['./wsjf-score-card-form.component.scss']
})
export class WsjfScoreCardFormComponent {
    @Input() form!: WsjfScoreCardFormGroup;
    @Input() isEdit: boolean = false;
    @Input() portfolioId!: number;
    @Output() save = new EventEmitter<{
        wsjfScoreCard: WsjfScoreCardFormModel,
        addNewProject: boolean,
        projectPortfolioId: string
    }>();
    @Output() cancel = new EventEmitter();

    private selectedProjectPortfolio = '';
    private addNewProject = false;

    readonly portfolioAdoBoardBaseurl = environment.portfolioAdoBoardBaseurl;
    
    public onSave() {
        const data = {
            businessValue: this.form.businessValue.value,
            timeCriticality: this.form.timeCriticality.value,
            riskReductionOpportunityEnablementValue: this.form.riskReductionOpportunityEnablementValue.value,
            estimatedImplementationEffort: this.form.estimatedImplementationEffort.value
        } as WsjfScoreCardFormModel;
        let projectPortfolioId = this.selectedProjectPortfolio;
        if (this.addNewProject) {
            projectPortfolioId = '';
        }
        this.save.emit({
            wsjfScoreCard: data,
            projectPortfolioId: projectPortfolioId,
            addNewProject: this.addNewProject
        });
    }

    public onCancel() {
        this.cancel.emit();
    }

    public onUnlinkButtonClicked() {
        this.portfolioId = 0;
        this.selectedProjectPortfolio = '';
    }

    public onPortfolioIdChanged(value: string) {
        console.log(value);
        if (value === 'NEW') {
            this.addNewProject = true;
        }
        else {
            this.addNewProject = false;
            this.selectedProjectPortfolio = value;
        }
    }
}

export class WsjfScoreCardFormGroup extends UntypedFormGroup {
    private readonly _initial: WsjfScoreCardFormModel;

    public readonly businessValue = this.get('businessValue') as UntypedFormControl;
    public readonly timeCriticality = this.get('timeCriticality') as UntypedFormControl;
    public readonly riskReductionOpportunityEnablementValue = this.get('riskReductionOpportunityEnablementValue') as UntypedFormControl;
    public readonly estimatedImplementationEffort = this.get('estimatedImplementationEffort') as UntypedFormControl;
    public readonly wsjfScore = this.get('wsjfScore') as UntypedFormControl;

    constructor(
        readonly model: WsjfScoreCardFormModel | null = null,
        readonly fb: UntypedFormBuilder = new UntypedFormBuilder()
    ) {
        super(fb.group({
            businessValue: [model?.businessValue],
            timeCriticality: [model?.timeCriticality],
            riskReductionOpportunityEnablementValue: [model?.riskReductionOpportunityEnablementValue],
            estimatedImplementationEffort: [model?.estimatedImplementationEffort],
            wsjfScore: [model?.wsjfScore]
        }).controls);
        this._initial = model as WsjfScoreCardFormModel;
    }

    public reset(): void {
        this.businessValue.reset(this._initial?.businessValue);
        this.timeCriticality.reset(this._initial?.timeCriticality);
        this.riskReductionOpportunityEnablementValue.reset(this._initial?.riskReductionOpportunityEnablementValue);
        this.estimatedImplementationEffort.reset(this._initial?.estimatedImplementationEffort);
    }
}
