import { Component, Input } from "@angular/core";
import * as d3 from 'd3'
import { BaseType, json, max, tsv, geoEquirectangular, geoPath, interpolateBlues, rgb, select, Selection, zoom  } from "d3";
import { feature } from "topojson-client";
import { NumberValue, scaleSequential, ScaleSymLog, scaleSymlog } from 'd3-scale';
import { CountryProjectsSummary } from "../developer-location.component";

@Component({
    selector: 'developer-projects-map',
    templateUrl: './developer-projects-map.component.html',
    styleUrls: ['./developer-projects-map.component.scss']
})
export class DeveloperProjectsMapComponent {
    @Input() creditsByCountry: CountryProjectsSummary[] = [];
    public isLoading = true;
    public error: any;

    public async ngAfterViewInit() {
        const mapData: any = await this.loadMapData();
        if (!this.error) {
            let num: any = max(mapData.features, (d: any) => { return Number(d['properties']['creditsIssued']) });
            let maxProjects: number = Math.round(num / 1000) * 1000;
            let scaleProjects = scaleSymlog().domain([0.00001, maxProjects]).range([0, 1]);

            let svg: any = select('svg#mapCanvas');
            let projection = geoEquirectangular();
            let pathGenerator = geoPath().projection(projection);

            this.addColorgLegend(svg, maxProjects);

            let g: any = svg.append('g');
            const zoomBehavior = zoom()
              .scaleExtent([1, Infinity])  // set the scale extent
              .on('zoom', (event) => {
                g.attr('transform', event.transform);
              });
            svg.call(zoomBehavior);

            g.selectAll('path')
                .data(mapData.features)
                .enter()
                .append('path')
                .attr('class', 'country')
                .attr('d', pathGenerator)
                .attr('fill', (d: any) => this.getColor(scaleProjects, d))
                .append('title')
                .text((d: any) => this.getToolTip(d));
        }

        this.isLoading = false;
    }

    private async loadMapData() {
        return Promise.all([
            tsv('https://unpkg.com/world-atlas@1.1.4/world/50m.tsv'),
            json('https://unpkg.com/world-atlas@1.1.4/world/50m.json')
        ]).then(([tsvData, geoData]): any => {
            if (this.error) {
                return false;
            }
            return this.parseMapData(tsvData, geoData);
        });
    }

    private async parseMapData(tsvData: any, geoData: any) {
        const summaryByCountryId = tsvData.reduce((acc: any, d: any) => {
            const countrySummary = this.creditsByCountry.find((summary) => {
                return d.name === this.parseCountryName(summary.country);
            });

            acc[Number(d.iso_n3)] = {
                name: d.name,
                creditsIssued: countrySummary?.creditsIssued ?? 0,
                creditsRetired: countrySummary?.creditsRetired ?? 0,
                creditsRemaining: countrySummary?.creditsRemaining ?? 0,
                projects: countrySummary?.projects ?? []
            }
            return acc;
        }, {});
        let countries: any = feature(geoData, geoData.objects.countries);
        countries.features.forEach((d: any) => {
            const countryId = Number(d.id);
            const credits = summaryByCountryId[countryId];
            Object.assign(d.properties, credits || {});
        });
        return countries;
    }

    private parseCountryName(country: string): string {
        const countryLower = country.toLowerCase();
        if (countryLower === 'viet nam') {
            return 'Vietnam';
        }
        if (countryLower === 'united states of america') {
            return 'United States';
        }
        return country;
    }

    private addColorgLegend(svg: Selection<BaseType, any, HTMLElement, any>, maxProjects: NumberValue) {
        let colorLegendId: string = "colorlegend";

        svg.append('g')
            .attr('transform', `translate(20, 135)`)
            .attr('id', colorLegendId);

        let legendProps = this.initializeMapLegend();
        let legend: any = this.colorLegend(scaleSequential([0, maxProjects], interpolateBlues), legendProps);

        let svg2: any = document.querySelector(`#${colorLegendId}`);
        svg2.appendChild(legend);
    }
    private initializeMapLegend() {
        const result: any = {
            title: "Credits",
            tickSize: 6,
            height: 320,
            marginTop: 10,
            marginBottom: 20,
            marginLeft: 5,
            tickFormat: null,
            tickValues: null
        };
        result.width = 36 + result.tickSize;
        result.marginRight = 10 + result.tickSize;
        result.ticks = result.height / 64;
        return result;
    }

    private colorLegend(color: any, props: any): SVGSVGElement {
        const svg: any = d3.create("svg")
            .attr("width", props.width)
            .attr("height", props.height)
            .attr("viewBox", [0, 0, props.width, props.height])
            .style("overflow", "visible")
            .style("display", "block");

        let tickAdjust = (g: any) => g.selectAll(".tick line").attr("x1", props.marginLeft - props.width + props.marginRight);
        let x;

        // Continuous
        if (color.interpolate) {
            const n = Math.min(color.domain().length, color.range().length);

            x = color.copy().rangeRound(d3.quantize(d3.interpolate(props.height - props.marginBottom, props.marginTop), n));

            svg.append("image")
                .attr("x", props.marginLeft)
                .attr("y", props.marginTop)
                .attr("width", props.width - props.marginLeft - props.marginRight)
                .attr("height", props.height - props.marginTop - props.marginBottom)
                .attr("preserveAspectRatio", "none")
                .attr("xlink:href", this.ramp(color.copy().domain(d3.quantize(d3.interpolate(0, 1), n))).toDataURL());
        }

        // Sequential
        else if (color.interpolator) {
            x = this.colorSequential(color, svg, props);
        }

        // Threshold
        else if (color.invertExtent) {
            x = this.colorThreshold(color, svg, props);
        }

        // Ordinal
        else {
            x = d3.scaleBand()
                .domain(color.domain())
                .rangeRound([props.height - props.marginBottom, props.marginTop]);

            svg.append("g")
                .selectAll("rect")
                .data(color.domain())
                .join("rect")
                .attr("y", x)
                .attr("x", props.marginLeft)
                .attr("height", Math.max(0, x.bandwidth() - 1))
                .attr("width", props.width - props.marginLeft - props.marginRight)
                .attr("fill", color);

            tickAdjust = () => { };
        }

        svg.append("g")
            .attr("transform", `translate(${props.width - props.marginRight},0)`)
            .call(d3.axisRight(x)
                .ticks(props.ticks, typeof props.tickFormat === "string" ? props.tickFormat : null)
                .tickFormat(typeof props.tickFormat === "function" ? props.tickFormat : null)
                .tickSize(props.tickSize)
                .tickValues(props.tickValues)
            )
            .call(tickAdjust)
            .call((g: any) => g.select(".domain").remove())
            .call((g: any) => g.append("text")
                .attr("x", props.marginLeft - props.width + props.marginRight)
                .attr("y", 0)
                .attr("fill", "currentColor")
                .attr("text-anchor", "start")
                .attr("font-weight", "bold")
                .attr("color", "black")
                .text(props.title));

        return svg.node();
    }

    private ramp(color: any, n = 256): HTMLCanvasElement {
        const canvas = document.createElement("canvas");
        canvas.width = 1;
        canvas.height = n;
        const context: any = canvas.getContext("2d");
        for (let i = 0; i < n; ++i) {
            context.fillStyle = color(i / (n - 1));
            context.fillRect(0, n - i, 1, 1);
        }
        return canvas;
    }

    private getToolTip(d: any): string {
        const props = d.properties;

        if (props.projects.length > 0) {
            return d.properties.name + " (" + Number(props.projects.length).toLocaleString() + "):\n"
                + " - issued: " + Number(props.creditsIssued).toLocaleString() + "\n"
                + " - retired: " + Number(props.creditsRetired).toLocaleString() + "\n"
                + " - remaining: " + Number(props.creditsRemaining).toLocaleString();
        }

        return props.name;
    }

    private colorThreshold(color: any, svg: any, props: any) {
        const quantiles = color.quantiles ? color.quantiles() : color.domain();
        const thresholds = color.thresholds ? color.thresholds() : quantiles;

        const tickFormatLogic = typeof props.tickFormat === "string" ? d3.format(props.tickFormat) : props.tickFormat;
        const thresholdFormat = props.tickFormat === undefined ? (d: any) => d : tickFormatLogic;

        let x = d3.scaleLinear()
            .domain([-1, color.range().length - 1])
            .rangeRound([props.height - props.marginBottom, props.marginTop]);

        svg.append("g")
            .selectAll("rect")
            .data(color.range())
            .join("rect")
            .attr("y", (_d: any, i: any) => x(i))
            .attr("x", props.marginLeft)
            .attr("height", (_d: any, i: any) => x(i - 1) - x(i))
            .attr("width", props.width - props.marginRight - props.marginLeft)
            .attr("fill", "red");

        props.tickValues = d3.range(thresholds.length);
        props.tickFormat = (i: any) => thresholdFormat(thresholds[i], i);

        return x;
    }

    private colorSequential(color: any, svg: any, props: any) {
        let x = Object.assign(color.copy().interpolator(d3.interpolateRound(props.height - props.marginBottom, props.marginTop)),
            {
                range() { return [props.height - props.marginBottom, props.marginTop]; }
            });

        svg.append("image")
            .attr("x", props.marginLeft)
            .attr("y", props.marginTop)
            .attr("width", props.width - props.marginLeft - props.marginRight)
            .attr("height", props.height - props.marginTop - props.marginBottom)
            .attr("preserveAspectRatio", "none")
            .attr("xlink:href", this.ramp(color.interpolator()).toDataURL());

        // scaleSequentialQuantile doesn’t implement ticks or tickFormat.
        if (!x.ticks) {
            if (props.tickValues === undefined) {
                const n = Math.round(props.ticks + 1);
                props.tickValues = d3.range(n).map(i => d3.quantile(color.domain(), i / (n - 1)));
            }

            if (typeof props.tickFormat !== "function") {
                props.tickFormat = d3.format(props.tickFormat === undefined ? ",f" : props.tickFormat);
            }
        }

        return x;
    }

    private getColor(scaleProjects: ScaleSymLog<number, number, never>, d: any) {
        const projectCount = d.properties.projects.length;
        const creditsIssued: number = Number(d['properties']['creditsIssued']);
        if (projectCount > 0) {
            return interpolateBlues(scaleProjects(creditsIssued));
        }

        return rgb(224, 224, 224).toString();
    }
}