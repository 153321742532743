import { Component } from '@angular/core';


import { BaseComponent } from '~/pages/base.component';
import { FormatterService } from '~/services/shared/formatter.service';
import { AbuInventoryService } from '~/services/shared/inventory/abu-inventory-service';
import { InventorySummaryDto } from '~/services/shared/inventory/models/inventory-summary-dto';
import { AbuInventorySearchOptions } from '~/services/shared/inventory/models/abu-Inventory-search-options';
import { TransactionInfo } from './chart/Model/TransactionInfo';

@Component({
    selector: 'abu-overview',
    templateUrl: './abu-overview.component.html',
    styleUrls: ['./abu-overview.component.scss']
})

export class AbuOverviewComponent extends BaseComponent {
    public footnote: string = "";
    public MAXRECORDS: number = 1000;

    
    public totalAcquired: number = 0;
    public totalTransferred: number = 0;
    public totalBalance: number = 0;

    public transactionData: any;

    constructor(
        private abuInventoryService: AbuInventoryService,
        private formatterService: FormatterService
    ) {
        super();
    }

    //*************************************************************************
    //  Component Life-Cycle Methods
    //*************************************************************************
    public async ngOnInit() {
        await super.ngOnInit();

        await this.getData();
    }

    //*************************************************************************
    //  Public Methods
    //*************************************************************************


    //*************************************************************************
    //  Private Methods
    //*************************************************************************
    private async getData() {
        try {
            const summaryResult = await this.abuInventoryService.getSummary();
            if (summaryResult) {
                if (summaryResult.accountSummaries && summaryResult.accountSummaries.length > 0) {
                    this.updateTotals(summaryResult);
                    await this.getTransactionData(summaryResult);
                }
            }

            this.footnote = `Inventory as of ${this.formatterService.formatDate(new Date(), "dd MMM yyyy hh:mm:ss (z)")}`;
        } catch (error: any) {
        }
    }

    private updateTotals(summaryResult: InventorySummaryDto) {
        this.totalAcquired = summaryResult.accountSummaries.reduce((prev, next) => prev + next.acquired, 0);
        this.totalTransferred = summaryResult.accountSummaries.reduce((prev, next) => prev + next.transferred, 0);
        this.totalBalance = this.totalAcquired - this.totalTransferred;

    }

    private async getTransactionData(summaryResult: InventorySummaryDto) {
        const accountNumbers = summaryResult.accountSummaries.map(summary => summary.accountNumber);

        try {
            const acquired = await this.getTransactions(accountNumbers, "acquiringAccountNumber", "acquired");
            const transferred = await this.getTransactions(accountNumbers, "transferringAccountNumber", "transferred");

            let allData: TransactionInfo[] = [];
            acquired.forEach(a => allData.push(a));
            transferred.forEach(t => allData.push(t));

            this.transactionData = allData;
        } catch (error: any) {
            console.error(error.message);
        } 
    }

    private async getTransactions(accountNumbers: string[], fieldName: string, transactionType: string): Promise<TransactionInfo[]> {
        const queryOptions: AbuInventorySearchOptions = {
            filters: [
                {
                    field: fieldName,
                    operation: "Equals",
                    values: accountNumbers
                }
            ],
            skip: 0,
            take: 1000,
            sortOptions: [{
               property: "transactionCompletionDate",
               isDescending: true
            }]
        };

        const summary = await this.abuInventoryService.search(queryOptions);
        
        let summaryItems: TransactionInfo[] = [];

        summary.values.forEach(transaction => {
            const transactionInfo = new TransactionInfo();
            transactionInfo.transactionCompletionDate = transaction.transactionCompletionDate;
            transactionInfo.transactionType = transactionType
            transactionInfo.quantity = transaction.quantity;

            summaryItems.push(transactionInfo);
        });

        return summaryItems;
    }
}