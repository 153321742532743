import { DurableTaskStatusResponse } from "~/models/shared/durable-task/durable-task-status-response.model";

export class MetaRegistryExportCommon {

  static hasExportExpired(event: DurableTaskStatusResponse): boolean {
    // Define the date to check
    const dateToCheck = new Date(event.lastUpdatedTime);

    const oneDayAhead = new Date(dateToCheck.getTime() + 24 + 60 * 60 * 1000);

    // Compare the current time to the calculated time
    return new Date() >= oneDayAhead;
  }
}