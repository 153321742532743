import { Injectable } from "@angular/core";
import { Observable, from } from "rxjs";
import { HttpService } from "~/services/core/http.service";
import { WsjfSummary } from "~/services/shared/kpi/models/wsjf-summary.model";

@Injectable()
export class KpiService {
    constructor(
        private readonly httpService: HttpService
    ) {
        // Intentionally blank
    }

    public getWsjfSummary(projectId: number): Observable<WsjfSummary> {
        const requestUri = `/kpi/wsjf-summary/${projectId}`;
        return from(this.httpService.Get(requestUri));
    }
}