import { createStore } from "@ngneat/elf";
import { selectAllEntities, updateEntities, withEntities } from "@ngneat/elf-entities";
import { localStorageStrategy, persistState } from "@ngneat/elf-persist-state";
import { map, Observable } from "rxjs";
import { TodoItemFlatNode } from "~/components/shared/tree-checklist/tree-checklist.component";
import { PolicyTree } from "~/services/shared/policy-tracker/tree.constants";
import { PolicyTrackerMapState } from "./policy-tracker-map.state";


export const DEFAULT_MAP = "default";

const defaultFacets = [
  new TodoItemFlatNode(PolicyTree.ROOT_MARKET_TYPE, 1),
  new TodoItemFlatNode(PolicyTree.LEAF_MARKET_ETS, 1),
  new TodoItemFlatNode(PolicyTree.LEAF_MARKET_TAX, 1),
  new TodoItemFlatNode(PolicyTree.LEAF_MARKET_CREDITS, 1),
  new TodoItemFlatNode(PolicyTree.ROOT_MARKET_STATUS, 1),
  new TodoItemFlatNode(PolicyTree.LEAF_STATUS_INFORCE, 1),
  new TodoItemFlatNode(PolicyTree.LEAF_STATUS_UNDER_CONSIDERATION, 1),
  new TodoItemFlatNode(PolicyTree.LEAF_STATUS_UNDER_DEVELOPMENT, 1),
  new TodoItemFlatNode(PolicyTree.ROOT_JURISDICTION_TYPE, 1),
  new TodoItemFlatNode(PolicyTree.LEAF_JURISDICTION_NATIONAL, 1),
  new TodoItemFlatNode(PolicyTree.LEAF_JURISDICTION_SUBNATIONAL, 1),
  new TodoItemFlatNode(PolicyTree.LEAF_JURISDICTION_REGIONAL, 1),
  new TodoItemFlatNode(PolicyTree.ROOT_ARTICLE6)
];

const initialMap : PolicyTrackerMapState[] = [
  {id: DEFAULT_MAP, defaultFacets: defaultFacets, selectedFacets: [], nodeExpansion: []}
]

const store = createStore(
  { name: 'policy-tracker-map' },
  withEntities<PolicyTrackerMapState>({initialValue: initialMap})
);

export const persist = persistState(store, 
  {  
    storage: localStorageStrategy
  },
);

const mapFacets$ = store.pipe(selectAllEntities());

export function getMapFacets(id: PolicyTrackerMapState['id'] = DEFAULT_MAP): Observable<PolicyTrackerMapState> {
  return mapFacets$.pipe(
    map(chart => { 
      return chart.find(chart => chart.id == id)!;
    })
  );
}

export function updateMapFacets(facets: TodoItemFlatNode[], id: PolicyTrackerMapState['id'] = DEFAULT_MAP) {
  store.update(
    updateEntities(id, (entity) => ({
      ...entity,
      selectedFacets: facets
    }))
  );
}

export function updateExpansionNodes(nodeExpansion: TodoItemFlatNode[], id: PolicyTrackerMapState['id'] = DEFAULT_MAP) {
  store.update(
    updateEntities(id, (entity) => ({
      ...entity,
      nodeExpansion: nodeExpansion
    }))
  );
}
