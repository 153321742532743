export class ProjectScopeSummary {
    private readonly _scope: string;
    public get scope() {
        return this._scope;
    }
    public _registries: Record<string, number> = {};
    public get registries() {
        return this._registries;
    }
    public get total() {
        return Object.values(this._registries).reduce((a, b) => a + b, 0);
    };

    constructor(scope: string, registries: Record<string, number>) {
        this._scope = scope;
        this._registries = registries ?? {};
    }
}