import { OnInit, AfterViewInit, ViewChild, Component} from '@angular/core';
import { ISearchResultViewModel } from './models/search-result-viewmodel-interface';
import { SearchResult } from '~/models/shared/search/search-result';
import { SearchResultViewModel } from './models/search-result-viewmodel';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { KeywordSearchService } from '~/services/shared/keyword-search.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Sort } from '@angular/material/sort';
import { GridPager } from '~/models/shared/search/grid-pager';

@Component({
    templateUrl: './search-results.page.html',
    styleUrls: ['./search-results.page.scss']
})
export class SearchResultsPage implements OnInit, AfterViewInit {    
    @ViewChild('paginator') paginator!: MatPaginator;            
    
    searchResultList: MatTableDataSource<ISearchResultViewModel> = new MatTableDataSource();
    columnsToDisplay: string[] = ["entityType", "id"];
    gridPager: GridPager = new GridPager()
    isLoading: boolean = false;
    hasResults: boolean = true;
    term!: string;

    public callbackState: any;

    constructor(
        private readonly _searchService: KeywordSearchService,
        private readonly _router: Router,
        private readonly _route: ActivatedRoute
    ) { 
        // Intentionally blank
    }

    ngOnInit(): void {
        this._route.queryParamMap.subscribe(parameters => {
            this.isLoading = true;
            let searchTerms = parameters.get("text");
            if (searchTerms) {
              this.term = searchTerms;
              this._searchService.findByKeywords(this.term)
                .subscribe({
                  complete: () => {
                      this.isLoading = false;
                  },
                  next: (results: SearchResult[]) => { 
                    if (results && results.length > 0) {
                      this.hasResults = true;
                    } else {
                      this.hasResults = false;
                    }
                    this.searchResultList.data = results.map((item: SearchResult) => new SearchResultViewModel(item, this._router.config)) as ISearchResultViewModel[];
                  
                    this.callbackState = { callback: '/search', callbackLabel: 'Back to Search', callbackParams: { 'text': this.term } };

                    // Reset the paginator after setting new data
                    if (this.paginator) {
                      this.paginator.firstPage(); // This resets the paginator to the first page
                    }
                  },
                  error: (e) => this.isLoading = false
              });
            } else {
              this.isLoading = false;
            }
        });
    }

    ngAfterViewInit(): void {
        this.searchResultList.paginator = this.paginator;
    }

    onChange(entityType: string) {
        this.searchResultList.filter = entityType;
    }

    async sortChange(sortState: Sort){
        alert(sortState.direction);
    }

    async pageData(event?: PageEvent) {
        if (event) {
            this.gridPager.pageSize = event.pageSize;
        }
    }
}
