import { Component } from '@angular/core';
import { SDKDataGridDataset, SDKDataGridOptions, SDKDataGridColumn } from 'sdk-datagrid';
import { BaseComponent } from '~/pages/base.component';
import { FormatterService } from '~/services/shared/formatter.service';
import { from, map, Observable } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { SupplyTradingInventoryService } from '~/services/shared/inventory/supplytrading-inventory.service';
import { SupplyTradingInventoryAccountSummary } from '~/services/shared/inventory/models/supplytrading-inventory-account-summary';
import { RequestParameters } from '~/models/shared/request-parameters';

@Component({
    selector: 'supplytrading-summary',
    templateUrl: './supplytrading-summary.component.html',
    styleUrls: ['./supplytrading-summary.component.scss']
})

export class SupplyTradingSummaryComponent extends BaseComponent {
    public footnote: string = "";
    public MAXRECORDS: number = 1000;
    public activeView: string = "SupplyTradingInventory/Summary";
    public datasets: SDKDataGridDataset[] = [
        {
            Title: "Inventory",
            DbName: "SupplyTradingInventory/Summary",
        }
    ];
    public data: string = "";
    public columns: SDKDataGridColumn[] = [
        { Name: "accountName", DisplayName: "Account Name", isVisible: true },
        { Name: "purchased", DisplayName: "Purchased", isVisible: true, formatter: (value: any) => this.formatterService.formatNumber(value) },
        { Name: "sold", DisplayName: "Sold", isVisible: true, formatter: (value: any) => this.formatterService.formatNumber(value) },
        { Name: "balance", DisplayName: "Balance", isVisible: true, formatter: (value: any) => this.formatterService.formatNumber(value) }
    ];

    public uniqueIdentifier = "inventory.supplytrading.summary";

    public gridOptions: SDKDataGridOptions = {
          settings: false,
		  columnSettings: false,
		  filtering: false,
		  sorting: false,
		  formulas: false,
		  charts: false,
		  export: true,
		  expandableRows: true,
		  selectableRows: false,
          autoClosePanel: true
    };

    public fullData: string = "";
    public dbPage: number = 0;
    public dbTotal: number = 0;
    public error: string = "";

    public totalPurchased: number = 0;
    public totalSold: number = 0;
    public totalBalance: number = 0;

    public transactionMap: Map<string, Observable<any[]>> = new Map<string, Observable<any[]>>();
    public detailTransactionsCount = 200;

    public dateFormat = 'MM/dd/yyyy';
    public numberFormat = '1.0-5';
    private activeAccount = "";

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private formatterService: FormatterService,
        private inventoryService: SupplyTradingInventoryService,
    ) {
        super();
    }

    //*************************************************************************
    //  Component Life-Cycle Methods
    //*************************************************************************
    public async ngOnInit() {
        await super.ngOnInit();

        await this.getTotals();
        await this.subscribeToRouteParams();
    }

    //*************************************************************************
    //  Public Methods
    //*************************************************************************
    public async loadData(event: any = null) {
        this.isLoading = true;

        try {
            this.MAXRECORDS = event.rows;

            this.resetVariables();
            const summaryResult = await this.inventoryService.getSummary();
            if (summaryResult) {
                let summaries = JSON.parse(JSON.stringify(summaryResult.accountSummaries));
                if (this.activeAccount && this.activeAccount !== '') {
                    summaries.forEach((summary) => {
                        if (summary.accountName && summary.accountName === this.activeAccount) {
                            summary["Expanded"] = true;
                        }
                    });
                }
                this.data = summaries;
                this.dbPage = 1;
                this.dbTotal = summaryResult.count;
                this.error = "";
                this.footnote = `Inventory as of ${this.formatterService.formatDate(new Date(), "dd MMM yyyy hh:mm:ss (z)")}`;

                if (summaryResult.accountSummaries && summaryResult.accountSummaries.length > 0) {
                    this.initializeTransactionsMap(summaryResult.accountSummaries);
                }
            }
        } catch (error: any) {
            this.error = error.message;
        }

        this.isLoading = false;
    }

    public getAcccountTransactions(accountName: string): Observable<any[]> {
        const found = this.transactionMap.get(accountName);
        return found ? found : from([]);
    }

    public getSerialRange(transaction: any): string {
        return `${transaction.serialRangeStart} - ${transaction.serialRangeEnd}`;
    }

    public gotoProject(accountName: string, transaction: any) {
        if (transaction?.metaRegistryProjectId) {
            this.router.navigate(["/metaregistry/detail"], { state: { callback: '/inventory', callbackParams: { 'tab': 'Summary', 'parentTab': 'Supply & Trading', 'activeAccount': accountName }, callbackLabel: 'Back to Supply & Trading Inventory', projectId: transaction.metaRegistryProjectId } });
        }
    }
    //*************************************************************************
    //  Private Methods
    //*************************************************************************
    private async subscribeToRouteParams() {
        this.route.queryParams.subscribe((params: any) => {
            if (params?.activeAccount) {
                this.activeAccount = params?.activeAccount;
            }
        });
    }

    private async getTotals() {
        try {
            await this.inventoryService.getTotals().then((data: any) => {
                if (data) {
                    this.totalPurchased = data.totalCreditPurchases ?? 0;
                    this.totalSold = data.totalCreditSales ?? 0;
                    this.totalBalance = (data.totalCreditPurchases && data.totalCreditSales) ? data.totalCreditPurchases - data.totalCreditSales : 0;
                }
            });

            this.footnote = `Inventory as of ${this.formatterService.formatDate(new Date(), "dd MMM yyyy hh:mm:ss (z)")}`;
        } catch (error: any) {
        }
    }

    private resetVariables() {
        this.data = "";
        this.fullData = "";
        this.dbPage = 0;
        this.dbTotal = 0;
        this.error = "";
    }

    private initializeTransactionsMap(accountSummaries: SupplyTradingInventoryAccountSummary[]) {
        accountSummaries.forEach(summary => {
            if (summary) {
                const queryOptions: RequestParameters = {
                    Terms: [
                        {
                            Name: "cvxCompany",
                            Field: "cvxCompany",
                            Value: summary.accountName,
                            MultiSelectValues: [],
                            Operation: "Equals"
                        }
                    ],
                    Paging: {
                        pageSize: this.detailTransactionsCount, // limit how many we load,
                        currentPage: 1
                    },
                    SortingOptions: [
                        {
                            field: "transactionDate",
                            direction: 1,
                            priority: 1
                        }
                    ],
                    EntityIds: [],
                    ActionFilter: ""
                };


                let trnsactionObservable$ = from(this.inventoryService.getInventory(queryOptions))
                    .pipe(
                        map(result => result.values)
                    );
                this.transactionMap.set(summary.accountName, trnsactionObservable$);
            }
        });
    }
}