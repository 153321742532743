import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
    selector: 'radio-button',
    templateUrl: './radio-button.component.html',
    styleUrls: ['./radio-button.component.scss']
})

export class RadioButtonComponent {
    @Input() name: string = ""; // Name of field.
    @Input() value: any = null; // Current value of field.
    @Input() isRequired: boolean = false; // If field is required.
    @Input() type: any = "boolean"; // Value type: boolean/number
    @Output() changeEvent: EventEmitter<any> = new EventEmitter();

    public setChange(event: any) {
        this.changeEvent.emit({ field: this.name, value: event.target.value });
    }
}