import { Component, EventEmitter, Inject, Output } from '@angular/core';
import { Article6MemoViewModel } from './article6-memo.viewmodel';
import { Article6Mou } from '~/models/shared/policy-tracker/article6-mou';
import { ICountry } from '~/models/shared/location';

@Component({
  selector: 'ccms-article6-dialog',
  templateUrl: './article6-dialog.component.html',
  styleUrls: ['./article6-dialog.component.scss']
})
export class Article6DialogComponent {
  @Output() onClose = new EventEmitter<void>();

  constructor(
    @Inject('ARTICLE6_DATA') public mous: Article6Mou[],
    @Inject('COUNTRY_DATA') public country: ICountry) 
  { 
    mous.forEach(item => {
      if (item.mouCountry) {
        this.memos.push(new Article6MemoViewModel(item, country));
      }
    });
  }

  public memos: Article6MemoViewModel[] = [];

  close(): void {
    this.onClose.emit();
  } 
}
