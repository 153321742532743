import { NumberType } from "~/classes/shared/type-alias";
import { ICreditForecast } from "~/models/shared/common";

export class CreditForecastViewModel {
  private readonly _context: ICreditForecast;
 
  constructor(context: ICreditForecast) {
    this._context = context;
  }

  public getCreditForecast(): ICreditForecast {
    return this._context;
  }

  public get projectId(): NumberType {
    return this._context?.cvxProjectId;
  }

  public get year(): number {
    return this._context?.year;
  }

  public get pricePerTonne(): NumberType {
    return this._context?.pricePerTonne;
  }

  public get credits(): NumberType {
    return this._context?.credits;
  }

  public get fundingAmount(): NumberType {
    let fundingAmount = 0;
    
    if (this._context && this.discretionarySpend) {
      fundingAmount += this._context?.discretionarySpend ?? 0;
    }
    if (this._context && this.nonDiscretionarySpend) {
      fundingAmount += this._context?.nonDiscretionarySpend ?? 0;
    }
  
    if (fundingAmount === 0) {
      return null;
    }

    return fundingAmount;
  }

  public get prepayment(): NumberType {
    return this._context?.prepayment;
  }

  public get opEx(): NumberType {
    return this._context?.opEx;
  }

  public get priceFloor(): NumberType {
    return this._context?.priceFloor;
  }

  public get discountRate(): NumberType {
    return this._context?.discountRate;
  }

  public get discretionarySpend(): NumberType {
    return this._context?.discretionarySpend;
  }

  public get nonDiscretionarySpend(): NumberType {
    return this._context?.nonDiscretionarySpend;
  }

  public get nonObligatedCredits(): NumberType {
    return this._context?.nonObligatedCredits;
  }

  public get rowId() : string {
    if (this.tempId) {
      return this.tempId;
    }
    
    return `${this.projectId}-${this.year}`;
  } 
  
  public set rowId(value: string) {
    this.tempId = value;
  }

  private tempId: string | undefined;

  public static getNewCreditForecastModel(forecasts: CreditForecastViewModel[], latestYear: number, year?: number): CreditForecastViewModel {
		const newInner = {} as ICreditForecast;
		if (!year) {
			newInner.year = latestYear;
		} else {
			newInner.year = year;
		}
		const newRow = new CreditForecastViewModel(newInner);
		let index: number = forecasts.length + 1;
		newRow.rowId = `new-${index}`;

		return newRow;
	}
}