import { Injectable } from "@angular/core";
import { HttpService } from "~/services/core/http.service";
import { from, Observable } from "rxjs";
import { UserInfo } from "~/models/shared/users/user-info";

@Injectable()
export class UserService {

    private _baseServiceUri = "/users";

    constructor(private readonly httpService: HttpService) {
    }

    public GetUserSelections(entity: string, field: string): Observable<UserInfo[]> {
        const requestUrl = `${this._baseServiceUri}/selection?entity=${entity}&field=${field}`
        return from(this.httpService.Get(requestUrl))
    }

}