import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { moveItemInArray } from '@angular/cdk/drag-drop';

import { SDKDataGridColumn } from '../../models/datagrid-column';
import { FilterType, SDKDataGridCustomFilter } from '../../models/datagrid-filter';

import { Utils } from '../../classes/random';
import { Filters } from '../../classes/filters';

@Component({
    selector: 'sdk-datagrid-filter-option',
    templateUrl: './filter-option.component.html',
    styleUrls: ['./filter-option.component.scss']
})

export class SDKDataGridFilterOptionComponent implements OnChanges {
    @Input() columns: SDKDataGridColumn[] = [];
    @Input() filters: any[] = [];
    @Input() filterTypes: Filters[] = [];
    @Input() customFilters: SDKDataGridCustomFilter[] = [];
    @Output() closeEvent = new EventEmitter();
    @Output() applyEvent = new EventEmitter<SDKDataGridColumn[]>();

    public columnList: SDKDataGridColumn[] = [];
    public filterList: any[] = [];

    public ngOnChanges(_args: any) {
        this.initialze();
    }

    public initialze() {
        this.filterList = [];

        this.customFilters.forEach((filter: any) => {
            if (filter.Type === FilterType.Dropdown && (!filter.FilterTypes || filter.FilterTypes.length === 0)) {
                filter.FilterTypes = [Filters.Contains, Filters.Empty, Filters.Equals, Filters.GreaterThan, Filters.GreaterThanOrEqual, Filters.InList, Filters.LessThan, Filters.LessThanOrEqual, Filters.NotContains, Filters.NotEmpty, Filters.NotEquals];
            }
            if (filter.FilterMultiSelect && filter.FilterValues && filter.FilterValues.length > 0) {
                filter.FilterTypes = [Filters.Equals, Filters.NotEquals];
            }

            let id = 0;
            let operation = filter.FilterTypes ? filter.FilterTypes[0] : "";
            let value = !filter.FilterMultiSelect ? "" : [];
            let ndx = this.filters.findIndex((f: any) => f.Type === "Custom" && f.Name === filter.Name);

            if (ndx > -1) {
                id = this.filters[ndx].ID;
                operation = this.filters[ndx].Operation;
                value = this.filters[ndx].Value;
            }

            this.filterList.push({
                Type: "Custom",
                ID: id,
                Name: filter.Name,
                DisplayName: filter.DisplayName,
                Operation: operation,
                ValueType: filter.Type,
                ValueList: filter.FilterValues,
                MultiSelect: filter.FilterMultiSelect,
                Value: value
            });
        });

        this.columns.filter(c => c.isVisible && c.showFilter && !c.actionTemplate).forEach((column: any) => {
            if (!column.FilterTypes || column.FilterTypes.length === 0) {
                column.FilterTypes = [Filters.Contains, Filters.Empty, Filters.Equals, Filters.GreaterThan, Filters.GreaterThanOrEqual, Filters.InList, Filters.LessThan, Filters.LessThanOrEqual, Filters.NotContains, Filters.NotEmpty, Filters.NotEquals];
            }
            if (column.FilterMultiSelect && column.FilterValues && column.FilterValues.length > 0) {
                column.FilterTypes = [Filters.Equals, Filters.NotEquals];
            }

            let id = 0;
            let operation = (column.FilterTypes && column.FilterTypes.length === 1) ? column.FilterTypes[0] : (column.FilterValues && column.FilterTypes) ? column.FilterTypes[0] : "Contains";
            let value = !column.FilterMultiSelect ? "" : [];
            let ndx = this.filters.findIndex((filter: any) => filter.Type === "System" && filter.Name === column.Name);

            if (ndx > -1) {
                id = this.filters[ndx].ID;
                operation = this.filters[ndx].Operation;
                value = this.filters[ndx].Value;
            }

            this.filterList.push({
                Type: "System",
                ID: id,
                Name: column.Name,
                DisplayName: column.FriendlyName === "" ? this.getColumnOriginalName(column) : column.FriendlyName,
                Operation: operation,
                ValueType: column.FilterValues ? "list" : "text",
                ValueList: column.FilterValues,
                MultiSelect: column.FilterMultiSelect,
                Value: value
            });
        });

        this.filters.forEach((filter: any) => {
            if (filter.Type === "Adhoc") {
                this.filterList.push({
                    Type: "Adhoc",
                    ID: filter.ID,
                    Name: filter.Name,
                    DisplayName: filter.DisplayName,
                    Operation: filter.Operation,
                    ValueType: filter.ValueType,
                    ValueList: filter.ValueList,
                    MultiSelect: filter.MultiSelect,
                    Value: filter.Value
                });
            }
        });

        this.columnList = this.columns.slice(0).filter(c => c.isVisible && c.showFilter && !c.actionTemplate);
    }

    public filterExists(value: any, filter: any) {
        let obj: any;

        if (filter.Type === "Custom") {
            obj = this.customFilters.find((o: SDKDataGridCustomFilter) => o.Name === filter.Name);

        } else {
            obj = this.columns.find((o: SDKDataGridColumn) => o.Name === filter.Name);
        }

        if (obj?.FilterTypes && obj.FilterTypes.length > 0) {
            if (Object.values(obj.FilterTypes).includes(value)) {
                return true;
            }
        } else {
            if (!this.filterTypes || this.filterTypes.length === 0 || Object.values(this.filterTypes).includes(value)) {
                return true;
            }
        }

        return false;
    }

    public getColumnOriginalName(column: SDKDataGridColumn): string {
        let originalName = column.Name;

        if (column.DisplayName && column.DisplayName !== "") {
            originalName = column.DisplayName;
        }

        return originalName;
    }

    public addFilter() {
        this.filterList.push({
            Type: "Adhoc",
            ID: new Date().getTime() + Utils.random(0, 999999999),
            Name: "",
            Operation: "",
            ValueType: "text",
            ValueList: undefined,
            MultiSelect: false,
            Value: ""
        });
    }

    public deleteFilter(filter: any) {
        this.filterList = this.filterList.filter(f => f.ID !== filter.ID);

        // this.filterViewList = this.filterList.filter((f: any) => ["System", "Adhoc"].includes(f.Type));
    }

    public addColumn(event: any, filter: any) {
        this.filterList.find((f: any) => f.ID === filter.ID).Name = event.target.value;

        let column = this.columns.find((c: any) => c.Name === filter.Name);

        if (column) {
            filter.Operation = (column.FilterTypes && column.FilterTypes.length === 1) ? column.FilterTypes[0] : (column.FilterValues && column.FilterTypes) ? column.FilterTypes[0] : "Contains";
            filter.ValueType = column.FilterValues ? "list" : "text";
            filter.ValueList = column.FilterValues;
            filter.MultiSelect = column.FilterMultiSelect;
        }
    }

    public addOperation(event: any, filter: any) {
        let item: any = this.filterList.find(f => f.ID === filter.ID && f.Name === filter.Name);

        if (item) {
            item.Operation = event.target.value;
        }
    }

    public addValue(event: any, filter: any) {
        let item: any = this.filterList.find(f => f.ID === filter.ID && f.Name === filter.Name);

        if (item) {
            if (["System", "Custom"].includes(item.Type) && event.target.value === "") {
                item.ID = 0;
                item.Value = "";
            } else {
                item.ID = new Date().getTime() + Utils.random(0, 999999999);
                item.Value = event.target.value;
            }
        }
    }

    public selectedValue(event: any, filter: any) {
        let item: any = this.filterList.find(f => f.ID === filter.ID && f.Name === filter.Name);

        if (item) {
            if (["System", "Custom"].includes(item.Type) && event === "") {
                item.ID = 0;
                item.Value = event;
            } else {
                item.ID = new Date().getTime() + Utils.random(0, 999999999);
                item.Value = event;
            }
        }

        filter.Value = event;
    }

    public toggleAny(filter: any) {
        let item: any = this.filterList.find((f: any) => f.Type === "Custom" && f.ValueType === "checkbox" && f.ID === filter.ID && f.Name === filter.Name);

        if (item) {
            if (item.Value === "true") {
                item.ID = 0;
                item.Value = "";
            } else {
                item.ID = new Date().getTime() + Utils.random(0, 999999999);
                item.Value = "true";
            }
        }
    }

    public drop(event: any) {
        moveItemInArray(this.filterList, event.previousIndex, event.currentIndex);
    }

    public reset() {
        if (confirm("Reset Filtering... Are you sure?")) {
            this.filters = [];

            this.initialze();
        }
    }

    public apply() {
        const tmp: any[] = [];

        this.filterList.filter((filter: any) => filter.ID !== 0).forEach((filter: any) => {
            if (filter.Name !== "") {
                let doApply: boolean = false;

                if (filter.ValueType === "text" && filter.Value.length > 0) {
                    doApply = true;
                }

                if (filter.ValueType === "list" && filter.MultiSelect && filter.Value.length > 0) {
                    doApply = true;
                }

                if (filter.ValueType === "checkbox" && filter.Value === "true") {
                    doApply = true;
                }

                if (filter.ValueType === "list" && !filter.MultiSelect) {
                    if (typeof filter.Value !== "object" && filter.Value !== "" && filter.Value !== undefined) {
                        doApply = true;
                    }

                    if (typeof filter.Value === "object" && filter.Value.length > 0) {
                        // filter.Value = filter.Value[0];
                        doApply = true;
                    }
                }

                if (doApply) {
                    tmp.push(filter);
                }
            }
        });

        this.applyEvent.emit(tmp);
    }

    public close() {
        this.closeEvent.emit();
    }
}
