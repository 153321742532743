import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { Router } from '@angular/router';

import { AppConfigService } from '~/services/core/appconfig.service';

@Component({
    selector: 'header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss']
})

export class HeaderComponent implements OnChanges {
    @Input() title = "Angular App";
    @Input() user = "";
    @Input() photo = "";
    @Output() setLayoutCssClass = new EventEmitter();
    @Output() reloadPage = new EventEmitter();

    public env: string = this.appConfigService.getConfig("environment").toString().toLocaleUpperCase();
    public itemsClass = "";
    public initials: string = "";

    constructor(
        private router: Router,
        private appConfigService: AppConfigService
    ) { }

    //*************************************************************************
    //  Component Life-Cycle Methods
    //*************************************************************************
    public ngOnChanges(_args: any): void {
        if (this.user) {
            const name: string[] = this.user.split(", ");

            if (name && name.length > 1) {
                const firstName: string = name[1].split(" (")[0].split(" [")[0];
                const lastName: string = name[0];

                this.user = `${firstName} ${lastName}`;
                this.initials = firstName.substring(0, 1).toUpperCase() + lastName.substring(0, 1).toUpperCase();
            }
        }
    }

    //*************************************************************************
    //  Public Methods
    //*************************************************************************
    public navigateTo(url: string) {
        this.router.navigateByUrl(url);
    }
}
