import { Pipe, PipeTransform } from '@angular/core';
import LinkifyIt from 'linkify-it';

@Pipe({
  name: 'policyLinkify',
})
export class PolicyTrackerLinkifyPipe implements PipeTransform {
  transform(value: string): any {
    if (!value) {
      return value;
    }

    const linkify = LinkifyIt();
    const matches: any[] = linkify.match(value);
    let result = value;

    if (matches) {
      // extract all unique URLs
      const links = Array.from(new Set(matches.map(match => match.url)));
      let linkCounter = 0; // This will be used to generate the link text
      for (const link of links) {
        linkCounter++; // increment for each link
        // create a regex to match the URL globally
        const regex = new RegExp(link.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'), 'g');
        // create the anchor tag with 'link n' text and the matTooltip directive
        const anchorTag = `<a href="${link}" target="_blank" rel="noopener noreferrer">link ${linkCounter}</a>`;
        // replace all occurrences of the URL with the anchor tag
        result = result.replace(regex, anchorTag);
      }      
    }
  
    return result;
  }
}