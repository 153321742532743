import { Component, Input } from "@angular/core";
import { DeveloperProfile } from "~/services/shared/developers-screening/models/developer-summary";
import { ProjectSummary } from "~/services/shared/developers-screening/models/project-summary";

@Component({
    selector: 'developer-ratings',
    templateUrl: './developer-ratings.component.html',
    styleUrls: ['./developer-ratings.component.scss']
})

export class DeveloperRatingsComponent {
    @Input() developer!: DeveloperProfile;
    public ratedProjects: ProjectSummary[] = [];
    public hoverText: any;

    public async ngOnChanges() { 
        await this.loadData();
    }

    private async loadData(): Promise<void> {
        if (Object.keys(this.developer).length === 0) {
            return;
        }
        this.ratedProjects = this.developer.projects.filter(project => {
            return project.beZeroRating
                || project.calyxGhgRating
                || project.sylveraRating
        });
    }
}