import { Component } from "@angular/core";
import { BaseComponent } from "~/pages/base.component";


@Component({
    selector: 'signpost-report',
    templateUrl: './signposts.component.html',
    styleUrls: ['./signposts.component.scss']
})
export class SignPostReportComponent extends BaseComponent {
    constructor() {
        super();
    }
    public reportId: string = "";
    public title: string = "CNEOE Signposts Dashboard"

    public async ngOnInit() {
        await super.ngOnInit();
        this.reportId = this.appConfig.signpost;
    }
    public selectedValueChanged(event: any) { 
        this.reportId = event;
    }
   

}