import { ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss']
})
export class SearchComponent implements OnChanges {
  @ViewChild('searchInput') searchInput!: ElementRef;
  @Input() expanded: boolean = false;
  @Output() collapse = new EventEmitter<void>();

  public text!: string;

  constructor(private router: Router,  private cdRef: ChangeDetectorRef) { }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.expanded && changes.expanded.currentValue === true) {
      this.cdRef.detectChanges(); // Manually trigger change detection
      this.setFocus();
    }
  }

  public setFocus() {
    this.searchInput.nativeElement.focus();
  }

  onInputChange(event: any) {
    this.text = event;
  }

  handleEnterKey(event: KeyboardEvent): void {
    event.preventDefault(); // to prevent form from being submitted twice
    this.handleSubmit();
  }

  handleSubmit(event?: Event): void {
    if(event) {
      event.preventDefault(); // prevent default form submission
    }
    this.collapse.emit();
    this.router.navigate(['search'], { queryParams: { text: this.text } });
  }
}