import { Component, ViewChild } from '@angular/core';

import { BaseComponent } from '~/pages/base.component';
import { getChartPriceSeries, updateExpansionNodes, updateFitAllContent, updateSelectedPriceSeries } from './state/chart-price-series.repository';
import { TodoItemFlatNode, TreeChecklistComponent } from '~/components/shared/tree-checklist/tree-checklist.component';
import { firstValueFrom } from 'rxjs';
import { Markets } from '~/services/shared/markets/markets';
import { SelectionChange } from '@angular/cdk/collections';
import { PriceChartComponent } from '../../components/shared/price-chart/price-chart.component';
import { PriceChartLegendComponent } from '~/components/shared/price-chart-legend/price-chart-legend.component';

@Component({
	selector: 'markets',
	templateUrl: './markets.component.html',
	styleUrls: ['./markets.component.scss']
})
export class MarketsComponent extends BaseComponent {
	@ViewChild('treeCheckList', { static: true })
	treeCheckList!: TreeChecklistComponent;
	@ViewChild('priceChart', { static: true })
	priceChart!: PriceChartComponent;
	@ViewChild('priceChartLegend', { static: true })
	priceChartLegend!: PriceChartLegendComponent;

	public title: string = "Carbon Markets";
	public fitAllContent: boolean = false;
	
	async ngOnInit() {
		this.treeCheckList.checklistSelection.changed.subscribe(
			{
				next: v => {
					this.treeListChanged(v);
				}
			}
		);

		this.treeCheckList.toggleExpanded.subscribe(
			{
				next: v => {
          this.treeListToggleExpanded(v);
        }
			}
		)

		const state = await firstValueFrom(getChartPriceSeries());
		if (state) {
			if (state.nodeExpansion?.length > 0) {
				state.nodeExpansion.forEach(s => {
					this.treeCheckList.toggleExpandNode(s);
				})
			} else {
				this.treeCheckList.treeControl.expandAll();
			}

			if (state.selectedSeries?.length > 0) {
				state.selectedSeries.forEach(s => {
					this.treeCheckList.branchSelectionToggleExternal(s.item);
				})
			} else {
				state.defaultSeries.forEach(s => {
					this.treeCheckList.branchSelectionToggleExternal(s.item);
				})
			}

      this.treeCheckList.initBranches();
			this.fitAllContent = state.fitAllContent;
		}
	}

	private async treeListChanged(event: SelectionChange<TodoItemFlatNode>) {
    const state = await firstValueFrom(getChartPriceSeries());

    if (state) {
        this.handleAddedItems(event.added, state);
        this.handleRemovedItems(event.removed, state);
        updateSelectedPriceSeries(state.selectedSeries);
    }

    this.emitSeriesAddedEvents(event.added);
    this.emitSeriesRemovedEvents(event.removed);
  }

  private handleAddedItems(items: TodoItemFlatNode[], state: any) {
    items.forEach(element => {
        const found = state.selectedSeries.find(x => x.item === element.item);
        if (!found) {
            state.selectedSeries.push(element);
        }
    });
  }

  private handleRemovedItems(items: TodoItemFlatNode[], state: any) {
    items.forEach(element => {
        const found = state.selectedSeries.find(x => x.item === element.item);
        if (found) {
            const index = state.selectedSeries.indexOf(found, 0);
            if (index > -1) {
                state.selectedSeries.splice(index, 1);
            }
        }
    });
  }

  private emitSeriesAddedEvents(items: TodoItemFlatNode[]) {
    items.forEach(added => {
        this.priceChart.seriesAdded.next(added.item);
        if (added.level > 0) {
            const market = Markets.getMarketByName(added.item);
            if (market) {
                this.priceChartLegend.seriesAdded.next({
                    name: market.name,
                    symbol: market.symbol,
                    color: Markets.getColor(market)
                });
            }
        }
    });
  }

  private emitSeriesRemovedEvents(items: TodoItemFlatNode[]) {
    items.forEach(removed => {
        this.priceChart.seriesRemoved.next(removed.item);
        const market = Markets.getMarketByName(removed.item);
        if (market) {
            this.priceChartLegend.seriesRemoved.next({
                name: market.name,
                symbol: market.symbol,
                color: Markets.getColor(market)
            });
        }
    });
  }

	private async treeListToggleExpanded(node: TodoItemFlatNode) {
		const state = await firstValueFrom(getChartPriceSeries());

		if (state) {
			let found: TodoItemFlatNode | null = null;
			state.nodeExpansion.forEach(element => {
				if (element.item === node.item) {
					found = element;
					found.isExpanded = node.isExpanded;
				}
			});

			if (!found) {
				state.nodeExpansion.push(node);
			}

			updateExpansionNodes(state.nodeExpansion);
		}
	}

	setFitAllContent(fitAllContent: boolean) {
		updateFitAllContent(fitAllContent);
		this.fitAllContent = fitAllContent;
	}

	onLegendHoverStart(symbol: string) {
		this.priceChart.seriesSelected.next(symbol);
	}

	onLegendHoverEnd(symbol: string) {
		this.priceChart.seriesUnselected.next(symbol);
	}
}
