<div *ngIf="dataSource?.data.length > 0" class="climate-table-wrapper">
<table mat-table [dataSource]="dataSource" class="climate-table" matSort>
  <caption>Climate Policies</caption>
  <ng-container matColumnDef="policyName">
    <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by name">
      Policy Name
    </th>
    <td mat-cell *matCellDef="let element" class="policy-name-cell">
      <a *ngIf="element.reference; else noReference" href="{{element.reference}}" (mouseenter)="openDialog($event, element)" (mouseleave)="onMouseExit(element)" target="_blank" rel="noopener noreferrer">{{element.policyName}}</a>
      <ng-template #noReference>{{element.policyName}}</ng-template>
    </td>
  </ng-container>

  <ng-container matColumnDef="policyTitle">
    <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by title">
      Policy Title
    </th>
    <td mat-cell *matCellDef="let element" class="policy-name-cell">
      <a *ngIf="element.reference; else noReference" href="{{element.reference}}" (mouseover)="openDialog($event, element)" (mouseleave)="onMouseExit(element)" target="_blank" rel="noopener noreferrer">{{element.policyName}}</a>
      <ng-template #noReference>{{element.policyTitle}}</ng-template>
    </td>
  </ng-container>

  <ng-container matColumnDef="sector">
    <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by sector">
      Sector
    </th>
    <td mat-cell *matCellDef="let element" class="center-cell"> {{element.sector}} </td>
  </ng-container>

  <ng-container matColumnDef="policyType">
    <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by type">
      Policy Type
    </th>
    <td mat-cell *matCellDef="let element" class="center-cell"> {{element.policyType}} </td>
  </ng-container>

  <ng-container matColumnDef="decisionDate">
    <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by decision date">
      Decison Date
    </th>
    <td mat-cell *matCellDef="let element" class="center-cell"> {{element.decisionDate}} </td>
  </ng-container>

  <ng-container matColumnDef="policyStatusName">
    <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by status">
      Status
    </th>
    <td mat-cell *matCellDef="let element" class="center-cell"> {{element.policyStatusName}} </td>
  </ng-container>

  <ng-container matColumnDef="jurisdiction">
    <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by jurisdiction">
      Jurisdiction
    </th>
    <td mat-cell *matCellDef="let element"> {{element.jurisdiction}} </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
</table>
<mat-paginator
#paginator
[pageSizeOptions]="[10, 20, 50, 100]"
showFirstLastButtons *ngIf="dataSource?.data.length > 10" class="paginator"></mat-paginator>
</div>
