import { Component } from "@angular/core";
import { BaseComponent } from "~/pages/base.component";


@Component({
    selector: 'market-report',
    templateUrl: './market.component.html',
    styleUrls: ['./market.component.scss']
})
export class MarketReportComponent extends BaseComponent {
    constructor() {
        super();
    }
    public reportId: string = "";
    
    public async ngOnInit() {
        await super.ngOnInit();
        this.reportId = this.appConfig.market;
    }
}