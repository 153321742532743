<div class="job-detail">
  <div class="centering-wrapper">
    <div class="loading-container" *ngIf="isExporting">
      <mat-spinner></mat-spinner>
    </div>
  </div>
  <div class="header">
    <div class="job-info">
      <h3>Job Info</h3>
      <div><span class="detail-label">Run as Identity:</span>{{jobDefinition?.runAsIdentity}}</div>
      <div><span class="detail-label">Initiated By:</span>{{queuedJob?.initiatedBy}}</div>
      <div><span class="detail-label">Percent Completed:</span>{{queuedJob?.percentCompleted}}</div>
    </div>
    <div>
      <div class="updated" *ngIf="lastUpdated">
        <span class="detail-label">Last Update:</span>{{lastUpdated | date:'mediumTime'}}
        <span *ngIf="this.shouldRefresh(queuedJob)" class="spacer"></span>
        <span *ngIf="this.shouldRefresh(queuedJob)" matTooltip="refresh" class="dg-icon material-icons-outlined" (click)="refreshJobInstance()">refresh</span>
      </div>
    </div>
  </div>

  <div class="event-logs" *ngIf="dataSource.data.length > 0 || keyword || selectedLogTypes.length > 0 || selectedCategories.length > 0">
  <div class="log-header">
    <h3>Event Logs</h3>
    <div class="filters">
      <mat-form-field appearance="outline" subscriptSizing="dynamic" class="log-types">
        <mat-label>Filter log types...</mat-label>
        <mat-select multiple [(value)]="selectedLogTypes" (selectionChange)="onLogTypesChanged($event)">
          <mat-option value="Debug">Debug</mat-option>
          <mat-option value="Information">Information</mat-option>
          <mat-option value="Warning">Warning</mat-option>
          <mat-option value="Error">Error</mat-option>
        </mat-select>
      </mat-form-field>
      <multi-select *ngIf="categories.length > 0" class="categories" [data]="categories" (changed)="onChangeCategories($event)" label="Filter categories..." size="larger"></multi-select>
      <mat-form-field class="search-field" appearance="outline" subscriptSizing="dynamic">
        <mat-label>Filter logs...</mat-label>
        <input matInput [formControl]="searchControl">
        <button matSuffix mat-icon-button *ngIf="searchControl.value" aria-label="Clear" (click)="clearSearch()">
          <mat-icon class="clear-icon">close</mat-icon>
        </button>
      </mat-form-field>
      <div title="Export" class="icon material-icons-outlined export-icon" [class.disabled-icon]="isExporting" (click)="export()" >file_download</div>
    </div>
  </div>
  <table mat-table [dataSource]="dataSource" matSort matSortDisableClear (matSortChange)="onSortChange($event)" aria-describedby="job event logs">
    <!-- Type Column -->
    <ng-container matColumnDef="type">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Type</th>
      <td mat-cell *matCellDef="let log">{{ log.type }}</td>
    </ng-container>
    
    <!-- Category Column -->
    <ng-container matColumnDef="category">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Category</th>
      <td mat-cell *matCellDef="let log">{{ log.category }}</td>
    </ng-container>
    
    <!-- Message Column -->
    <ng-container matColumnDef="message">
      <th mat-header-cell *matHeaderCellDef>Message</th>
      <td mat-cell *matCellDef="let log"
          matTooltipPosition="above"
          (click)="openFullMessageDialog(log.message)"
          class="wrap-text">
          {{ log.message | slice:0:500 }}
          <span *ngIf="log.message.length > 500">...</span>
      </td>
    </ng-container>
    
    <!-- Timestamp Column -->
    <ng-container matColumnDef="timestamp">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Timestamp</th>
      <td mat-cell *matCellDef="let log">{{ log.timestamp | date:'medium' }}</td>
    </ng-container>
    
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>
    
  <mat-paginator
      [length]="pageInfo?.totalCount"
      [pageIndex]="pageInfo?.currentPage - 1"
      [pageSize]="pageInfo?.pageSize" 
      [pageSizeOptions]="[5, 10, 25, 100]" 
      (page)="onPageChange($event)">
  </mat-paginator>
  </div>
</div>