import { Component, EventEmitter, Input, Output } from '@angular/core';

import { SDKDataGridColumn } from 'sdk-datagrid';
import { DurableTaskResponse } from '~/models/shared/durable-task/durable-task-response.model';
import { AlertType } from '~/models/shared/errors/error-alert-type.enum';
import { RequestParameters } from '~/models/shared/request-parameters';
import { ExcelExportService } from '~/services/shared/export-excel/export-excel-service';
import { buildParametersFilters, buildParametersSorts } from '../../project-helpers';
import { ProjectService } from '~/services/shared/projects/project.service';
import { ICvxProject, ICvxProjectOwner } from '~/models/shared/cvxproject';
import { FormatterService } from '~/services/shared/formatter.service';


@Component({
    selector: 'project-export-window',
    templateUrl: './project-export-window.component.html',
    styleUrls: ['./project-export-window.component.scss']
})
export class ProjectExportWindowComponent {
  @Input() dataClass: string = ""; // Used to indicate if window is open/closed.
  @Input() optionTitle: string = ""; // Unique name for option.
  @Input() datasets: any[] = []; // Data array.
  @Input() columns: SDKDataGridColumn[] = []; // Columns defines for data.
  @Input() filters: any[] = []; // Filters defines for data.
  @Input() sorts: any[] = []; // Sorting defines for data.

  @Output() applyEvent = new EventEmitter<any>(); // Apply option.
  @Output() closeEvent = new EventEmitter<any>(); // Close window.
    
  public MAXRECORDS: number = 1000;

  includeFilters: boolean = true;
  includeSorts: boolean = true;
  includeColumnChanges: boolean = true;

  isLoading; boolean = false;
  errorMessage!: string | null | undefined;
  excelUri!: string | null;
  timerId!: any;
  
  progress: number = 0;
  AT = AlertType;
  task!: DurableTaskResponse | null;
  status!: string; 

  constructor(
    private readonly exportService: ExcelExportService, 
    private projectService: ProjectService,
    private formatterService: FormatterService) {}

  public close() {
    this.closeEvent.emit();
  }

  public async apply() {
    this.applyEvent.emit();
    this.closeEvent.emit();
  }
  
  async onExport() {
    this.isLoading = true;
    let columnsFinal: SDKDataGridColumn[] = [...this.columns];
    if (this.includeColumnChanges) {
      columnsFinal = this.columns.filter(column => column.isVisible);
    }
    const columnDisplayNames = columnsFinal.flatMap(col => col.DisplayName ?? col.Name);

    const parameters = this.buildParameters();
    await this.projectService.searchProjects(parameters).then((data: any) => {
      if (data) {
        const extended = this.extendResults(data.results.values);
        const mappedData = this.mapProjectsToColumns(extended, columnsFinal);
        this.exportService.export(mappedData, 'cvx-projects', columnDisplayNames);
      }
      this.isLoading = false;
    });
  }

  private mapProjectsToColumns(projects: ICvxProject[], columns: SDKDataGridColumn[]): any[] {
    return projects.map(project => {
        const mappedObject: { [key: string]: any } = {};

        columns.forEach(column => {
            if (column.Name in project) {
                mappedObject[column.Name] = this.getColumnValue(project, column.Name);
            }
        });

        return mappedObject;
    });
}

private getColumnValue(project: ICvxProject, columnName: string): any {
    const columnMapping = {
        'country': project => project[columnName]?.name,
        'owners': project => this.getOwners(project[columnName]),
        'status': project => project[columnName]?.name,
        'cvxProjectType': project => project[columnName]
            ? `${project[columnName]?.cvxProjectScopeName} - ${project[columnName]?.name}`
            : '',
        'methodology': project => project[columnName]
            ? `${project[columnName]?.methodology} - ${project[columnName]?.description}`
            : '',
        'product': project => project[columnName]?.name,
        'investmentType': project => project[columnName]?.name,
        'technicalReviewStatus': project => project[columnName]?.name,
        'wsjfScorecardBusinessValue': project => project[columnName]?.businessValue,
        'wsjfScorecardTimeCriticality': project => project[columnName]?.timeCriticality,
        'wsjfRiskReductionValue': project => project[columnName]?.riskReductionOpportunityEnablementValue,
        'wsjfEstimatedEffort': project => project[columnName]?.estimatedImplementationEffort,
        'wsjfScore': project => project[columnName]?.wsjfScore,
        'technicalReviewRecommendation': project => project[columnName]?.name,
        'executionStartDate': project => {
            const rawDate = project[columnName];
            return rawDate
                ? new Date(rawDate.toString().split('T')[0]).toLocaleDateString('en-US', { month: '2-digit', day: '2-digit', year: 'numeric' })
                : '';
        },
        'ndaExecutionDate': project => {
            const rawDate = project[columnName];
            return rawDate
                ? new Date(rawDate.toString().split('T')[0]).toLocaleDateString('en-US', { month: '2-digit', day: '2-digit', year: 'numeric' })
                : '';
        },
        'lastUpdated': project => this.formatterService.formatDate(project[columnName], "MM/dd/yyyy")
    };

    return columnMapping[columnName]
        ? columnMapping[columnName](project)
        : project[columnName];
}


  private getOwners(owners: ICvxProjectOwner[]) {
    if (owners) {
      return owners.map(owner => `${owner.accountName} (${owner.percentOwned}%)`).join(', ');
    }

    return '';
  }

  private extendResults(values: ICvxProject[]): ICvxProject[] {
    values.forEach(value => {
      value.country = value?.location?.addressCountry;
      value.wsjfScorecardBusinessValue = value.wsjfScorecard;
      value.wsjfScorecardTimeCriticality = value.wsjfScorecard;
      value.wsjfRiskReductionValue = value.wsjfScorecard;
      value.wsjfEstimatedEffort = value.wsjfScorecard;
      value.wsjfScore = value.wsjfScorecard;
    });
  
    return values;
  }
  
  private buildParameters(): any {
    let parameters: RequestParameters = new RequestParameters();

    parameters.Paging = {
        pageSize: this.MAXRECORDS,
        currentPage: 1
    };

    parameters.SortingOptions = [];
    parameters.Terms = [];

    const event = {filters: this.filters, sorts: this.sorts};
    
    parameters.Paging.pageSize = this.MAXRECORDS;
    
    if (this.includeSorts) {
      buildParametersSorts(event, parameters);
    }
  
    if (this.includeFilters) {
      buildParametersFilters(event, parameters);
    }
    
    return parameters;
  }
}
