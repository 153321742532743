
<div [class.edit]="isEdit$ | async">
    <sdk-datagrid
        fontFamily="'GothamNarrowBook', sans-serif, 'Helvetica Neue', Arial"
        [uniqueIdentifier]="'projects.project-detail.credits.credit-issuance-table.credit-issuance-table'"
        [name]="'projects/credits'"
        [datasets]="datasets"
        [data]="data$ | async"
        [columns]="columns"
        [options]="gridOptions"
        [showOptions]=false
        [showFooter]=false
        [detailTemplate]="rowDetail"
        [isLoading]="isLoading$ | async">
    </sdk-datagrid>
    <div [style.display]="(isEdit$ | async) ? 'block' : 'none'">
        <ccms-icon-button 
            class="add-inventory-btn small" 
            icon="icon-expand-plus"
            (click)="addInventory()"
            aria-label="Add credit forecast">Row</ccms-icon-button>
    </div>
</div>

<ng-template #actionRight let-rowItem="rowItem" let-activeRow="activeRow" let-index="index">
    <div *ngIf="rowItem.id === -1" class="action">
        <div title="Delete" class="icon material-icons-outlined delete" 
            (click)="$event.stopPropagation();undoAddInventory(rowItem)">delete</div>
    </div>
</ng-template>

<ng-template #dateIssued let-activeRow="activeRow" let-rowItem="rowItem">
    <div *ngIf="(isEdit$ | async); else dateIssuedReadOnly">
        <input type="date"
            class="required" 
            [value]="rowItem.dateIssued | date:'yyyy-MM-dd'"
            (input)="setDateIssued(rowItem, $event.target.value)">
        <small class="error" *ngIf="hasError(rowItem, 'dateIssued')">
            {{ rowItem.errors['dateIssued'] }}
        </small>
    </div>
    <ng-template #dateIssuedReadOnly>
        <div>{{ rowItem.dateIssued | date }}</div>
    </ng-template>
</ng-template>

<ng-template #serialNumber let-activeRow="activeRow" let-rowItem="rowItem">
    <div *ngIf="(isEdit$ | async); else serialNumberReadOnly">
        <input type="text"
            [class.required]="rowItem.serialNumber != ''" 
            [value]="rowItem.serialNumber"
            (input)="setSerialNumber(rowItem, $event.target.value)">
        <small class="error" *ngIf="hasError(rowItem, 'serialNumber')">
            {{ rowItem.errors['serialNumber'] }}
        </small>
    </div>
    <ng-template #serialNumberReadOnly>
        {{ rowItem.serialNumber }}
    </ng-template>
</ng-template>

<ng-template #creditsIssued let-activeRow="activeRow" let-rowItem="rowItem">
    <div *ngIf="(isEdit$ | async) && (rowItem.id === -1 || rowItem.accounts.length <= 1); else creditsIssuedReadOnly">
        <input type="text" class="required"
            [value]="rowItem.creditsIssued"
            (input)="setCreditsIssued(rowItem, $event.target.value)">
        <small class="error" *ngIf="hasError(rowItem, 'creditsIssued')">
            {{ rowItem.errors['creditsIssued'] }}
        </small>
    </div>
    <ng-template #creditsIssuedReadOnly>
        {{ rowItem.creditsIssued | number }}
    </ng-template>
</ng-template>

<ng-template #vintage let-activeRow="activeRow" let-rowItem="rowItem">
    <div *ngIf="(isEdit$ | async); else vintageReadonly">
        <input type="number"
            class="required"
            [value]="rowItem.vintage"
            (input)="setVintage(rowItem, $event.target.value)">
        <small class="error" *ngIf="hasError(rowItem, 'vintage')">
            {{ rowItem.errors['vintage'] }}
        </small>
    </div>
    <ng-template #vintageReadonly>
        {{ rowItem.vintage }}
    </ng-template>
</ng-template>

<ng-template #unitPrice let-activeRow="activeRow" let-rowItem="rowItem">
    <div *ngIf="(isEdit$ | async); else unitPriceReadOnly">
        <input type="number"
            class=""
            [value]="rowItem.unitPrice"
            (input)="setUnitPrice(rowItem, $event.target.value)">
        <small class="error" *ngIf="hasError(rowItem, 'unitPrice')">
            {{ rowItem.errors['unitPrice'] }}
        </small>
    </div>
    <ng-template #unitPriceReadOnly>
        {{ rowItem.unitPrice | currency }}
    </ng-template>
</ng-template>

<ng-template #rowDetail let-creditInventory="rowItem">
    <div title="{{ creditInventory.serialNumber }}">
        <p *ngIf="creditInventory.accounts?.length === 0; else detailTable">
            Credit inventory is not associated with any account(s).
        </p>
        <ng-template #detailTable>
            <table class="table table-striped" title="{{ creditInventory.serialNumber }}">
                <caption style="display: none;">Credit Inventory Accounts</caption>
                <thead>
                    <tr>
                        <th>
                            Assigned To
                        </th>
                        <th>
                            Description
                        </th>
                        <th>
                            Balance
                        </th>
                        <th>
                            <!-- Menu -->&nbsp;
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let account of creditInventory.accounts; let idx = index">
                        <td>
                            {{ account.name }}
                        </td>
                        <td>
                            {{ account.description }}
                        </td>
                        <td>
                            {{ account.balance | number }}
                        </td>
                        <td>
                            <div class="action p-0" *ngIf="account.balance > 0 && canEdit">
                                <div (click)="initializeCreditTransfer(creditInventory, account, 'Transfer')"
                                    class="icon icon-box-transfer icon-button" role="button"
                                    matTooltip="transfer credits" aria-label="transfer credits"></div>
                                <div (click)="initializeCreditTransfer(creditInventory, account, 'Retire')"
                                    class="icon icon-globe-remove ml-3 icon-button" role="button"
                                    matTooltip="retire credits" aria-label="retire credits"></div>
                            </div>                        
                        </td>
                    </tr>
                </tbody>
            </table>
        </ng-template>
    </div>
</ng-template>