import { Component, TemplateRef, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { SDKDataGridDataset, SDKDataGridOptions, Filters, SDKDataGridSettings } from 'sdk-datagrid';

import { BaseComponent } from '~/pages/base.component';

import { SupplyTradingInventoryService } from '~/services/shared/inventory/supplytrading-inventory.service';
import { FormatterService } from '~/services/shared/formatter.service';

import { RequestParameters, SearchFilter } from '~/models/shared/request-parameters';

import { SettingsGrid } from '~/services/shared/grid/models/settings-grid.model';
import { GridHandlerService } from '~/services/shared/grid/grid-handler.service';
import { FilterInfo } from '~/models/shared/filterInfo';

@Component({
    selector: 'supplytrading-transactions',
    templateUrl: './supplytrading-transactions.component.html',
    styleUrls: ['./supplytrading-transactions.component.scss']
})

export class SupplyTradingTransactionsComponent extends BaseComponent implements SettingsGrid {
    @ViewChild('registryTemplate') registryTemplate!: TemplateRef<any>;
    @ViewChild('metaRegistryTemplate') metaRegistryTemplate!: TemplateRef<any>;

    public footnote: string = "";
    public MAXRECORDS: number = 1000;
    public activeView: string = "RightAngleInventory/Search";
    public datasets: SDKDataGridDataset[] = [
        {
            Title: "Inventory",
            DbName: "RightAngleInventory/Search",
        }
    ];
    public data: string = "";
    public columns: any[] = [
        { Name: "dealTypeId", DisplayName: "Deal Type", FilterMultiSelect: true, FilterValues: [], isVisible: true },
        { Name: "counterparty", DisplayName: "Counterparty", FilterMultiSelect: true, FilterValues: [], isVisible: true },
        { Name: "product", DisplayName: "Product", FilterMultiSelect: true, FilterValues: [], isVisible: true },
        { Name: "credits", DisplayName: "Credits", isVisible: true, formatter: (value: any) => this.formatterService.formatNumber(value), FilterTypes: [Filters.LessThan, Filters.LessThanOrEqual, Filters.GreaterThan, Filters.GreaterThanOrEqual] },
        { Name: "pricePerTon", DisplayName: "Price Per Ton", isVisible: true, formatter: (value: any) => this.formatterService.formatCurrency(value), FilterTypes: [Filters.LessThan, Filters.LessThanOrEqual, Filters.GreaterThan, Filters.GreaterThanOrEqual] },
        { Name: "cvxCompany", DisplayName: "Cvx Company", FilterMultiSelect: true, FilterValues: [], isVisible: true },
        { Name: "creditSourceId", DisplayName: "Credit Source", FilterMultiSelect: true, FilterValues: [], isVisible: true },
        { Name: "registry", DisplayName: "Registry", FilterMultiSelect: true, FilterValues: [], isVisible: true, dataTemplate: () => this.registryTemplate },
        { Name: "metaRegistryProjectId", DisplayName: "Meta Registry", FilterMultiSelect: true, FilterValues: [], isVisible: true, dataTemplate: () => this.metaRegistryTemplate },
        { Name: "transactionDate", DisplayName: "Transaction Date", isVisible: true, formatter: (value: any) => this.formatterService.formatDateUtc(value, "MM/dd/yyyy"), FilterTypes: [Filters.LessThan, Filters.LessThanOrEqual, Filters.GreaterThan, Filters.GreaterThanOrEqual] },
        { Name: "changeDate", DisplayName: "Change Date", formatter: (value: any) => this.formatterService.formatDateUtc(value, "MM/dd/yyyy"), FilterTypes: [Filters.LessThan, Filters.LessThanOrEqual, Filters.GreaterThan, Filters.GreaterThanOrEqual] },
        { Name: "dealNumber", DisplayName: "Deal Number", FilterMultiSelect: true, FilterValues: [] },
        { Name: "directPriceEstimate", DisplayName: "Price Estimate", formatter: (value: any) => this.formatterService.formatCurrency(value), FilterTypes: [Filters.LessThan, Filters.LessThanOrEqual, Filters.GreaterThan, Filters.GreaterThanOrEqual] },
        { Name: "beZeroRating", DisplayName: "BeZero Rating", FilterMultiSelect: true, FilterValues: [] },
        { Name: "sylveraRating", DisplayName: "Sylvera Rating", FilterMultiSelect: true, FilterValues: [] },
        { Name: "calyxGhgRating", DisplayName: "Calyx GHG Rating", FilterMultiSelect: true, FilterValues: [] }
    ];

    // Settings grid interface Implementation:
    public uniqueIdentifier = "inventory.inventory";
    public allSettings: SDKDataGridSettings[] = [];
    public multiSelectFilters: FilterInfo[] = [];
    public errorHanlder = (message, error) => {
        console.error(`${message} ${error?.message}`);
    }

    public gridOptions: SDKDataGridOptions = {
        settings: true,
        columnSettings: true,
        filtering: true,
        sorting: true,
        formulas: true,
        charts: false,
        export: true,
        expandableRows: false,
        selectableRows: false,
        autoClosePanel: true
    };
    public filterTypes: any = [Filters.Equals, Filters.NotEquals, Filters.Contains];
    public fullData: string = "";
    public dbPage: number = 0;
    public dbTotal: number = 0;
    public error: string = "";

    public totalPurchased: number = 0;
    public totalSold: number = 0;
    public totalBalance: number = 0;

    private ids!: string[];

    public callbackLabel: string = "";
    private callback: string = "";
    private callbackParams: any;

    constructor(
        private router: Router,
        private inventoryService: SupplyTradingInventoryService,
        private formatterService: FormatterService,
        private gridHandlerService: GridHandlerService,
        private route: ActivatedRoute,
    ) {
        super();
    }

    //*************************************************************************
    //  Component Life-Cycle Methods
    //*************************************************************************
    public async ngOnInit() {
        await super.ngOnInit();
        this.subscribeToRouteParams();
        await this.loadMultiSelectFilters();
        this.gridHandlerService.loadSettings(this);

        if (window.history.state.callback) {
            this.callbackLabel = window.history.state.callbackLabel ?? null;
            this.callback = window.history.state.callback ?? null;
            this.callbackParams = window.history.state.callbackParams ?? null;
        }
    }

    //*************************************************************************
    //  Public Methods
    //*************************************************************************
    public async loadData(event: any = null, ids?: string[]) {
        this.isLoading = true;

        let parameters: RequestParameters = this.buildParameters(event);
        let fullLoad: boolean = false;

        if (event && (event.chart !== undefined || event.export !== undefined)) {
            fullLoad = true;

            parameters.Paging = {
                pageSize: 999999,
                currentPage: 1
            };
        }

        if (this.ids?.length > 0) {
            parameters.EntityIds = this.ids;
        }

        try {
            const data = await this.inventoryService.getInventory(parameters);
            if (data) {

                if (this.multiSelectFilters.length == 0) {
                    await this.loadMultiSelectFilters();
                }
                this.setMultiSelectFilters();

                if (fullLoad) {
                    this.fullData = data.values;
                } else {
                    this.resetVariables();

                    this.data = data.values;
                    this.dbPage = parseInt(data.metadata.currentPage);
                    this.dbTotal = parseInt(data.metadata.totalCount);
                    this.error = "";
                }

                this.footnote = `Inventory as of ${this.formatterService.formatDate(new Date(), "dd MMM yyyy hh:mm:ss (z)")}`;

            }

        } catch (error: any) {
            this.error = error.message;
        }

        this.isLoading = false;
    }

    public gotoProject(id: any) {
        if (id) {
            this.router.navigate(["/metaregistry/detail"], { state: { callback: '/inventory', callbackParams: { 'tab': 'Transactions', 'parentTab': 'Supply & Trading' }, callbackLabel: 'Back to Supply & Trading Inventory', projectId: id } });
        }
    }

    public back() {
        this.router.navigate([this.callback], { queryParams: this.callbackParams });
    }


    public saveSettings(event: SDKDataGridSettings[]) {
        this.gridHandlerService.saveSettings(this, event);
    }

    //*************************************************************************
    //  Private Methods
    //*************************************************************************
    private async subscribeToRouteParams() {
        this.route.queryParams.subscribe((params: any) => {
            if (params?.ids) {
                this.ids = params.ids.split(',');
            } else {
                this.ids = [];
            }
        });
    }

    private buildParameters(event: any = null): any {
        let parameters: RequestParameters = new RequestParameters();

        parameters.Paging = {
            pageSize: this.MAXRECORDS,
            currentPage: 1
        };

        parameters.SortingOptions = [];
        parameters.SortingOptions = [];
        parameters.Terms = [];

        if (event) {
            this.buildParametersPage(event, parameters);
            this.buildParametersRows(event, parameters);
            this.buildParametersSorts(event, parameters);
            this.buildParametersFilters(event, parameters);
        }

        return parameters;
    }

    private buildParametersPage(event: any, parameters: any) {
        if (event.page) {
            parameters.Paging.currentPage = parseInt(event.page);
        }
    }

    private buildParametersRows(event: any, parameters: any) {
        parameters.Paging.pageSize = this.MAXRECORDS;
    }

    private buildParametersSorts(event: any, parameters: any) {
        if (event.sorts) {
            event.sorts.forEach((sort: any, index: number) => {
                let direction = (sort.Sort === "asc") ? 0 : 1;
                let ndx = index + 1;

                parameters.SortingOptions.push({ field: sort.Name, direction: direction, priority: ndx });
            });
        }
    }

    private buildParametersFilters(event: any, parameters: any) {
        if (event.filters) {
            event.filters.forEach((filter: any) => {
                const searchFilter = new SearchFilter();
                searchFilter.Name = filter.Name;
                searchFilter.Field = filter.Name;
                searchFilter.Operation = filter.Operation;

                const filterValue = filter.Value;
                if (filterValue instanceof (Array)) {
                    searchFilter.MultiSelectValues = filterValue;
                }
                else {
                    searchFilter.Value = filterValue;
                }

                parameters.Terms.push(searchFilter);
            });
        }
    }

    private resetVariables() {
        this.data = "";
        this.fullData = "";
        this.dbPage = 0;
        this.dbTotal = 0;
        this.error = "";
    }

    private async loadMultiSelectFilters() {
        try {
            const multiSelectColumns: any[] = this.columns.filter(column => column.FilterMultiSelect);
            this.multiSelectFilters = await this.inventoryService.getFilters(multiSelectColumns.map(column => column.Name));
        } catch (error: any) {
            console.error("Error loading filters: " + error.message);
        }
    }

    private setMultiSelectFilters() {
        const multiSelectColumns: any[] = this.columns.filter(column => column.FilterMultiSelect);

        multiSelectColumns?.forEach((multiSelectColumn) => {
            const filter = this.multiSelectFilters.find(filterInfo => filterInfo.fieldName.toLowerCase() === multiSelectColumn.Name.toLowerCase());
            const filterValues = filter?.filterValues;
            multiSelectColumn.FilterValues.length = 0;
            filterValues?.forEach(value => multiSelectColumn.FilterValues.push(value));
        });
    }
}