<div class="content">
    <div class="header">
        <div class="title">{{ title }}</div>
    </div>
    
    <div class="grid">
        <sdk-datagrid
            titleStyle="text-align: left; font-size: 1.3em; font-weight: normal; color: var(--blue-dark); font-family: 'GothamBold';"
            [uniqueIdentifier]="uniqueIdentifier"
            [datasets]="datasets"
            [name]="activeView"
            [data]="data"
            [fullData]="fullData"
            [columns]="columns"
            [customFilters]="customFilters"
            [options]="gridOptions"
            [dbPage]="dbPage"
            [dbTotal]="dbTotal"
            [dbMAXRECORDS]="MAXRECORDS"
            [isDataWrapped]=false
            [minimizeOptions]=false
            [isLoading]="isLoading$ | async"
            [error]="error"
            [settings]="allSettings"
            (loadDataEvent)="loadData($event)"
            (selectedRowActionEvent)="showDetail($event)"
            (settingsSavedEvent)="saveSettings($event)">
        </sdk-datagrid>
    </div>  
    
    <div class="footnote">
        {{ footnote }}
    </div>
    <div>
    </div>
</div>

<ng-template #creditDeliveryRate let-rowItem="rowItem">
    <div (click)="$event.stopPropagation()">
        <div>
          {{ (rowItem?.creditDeliveryRate | percent: '1.2') ?? 'N/A' }}
        </div>
    </div>
</ng-template>

<ng-template #accreditationsTemplate let-rowItem="rowItem">
    <div (click)="$event.stopPropagation()">
        <div>
          {{ rowItem.accreditations.join(', ') }}
        </div>
    </div>
</ng-template>

<ng-template #programsTemplate let-rowItem="rowItem">
    <div (click)="$event.stopPropagation()">
        <div>
          {{ rowItem.programs.join(', ') }}
        </div>
    </div>
</ng-template>

<ng-template #protocolsTemplate let-rowItem="rowItem">
    <div (click)="$event.stopPropagation()">
        <ng-container *ngIf="rowItem.protocols?.length > 3">
            <div title="{{ rowItem.protocols.join(', ') }}">
                <span>{{ rowItem.protocols[0] }}</span>
                <br />
                <span>{{ rowItem.protocols[1] }}</span>
                <br />
                <span>{{ rowItem.protocols[2] }}</span>
                <br />
            </div>
        </ng-container>
        <ng-container *ngIf="rowItem.protocols && rowItem.protocols.length < 3">
            <div *ngFor="let protocol of rowItem.protocols">
                <span>{{ protocol }}</span><br />
            </div>
        </ng-container>
    </div>
</ng-template>