import { NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SDKSelectComponent } from './sdk-select.component';

@NgModule({
  declarations: [
    SDKSelectComponent
  ],
  imports: [
    CommonModule
  ],
  exports: [
    SDKSelectComponent
  ],
  schemas: [
    NO_ERRORS_SCHEMA
  ]
})
export class SDKSelectModule { }
