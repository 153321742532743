import { Component, ElementRef, OnInit, Renderer2, ViewChild } from "@angular/core";
import { ActivatedRoute, ParamMap, Router } from "@angular/router";
import { PolicyTrackerDetailViewModel } from "./policy-tracker-detail.viewmodel";
import { MatTabGroup } from "@angular/material/tabs";
import { MarketChartComponent } from "~/components/shared/market-chart/market-chart.component";
import { PolicyTrackerService } from "~/services/shared/policy-tracker/policy-tracker.service";
import { PolicyTracker } from "~/models/shared/policy-tracker/policy-tracker";
import { ClimatePolicy } from "~/models/shared/policy-tracker/climate-policy.model";
import { ClimatePolicyService } from "~/services/shared/policy-tracker/climate-policy.service";
import { PriceDataService } from "~/services/shared/markets/price-data.service";
import { PolicyTrackerConstants } from "~/services/shared/policy-tracker/policy-tracker-constants";
import { Markets } from "~/services/shared/markets/markets";

@Component({
	templateUrl: './policy-tracker-detail.page.html',
	styleUrls: ['./policy-tracker-detail.page.scss']
})
export class PolicyTrackerDetailPage implements OnInit {
  @ViewChild("tabGroup", { static: false }) tabGroup!: MatTabGroup;
  @ViewChild('marketChart') marketChart!: MarketChartComponent;

  constructor(
    private router: Router,
    private _route: ActivatedRoute, 
    private _policyTrackerSerice: PolicyTrackerService,
    private _climatePolicyService: ClimatePolicyService,
    private renderer: Renderer2, 
    private el: ElementRef,
    private _priceDataService: PriceDataService
  ) {}
 
  ccmsName!: string;
  public policyTracker!: PolicyTracker;
  public viewModel!: PolicyTrackerDetailViewModel;
  public region!: string;
  public countryId!: number;
  public EuropeanUnion = PolicyTrackerConstants.EuropeanUnion;
  public Sakhalin = PolicyTrackerConstants.Sakhalin;
  public hasChart = false;
  public hasPolicies = false;
  public policies!: ClimatePolicy[];
  chartLoaded = false;
 
  async ngOnInit() {
    this._route.paramMap.subscribe((params: ParamMap) => {
      const param = params.get('marketName');
      if (param) {
        this.ccmsName = param;
      }
      this.load();
    });
  }

  public back() {
    this.router.navigate(["/policy-tracker"]);
}

  async load() {
    this.policyTracker = await this._policyTrackerSerice.getPolicyTrackerByName(this.ccmsName);
    this.viewModel = new PolicyTrackerDetailViewModel(this.policyTracker);
    this.hasChart = this.getCharts(this.policyTracker.name).length > 0;

    if (this.policyTracker.country.name != this.policyTracker.regions[0]) {
      this.region = this.policyTracker.regions[0];
    }    
  
    if (this.policyTracker.name != PolicyTrackerConstants.EU_ETS && this.policyTracker.name != "EU-ETS2") {
      this.countryId = this.policyTracker.country.id;
      const euEts = await this._policyTrackerSerice.getPolicyTrackerByName(PolicyTrackerConstants.EU_ETS);
      if (euEts.regions.includes(this.policyTracker.country.name)) {
        this.region = PolicyTrackerConstants.EuropeanUnion;
      }
      this.policies = await this.loadClimatePolicies(this.policyTracker.country.alpha3Code, this.region);
    } else {
      this.region = PolicyTrackerConstants.EuropeanUnion;
      this.policies = await this.loadClimatePolicies(PolicyTrackerConstants.EU_ALPHA3_CODE);
    }
      
    this.hasPolicies = this.policies.length > 0;
   
    await this.displayComponents();
  }

  private async displayComponents() {
    let chartId = "marketChart";
   
    if (!this.hasChart || !this.hasPolicies) {
      let el = this.el.nativeElement.querySelector('#tabGroup');
      this.renderer.addClass(el, 'hide-chart');
    }

    if (this.hasChart) {
      await this.loadChart(this.policyTracker.name, chartId);
    } 
  }

  private async loadChart(name: string, chartId: string) {
    this.chartLoaded = true;
    
    if (chartId !== "marketChart" || !this.marketChart) {
      return;
    }
    
    const markets = this.getCharts(name);
    if (markets.length === 0) {
      return;
    }

    await this.loadMarketCharts(markets);
  }

  private async loadMarketCharts(markets: string[]) {
    for (const name of markets) {
        const market = Markets.getMarketByName(name);
        if (!market) continue;
        
        const data = await this._priceDataService.getChartData(market.marketType, market.symbol);
        if (data) {
            this.marketChart.seriesAdded.next({id: name, color: Markets.getColor(market), data: data});
        }
    }
  }

  private getCharts(name: string): string[] {
    let markets: string[] = [];
    switch(name) {
      case PolicyTrackerConstants.EU_ETS:
        markets.push(Markets.EuEts.name);  
        break;
      case PolicyTrackerConstants.China:
        markets.push(Markets.Chinese.name);
        break;
      case PolicyTrackerConstants.Alberta:
        markets.push(Markets.Alberta.name);
        break;
      case PolicyTrackerConstants.NewZealand:
        markets.push(Markets.NewZealand.name);
        break;
      case PolicyTrackerConstants.RGGI:
        markets.push(Markets.RGGI.name);
        break;
      case PolicyTrackerConstants.Canada:
        markets.push(Markets.Federal.name);
        break;
      case PolicyTrackerConstants.California:
        markets.push(Markets.WCI.name);
        markets.push(Markets.LCFS.name);
        break
      case PolicyTrackerConstants.Quebec:
        markets.push(Markets.WCI.name);
        break;
      case PolicyTrackerConstants.Austrailia:
        markets.push(Markets.Australia.name);
        break;
    }

    return markets;
  }

  async loadClimatePolicies(countryAlpha3Code: string, region?: string): Promise<ClimatePolicy[]> {
    let policies: ClimatePolicy[] = [];
    if (region) {
      policies = await this._climatePolicyService.getClimatePoliciesByCountryAndRegion(countryAlpha3Code, region);
    }

    if (!policies || policies.length === 0) {
      policies = await this._climatePolicyService.getClimatePoliciesByCountry(countryAlpha3Code);
    }

    return policies;
  }
}