import { Component, ComponentRef, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { Observable } from 'rxjs';
import { ResourcePermission } from '~/models/shared/permissions';

export interface Tab {
    title: string;
    type?: ComponentRef<any>;
    inputs?: any;
    outputs?: any,

    // Optional permission to control access to the tab
    resourcePermission?: ResourcePermission
}

@Component({
    selector: 'tabs',
    templateUrl: './tabs.component.html',
    styleUrls: ['./tabs.component.scss']
})

export class TabsComponent implements OnInit {
    @Input() tabs: Tab[] = [];
    @Input() activeTab: Tab | undefined;
    @Input() share: boolean = false;
    @Input() urlParams: string = "";
    @Input() beforeTabChange!: ($event) => Observable<boolean>;
    @Output() messageEvent: EventEmitter<any> = new EventEmitter();
    @Output() tabChanged: EventEmitter<{ from: any, to: any }> = new EventEmitter();

    public title: string = "";
    public type: ComponentRef<any> | undefined;
    public inputs: any = {};
    public outputs: any = {};

    public sharedUrl: string = "";

	  //******************************************************************************
	  //  Page Life-cycle Methods
	  //******************************************************************************
    public ngOnInit() {

        if (this.tabs.length > 0) {
            if (!this.activeTab) {
                this.activeTab = this.tabs[0];
            }

            this.sharedUrl = `${window.location.href.split('?')[0]}?tab=${this.activeTab.title}&${this.urlParams}`;
        } 
    }

    public setActiveTab(tab: any) {
      if (this.beforeTabChange) {
        this.beforeTabChange({
          from: this.activeTab,
          to: tab
        }).subscribe(allowed => {
          if (allowed) {
            this.changeTab(tab);
          }
        });
      } else {
        this.changeTab(tab);
      }
    }

    private changeTab(tab: any) {
      if (tab != this.activeTab) {
        this.tabChanged.emit({
            from: this.activeTab,
            to: tab
        });
      }
      
      this.activeTab = tab;
      this.sharedUrl = `${window.location.href.split('?')[0]}?tab=${this.activeTab!.title}&${this.urlParams}`;
    }

	  public copied() {
		  this.messageEvent.emit("Link has been Copied!");
	  }
}
