import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Subject } from 'rxjs';

@Component({
  selector: 'confirm-popup',
  templateUrl: './confirm-popup.component.html',
  styleUrls: ['./confirm-popup.component.scss']
})
export class ConfirmPopUpComponent {
  @Input() message!: string;
  @Output() onCancel = new EventEmitter<void>();
  @Output() onOk = new EventEmitter<void>();

  private resultSubject = new Subject<string>();

  handleCancel(): void {
    this.onCancel.emit();
    this.resultSubject.next('cancel');
    this.resultSubject.complete();
  }

  handleOk(): void {
    this.onOk.emit();
    this.resultSubject.next('ok');
    this.resultSubject.complete();
  }

  getResult(): Subject<string> {
    return this.resultSubject;
  }
}
