import { AfterViewInit, Component, EventEmitter, Input, OnDestroy, Output, ViewChild } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { MatPaginator, PageEvent } from "@angular/material/paginator";
import { MatSort, Sort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { Subscription, debounceTime, distinctUntilChanged, interval, tap } from "rxjs";
import { EventLog, JobDefinition, JobEventLogRequestParameters, PaginationInfo, QueuedJob } from "~/models/shared/job/job.model";
import { Paging, RequestParameters, SearchFilter, SortOption } from "~/models/shared/request-parameters";
import { JobService } from "~/services/shared/job/job.service";
import { ColumnDialogComponent } from "../column-dialog/column-dialog.component";
import { FormControl } from "@angular/forms";
import { MatSelectChange } from "@angular/material/select";
import { ExcelExportService } from "~/services/shared/export-excel/export-excel-service";
import { DatePipe } from "@angular/common";

@Component({
  selector: 'ccms-job-details',
  templateUrl: './job-details.component.html',
  styleUrls: ['./job-details.component.scss']
})
export class JobDetailsComponent implements AfterViewInit, OnDestroy {
  @Input() jobDefinition!: JobDefinition;
  @Input() set jobInstance(job: QueuedJob) {
    this.queuedJob = job;
    if (this.isExpandedFlag) {
      this.loadJobLogs(this.getPage(), this.getSort(), this.keyword);
    }
  }
  @Input() set isExpanded(flag: boolean) {
    this.isExpandedFlag = flag;
    if (this.isExpandedFlag) {
      this.loadJobLogs(this.getPage(), this.getSort());
    } else {
      if (this.jobsRefreshSubscription) {
        this.jobsRefreshSubscription.unsubscribe();
        this.jobsRefreshSubscription = null;
      }
    }
  }
  @Input() isExpandedFlag = false;

  @Output() refreshEvent: EventEmitter<string> = new EventEmitter<string>();

  private refreshInterval = 20000; // 20 seconds
  private jobsRefreshSubscription!: Subscription | null;
  
  public queuedJob!: QueuedJob;
  public dataSource = new MatTableDataSource<EventLog>();
  public pageInfo!: PaginationInfo;
  public lastUpdated!: Date;
  public isExporting = false;
  public categories: string[] = [];
  public keyword: string | undefined;
  public selectedCategories: string[] = [];

  searchControl = new FormControl();
  private searchSubscription!: Subscription;
  
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;
  
  displayedColumns: string[] = ['type', 'category', 'message', 'timestamp'];
  selectedLogTypes: string[] = [];
  
  constructor(private jobService: JobService, 
    private dialog: MatDialog,
    private readonly exportService: ExcelExportService,
    private datePipe: DatePipe) {
    // Set up the debouncing here
    this.searchSubscription = this.searchControl.valueChanges.pipe(
      debounceTime(300), // debounce for 300ms
      distinctUntilChanged(),
      tap(term => {
        this.keyword = term;
        this.loadJobLogs(this.getPage(), this.getSort(), this.keyword);
      })
    ).subscribe();
  }

  ngOnDestroy(): void {
    if (this.jobsRefreshSubscription) {
      this.jobsRefreshSubscription.unsubscribe();
    }
    if (this.searchSubscription) {
      this.searchSubscription.unsubscribe();
    }
  }  

  ngAfterViewInit() {
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
  }

  public openFullMessageDialog(message: string): void {
    this.dialog.open(ColumnDialogComponent, {
      width: '800px',
      data: { message }
    });
  }
  
  public loadJobLogs(pageEvent: PageEvent, sortEvent: Sort | null, keyword?: string) {
    if (this.queuedJob) {
      this.jobService.getJobEventLogs(this.buildParameters(pageEvent, sortEvent, keyword))
        .subscribe({
          next: (data) => {
            this.dataSource.data = data.values;
            this.pageInfo = data.metadata;
            this.lastUpdated = new Date();
          },
          error: (error) => {
            console.error("Error occurred:", error);
          }
        });
      this.jobService.getJobEventLogCategories(this.queuedJob.name)
      .subscribe({
        next: (data) => {
            this.categories = data;
          }  
        });

      this.startRefresh();
    }
  }  

  private buildParameters(pageEvent: PageEvent, sortEvent: Sort | null, keyword: string | undefined): RequestParameters {
    const params = new JobEventLogRequestParameters();
    params.Name = this.queuedJob.name;
    params.Paging = new Paging();
    params.Paging.currentPage = pageEvent.pageIndex;
    params.Paging.pageSize = pageEvent.pageSize;

    this.buildSortParameters(params, sortEvent);

    if (keyword) {
      const filter = new SearchFilter();
      filter.Field = "keyword";
      filter.Value = keyword;
      params.Terms.push(filter);
    }

    if (this.selectedLogTypes.length > 0) {
      const filter = new SearchFilter();
      filter.Field = "logTypes";
      filter.Value = this.selectedLogTypes.join(",");
      params.Terms.push(filter);
    }

    if (this.selectedCategories.length > 0) {
      const filter = new SearchFilter();
      filter.Field = "categories";
      filter.Value = this.selectedCategories.join(",");
      params.Terms.push(filter);
    }

    return params;
  }

  private buildSortParameters(params: JobEventLogRequestParameters, event: Sort | null) {
    if (event) {
      const option = new SortOption();
      if (event.active) {
        option.field = event.active;
        option.direction = event.direction.toString() === "asc" ? 0 : 1;
      } else {
        option.field = 'timestamp';
        option.direction = 1;
      }
      
      params.SortingOptions.push(option);
    }
  }

  onPageChange(event: PageEvent) {
    event.pageIndex = event.pageIndex + 1;

    this.loadJobLogs(event, this.getSort(), this.keyword);  // Reload logs based on the new pagination settings
  }

  private startRefresh() {
    if (this.shouldRefresh(this.queuedJob) && (!this.jobsRefreshSubscription)) { 
      this.jobsRefreshSubscription = interval(this.refreshInterval).subscribe(() => {
        if (this.queuedJob) {
          this.refreshEvent.emit(this.queuedJob.name);
        }
      });
    }
  }

  private getPage(): PageEvent {
    const page = new PageEvent();
    if (this.paginator) {
      page.pageIndex = this.paginator.pageIndex + 1;
      page.pageSize = this.paginator.pageSize;
    } else {
      page.pageIndex = 1;
      page.pageSize = 20;
    }

    return page;
  }

  private getSort(): Sort {
    if (this.sort) {
      return { active: this.sort.active, direction: this.sort.direction };
    } else {
      return { active: 'timestamp', direction: 'desc' };
    }
  }

  shouldRefresh(job: QueuedJob) {
    if (job && (job.status === 'Running' || job.status === 'Queued')) {
      return true;
    } 

    return false;
  }

  onSortChange(event: Sort) {
    this.loadJobLogs(this.getPage(), event, this.keyword);
  }

  refreshJobInstance() {
    this.refreshEvent.emit(this.queuedJob.name);
  }

  clearSearch() {
    this.searchControl.setValue('');
  }

  onLogTypesChanged(event: MatSelectChange) { 
    this.loadJobLogs(this.getPage(), this.getSort(), this.keyword);
  }

  onChangeCategories(event: string[]) {
    this.selectedCategories = event;
    this.loadJobLogs(this.getPage(), this.getSort(), this.keyword);
  }

  export() {
    const pageEvent = new PageEvent();
    pageEvent.pageIndex = 1;
    pageEvent.pageSize = 1000;
    const sort = this.getSort();
    const logs: EventLog[] = [];

    this.isExporting = true;
    this.exportLogs(pageEvent, sort, logs, this.keyword);
  }
  
  private exportLogs(pageEvent: PageEvent, sort: any, logs: EventLog[], keyword: string | undefined) {
      this.jobService.getJobEventLogs(this.buildParameters(pageEvent, sort, keyword)).subscribe(data => {
        data.values.forEach(log => {
          log.timestamp = this.datePipe.transform(log.timestamp, 'medium') as string;
          logs.push(log);
        });
  
        // If there are more pages, load next page
        if (pageEvent.pageIndex < data.metadata.totalPages) {
          pageEvent.pageIndex++;
          this.exportLogs(pageEvent, sort, logs, keyword);
        } else {
          if (logs.length) {
            const properties = ["Source", "LogType", "Category", "Message", "Timestamp"];
            this.exportService.export(logs, 'web-job-event-logs', properties);
            this.isExporting = false;
          }
        }
      });
  }  
}