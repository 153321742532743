import { Component } from "@angular/core";
import { BaseComponent } from "~/pages/base.component";


@Component({
    selector: 'offsetprojects-report',
    templateUrl: './offset-projects.component.html',
    styleUrls: ['./offset-projects.component.scss']
})
export class OffsetProjectReportComponent extends BaseComponent {
    constructor() {
        super();
    }
    public reportId: string = "";
    
    public async ngOnInit() {
        await super.ngOnInit();
        this.reportId = this.appConfig.portfolio;
    }
}