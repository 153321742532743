import { Injectable } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class RouteChangedService {
  private previousUrl: string = '';
  private currentUrl: string = '';

  constructor(private router: Router) {
    this.router.events.subscribe((event: any) => {
      if (event instanceof NavigationEnd) {
        this.previousUrl = this.currentUrl;
        this.currentUrl = event.urlAfterRedirects;
        console.log(this.previousUrl);
        console.log(this.currentUrl);
      }
    });
  }

  getPreviousUrl() {
    return this.previousUrl;
  }

  getCurrentUrl() {
    return this.currentUrl;
  }

  getRouteChanged() {
    return this.previousUrl != this.currentUrl;
  }

  public clearRouteChange() {
    this.previousUrl = "";
    this.currentUrl = "";
  }
}
