<div class="file-upload-container">
  <div class="file-select-control">
    <div class="file-select"
      (dragover)="onDragOver($event)"
      (dragleave)="onDragLeave($event)"
      (drop)="onDrop($event)"
      [ngClass]="dropZoneClass">
      <span matTooltip="upload-icon" class="file-select-icon icon-upload"></span>
      <span class="drag-drop-text">{{ dropFileMessage }}</span>
      <input #fileSelect type="file" id="file-input"
        [accept]="options.allowedFileTypes"
        [multiple]="options.multiple"
        (change)="onFilesSelected($event)" hidden>
      <label for="file-input" class="file-select-btn">click here to browse</label>
    </div>
  </div>
  <ng-container *ngIf="fileList.length > 0">
    <ng-content select="file-list"></ng-content>
    <ng-container *ngTemplateOutlet="fileListTemplate || defaultFileListTemplate; context: {
      fileList: fileList,
      isDirty: isDirty,
      onRemoveFile: onRemoveFile
    }"></ng-container>
  </ng-container>
  <div class="actions" *ngIf="canReset || (fileList.length > 5 && !options.autoUpload)">
    <button *ngIf="canReset" class="clear-btn" (click)="onReset()"
      matTooltip="Reset control to initial state and undo all changes">Reset</button>
    <button *ngIf="fileList?.length > 5" class="clear-btn" (click)="onRemoveAllFiles()"
      matTooltip="Remove all files">Clear</button>
    <button *ngIf="options.showUploadButton" class="upload-btn" (click)="onBeginUpload()">Upload</button>
  </div>

  <ng-template #defaultFileListTemplate>
    <ul class="file-list" *ngIf="fileList.length > 0">
      <li *ngFor="let fileContext of fileList; let i = index" class="file-item">
        <div class="file-info">
          <span class="file-name">{{ fileContext.file.name }}</span>
          <ng-container *ngIf="options.showFileSize && fileContext.file.size">        
            <span class="file-size">{{ fileContext.file.size | fileSize }}</span>
          </ng-container>
          <ng-container *ngIf="options.showUploadStatus && fileContext.canUpload">
            <!-- Ready for upload -->
            <span class="file-upload-pending" *ngIf="!fileContext.isUploading && fileContext.progress != 100">
              Ready to upload
            </span>
            <!-- Upload success -->
            <span class="file-upload-success" *ngIf="fileContext.progress == 100">
              File uploaded successfully
            </span>
            <!-- Upload progress-->
            <div class="progress" *ngIf="fileContext.isUploading">            
              <div class="progress-bar" role="progressbar" 
                  [style.width]="fileContext.progress + '%'" 
                  aria-valuemin="0" aria-valuemax="100">
              </div>
              <span class="progress-value">
                {{fileContext.progress}}%
              </span>
            </div>
          </ng-container>
        </div>
        <span matTooltip="remove" class="remove-file-btn icon-trash-can" aria-label="remove"
          (click)="onRemoveFile(fileContext)"></span>
      </li>
    </ul>
  </ng-template>
</div>
