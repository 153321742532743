import { animate, state, style, transition, trigger } from "@angular/animations";
import { Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { MatSort, Sort } from "@angular/material/sort";
import { JobDefinition, JobDefinitionsWithQueuedJob } from "~/models/shared/job/job.model";
import { RequestParameters, SortOption } from "~/models/shared/request-parameters";
import { JobService } from "~/services/shared/job/job.service";
import { RunDialogComponent } from "../run-dialog/run-dialog.component";
import { Observable, Subscription } from "rxjs";

@Component({
  selector: 'ccms-jobs',
  templateUrl: './jobs.component.html',
  styleUrls: ['./jobs.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({height: '0px', minHeight: '0'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class JobsComponent implements OnInit, OnDestroy {
  @ViewChild(MatSort) sort!: MatSort;
  
  isLoading = false;
  displayedColumns: string[] = ['Name', 'Recurrence', 'Status', 'StartTime', 'EndTime', 'JobActions'];
  dataSource: JobDefinitionsWithQueuedJob[] = [];
  
  private subscriptions: Subscription[] = [];
  private expandedRowIds: string[] = [];

  constructor(private jobService: JobService,
    private dialog: MatDialog,) {}

  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
  }
  
  ngOnInit(): void {
    this.loadJobs(this.getSort());
  }  

  private loadJobs(sort: Sort) {
    this.isLoading = true;
    const sub = this.jobService.getJobDefinitionsWithLatestQueuedJob(this.buildParameters(sort)).subscribe(jobs => {
      jobs.values.forEach(job => {
        if (this.expandedRowIds.includes(job.jobDefinition.name)) {
          job['expanded'] = true;
        }
      });
  
      this.dataSource = jobs.values;
      this.isLoading = false;
    });
    this.subscriptions.push(sub);
  }
  
  startJob(job: JobDefinition) {
    const sub = this.openRunDialog(job).subscribe(result => {
      const reset = result.resetState;
      if (result.action === 'run' ) {
        this.queueTheJob(job, reset);
      }
    });
    this.subscriptions.push(sub);
  }
  
  queueTheJob(job: JobDefinition, reset: boolean) {
    // Use the reset flag in your logic here if needed.
    const sub = this.jobService.queueJob(job.name, reset).subscribe(jobResponse => {
      const jobEntry = this.dataSource.find(j => j.jobDefinition.name === jobResponse.name);
      if (jobEntry) {
        jobEntry.queuedJob = jobResponse;
      }
    });
    this.subscriptions.push(sub);
  }
  
  stopJob(jobId: number) {
    console.log(`Stopping job with ID: ${jobId}`);
    const sub = this.jobService.cancelJob(jobId).subscribe(job => {
      // find the job by jobId in the dataSource array
      const jobEntry = this.dataSource.find(j => j.queuedJob?.id === jobId);
      if (jobEntry) {
        jobEntry.queuedJob = job; // reassign the queuedJob property
      }
    });
    this.subscriptions.push(sub);
  }

  refreshJob(jobName: string) {
    console.log(`Refreshing job with name: ${jobName}`);
    const sub = this.jobService.getQueuedJob(jobName).subscribe(job => {
      // find the job by jobId in the dataSource array
      const jobEntry = this.dataSource.find(j => j.jobDefinition.name === jobName);
      if (jobEntry) {
        jobEntry.queuedJob = job; // reassign the queuedJob property
      }
    });
    this.subscriptions.push(sub);
  }
  
  viewJobDetails(jobId: string) {
    console.log(`Viewing details for job with ID: ${jobId}`);
  }

  expandRow(row: JobDefinitionsWithQueuedJob) {
    if (row['expanded'] === true) {
      row['expanded'] = false;
      const index = this.expandedRowIds.indexOf(row.jobDefinition.name);
      if (index > -1) {
        this.expandedRowIds.splice(index, 1);
      }
    } else {
      row['expanded'] = true;
      this.expandedRowIds.push(row.jobDefinition.name);
    }
  }  

  canCancelJob(status: string) {
    return status === 'Queued';
  }

  isJobRunning(status: string) {
    return status === 'Queued' || status === 'Running';
  }

  private buildParameters(event: Sort | null): RequestParameters {
    const params = new RequestParameters();

    if (event) {
      const option = new SortOption();
      if (event.active) {
        option.field = event.active;
        option.direction = event.direction.toString() === "asc" ? 0 : 1;
      } else {
        option.field = 'name';
        option.direction = 0;
      }
      
      params.SortingOptions.push(option);
    }

    return params;
  }

  private getSort(): Sort {
    if (this.sort) {
      return { active: this.sort.active, direction: this.sort.direction };
    } else {
      return { active: 'name', direction: 'asc' };
    }
  }

  onSortChange(event: Sort) {
    this.loadJobs(event);
  }

  openRunDialog(job: JobDefinition): Observable<any> {
    const dialogRef = this.dialog.open(RunDialogComponent, {
      data: { job: job }
    });
  
    // The dialog component emits the checkbox state through its onClose event
    return dialogRef.afterClosed();
  }
}