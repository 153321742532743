import { AfterViewInit, Component, EventEmitter, Input, OnDestroy, Output, ViewChild } from "@angular/core";
import { Router } from "@angular/router";
import { ColorType, IChartApi, ISeriesApi, LineStyle, LineStyleOptions, SeriesOptionsMap, createChart } from "lightweight-charts";
import { Subject, debounceTime, fromEvent } from "rxjs";
import { PriceChartLegendComponent } from "../price-chart-legend/price-chart-legend.component";
import { ResizeEventService } from "~/services/shared/resize-event.service";
import Dictionary from "../dictionary/dictionary";

export interface ChartDataPoint {
  time: string;
  value: number;
}

interface MarketData {
  id: string;
  data: ChartDataPoint[];
  color: string;
}

export enum SeriesSelectedBehavior {
  HighlightSelected,
  HideOthers
}

export interface IMarketChartSeries {
  name: string;
  color: string;
  series: ISeriesApi<keyof SeriesOptionsMap>;
  points: ChartDataPoint[];
}

@Component({
  selector: 'ccms-market-chart',
  templateUrl: './market-chart.component.html',
  styleUrls: ['./market-chart.component.scss']
})
export class MarketChartComponent implements OnDestroy, AfterViewInit {
  @ViewChild('marketChartLegend', { static: true }) marketChartLegend!: PriceChartLegendComponent;
  @Input() seriesSelectedBehavior: SeriesSelectedBehavior = SeriesSelectedBehavior.HighlightSelected;
  @Output() onSeriesSelected = new EventEmitter<string>();
  @Output() onSeriesUnselected = new EventEmitter<string>();

  seriesAdded: Subject<MarketData> = new Subject<MarketData>();
  seriesSelected: Subject<string> = new Subject<string>();
  seriesUnselected: Subject<string> = new Subject<string>();
  
  chart!: IChartApi;
  series!: Dictionary<IMarketChartSeries>;
  seriesHidden!: IMarketChartSeries[];
  marketName!: string;
 
  constructor( 
    private router: Router,
    private _resizeEventService: ResizeEventService
  ) { 
    fromEvent(window, 'resize').pipe(debounceTime(10))
        .subscribe(() => {
          this.resizeChart();
        });

    this.router.events.subscribe(() => {
      this.resizeChart();
    });
  }

  ngAfterViewInit(): void {
    this.load();
  }

  private async load() {
    this.series = new Dictionary<IMarketChartSeries>();
    this.seriesHidden = [];

    this.seriesAdded.subscribe({
      next: (v) => {
        this.chartData(v.id, v.color, v.data);
        this.marketChartLegend.seriesAdded.next({name: v.id, symbol: v.id, color: v.color});
      }
    });

    this.seriesSelected.subscribe({
      next: (v) => this.setSeriesSelected(v)
    });
    this.seriesUnselected.subscribe({
      next: (v) => this.setSeriesUnselected(v)
    });

    const chartOptions = { 
      layout: { textColor: 'black', background: {type: ColorType.Solid, color: 'white'}},
      timeScale: { minBarSpacing: 0.001 },
    };

    const chart = document.getElementById('chart');
    if (chart) {
      this.chart = createChart(chart, chartOptions);
    }

    this._resizeEventService.getResizeEvent().subscribe({
      next: () => {
        setTimeout(this.resizeChart.bind(this), 100);
      }
    });    
  }

  ngOnDestroy(): void {
    this.seriesAdded.unsubscribe();
    this.seriesSelected.unsubscribe();
    this.seriesUnselected.unsubscribe();
  }

  private async chartData(name: string, color: string, data: ChartDataPoint[]) { 
    this.addSeries(name, color, data)
    this.chart.timeScale().fitContent();
  }

  private async addSeries(name: string, color: string, points: ChartDataPoint[]) {
      if (!points || points.length == 0) {
        return;
      }

      const lineSeries = this.chart.addLineSeries({
        color: color
      });
      lineSeries.applyOptions(this.seriesNormalOptions);
      lineSeries.setData(points);

      const series: IMarketChartSeries = { 
        name: name,
        color: color,
        series: lineSeries,
        points: points
      }
      this.series.add(name, series);
  }

  private get seriesNormalOptions(): LineStyleOptions {
    const lineStyleOptions = <LineStyleOptions>{};
    lineStyleOptions.lineStyle = LineStyle.Solid;
    lineStyleOptions.lineWidth = 2;

    return lineStyleOptions;
  }

  private get seriesSelectedOptions(): LineStyleOptions {
    const lineStyleOptions = <LineStyleOptions>{};
    lineStyleOptions.lineStyle = LineStyle.LargeDashed;
    lineStyleOptions.lineWidth = 4;

    return lineStyleOptions;
  }

  private setSeriesSelected(symbol: string) {
    if (this.seriesSelectedBehavior == SeriesSelectedBehavior.HighlightSelected) {
      const series = this.series.getItem(symbol);
      if (series) {
        series.series.applyOptions(this.seriesSelectedOptions);
      }
    }
    if (this.seriesSelectedBehavior == SeriesSelectedBehavior.HideOthers) {
      const others = this.getOtherSeries(symbol);
      if (others) {
        this.seriesHidden = [];
        others.forEach(other => {
          this.seriesHidden.push(other);
          this.series.removeItem(other.name);
          this.chart.removeSeries(other.series);
        });
      }
    }
  }
  
  private setSeriesUnselected(symbol: string) {
    if (this.seriesSelectedBehavior == SeriesSelectedBehavior.HighlightSelected) {
      const series = this.series.getItem(symbol);
      if (series) {
        series.series.applyOptions(this.seriesNormalOptions);
      }
    }
    if (this.seriesSelectedBehavior == SeriesSelectedBehavior.HideOthers) {
      if (this.seriesHidden.length > 0) {
        this.seriesHidden.forEach(series => {
          const line = this.chart.addLineSeries({ color: series.color});
          line.applyOptions(this.seriesNormalOptions);
          line.setData(series.points);
          const newSeries: IMarketChartSeries = { 
            name: series.name,
            color: series.color,
            series: line,
            points: series.points
          }
          this.series.add(series.name, newSeries);
        });
        this.seriesHidden = [];
      }
    }
  }

  getOtherSeries(excludedSeriesName: string): IMarketChartSeries[] {
    let otherSeries: IMarketChartSeries[] = [];
    let keys = this.series.getKeys();
  
    for (let key of keys) {
      if (key !== excludedSeriesName) {
        otherSeries.push(this.series.getItem(key));
      }
    }
  
    return otherSeries;
  }  

  resizeChart() {
    if (!this.chart) {
      return;
    }

    const container = document.querySelector('.chart-container');
    if (container) {
      const rect = container.getBoundingClientRect();

      this.chart.applyOptions({
        width: rect.width,
        height: rect.height
      });
    }
    
    this.chart.timeScale().fitContent();
  }

  onLegendHoverStart(symbol: string) {
    this.seriesSelected.next(symbol);
    this.onSeriesSelected.emit(symbol);
  }

  onLegendHoverEnd(symbol: string) {
    this.seriesUnselected.next(symbol);
    this.onSeriesUnselected.emit(symbol);
  }
}