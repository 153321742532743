import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ResizeEventService {
  private static subject = new Subject<any>();

  sendResizeEvent(event: any) {
    ResizeEventService.subject.next(event);
  }

  getResizeEvent() {
    return ResizeEventService.subject.asObservable();
  }
}
