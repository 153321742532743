import { Injectable } from '@angular/core';

import { HttpService } from '../core/http.service';

@Injectable()
export class PriceDeckService {

    constructor(
        private httpService: HttpService,
    ) { }

    public async getValues(): Promise<any> {
        try {
            return await this.httpService.Get(`/price-decks`).then((data: any) => {
                return data;
            });
        } catch (error: any) {
            throw error;
        }
    }
}
