import { formatCurrency, formatNumber } from '@angular/common';
import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { PatternCreator } from './textbox-formatter/pattern-creator';
    
@Component({ 
  selector: 'ccms-textbox-formatter', 
  template: "<input #text type=\"text\" [class]=\"class\" [style]=\"style\" [placeholder]=\"hint\" (keydown)=\"onKeyDown($event)\" (keyup)=\"onKeyUp($event)\" (blur)=\"onBlur($event)\" (paste)=\"onPaste($event)\" [value]=\"value\" />", 
  styles: ["::placeholder{color:#dcdcdc;opacity:1}:-ms-input-placeholder{color:#dcdcdc}::-ms-input-placeholder{color:#dcdcdc}\n"] 
})   
export class TextboxFormatterComponent {
    @Input() validCharacters: any;
    @Input() value: any;
    @Input() pattern: any;
    @Input() locale: any;
    @Input() hint: any;
    @Input() style: any;
    @Input() class: any;
    @Output() enterCallBackEvent: EventEmitter<string>;
    @ViewChild('text') text: any;
    
    pArray: any[];
    fArray: any[];
  
    constructor() {
        this.validCharacters = null;
        this.value = null;
        this.pattern = null;
        this.locale = "en-US";
        this.hint = "";
        this.style = "";
        this.class = "";
        this.enterCallBackEvent = new EventEmitter();
        this.pArray = [];
        this.fArray = [];
    }
    
    ngOnInit() {
      if (this.validCharacters) {
          this.validCharacters = this.validCharacters.toString().toLocaleLowerCase();
          
          if (this.validCharacters === "custom" && this.pattern
              && this.pattern.indexOf("#") > -1
              && !this.pattern.startsWith("[")
              && !this.pattern.endsWith("]")) {
              this.hint = this.pattern;
              const patternCreator = new PatternCreator();
              this.pArray = patternCreator.pArray;
              this.fArray = patternCreator.fArray;
          }
      }
    } 
 
    isSpecialKey(event): boolean {
      const specialKeys = ["Backspace", "Tab", "ArrowLeft", "ArrowRight", "Meta", "Control"];
      return specialKeys.includes(event.key) || (event.metaKey && ["v", "c"].includes(event.key));
    }
  
    isValidAlphaKey(key: string): boolean {
      return /[a-zA-Z]/.test(key);
    }

    isValidNumericKey(key: string): boolean {
      return /\d/.test(key);
    }

    isValidAlphanumericKey(key: string): boolean {
      return /[a-zA-Z0-9]/.test(key);
    }

    isValidCustomKey(key: string, text: string): boolean {
      if (this.pattern?.includes("#")
        && !this.pattern.startsWith("[")
        && !this.pattern.endsWith("]")
        && (!/\d/.test(key) || text.length === this.pattern.length)) {
        return false;
      }
      if (this.pattern?.startsWith("[")
        && this.pattern?.endsWith("]")) {
        if (!new RegExp(this.pattern, "g").test(key)) {
            return false;
        }
      }
      return true;
    }
  
    isValidDecimalOrCurrencyKey(key: string, text: string, selectionStart: number | null): boolean {
      const numericRegex = /[-0-9.]/;
  
      if (!numericRegex.exec(key)) return false;
      if (key === "." && text.includes(".")) return false;
      if (key === "-" && (text.includes("-") || selectionStart !== 0)) return false;
      if (this.validCharacters === "currency" && text.split(".").length > 1 && text.split(".")[1].length >= 2) return false;
      return true;
    }   
  
    onKeyDown(event) {
      const text = this.removeSelection(event);
  
      if (this.shouldBlurOnEnter(event.key)) {
          this.blurAndEmit(text);
          return;
      }
  
      if (this.isSpecialKey(event)) return;
  
      if (!this.isKeyValidForMode(event.key, text, event.target)) {
          event.preventDefault();
      }
    }
  
    shouldBlurOnEnter(key) {
      return key === "Enter";
    }
  
    blurAndEmit(text) {
      this.text?.nativeElement.blur();
      if (this.enterCallBackEvent.observed) {
          this.enterCallBackEvent.emit(text);
      }
    }
  
    isKeyValidForMode(key, text, target) {
      switch (this.validCharacters) {
          case "alpha":
              return this.isValidAlphaKey(key);
          case "numeric":
              return this.isValidNumericKey(key);
          case "alphanumeric":
              return this.isValidAlphanumericKey(key);
          case "decimal":
          case "currency":
              return this.isValidDecimalOrCurrencyKey(key, text, target.selectionStart);
          case "custom":
              return this.isValidCustomKey(key, text);
          default:
              return true;
      }
    } 
  
    onKeyUp(event) {
        if (this.validCharacters === "decimal"
            || this.validCharacters === "currency") {
            this.setNumericText(event);
        }
        if (this.validCharacters === "custom" && this.pArray.length > 0) {
            this.setCustomText(event);
        }
    }

    onBlur(event) {
        let text = event.target.value;
        if (this.validCharacters && text && text !== "") {
            text = this.cleanText(text);
            text = this.formatText(text);
            event.target.value = text;
        }
    }

    onPaste(event) {
        let clipboardData = event.clipboardData || event.originalEvent.clipboardData;
        let text = clipboardData.getData('text');
        let textLeft = event.target.value.toString().substring(0, event.target.selectionStart);
        let textRight = event.target.value.toString().substring(event.target.selectionEnd);
        text = textLeft + text + textRight;
        event.preventDefault();
        if (text !== "") {
            text = this.cleanText(text);
            event.target.value = text;
        }
    }

    setNumericText(event) {
        let text = event.target.value;
        let newText = "";
        let beforeLength = text.length;
        let afterLength = 0;
        let cursorStart = event.target.selectionStart;
        let cursor = 0;
        event.preventDefault();
        newText = text.toString().replace(/[^-0-9.]/g, "");
        newText = newText.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        let periodArry = newText.toString().split(".");
        if (periodArry.length > 1) {
          newText = periodArry[0] + "." + periodArry[1].replace(/,/g, "");
        }
        afterLength = newText.length;
        cursor = cursorStart + (afterLength - beforeLength);
        event.target.value = newText;
        event.target.selectionStart = cursor;
        event.target.selectionEnd = cursor;
    }

    setCustomText(event) {
        let text = event.target.value;
        let newText = "";
        let beforeLength = text.length;
        let afterLength = 0;
        let cursorStart = event.target.selectionStart;
        let cursor = 0;
        event.preventDefault();
        if (text === "+1 ")
            text = "";
        newText = text.toString().replace(/\D/g, "");
        for (let x = 0; x < this.pArray.length; x++) {
            newText = newText.replace(new RegExp(this.pArray[x]), this.fArray[x]);
        }
        afterLength = newText.length;
        cursor = cursorStart + (afterLength - beforeLength);
        event.target.value = newText;
        event.target.selectionStart = cursor;
        event.target.selectionEnd = cursor;
    }

    removeSelection(event) {
        let textLeft = event.target.value.toString().substring(0, event.target.selectionStart);
        let textRight = event.target.value.toString().substring(event.target.selectionEnd);
        return textLeft + textRight;
    }

    cleanText(text: string): string {
      text = text.toString();
    
      if (this.isRegexValidCharacterFormat()) {
        text = this.cleanWithRegexFormat(text);
      } else {
        text = this.cleanBasedOnValidCharacter(text);
      }

      return text;
    }

    isRegexValidCharacterFormat(): boolean {
      return this.validCharacters?.toString().startsWith("[");
    }

    cleanWithRegexFormat(text: string): string {
      let format = "[^" + this.validCharacters?.toString().split("[")[1];
      return text.replace(new RegExp(format, "g"), "");
    }

    cleanBasedOnValidCharacter(text: string): string {
      switch (this.validCharacters) {
        case "alpha":
            return text.replace(/[^a-zA-Z]/g, "");
        case "numeric":
            return text.replace(/\D/g, "");
        case "alphanumeric":
            return text.replace(/[^a-zA-Z0-9]/g, "");
        case "decimal":
        case "currency":
            return text.replace(/[^0-9.-]|(?<=[.].*)[.]|(?!^)-/g, "");
        case "custom":
            return this.cleanCustomText(text);
        default:
            return text;
      }
    }

    cleanCustomText(text: string): string {
      if (this.pattern) {
        if (this.isPatternNumeric()) {
            let uniquePatternChars = Array.from(new Set(this.pattern.split(""))).join("");
            let re = "[^0-9" + this.encodeText(uniquePatternChars) + "]";
            text = text.replace(new RegExp(re.replace("#", ""), "g"), "");
            return text.substring(0, this.pattern.length);
        } else if (this.isPatternRegex()) {
            let re = this.pattern.replace("[", "[^");
            if (this.pattern.startsWith("[^")) {
                re = this.pattern.replace("[^", "[");
            }
            return text.replace(new RegExp(re, "g"), "");
        }
      }
      return text;
    }

    isPatternNumeric(): boolean {
      return this.pattern.includes("#") 
        && !this.pattern.startsWith("[") 
        && !this.pattern.endsWith("]");
    }

    isPatternRegex(): boolean {
      return this.pattern.startsWith("[") && this.pattern.endsWith("]");
    }

    formatText(text) {
        if (this.validCharacters === "currency") {
            text = formatCurrency(parseFloat(text), this.locale, this.pattern ?? '$');
        }
        else if (this.validCharacters === "custom") {
            // DO NOTHING
        }
        else {
            {
                if (this.pattern) {
                    text = formatNumber(parseFloat(text), this.locale, this.pattern);
                }
            }
        }
        return text;
    }

    encodeText(text) {
      return text.replace(/[|{}\\()[\]^$+*?.]/g, '\\$&').replace(/\s/, "\\s");
    }
}