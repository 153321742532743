import { Component, TemplateRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, firstValueFrom } from 'rxjs';
import { Filters, FilterType, SDKDataGridColumn, SDKDataGridCustomFilter, SDKDataGridDataset, SDKDataGridOptions, SDKDataGridSettings } from 'sdk-datagrid';
import { Observe } from '~/classes/shared/observe.decorator';
import { StringUtility } from '~/classes/shared/string-utility';
import { BaseComponent } from '~/pages/base.component';
import { DevelopersScreeningService, DeveloperSearchOptions } from '~/services/shared/developers-screening/developers-screening.service';
import { DeveloperSearchResult } from '~/services/shared/developers-screening/models/developer-search-result';
import { DeveloperProfileDto } from '~/services/shared/developers-screening/models/developer-summary';
import { RouteChangedService } from '~/services/shared/route-changed.service';
import { GridName } from '~/services/shared/state/grid/grid-name.enum';
import { arraysAreEqual, getGridPager, setGridPager } from '~/services/shared/state/grid/grid-pager.repository';
import { FilterInfo } from '~/models/shared/filterInfo';
import { SettingsGrid } from '~/services/shared/grid/models/settings-grid.model';
import { GridHandlerService } from '~/services/shared/grid/grid-handler.service';

@Component({
    templateUrl: './developer-screening.component.html',
    styleUrls: ['./developer-screening.component.scss']
})
export class DeveloperScreeningComponent extends BaseComponent implements SettingsGrid {
    private readonly _str = StringUtility;
    
    @ViewChild('creditDeliveryRate') creditDeliveryRate!: TemplateRef<any>;
    @ViewChild('accreditationsTemplate') accreditationsTemplate!: TemplateRef<any>;
    @ViewChild('programsTemplate') programsTemplate!: TemplateRef<any>;
    @ViewChild('protocolsTemplate') protocolsTemplate!: TemplateRef<any>;

	@Observe('isLoading') isLoading$!: Observable<boolean>;

    /**
     * @comment Data is the backing object
     * for the observable data$.
     */
    public data: any = "";  

    public MAXRECORDS = 1000;
    public title = 'Developers Screening';
    public footnote = '';

    public activeView = 'devScreen/search';
    public datasets: SDKDataGridDataset[] = [
        {
            Title: "Developers Screening",
            DbName: "DevelopersScreening/Search"
        }
    ];

    public columns: SDKDataGridColumn[] = [
        { Name: "name", DisplayName: "Name", isVisible: true, showFilter: false },
        { Name: "country", DisplayName: "Developer Country", isVisible: false, FilterMultiSelect: true, FilterValues: [] },
        { Name: "projectCount", DisplayName: "Project Count", isVisible: true, FilterTypes: [Filters.GreaterThanOrEqual] },
        { Name: "accreditations", DisplayName: "Certifications", dataTemplate: () => this.accreditationsTemplate, FilterMultiSelect: true, FilterValues: [] },
        { Name: "programs", DisplayName: "Programs", dataTemplate: () => this.programsTemplate, FilterMultiSelect: true, FilterValues: [] },
        { Name: "protocols", DisplayName: "Protocols", dataTemplate: () => this.protocolsTemplate, FilterMultiSelect: true, FilterValues: [] },
        { Name: "beZeroRatedProjectCount", DisplayName: "BeZero Rating Count", isVisible: true, FilterTypes: [Filters.GreaterThanOrEqual] },
        { Name: "calyxGhgRatedProjectCount", DisplayName: "Calyx GHG Rating Count", isVisible: true, FilterTypes: [Filters.GreaterThanOrEqual] },
        { Name: "sylveraRatedProjectCount", DisplayName: "Sylvera Rating Count", isVisible: true, FilterTypes: [Filters.GreaterThanOrEqual] },
        { Name: "totalCreditsGenerated", DisplayName: "Total Credits Generated", isVisible: true, FilterTypes: [Filters.GreaterThanOrEqual] },
        { Name: "creditDeliveryRate", DisplayName: "Credit Delivery Rate", dataTemplate: () => this.creditDeliveryRate, FilterTypes: [Filters.GreaterThanOrEqual] }
    ];

    public customFilters: SDKDataGridCustomFilter[] = [
        { Name: "*keyword", DisplayName: "* Keyword Search", Type: FilterType.Textbox, FilterTypes: [Filters.Contains] },
        { Name: "hasProjectsWithTypes", DisplayName: "Types", FilterMultiSelect: true, FilterValues: [], Type: FilterType.Dropdown, FilterTypes: [Filters.Equals] },
        { Name: "hasProjectsInRegions", DisplayName: "Regions", FilterMultiSelect: true, FilterValues: [], Type: FilterType.Dropdown, FilterTypes: [Filters.Equals] },
        { Name: "hasProjectsInCountries", DisplayName: "Countries", FilterMultiSelect: true, FilterValues: [], Type: FilterType.Dropdown, FilterTypes: [Filters.Equals] }
    ]

    public gridOptions: SDKDataGridOptions = {
          settings: true,
		  columnSettings: true,
		  filtering: true,
		  sorting: true,
		  formulas: false,
		  charts: false,
		  export: false,
		  expandableRows: false,
		  selectableRows: false,
		  autoClosePanel: true
    };

    // Settings grid interface Implementation:
    public uniqueIdentifier = "developers-screening.developer-screening";
    public allSettings: SDKDataGridSettings[] = [];
    public multiSelectFilters: FilterInfo[] = [];
    public errorHanlder = (message, error) => {
        console.error(`${message} ${error?.message}`);
    }

    public fullData: any = "";
    public dbPage: number = 0;
    public dbTotal: number = 0;
    public error: string = "";

    constructor(
        private readonly developerScreeningService: DevelopersScreeningService,
        private readonly router: Router,
        private routeChangedService: RouteChangedService,
        private gridHandlerService: GridHandlerService
    ) {
        super();
    }

    public async ngOnInit() {
        await super.ngOnInit();
        await this.loadMultiSelectFilters();
        this.gridHandlerService.loadSettings(this);
    }

    //*************************************************************************
    //  Public Methods
    //*************************************************************************
    public async loadData(event: any = null) {
        this.isLoading = true;

        try {
            this.MAXRECORDS = event.rows; // Overrides total records due to slowness in API.
            let page = await this.getCurrentPageFromSettings(event);
            let queryOptions: DeveloperSearchOptions = this.buildParameters(event, page);

            await this.developerScreeningService.search(queryOptions).then(async (result: DeveloperSearchResult) => {
                console.log(result);

                if (this.multiSelectFilters.length == 0) {
                    await this.loadMultiSelectFilters();
                }
                this.setMultiSelectFilters();

                /// temp fix
                const data = result.values.map(d => {
                    return {
                        ...d,
                        beZeroRatedProjectCount: d.projects.filter(p => !this._str.IsNullOrWhiteSpace(p.beZeroRating)).length,
                        calyxGhgRatedProjectCount: d.projects.filter(p => !this._str.IsNullOrWhiteSpace(p.calyxGhgRating)).length,
                        sylveraRatedProjectCount: d.projects.filter(p => !this._str.IsNullOrWhiteSpace(p.sylveraRating)).length,
                    } as DeveloperProfileDto;
                });

                this.resetVariables();
                this.data = data;
                this.dbPage = result.currentPage;
                this.dbTotal = result.totalCount;
                this.error = "";
                setGridPager(GridName.DeveloperScreening, result.currentPage, result.pageSize, event?.filters);
            });
        } catch (error: any) {
            this.error = error.message;
        }

        this.isLoading = false;
    }

    public showDetail(record: any) { 
        if (record) {
            this.router.navigate(["/developers-screening/detail"], { state: { developerId: record.data.id } });
        }
    }

    public saveSettings(event: SDKDataGridSettings[]) {
        this.gridHandlerService.saveSettings(this, event);
    }

    //#region Helpers
    //*************************************************************************
    //  Private Methods
    //*************************************************************************  

    private buildParameters(event: any, page: number | null = null): any {
		let parameters = {} as DeveloperSearchOptions;
		parameters.sortOptions = [];

        if (event.rows) {
            parameters.take = event.rows;
        }
        let actualPage = parseInt(event?.page);
        if ((page && page > 0) && actualPage === 1 && this.routeChangedService.getRouteChanged()) {
            actualPage = page;
            this.routeChangedService.clearRouteChange();
        }
        if (actualPage) {
            parameters.skip = event.rows * (actualPage - 1);
        }
        this.buildParameterFilters(event, parameters);
        this.buildParametersSorts(event, parameters);

		return parameters;
	}

    private buildParameterFilters(event: any, parameters: DeveloperSearchOptions) {
        if (!event?.filters) {
            return;
        }
        const filters: Array<{
            Name: string,
            Value: any
        }> = event.filters;
        filters.forEach((filter) => {
            let filterName = filter.Name;
            if (filterName === '*keyword') {
                filterName = 'keyword';
            }
            parameters[filterName] = filter.Value;
        });
    }

	private buildParametersSorts(event: any, parameters: DeveloperSearchOptions) {
        if (!event?.sorts || event.sorts.length === 0) {
            event.sorts = [
                {
                    Name: "projectCount",
                    Sort: "desc"
                }
            ]
        }
		if (event?.sorts) {
			event.sorts.forEach((sort: any) => {
                parameters.sortOptions.push({
                    property: sort.Name,
                    isDescending: sort.Sort === "desc"
                })
			});
		}
	}
    
    private async getCurrentPageFromSettings(event: any = null) {
        const pagerSettings = await firstValueFrom(getGridPager(GridName.DeveloperScreening));
        if (pagerSettings) {
          if (arraysAreEqual(pagerSettings.filters, event?.filters) && pagerSettings.pageSize === event?.rows) {
            return pagerSettings.pageIndex;
          } 
        }
  
        return 1;
      }

	private resetVariables() {
		this.data = [];
		this.fullData = "";
		this.dbPage = 0;
		this.dbTotal = 0;
		this.error = "";
	}

    private async loadMultiSelectFilters() {
        try {
            const multiSelectFilterNames = this.getMultiSelectFilterNames();
            this.multiSelectFilters = await this.developerScreeningService.getFilters(multiSelectFilterNames);
        } catch (error: any) {
            console.error("Error loading filters: " + error.message);
        } 
    }

    private setMultiSelectFilters() {
        const multiSelectColumns: any[] = this.columns.filter(column => column.FilterMultiSelect);

        multiSelectColumns?.forEach((multiSelectColumn) => {
            const filter = this.multiSelectFilters.find(filterInfo => filterInfo.fieldName.toLowerCase() === multiSelectColumn.Name.toLowerCase());
            const filterValues = filter?.filterValues;
            multiSelectColumn.FilterValues.length = 0;
            filterValues?.forEach(value => multiSelectColumn.FilterValues.push(value));
        });

        const multiSelectCustomFilters = this.customFilters.filter(filter => filter.FilterMultiSelect);
        multiSelectCustomFilters.forEach(customFilter => {
            const filter = this.multiSelectFilters.find(filterInfo => filterInfo.fieldName.toLowerCase() === customFilter.Name.toLowerCase());
            const filterValues = filter?.filterValues;

            if (customFilter?.FilterValues) {
                customFilter.FilterValues.length = 0;
                filterValues?.forEach(value => customFilter.FilterValues?.push(value));
            }
        });
    }

    private getMultiSelectFilterNames(): string[] {
        let multiSelectFilterNames: string[] = this.columns.filter(column => column.FilterMultiSelect).map(column => column.Name);
        multiSelectFilterNames = [...multiSelectFilterNames, ...this.customFilters.filter(filter => filter.FilterMultiSelect).map(filter => filter.Name)];
        return multiSelectFilterNames;
    }

    //#endregion
}
