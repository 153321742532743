export enum CarbonMarketStatusEnum {
  UnderConsideration = 1,
  Scheduled = 2,
  UnderDevelopment = 3,
  Implemented = 4,
  Abolished = 5
}

export interface CarbonMarketStatus {
  id: number;
  name: string;
  iconPath: string;
}

export class CarbonMarketStatusUnderConsideration implements CarbonMarketStatus {
  id: number;
  name: string;
  iconPath: string

  private constructor() {
    this.id = CarbonMarketStatusEnum.UnderConsideration;
    this.name = "Under consideration";
    this.iconPath = 'assets/svg/policy-tracker/ets-under-consideration.svg'; 
  }

  public static getInstance(): CarbonMarketStatus {
    return new CarbonMarketStatusUnderConsideration();
  }
}

export class CarbonMarketStatusScheduled implements CarbonMarketStatus {
  id: number;
  name: string;
  iconPath: string;

  private constructor() {
    this.id = CarbonMarketStatusEnum.Scheduled;
    this.name = "Under development";
    this.iconPath = 'assets/svg/policy-tracker/ets-under-development.svg'; 
  }

  public static getInstance(): CarbonMarketStatus {
    return new CarbonMarketStatusScheduled();
  }
}

export class CarbonMarketStatusUnderDevelopment implements CarbonMarketStatus {
  id: number;
  name: string;
  iconPath: string;

  private constructor() {
    this.id = CarbonMarketStatusEnum.UnderDevelopment;
    this.name = "Under development";
    this.iconPath = 'assets/svg/policy-tracker/ets-under-development.svg'; 
  }

  public static getInstance(): CarbonMarketStatus {
    return new CarbonMarketStatusUnderDevelopment();
  }
}

export class CarbonMarketStatusInforce implements CarbonMarketStatus {
  id: number;
  name: string;
  iconPath: string;

  private constructor() {
    this.id = CarbonMarketStatusEnum.Implemented;
    this.name = "In force";
    this.iconPath = 'assets/svg/policy-tracker/ets-in-force.svg'; 
  }

  public static getInstance(): CarbonMarketStatus {
    return new CarbonMarketStatusInforce();
  }
}