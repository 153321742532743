import { IServerError } from "./ccms-server-error.model";

export default class CcmsServerError {
    public serverError: IServerError;
    public hasErrors!: boolean;
    private _message: string = '';

    constructor(error: any) {
        if (error.error && error.error instanceof (Array)) {
            if (error.error.length > 0) {
                error.error.forEach(e => this._message = this._message + e.message);
            }
        }

        this.serverError = <IServerError>error;
        if (this.serverError?.error) {
            if (this.serverError.error.errors) {
                this.hasErrors = true;
            }
        } else {
            this.hasErrors = false;
        }
    }

    public get errors(): string {
        if (!this.hasErrors) {
            if (this._message && this._message != '') {
                return this._message;
            }
            if (this.serverError.error && this.serverError.error.detail) {
                return this.serverError.error.detail;
            } else if (this.serverError.message) {
                return this.serverError.message;
            } else {
                return '';
            }
        }

        let messages: string[] = [];
        for (let key in this.serverError.error.errors) {
            messages.push(key + " - " + this.serverError.error.errors[key]);
        }

        return messages.join(',');
    }

    public get getFriendlyError(): string | null {
        if (this.serverError.error.status === 500) {
            return "There was a server error.";
        }

        return this.errors;
    }
}