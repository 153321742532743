import { createStore, select, withProps, setProp } from "@ngneat/elf";
import { localStorageStrategy, persistState } from "@ngneat/elf-persist-state";
import { DurableTaskResponse } from "~/models/shared/durable-task/durable-task-response.model";

interface Props {
  task: DurableTaskResponse | null;
}

const store = createStore(
  { name: 'meta-registry-export-task' },
  withProps<Props>({ task: null }),
);

export const persist = persistState(store, 
  { 
    storage: localStorageStrategy
  },
);

export const metaRegistryExportTask$ = store.pipe(select(props => props.task));

export function updateMetaRegistryExportTask(task: DurableTaskResponse | null) {
  store.update(setProp('task', task));
}