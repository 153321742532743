import chroma from "chroma-js";
import { IMarket, MarketType } from "~/models/shared/markets/market.model";

export class Markets {
  //Ice
  public static readonly VCU_V16_V20: IMarket = {symbol: "NBT", marketType: MarketType.Ice, name: 'Verified Carbon Units Vintage 2016-2020'};
  public static readonly VCU_V17_V21: IMarket = {symbol: "OVA", marketType: MarketType.Ice, name: 'Verified Carbon Units Vintage 2017-2021'};
  public static readonly VCU_V18_V22: IMarket = {symbol: "OVB", marketType: MarketType.Ice, name: 'Verified Carbon Units Vintage 2018-2022'};
  public static readonly VCU_V19_V23: IMarket = {symbol: "OVC", marketType: MarketType.Ice, name: 'Verified Carbon Units Vintage 2019-2023'};
  public static readonly VCU_V20_V24: IMarket = {symbol: "OVD", marketType: MarketType.Ice, name: 'Verified Carbon Units Vintage 2020-2024'};
  public static readonly VCU_V21_V25: IMarket = {symbol: "OVE", marketType: MarketType.Ice, name: 'Verified Carbon Units Vintage 2021-2025'};
  public static readonly VCU_V22_V26: IMarket = {symbol: "OVF", marketType: MarketType.Ice, name: 'Verified Carbon Units Vintage 2022-2026'};
  public static readonly VCU_V23_V27: IMarket = {symbol: "OVG", marketType: MarketType.Ice, name: 'Verified Carbon Units Vintage 2023-2027'};
  public static readonly VCU_V24_V28: IMarket = {symbol: "OVH", marketType: MarketType.Ice, name: 'Verified Carbon Units Vintage 2024-2028'};
  public static readonly VCU_V25_V29: IMarket = {symbol: "OVI", marketType: MarketType.Ice, name: 'Verified Carbon Units Vintage 2025-2029'};
  public static readonly VCU_V26_V30: IMarket = {symbol: "OVJ", marketType: MarketType.Ice, name: 'Verified Carbon Units Vintage 2026-2030'};
  public static readonly C: IMarket = {symbol: "C", marketType: MarketType.Ice, name: 'EUA Futures'};
  public static readonly ECP: IMarket = {symbol: "ECP", marketType: MarketType.Ice, name: 'EUA Daily Future'};
  public static readonly CO2: IMarket = {symbol: "CO2", marketType: MarketType.Ice, name: 'Global Carbon Index Futures'};
  public static readonly DUK: IMarket = {symbol: "DUK", marketType: MarketType.Ice, name: 'UKA Daily Futures'};
  public static readonly UKA: IMarket = {symbol: "UKA", marketType: MarketType.Ice, name: 'UKA Futures'};
  public static readonly UK5: IMarket = {symbol: "UK5", marketType: MarketType.Ice, name: 'UKA UK Auction'};
  public static readonly EFO: IMarket = {symbol: "EFO", marketType: MarketType.Ice, name: 'EUA Futures Options'};
  
  public static readonly Ice: IMarket[] = [
    this.VCU_V16_V20, 
    this.VCU_V17_V21, 
    this.VCU_V18_V22,
    this.VCU_V19_V23,
    this.VCU_V20_V24,
    this.VCU_V21_V25,
    this.VCU_V22_V26,
    this.VCU_V23_V27,
    this.VCU_V24_V28,
    this.VCU_V25_V29,
    this.VCU_V26_V30,
    this.C,
    this.ECP,
    this.CO2,
    this.DUK,
    this.UKA
  ]

  //Clear Blue
  public static readonly Alberta: IMarket = {symbol: "Alberta", marketType: MarketType.ClearBlue, name: 'Alberta'}; 
  public static readonly Australia: IMarket = {symbol: "Australia ETS", marketType: MarketType.ClearBlue, name: 'Australia ETS'}; 
  public static readonly Chinese: IMarket = {symbol: "Chinese ETS", marketType: MarketType.ClearBlue, name: 'Chinese ETS'}; 
  public static readonly EuEts: IMarket = {symbol: "EU ETS", marketType: MarketType.ClearBlue, name: 'EU ETS'}; 
  public static readonly Federal: IMarket = {symbol: "Federal Backstop (OBPS)", marketType: MarketType.ClearBlue, name: 'Federal Backstop (OBPS)'}; 
  public static readonly LCFS: IMarket = {symbol: "Low Carbon Fuel Standard (LCFS)", marketType: MarketType.ClearBlue, name: 'Low Carbon Fuel Standard (LCFS)'};
  public static readonly NewZealand: IMarket = {symbol: "New Zealand ETS", marketType: MarketType.ClearBlue, name: 'New Zealand ETS'}; 
  public static readonly REC: IMarket = {symbol: "REC", marketType: MarketType.ClearBlue, name: 'REC'}; 
  public static readonly RGGI: IMarket = {symbol: "Regional Greenhouse Gas Initiative (RGGI)", marketType: MarketType.ClearBlue, name: 'Regional Greenhouse Gas Initiative (RGGI)'}; 
  public static readonly VoluntaryCgeo: IMarket = {symbol: "Voluntary-cgeo", marketType: MarketType.ClearBlue, name: 'Voluntary CGEO'};
  public static readonly VoluntaryCorsia: IMarket = {symbol: "Voluntary-corsia", marketType: MarketType.ClearBlue, name: 'Voluntary CORSIA'};
  public static readonly VoluntaryNgeo: IMarket = {symbol: "Voluntary-ngeo", marketType: MarketType.ClearBlue, name: 'Voluntary NGEO'};
  public static readonly VoluntaryOpis: IMarket = {symbol: "Voluntary-opis", marketType: MarketType.ClearBlue, name: 'Voluntary OPIS'};
  public static readonly WCI: IMarket = {symbol: "Western Climate Initiative (WCI)", marketType: MarketType.ClearBlue, name: 'Western Climate Initiative (WCI)'}; 
    
  public static readonly ClearBlue: IMarket[] = [
    this.Alberta,
    this.Australia,
    this.Chinese,
    this.EuEts,
    this.Federal,
    this.LCFS,
    this.NewZealand,
    this.REC,
    this.RGGI,
    this.VoluntaryCgeo,
    this.VoluntaryCorsia,
    this.VoluntaryNgeo,
    this.VoluntaryOpis,
    this.WCI,
  ]

  //AirCarbon
  public static readonly BCC: IMarket = {symbol: "BCC", marketType: MarketType.AirCarbon, name: 'Base Carbon Contract'}; 
  public static readonly CCC: IMarket = {symbol: "CCC", marketType: MarketType.AirCarbon, name: 'Carbon Capture Contract'}; 
  public static readonly CET: IMarket = {symbol: "CET", marketType: MarketType.AirCarbon, name: 'CORSIA Eligible Tonne'}; 
  public static readonly FCC: IMarket = {symbol: "FCC", marketType: MarketType.AirCarbon, name: 'Forestry Carbon Contract'}; 
  public static readonly GER: IMarket = {symbol: "GER", marketType: MarketType.AirCarbon, name: 'Global Emission Reduction Contract'}; 
  public static readonly GN0: IMarket = {symbol: "GN0", marketType: MarketType.AirCarbon, name: 'Global Nature+ Tonne 2020'}; 
  public static readonly GN1: IMarket = {symbol: "GN1", marketType: MarketType.AirCarbon, name: 'Global Nature+ Tonne 2021'}; 
  public static readonly GN2: IMarket = {symbol: "GN2", marketType: MarketType.AirCarbon, name: 'Global Nature+ Tonne 2022'}; 
  public static readonly GN6: IMarket = {symbol: "GN6", marketType: MarketType.AirCarbon, name: 'Global Nature+ Tonne 2016'}; 
  public static readonly GN7: IMarket = {symbol: "GN7", marketType: MarketType.AirCarbon, name: 'Global Nature+ Tonne 2017'}; 
  public static readonly GN8: IMarket = {symbol: "GN8", marketType: MarketType.AirCarbon, name: 'Global Nature+ Tonne 2018'}; 
  public static readonly GN9: IMarket = {symbol: "GN9", marketType: MarketType.AirCarbon, name: 'Global Nature+ Tonne 2019'}; 
  public static readonly GNA: IMarket = {symbol: "GNA", marketType: MarketType.AirCarbon, name: 'Global Nature Tonne 2012 to 2015'}; 
  public static readonly GNB: IMarket = {symbol: "GNB", marketType: MarketType.AirCarbon, name: 'Global Nature Tonne 2016 to 2020'}; 
  public static readonly GNC: IMarket = {symbol: "GNC", marketType: MarketType.AirCarbon, name: 'Global Nature Tonne 2021 to 2024'};  
  public static readonly HOT: IMarket = {symbol: "HOT", marketType: MarketType.AirCarbon, name: 'Household Offset Tonne'}; 
  public static readonly PCC: IMarket = {symbol: "PCC", marketType: MarketType.AirCarbon, name: 'Prime Carbon Contract'}; 
  public static readonly RET: IMarket = {symbol: "RET", marketType: MarketType.AirCarbon, name: 'Renewable Energy Tonne'}; 
  public static readonly SDGT: IMarket = {symbol: "SDGT", marketType: MarketType.AirCarbon, name: 'Sustainable Development Goal Tonne'}; 

  public static readonly AirCarbon: IMarket[] = [
    this.BCC,
    this.CCC,
    this.CET,
    this.FCC,
    this.GER,
    this.GN0,
    this.GN1,
    this.GN2,
    this.GN6,
    this.GN7,
    this.GN8,
    this.GN9,
    this.GNB,
    this.GNB,
    this.GNC,
    this.HOT,
    this.PCC,
    this.RET,
    this.SDGT
  ]

  //Platts
  public static readonly HouseHold: IMarket = { symbol: "CBXAA00", marketType: MarketType.Platts, name: "Household Devices CARBEX (USD/MTC)" };
  public static readonly CEC: IMarket = { symbol: "CBXAG00", marketType: MarketType.Platts, name: "Blue Carbon CARBEX $/mtCO2e (USD/MTC)" };

  public static readonly Platts: IMarket[] = [
    this.HouseHold,
    this.CEC
  ];


  public static getColor(market: IMarket) {
    switch(market.marketType) {
      case MarketType.Ice: {
        const position = this.Ice.indexOf(market);
        const colors = chroma.scale('Paired').colors(this.Ice.length);
        return colors[position];
      }
      case MarketType.ClearBlue: {
        const position = this.ClearBlue.indexOf(market);
        const colors = chroma.scale('Set1').colors(this.ClearBlue.length);
        return colors[position];
      }
      case MarketType.AirCarbon: {
        const position = this.AirCarbon.indexOf(market);
        const colors = chroma.scale('Set3').colors(this.AirCarbon.length);
        return colors[position];
      }
      case MarketType.Platts: {
        const position = this.Platts.indexOf(market);
        const colors = chroma.scale('RdYlBu').colors(this.Platts.length);
        return colors[position];
      }
    }
  }

  public static getMarketByName(name: string): IMarket | undefined {
    let market = Markets.Ice.find(x => `${x.name} (${x.symbol})` === name);
    if (!market) {
      market = Markets.ClearBlue.find(x => x.name === name);
    }
    if (!market) {
      market = Markets.AirCarbon.find(x => `${x.name} (${x.symbol})` === name);
    }
    if (!market) {
      market = Markets.Platts.find(x => x.name === name);
    }

    return market;
  }

  public static getMarketBySymbol(symbol: string): IMarket | undefined {
    let market = Markets.Ice.find(x => x.symbol === symbol);
    if (!market) {
      market = Markets.ClearBlue.find(x => x.symbol === symbol);
    }
    if (!market) {
      market = Markets.AirCarbon.find(x => x.symbol === symbol);
    }
    if (!market) {
      market = Markets.Platts.find(x => x.symbol === symbol);
    }

    return market;
  }
}