import { Component, EventEmitter, Inject, Output } from '@angular/core';
import { Router } from '@angular/router';
import { CarbonMarketStatusEnum } from '~/models/shared/policy-tracker/carbon-market-status';
import { PolicyTracker } from '~/models/shared/policy-tracker/policy-tracker';

@Component({
  selector: 'ccms-market-dialog',
  templateUrl: './market-dialog.component.html',
  styleUrls: ['./market-dialog.component.scss']
})
export class MarketDialogComponent {
  @Output() onClose = new EventEmitter<void>();

  public status: CarbonMarketStatusEnum = CarbonMarketStatusEnum.Implemented;

  constructor(
    @Inject('MARKET_DATA') public market: PolicyTracker,
    private router: Router) 
  { 
    switch(market?.marketStatus.id) {
      case CarbonMarketStatusEnum.UnderConsideration:
        this.status = CarbonMarketStatusEnum.UnderConsideration;
        break;
      case CarbonMarketStatusEnum.Implemented:
        this.status = CarbonMarketStatusEnum.Implemented;
        break;
      case CarbonMarketStatusEnum.UnderDevelopment:
        this.status = CarbonMarketStatusEnum.UnderDevelopment;
        break;
    }
  }

  close(): void {
    this.onClose.emit();
  } 

  viewDetails(): void {
    this.onClose.emit();
    this.router.navigate([`/policy-tracker/${this.market.name}`]);
  }
}
