<div class="content">
    <div class="header">
        <div class="totals">
            <div class="total"><span>Total Purchased:</span>{{ totalPurchased | number }}</div>
            <div class="total"><span>Total Sold:</span>{{ totalSold | number }}</div>
            <div class="total"><span>Balance:</span>{{ totalBalance | number }}</div>
        </div>
    </div>
    <div class="chart">
        <supplytrading-chart [data]="transactionData"></supplytrading-chart>
    </div>
    <div class="footnote">
        {{ footnote }}
    </div>
</div>
