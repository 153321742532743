export class PatternCreator {
  pattern?: string;
  pArray: string[] = [];
  fArray: string[] = [];

  createPattern() {
      const ws = this.getWhitespacePattern();
      const breakArray = this.getBreakArray(ws);
      const patternArray = this.getPatternArray();

      this.constructPatternArrays(ws, breakArray, patternArray);
  }

  getWhitespacePattern(): string {
      return (this.pattern?.trim() ?? "").replace(/(#)\1+/g, "#");
  }

  getBreakArray(ws: string): string[] {
      return Array.from(ws).filter(char => 
          [" ", "-", ".", "/", "_"].includes(char)
      );
  }

  getPatternArray(): string[] {
      let chars = "";
      const charArray = Array.from(this.pattern ?? "").reduce((acc, char) => {
          if (char === "#") {
              chars += "#";
          } else {
              if (chars) {
                  acc.push(chars);
                  chars = "";
              }
          }
          return acc;
      }, [] as string[]);

      if (chars) charArray.push(chars);

      return charArray.map(char => `[0-9]{1,${char.length}}`);
  }

  encodeText(text) {
    return text.replace(/[|{}\\()[\]^$+*?.]/g, '\\$&').replace(/\s/, "\\s");
  }

  constructPatternArrays(ws: string, breakArray: string[], patternArray: string[]) {
      let wsFilter = ws;
      let wspFilter = this.getWhitespacePattern();
      
      for (let l = 0; l < patternArray.length; l++) {
          const [f, p] = this.splitByBreakArray(wsFilter, wspFilter, breakArray, l);
          wsFilter = wsFilter.substring(f.length + 1);
          wspFilter = wspFilter.substring(p.length + 1);
          
          this.constructArrayValues(f, p, breakArray, patternArray, l);
      }
  }

  splitByBreakArray(wsFilter: string, wspFilter: string, breakArray: string[], index: number): [string, string] {
      return [
          wsFilter.split(breakArray[index])[0],
          wspFilter.split(breakArray[index])[0]
      ];
  }

  constructPatternSegment(f: string, patternArray: string[], l: number, x: number, m: string, breakArray: string[]): string {
  let result = "";

  if (f.startsWith("#") && f.endsWith("#")) {
      result = (x === l) ? `${m}(${patternArray[l]})` : this.encodeText(f).replace("#", `${m}(${patternArray[l].replace("1,", "")})`);
  } else {
      const segment = this.encodeText(f).replace("#", `${m}(${patternArray[l].replace("1,", "")})`);
      result = (x === l || x === (l + 1)) ? `${m}(${patternArray[l]})` : segment;
  }

  return result + (f.endsWith("#") ? this.encodeText(breakArray[l]) : "");
  }

  constructFilterSegment(f: string, p: string, breakArray: string[], l: number, x: number): string {
    if (f.startsWith("#")) {
      return (l === x) ? p : p + (breakArray[l] ?? "");
    } else if (!f.startsWith("#") && !f.endsWith("#")) {
      return (l === x) ? p.replace(/[^$0-9]/g, "") : p + (breakArray[l] ?? "");
    }

    return p + (breakArray[l] ?? "");
  }

  constructArrayValues(f: string, p: string, breakArray: string[], patternArray: string[], l: number) {
    for (let x = l; x < patternArray.length; x++) {
      const m = (l > 0) ? "*" : "";
      const patternSegment = this.constructPatternSegment(f, patternArray, l, x, m, breakArray); // Passed breakArray here
      const filterSegment = this.constructFilterSegment(f, p, breakArray, l, x);

      this.pArray[x] = (this.pArray[x] ?? "") + patternSegment;
      this.fArray[x] = (this.fArray[x] ?? "") + filterSegment;
    }
  }

}