import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { moveItemInArray } from '@angular/cdk/drag-drop';

import { SDKDataGridColumn } from '../../models/datagrid-column';

@Component({
    selector: 'sdk-datagrid-columns-option',
    templateUrl: './columns-option.component.html',
    styleUrls: ['./columns-option.component.scss']
})

export class SDKDataGridColumnsOptionComponent implements OnChanges {
    @Input() originalColumns: SDKDataGridColumn[] = [];
    @Input() columns: SDKDataGridColumn[] = [];
    @Output() closeEvent = new EventEmitter();
    @Output() resetEvent = new EventEmitter();
    @Output() applyEvent = new EventEmitter<SDKDataGridColumn[]>();

    public columnList: any[] = [];
    public allVisible: boolean = true;

    public ngOnChanges(_args: any) {
        this.initialze();
    }

    private initialze() {
        this.columnList = [];

        this.columns.filter((column: any) => !column.actionTemplate).forEach((column: any) => {
            this.columnList.push({ ...column });
        });

        this.allVisible = this.columnList.filter(c => !c.isVisible).length > 0 ? false : true;
    }

    public getColumnOriginalName(column: SDKDataGridColumn): string {
        let originalName = column.Name;

        if (column.DisplayName && column.DisplayName !== "") {
            originalName = column.DisplayName;
        }

        return originalName;
    }

    public toggleAllColumns(event: any) {
        this.allVisible = event.target.checked;

        this.columnList.forEach((column: SDKDataGridColumn) => {
            column.isVisible = this.allVisible;
        });
    }

    public groupVisibleColumns() {
        const visible: any[] = this.columnList.filter((c: any) => c.isVisible !== undefined && c.isVisible);
        const hidden: any[] = this.columnList.filter((c: any) => c.isVisible !== undefined && !c.isVisible);

        this.columnList = visible.concat(hidden);
    }

    public toggleColumn(event: any, column: SDKDataGridColumn) {
        column.isVisible = event.target.checked;
    }

    public setFriendlyName(event: any, column: SDKDataGridColumn) {
        if (event.target.value === this.getColumnOriginalName(column))
            column.FriendlyName = "";  // if column being set to it's original name, blank it out as this would be going back to original setting
        else
            column.FriendlyName = event.target.value;
    }

    public drop(event: any) {
        moveItemInArray(this.columnList, event.previousIndex, event.currentIndex);
    }

    public reset() {
        if (confirm("Reset Columns... Are you sure?")) {
            this.columns = this.originalColumns;
            this.initialze();
        }
    }

    public apply() {
        this.applyEvent.emit(this.columnList);
    }

    public close() {
        this.closeEvent.emit();
    }
}
