import { Component } from '@angular/core';
import { RetiredChartIssuanceComponent } from '~/pages/metaregistry/retirements/retired-chart-issuance/retired-chart-issuance.component';
import { RetiredChartYearlyComponent } from '~/pages/metaregistry/retirements/retired-chart-yearly/retired-chart-yearly.component';
import { Tab } from '~/components/shared/tabs/tabs.component';
import { BaseComponent } from '~/pages/base.component';
import { ActivatedRoute } from '@angular/router';

@Component({
    selector: 'retirements',
    templateUrl: './retirements.component.html',
    styleUrls: ['./retirements.component.scss']
})

export class MetaRegistryRetirementsComponent extends BaseComponent {
    public title: string = "Credit Retirements";
    public messageStyle: string = "";
    public activeTab: Tab | undefined;
    public message: string = "";

    public tabs: Tab[] = [
        {
            title: "Year & Quarter",
            type: <any>RetiredChartYearlyComponent,
            inputs: {
                id: 'yearly',
                chartTitle: 'Retired by Year & Quarter',
                xaxisTitle: '',
                yaxisTitle: 'Credits'
            }, 
            outputs: {
              'messageEvent': (event: any) => {
                this.setMessage(event);
              },
            }
        },
        {
            title: "Issued Date",
            type: <any>RetiredChartIssuanceComponent,
            inputs: {
                id: 'issued',
                chartTitle: 'Retired by Issuance Date',
                xaxisTitle: '',
                yaxisTitle: 'Credits'
            },
            outputs: {
              'messageEvent': (event: any) => {
                this.setMessage(event);
              },
            }
        }
    ];
  
    constructor(private route: ActivatedRoute,) {
        super();
    }

    //*************************************************************************
    //  Component Life-Cycle Methods
    //*************************************************************************
    public async ngOnInit() {
        await super.ngOnInit();
        this.isLoading = false;

        this.route.queryParams.subscribe((params: any) => {
         
          if (params?.tab) {
              this.activeTab = this.tabs.find((tab: Tab) => tab.title === params.tab);
          }
      });
    }

    public showMessage(event: any) {
      this.message = event;
    }

    public setMessage(event: any) {
      this.message = event.text;
      this.messageStyle = event.style;
    }

    public async onTabChanged(event: any) {
      if (event?.to) {
          this.activeTab = event?.to;
      }
    }
}
