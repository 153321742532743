<div class="menu">
	<div class="icon material-icons-outlined" (click)="toggleMenu(true)">{{ expand ? 'close' : 'menu' }}</div>
</div>
<div class="items" [ngClass]="{ expand: expand }">
	<ng-container *ngFor="let item of navItems">
		<div class="item" [ngClass]="{ active: activeItem && item.title === activeItem.title, expand: expand }" (mouseleave)="displaySubMenu = false;">
			<div class="icon material-icons" [title]="item.title" [ngClass]="{ active: activeItem && item.title === activeItem.title }" (click)="navigateTo(item)">{{ item.icon }}</div>
			<div class="title" [ngClass]="{ active: activeItem && item.title === activeItem.title, expand: expand }" (click)="navigateTo(item)">{{ item.title }}
				<div class="arrow" [ngClass]="{ active: activeItem }" *ngIf="item?.subRoutes?.length > 0" (mouseover)="showSubMenu($event);">►</div>
			</div>
			<div class="sub" [ngClass]="{ expand: expand }" *ngIf="displaySubMenu"  >
				<table >
					<caption style="display: none;">sub menu items</caption>
					<tr style="display: none;">
						<th scope="col">icon</th>
						<th scope="col">url</th>
					</tr>
					<ng-container *ngFor="let subItem of item.subRoutes">
						<tr >
							<td>
								<div class="icon material-icons-outlined" [title]="subItem.title" [ngClass]="{ active: activeItem && subItem.title === activeItem.title }" 
									(click)="navigateTo(subItem)">{{ subItem.icon }}</div>
							</td>
							<td>
								<div  class="title" (click)="navigateTo(subItem)" [ngClass]="{ active: activeItem && subItem.title === activeItem.title, expand: expand }">{{ subItem.title }}
								</div>
							</td>
						</tr>
					</ng-container>
				</table>
			</div>
		</div>
	</ng-container>
  <div class="item" [ngClass]="{ expand: expand }">
    <div class="icon material-icons-outlined" title="Search" (click)="toggleSearch()">search</div>
    <div class="title" [ngClass]="{ expand: expand }" (click)="toggleSearch()">Search</div>
  </div>
</div>
<div class="toggle" [ngClass]="{ expand: expand }">
	<div [title]="appTitle" class="icon material-icons-outlined" (click)="toggleFullscreen()">{{ appIcon }}</div>
	<div class="icon material-icons-outlined" (click)="toggleMenu()">{{ menuIcon }}</div>
</div>