import { Component, inject } from '@angular/core';

import { AppConfigService } from '~/services/core/appconfig.service';
import { AppSettingsService } from '~/services/core/appsettings.service';

import { AppConfig } from '~/models/core/appconfig';
import { AppSettings } from '~/models/core/appsettings';
import { Permissions } from '~/models/shared/permissions';
import { UserPermissionsService } from '~/services/shared/user-permissions.service';

@Component({
    template: ''
})

export class BaseComponent {
    public appConfig: AppConfig = new AppConfig();
    public appSettings: AppSettings = new AppSettings();
    public permissions: Permissions | undefined;

    public isLoading = true;

    private _appConfigService: AppConfigService = inject(AppConfigService);
    private _appSettingsService: AppSettingsService = inject(AppSettingsService);
    private _permissionsService: UserPermissionsService = inject(UserPermissionsService);

    //*************************************************************************
    //  Component Life-Cycle Methods
    //*************************************************************************
    public async ngOnInit() {
        this.appConfig = this._appConfigService.getConfig();
        this.appSettings = await this._appSettingsService.getSettings();
        this._permissionsService.getUserPermissions().pipe().subscribe((permissions: Permissions) => {
            this.permissions = permissions;
        });
    }
}
