import { Injectable } from "@angular/core";
import { Observable, firstValueFrom, from } from "rxjs";
import { CountryCpi } from "~/models/shared/policy-tracker/country-cpi.model";
import { HttpService } from "~/services/core/http.service";

@Injectable()
export class TransparencyCpiService {
  constructor(private readonly _offsetClient: HttpService) { }

  private cache$: Observable<CountryCpi[]> | undefined;

  
  private requestCountryCpis(alpha3Code: string): Observable<CountryCpi[]> {
    const uri = '/transparency/cpi/' + alpha3Code;
    return from(this._offsetClient.Get(uri));
  }
  
  public async getCountryCpi(alpha3Code: string): Promise<CountryCpi[]> {
      const response = this.requestCountryCpis(alpha3Code);
      return firstValueFrom(response);
  }
}