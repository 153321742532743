import { Paging } from '../request-parameters';
import { SortingOption } from './sorting-options';

export class PagedSearchArgs {
    public Paging: Paging | undefined;
    public SortingOptions: Array<SortingOption> | undefined;
    public EntityIds: Array<string> | undefined;

    constructor(pagingOptions?: Paging, sortOptions?: Array<SortingOption>, entityIds?: Array<string>) {
        this.Paging = pagingOptions;
        this.SortingOptions = sortOptions;
        this.EntityIds = entityIds;
    }
}
