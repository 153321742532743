import { Component, EventEmitter, Inject, Output } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'column-dialog',
  templateUrl: './column-dialog.component.html',
  styleUrls: ['./column-dialog.component.scss']
})
export class ColumnDialogComponent {
  @Output() onClose = new EventEmitter<void>();
  
  constructor(@Inject(MAT_DIALOG_DATA) public data: { message: string }) {}

  close(): void {
    this.onClose.emit();
  } 
}
