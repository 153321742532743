import { Component, OnInit } from "@angular/core";
import { Observable } from "rxjs";
import { Tab } from "~/components/shared/tabs/tabs.component";
import { DeveloperInfoComponent } from "./info/developer-info.component";
import { DeveloperLocationComponent } from "./locations/developer-location.component";
import { DeveloperCreditsComponent } from "./credits/developer-credits.component";
import { DeveloperProjectsComponent } from "./projects/developer-projects.component";
import { DeveloperRatingsComponent } from "./ratings/developer-ratings.component";
import { ActivatedRoute, Router } from "@angular/router";
import { DevelopersScreeningService } from "~/services/shared/developers-screening/developers-screening.service";
import { DeveloperProfile } from "~/services/shared/developers-screening/models/developer-summary";



@Component({
    selector: 'developer-detail',
    templateUrl: './developer-detail.component.html',
    styleUrls: ['./developer-detail.component.scss']
})
    
export class DeveloperDetailComponent implements OnInit {

    public developer!: DeveloperProfile;
    public callbackLabel: string = "Back to Developers";
    public message: string = "";
    public messageStyle: string = "";
    public isLoading: boolean = false;
    public tabs: Tab[] = [
        {
            title: "Developer",
            type: <any>DeveloperInfoComponent,
            inputs: {
                "developer": this.developer
            }
        },
        {
            title: "Projects",
            type:<any>DeveloperProjectsComponent,
            inputs: {
                "developer": this.developer
            }
        },
        {
            title: "Locations",
            type: <any>DeveloperLocationComponent,
            inputs: {
                "developer": this.developer
            }
        },
        {
            title: "Credits",
            type: <any>DeveloperCreditsComponent,
            inputs: {
                "developer": this.developer
            }
        },
        {
            title: "Ratings",
            type:<any>DeveloperRatingsComponent,
            inputs: {
                "developer": this.developer
            }
        },
       
    ];

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private developerService: DevelopersScreeningService) { }

    async ngOnInit() {
        let developerId: string = "";

        this.route.queryParams.subscribe((params: any) => {
            developerId = (params?.id) ? params.id : window.history.state.developerId;
        });     

        if (!developerId) {
            this.back();
        } else {
            this.loadDeveloper(+developerId);
        }

        this.isLoading = false;
        
    }
    private loadDeveloper(developerId: number) {
        this.developerService.get(developerId).then((data) => {
            this.developer = new DeveloperProfile(data);
            this.tabs.forEach(element => {
                element.inputs = element.inputs || {};
                element.inputs.developer = this.developer;
            });
        });
    }
    back(): void {
        this.router.navigate(['/developers-screening']);
    }
    confirmTabChange(event: any): Observable<boolean>{
        return new Observable(o => o.next(true));
    }
}