<div class="content">
    <div class="info">
        <br />
        <div class="header">Sustainable Development Goals (SDG)</div>
        <div class="goals">
            
            <div class="goal-data">
                <div>
                    <ng-container *ngFor="let goal of sustainabilityList">
                        <div class="editGoal flex-row">
                            <input type="checkbox" [id]="'goal.' + goal.id" 
                                (change)="setGoal($event, goal)" />
                            <label [for]="'goal.' + goal.id"><img [alt]="goal.description" [title]="goal.description"
                                    [src]="'assets/images/E_WEB_INVERTED_' + (goal.id | number: '2.0') + '.png'"
                                    class="goal edit" /></label>
                        </div>
                    </ng-container>
                </div>
            </div>
        </div>
    </div>
</div>