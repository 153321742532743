import { Component, Input, OnChanges, SimpleChanges } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { ProjectPriorityScoreCard, ProjectPriorityScoreCardInputModel } from "~/services/shared/projects/models/project-priority-score-card";

type RatingType = 'projectType' | 'geography' | 'developer' | 'fungibility' | 'scale' | 'economics';
@Component({
    selector: 'ccms-project-priority-score-card-form',
    templateUrl: './project-priority-score-card-form.component.html',
    styleUrls: ['./project-priority-score-card-form.component.scss']
})
export class ProjectPriorityScoreCardFormComponent implements OnChanges {
    @Input() isEdit = false;
    @Input() context!: ProjectPriorityScoreCard;

    public form!: FormGroup;

    public ngOnChanges(changes: SimpleChanges): void {
        if (changes.context) {
            this.onFormContextChanged();
        }
    }
    private onFormContextChanged() {
        this.reset();
    }

    public reset() {
        this.form = new FormGroup({
            projectType: new FormControl(this.context?.projectType ?? '', [
                Validators.min(1),
                Validators.max(3)
            ]),
            geography: new FormControl(this.context?.geography ?? '', [
                Validators.min(1),
                Validators.max(3)
            ]),
            developer: new FormControl(this.context?.developer ?? '', [
                Validators.min(1),
                Validators.max(3)
            ]),
            fungibility: new FormControl(this.context?.fungibility ?? '', [
                Validators.min(1),
                Validators.max(3)
            ]),
            scale: new FormControl(this.context?.scale ?? '', [
                Validators.min(1),
                Validators.max(3)
            ]),
            economics: new FormControl(this.context?.economics ?? '', [
                Validators.min(1),
                Validators.max(3)
            ])
        });
    }

    public clearValue(type: RatingType) {
        if (!this.form?.get(type)) {
            return;
        }
        this.form.get(type)!.setValue(null);
    }

    public getFormData(): ProjectPriorityScoreCardInputModel {
        const values: Record<string, any> = {};
        if (this.form.get('projectType')!.value) {
            values.projectType = +this.form.get('projectType')!.value;
        }
        if (this.form.get('geography')!.value) {
            values.geography = +this.form.get('geography')!.value;
        }
        if (this.form.get('developer')!.value) {
            values.developer = +this.form.get('developer')!.value;
        }
        if (this.form.get('fungibility')!.value) {
            values.fungibility = +this.form.get('fungibility')!.value;
        }
        if (this.form.get('scale')!.value) {
            values.scale = +this.form.get('scale')!.value;
        }
        if (this.form.get('economics')!.value) {
            values.economics = +this.form.get('economics')!.value;
        }
        return {...values} as ProjectPriorityScoreCardInputModel;
    }

    public getToolTip(type: RatingType) {
        return this.getRatingDescriptions(type)
            .map((str, idx) => `${idx + 1} - ${str}`).join('\n');
    }

    public getRatingDescriptions(type: RatingType) {
        /**
         * These values should be orderd by value (index = 0 means a rating of 1, etc...)
         */
        if (type === 'projectType') {
            return [
                'Removal or Avoidance for Regulator set Compliance',
                'Removal for VCM and Tech Avoidance',
                'NBS Avoidance in VCM'
            ];
        }
        if (type === 'geography') {
            return [
                'CVX Compliance Country or Article 6 to meet CVX Compliance',
                'VCM where CVX does business or potential Article 6',
                'VCM where CVX does not do business, CVX risk assessment, and/or not on Offsets map'
            ];
        }
        if (type === 'developer') {
            return [
                'Implementation capability (resources secured -boots on the ground), experienced team (internal or external) with registry submital of carbon offsets projects (registered projects), and sophistication',
                'Implementation capability (resources secured -boots on the ground), experienced team (internal or external) of carbon offsets projects',
                'Experienced team (internal or external) with no registry submital of carbon offsets projects (no registered projects)'
            ];
        }
        if (type === 'fungibility') {
            return [
                'Multiple VCM or Compliance use',
                'Multiple VCM or Compliance use potential',
                'NBS Avoidance in  VCM'
            ];
        }
        if (type === 'scale') {
            return [
                'Material impact of compliance need',
                'Minimal impact of compliance need compliance + VCM greater than 200Kpa or 2.5mMT total',
                'VCM vol less than 200Kpa or 2.5MMT total'
            ];
        }
        if (type === 'economics') {
            return [
                'greater than 15%',
                'between 10% -15%',
                'less than 10%'
            ];
        }
        return [];
    }
}
