<div class="content">
    <div class="data">
        <developer-projects-map [creditsByCountry]="creditsByCountry$ | async"></developer-projects-map>
        <div class="country-summary">
            <div class="country-summary-data">
                <table class="table-x" >
                    <caption style="display: none;">country-summary</caption>
                    <tbody>
                        <tr>
                            <th scope="col">Country</th>
                            <th scope="col">Projects Count</th>
                            <th scope="col">Credits Issued</th>
                            <th scope="col">Credits Retired</th>
                            <th scope="col">Credits Remaining</th>
                        </tr>
                        <ng-container *ngFor="let row of creditsByCountry">
                            <tr (click)="toggleRow(row)">
                                <td>{{ row.country }}</td>
                                <td>{{ row.projects.length }}</td>
                                <td>{{ row.creditsIssued }}</td>
                                <td>{{ row.creditsRetired }}</td>
                                <td>{{ row.creditsRemaining }}</td>
                            </tr>
                            <tr *ngIf="row.expanded">
                                <td colspan="5">
                                    <table class="table-x">
                                        <caption style="display: none;">country-detail</caption>
                                        <tbody>
                                            <tr>
                                                <th scope="row">Id</th>
                                                <th scope="col">Status</th>
                                                <th scope="col">Name</th>
                                                <th scope="col">Registry</th>
                                                <th scope="col">Scope</th>
                                                <th scope="col">Credits Issued</th>
                                                <th scope="col">Credits Retired</th>
                                                <th scope="col">Credits Remaining</th>
                                            </tr>
                                            <ng-container *ngFor="let project of row.projects">
                                                <tr>
                                                    <td style="width: unset;">{{project.id}}</td>
                                                    <td style="width: unset;">{{project.status}}</td>
                                                    <td>                                                        
                                                        <a href="/metaregistry/detail?id={{ project.id }}" target="_blank">
                                                            {{project.name}}
                                                        </a>
                                                    </td>
                                                    <td>{{project.registry}}</td>
                                                    <td>{{project.scope}}</td>
                                                    <td>{{project.creditsIssued}}</td>
                                                    <td>{{project.creditsRetired}}</td>
                                                    <td>{{project.creditsRemaining}}</td>
                                                </tr>
                                            </ng-container>
                                        </tbody>
                                    </table>
                                </td>
                            </tr>
                        </ng-container>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
    <div class="info">
        <table class="table-x">
            <caption style="display: none;">details</caption>
            <tbody>
                <tr>
                    <th scope="col">Total Projects<fa-icon></fa-icon>
                    </th>
                    <td>{{ developer?.projectCount }}</td>
                </tr>
                <tr>
                    <th scope="col">Countries<fa-icon></fa-icon>
                    </th>
                    <td>{{ creditsByCountry?.length }}</td>
                </tr>
                <tr>
                    <th scope="col">Credits Issued</th>
                    <td>
                        {{ developer?.totalCreditsGenerated | number }}
                    </td>
                </tr>
                <tr>
                    <th scope="col">Credit Delivery Rate</th>
                    <td>
                        {{ developer?.creditDeliveryRate | number: '1.2' }}
                    </td>
                </tr>
                <tr>
                    <th scope="col">Website</th>
                    <td>
                        <a *ngIf="developer?.website !== ''"
                            href="{{developer.website}}" target="_blank">{{developer.website}}</a>
                        <span *ngIf="!developer?.website || developer?.website === ''">N/A</span>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</div>