<div class="sdk-datagrid-window">
    <div class="header">
        <span class="title">Sorting</span>
        <span title="Close" class="sdk-icon close" (click)="close()">close</span>
    </div>
    <div class="actions">
        <select class="select" (change)="addSort($event)">
            <option selected value="">ADD SORT...</option>
            <ng-container *ngFor="let column of columnList">
                <option [value]="column.Name">{{ column.FriendlyName === '' ? getColumnOriginalName(column) : column.FriendlyName }}</option>
            </ng-container>
        </select>
    </div>
    <div cdkDropList class="content" (cdkDropListDropped)="drop($event)">
        <div *ngIf="sortList.length === 0" class="no-records">
            <span>Add a sort.</span>
        </div>
        <div class="box" *ngFor="let sort of sortList" cdkDrag>
            <div class="delete">
                <span class="sdk-icon" (click)="deleteSort(sort)">delete</span>
            </div>
            <div class="name" [title]="sort.FriendlyName === '' ? sort.Name : sort.FriendlyName">{{ sort.FriendlyName === '' ? getColumnOriginalName(sort) : sort.FriendlyName }}</div>
            <div class="select">
                <div style="display: inline-block;" (click)="sort.Sort = 'asc'">
                    <input type="radio" class="data-option" (click)="sort.Sort = 'asc'" [id]="sort.Name + '_asc'" [name]="sort.Name" [checked]="(sort.Sort === 'asc') ? 'checked' : ''" value="asc">
                    <span class="data-label" [for]="sort.Name + '_asc'">ASC</span>
                </div>
                <div style="display: inline-block;" (click)="sort.Sort = 'desc'">
                    <input type="radio" class="data-option" (click)="sort.Sort = 'desc'" [id]="sort.Name + '_desc'" [name]="sort.Name" [checked]="(sort.Sort === 'desc') ? 'checked' : ''" value="desc" style="margin-left: 20px;">
                    <span class="data-label" [for]="sort.Name + '_desc'">DESC</span>
                </div>
            </div>
        </div>
    </div>
    <div class="footer">
        <div class="reset" (click)="reset()">Reset</div>
        <button class="button" type="button" (click)="apply()">Apply</button>
    </div>
</div>
