import { Injectable } from "@angular/core";
import { from, Observable } from "rxjs";
import { IAgreementPaperType } from "./models/agreement-paper-type";
import { IAgreementType } from "./models/agreement-type";
import { IProjectAgreement } from "./models/agreement.model";
import { HttpService } from "~/services/core/http.service";

@Injectable()
export class AgreementService {
    
    private _baseServiceUri = "/agreements";
    constructor(private httpService: HttpService) {
    
    }

    public getAgreements(projectId: number): Observable<IProjectAgreement[]> {
        const uri = `${this._baseServiceUri}/${projectId}`;
        
        return from(this.httpService.Get(uri));
    }

    public getAgreementTypes(): Observable<IAgreementType[]> { 
        const uri = `${this._baseServiceUri}/agreement-types`;
        
        return from(this.httpService.Get(uri));
    }

    public getPaperTypes(): Observable<IAgreementPaperType[]> {
        const uri = `${this._baseServiceUri}/agreement-paper-types`;
        
        return from(this.httpService.Get(uri));
    }

    public createAgreements(projectId: number, agreements: IProjectAgreement[]) {
        const uri = `${this._baseServiceUri}/${projectId}`;

        return from(this.httpService.Post(uri, agreements));
    }

    public saveAgreements(projectId: number, agreements: IProjectAgreement[]): Observable<void> {
        const uri = `${this._baseServiceUri}/${projectId}`;

        return from(this.httpService.Put(uri, agreements));
    }

    public deleteAgreement(agreementId: number): Observable<void> {
        const uri = `${this._baseServiceUri}/${agreementId}`;

        return from(this.httpService.Delete(uri));
    }

}