<div class="content">
    <div class="data">
        <div class="header">Internal</div>
        <div class="detail">
            <table class="table-x" aria-hidden="true">
                <caption style="display: none;">Internal</caption>
                <tbody>
                    <tr>
                        <th scope="col" [ngClass]="{ 'required label': isEdit && isRequired('contact') }">Commercial Mgr</th>
                        <td *ngIf="!isEdit">{{ project.contact || '-' }}</td>
                        <td *ngIf="isEdit">
                            <user-selection [entity]="entity" 
                                            [field]="'contact'" 
                                            [selectedUserName] ="projectEdit.contact" 
                                            [allowEmptySelection]="!isRequired('contact')"
                                            (userSelectedEvent)="userSelectionChange('contact', $event)">
                            </user-selection>
                        </td>
                    </tr>
                    <tr>
                        <th scope="col" [ngClass]="{ 'required label': isEdit && isRequired('legalLead') }">Legal Lead</th>
                        <td *ngIf="!isEdit">{{ project.legalLead || '-' }}</td>
                        <td *ngIf="isEdit">
                            <user-selection [entity]="entity"
                                            [field]="'legalLead'"
                                            [selectedUserName]="projectEdit.legalLead"
                                            (userSelectedEvent)="userSelectionChange('legalLead', $event)">
                            </user-selection>
                        </td>
                    </tr>
                    <tr>
                        <th scope="col" [ngClass]="{ 'required label': isEdit && isRequired('originator') }">Originator</th>
                        <td *ngIf="!isEdit">{{ project.originator || '-' }}</td>
                        <td *ngIf="isEdit">
                            <user-selection [entity]="entity"
                                            [field]="'originator'"
                                            [selectedUserName]="projectEdit.originator"
                                            (userSelectedEvent)="userSelectionChange('originator', $event)">
                            </user-selection>
                        </td>
                    </tr>
                    <tr>
                        <th scope="col" [ngClass]="{ 'required label': isEdit && isRequired('termSheetLead') }">Term Sheet Lead</th>
                        <td *ngIf="!isEdit">{{ project.termSheetLead || '-' }}</td>
                        <td *ngIf="isEdit">
                            <user-selection [entity]="entity"
                                            [field]="'termSheetLead'"
                                            [selectedUserName]="projectEdit.termSheetLead"
                                            (userSelectedEvent)="userSelectionChange('termSheetLead', $event)">
                            </user-selection>
                        </td>
                    </tr>
                    <tr>
                        <th scope="col" [ngClass]="{ 'required label': isEdit && isRequired('negotiator') }">Negotiator</th>
                        <td *ngIf="!isEdit">{{ project.negotiator || '-' }}</td>
                        <td *ngIf="isEdit">
                            <user-selection [entity]="entity"
                                            [field]="'negotiator'"
                                            [selectedUserName]="projectEdit.negotiator"
                                            (userSelectedEvent)="userSelectionChange('negotiator', $event)">
                            </user-selection>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>

        <div class="header">External</div>
        <div class="detail">
            <table class="table-x">
                <caption style="display: none;">External</caption>
                <tbody>
                    <tr class="developer-row" [ngClass]="{edit: isEdit}" >
                        <th scope="col" [ngClass]="{ 'required label': isEdit && isRequired('cvxProjectDeveloper') }">Developer</th>
                        <td *ngIf="!isEdit">{{ project.cvxProjectDeveloper? project.cvxProjectDeveloper.name :'-' }}</td>
                        <td *ngIf="isEdit">
                            <developer-search [currentDeveloper]="project.cvxProjectDeveloper" (developerSelectedEvent)="infoChange({ field: 'cvxProjectDeveloper', value: $event })"> </developer-search>
                        </td>
                    </tr>
                    <tr>
                        <th scope="col" [ngClass]="{ 'required label': isEdit && isRequired('operator') }">Operator</th>
                        <td *ngIf="!isEdit">{{ project.operator || '-' }}</td>
                        <td *ngIf="isEdit"><sdk-textbox [value]="projectEdit.operator" width="100%" (changeCallBackEvent)="infoChange({ field: 'operator', value: $event })"></sdk-textbox></td>
                    </tr>
                    <tr>
                        <th scope="col" [ngClass]="{ 'required label': isEdit && isRequired('verifier') }">Verifier</th>
                        <td *ngIf="!isEdit">{{ project.verifier || '-' }}</td>
                        <td *ngIf="isEdit"><sdk-textbox [value]="projectEdit.verifier" width="100%" (changeCallBackEvent)="infoChange({ field: 'verifier', value: $event })"></sdk-textbox></td>
                    </tr>
                </tbody>
            </table>
        </div>

        <div class="header" [ngClass]="{ 'required label': isEdit && isRequired('owners') }">Ownership</div>
        <div *ngIf="!isEdit" class="detail">
            <div *ngIf="!project.owners || project.owners.length === 0" class="nodata">No Owners defined for this project.</div>
            <table *ngIf="project.owners && project.owners.length > 0" class="table-x">
                <caption style="display: none;">Ownership</caption>
                <tbody>
                    <tr *ngFor="let owner of project.owners">
                        <th scope="col">{{ owner.accountName }}</th>
                        <td>{{ owner.percentOwned + '%' || '-' }}</td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div *ngIf="isEdit" class="detail">
            <div *ngIf="!_owners || _owners.length === 0" class="nodata">No Owners defined for this project.</div>
            <table *ngIf="_owners && _owners.length > 0" aria-hidden="true" class="table-x">
                <caption style="display: none;">Ownership</caption>
                <tbody>
                    <tr *ngFor="let owner of _owners; let ndx = index">
                        <td style="width: 50%"><sdk-textbox [value]="owner.accountName" (changeCallBackEvent)="ownerChange(ndx, 'accountName', $event)"></sdk-textbox></td>
                        <td style="width: 50%"><sdk-textbox validCharacters="decimal" pattern="1.0-2" width="50px" style="display: inline-flex" [value]="owner.percentOwned" (changeCallBackEvent)="ownerChange(ndx, 'percentOwned', $event)"></sdk-textbox> %</td>
                        <td><div title="Delete" class="icon material-icons-outlined cursor delete" (click)="deleteOwner(owner.accountId)">delete</div></td>
                    </tr>
                </tbody>
            </table>
            <div style="margin-top: 10px"><button class="button small" (click)="addOwner()">+ Owner</button></div>
        </div>
    </div>

    <div class="info">
        <tab-info [project]="project" [isEdit]="isEdit" [status]="getStatus()" [portfolioId]="portfolioId" (changeEvent)="infoChange($event)"></tab-info>
    </div>
</div>
