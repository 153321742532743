import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { ICvxProject } from "~/models/shared/cvxproject";
import { SustainabilityService } from "~/services/shared/sustainability.service";

@Component({
    selector: 'project-add-goals',
    templateUrl: './project-add-goals.component.html',
    styleUrls: ['./project-add-goals.component.scss']
})

export class ProjectAddGoals implements OnInit, AfterViewInit {
    public isLoading: boolean = true;
    public sustainabilityList: any = [];

    @Input() project: any;
    @Output() changeEvent: EventEmitter<ICvxProject> = new EventEmitter();
    @Output() saveDataEvent: EventEmitter<boolean> = new EventEmitter();

    constructor(private sustainabilityService: SustainabilityService) {

    }

    async ngOnInit(): Promise<void> {
        await this.loadData();
    }
    private async loadData(): Promise<void> {
        try {
            await this.sustainabilityService.getTypes().then((data: any) => {
                this.sustainabilityList = data;
                this.sustainabilityList.forEach((goal: any) => {
                    goal.selected = false;
                })
            });

        }
        catch (error: any) {
            console.error(error);
        }
        finally {
            this.isLoading = false;
        }
    }
    ngAfterViewInit(): void {
        console.warn("nothing implemented");
    }


    public getGoal(goal: any) {
        console.log(goal);
    }

    public setGoal(event: any, goal: any) {
        
        goal.selected = event.target.checked;
        if (event.target.checked) {
            this.project.sustainableDevelopmentGoals = [... (this.project.sustainableDevelopmentGoals || []), goal];
        }
        else {
            const index = this.project.sustainableDevelopmentGoals.indexOf(goal);

            if (index !== -1) {
                this.project.sustainableDevelopmentGoals.splice(index, 1);
            }  
        }
    }
}

