<mat-tree [dataSource]="dataSource" [treeControl]="treeControl">
  <mat-tree-node *matTreeNodeDef="let node" matTreeNodeToggle matTreeNodePadding>
    <button mat-icon-button disabled></button>
    <mat-checkbox class="checklist-leaf-node"
                  color="primary"
                  [checked]="checklistSelection.isSelected(node)"
                  (change)="toggleNestedNode(node);" class="text-wrap">{{node.item}}</mat-checkbox>
  </mat-tree-node>

   <mat-tree-node *matTreeNodeDef="let node; when: hasChild" matTreeNodePadding>
    <button mat-icon-button matTreeNodeToggle
            (click)="expandableClicked($event, node)"
            [attr.aria-label]="'toggle ' + node.filename">
      <mat-icon class="mat-icon-rtl-mirror">
        {{treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
      </mat-icon>
    </button>
    <mat-checkbox [checked]="getCompleteForNode(node)"
                  [indeterminate]="getIsIndeterminate(node)"
                  color="primary"
                  (change)="branchSelectionToggle($event, node)" class="text-wrap">{{node.item}}</mat-checkbox>
  </mat-tree-node>
</mat-tree>