import { Injectable } from "@angular/core";
import { Observable, from } from "rxjs";
import { HttpService } from "~/services/core/http.service";
import {
    CreditInventory,
    CreditInventoryAccountBase,
    CreditInventoryCreateInfo,
    CreditInventoryUpdateRequest,
    CreditTransferRequest,
    RetireCreditsRequest,
    TransactionJournal
} from "~/services/shared/credit-inventory/models/credit-inventory.model";
import { InventoryAccountQuery } from "./models/credit-inventory-query.model";

@Injectable()
export class CreditInventoryService {
    constructor(
        private readonly httpService: HttpService
    ) {
        // Intentionally blank
    }

    public getCreditInventories(projectId: number): Observable<CreditInventory[]> {
        const requestUri = `/projects/${projectId}/inventories`;
        return from(this.httpService.Get(requestUri));
    }

    public addCreditInventory(
        projectId: number, 
        createInfo: CreditInventoryCreateInfo
    ): Observable<CreditInventory> {
        const requestUri = `/projects/${projectId}/inventories`;
        return from(this.httpService.Post(requestUri, createInfo));
    }

    public updateCreditInventory(inventoryId: number, updateRequest: CreditInventoryUpdateRequest): Observable<boolean> {
        const requestUri = `/inventories/${inventoryId}`;
        return from(this.httpService.Patch(requestUri, updateRequest));
    }

    public findInventoryAccount(query: InventoryAccountQuery): Observable<CreditInventoryAccountBase[]> {
        let requestUri = `/inventories/accounts?`;
        if (query?.name) {
            requestUri += 'name=' + query.name;
        }
        return from(this.httpService.Get(requestUri));
    }
    
    public transferCreditsAsync(
        request: CreditTransferRequest
    ): Observable<TransactionJournal> {
        const requestUri = `/inventories/${request.inventoryId}/transfer`;
        return from(this.httpService.Post(requestUri, {
            fromAccount: request.fromAccount,
            toAccount: request.toAccount,
            amount: request.amount
        }));
    }

    public retireCreditsAsync(
        request: RetireCreditsRequest
    ): Observable<TransactionJournal> {
        const requestUri = `/inventories/${request.inventoryId}/retire`;
        return from(this.httpService.Post(requestUri, {
            account: request.account,
            amount: request.amount
        }));
    }
}