import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { debounceTime, distinctUntilChanged, Subject } from 'rxjs';
import { ProjectPortfolioSearchOptions } from '~/services/shared/projects/models/project-portfolio.model';
import { ProjectPortfolioService } from '~/services/shared/projects/project-portfolio.service';

const ADD_NEW_PROJECT_TEXT = '--new portfolio epic--';
@Component({
	selector: 'ccms-portfolio-epic-select',
	templateUrl: './portfolio-epic-select.component.html',
	styleUrls: ['./portfolio-epic-select.component.scss']
})
export class PortfolioEpicSelectComponent implements OnInit {
	private _keywordSubject = new Subject<string>();
	public keyword$ = this._keywordSubject.asObservable();

	private _addNewProjectSubject = new Subject<boolean>();
	public addNewProject$ = this._addNewProjectSubject.asObservable();

	@Input() initialValue = '';
	@Output() changed = new EventEmitter<string>();

	private _optionsSubject = new Subject<{
		id: string,
		title: string,
		description: string,
		enabled: boolean
	}[]>();
	public options$ = this._optionsSubject.asObservable();

	private _valueSubject = new Subject<string>( );
	public value$ = this._valueSubject.asObservable();

	public showHint = true;
	public isLoading = false;

	constructor(
		private readonly _projectPortfolioService: ProjectPortfolioService) {
		// Intentionally blank
	}

	ngOnInit(): void {
		this.keyword$.pipe(
			debounceTime(300),
			distinctUntilChanged()
		).subscribe(keyword => {
			if (keyword) {
				this.loadData(keyword);
			}
			else {
				this.writeValue('');
				this._optionsSubject.next([]);
			}
		});
		this.options$.subscribe(options => {
			if (options?.length > 0) {
				this.showHint = false;
				return;
			}
			this.showHint = true;
		});
		this.value$.subscribe(value => {	
			if (value === ADD_NEW_PROJECT_TEXT) {
				this.changed.emit('NEW');
			}
			else {
				this.changed.emit(value);
			}
		})
	}

	ngAfterViewInit(): void {
		if (this.initialValue) {
			this._valueSubject.next(this.initialValue);
		}
		this.addNewProject$.subscribe((value) => {
			if (value) {
				this._valueSubject.next(ADD_NEW_PROJECT_TEXT);
			}
			else {
				this._valueSubject.next('');
			}
		});
	}

	public onAddNewprojectChanged(value: boolean) {
		this._addNewProjectSubject.next(value);
	}

	public onKeywordChanged(value: string) {
		this._keywordSubject.next(value);
	}

	public writeValue(obj: any): void {
		this._valueSubject.next(obj);
	}

	public onLinkButtonClicked() {
	}

	//#region Internals

	private loadData(keyword: string) {
		this.isLoading = true;
		setTimeout(() => {
			this.isLoading = false;
		},30000);
		try {
			const options = {} as ProjectPortfolioSearchOptions;
			options.keyword = keyword;
			this._projectPortfolioService.searchAsync(options).subscribe(result => {
				const options = result.values.map((value) => {
					let description = value.id + " - " + value.title;
					if (value.linkedProjectId) {
						description += "\r\n" + `---Work item linked to project ID '${value.linkedProjectId}'---`;
					}
					if (value.description) {
						description += "\r\n" + value.description;
					}
					return {
						id: value.id,
						title: value.title,
						description: description,
						enabled: !value.linkedProjectId
					};
				});
				this._optionsSubject.next(options);
				this.isLoading = false;
			});
		}
		catch (err) {
			console.error(err);
		}
	}

	//#endregion
}
