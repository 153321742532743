<div class="registry-x">
  <div class="data">
    <sdk-loading [isLoading]="isLoading"></sdk-loading>
    <ccms-market-chart *ngIf="hasPrices" [seriesSelectedBehavior]="seriesSelectedBehavior" (onSeriesSelected)="onSeriesSelectedStart($event)" (onSeriesUnselected)="onSeriesSelectedEnd($event)" #marketChart></ccms-market-chart>
    <p *ngIf="!hasPrices" class="nodata">No Price data available for this project.</p>
  </div>
  <div class="info">
    <table class="table-x">
      <caption style="display: none;">Location</caption>
      <tbody>
          <tr>
              <th scope="col">Project ID:</th>
              <td [title]="project.id || '-'">{{ project.id || '-' }}</td>
          </tr>
          <tr>
              <th scope="col">Status:</th>
              <td>{{ project.status || '-' }}</td>
          </tr>
          <tr>
              <th scope="col">Registry:</th>
              <td><a [href]="project.registryUrl" target="_blank">{{ project.registry }}</a></td>
          </tr>
          <tr>
            <th scope="col">Country:</th>
            <td>{{ project.country || '-' }}</td>
          </tr>
          <tr>
            <th scope="col">Vintage:</th>
            <td>{{ vintage }}</td>
          </tr>
          <tr>
            <th scope="col">Price:</th>
            <td>{{ price | currency }}</td>
          </tr>
          <tr>
            <th scope="col">Average Price:</th>
            <td>{{ avgPrice | currency }}</td>
          </tr>
          <tr>
            <th scope="col">52 week high:</th>
            <td>{{ high52 | currency }}</td>
          </tr>
          <tr>
            <th scope="col">52 week low:</th>
            <td>{{ low52 | currency }}</td>
          </tr>
      </tbody>
    </table>
  </div>
</div>