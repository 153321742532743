import { ITreeDataContract } from "~/components/shared/tree-checklist/tree-data.contract";
import { PolicyTree } from "./tree.constants";

export class TreeDataPolicyTrackerService implements ITreeDataContract {
  getTreeData(): object {
    const tree = {};

    tree[PolicyTree.ROOT_MARKET_TYPE] = {};
    
    tree[PolicyTree.ROOT_MARKET_TYPE][PolicyTree.LEAF_MARKET_ETS] = null; 
    tree[PolicyTree.ROOT_MARKET_TYPE][PolicyTree.LEAF_MARKET_TAX] = null;
    tree[PolicyTree.ROOT_MARKET_TYPE][PolicyTree.LEAF_MARKET_CREDITS] = null;

    tree[PolicyTree.ROOT_MARKET_STATUS] = {};
    tree[PolicyTree.ROOT_MARKET_STATUS][PolicyTree.LEAF_STATUS_INFORCE] = null;
    tree[PolicyTree.ROOT_MARKET_STATUS][PolicyTree.LEAF_STATUS_UNDER_DEVELOPMENT] = null;
    tree[PolicyTree.ROOT_MARKET_STATUS][PolicyTree.LEAF_STATUS_UNDER_CONSIDERATION] = null;

    tree[PolicyTree.ROOT_JURISDICTION_TYPE] = {};
    tree[PolicyTree.ROOT_JURISDICTION_TYPE][PolicyTree.LEAF_JURISDICTION_REGIONAL] = null;
    tree[PolicyTree.ROOT_JURISDICTION_TYPE][PolicyTree.LEAF_JURISDICTION_NATIONAL] = null;
    tree[PolicyTree.ROOT_JURISDICTION_TYPE][PolicyTree.LEAF_JURISDICTION_SUBNATIONAL] = null;
    
    tree[PolicyTree.ROOT_ARTICLE6] = {};

    return tree;
  }
}