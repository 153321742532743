import { Component, Input } from '@angular/core';

@Component({
  selector: 'dialog-content-example-dialog',
  templateUrl: './popup-content-dialog.component.html',
  styleUrls: ['./popup-content-dialog.component.scss']
})
export class PopupContentDialog {
  @Input() data: string | undefined;
  
  isMouseOver = false;

  mouseEnter() {
    this.isMouseOver = true;
  }

  mouseLeave() {
    this.isMouseOver = false;
  }
}
