import { Component } from '@angular/core';
import { Tab } from "~/components/shared/tabs/tabs.component";
import { BaseComponent } from '~/pages/base.component';
import { SignPostReportComponent } from './signposts/signposts.component';
import { OffsetProjectReportComponent } from './offset-projects/offset-projects.component';
import { MarketReportComponent } from './market/market.component';

@Component({
    selector: 'reports',
    templateUrl: './reports.component.html',
    styleUrls: ['./reports.component.scss']
})

export class ReportsComponent extends BaseComponent {
    constructor() {
        super();
    }
    public message: string = "";
    public messageStyle: string = "";
    public tabs: Tab[] = [
        {
            title: "SignPosts",
            type: <any>SignPostReportComponent
        },
        {
            title: "Portfolio",
            type: <any>OffsetProjectReportComponent
        },
        {
            title: "Market",
            type: <any>MarketReportComponent
        }

    ];

    //*************************************************************************
    //  Component Life-Cycle Methods
    //*************************************************************************
    public async ngOnInit() {
        await super.ngOnInit();

        this.isLoading = false;
    }
}
