import { ITreeDataContract } from "~/components/shared/tree-checklist/tree-data.contract";
import { Markets } from "./markets";

export class TreeDataMarketService implements ITreeDataContract {
  readonly IceFutures = 'ICE - Nature-Based Carbon Credit Futures';
  readonly ClearBlueMarkets = 'ClearBlue Markets';
  readonly AirCarbonExchange = "AirCarbon Exchange"
  readonly Platts = "Platts";

  getTreeData(): object {
    const tree = {};
    tree[this.IceFutures] = {};
    tree[this.ClearBlueMarkets] = {};
    tree[this.AirCarbonExchange] = {};
    tree[this.Platts] = {};
    
    Markets.Ice.forEach(m => {
      tree[this.IceFutures][`${m.name} (${m.symbol})`] = null;
    })

    Markets.ClearBlue.forEach(m => {
      tree[this.ClearBlueMarkets][m.name] = null;
    })

    Markets.AirCarbon.forEach(m => {
      tree[this.AirCarbonExchange][`${m.name} (${m.symbol})`] = null;
    })

    Markets.Platts.forEach(m => { 
      tree[this.Platts][m.name] = null;
    });

    return tree;
  }
}