<sdk-message [(message)]="message" [style]="messageStyle"></sdk-message>
<div *ngIf="developer" class="content">
    <div class="header">
        <div class="back" (click)="back()"><span class="arrow">◄</span><span class="highlight">{{ callbackLabel }}</span></div>
        <h3 [title]="'ID: ' + developer.id">{{ developer.name }}</h3>
    </div>
    <div class="data">
        <tabs 
        [tabs]="tabs"
        [beforeTabChange]="confirmTabChange.bind(this)"></tabs>
    </div>
</div>

<sdk-loading [isLoading]="isLoading"></sdk-loading>