<div class="content">
	<div class="header">
		<div class="title">{{ title }}</div>
	</div>
	<div class="data">
		<div class="chart">
			<div class="chart-container">
				<ccms-price-chart [fitAllContent]="fitAllContent" (changeLoadingEvent)="isLoading = $event" #priceChart></ccms-price-chart>
			</div>
			<div class="fit-all-content">
				<mat-checkbox color="primary" [checked]="fitAllContent" (change)="setFitAllContent($event.checked)">Fit all content</mat-checkbox>
			</div>
			<ccms-price-chart-legend #priceChartLegend (onHoverStart)="onLegendHoverStart($event)" (onHoverEnd)="onLegendHoverEnd($event)"></ccms-price-chart-legend>
		</div>
		<div class="options">
			<ccms-tree-checklist #treeCheckList></ccms-tree-checklist>
		</div>
	</div>
</div>