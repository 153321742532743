import { Injectable } from "@angular/core";
import { Observable, from } from "rxjs";
import { IceContract } from "~/models/shared/markets/ice-contract.model";
import { HttpService } from "~/services/core/http.service";

@Injectable({
  providedIn: 'root',
})
export class IcePriceService {
    constructor(private readonly _httpClient: HttpService) { }

    public getPricesBySymbol(symbol: string): Observable<IceContract> {
      const priceUrl = `/Ice/${symbol}/prompt`;
      return from(this._httpClient.Get(priceUrl));
  }
}