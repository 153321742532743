import { Component, ElementRef, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, TemplateRef, Type, ViewChild } from '@angular/core';

import Chart from 'chart.js/auto'

import { Colors } from './classes/colors';
import { Filters } from './classes/filters';

import { SDKDataGridDataset } from './models/datagrid-dataset';
import { SDKDataGridOptions } from './models/datagrid-options';
import { SDKDataGridColumn } from './models/datagrid-column';
import { SDKDataGridFormula } from './models/datagrid-formula';
import { SDKDataGridSettings } from './models/datagrid-settings';
import { SDKDataGridCustomFilter } from './models/datagrid-filter';

const VERSION = "1_3_1";
const SESSION_KEY = "__sdk-datagrid__";

@Component({
    selector: 'sdk-datagrid',
    templateUrl: './sdk-datagrid.component.html',
    styleUrls: ['./sdk-datagrid.component.scss']
})

export class SDKDatagridComponent implements OnInit, OnChanges, OnDestroy {
    @ViewChild('chartCanvas') chartCanvas!: { nativeElement: any };

    /**************************************************************************
    * Required Input/Output Parameters
    **************************************************************************/
    @Input() datasets: SDKDataGridDataset[] = []; // Once provided, the loadDataEvent will be triggered.

    @Output() loadDataEvent = new EventEmitter<any>(); // This is a callback to load data.

    /**************************************************************************
    * Optional Input/Output Parameters
    **************************************************************************/
    @Input() name: string = ""; // This value will be set by the loadDataEvent callback and will be the active dataset (view).
    @Input() data: string = ""; // This value will be set by the loadDataEvent callback and contain the actual data.
    @Input() fullData: string = ""; // This value will be set by the loadDataEvent callback when chart or export options are selected.

    @Input() title: string = ""; // Add a title to the page if only 1 dataset (view) is being used.
    @Input() titleStyle: string = ""; // Add a style to the title.
    @Input() uniqueIdentifier: string = ""; // This value is ONLY used for creating the storage (saved session) name.
    @Input() options: SDKDataGridOptions | null = null; // You can turn on/off data options.
    @Input() columns: SDKDataGridColumn[] = []; // You can specify certain columns to be set.
    @Input() customFilters: SDKDataGridCustomFilter[] = []; // Custom filters added to standard filter provided in loaddata callback (filters).
    @Input() formulas: SDKDataGridFormula[] = []; // You can specify default formulas.
    @Input() settings: SDKDataGridSettings[] = []; // Options that are saved outside the grid. This value should be set to the value retieved from the datagrid @Output method "settingsSavedEvent". Consider it a passthrough value.
    @Input() includeAllColumns: boolean = false; // You can specify whether or not to include all columns in dataset.
    @Input() isDataWrapped: boolean = false; // You can specify whether or not to wrap data within a column.
    @Input() forceReload: boolean = false; // You can reload the current data.
    @Input() fontFamily: string = ""; // Applies a given font-family to entire grid.
    @Input() columnHeaderStyle: string = ""; // You can set the column header style for the grid.
    @Input() filterTypes: Filters[] = []; // Limits the filtering list to provided types.
    @Input() rowValues = [10, 25, 50, 100, 500, 1000]; // Sets the values used for Rows.
    @Input() defaultRow = 100; // Default value for row value.

    @Input() loadingStyle: string = ""; // Adjust the loading style when the datagrid is embedded on the page with other components.
    @Input() error: string = ""; // Any errors that occur during processing.

    @Input() useTabs: boolean = false; // This option shows the list of datasets in a tabular format (instead of the default dropdown).
    @Input() isLoading: boolean = false; // Parent controlled variable for the entire grid.
    @Input() showLoadingTimer: boolean = false; // Shows a timer while the loading component is running.

    /**************************************************************************
    * Edit Mode
    **************************************************************************/
    @Input() editRowIndex: any;

    /**************************************************************************
    * Addon Parameters (Option Icon/Modal Window)
    **************************************************************************/
    @Input() optionAddons: Type<any>[] = [];
    @Input() windowAddons: Type<any>[] = [];

    /**************************************************************************
    * Database Parameters
    **************************************************************************/
    @Input() dbMAXRECORDS: number = 1000; // Total records to retrieve from db on any given pull (skip/take).
    @Input() dbPage: number | undefined; // Current db page (for partial loads).
    @Input() dbTotal: number | undefined; // Total db records (based on filters).

    /**************************************************************************
    * UI Section Parameters
    **************************************************************************/
    @Input() showHeader: boolean = true; // Enable/disable the header section of the grid.
    @Input() showOptions: boolean = true; // Enable/disable options section of the grid.
    @Input() showFooter: boolean = true; // Enable/disable the footer section of the grid.
    @Input() showPaging: boolean = true; // Enable/disable the paging section (within the footer) of the grid.
    @Input() showPanelOverlay: boolean = true; // Enable/disable the overlay of the options panel.
    @Input() autoClosePanel: boolean = true; // Enable/disable automatically closing panel after action.
    @Input() minimizeOptions: boolean | undefined; // Minimize the 'options' section on the right-side of the grid.

    @Input() detailTemplate!: TemplateRef<any> | undefined; // Embedded component for row detail.

    /**************************************************************************
    * Version - Used for local storage across ALL grids. This should be based
    * on the version (whatever that may be) associated with your data model.
    **************************************************************************/
    @Input() version: string = VERSION;

    @Output() columnSettingsChangedEvent: EventEmitter<SDKDataGridColumn[]> = new EventEmitter(); // Used as a callback for column changes.
    @Output() settingsSavedEvent: EventEmitter<SDKDataGridSettings[]> = new EventEmitter(); // Used as a callback to save settings externally.
    @Output() selectedRowsChangedEvent: EventEmitter<any[]> = new EventEmitter(); // Used as a callback for row changes.
    @Output() selectedRowActionEvent: EventEmitter<any> = new EventEmitter(); // // Used as a callback for row actions. NOTE: The return object is { data: [the row], index: [the row index] }

    @ViewChild('sdkdatagrid') sdkdatagrid!: ElementRef<any>;

    /**************************************************************************
    * Component Variables
    **************************************************************************/
    public _isLoading: boolean = true;
    public _name: string = "";
    public _originalData: any[] = [];
    public _data: any = "";
    public _activeRow: any;
    public _originalColumns: SDKDataGridColumn[] = [];
    public _columns: SDKDataGridColumn[] = [];
    public _columnActions: SDKDataGridColumn[] = [];
    // public _customFilters: SDKDataGridCustomFilter[] = [];
    public _formulas: any[] = [];
    public _options: SDKDataGridOptions = new SDKDataGridOptions();
    public _total: number = 0;
    public _menuOptions: boolean = true;

    public dataMode: string = "data";
    public dataClass: string = "";
    public optionTitle: string = "";
    public editMode: boolean = false;

    public filters: any[] = [];
    public sorts: any[] = [];
    public export: any = "";
    public chart: any = "";
    public fullDataArray: any[] = [];

    public columnBadge: string = "";
    public filterBadge: string = "";
    public sortBadge: string = "";
    public formulaBadge: string = "";
    public chartBadge: string = "";

    public page: number = 1;
    public rows: number = 100;
    public totalPages: Array<string> = new Array(0);
    public isPartial: boolean = true; // Is the query (partially) loaded based on MAXRECORDS.

    private scrollTop: number = 0;
    private _scrollTop: number = 0;

    public initialLoad: boolean = true;
    public isLoaded: boolean = false;
    public hasData: boolean = false;
    public showReset: boolean = false;
    public showNote: boolean = false;

    public selectAll = false;
    public selectPartial = false;

    /**************************************************************************
    * Addon Variables
    *   - option(Inputs/Outputs): Used with the Addon icons.
    *   - window(Inputs/Outputs): Used with the Addon modal.
    **************************************************************************/
    public optionInputs = {};
    public windowInputs = {};

    public optionOutputs = {
        showDataOptionsEvent: (event: any) => this.showDataOptions(event),
    };

    public windowOutputs = {
        closeEvent: () => this.closeDataOptions(),
        applyEvent: (event: any) => this.applyDataOptions(event),
    };

    /**************************************************************************
    * Chart Variables
    **************************************************************************/
    private canvas: any;
    private ctx: any;
    private chartjs: any;

    /**************************************************************************
    * Component Methods
    **************************************************************************/
    public ngOnInit() {
        // If no dbPage, data will NOT be partial.
        if (this.dbPage === undefined) {
            this.isPartial = false;
        }
    }

    public ngAfterViewInit() {
        setTimeout(() => {
            if (this.sdkdatagrid) {
                if (this.fontFamily !== "") {
                    this.sdkdatagrid.nativeElement.style.setProperty("--font-family", this.fontFamily);
                }
            }
        }, 100);
    }

    public ngOnDestroy() {
        this.datasets = [];
        this.name = "";
        this._name = "";

        this.resetVariables();
    }

    public ngOnChanges(_args: any) {
        this.setHeader(_args);
        this.setOptions(_args);
        this.isLoadingChange(_args);
        this.nameChange(_args);
        this.datasetsChange(_args);
        this.dataChange(_args);
        this.fullDataChange(_args);
        this.forceReloadChange(_args);
        this.setEditMode(_args);
        this.setDefaultRow(_args);
    }

    public loadDataset(event: any) {
        this.initialLoad = true;
        this.isLoaded = false;
        this._isLoading = true;

        this._name = (event && event.target) ? event.target.value : event;

        this.resetVariables();
        this.getSessionData();

        let page = this.getDBPage();

        this.loadDataEvent.emit({ name: this._name, page: page, rows: this.rows, sorts: this.sorts, filters: this.filters });
    }

    public clearStorage() {
        localStorage.clear();
    }

    /**************************************************************************
    * Change Methods
    **************************************************************************/
    private setHeader(_args: any) {
        if (this.datasets.length > 0 && !this.isLoading) {
            if (this.showHeader) {
                if (this.datasets.length <= 1
                    && !this.useTabs
                    && (this.title === undefined || this.title === "")) {
                    this.showHeader = false;
                }
            }
        }
    }

    private setOptions(_args: any) {
        if (_args.options !== undefined) {
            this._options = this.options ?? new SDKDataGridOptions();

            if (this.showOptions) {
                let values: any = [
                    this._options.columnSettings,
                    this._options.filtering,
                    this._options.sorting,
                    this._options.formulas,
                    this._options.charts,
                    this._options.export,
                    this._options.expandableRows,
                    this._options.selectableRows
                ];

                if (!this._options || values.filter((v: boolean) => v).length === 0) {
                    this.showOptions = false;
                }
            }
        }

        if (_args.minimizeOptions !== undefined) {
            this._menuOptions = false;
        }
    }

    private isLoadingChange(_args: any) {
        if (_args.isLoading !== undefined) {
            this._isLoading = this.isLoading;

            if (!this._isLoading) {
                this.isLoaded = true;
                this.initialLoad = false;
            }
        }
    }

    private nameChange(_args: any) {
        if (_args.name !== undefined) {
            setTimeout(() => {
                if (this.name !== "") {
                    if (this.name !== this._name) {
                        this.loadDataset(this.name);
                    }
                } else {
                    this.resetVariables();
                    this._name = this.name;
                }
            }, 100);
        }
    }

    private datasetsChange(_args: any) {
        if (_args.datasets !== undefined && this.datasets.length > 0) {
            if (this.name === "" && this._name === "") {
                this._name = this.datasets[0].DbName;

                this.setAddonInputs();
                this.resetVariables();
                this.getSessionData();

                let page = this.getDBPage();

                this.loadDataEvent.emit({ name: this._name, page: page, rows: this.rows, sorts: this.sorts, filters: this.filters });
            }
        }
    }

    private dataChange(_args: any) {
        if (_args.data !== undefined) {
            this.hasData = false;

            if (this.data && this.data !== "") {
                this.hasData = true;

                this._originalData = <any>this.data;

                if (!this.isPartial) {
                    this.fullDataArray = <any>this.data.slice(0);
                }

                // if (this._originalColumns.length === 0 && this._originalData && this._originalData.length > 0) {
                this.setOriginalColumns(true);
                // }

                if (this.formulas.length > 0) {
                    this.setOriginalFormulas();
                }

                this.loadData();
                this.setAddonInputs();
            } else {
                this.data = "";
            }
        }
    }

    private fullDataChange(_args: any) {
        if (_args.fullData !== undefined && _args.fullData.currentValue !== "" && this.fullData && this.fullData !== "") {
            let tmp: any = this.fullData.slice(0);

            this.fullDataArray = tmp.slice(0);

            if (this.optionTitle === "Export") {
                this.exportData();
            } else {
                this.createChart();
            }
        }
    }

    private forceReloadChange(_args: any) {
        if (_args.forceReload !== undefined && _args.forceReload.currentValue === true) {
            this.reloadData(true);
        }
    }

    private setEditMode(_args: any) {
        if (_args.editRowIndex !== undefined) {
            if (this._data.length > 0 && _args.editRowIndex.previousValue !== -1) {
                this._data[_args.editRowIndex.previousValue].isEdit = false;

                this._activeRow = null;
            }

            if (this._data.length > 0 && _args.editRowIndex.currentValue !== -1) {
                this._activeRow = JSON.parse(JSON.stringify(this._data[_args.editRowIndex.currentValue])); // Deep copy.
                // this._activeRow = { ...this._data[_args.editRowIndex.currentValue] }; // Shallow copy.

                this._data[_args.editRowIndex.currentValue].isEdit = true;
            }
        }
    }

    private setDefaultRow(_args: any) {
        if (_args.defaultRow !== undefined) {
            this.rows = this.defaultRow;
        }
    }

    /**************************************************************************
    * Data Methods
    **************************************************************************/
    public reloadData(forced: boolean = false) {
        /**************************************************************************
        * This method is called after options are applied or is forced through
        * the grid property "forceReload".
        **************************************************************************/
        this._isLoading = true;

        this.page = 1;
        this.fullDataArray = [];

        if (this.isPartial || forced) {
            this.loadDataEvent.emit({ name: this._name, page: 1, rows: this.rows, sorts: this.sorts, filters: this.filters });
        } else {
            setTimeout(() => {
                this.loadData();
            }, 100);
        }
    }

    public resetGrid() {
        setTimeout(() => {
            let grid = <HTMLInputElement>document.getElementById("grid_" + this._name);

            if (grid) {
                grid.scrollTo(0, this.scrollTop);
            }

            this.scrollTop = 0;
            this._scrollTop = 0;

            this.resetScrollData();
        }, 100);
    }

    private resetVariables() {
        this.data = "";
        this.fullData = "";
        this.editRowIndex = undefined;

        this.showReset = false;
        this.showNote = false;
        this.columnBadge = "";
        this.sortBadge = "";
        this.filterBadge = "";
        this.chartBadge = "";
        this.formulaBadge = "";
        this.filters = [];
        this.sorts = [];
        this.chart = "";
        this.fullDataArray = [];
        this.page = 1;
        this.scrollTop = 0;

        this._columns = [];
        this._columnActions = [];
        this._formulas = [];
        this._total = 0;
        this._originalData = [];
        this._data = "";
        this._originalColumns = [];
        this._scrollTop = 0;

        this.optionInputs = {};
        this.windowInputs = {};

        this.destroyChart();
    }

    private loadData() {
        setTimeout(() => {
            let tmp: any = this._originalData.slice(0);

            if (!this.isPartial) {
                if (this.filters.length > 0) tmp = this.filterData(tmp);
                if (this.sorts.length > 0) tmp = this.sortData(tmp);
            }

            if (this._formulas.length > 0) tmp = this.formulaData(tmp);

            this.sliceData(tmp);

            this.fullData = tmp.slice(0);
            this.fullDataArray = tmp.slice(0);

            if (tmp.length === 0) {
                this.hasData = false;
            } else {
                this.hasData = true;
            }

            if (this.isPartial) {
                this._total = this.dbTotal!;
            } else {
                this._total = tmp.length;
            }

            if (!this.options) {
                if (!this.isPartial || this._total <= this.dbMAXRECORDS) {
                    this._options.formulas = true;
                } else {
                    this._options.formulas = false;
                }
            }

            if (this.showFooter && this._total > -1 && this.rows > 0) this.setFooter();

            this.resetGrid();

            if (this.dataMode === "chart" && this.fullDataArray?.length === 0) {
                this.loadDataEvent.emit({ name: this._name, page: 1, rows: this.rows, sorts: this.sorts, filters: this.filters, chart: true });
            }

            this.isLoaded = true;
            this.initialLoad = false;

            this._isLoading = false;
        }, 1);
    }

    private filterData(tmp: any): any {
        this.filters.filter((filter: any) => filter.Type !== "Custom").forEach((filter: any) => {
            switch (filter.Operation) {
                case "Equals":
                    tmp = tmp.filter((d: any) => {
                        let value = this.getColumnValue(filter.Name, d);

                        if (filter.ValueType === "list" && filter.MultiSelect) {
                            return filter.Value.indexOf(value) > -1
                        } else {
                            return value !== undefined && value !== null && value?.toString().trim().toLowerCase() === filter.Value.toString().trim().toLowerCase();
                        }
                    }).slice(0);
                    break;

                case "NotEquals":
                    tmp = tmp.filter((d: any) => {
                        let value = this.getColumnValue(filter.Name, d);

                        if (filter.ValueType === "list" && filter.MultiSelect) {
                            return filter.Value.indexOf(value) === -1
                        } else {
                            return value !== undefined && value !== null && value?.toString().trim().toLowerCase() !== filter.Value.toString().trim().toLowerCase();
                        }
                    }).slice(0);
                    break;

                case "Empty":
                    tmp = tmp.filter((d: any) => {
                        let value = this.getColumnValue(filter.Name, d);
                        return value !== undefined || value === null;
                    }).slice(0);
                    break;

                case "NotEmpty":
                    tmp = tmp.filter((d: any) => {
                        let value = this.getColumnValue(filter.Name, d);
                        return value !== undefined && value !== null && value !== "";
                    }).slice(0);
                    break;

                case "Contains":
                    tmp = tmp.filter((d: any) => {
                        let value = this.getColumnValue(filter.Name, d);
                        return value?.toString().toLowerCase().indexOf(filter.Value.toString().toLowerCase()) > -1;
                    }).slice(0);
                    break;

                case "NotContains":
                    tmp = tmp.filter((d: any) => {
                        let value = this.getColumnValue(filter.Name, d);
                        return value?.toString().toLowerCase().indexOf(filter.Value.toString().toLowerCase()) === -1;
                    }).slice(0);
                    break;

                case "GreaterThan":
                    tmp = tmp.filter((d: any) => {
                        let value = this.getColumnValue(filter.Name, d);
                        return value > filter.Value;
                    }).slice(0);
                    break;

                case "GreaterThanOrEqual":
                    tmp = tmp.filter((d: any) => {
                        let value = this.getColumnValue(filter.Name, d);
                        return value >= filter.Value;
                    }).slice(0);
                    break;

                case "LessThan":
                    tmp = tmp.filter((d: any) => {
                        let value = this.getColumnValue(filter.Name, d);
                        return value < filter.Value;
                    }).slice(0);
                    break;

                case "LessThanOrEqual":
                    tmp = tmp.filter((d: any) => {
                        let value = this.getColumnValue(filter.Name, d);
                        return value <= filter.Value;
                    }).slice(0);
                    break;

                case "InList":
                    let inList = filter.Value.split(",");
                    let tmpArray: any[] = [];

                    inList.forEach((item: any) => {
                        let arr = tmp.filter((d: any) => {
                            let value = this.getColumnValue(filter.Name, d);
                            let found = (value?.toString().toLowerCase() === item.toString().toLowerCase()) ? true : false;

                            return found;
                        });

                        if (arr) tmpArray = tmpArray.concat(arr.slice(0));
                    });

                    tmp = tmpArray;
                    break;
            };
        });

        return tmp;
    }

    private sortData(tmp: any): any {
        let sorts: any[] = this.addSortFilters();

        tmp.sort((a: any, b: any) => {
            let results = 0;

            for (let sort of sorts) {
                let left = sort.RawValueRetriever ? sort.RawValueRetriever(a[sort.Column]) : a[sort.Column]
                let right = sort.RawValueRetriever ? sort.RawValueRetriever(b[sort.Column]) : b[sort.Column]

                results = this.compareSortFields(left, right, sort.Value);

                if (results !== 0) {
                    break;
                }
            }

            return results;
        });

        return tmp;
    }

    private addSortFilters(): any[] {
        let sorts: any[] = [];

        this.sorts.filter(s => s.Sort !== "").forEach(column => {
            let direction: number = (column.Sort === "asc") ? 1 : -1;

            sorts.push({ Column: column.Name, Value: direction, RawValueRetriever: column.RawValueRetriever });
        });

        return sorts;
    }

    private compareSortFields(left: any, right: any, value: any): number {
        // equal items sort equally
        if (left === right) {
            return 0;
        }

        // nulls sort after anything else
        if (left === null) {
            return value * -1;
        }
        if (right === null) {
            return value * 1;
        }

        // if ascending, lowest sorts first
        if (left < right) {
            return value * -1;
        }

        // if descending, highest sorts first
        if (left > right) {
            return value * 1;
        }

        return 0;
    }

    private formulaData(tmp: any): any {
        this._formulas.forEach((formula: any) => {
            // Gets the Total.
            formula.Value = tmp.reduce((total: number, row: any) => total + parseFloat((row[formula.Name] ?? 0).toString().replace(/[^\d.-]/g, '')), 0);

            if (formula.Operation === "Avg") {
                formula.Value = formula.Value / tmp.length;
            }

            if (formula.Operation === "Rng") {
                let high: number = Math.max.apply(Math, tmp.map((o: any) => { return parseFloat((o[formula.Name] ?? 0).toString().replace(/[^\d.-]/g, '')); }));
                let low: number = Math.min.apply(Math, tmp.map((o: any) => { return parseFloat((o[formula.Name] ?? 0).toString().replace(/[^\d.-]/g, '')); }));

                formula.Value = `${high.toFixed(formula.Format)} / ${low.toFixed(formula.Format)}`;
            }
        });

        return tmp;
    }

    private setResetOption(columns: any) {
        this.showReset = false;

        if ((this._options.columnSettings && columns.length > 0)
            || (this._options.filtering && this.filters.length > 0)
            || (this._options.sorting && this.sorts.length > 0)
            || (this._options.charts && this.chart !== "")) {
            this.showReset = true;
        }

        // Verify formulas set within grid but NOT passed into grid as pre-formulas.
        if (this._options.formulas && this._formulas.length > 0) {
            for (let formula of this._formulas) {
                let formulaNdx: number = this.formulas.findIndex((f: any) => f.ID === formula.ID && f.Name === formula.Name);

                if (formulaNdx === -1) {
                    this.showReset = true;
                    break;
                }
            }
        }
    }

    /**************************************************************************
     * Session Data
    **************************************************************************/
    private getSessionData() {
        let storage: any = localStorage.getItem(SESSION_KEY);

        if (storage && storage.length > 0) {
            storage = JSON.parse(storage);

            if (storage.settings) {
                if (storage.settings.rows) {
                    this.rows = storage.settings.rows;
                }
            }
        } else {
            storage = {
                version: this.version,
                settings: {
                    rows: 0
                },
                grids: []
            };
        }

        // Verify version
        if (storage.version !== this.version) {
            storage.grids = [];
        }

        storage.version = this.version;
        storage.settings = {
            rows: this.rows
        };

        localStorage.setItem(SESSION_KEY, JSON.stringify(storage));

        let name: string = `${this._name}_${this.uniqueIdentifier}`;

        let ndx = storage.grids.findIndex((grid: any) => grid.Name === name);

        if (ndx > -1) {
            let sessionData: any = storage.grids[ndx];

            this.initializeData(sessionData);
            this.initializeFormatters();
            this.initializeRawValueRetrievers();
            this.initializeDataTemplate();
        }
    }

    private initializeData(sessionData: any) {
        this.showNote = sessionData.Data.showNote ?? "";
        this.columnBadge = sessionData.Data.columnBadge ?? "";
        this.filterBadge = sessionData.Data.filterBadge ?? "";
        this.sortBadge = sessionData.Data.sortBadge ?? "";
        this.formulaBadge = sessionData.Data.formulaBadge ?? "";
        this.chartBadge = sessionData.Data.chartBadge ?? "";
        this._columns = sessionData.Data.columns ?? [];
        this.filters = sessionData.Data.filters ?? [];
        this.sorts = sessionData.Data.sorts ?? [];
        this._formulas = sessionData.Data.formulas ?? [];
        this.chart = sessionData.Data.chart ?? "";
        this.page = sessionData.Data.page ?? 1;
        this.scrollTop = sessionData.Data.scrollTop ?? 0;

        this.setResetOption(this._columns);
    }

    private initializeFormatters() {
        let columnsWithFormatters = this._columns.filter(c => c.formatter !== null);

        columnsWithFormatters?.forEach(fc => {
            let matchingColumns = this._columns.filter(c => c.Name === fc.Name);

            if (matchingColumns !== null && matchingColumns.length > 0)
                matchingColumns[0].formatter = fc.formatter;
        });
    }

    private initializeRawValueRetrievers() {
        let columsnWithRawValueRetrievers = this._columns.filter(c => c.rawValueRetriever !== null);

        columsnWithRawValueRetrievers?.forEach(fc => {
            let matchingColumns = this._columns.filter(c => c.Name === fc.Name);

            if (matchingColumns !== null && matchingColumns.length > 0)
                matchingColumns[0].rawValueRetriever = fc.rawValueRetriever;
        });
    }

    private initializeDataTemplate() {
        let columnsWithDataTemplate = this._columns.filter(c => c.dataTemplate !== null);

        columnsWithDataTemplate?.forEach(fc => {
            let matchingColumns = this._columns.filter(c => c.Name === fc.Name);

            if (matchingColumns !== null && matchingColumns.length > 0)
                matchingColumns[0].dataTemplate = fc.dataTemplate;
        });
    }

    private setSessionData(_page: number | null = null) {
        let columns: any = [];
        let match: boolean = true;

        try {
            if (this._columns.length > 0) {
                if (this.columns.length > 0) {
                    for (let ndx = 0; ndx < this.columns.filter((c: any) => !c.actionTemplate).length; ndx++) {
                        if (this.columns[ndx].Name !== this._columns[ndx].Name) match = false;
                        if (this.columns[ndx].isVisible === undefined && this._columns[ndx].isVisible) match = false;
                        if (this.columns[ndx].isVisible !== undefined && this.columns[ndx].isVisible !== this._columns[ndx].isVisible) match = false;
                        if (this.columns[ndx].FriendlyName === undefined && this._columns[ndx].FriendlyName) match = false;
                        if (this.columns[ndx].FriendlyName !== undefined && this.columns[ndx].FriendlyName !== this._columns[ndx].FriendlyName) match = false;
                    }
                } else {
                    for (let ndx = 0; ndx < this._originalColumns.length; ndx++) {
                        if (this._originalColumns[ndx].Name !== this._columns[ndx].Name) match = false;
                        if (this._originalColumns[ndx].isVisible !== this._columns[ndx].isVisible) match = false;
                        if (this._originalColumns[ndx].FriendlyName !== this._columns[ndx].FriendlyName) match = false;
                    }
                }
            }
        } catch {
            match = false;
        }

        if (!match || this.filters.length > 0) columns = this._columns;

        this.setResetOption(columns);

        let storage: any = localStorage.getItem(SESSION_KEY);
        let name: string = `${this._name}_${this.uniqueIdentifier}`;

        if (storage && storage.length > 0) {
            storage = JSON.parse(storage);

            storage.version = this.version;
            storage.settings.rows = this.rows;
        } else {
            storage = {
                version: this.version,
                settings: {
                    rows: this.rows
                },
                grids: []
            };
        }

        let sessionData: any = {
            "Name": name,
            "Data": {
                showNote: this.showNote,
                columnBadge: this.columnBadge,
                filterBadge: this.filterBadge,
                sortBadge: this.sortBadge,
                formulaBadge: this.formulaBadge,
                chartBadge: this.chartBadge,
                columns: columns,
                filters: this.filters,
                sorts: this.sorts,
                formulas: this._formulas,
                chart: this.chart,
                page: _page,
                scrollTop: this._scrollTop
            }
        };

        let grids = storage.grids.filter((grid: any) => grid.Name !== name) ?? [];

        grids.push(sessionData);

        storage.grids = grids;

        localStorage.setItem(SESSION_KEY, JSON.stringify(storage));
    }

    private resetScrollData() {
        let storage: any = localStorage.getItem(SESSION_KEY);
        let name: string = `${this._name}_${this.uniqueIdentifier}`;

        if (storage && storage.length > 0) {
            storage = JSON.parse(storage);

            if (storage.grids && storage.grids.length > 0) {
                let ndx = storage.grids.findIndex((grid: any) => grid.Name === name);

                if (ndx > -1) {
                    storage.grids[ndx].Data.page = null;
                    storage.grids[ndx].Data.scrollTop = 0;

                    localStorage.setItem(SESSION_KEY, JSON.stringify(storage));
                }
            }
        }
    }

    private clearSessionData() {
        let storage: any = localStorage.getItem(SESSION_KEY);
        let name: string = `${this._name}_${this.uniqueIdentifier}`;

        if (storage && storage.length > 0) {
            storage = JSON.parse(storage);

            let grids = storage.grids.filter((grid: any) => grid.Name !== name) ?? [];

            storage.grids = grids;

            localStorage.setItem(SESSION_KEY, JSON.stringify(storage));
        }
    }

    /**************************************************************************
    * Data Options
    **************************************************************************/
    public showDataOptions(type: string) {
        this.dataClass = this.showPanelOverlay ? "overlay" : "expand";
        this.dataMode = "data";

        switch (type) {
            case "settings":
                this.optionTitle = "Settings";

                let currentSetting = {
                    Name: "[Current Settings]",
                    Data: {
                        showNote: this.showNote,
                        columnBadge: this.columnBadge,
                        filterBadge: this.filterBadge,
                        sortBadge: this.sortBadge,
                        formulaBadge: this.formulaBadge,
                        chartBadge: this.chartBadge,
                        columns: this._columns,
                        filters: this.filters,
                        sorts: this.sorts,
                        formulas: this._formulas,
                        chart: this.chart
                    }
                }

                let ndx = this.settings.findIndex((setting: SDKDataGridSettings) => setting.Name === "[Current Settings]");

                if (ndx > -1) {
                    this.settings[ndx] = currentSetting;
                } else {
                    this.settings.unshift(currentSetting);
                }

                break;
            case "columns":
                this.optionTitle = "Columns";
                break;
            case "filters":
                this.optionTitle = "Filters";
                break;
            case "sorting":
                this.optionTitle = "Sorting";
                break;
            case "formulas":
                this.optionTitle = "Formulas";
                break;
            case "export":
                this.optionTitle = "Export";
                break;
            case "chart":
                this.optionTitle = "Chart";
                this.toggleDataMode("chart");
                break;
            default:
                this.optionTitle = type;
                break;
        }

        this.setAddonInputs();
    }

    public toggleDataMode(type: string, autoClose: boolean = false) {
        this.dataMode = type;

        if (this.dataMode === "data" && this.optionTitle === "Chart") {
            this.dataClass = "";
            this.optionTitle = "";
        }
        if (this.dataMode === "chart") {
            if (this.chart && this.chart !== "") {
                this.applyChartOptions(this.chart, autoClose);
            }
        }
    }

    public closeDataOptions() {
        this.optionTitle = "";
        this.dataClass = "";

        this.setAddonInputs();
    }

    public clearAllOptions() {
        if (confirm("Reset ALL Options... Are you sure?")) {
            this.dataMode = "data";
            this.optionTitle = "";
            this.dataClass = "";

            this.initialLoad = true;
            this.isLoaded = false;
            this._isLoading = true;

            this.resetVariables();
            this.clearSessionData();

            this.loadDataEvent.emit({ name: this._name, page: 1, rows: this.rows, sorts: this.sorts, filters: this.filters });
        }
    }

    /**************************************************************************
    * Settings
    **************************************************************************/
    public saveSettings(event: any) {
        if (this.settingsSavedEvent.observed && event) {
            this.settingsSavedEvent.emit(event);
        }
    }

    public applySettings(event: any) {
        if (event) {
            this.initializeData(event);
            this.initializeFormatters();
            this.initializeRawValueRetrievers();
            this.initializeDataTemplate();

            this.reloadData();

            if (this.autoClosePanel) this.closeDataOptions();

            this.setSessionData();
        }
    }

    /**************************************************************************
    * Columns
    **************************************************************************/
    public applyColumnOptions(event: any) {
        this._isLoading = true;

        setTimeout(() => {
            this._columns = event;

            this.showNote = this._columns.filter(c => c.FriendlyName !== "").length > 0 ? true : false;

            let adjustments: number = this._columns.filter(c => !c.isVisible || c.FriendlyName !== "").length;

            if (this.columns.length > 0) {
                adjustments = 0;

                this._columns.forEach((column: any) => {
                    let ndx: any = this.columns.findIndex((c: any) => c.Name === column.Name);

                    if (ndx === -1) {
                        adjustments++;
                    } else {
                        if ((this.columns[ndx].isVisible === undefined && column.isVisible)
                            || (this.columns[ndx].isVisible !== undefined && column.isVisible !== this.columns[ndx].isVisible)
                            || this.columns[ndx].FriendlyName !== undefined && column.FriendlyName !== this.columns[ndx].FriendlyName) {
                            adjustments++;
                        }
                    }
                });
            }

            if (adjustments === 0) {
                this.columnBadge = "";
            } else {
                this.columnBadge = adjustments.toString();
            }

            this.adjustColumnsForOptions();

            this._isLoading = false;

            if (this.autoClosePanel) this.closeDataOptions();

            this.setSessionData();
            this.columnSettingsChangedEvent.emit(event);
        }, 1);
    }

    public hasVisibleColumns(): boolean {
        return this._columns.filter(c => c.isVisible).length > 0 ? true : false;
    }

    public getColumnOriginalName(column: SDKDataGridColumn): string {
        let originalName = column.Name;

        if (column.DisplayName && column.DisplayName !== "") {
            originalName = column.DisplayName;
        }

        return originalName;
    }

    public setOriginalColumns(isInit: boolean = false) {
        this._originalColumns = [];

        // Get all columns from dataset.
        if (this._originalData && this._originalData.length > 0) {
            for (let key in this._originalData[0]) {
                this._originalColumns.push({
                    Name: key.toString(),
                    DisplayName: "",
                    FriendlyName: "",
                    Sort: "",
                    FilterMultiSelect: false,
                    ColumnSort: false,
                    showSort: true,
                    showFilter: true,
                    showTooltip: false,
                    isVisible: true,
                    isAction: false,

                    actionSide: "",

                    allowEdit: true,
                    required: false,
                    notes: "",
                    validCharacters: "",
                    hint: "",
                    pattern: "",
                    height: "",
                    width: "",
                    border: "",
                    style: "",

                    actionTemplate: undefined,
                    editTemplate: undefined,
                    dataTemplate: undefined,

                    formatter: undefined,
                    rawValueRetriever: undefined
                });
            }
        }

        // Process user-defined columns.
        if (this.columns.length > 0) {
            let tmp: any = [];

            this._columnActions = [];

            // Verify column is actually in dataset or an Action column.
            this.columns.forEach((column: any) => {
                if (column.FilterMultiSelect === undefined) {
                    column.FilterMultiSelect = false;
                }

                if (column.isVisible === undefined) {
                    column.isVisible = true;
                }

                if (column.ColumnSort === undefined) {
                    column.ColumnSort = false;
                }

                if (column.showSort === undefined) {
                    column.showSort = true;
                }

                if (column.showFilter === undefined) {
                    column.showFilter = true;
                }

                if (column.showTooltip === undefined) {
                    column.showTooltip = false;
                }

                if (column.allowEdit === undefined) {
                    column.allowEdit = true;
                }

                if (column.required === undefined) {
                    column.required = false;
                }

                if (column.isAction === undefined || column.actionTemplate === undefined) {
                    column.isAction = false;
                }

                let ndx: number = this._originalColumns.findIndex((c: any) => c.Name === column.Name);

                if (ndx > -1) {
                    let merge = { ...this._originalColumns[ndx], ...column };

                    tmp.push(merge);
                } else {
                    if (column.actionTemplate) {
                        column.FriendlyName = "";
                        column.Sort = "";
                        column.FilterMultiSelect = false;
                        column.ColumnSort = false;
                        column.showSort = true;
                        column.showFilter = true;
                        column.showTooltip = false;
                        column.isVisible = false;
                        column.isAction = true;

                        this._columnActions.push({ ...column });
                    }
                }
            });

            this._originalColumns = tmp;

            if (isInit && this._columns.length > 0) {
                // Reset properties that are NOT saved in storage.
                tmp.forEach((column: any) => {
                    let ndx: number = this._columns.findIndex((c: any) => c.Name === column.Name);

                    if (ndx > -1) {
                        let merge = { ...column, ...this._columns[ndx] };

                        merge.actionTemplate = column.actionTemplate;
                        merge.editTemplate = column.editTemplate;
                        merge.dataTemplate = column.dataTemplate;

                        merge.formatter = column.formatter;
                        merge.rawValueRetriever = column.rawValueRetriever;

                        this._columns[ndx] = merge;
                    }
                });
            } else {
                this._columns = tmp;
            }

            // Arrange columns in user-defined order.
            tmp = [];

            this._columns.forEach((column: any) => {
                if (column.isAction !== undefined && column.isAction) {
                    tmp.push(column);
                } else {
                    let ndx: number = this._columns.findIndex((c: any) => c.Name === column.Name);

                    if (ndx > -1) {
                        tmp.push(this._columns[ndx]);
                    }
                }
            });

            this._columns = tmp;
        } else {
            if (!isInit || this._columns.length === 0) this._columns = this._originalColumns;
        }
    }

    private adjustColumnsForOptions() {
        /**************************************************************************
        * Adjust columns (isVisible/FriendlyName) for all other options
        **************************************************************************/
        let updateData = false;
        let tmp: any[] = [];

        if (this.filters.length > 0) {
            tmp = [];

            this.filters.forEach((filter: any) => {
                let ndx: number = this._columns.findIndex(c => c.Name === filter.Name && c.isVisible && !c.actionTemplate);
                let column: SDKDataGridColumn = this._columns[ndx];

                if (column) {
                    filter.FriendlyName = column.FriendlyName;

                    tmp.push(filter);
                } else {
                    updateData = true;
                }
            });

            this.filters = tmp;
            this.adjustFilterBadge();
        }

        if (this.sorts.length > 0) {
            tmp = [];

            this.sorts.forEach((sort: any) => {
                let ndx: number = this._columns.findIndex(c => c.Name === sort.Name && c.isVisible && !c.actionTemplate);
                let column: SDKDataGridColumn = this._columns[ndx];

                if (column) {
                    sort.FriendlyName = column.FriendlyName;

                    tmp.push(sort);
                } else {
                    updateData = true;
                }
            });

            this.sorts = tmp;
            this.adjustSortBadge();
        }

        if (this.formulas.length > 0) {
            updateData = true;
        }

        if (this._formulas.length > 0) {
            tmp = [];

            this._formulas.forEach((formula: any) => {
                let ndx: number = this._columns.findIndex(c => c.Name === formula.Name && c.isVisible && !c.actionTemplate);
                let column: SDKDataGridColumn = this._columns[ndx];

                if (column) {
                    formula.FriendlyName = column.FriendlyName;

                    tmp.push(formula);
                } else {
                    updateData = true;
                }
            });

            this._formulas = tmp;
            this.adjustFilterBadge();
        }

        if (updateData) {
            this.setSessionData();
            this.resetGrid();
            this.reloadData();
        }
    }

    private getColumnValue(columnName: string, dataRow: any): any {
        let matches = this._columns.filter((c: any) => c.Name === columnName);

        if (matches) {
            let column = matches[0];

            if (column) {
                let columnValue = dataRow[column.Name];

                return column.rawValueRetriever ? column.rawValueRetriever(columnValue) : columnValue;
            }
        }

        return null;
    }

    /**************************************************************************
    * Filtering
    **************************************************************************/
    public applyFilterOptions(event: any) {
        this._isLoading = true;

        setTimeout(() => {
            this.filters = event;

            this.adjustFilterBadge();
            this.setSessionData();
            this.reloadData();

            if (this.autoClosePanel) this.closeDataOptions();
        }, 1);
    }

    private adjustFilterBadge() {
        if (this.filters.length === 0) {
            this.filterBadge = "";
        } else {
            this.filterBadge = (this.filters.length).toString();
        }
    }

    /**************************************************************************
    * Sorting
    **************************************************************************/
    public applySortOptions(event: any) {
        this._isLoading = true;

        setTimeout(() => {
            this.sorts = event;

            this._columns.forEach((column: SDKDataGridColumn) => {
                if (this.sorts.length > 0 && this.sorts[0].Name === column.Name) {
                    column.Sort = this.sorts[0].Sort;
                } else {
                    column.Sort = "";
                }

                column.ColumnSort = false;
            });

            this.resetGrid();
            this.applySorting();
        }, 1);
    }

    public inlineSort(column: SDKDataGridColumn) {
        if (column.showSort && !column.actionTemplate) {
            let ndx: number = this.sorts.findIndex(s => s.Name === column.Name);
            let sort: any = this.sorts[ndx];

            if (sort) {
                let item: any = this.sorts.splice(ndx, 1)[0];

                this.sorts.unshift({ Name: column.Name, DisplayName: column.DisplayName, FriendlyName: column.FriendlyName, Sort: (item.Sort === "asc") ? "desc" : "asc", formatter: column.formatter, rawValueRetriever: column.rawValueRetriever, dataTemplate: column.dataTemplate });

                column.Sort = (item.Sort === "asc") ? "desc" : "asc";
            } else {
                this.sorts.unshift({ Name: column.Name, DisplayName: column.DisplayName, FriendlyName: column.FriendlyName, Sort: "asc", formatter: column.formatter, rawValueRetriever: column.rawValueRetriever, dataTemplate: column.dataTemplate });

                column.Sort = "asc";
                column.ColumnSort = true;
            }

            this.sorts = this.sorts.slice(0);

            this.resetColumnSorts(column.Name);
            this.applySorting();
        }
    }

    private resetColumnSorts(name: string) {
        this._columns.forEach((column: SDKDataGridColumn) => {
            if (column.Name !== name) {
                column.Sort = "";

                if (column.ColumnSort) {
                    column.ColumnSort = false;

                    let ndx = this.sorts.findIndex(s => s.Name === column.Name);

                    if (ndx) this.sorts.splice(ndx, 1);
                }
            }
        });

        this.setSessionData();
    }

    private applySorting() {
        this.adjustSortBadge();
        this.setSessionData();
        this.reloadData();

        if (this.autoClosePanel) this.closeDataOptions();
    }

    private adjustSortBadge() {
        if (this.sorts.length === 0) {
            this.sortBadge = "";
        } else {
            this.sortBadge = (this.sorts.length).toString();
        }
    }

    /**************************************************************************
    * Formulas
    **************************************************************************/
    public applyFormulaOptions(event: any) {
        this._isLoading = true;

        setTimeout(() => {
            this._formulas = event;

            this.adjustFormulaBadge();
            this.setSessionData();
            this.reloadData();

            if (this.autoClosePanel) this.closeDataOptions();
        }, 1);
    }

    private setOriginalFormulas() {
        // Process user-defined formulas.
        this.formulas.forEach((formula: any) => {
            let columnNdx: number = this._columns.findIndex((c: any) => c.Name === formula.Name && c.isVisible);
            let formulaNdx: number = this._formulas.findIndex((f: any) => f.Name === formula.Name);

            if (columnNdx > -1 && formulaNdx === -1) {
                this._formulas.push(formula);
            }
        });
    }

    private adjustFormulaBadge() {
        if (this._formulas.length === 0) {
            this.formulaBadge = "";
        } else {
            let count: number = 0;

            for (let formula of this._formulas) {
                let formulaNdx: number = this.formulas.findIndex((f: any) => f.ID === formula.ID && f.Name === formula.Name);

                if (formulaNdx === -1) {
                    count++;
                }
            }

            this.formulaBadge = count === 0 ? "" : count.toString();
        }
    }

    /**************************************************************************
    * Export
    **************************************************************************/
    public applyExportOptions(event: any) {
        this._isLoading = true;

        setTimeout(() => {
            this.export = event;

            if (this.isPartial) {
                this.page = 1;

                let exportFilters = this.export.Filters ? this.filters : [];
                let exportSorts = this.export.Sorting ? this.sorts : [];

                this.loadDataEvent.emit({ name: this._name, page: 1, rows: this.rows, sorts: exportSorts, filters: exportFilters, export: true });
            } else {
                let tmp: any = this._originalData.slice(0);

                if (this.export.Filters) tmp = this.filterData(tmp);
                if (this.export.Sorting) tmp = this.sortData(tmp);

                this.fullDataArray = tmp.slice(0);
                this.exportData();
            }
        }, 100);
    }

    private exportData() {
        let tmp: any = this.fullDataArray.slice(0, 1000);

        tmp = this.adjustExportColumns(tmp);

        let dataArray: any[] = JSON.parse(JSON.stringify(tmp));
        let fileName = this.getExportFileName();

        let link = document.createElement("a");

        link.href = 'data:text/csv;charset=utf-8,' + this.convertToCSV(dataArray);
        link.download = fileName + ".csv";
        link.click();

        if (this.autoClosePanel) this.closeDataOptions();

        this._isLoading = false;
    }

    private convertToCSV(objArray: any) {
        let csv: any = "";

        for (let row = 0; row < objArray.length; row++) {
            let output: any = "";

            // Add column names as first row.
            if (csv === "") {
                for (let column in objArray[row]) {
                    if (output !== "") {
                        output += ",";
                    }

                    output += column;
                }

                csv += `${output}\r\n`;
                output = "";
            }

            for (let column in objArray[row]) {
                if (output !== "") {
                    output += ",";
                }

                if (objArray[row][column]) {
                    output += `"${objArray[row][column].toString().replace(/\"/g, "'")}"`;
                } else {
                    output += "";
                }
            }

            csv += `${output}\r\n`;
        }

        return csv;
    }

    private adjustExportColumns(tmp: any) {
        let rows: any = [];

        tmp.forEach((row: any) => {
            let columns: any = {};

            this._columns.forEach((column: any) => {
                if ((column.isVisible === undefined || column.isVisible) && !column.isAction) {
                    if (this.export.Columns && column.FriendlyName !== "") {
                        columns[column.FriendlyName] = row[column.Name];
                    } else if (column.DisplayName !== "") {
                        columns[column.DisplayName] = row[column.Name];
                    } else {
                        columns[column.Name] = row[column.Name];
                    }
                }
            });

            rows.push(columns);
        });

        return rows.slice(0);
    }

    private getExportFileName() {
        if (this.datasets && this.datasets.length > 0) {
            let filtered = this.datasets.filter(ds => ds.DbName === this._name);

            if (filtered && filtered.length > 0) {
                return filtered[0].Title;
            }
        }

        if (this._name && this._name !== "") {
            return this._name;
        }

        return "App-Data";
    }

    /**************************************************************************
    * Chart
    **************************************************************************/
    public applyChartOptions(event: any, autoClose: boolean = true) {
        this._isLoading = true;

        setTimeout(() => {
            this.chart = event;

            this.setChartBadge();

            if (this.chart.Show) {
                if (this.fullDataArray.length > 0) {
                    this.createChart();
                } else {
                    this.loadDataEvent.emit({ name: this._name, page: 1, rows: this.rows, sorts: this.sorts, filters: this.filters, chart: true });
                }
            } else {
                this.destroyChart();

                this._isLoading = false;
            }

            this.setSessionData();

            if (autoClose) this.closeDataOptions();
        }, 1);
    }

    private createChart() {
        this._isLoading = true;

        if (['bar', 'line', 'radar'].indexOf(this.chart.Type) > -1) {
            if (this.chart.Label !== "" && this.chart.DataX !== "" && this.chart.DataY !== "") {
                this.createTwoColumnChart();
            }
        }

        if (['doughnut', 'pie', 'polarArea'].indexOf(this.chart.Type) > -1) {
            if (this.chart.Label !== "" && this.chart.DataY !== "") {
                this.createOneColumnChart();
            }
        }

        this._isLoading = false;
    }

    private destroyChart() {
        if (this.chartjs) this.chartjs.destroy();
    }

    private createOneColumnChart() {
        let labels = this.fullDataArray.map((d: any) => this.getColumnValue(this.chart.Label, d)).filter((value: any, index: any, self: any) => self.indexOf(value) === index);//.sort((a: any, b: any) => (a > b) ? 1 : -1);
        let datasets: any[] = [];
        let tmp: any[] = [];

        labels.forEach((label: string) => {
            let data: any = this.fullDataArray.filter((d: any) => this.getColumnValue(this.chart.Label, d) === label).reduce((total: any, current: any) => total + new Number(this.getColumnValue(this.chart.DataY, current)), 0);

            tmp.push(data);
        });

        datasets.push({
            data: tmp,
            backgroundColor: Colors.getRandomColors(tmp.length),
            hoverOffset: 5,
        });

        this.destroyChart();

        this.canvas = this.chartCanvas.nativeElement;

        this.ctx = this.canvas.getContext('2d');

        this.chartjs = new Chart(this.ctx, {
            type: this.chart.Type,
            data: {
                labels: labels,
                datasets: datasets,
            },
            options: {
                responsive: true,
                maintainAspectRatio: false,
                aspectRatio: 1,
                plugins: {
                    tooltip: {
                        callbacks: {
                            label: (context: any) => {
                                let label = context.label;
                                let value = context.raw;
                                let percent = "0.00%";

                                if (['doughnut', 'pie'].indexOf(this.chart.Type) > -1) {
                                    let sum = 0;

                                    let dataArr = context.dataset.data;
                                    dataArr.map((x: any) => {
                                        sum += x;
                                    });

                                    percent = `${(value * 100 / sum).toFixed(2)}%`;
                                }

                                return `${label}: ${value} (${percent})`;
                            }
                        }
                    }
                }
            }
        });
    }

    private createTwoColumnChart() {
        let labels: any[] = this.fullDataArray.map((d: any) => this.getColumnValue(this.chart.Label, d)).filter((value: any, index: any, self: any) => self.indexOf(value) === index);//.sort((a: any, b: any) => (a > b) ? 1 : -1);
        let datasets: any[] = [];
        let colors: any[] = Colors.getRandomColors(labels.length);
        let count: number = 0;

        labels.forEach((label: string) => {
            let data: any[] = this.fullDataArray.filter((d: any) => this.getColumnValue(this.chart.Label, d) === label);
            let tmp: any[] = [];

            data.forEach((item: any) => {
                let convertNumber = new Number(this.getColumnValue(this.chart.DataY, item));
                tmp.push(convertNumber);
            });

            if (this.chart.Option && this.chart.Type === "bar") {
                datasets.push({
                    data: tmp,
                    label: label,
                    stack: "a",
                    backgroundColor: colors[count]
                });
            } else if (this.chart.Option && this.chart.Type === "line") {
                datasets.push({
                    data: tmp,
                    label: label,
                    fill: 'origin',
                    tension: 0.1,
                    backgroundColor: colors[count]
                });
            } else {
                datasets.push({
                    data: tmp,
                    label: label,
                    backgroundColor: colors[count]
                });
            }

            count++
        });

        this.destroyChart();

        this.canvas = this.chartCanvas.nativeElement;

        this.ctx = this.canvas.getContext('2d');

        this.chartjs = new Chart(this.ctx, {
            type: this.chart.Type,
            data: {
                labels: this.fullDataArray.map((d: any) => this.getColumnValue(this.chart.DataX, d)).filter((value: any, index: any, self: any) => self.indexOf(value) === index),//.sort((a: any, b: any) => (a > b) ? 1 : -1),
                datasets: datasets
            },
            options: {
                responsive: true,
                maintainAspectRatio: false,
                aspectRatio: 1,
                plugins: {
                    tooltip: {
                        callbacks: {
                            label: (context: any) => {
                                let label = context.dataset.label;
                                let value = context.raw;

                                return `${label}: ${value}`;
                            }
                        }
                    }
                }
            }
        });
    }

    private setChartBadge() {
        if (this.chart === "" || (this.chart.DataX === "" && this.chart.DataY === "")) {
            this.chartBadge = "";
        } else {
            this.chartBadge = "*";
        }
    }

    /**************************************************************************
    * Addon Methods
    **************************************************************************/
    public applyDataOptions(event: any) {
        this._isLoading = true;

        this.loadDataEvent.emit({ name: this._name, page: 1, rows: this.rows, sorts: this.sorts, filters: this.filters, addon: event });
    }

    private setAddonInputs() {
        this.optionInputs = {
            optionTitle: this.optionTitle,
        };

        let ds: any = this.datasets.filter((ds: any) => ds.DbName === this._name)[0];

        if (ds) {
            ds.DbName = this._name

            this.windowInputs = {
                dataClass: this.dataClass,
                optionTitle: this.optionTitle,
                datasets: [ds],
                columns: this._columns ?? [],
                filters: this.filters ?? [],
                sorts: this.sorts ?? []
            };
        }
    }

    /**************************************************************************
    * Rows
    **************************************************************************/
    /**************************************************************************
    * Action
    **************************************************************************/
    public takeAction(args: any) {
        if (this.selectedRowActionEvent.observed) {
            this.setSessionData(this.page);

            this.selectedRowActionEvent.emit(args);
        }
    }

    public onScroll(event: any) {
        if (this.selectedRowActionEvent.observed) {
            this._scrollTop = event.target.scrollTop;
        }
    }

    /**************************************************************************
    * Expand/Collapse Rows
    **************************************************************************/
    public expandAll() {
        this.setExpandedForAll(true);
    }

    public collapseAll() {
        this.setExpandedForAll(false);
    }

    public toggleExpanded(rowItem: any) {
        rowItem["Expanded"] = !rowItem["Expanded"];
    }

    public isFirstColumn(column: SDKDataGridColumn): boolean {
        let visibleColumns = this._columns.filter(c => c.isVisible);

        return visibleColumns.length > 0 && column.Name === visibleColumns[0].Name;
    }

    private setExpandedForAll(isExpanded: boolean) {
        let dataItems = this._data as unknown as any[];

        dataItems.forEach((item) => {
            item["Expanded"] = isExpanded;
        });
    }

    /**************************************************************************
    * Select Rows
    **************************************************************************/
    public toggleSelected(event: any, rowItem: any) {
        event.stopPropagation();
        rowItem["Selected"] = !rowItem["Selected"];

        // Check if we need to set select all (if all items have the same state)
        let dataItems = this._data as unknown as any[];

        this.selectAll = dataItems.every(item => item["Selected"] && item["Selected"] === true);
        this.selectPartial = !this.selectAll && dataItems.some(item => item["Selected"] && item["Selected"] === true);

        this.emitSelectionChangedEvent();
    }

    public toggleSelectAll(event: any) {
        event.stopPropagation();

        this.selectAll = !this.selectAll;
        this.selectPartial = false;

        let dataItems = this._data as unknown as any[];

        dataItems.forEach((item) => {
            item["Selected"] = this.selectAll;
        });

        this.emitSelectionChangedEvent();
    }

    public isSelected(rowItem: any): boolean {
        return rowItem["Selected"] && rowItem["Selected"] === true;
    }

    private emitSelectionChangedEvent() {
        let dataItems = this._data as unknown as any[];

        if (dataItems && dataItems.length > 0) {
            let selectedItems = dataItems.filter(d => d["Selected"] === true);

            this.selectedRowsChangedEvent.emit(selectedItems);
        }
    }

    /**************************************************************************
    * Paging (Footer)
    **************************************************************************/
    public getPage(page: any) {
        this._isLoading = true;

        if (page.target) page = page.target.value;

        setTimeout(() => {
            this.page = parseInt(page);

            if (this.isPartial && this.loadMoreData()) {
                let nextPage = Math.ceil((this.page * this.rows) / this.dbMAXRECORDS);

                this.loadDataEvent.emit({ name: this._name, page: nextPage, rows: this.rows, sorts: this.sorts, filters: this.filters });
            } else {
                let tmp: any = this._originalData.slice(0);

                if (this.filters.length > 0) tmp = this.filterData(tmp);
                if (this.sorts.length > 0) tmp = this.sortData(tmp);

                this.sliceData(tmp);

                this.resetGrid();

                this._isLoading = false;
            }
        }, 1);
    }

    public setRows(rows: any) {
        this._isLoading = true;

        setTimeout(() => {
            this.page = 1;
            this.rows = parseInt(rows.target.value);

            this.setSessionData();

            this.loadDataEvent.emit({ name: this._name, page: 1, rows: this.rows, sorts: this.sorts, filters: this.filters });
        }, 1);
    }

    private loadMoreData(): boolean {
        let loadMoreAbove: boolean = (this.page * this.rows) > (this.dbPage! * this.dbMAXRECORDS);
        let loadMoreBelow: boolean = (this.page * this.rows) <= ((this.dbPage! * this.dbMAXRECORDS) - this.dbMAXRECORDS);

        return loadMoreAbove || loadMoreBelow;
    }

    private getDBPage(): number {
        return Math.ceil((this.page * this.rows) / this.dbMAXRECORDS);
    }

    private sliceData(data: any) {
        let maxRecords: number = this.isPartial ? this.dbMAXRECORDS : this._total;
        let page: number = this.isPartial ? this.dbPage! : 1;
        let pagesPerData: number = maxRecords / this.rows;
        let start: number = Math.abs(((page - 1) * pagesPerData * this.rows) - ((this.page - 1) * this.rows));
        let end: number = (start + this.rows);

        if (data) {
            this._data = data.slice(start, end);
        }

        if (this.editRowIndex !== undefined) {
            for (let row of this._data) {
                row.isEdit = false;
            }
        }
    }

    private setFooter() {
        this.totalPages = new Array(0);

        for (let p = 0; p < Math.ceil(this._total / this.rows); p++) {
            let start: number = (p * this.rows) + 1;
            let end: number = (start + this.rows) - 1;

            if (end > this._total) end = this._total;

            this.totalPages.push(start.toString() + " - " + end.toString());
        }
    }
}
