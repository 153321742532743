<div class="content">
    <div>
        <div style="text-align: center;"><img [title]="title" [alt]="title" src="assets/images/logo.png" height="150"></div>
        <div class="header">You do not have permission to access this {{ type }}.</div>
        <div class="detail">
            To request access, please use the following link:
            <div class="support"><a href="{{ supportLink }}" target="_blank" rel="noopener noreferrer">Carbon Credits Management System Support</a></div>
        </div>
    </div>
</div>
