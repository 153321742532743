<div *ngIf="hasData" class="profile-container">
  <h3 class="header" *ngIf="!shortenTitle">Emissons and Targets of {{viewModel.name}}</h3>
  <h3 class="header" *ngIf="shortenTitle">Emissons and Targets</h3>
  <div class="indent">
  <div class="flex-row">
    <div class="profile-data">
      <label class="key-label">
        GHG emissions:&nbsp;
      </label>
      <span>
        {{ viewModel.ghgEmissionsFormatted }} <span class="subscript">MtCO<sub>2</sub>e</span>
      </span>
    </div>
    <div class="profile-data" *ngIf="!viewModel.isRegion">
      <label class="key-label">
        GDP:&nbsp;
      </label>
      <span>
        ${{ viewModel.gdpFormatted }}
      </span>
    </div>
    <div class="profile-data" *ngIf="viewModel.population">
      <label class="key-label">
        Population:&nbsp;
      </label>
      <span>
        {{ viewModel.populationFormatted }}
      </span>
    </div>
    <div class="profile-data">
      <label class="key-label">
        NDC Plan:&nbsp;
      </label>
      <span>
        {{ viewModel.ndcPlan }}
      </span>
    </div>
  </div>
  <div class="key-break"></div>
  <div *ngIf="viewModel.notes">
    <label class="detail-label">
      Notes:&nbsp;
    </label>
    <span [innerHTML]="viewModel.notes | policyLinkify">
    </span>
  </div>
  </div>
  <div class="section-break"></div>
  <h3 class="header">GHG Reduction Targets</h3>
  <div class="indent">
  <div *ngIf="viewModel.interimTargetYear">
    <span class="by-year">By {{ viewModel.interimTargetYear }}:</span>&nbsp;<span>{{ viewModel.interimTargetSummary }}&nbsp;</span><span *ngIf="viewModel.interimTargetBaselineSummary">({{ viewModel.interimTargetBaselineSummary }})</span>
    <span class="indent" [innerHTML]="viewModel.interimTargetText | policyLinkify"></span>
  </div>
  <div *ngIf="viewModel.endTargetYear">
    <span class="by-year">By {{ viewModel.endTargetYear }}:</span>&nbsp;<span class="lowercase">{{ viewModel.endTargetSummary }}&nbsp;</span><span *ngIf="viewModel.endTargetStatusSummary" class="lowercase">({{ viewModel.endTargetStatusSummary }})</span>
    <span class="indent" [innerHTML]="viewModel.endTargetText | policyLinkify"></span>
  </div>
  </div>
  <div class="section-break"></div>
  <div *ngIf="viewModel.sourceUrl">
    <label class="detail-label">
      Source:&nbsp;&nbsp;<a *ngIf="viewModel.sourceUrl" [href]="viewModel.sourceUrl" target="_blank">link</a>
    </label>
  </div>
</div>