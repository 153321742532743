import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
    selector: 'export-option',
    templateUrl: './export-option.component.html',
    styleUrls: ['./export-option.component.scss']
})
export class ExportOptionComponent {
    @Input() optionTitle: string = ""; // Unique name for option.
    @Output() showDataOptionsEvent = new EventEmitter<any>(); // Method to show option window.

    public exportBadge: string = ""; // Used to add badge to custom option.

    public showDataOptions(type: string) {
        this.showDataOptionsEvent.emit(type);
    }
}
