import { Injectable } from '@angular/core';
import { Observable, from } from 'rxjs';
import { HttpService } from '../core/http.service';
import { SearchResult } from '~/models/shared/search/search-result';

@Injectable()
export class KeywordSearchService {
    constructor(private readonly _httpClient: HttpService) { }

    public findByKeywords(...searchTerms: string[]): Observable<SearchResult[]>{
        const searchUrl = `/Search?terms=${searchTerms}`;
        return from(this._httpClient.Get(searchUrl));
    }
}