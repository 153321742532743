<div class="content">
    <div class="data">
      <mat-tab-group #tabGroup mat-align-tabs="center" class="remove-border-bottom" (selectedTabChange)="onTabChanged($event)" animationDuration="200ms" dynamicHeight fitInkBarToContent>
        <mat-tab label="funded">
          <ng-template mat-tab-label>
            <span class="material-icons-outlined" matTooltip="funded">
              attach_money
            </span>
          </ng-template>
          <ng-template matTabContent>
            <ccms-credit-forecasts-form #funded
              [projectId]="project.id"
              [riskForecastData]="riskFundedForecasts"
              [unriskForecastData]="unriskFundedForecasts"
              [forecastType]="FT.Funded" (onSave)="creditForecastsForm_OnSaved($event)"></ccms-credit-forecasts-form>
          </ng-template>
        </mat-tab>
        <mat-tab label="current">
          <ng-template mat-tab-label>
            <span class="material-icons-outlined" matTooltip="current">
              payments
            </span>
          </ng-template>
          <ng-template matTabContent>
            <div class="copy-button">
              <ccms-icon-button class="small" aria-label="Copy funded forecasts" icon="icon-copy" (click)="copyFunded()" [hidden]="unriskCurrentForecasts.length > 0 || isCurrentEditing || !canUpdate" [disabled]="unriskFundedForecasts.creditForecasts.length === 0">Funded</ccms-icon-button>
            </div>
            <ccms-credit-forecasts-form #current 
              [projectId]="project.id" 
              [riskForecastData]="riskCurrentForecasts"
              [unriskForecastData]="unriskCurrentForecasts"
              [forecastType]="FT.Current" [hidden]="unriskCurrentForecasts.length === 0 && !isCurrentEditing" (onSave)="creditForecastsForm_OnSaved($event)"></ccms-credit-forecasts-form>
          </ng-template>
        </mat-tab>
      </mat-tab-group>
      <ng-container *ngIf="error">
        <ccms-alert [type]="AT.WARNING" [message]="error.errors" class="alert-container"></ccms-alert>
      </ng-container>
      <div class="spacer"></div>
      <sdk-loading [isLoading]="isLoading"></sdk-loading>
    </div>
    <div class="info">
      <tab-info [project]="project" [portfolioId]="portfolioId" [isEdit]="isInEdit" (changeEvent)="infoChange($event)"></tab-info>
    </div>
</div>
