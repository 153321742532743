<div *ngIf="dataClass === 'overlay' && optionTitle === 'export-mr'" class="window">
  <div class="header">
      <span class="title">Export to Excel</span>
      <span title="Close" class="icon material-icons-outlined close" (click)="close()">close</span>
  </div>
  <div class="content">
      <ng-container *ngIf="errorMessage" class="alert-container">
          <ccms-alert [type]="AT.WARNING" [message]="errorMessage"></ccms-alert>
      </ng-container>
      
      <!-- Include Filters Checkbox -->
      <div class="section">
        <div class="checkbox-label">
          <input type="checkbox" id="includeFilters" [(ngModel)]="includeFilters">
          <label for="includeFilters">Include Filters</label>
        </div>
      </div>

      <!-- Include Sorting Checkbox -->
      <div class="section">
        <div class="checkbox-label">
          <input type="checkbox" id="includeSorts" [(ngModel)]="includeSorts">
          <label for="includeSorts">Include Sorting</label>
        </div>
      </div>

      <div class="section">
        <div class="checkbox-label">
          <input type="checkbox" id="includeColumnChanges" [(ngModel)]="includeColumnChanges">
          <label for="includeColumnChanges">Include Column Changes</label>
        </div>
      </div>
      
      <sdk-loading [isLoading]="isLoading"></sdk-loading>
  </div>
  <div class="footer">
      <button class="button" type="button" (click)="onExport()" [disabled]="isLoading">Export</button>
  </div>
</div>
