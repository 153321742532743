import { Component, Input, SimpleChanges, TemplateRef, ViewChild } from '@angular/core';
import { SDKDataGridDataset, SDKDataGridOptions, Filters, SDKDataGridSettings, SDKDataGridColumn } from 'sdk-datagrid';
import { BaseComponent } from '~/pages/base.component';
import { FormatterService } from '~/services/shared/formatter.service';
import { SettingsGrid } from '~/services/shared/grid/models/settings-grid.model';
import { GridHandlerService } from '~/services/shared/grid/grid-handler.service';
import { AbuInventoryService } from '~/services/shared/inventory/abu-inventory-service';
import { AbuInventorySearchOptions } from '~/services/shared/inventory/models/abu-Inventory-search-options';
import { Filter } from '~/models/shared/filter';
import { FilterInfo } from '~/models/shared/filterInfo';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
    selector: 'abu-transactions',
    templateUrl: './abu-transactions.component.html',
    styleUrls: ['./abu-transactions.component.scss']
})

export class AbuTransactionsComponent extends BaseComponent implements SettingsGrid {
    @ViewChild('longTextTemplate') longTextTemplate!: TemplateRef<any>;
    @ViewChild('registryTemplate') registryTemplate!: TemplateRef<any>;
    @ViewChild('metaRegistryIdTemplate') metaRegistryIdTemplate!: TemplateRef<any>;
    @Input() showFooter: boolean = true;
    @Input() accountNumber: string | undefined; // optional account number to limit transactions for this account (acquired or transferred)
    @Input() uniqueIdentifierPostfix: string = "";
    public footnote: string = "";
    public MAXRECORDS: number = 1000;
    public activeView: string = "AbuInventory/Search";
    public datasets: SDKDataGridDataset[] = [
        {
            Title: "Inventory",
            DbName: "AbuInventory/Search",
        }
    ];
    public data: string = "";
    public columns: SDKDataGridColumn[] = [
        { Name: "acquiringAccountName", DisplayName: "Acquiring Account Name", FilterMultiSelect: true, FilterValues: [], isVisible: true },
        { Name: "acquiringAccountNumber", DisplayName: "Acquiring Account Number", FilterMultiSelect: true, FilterValues: [], isVisible: true },
        { Name: "transferringAccountName", DisplayName: "Transferring Account Name", FilterMultiSelect: true, FilterValues: [], isVisible: true },
        { Name: "transferringAccountNumber", DisplayName: "Transferring Account Number", FilterMultiSelect: true, FilterValues: [], isVisible: true },
        { Name: "quantity", DisplayName: "Quantity", isVisible: true, formatter: (value: any) => this.formatterService.formatNumber(value), FilterTypes: [Filters.LessThan, Filters.LessThanOrEqual, Filters.GreaterThan, Filters.GreaterThanOrEqual ] },
        { Name: "serialRange", DisplayName: "Serial Range", FilterMultiSelect: true, FilterValues: [], isVisible: true },
        { Name: "vintage", DisplayName: "Vintage", FilterMultiSelect: true, FilterValues: [], isVisible: true },
        { Name: "transactionCompletionDate", DisplayName: "Transaction Completion Date", isVisible: true, FilterTypes: [Filters.LessThan, Filters.LessThanOrEqual, Filters.GreaterThan, Filters.GreaterThanOrEqual], formatter: (value: any) => this.formatterService.formatDateUtc(value, "MM/dd/yyyy") },
        { Name: "transactionNumber", DisplayName: "Transaction Number", FilterMultiSelect: true, FilterValues: [] },
        { Name: "transactionType", DisplayName: "Transaction Type", FilterMultiSelect: true, FilterValues: [] },
        { Name: "unit", DisplayName: "Unit", FilterMultiSelect: true, FilterValues: [] },
        { Name: "registryProjectUrl", DisplayName: "Registry", showFilter:false, isVisible: true, dataTemplate: () => this.registryTemplate },
        { Name: "metaRegistryProjectId", DisplayName: "Meta Registry", showFilter: false, FilterValues: [], isVisible: true, dataTemplate: () => this.metaRegistryIdTemplate },
        { Name: "projectId", DisplayName: "Project Id", FilterMultiSelect: true, FilterValues: [] },
        { Name: "projectName", DisplayName: "Project Name", FilterMultiSelect: true, FilterValues: [], dataTemplate: () => this.longTextTemplate },
        { Name: "comment", DisplayName: "Comment", FilterTypes: [Filters.Contains, Filters.NotContains], dataTemplate: () => this.longTextTemplate },
        { Name: "beZeroRating", DisplayName: "BeZero Rating", FilterMultiSelect: true, FilterValues: [] },
        { Name: "sylveraRating", DisplayName: "Sylvera Rating", FilterMultiSelect: true, FilterValues: [] },
        { Name: "calyxGhgRating", DisplayName: "Calyx GHG Rating", FilterMultiSelect: true, FilterValues: [] }
    ];

    // Settings grid interface Implementation:
    public uniqueIdentifier = "inventory.abu.transactions" + this.uniqueIdentifierPostfix;
    public allSettings: SDKDataGridSettings[] = [];
    public multiSelectFilters: FilterInfo[] = [];
    public errorHanlder = (message, error) => {
        console.error(`${message} ${error?.message}`);
    }

    public gridOptions: SDKDataGridOptions = {
          settings: true,
		  columnSettings: true,
		  filtering: true,
		  sorting: true,
		  formulas: false,
		  charts: false,
		  export: true,
		  expandableRows: false,
		  selectableRows: false,
		  autoClosePanel: true
	  };
    public filterTypes: any = [Filters.Equals, Filters.NotEquals, Filters.Contains ];
    public fullData: string = "";
    public dbPage: number = 0;
    public dbTotal: number = 0;
    public error: string = "";

    private _savedEvent: any = null;

    private ids!: string[];

    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private formatterService: FormatterService,
        private gridHandlerService: GridHandlerService,
        private abuInventoryService: AbuInventoryService
    ) {
        super();
    }

    //*************************************************************************
    //  Component Life-Cycle Methods
    //*************************************************************************
    public async ngOnInit() {
        await super.ngOnInit();
        this.subscribeToRouteParams();
        await this.loadMultiSelectFilters();
        this.gridHandlerService.loadSettings(this);
    }

    public ngOnChanges(changes: SimpleChanges) {
        if (changes.accountNumber) {
            this.loadData(this._savedEvent);
        }
    }

    //*************************************************************************
    //  Public Methods
    //*************************************************************************
    public async loadData(event: any = null) {
        this.isLoading = true;

        try {
            this._savedEvent = event;
            this.MAXRECORDS = event.rows;
            let queryOptions: AbuInventorySearchOptions = this.buildParameters(event);
            const searchResult = await this.abuInventoryService.search(queryOptions);

            if (this.multiSelectFilters.length == 0) {
                await this.loadMultiSelectFilters();
            }
            this.setMultiSelectFilters();

            if (searchResult) {
                this.resetVariables();
                this.data = JSON.parse(JSON.stringify(searchResult.values));
                this.dbPage = searchResult.currentPage;
                this.dbTotal = searchResult.totalCount;
                this.error = "";
                this.footnote = `Inventory as of ${this.formatterService.formatDate(new Date(), "dd MMM yyyy hh:mm:ss (z)")}`;
            }
        } catch (error: any) {
            this.error = error.message;
        }

        this.isLoading = false;
    }

    public saveSettings(event: SDKDataGridSettings[]) {
        this.gridHandlerService.saveSettings(this, event);
    }

    public gotoProject(id: any) {
        if (id) {
            this.router.navigate(["/metaregistry/detail"], { state: { callback: '/inventory', callbackParams: { 'tab': 'Transactions', 'parentTab': 'ABU' },  callbackLabel: 'Back to ABU Inventory', projectId: id } });
        }
    }
    //*************************************************************************
    //  Private Methods
    //*************************************************************************
    private async subscribeToRouteParams() {
        this.route.queryParams.subscribe((params: any) => {
            if (params?.ids) {
                this.ids = params.ids.split(',');
            } else {
                this.ids = [];
            }
        });
    }

    private buildParameters(event: any): any {
        let parameters = {} as AbuInventorySearchOptions;
        if (this.accountNumber && this.accountNumber !== '') {
            parameters.accountNumber = this.accountNumber;
        }

        parameters.sortOptions = [];

        const rowCount = event.rows ?? 100;
        parameters.take = rowCount;

        const pageNumber = event.page ?? 1;
        parameters.skip = rowCount * (pageNumber - 1);

        this.buildParameterFilters(event, parameters);
        this.buildParametersSorts(event, parameters);

        return parameters;
    }

    private buildParameterFilters(event: any, parameters: AbuInventorySearchOptions) {
        if (!event?.filters) {
            return;
        }

        parameters.filters = [];

        if (this.ids?.length > 0) {
            parameters.filters.push({
                field: "id",
                operation: "Equals",
                values: this.ids
            });
        }
        else {
            event?.filters.forEach((eventFilter: any) => {
                const filter: Filter = {
                    field: eventFilter.Name,
                    operation: eventFilter.Operation,
                    values: eventFilter.Value instanceof (Array) ? eventFilter.Value : [eventFilter.Value]
                };
                parameters.filters?.push(filter);
            })
        }
    }

    private buildParametersSorts(event: any, parameters: AbuInventorySearchOptions) {
        if (!event?.sorts || event.sorts.length === 0) {
            event.sorts = [
                {
                    Name: "projectCount",
                    Sort: "desc"
                }
            ]
        }
        if (event?.sorts) {
            event.sorts.forEach((sort: any) => {
                parameters.sortOptions.push({
                    property: sort.Name,
                    isDescending: sort.Sort === "desc"
                })
            });
        }
    }

	private resetVariables() {
		this.data = "";
		this.fullData = "";
		this.dbPage = 0;
		this.dbTotal = 0;
		this.error = "";
    }

    private async loadMultiSelectFilters() {
        try {
            const multiSelectColumns: any[] = this.columns.filter(column => column.FilterMultiSelect);
            this.multiSelectFilters = await this.abuInventoryService.getFilters(multiSelectColumns.map(column => column.Name));
        } catch (error: any) {
            console.error("Error loading filters: " + error.message);
        }
    }

    private setMultiSelectFilters() {
        const multiSelectColumns: any[] = this.columns.filter(column => column.FilterMultiSelect);

        multiSelectColumns?.forEach((multiSelectColumn) => {
            const filter = this.multiSelectFilters.find(filterInfo => filterInfo.fieldName.toLowerCase() === multiSelectColumn.Name.toLowerCase());
            const filterValues = filter?.filterValues;
            multiSelectColumn.FilterValues.length = 0;
            filterValues?.forEach(value => multiSelectColumn.FilterValues.push(value));
        });
    }
}