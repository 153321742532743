import { Injectable } from "@angular/core";
import { HttpService } from "~/services/core/http.service";
import { FilterInfo } from "~/models/shared/filterInfo";
import { DeveloperSearchResult } from "./models/developer-search-result";
import { DeveloperProfileDto } from "./models/developer-summary";

@Injectable()
export class DevelopersScreeningService {
    constructor(
        private readonly httpService: HttpService,
    ) {
        // Intentionally blank
    }

    public search(options: DeveloperSearchOptions): Promise<DeveloperSearchResult> {
        options ??= {} as DeveloperSearchOptions;
        let requestUri = '/developers/search';
        return this.httpService.Post(requestUri, options);
    }

    public async get(id: number): Promise<DeveloperProfileDto> {
        const requestUri = `/developers/${id}`;
        return this.httpService.Get(requestUri);
    }

    public async getFilters(fieldNames: string[]): Promise<FilterInfo[]> {
        try {
            let queryString = "";
            fieldNames.forEach((fieldName) => {
                queryString = queryString === "" ? `fields=${fieldName}` : `${queryString}&fields=${fieldName}`;
            });
            const response = await this.httpService.Get(`/developers/filters?${queryString}`);
            return response;
        } catch (error: any) {
            throw error;
        }
    }
}

export interface DeveloperSearchOptions {
    keyword: string;
    country: string;
    hasProjectsInRegions: string[];
    hasProjectsInCountries: string[];
    hasProjectsWithTypes: string[];
    hasProjectsWithRating: string;
    totalCreditsGenerated: number;
    projectCount: number;
    beZeroRatedProjectCount: number;
    calyxGhgRatedProjectCount: number;
    sylveraRatedProjectCount: number;
    totalCreditsIssued: number;
    creditDeliveryRate: number;
    accreditations: string;
    programs: string;
    sortOptions: Array<{
      property: string;
      isDescending: boolean;
    }>;
    skip: number;
    take: number;
}
