import { Component, EventEmitter, Input, OnInit, Output, ViewChild, ViewContainerRef } from "@angular/core";

@Component({
  selector: 'ccms-icon-button-material',
  templateUrl: './icon-button-material.component.html',
  styleUrls: ['./icon-button-material.component.scss']
})
export class IconButtonMaterialComponent implements OnInit {
  @Input() class: string | undefined;
  @Input() disabled: boolean = false;
  @Input("aria-label") ariaLabel: string | undefined;
  @Input("icon") icon: string | undefined;
  @Input() id: string | null = null;
  @Input() hidden: boolean = false;
  @Output() click = new EventEmitter<MouseEvent>();
  @ViewChild('template', { static: true }) template;

  constructor(
    private viewContainerRef: ViewContainerRef
  ) { }

  ngOnInit() {
    this.viewContainerRef.createEmbeddedView(this.template);
    this.viewContainerRef.element.nativeElement.remove();
  }

  clicked(event: MouseEvent) {
    this.click.emit(event);
  }

  get finalClass(): string {
    if (this.class) {
      return `np-button ${this.class}`; 
    }

    return `np-button`;
  }
}