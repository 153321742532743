import { feature } from 'topojson-client';
import { Injectable } from "@angular/core";
import * as d3 from "d3";
import { tsv, json } from "d3";
import { PolicyTracker } from '~/models/shared/policy-tracker/policy-tracker';
import { CountryIdEnum } from '~/models/shared/country';
import { getChinaFeatures, getUsaStateFeatures, getCountryFeatures, filterPoliciesByRegion } from './country-feature';
import { FeatureType } from '~/models/shared/policy-tracker/feature-type.enum';

@Injectable()
export class CountriesGeoService {
    public async getCountries(policies: PolicyTracker[]) { 
      return await this.loadCountries(policies);
    }

    private async loadCountries(policies: PolicyTracker[]) {
      const [tsvData, countries, states, canada, china, mexico, japan, spain] = await Promise.all([
            tsv('assets/geojson/world/50m.tsv'),
            json('assets/geojson/world/50m.json'),
            json('assets/geojson/states-10m.json'),
            json('assets/geojson/canada.json'),
            json('assets/geojson/china-provinces-simplified.json'),
            json('assets/geojson/mexico-states.json'),
            json('assets/topojson/jp-prefectures.json'),
            json('assets/topojson/spain-comunidad.json')
      ]);
        
      const stateFeatures = getUsaStateFeatures(states, policies);
      const chinaFeatures = getChinaFeatures(china, policies);
      const countryFeatures = getCountryFeatures(tsvData, countries, policies);
      const canadaFeatures = this.getCanadaFeatures(canada, policies);
      const mexicoFeatures = this.getMexicoFeatures(mexico, policies);
      const japanFeatures = this.getJapanFeatures(japan, policies);
      const spainFeatures = this.getSpainFeatures(spain, policies);
      const processedData = this.processData(countryFeatures, stateFeatures, canadaFeatures, chinaFeatures, mexicoFeatures, japanFeatures, spainFeatures);
      return processedData;
    }

    private getCanadaFeatures(canada: any, policies: PolicyTracker[]) {
      let policyByRegion = {};

      canada.features.forEach(feature => {
        const region = feature.properties.name;
        const policy = filterPoliciesByRegion(policies, region);
      
        if (policy.length > 0) {
          policyByRegion[region] = policy;
        }
      });

      canada.features.forEach(d => {
        d.id = CountryIdEnum.Canada.toString();
        const policy = policyByRegion[d.properties.name];
        if (policy) {
          Object.assign(d.properties, 
          { 
            regionType: FeatureType.CanadianProvinces,
            policies: policy
          });
        }
      });

      return canada;
    }

    private getMexicoFeatures(mexico, policies: PolicyTracker[]) {
      let policyByRegion = {};

      mexico.features.forEach(feature => {
        const region = feature.properties.state_name;
        const policy = filterPoliciesByRegion(policies, region);
      
        if (policy.length > 0) {
          policyByRegion[region] = policy;
        }
      });

      mexico.features.forEach(d => {
        d.id = CountryIdEnum.Mexico.toString();
        const policy = policyByRegion[d.properties.state_name];
        if (policy) {
          Object.assign(d.properties, 
          { 
            regionType: FeatureType.MexicoStates,
            policies: policy,
            name: d.properties.state_name,
            id: CountryIdEnum.Mexico
          });
        } else {
          Object.assign(d.properties, {
            regionType: FeatureType.MexicoStates,
            name: d.properties.state_name,
            id: CountryIdEnum.Mexico
          });
        }
      });

      return mexico;
    }
    
    private getJapanFeatures(japanTopoJson, policies: PolicyTracker[]) {
      const japan = this.convertTopoJsonToGeoJson(japanTopoJson, 'JPN_adm1');
  
      let policyByRegion = {};

      japan.features.forEach(feature => {
        const region = feature.properties.NAME_1;
        const policy = filterPoliciesByRegion(policies, region);
      
        if (policy.length > 0) {
          policyByRegion[region] = policy;
        }
      });

      japan.features.forEach(d => {
        d.id = CountryIdEnum.Japan.toString();
        const policy = policyByRegion[d.properties.NAME_1];
        if (policy) {
          Object.assign(d.properties, 
          { 
            regionType: FeatureType.JapanPrefectures,
            policies: policy,
            name: d.properties.NAME_1,
            id: CountryIdEnum.Japan
          });
        } else {
          Object.assign(d.properties, {
            regionType: FeatureType.JapanPrefectures,
            name: d.properties.NAME_1,
            id: CountryIdEnum.Japan
          });
        }
      });

      return japan;
    }

    private getSpainFeatures(spainTopoJson, policies: PolicyTracker[]) {
      const spain = this.convertTopoJsonToGeoJson(spainTopoJson, 'ESP_adm1');

      let policyByRegion = {};
     
      spain.features.forEach(feature => {
        const region = feature.properties.NAME_1;
        let policy = filterPoliciesByRegion(policies, region);
        if (!policy || policy.length == 0) {
          policy = filterPoliciesByRegion(policies, "Spain");
        }

        if (policy.length > 0) {
          policyByRegion[region] = policy;
        }
      });

      spain.features.forEach(d => {
        d.id = CountryIdEnum.Spain.toString();
        const policy = policyByRegion[d.properties.NAME_1];
        if (policy) {
          Object.assign(d.properties, 
          { 
            regionType: FeatureType.SpainProvinces,
            policies: policy,
            name: d.properties.NAME_1,
            id: CountryIdEnum.Spain
          });
        } else {
          Object.assign(d.properties, {
            regionType: FeatureType.SpainProvinces,
            name: d.properties.NAME_1,
            id: CountryIdEnum.Spain
          });
        }
      });

      return spain;
    }

    public filterByCountry(countries: any, countryId: number) {
      const features = countries.features.filter(d => { 
          if (d.properties.id === countryId && d.properties.regionType === FeatureType.Country) {
            return true;
          } 
          return false;
      });
      
      countries.features = features;
      return countries;
    }

    private convertTopoJsonToGeoJson(topojsonData: any, objectName: string): any {
      // Convert TopoJSON to GeoJSON
      const geojsonData = feature(topojsonData, topojsonData.objects[objectName]);
  
      return geojsonData;
    }

    private processData(countryFeatures, stateFeatures, canadaFeatures, chinaFeatures, mexicoFeatures, japanFeatures, spainFeatures) {
      const combined = {
        "type": "FeatureCollection",
        "features": d3.merge([chinaFeatures.features, mexicoFeatures.features, canadaFeatures.features, japanFeatures.features, spainFeatures.features, stateFeatures.features, countryFeatures.features])
      };
      
      return combined;
    }
}