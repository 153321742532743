<div *ngIf="_isLoading" class="sdk-loading" [style]="style">
    <div *ngIf="showTimer" class="p-centered">
        <div class="p">
            <div class="progress" [style]="status"></div>
            <div class="progress-label" [style]="statusLabel">{{ progress }} seconds</div>
            <div class="progress-label" [style]="statusLabel"></div>
        </div>
    </div>
    <div *ngIf="isLoading && !showEllipsis" class="s-centered">
        <div class="s timer" [ngClass]="{ 'timer': showTimer }">
            <div class="spinner" [ngClass]="{ 'timer': showTimer }"></div>
        </div>
    </div>
    <div *ngIf="isLoading && showEllipsis" class="e-centered">
        <div class="e"></div>
    </div>
</div>
