export interface JurisdictionType {
  id: number;
  name: string;
}

export class JurisdictionTypeNational implements JurisdictionType {
  id: number;
  name: string;

  private constructor() {
    this.id = 1;
    this.name = "National";
  }

  public static getInstance(): JurisdictionType {
    return new JurisdictionTypeNational();
  }
}

export class JurisdictionTypeSubnational implements JurisdictionType {
  id: number;
  name: string;

  private constructor() {
    this.id = 2;
    this.name = "Subnational";
  }

  public static getInstance(): JurisdictionType {
    return new JurisdictionTypeSubnational();
  }
}

export class JurisdictionTypeRegional implements JurisdictionType {
  id: number;
  name: string;

  private constructor() {
    this.id = 3;
    this.name = "Regional";
  }

  public static getInstance(): JurisdictionType {
    return new JurisdictionTypeRegional();
  }
}
