<div *ngIf="project" class="content">
    <sdk-message [(message)]="message"></sdk-message>

    <div class="header">
        <div class="back" (click)="back()"><span class="arrow">◄</span><span class="highlight">{{ callbackLabel }}</span></div>
        <h3 [title]="project.name">{{ project.name }}</h3>
    </div>

    <div class="data">
        <tabs [tabs]="tabs" [activeTab]="activeTab" [share]="true" [urlParams]="urlParams" (messageEvent)="showMessage($event)"></tabs>
    </div>
</div>

<sdk-loading [isLoading]="isLoading"></sdk-loading>