<div class="sdk-datagrid-window">
    <div class="header">
        <span class="title">Chart</span>
        <span title="Close" class="sdk-icon close" (click)="close()">close</span>
    </div>
    <div class="content no-actions">
        <div class="no-box">
            <div class="section">
                <span>Chart Type</span>
                <select class="column" (change)="chartType = getValue($event)">
                    <option value="bar" [selected]="(chartType === 'bar') ? 'selected' : ''">Bar</option>
                    <option value="doughnut" [selected]="(chartType === 'doughnut') ? 'selected' : ''">Doughnut</option>
                    <option value="line" [selected]="(chartType === 'line') ? 'selected' : ''">Line</option>
                    <option value="pie" [selected]="(chartType === 'pie') ? 'selected' : ''">Pie</option>
                    <option value="polarArea" [selected]="(chartType === 'polarArea') ? 'selected' : ''">Polar Area</option>
                    <option value="radar" [selected]="(chartType === 'radar') ? 'selected' : ''">Radar</option>
                </select>
            </div>
            <div class="section">
                <span *ngIf="['bar', 'line', 'radar'].indexOf(chartType) > -1">Column for Grouping</span>
                <span *ngIf="['doughnut', 'pie', 'polarArea'].indexOf(chartType) > -1">Column for Sections</span>
                <select class="column" (change)="columnLabel = getValue($event)">
                    <option selected value=""></option>
                    <ng-container *ngFor="let column of columns">
                        <option [value]="column.Name" [selected]="(columnLabel === column.Name) ? 'selected' : ''">{{ column.FriendlyName === '' ? getColumnOriginalName(column) : column.FriendlyName }}</option>
                    </ng-container>
                </select>
            </div>
            <div *ngIf="['bar', 'line', 'radar'].indexOf(chartType) > -1" class="section">
                <span>Column for X-Axis (Bottom)</span>
                <span class="note">(typically a timespan)</span>
                <select class="column" (change)="columnDataX = getValue($event)">
                    <option selected value=""></option>
                    <ng-container *ngFor="let column of columns">
                        <option [value]="column.Name" [selected]="(columnDataX === column.Name) ? 'selected' : ''">{{ column.FriendlyName === '' ? getColumnOriginalName(column) : column.FriendlyName }}</option>
                    </ng-container>
                </select>
            </div>
            <div class="section">
                <div *ngIf="['bar', 'line', 'radar'].indexOf(chartType) > -1">
                    <span>Column for Y-Axis (Left Side)</span>
                    <span class="note">(typically a number)</span>
                </div>
                <div *ngIf="['doughnut', 'pie', 'polarArea'].indexOf(chartType) > -1">
                    <span>Column to Summarize</span>
                    <span class="note">(must be a number)</span>
                </div>
                <select class="column" (change)="columnDataY = getValue($event)">
                    <option selected value=""></option>
                    <ng-container *ngFor="let column of columns">
                        <option [value]="column.Name" [selected]="(columnDataY === column.Name) ? 'selected' : ''">{{ column.FriendlyName === '' ? getColumnOriginalName(column) : column.FriendlyName }}</option>
                    </ng-container>
                </select>
            </div>
            <div *ngIf="['bar'].indexOf(chartType) > -1" class="section">
                <div style="display: inline-block;">
                    <span class="data-label" (click)="dataOption = !dataOption">Stack Data?</span>
                    <input class="data-option" type="checkbox" [checked]="dataOption" (change)="dataOption = !dataOption">
                </div>
            </div>
            <div *ngIf="['line'].indexOf(chartType) > -1" class="section">
                <div style="display: inline-block;">
                    <span class="data-label" (click)="dataOption = !dataOption">Fill Data?</span>
                    <input class="data-option" type="checkbox" [checked]="dataOption" (change)="dataOption = !dataOption">
                </div>
            </div>
        </div>
    </div>
    <div class="footer">
        <div class="reset" (click)="reset()">Reset</div>
        <button class="button" type="button" (click)="apply()">Apply</button>
    </div>
</div>
