<div class="sdk-datagrid-window">
    <div class="header">
        <span class="title">Formulas</span>
        <span title="Close" class="sdk-icon close" (click)="close()">close</span>
    </div>
    <div class="actions">
        <button class="button button-left" type="button" (click)="addFormula()">Add formula</button>
    </div>
    <div cdkDropList class="content" (cdkDropListDropped)="drop($event)">
        <div *ngIf="formulaList.length === 0" class="no-records">
            <span>Add a formula.</span>
        </div>
        <div class="box" *ngFor="let formula of formulaList" cdkDragBoundary=".list" cdkDrag>
            <div *ngIf="formula.ID !== '_original_'" class="delete">
                <span class="sdk-icon" (click)="deleteFormula(formula)">delete</span>
            </div>
            <div>
                <div class="column" [ngClass]="{ 'no-delete': formula.ID === '_original_' }">
                    <select class="select" (change)="addColumn($event, formula)">
                        <option selected disabled value="">Select column...</option>
                        <ng-container *ngFor="let column of columnList">
                            <option [value]="column.Name" [selected]="(formula.Name === column.Name) ? 'selected' : ''">{{ column.FriendlyName === '' ? getColumnOriginalName(column) : column.FriendlyName }}</option>
                        </ng-container>
                    </select>
                </div>
                <div class="operation-precision" [ngClass]="{ 'no-delete': formula.ID === '_original_' }">
                    <div class="operation">
                        <select class="select" (change)="addOperation($event, formula)">
                            <option selected disabled value="">Select operator...</option>
                            <option value="Avg" [selected]="(formula.Operation === 'Avg') ? 'selected' : ''">Average</option>
                            <option value="Rng" [selected]="(formula.Operation === 'Rng') ? 'selected' : ''">Range (High/Low)</option>
                            <option value="Sum" [selected]="(formula.Operation === 'Sum') ? 'selected' : ''">Sum</option>
                        </select>
                    </div>
                    <div class="precision">
                        <span>#.</span>
                        <input [value]="formula.Format" (keypress)="($event.charCode >= 48 && $event.charCode < 58) || $event.charCode === 8" (blur)="addFormat($event, formula)" />
                    </div>
                </div>
            </div>
            <div class="move" cdkDragHandle>
                <span class="sdk-icon">drag_handle</span>
            </div>
        </div>
    </div>
    <div class="footer">
        <div class="reset" (click)="reset()">Reset</div>
        <button class="button" type="button" (click)="apply()">Apply</button>
    </div>
</div>
