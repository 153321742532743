import { Component, EventEmitter, Input, Output } from "@angular/core";

import { ForecastSummaryService } from "~/services/shared/forecast-summary.service";
import { ProjectService } from "~/services/shared/projects/project.service";

import { ICvxProjectStatus } from "~/models/shared/cvxproject";
import { ForecastTypeEnum } from "~/pages/projects/project-detail/forecast/model/forecast-type.enum";

@Component({
	selector: 'project-charts-filters',
	templateUrl: './project-charts-filters.component.html',
	styleUrls: ['./project-charts-filters.component.scss']
})
export class ProjectChartsFiltersComponent {
	@Input() values: any;
	@Input() showRiskFilters!: boolean;
    @Output() applyEvent: EventEmitter<any> = new EventEmitter();

	public isLoading: boolean = true;
	public statuses: ICvxProjectStatus[] = [];
	public scopes: string[] = [];
	public regions: string[] = [];
	public forecastTypes: string[] = [ForecastTypeEnum.Current, ForecastTypeEnum.Funded, 'Both'];
	public scopeBreakout: boolean = false;
	public regionBreakout: boolean = false;

	public selectedStatus: any = [];
	public selectedScope: any = "";
	public selectedRegion: any = "";
	public selectedFunding: any = "";

	private readonly _allStatus: ICvxProjectStatus = {
		id: -999,
		name: "All - Except Do Not Pursue",
		category: "",
		description: "Includes all status except Do Not Pursue",
		order: -1
	};
	private _globalLocation: any = {
		name: "Global",
		id: -1,
		alpha2Code: "",
		alpha3Code: "",
		regionCode: -1,
		regionName: "Global",
		latLongCoords: null,
		countryRisk: null,
		region: null,
		subRegion: null
	};

	

	constructor(
		private projectService: ProjectService,
		private forecastSummaryService: ForecastSummaryService,
	) { }

	public async ngOnInit() {
		console.log(this.showRiskFilters);
        await this.initFilters();
    }

	public setStatus(event: any) {
		this.selectedStatus = event;
	}

	public setScope(event: any) {
		this.selectedScope = event;
	}

	public setRegion(event: any) {
		this.selectedRegion = event;
	}

	public setFunding(event: any) {
		this.selectedFunding = event;
	}

	public setScopeBreakout(event: any) {
		this.scopeBreakout = event.target.checked;
	}

	public setRegionBreakout(event: any) {
		this.regionBreakout = event.target.checked;
	}

	public reset() {
		this.selectedStatus = [];
		this.selectedScope = "";
		this.selectedRegion = "";
		this.selectedFunding = "";
		this.scopeBreakout = false;
		this.regionBreakout = false;
	}

	public apply() {
		let values = {
			status: this.selectedStatus,
			scope: this.selectedScope,
			region: this.selectedRegion,
			fundingType: this.selectedFunding,
			breakout: {
				scope: this.scopeBreakout,
				region: this.regionBreakout
			}
		}

		this.applyEvent.emit(values);
	}

    private async initFilters() {
		this.isLoading = true;

		let statuses = await this.projectService.getStatuses();
		this.statuses = [...statuses].sort((a, b) => a.name.localeCompare(b.name));

		let scopes: any = await this.forecastSummaryService.listScopes();
		this.scopes = [... new Set<string>(scopes.map((scope: any) => scope.cvxProjectScopeName))];

		let regions: any = await this.forecastSummaryService.listCountries();
		regions.unshift(this._globalLocation);
		this.regions = [... new Set<string>(regions.map((country: any) => country?.region?.name ?? country.name))];

		this.statuses.unshift(this._allStatus);

		this.selectedStatus = this.values.status;
		this.selectedScope = this.values.scope;
		this.selectedRegion = this.values.region;
		this.selectedFunding = this.values?.fundingType;
		this.scopeBreakout = this.values?.breakout?.scope || false;
		this.regionBreakout = this.values?.breakout?.region || false;

		this.isLoading = false;
    }
}