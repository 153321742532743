<div id="forecastSummaries" class="summary">
    <div class="first-row">
      <div><span class="detail-label">
          Total Forecast:</span>{{totalCredits}}</div>
      <div *ngIf="showRisk"><span class="detail-label" matTooltip="Probability of Occurrence" matTooltipPosition="above">
          Total Forecast(PoO):</span>{{totalCreditsRisk}}</div>
      <div><span class="detail-label">
          Total Funding:</span>{{totalFunding}}</div>
   
      <div><span class="detail-label">
          Average Price:</span>{{averagePrice}}</div>
      <div><span class="detail-label">
          Volumetric Exposure:</span>{{volumetricExposure}}</div>
      <div><span class="detail-label">
          Total Exposure:</span>{{nonDiscretionarySpend}}</div>
    </div>
    <div class="credits-market">
      <div *ngIf="creditsMarketInternal"><span class="detail-label">
        Credits Market:</span>{{creditsMarketInternal?.name}}</div>
    </div>
</div>
<div class="spacer"></div>
<div class="class row flex-wrap">
  <div class="col-12">
    <mat-tab-group #chartGroup (selectedTabChange)="onChartTabChanged()"
      mat-align-tabs="center" class="bb-0" animationDuration="500ms" fitInkBarToContent>
      <mat-tab label="unrisked">
        <ng-template mat-tab-label>
          <span class="material-icons-outlined" matTooltip="unrisked">
            trending_flat
          </span>
        </ng-template>
        <div class="spacer"></div>
        <canvas id="unriskChart" #unriskChart style="background-color: white;"></canvas>
      </mat-tab>
      <mat-tab label="risked" *ngIf="hasRiskData$ | async">
        <ng-template mat-tab-label>
          <span class="material-icons-outlined" matTooltip="risked">
            trending_down
          </span>
        </ng-template>
        <div class="spacer"></div>
        <canvas id="riskChart" #riskChart style="background-color: white;"></canvas>
      </mat-tab>
    </mat-tab-group>
  </div>
</div>