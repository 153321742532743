<div class="vcm">
	<div class="header">Voluntary Carbon Market</div>
	<div class="data">
		<div class="detail-label">Projects:</div>
		{{ credits?.projectCount | number }}
	</div>
	<div class="data">
		<div class="detail-label">Issued:</div>
		{{ credits?.creditsIssued | number }}
	</div>
	<div class="data">
		<div class="detail-label">Retired:</div>
		{{ credits?.creditsRetired | number }}
	</div>
	<div class="data">
		<div class="detail-label">Remaining:</div>
		{{ credits?.creditsRemaining | number }}
	</div>
</div>