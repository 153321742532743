import { Component, TemplateRef, ViewChild, ViewContainerRef } from "@angular/core";
import Chart, { BarElement, ChartDataset } from "chart.js/auto";
import chroma, { Color } from "chroma-js";
import zoomPlugin from 'chartjs-plugin-zoom';
import { Variables } from "~/classes/core/user/variables";
import { ForecastSummaryService } from '~/services/shared/forecast-summary.service';
import { IForecastRiskSummaryModel } from "~/models/shared/common";
import { ProjectChartsFiltersComponent } from "~/pages/projects/components/project-charts/components/filters/project-charts-filters.component";
import { ForecastTypeEnum } from "~/pages/projects/project-detail/forecast/model/forecast-type.enum";
import { RetiredChartUtility } from "~/pages/metaregistry/retirements/retired-charts-util";

@Component({
	selector: 'project-risk-charts',
	templateUrl: './risk-charts.component.html',
	styleUrls: ['./risk-charts.component.scss']
})


export class ProjectRiskChartsComponent {
	@ViewChild('template', { static: true }) template: TemplateRef<unknown> | undefined;

	public isLoading: boolean = true;
	public closeOptionsWindow: boolean = false;
	private _currentFundingType: string | undefined = "";
	private _currentStatus: number[] | undefined = [];
	private _currentScope: string | undefined = "";
	private _currentRegion: string | undefined = "";
	private _colorChangeFactor: number = 0.75;
	public options = [
		{
			title: "Filters",
			icon: "filter_alt",
			type: <any>ProjectChartsFiltersComponent,
			inputs: {
				values: {
					status: [],
					scope: "",
					region: "",
					fundingType: "",
					breakout: {
						scope: null,
						region: null
					}
				},
				showRiskFilters: true
			},
			outputs: {
				'applyEvent': async (event: any) => {
					this.closeOptionsWindow = true;

					this.setFilters(event);

					Variables.set("Project.Charts.Risk", event);

					await this.loadCharts();

					this.closeOptionsWindow = false;
				},
			}
		}
	];
	public legendItems: any[] = [];
	private barChart: any = null;
	private readonly _colors = new Map<string, string>([
		['Agriculture', '#0066B2'],
		['CarbonCaptureAndStorage', 'yellow'],
		['ChemicalProcesses', 'red'],
		['ForestryAndLandUse', 'green'],
		['HouseholdAndCommunity', 'orange'],
		['IndustrialManufacturing', 'purple'],
		['RenewableEnergy', '#347B98'],
		['Transportation', '#092834'],
		['WasteManagement', '#F5FFCC'],
		['Unavailable', '#002C3D'],
		['Africa', 'yellow'],
		['Americas', '#0066B2'],
		['Asia', 'green'],
		['Europe', 'red'],
		['Oceania', 'purple'],
		['Advanced Refrigerants', 'red'],
		['Afforestation/Reforestation', 'green']
	]);
	private _util: RetiredChartUtility = new RetiredChartUtility();
	
	
	
	

	constructor(
		private forecastSummaryService: ForecastSummaryService,
		private viewContainerRef: ViewContainerRef
	) {
		Chart.register(zoomPlugin);
	}

	public ngOnInit(): void {
		this.viewContainerRef.createEmbeddedView(this.template!);
		this.viewContainerRef.element.nativeElement.remove();
	}

	public async ngAfterViewInit() {
		let options = Variables.get("Project.Charts.Risk");

		if (!options) {
			options = {
				status: [],
				scope: "",
				region: "",
				fundingType: "",
				breakout: {
					scope: null,
					region: null
				}
			}
		}

		this.setFilters(options);

		this.barChart = await this.initializeCharts();

		await this.loadCharts();
		console.log(this.barChart.data.datasets);
	}

	public legendClick(event: any) {
		const clickedLabel = event.target as HTMLLabelElement;
		let datasetLabel: string = '';

		if (clickedLabel) {
			datasetLabel = clickedLabel.innerText;				
		}
		console.log(datasetLabel);
		event.target.classList.toggle('strikethrough');
		
		const dataset = this.barChart.data.datasets.find((element: any) => element.label === datasetLabel);
		if (dataset) { 
			dataset.hidden = !dataset.hidden;
		}
		this.barChart.update();
	}

	private destroyCharts() {
		if (this.barChart) this.barChart.destroy();
	}

	private setFilters(source: any) {
		if (source.status && source.status.length > 0) {
			this._currentStatus = [];

			source.status.forEach((status: any) => {
				this._currentStatus?.push(parseInt(status.id));
			});
		} else {
			this._currentStatus = undefined;
		}
		this._currentScope = source.scope;
		this._currentRegion = source.region;
		this._currentFundingType = source?.fundingType[0] || ForecastTypeEnum.Funded;

		this.options.filter((option: any) => option.inputs?.hasOwnProperty('values')).forEach((option: any) => {
			option.inputs.values.status = source.status;
			option.inputs.values.scope = source.scope;
			option.inputs.values.region = source.region;
			option.inputs.values.fundingType = source.fundingType;
			option.inputs.values.breakout.scope = source?.breakout?.scope ?? false;
			option.inputs.values.breakout.region = source?.breakout.region ?? false;

		});
		
	}


	private async initializeCharts(): Promise<Chart | null> {
		this.destroyCharts();
		let riskElement = document.getElementById("riskProjectScope");

		
		let canvas = riskElement as HTMLCanvasElement;
		if (canvas == null) {
			return null;
		}
		let chart = new Chart(canvas, {
			type: 'bar',
			data: {
				datasets: [],
				labels: []
			},
			options: {
				scales: {
					y:
					{
						ticks: {
							callback: function (v) {
								return v.toLocaleString('en-US');
							}
						},
						stacked: true,
						title: {
							display: true,
							text: "Funding",
							font: {
								weight: 'bold',
								family: "GothamNarrowBold, Arial, sans- serif",
								size: 14
							},
							color: '#212529'
						},
						
					},
					x:
					{
						stacked: true
					}
					
				},
				interaction: {
					mode: 'index'
				},
				plugins: {
					title: {
						display: true,
						font: {
							weight: 'bold',
							family: "GothamNarrowBold, Arial, sans- serif",
							size: 14
						},
						color: '#212529'
					},
					tooltip: {
						intersect: false,
						callbacks: {
							label: (context): string => {
								if (context.formattedValue === '0') {
									return "";
								}
								const label = context.dataset.label;
								return label + ": " + context.formattedValue;
							},
						}
					},
					legend: {
						display: false,
						position: 'bottom'
					},
					zoom: {
						pan: {
							enabled: true,
							mode: 'x',
							onPan: () => {
							},
						},
						zoom: {
							wheel: {
								enabled: true,
							},
							pinch: {
								enabled: false
							},
							mode: 'x'
						}

					},
					datalabels: {

						font: (context) => {
							const chart = context.chart;
							const meta = chart.getDatasetMeta(context.datasetIndex);
							const model: BarElement = meta.data[context.dataIndex] as BarElement;

							// passing true you can get the final value of element property
							// at the end of animation
							const { width } = model.getProps(['width'], true);
							const size = Math.round(this._util.fontSize * (width / this._util.defaultBarWidth));


							return {
								size: size,
								family: "Arial, sans- serif"
							}
						}
					}
				
				}
			}
		});
		chart.options.maintainAspectRatio = false;
		chart.update();

		return chart;
	}

	private async loadCharts() {
		this.isLoading = true;
		
		this.barChart.data.datasets = [];
		this.barChart.data.labels = [];
		
		const toggleOptions = this.options[0].inputs.values;
		const datasets = await this.loadData(this._currentScope, this._currentRegion, this._currentStatus, this._currentFundingType, toggleOptions);
		
		console.log(toggleOptions);

		this.barChart.data.labels = datasets.labels;
		this.barChart.data.datasets = datasets.data;
		this.barChart.update();
		this.barChart.zoom(0.25);
		this.barChart.zoomScale("x", { min: 2022, max: 2032 }, 'resize');
		this.isLoading = false;
		
		const items = this.barChart.options.plugins.legend.labels.generateLabels(this.barChart);
		this.legendItems = [];
		items.forEach(legendItem  => {
			if (this.barChart.data.datasets[legendItem.datasetIndex].data.some((element: any) => element.y > 0)) {
				this.legendItems.push(legendItem);
			}
		});
		this.legendItems.sort((a, b) => a.text.localeCompare(b.text));  
	}

	private async loadData(scope: string | undefined, region: string | undefined, status: any[] | undefined, fundingType: string | undefined, toggleOptions: any):
		Promise<{ data: ChartDataset[], labels: number[] }> {
		let data: any[] = [];
		let years: number[] = [];
		let results: IForecastRiskSummaryModel[] = [];
		await this.forecastSummaryService.getRiskForecast(scope, region,  status).then((result: IForecastRiskSummaryModel[]) => {
			years = Array.from(new Set<number>(result.map(element => element.year)));
			results = result;
		});

		if (toggleOptions.breakout.scope && toggleOptions.breakout.region) {
			data = this.handleScopeAndRegion(results, fundingType ?? ForecastTypeEnum.Funded, years);
		}
		else if (toggleOptions.breakout.region) {
			data = this.handleRegion(results, fundingType ?? ForecastTypeEnum.Funded, years);
		}
		else if (toggleOptions.breakout.scope) {
			data = this.handleScope(results, fundingType ?? ForecastTypeEnum.Funded, years);
		}
		else {
			const funding = this.getFundingDataSets(fundingType, results, years);
			funding.data.forEach(d => data.push(d));
		}
		data.forEach(d => {
			d.datalabels = {
				display: function (context) {
					// Calculate the height of the bar segment  
					const chart = context.chart;
					const { datasetIndex, dataIndex } = context;
					const meta = chart.getDatasetMeta(datasetIndex);
					const bar = meta.data[dataIndex];

					// Determine the height of the bar segment  
					const barHeight = Math.abs(bar.y - bar.base);

					// Define a minimum height threshold to show labels  
					const minHeightToShowLabel = 20; // for example, 20 pixels  

					// Return true to display the label if the bar segment is tall enough  
					return barHeight > minHeightToShowLabel;
				},
				formatter: function (value, context) {
					// Return the value that you would like to display as the label  
					return value.y > 0 ? value.y.toLocaleString() : null;
				},
				labels: {
					totals: {
						color: 'black',
						formatter: function (value, context) {
							let year = value.x;

							let total = results.flatMap(element => element)
								.filter(element => element.year == year)
								.reduce((sum, current) => sum + current.credits, 0);


							return context.datasetIndex == years.length - 1 ? total.toLocaleString() : null;
						},
						align: 'end',
						anchor: 'end',
						font: {
							weight: "bold",
							family: "GothamNarrowBold, Arial, sans- serif"
						}
					},
					value: {
						color: 'white',
						align: 'center',
						anchor: 'center'
					}
				}
			};
		});

		return { data, labels: years };
	}

	private handleScopeAndRegion(results: IForecastRiskSummaryModel[], fundingType: string, years: number[]): any[] {
		let data: any[] = [];
		const regionGroup = this.groupByProperty(results, 'regionName');
		regionGroup.forEach(region => {
			const regionData = region.items;
			const regionScopes = this.groupByProperty(regionData, 'projectScope');
			regionScopes.forEach(scope => {
				const regionalScopeFunding = this.getFundingDataSets(fundingType, scope.items, years);
				let riskColor = chroma(this._colors.get(region.key) ?? '#0066B2');
				let unrisk = riskColor.darken(this._colorChangeFactor);
				this.addDataToResult(data, regionalScopeFunding, `${region.key}-${scope.key}`, riskColor, unrisk);
			});
		});
		return data;
	}

	private handleRegion(results: IForecastRiskSummaryModel[], fundingType: string, years: number[]): any[] {
		let data: any[] = [];
		const regionGroup = this.groupByProperty(results, 'regionName');
		regionGroup.forEach(region => {
			const regionalFunding = this.getFundingDataSets(fundingType, region.items, years);
			let riskColor = chroma(this._colors.get(region.key) ?? '#0066B2');
			let unrisk = riskColor.darken(this._colorChangeFactor);
			this.addDataToResult(data, regionalFunding, `${region.key}`, riskColor, unrisk);
		});
		return data;
	}

	private handleScope(results: IForecastRiskSummaryModel[], fundingType: string, years: number[]): any[] {
		let data: any[] = [];
		const scopeGroup = this.groupByProperty(results, 'projectScope');
		scopeGroup.forEach(scope => {
			const scopeFunding = this.getFundingDataSets(fundingType, scope.items, years);
			let riskColor = chroma(this._colors.get(scope.key) ?? '#0066B2');
			let unrisk = riskColor.darken(this._colorChangeFactor);
			this.addDataToResult(data, scopeFunding, `${scope.key}`, riskColor, unrisk);
		});
		return data;
	}

	private addDataToResult(data: any[], fundingData: any, labelPrefix: string, riskColor: Color, unriskColor: Color) {
		fundingData.data.forEach(d => {
			
			d.label = `${labelPrefix}-${d.label}`;
			d.backgroundColor = d.label.indexOf('unrisk') > 0 ? unriskColor.toString() : riskColor.toString(); //NOSONAR in case of chroma object it returns the color in string format
			data.push(d);
		});
	}  


	private getFundingDataSets(fundingType: string | undefined, forecasts: any[], years: number[]): { data: ChartDataset[] } {
		let data: any[] = [];
		
		
		let currentUnriskColor = chroma.hex("0066B2");
		let currentRiskColor = chroma.hex('009DD9');
		let fundedUnriskColor = chroma.hex('FAAB18');
		let fundedRiskColor = chroma.hex('E5601F');


		if (fundingType == "Both") {
			const currentForecast = this.getRiskAndUnRiskData(ForecastTypeEnum.Current, years, forecasts);
			const fundedForecast = this.getRiskAndUnRiskData(ForecastTypeEnum.Funded, years, forecasts);

			let currentRiskDataSet: ChartDataset = {
				label: `${ForecastTypeEnum.Current}-risk`,
				order: 0,
				data: currentForecast.riskData,
				backgroundColor: currentRiskColor.toString(), //NOSONAR in case of chroma object it returns the color in string format
				yAxisID: 'y'

			};
			console.debug(currentRiskColor.toString());  //NOSONAR in case of chroma object it returns the color in string format
			let currentUnRiskDataSet: ChartDataset = {
				label: `${ForecastTypeEnum.Current}-unrisk`,
				order: 1,
				data: currentForecast.unriskData,
				backgroundColor: chroma(currentUnriskColor).toString(),  //NOSONAR in case of chroma object it returns the color in string format
				yAxisID: 'y'
			};

			let fundedRiskDataSet: ChartDataset = {
				label: `${ForecastTypeEnum.Funded}-risk`,
				order: 2,
				data: fundedForecast.riskData,
				backgroundColor: fundedRiskColor.toString(),  //NOSONAR in case of chroma object it returns the color in string format
			};
			let fundedUnRiskDataSet: ChartDataset = {
				label: `${ForecastTypeEnum.Funded}-unrisk`,
				order: 3,
				data: fundedForecast.unriskData,
				backgroundColor: fundedUnriskColor.toString(),  //NOSONAR in case of chroma object it returns the color in string format
			};
			data.push(currentRiskDataSet);
			data.push(currentUnRiskDataSet);
			data.push(fundedRiskDataSet);
			data.push(fundedUnRiskDataSet);
		}
		else {

			const forecast = this.getRiskAndUnRiskData(fundingType ?? ForecastTypeEnum.Funded, years, forecasts);

			let riskDataSet: ChartDataset = {
				label: `${fundingType}-risk`,
				order: 0,
				data: forecast.riskData,
				backgroundColor: currentRiskColor.toString(),  //NOSONAR in case of chroma object it returns the color in string format

			};
			let unRiskDataSet: ChartDataset = {
				label: `${fundingType}-unrisk`,
				order: 1,
				data: forecast.unriskData,
				backgroundColor: chroma(currentUnriskColor).toString(),  //NOSONAR in case of chroma object it returns the color in string format
			};
			data.push(riskDataSet);
			data.push(unRiskDataSet)
		}


		return { data };

	}


	private getRiskAndUnRiskData(forecastType: string, years: number[], dataByGroup: IForecastRiskSummaryModel[]): { riskData: any[], unriskData: any[] } {
		let riskData: any[] = [];
		let unriskData: any[] = [];

		years.forEach(year => {
			const annualUnriskSum = dataByGroup
				.filter((element: IForecastRiskSummaryModel) => element.forecastType == forecastType)
				.filter(element => element.year == year)
				.reduce((acc, item: IForecastRiskSummaryModel) => acc + item.credits, 0);

			const annualRiskSum = dataByGroup
				.filter((element: IForecastRiskSummaryModel) => element.forecastType == forecastType)
				.filter(element => element.year == year)
				.reduce((acc: number, item: IForecastRiskSummaryModel) => {
					let confidenceEstimate = item.confidenceEstimate ?? -1;
					let riskPercentage = confidenceEstimate > 0 ? confidenceEstimate : 1;
					acc += item.credits - ((item.credits * riskPercentage) / 100);
					return acc;
				}, 0);

			riskData.push({ x: year, y: annualRiskSum });
			unriskData.push({ x: year, y: annualUnriskSum - annualRiskSum });
		});

		return { riskData, unriskData };
	}

	private groupByProperty<T>(array: T[], propertyName: keyof T): any[] {
		return array.reduce((result: any[], item: T) => {
			const key = String(item[propertyName]);
			const group = result.find((g) => g.key === key);

			if (group) {
				group.items.push(item);
			} else {
				result.push({ key, items: [item] });
			}

			return result;
		}, []);
	}

}




