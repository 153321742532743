<div class="content"> 
    <div class="grid">
        <sdk-datagrid
            fontFamily="'GothamNarrowBook', sans-serif, 'Helvetica Neue', Arial"
            [uniqueIdentifier]="uniqueIdentifier"
            [datasets]="datasets"
            [name]="activeView"
            [data]="data"
            [columns]="columns"
            [isLoading]="isLoading"
            [filterTypes]="filterTypes"
            [options]="gridOptions"
            [settings]="allSettings"
            (loadDataEvent)="loadData($event)"
            (settingsSavedEvent)="saveSettings($event)">
        </sdk-datagrid>
    </div>

    <div class="info">
        <developer-summary [developer]="developer"></developer-summary>
    </div>

    <ng-template #ratingsTemplate let-rowItem="rowItem">
        <td style="white-space: nowrap;">
            <p *ngIf="rowItem.beZeroRating">
                <b>BeZero:</b> {{rowItem.beZeroRating}}
            </p>
            <p *ngIf="rowItem.calyxGhgRating">
                <b>Calyx:</b> {{rowItem.calyxGhgRating}}
            </p>
            <p *ngIf="rowItem.sylveraRating">
                <b>Sylvera:</b> {{rowItem.sylveraRating}}
            </p>
        </td>
    </ng-template>

    <ng-template #metaRegistryTemplate let-rowItem="rowItem">
        <div *ngIf="rowItem && rowItem.id && rowItem.id !== ''">
            <div class="link" (click)="gotoProject(rowItem.id)">{{ rowItem.name }}</div>
        </div>
    </ng-template>
</div>