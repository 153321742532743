import { Injectable } from "@angular/core";

import { HttpService } from '../core/http.service';
import { CountryService } from '~/services/shared/country.service';

import { IForecastRiskSummaryModel, IForecastSummaryModel } from "~/models/shared/common";
import { ICvxProjectType } from "~/models/shared/cvxproject";

@Injectable()
export class ForecastSummaryService {
    constructor(
        private httpService: HttpService,
        private countryService: CountryService
    ) { }

    public async listCountries() {
        return await this.countryService.getCountries();
    }

    public async listScopes(): Promise<ICvxProjectType[]> {
        try {
            return await this.httpService.Get("/forecasts/project-types").then((data: any) => {
                return data;
            });
        } catch (error: any) {
            throw error;
        }
    }

    public async getCreditForecastByRegion(region?: string, statuses?: number[]): Promise<IForecastSummaryModel[]> {
        try {
            return await this.httpService.Post(`/forecasts/project-region/credits/${region ?? ''}`, statuses ?? []).then((data: any) => {
                return data;
            });
        } catch (error: any) {
            throw error;
        }
    }

    public async getCreditForecastByScope(scope?: string, statuses?: number[]): Promise<IForecastSummaryModel[]> {
        try {
            return await this.httpService.Post(`/forecasts/project-scope/credits/${scope ?? ''}`, statuses ?? []).then((data: any) => {
                return data;
            });
        } catch (error: any) {
            throw error;
        }
    }

    public async getFundingForecastByRegion(region?: string, statuses?: number[]): Promise<IForecastSummaryModel[]> {
        try {
            return await this.httpService.Post(`/forecasts/project-region/capex/${region ?? ''}`, statuses ?? []).then((data: any) => {
                return data;
            });
        } catch (error: any) {
            throw error;
        }
    }

    public async getFundingForecastByScope(scope?: string, statuses?: number[]): Promise<IForecastSummaryModel[]> {
        try {
            return await this.httpService.Post(`/forecasts/project-scope/capex/${scope ?? ''}`, statuses ?? []).then((data: any) => {
                return data;
            });
        } catch (error: any) {
            throw error;
        }
    }

    public async getRiskForecast(scope?: string, region?: string, statuses?: number[]): Promise<IForecastRiskSummaryModel[]> {
        try {
            // Initialize URLSearchParams
            let params = new URLSearchParams();

            // Add scope and region to params if they are not null
            if (scope) params.append('scope', scope);
            if (region) params.append('region', region);

            // Create the query string
            let queryString = params.toString() ? '?' + params.toString() : '';

            return await this.httpService.Post(`/forecasts/project/credits/risk${queryString}`, statuses ?? []).then((data: any) => {
                return data;
            });
        } catch (error: any) {
            throw error;
        }
    }

    
}
