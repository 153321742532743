import { createStore } from '@ngneat/elf';
import { withEntities, selectAllEntities, updateEntities, addEntities } from '@ngneat/elf-entities';
import { firstValueFrom, map, Observable } from 'rxjs';
import { GridPager } from './model/grid-pager';
import { GridName } from './grid-name.enum';
import { GridFilter } from './model/grid-filter.model';

const initialGridPagers : GridPager[] = [
  {id : GridName.MetaRegistry, pageIndex: 1, pageSize: 10, filters: [] },
  {id : GridName.MetaRegistryIssuances, pageIndex: 1, pageSize: 10, filters: [] },
  {id : GridName.Inventory, pageIndex: 1, pageSize: 10, filters: [] },
  {id : GridName.Projects, pageIndex: 1, pageSize: 10, filters: [] },
  {id : GridName.DeveloperScreening, pageIndex: 1, pageSize: 10, filters: [] },
]

const store = createStore(
  { name: 'grid-pagers' },
  withEntities<GridPager>({initialValue: initialGridPagers })
);

const gridPagers$ = store.pipe(selectAllEntities());

export function getGridPager(id: GridPager['id']): Observable<GridPager | undefined> {
  return gridPagers$.pipe(
    map(grids => {
      return grids.find(grid => grid.id == id)
    })
  );
}

export async function setGridPager(id: GridPager['id'], pageIndex: number, pageSize: number, filters: GridFilter[]): Promise<GridPager | undefined> {
  const gridSettings: GridPager = { id: id, pageIndex: pageIndex, pageSize: pageSize, filters: filters };
  const found = await firstValueFrom(getGridPager(gridSettings.id));
  if (typeof found !== 'undefined') {
    found.pageIndex = gridSettings.pageIndex;
    found.filters = gridSettings.filters;
    found.pageSize = gridSettings.pageSize;
    store.update(updateEntities(found.id, found));
  } else {
    store.update(addEntities(gridSettings));
  }

  return firstValueFrom(getGridPager(gridSettings.id));
}

function deepEqual(obj1: any, obj2: any): boolean {
  if (obj1 === obj2) {
      return true;
  }
  if (typeof obj1 !== 'object' || obj1 === null || typeof obj2 !== 'object' || obj2 === null) {
      return false;
  }
  const keys1 = Object.keys(obj1);
  const keys2 = Object.keys(obj2);
  if (keys1.length !== keys2.length) {
      return false;
  }
  for (let key of keys1) {
      if (!keys2.includes(key) || !deepEqual(obj1[key], obj2[key])) {
          return false;
      }
  }
  return true;
}

export function arraysAreEqual(arr1: any[], arr2: any[]): boolean {
   if (arr1?.length !== arr2?.length) {
      return false;
  }

  if (!arr1 && !arr2) {
    return true;
  }

  if (!arr1 || !arr2) {
    return false;
  }

  // Sort arrays first before comparison
  const sortedArr1 = [...arr1].sort((a, b) => a.ID - b.ID);
  const sortedArr2 = [...arr2].sort((a, b) => a.ID - b.ID);

  for (let i = 0; i < sortedArr1.length; i++) {
      if (!deepEqual(sortedArr1[i], sortedArr2[i])) {
          return false;
      }
  }

  return true;
}
