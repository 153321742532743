import { NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';

import { SDKTextboxComponent } from './sdk-textbox.component';

import { CalendarComponent } from './components/calendar/calendar.component';

@NgModule({
  declarations: [
    SDKTextboxComponent,
    CalendarComponent
  ],
  imports: [
    CommonModule,
  ],
  exports: [
    SDKTextboxComponent
  ],
  providers: [
    DatePipe
  ],
  schemas: [
    NO_ERRORS_SCHEMA
  ]
})
export class SDKTextboxModule { }
