export class Logging {
	public static getRequestLog(key: string, id: any): any {
		let storage: any = localStorage.getItem(key + "_LOG") ?? null;
		let requests: any[] = JSON.parse(storage) ?? [];
		let request = {
			"id": id,
			"avg": null,
			"start": null,
			"stop": null
		};

		if (!storage || requests.length === 0 || requests.find((r: any) => r.id === id) === undefined) {
			requests?.push(request);
			localStorage.setItem(key + "_LOG", JSON.stringify(requests));
		} else {
			request = requests.filter((r: any) => r.id === id)[0];
		}

		return request;
	}

	public static setRequestLog(key: string, log: any) {
		let storage: any = localStorage.getItem(key + "_LOG");
		let requests: any[] = JSON.parse(storage);

		let ndx: any = requests.findIndex((r: any) => r.id === log.id);

		if (ndx > -1) {
			requests[ndx].avg = log.avg;
			requests[ndx].start = log.start;
			requests[ndx].stop = log.stop;
		}

		localStorage.setItem(key + "_LOG", JSON.stringify(requests));
	}
}