<div class="content">
  <sdk-loading [isLoading]="isLoading"></sdk-loading>
   
  <div class="header">
    <div class="title">Search Results</div>
  </div>
  
  <div class="data" *ngIf="hasResults">
    <table mat-table [dataSource]="searchResultList" matSort matSortDisableClear (matSortChange)="sortChange($event)">
      <caption *ngIf="searchResultList.data.length == 0">Searching the CCMS database for the term: '{{ term }}'</caption>
        <ng-container matColumnDef="entityType">
          <th mat-header-cell *matHeaderCellDef="let result">&nbsp;</th>
          <td mat-cell *matCellDef="let result">
            <em class="icon-{{ result.entityType | lowercase }} "></em>
          </td>
        </ng-container>
        <ng-container matColumnDef="id">
          <th mat-header-cell *matHeaderCellDef>Link</th>
          <td mat-cell *matCellDef="let result">
            <a routerLink="/{{result.url}}" [queryParams]="result.queryParams" [state]="callbackState"
              *ngIf="result.entityType == 'Inventory'">
              {{result.text}}
            </a>
            <a routerLink="/{{result.url}}" [queryParams]="result.queryParams" [state]="callbackState"
               *ngIf="result.entityType != 'Inventory'">
                {{result.text}}
            </a>
          </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
        <tr mat-row *matRowDef="let row; columns: columnsToDisplay;"></tr>
    </table>
    <mat-paginator #paginator [pageSize]="10" [length]="searchResultList.data.length"
      [pageSizeOptions]="[10,20,50,100]" showFirstLastButtons aria-label="Search" class="paginator">
    </mat-paginator>   
  </div>     
  <div class="data" *ngIf="!hasResults">
    <h3>
      No results found for search term: '{{ term }}'
    </h3>
  </div>
</div>
