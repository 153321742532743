import { Component, Input, OnInit } from '@angular/core';

import { HttpService } from '~/services/core/http.service';

@Component({
    selector: 'feeds',
    templateUrl: './feeds.component.html',
    styleUrls: ['./feeds.component.scss']
})
export class FeedsComponent implements OnInit {
    @Input() urls = "";

    public isLoading = true;
    public feedData: any;
    public error: any;

    constructor(
        private httpService: HttpService
    ) { }

    public async ngOnInit() {
        this.getRSSFeed();
    }

    private async getRSSFeed() {
        this.isLoading = true;

        this.feedData = [];
        this.error = null;

        try {
            let feeds: string[] = this.urls.split(",");

            let wait = new Promise<void>((resolve, reject) => {
              (async () => {
                  try {
                      for (let url of feeds) {
                          let data = await this.httpService.Get(`/RSSReader?url=${url}`);
                          this.feedData = this.feedData.concat(data);
                      }
                      resolve();
                  } catch (error) {
                      reject(error);
                  }
              })();
            });
          
            wait.then(() => {
                this.isLoading = false;
            });
        } catch (error: any) {
            this.error = error.message;

            this.isLoading = false;
        }
    }

    public getDate(date: any) {
        return new Date(date);
    }
}
