import { Component } from '@angular/core';


import { BaseComponent } from '~/pages/base.component';

import { SupplyTradingInventoryService } from '~/services/shared/inventory/supplytrading-inventory.service';
import { FormatterService } from '~/services/shared/formatter.service';
import { RequestParameters } from '~/models/shared/request-parameters';

@Component({
    selector: 'supplytrading-overview',
    templateUrl: './supplytrading-overview.component.html',
    styleUrls: ['./supplytrading-overview.component.scss']
})

export class SupplyTradingOverviewComponent extends BaseComponent {
    public footnote: string = "";
    public MAXRECORDS: number = 1000;

    
    public totalPurchased: number = 0;
    public totalSold: number = 0;
    public totalBalance: number = 0;

    public transactionData: any;

    constructor(
        private inventoryService: SupplyTradingInventoryService,
        private formatterService: FormatterService
    ) {
        super();
    }

    //*************************************************************************
    //  Component Life-Cycle Methods
    //*************************************************************************
    public async ngOnInit() {
        await super.ngOnInit();

        await this.getTotals();

        await this.getTransactionData();
    }

    //*************************************************************************
    //  Public Methods
    //*************************************************************************
    
    
    //*************************************************************************
    //  Private Methods
    //*************************************************************************
    private async getTotals() {
        try {
            await this.inventoryService.getTotals().then((data: any) => {
                if (data) {
                    this.totalPurchased = data.totalCreditPurchases ?? 0;
                    this.totalSold = data.totalCreditSales ?? 0;
                    this.totalBalance = (data.totalCreditPurchases && data.totalCreditSales) ? data.totalCreditPurchases - data.totalCreditSales : 0;
                }
            });

            this.footnote = `Inventory as of ${this.formatterService.formatDate(new Date(), "dd MMM yyyy hh:mm:ss (z)")}`;
        } catch (error: any) {
        }
    }

    private async getTransactionData() {
        const requestParameters: RequestParameters = {
            Paging: {
                pageSize: 5000,
                currentPage: 1
            },
            SortingOptions: [],
            Terms: [],
            EntityIds: [],
            ActionFilter: ""
        };

        try {
            const response = await this.inventoryService.getInventory(requestParameters);
            this.transactionData = response?.values;
        } catch (error: any) {
            console.error(error.message);
        }
    }
}