import { NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DragDropModule } from '@angular/cdk/drag-drop';

import { DynamicModule } from 'ng-dynamic-component';

import { SDKDatagridComponent } from './sdk-datagrid.component';

import { SDKDataGridChartOptionComponent } from './options/chart/chart-option.component';
import { SDKDataGridColumnsOptionComponent } from './options/columns/columns-option.component';
import { SDKDataGridExportOptionComponent } from './options/export/export-option.component';
import { SDKDataGridFilterOptionComponent } from './options/filter/filter-option.component';
import { SDKDataGridFormulaOptionComponent } from './options/formula/formula-option.component';
import { SDKDataGridSortOptionComponent } from './options/sort/sort-option.component';
import { SDKDataGridSettingsOptionComponent } from './options/settings/settings-option.component';

import { SDKLoadingModule } from 'sdk-loading';
import { SDKTextboxModule } from 'sdk-textbox';
import { SDKSelectModule } from 'sdk-select';

@NgModule({
  declarations: [
    SDKDatagridComponent,
    SDKDataGridChartOptionComponent,
    SDKDataGridColumnsOptionComponent,
    SDKDataGridExportOptionComponent,
    SDKDataGridFilterOptionComponent,
    SDKDataGridFormulaOptionComponent,
    SDKDataGridSortOptionComponent,
    SDKDataGridSettingsOptionComponent,
  ],
  imports: [
    CommonModule,
    DragDropModule,
    DynamicModule,
    SDKLoadingModule,
    SDKTextboxModule,
    SDKSelectModule,
  ],
  exports: [
    SDKDatagridComponent
  ],
  schemas: [
    NO_ERRORS_SCHEMA
  ]
})
export class SDKDatagridModule { }
