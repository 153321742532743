<div class="content">
    <div class="data">
        <div class="sections">
            <div class="header">Summary</div>
            <table class="table-x centered">
                <caption style="display: none;">RatingSummary</caption>
                <tbody>
                    <tr>
                        <th scope="col">
                            <div class="header-info">
                                <span class="label">Sylvera</span>
                            </div>
                        </th>
                        <th scope="col">
                            <div class="header-info">
                                <span class="label">Calyx</span>
                            </div>
                        </th>
                        <th scope="col">
                            <div class="header-info">
                                <span class="label">BeZero</span>
                            </div>
                        </th>
                    </tr>
                    <tr>
                        <td>
                            {{ developer?.sylveraRatedProjectCount}}
                        </td>
                        <td>
                            {{ developer?.calyxGhgRatedProjectCount}}
                        </td>
                        <td>
                            {{ developer?.beZeroRatedProjectCount}}
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="sections">
            <div class="header">Rated Projects</div>
            <table class="table-x" >
                <caption style="display: none;">RatedProjects</caption>
                <tbody>
                    <tr>
                        <th scope="row">Id</th>
                        <th scope="col">Status</th>
                        <th scope="col">Name</th>
                        <th scope="col">Registry</th>
                        <th scope="col">Scope</th>
                        <th scope="col">Country</th>
                        <th scope="col">Ratings</th>
                    </tr>
                    <ng-container *ngFor="let row of ratedProjects">
                        <tr>
                            <td style="width: unset;">{{row.id}}</td>
                            <td style="width: unset;">{{row.status}}</td>
                            <td>{{row.name}}</td>
                            <td>{{row.registry}}</td>
                            <td>{{row.scope}}</td>
                            <td>{{row.country}}</td>
                            <td style="white-space: nowrap;">
                                <p *ngIf="row.beZeroRating">
                                    <b>BeZero:</b> {{row.beZeroRating}}
                                </p>
                                <p *ngIf="row.calyxGhgRating" [ngClass]="">
                                    <b>Calyx:</b> {{row.calyxGhgRating}}
                                </p>
                                <p *ngIf="row.sylveraRating">
                                    <b>Sylvera:</b> {{row.sylveraRating}}
                                </p>
                            </td>
                        </tr>
                    </ng-container>
                </tbody>
            </table>
        </div>
    </div>
    <div class="info">
        <developer-summary [developer]="developer"></developer-summary>
    </div>
</div>