export class GeoCoordinates {
    private readonly _latitude: number;
    private readonly _longitude: number;

    public get latitude() {
        return this._latitude;
    }

    public get longitude() {
        return this._longitude;
    }

    constructor(
        latitude: number,
        longitude: number
    ) {
        if (latitude < -90 || latitude > 90) {
            throw new Error('Latitude must be between -90 and 90 degrees.');
        }

        if (longitude < -180 || longitude > 180) {
            throw new Error('Longitude must be between -180 and 180 degrees.');
        }

        this._latitude = latitude;
        this._longitude = longitude;
    }

    public toString(): string {
        return `Latitude: ${this.latitude}, Longitude: ${this.longitude}`;
    }

    public static isGeoCoordinates(obj: any): obj is GeoCoordinates {
      return obj instanceof GeoCoordinates;
    }
}