import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { moveItemInArray } from '@angular/cdk/drag-drop';

import { SDKDataGridColumn } from '../../models/datagrid-column';

@Component({
    selector: 'sdk-datagrid-sort-option',
    templateUrl: './sort-option.component.html',
    styleUrls: ['./sort-option.component.scss']
})

export class SDKDataGridSortOptionComponent implements OnChanges {
    @Input() columns: SDKDataGridColumn[] = [];
    @Input() sorts: any[] = [];
    @Output() closeEvent = new EventEmitter();
    @Output() applyEvent = new EventEmitter<SDKDataGridColumn[]>();

    public columnList: any[] = [];
    public sortList: any[] = [];

    public ngOnChanges(_args: any) {
        this.initialze();
    }

    private initialze() {
        this.sortList = [];

        this.sorts.slice(0).forEach((sort: any) => {
            this.sortList.push({ Name: sort.Name, DisplayName: sort.DisplayName, FriendlyName: sort.FriendlyName, Sort: sort.Sort, formatter: sort.formatter, rawValueRetriever: sort.rawValueRetriever, dataTemplateProvider: sort.dataTemplateProvider });
        });

        this.setColumnList();
    }

    public getColumnOriginalName(column: SDKDataGridColumn): string {
        let originalName = column.Name;

        if (column.DisplayName && column.DisplayName !== "") {
            originalName = column.DisplayName;
        }

        return originalName;
    }

    private setColumnList() {
        this.columnList = [];

        this.columns.filter((c: any) => c.isVisible && c.showSort && !c.actionTemplate).forEach((column: any) => {
            if (this.sortList.filter((s: any) => s.Name === column.Name).length === 0) {
                this.columnList.push({ Name: column.Name, DisplayName: column.DisplayName, FriendlyName: column.FriendlyName, Sort: "asc", formatter: column.formatter, rawValueRetriever: column.rawValueRetriever, dataTemplate: column.dataTemplate });
            }
        });
    }

    public addSort(event: any) {
        const ndx: number =  this.columns.findIndex(c => c.Name === event.target.value);
        const column: SDKDataGridColumn = this.columns[ndx];

        this.sortList.push({ Name: column.Name, DisplayName: column.DisplayName, FriendlyName: column.FriendlyName, Sort: "asc", formatter: column.formatter, rawValueRetriever: column.rawValueRetriever, dataTemplate: column.dataTemplate });

        this.setColumnList();
    }

    public deleteSort(sort: any) {
        this.sortList = this.sortList.filter(f => f.Name !== sort.Name);

        this.setColumnList();
    }

    public drop(event: any) {
        moveItemInArray(this.sortList, event.previousIndex, event.currentIndex);
    }

    public reset() {
        if (confirm("Reset Sorting... Are you sure?")) {
            this.sorts = [];

            this.initialze();
        }
    }

    public apply() {
        this.applyEvent.emit(this.sortList);
    }

    public close() {
        this.closeEvent.emit();
    }
}
