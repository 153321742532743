<div class="content">
    <div class="header">
        <div class="totals">
            <div class="total"><span>Total Acquired:</span>{{ totalAcquired | number }}</div>
            <div class="total"><span>Total Transferred:</span>{{ totalTransferred | number }}</div>
            <div class="total"><span>Balance:</span>{{ totalBalance | number }}</div>
        </div>
    </div>
    <div class="data">
        <div class="summary-grid">
            <sdk-datagrid fontFamily="'GothamNarrowBook', sans-serif, 'Helvetica Neue', Arial"
                          [uniqueIdentifier]="uniqueIdentifier"
                          [datasets]="datasets"
                          [name]="activeView"
                          [data]="data"
                          [fullData]="fullData"
                          [columns]="columns"
                          [options]="gridOptions"
                          [dbPage]="dbPage"
                          [dbTotal]="dbTotal"
                          [dbMAXRECORDS]="MAXRECORDS"
                          [isDataWrapped]=false
                          [minimizeOptions]=false
                          [isLoading]="isLoading"
                          [error]="error"
                          [detailTemplate]="rowDetail"
                          (loadDataEvent)="loadData($event)">
            </sdk-datagrid>
        </div>
    </div>
    <div class="footnote">
        {{ footnote }}
    </div>
</div>

<ng-template #rowDetail let-rowAccount="rowItem">
    <div class="detail">
        <div class="detail-title">Last&nbsp;up&nbsp;to&nbsp;{{detailTransactionsCount}}&nbsp;transactions</div>
        <div class="detail-table">
            <table class="table table-striped">
                <caption style="display: none;">Account Summary</caption>
                <thead>
                    <tr>
                        <th>
                            <div title="Transaction Completion Date" class="longtext">Transaction Completion Date</div>
                        </th>
                        <th>
                            <div title="Acquiring Account Name" class="longtext">Acquiring Account Name</div>
                        </th>
                        <th>
                            <div title="Acquiring Account Number" class="longtext">Acquiring Account Number</div>
                        </th>
                        <th>
                            <div title="Transferring Account Name" class="longtext">Transferring Account Name</div>
                        </th>
                        <th>
                            <div title="Transferring Account Number" class="longtext">Transferring Account Number</div>
                        </th>
                        <th>
                            <div title="Quantity" class="longtext">Quantity</div>
                        </th>
                        <th>
                            <div title="Serial Range" class="longtext">Serial Range</div>
                        </th>
                        <th>
                            <div title="Vintage" class="longtext">Vintage</div>
                        </th>
                        <th>
                            <div title="Transaction Number" class="longtext">Transaction Number</div>
                        </th>
                        <th>
                            <div title="Transaction Type" class="longtext">Transaction Type</div>
                        </th>
                        <th>
                            <div title="Unit" class="longtext">Unit</div>
                        </th>
                        <th>
                            <div title="Registry" class="longtext">Registry</div>
                        </th>
                        <th>
                            <div title="Meta Registry" class="longtext">Meta Registry</div>
                        </th>
                        <th>
                            <div title="Project Id" class="longtext">Project Id</div>
                        </th>
                        <th>
                            <div title="Project Name" class="longtext">Project Name</div>
                        </th>
                        <th>
                            <div title="Comment" class="longtext">Comment</div>
                        </th>
                        <th>
                            <div title="BeZero Rating" class="longtext">BeZero Rating</div>
                        </th>
                        <th>
                            <div title="Sylvera Rating" class="longtext">Sylvera Rating</div>
                        </th>
                        <th>
                            <div title="Calyx GHG Rating" class="longtext">Calyx GHG Rating</div>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let transaction of getAcccountTransactions(rowAccount.accountNumber) | async">
                        <td>
                            <div [title]="transaction.transactionCompletionDate | date:dateFormat" class="longtext">{{ transaction.transactionCompletionDate | date:dateFormat }}</div>
                        </td>
                        <td>
                            <div [title]="transaction.acquiringAccountName" class="longtext" [ngClass]="(rowAccount.accountName ===  transaction.acquiringAccountName) ? 'highlight'  : ''">{{ transaction.acquiringAccountName }}</div>
                        </td>
                        <td>
                            <div [title]="transaction.acquiringAccountNumber" class="longtext" [ngClass]="(rowAccount.accountNumber ===  transaction.acquiringAccountNumber) ? 'highlight'  : ''">{{ transaction.acquiringAccountNumber }}</div>
                        </td>
                        <td>
                            <div [title]="transaction.transferringAccountName" class="longtext" [ngClass]="(rowAccount.accountName ===  transaction.transferringAccountName) ? 'highlight'  : ''">{{ transaction.transferringAccountName }}</div>
                        </td>
                        <td>
                            <div [title]="transaction.transferringAccountNumber" class="longtext" [ngClass]="(rowAccount.accountNumber ===  transaction.transferringAccountNumber) ? 'highlight'  : ''">{{ transaction.transferringAccountNumber }}</div>
                        </td>
                        <td>
                            <div [title]="transaction.quantity | number: numberFormat" class="longtext">{{ transaction.quantity | number: numberFormat }}</div>
                        </td>
                        <td>
                            <div [title]="transaction.serialRange" class="longtext">{{ transaction.serialRange }}</div>
                        </td>
                        <td>
                            <div [title]="transaction.vintage" class="longtext">{{ transaction.vintage }}</div>
                        </td>
                        <td>
                            <div [title]="transaction.transactionNumber" class="longtext">{{ transaction.transactionNumber }}</div>
                        </td>
                        <td>
                            <div [title]="transaction.transactionType" class="longtext">{{ transaction.transactionType }}</div>
                        </td>
                        <td>
                            <div [title]="transaction.unit" class="longtext">{{ transaction.unit }}</div>
                        </td>
                        <td>
                            <div *ngIf="transaction.registry && transaction.registryProjectUrl && transaction.registryProjectUrl !== ''">
                                <a [href]="transaction.registryProjectUrl" target="_blank">{{ transaction.registry.shortName }}</a>
                            </div>
                            <div *ngIf="transaction.registry && (!transaction.registryProjectUrl || transaction.registryProjectUrl === '')">
                                {{ transaction.registry.shortName }}
                            </div>
                        </td>
                        <td>
                            <div *ngIf="!transaction.metaRegistryProjectId || transaction.metaRegistryProjectId == ''" [title]="transaction.metaRegistryProjectId" class="longtext">{{ transaction.metaRegistryProjectId }}</div>
                            <div *ngIf="transaction.metaRegistryProjectId && transaction.metaRegistryProjectId !== ''" class="longtext">
                                <div class="link" (click)="gotoProject(rowAccount.accountNumber, transaction)">{{ transaction.metaRegistryProjectId }}</div>
                            </div>
                        </td>
                        <td>
                            <div [title]="transaction.projectId" class="longtext">{{ transaction.projectId }}</div>
                        </td>
                        <td>
                            <div [title]="transaction.projectName" class="longtext">{{ transaction.projectName }}</div>
                        </td>
                        <td>
                            <div [title]="transaction.comment" class="longtext">{{ transaction.comment }}</div>
                        </td>
                        <td>
                            <div [title]="transaction.beZeroRating" class="longtext">{{ transaction.beZeroRating }}</div>
                        </td>
                        <td>
                            <div [title]="transaction.sylveraRating" class="longtext">{{ transaction.sylveraRating }}</div>
                        </td>
                        <td>
                            <div [title]="transaction.calyxGhgRating" class="longtext">{{ transaction.calyxGhgRating }}</div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</ng-template>