import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, lastValueFrom, of, throwError, timeout } from 'rxjs';

import { AppConfigService } from '~/services/core/appconfig.service';
import { AppConfig } from '~/models/core/appconfig';

const TIMEOUT: number = 60000;

export enum ServiceType {
    API = 1,
    MetaRegistry = 2,
    Maps = 3,
    OpenStreetMap = 4
}

@Injectable({
    providedIn: 'root'
})
export class HttpService {
    public apiUrl: string = "";
    public apiHeader: HttpHeaders = new HttpHeaders();

    private config: AppConfig;

    constructor(
        private http: HttpClient,
        private appconfigService: AppConfigService
    ) {
        this.config = this.appconfigService.getConfig();
    }

    public async Get(endpoint: string, type: ServiceType = ServiceType.API, callTimeout: number = TIMEOUT): Promise<any> {
      this.getApiValues(type);
      let url: string = this.apiUrl + endpoint;
      let stream$ = this.http.get(url, { headers: this.apiHeader }).pipe(timeout(callTimeout));
      return lastValueFrom(stream$);
    }

    public async GetText(endpoint: string, type: ServiceType = ServiceType.API, callTimeout: number = TIMEOUT): Promise<string> {
      this.getApiValues(type);
      let url: string = this.apiUrl + endpoint;
      return lastValueFrom(
        this.http.get<string>(url, {
          headers: this.apiHeader,
          responseType: 'text' as 'json' // Cast to 'json' to satisfy TypeScript's type checking
        }).pipe(
          timeout(callTimeout) // Apply timeout directly to the get request
        )
      );
    }
        
    public async GetRaw(endpoint: string, type: ServiceType = ServiceType.API, callTimeout: number = TIMEOUT): Promise<any> {
      this.getApiValues(type);
      let url: string = endpoint;
      let stream$ = this.http.get(url, { headers: this.apiHeader }).pipe(timeout(callTimeout));
      return lastValueFrom(stream$);
    }

    public async Post(endpoint: string, body?: any, options?: any, type: ServiceType = ServiceType.API, callTimeout: number = TIMEOUT) {
        let results: any = null;

        this.getApiValues(type);

        try {
            let url: string = this.apiUrl + endpoint;
            const requestOptions = {
              ...options,
              headers: this.apiHeader
            };
  
            let stream$ = this.http.post(url, body, requestOptions).pipe(timeout(callTimeout));

            results = await lastValueFrom(stream$);
        } catch (error: any) {
            throw error;
        }

        return results;
    }

    public async Patch(endpoint: string, body?: any, type: ServiceType = ServiceType.API, callTimeout: number = TIMEOUT) {
        let results: any = null;

        this.getApiValues(type);

        try {
            let url: string = this.apiUrl + endpoint;

            let stream$ = this.http.patch(url, body, { headers: this.apiHeader }).pipe(timeout(callTimeout));

            results = await lastValueFrom(stream$);
        } catch (error: any) {
            throw error;
        }

        return results;
    }

    public async Put(endpoint: string, body?: any, type: ServiceType = ServiceType.API, callTimeout: number = TIMEOUT) {
        let results: any = null;

        this.getApiValues(type);

        try {
            let url: string = this.apiUrl + endpoint;

            let stream$ = this.http.put(url, body, { headers: this.apiHeader }).pipe(timeout(callTimeout));

            results = await lastValueFrom(stream$);
        } catch (error: any) {
            throw error;
        }

        return results;
    }

    public async Delete(endpoint: string, type: ServiceType = ServiceType.API, callTimeout: number = TIMEOUT): Promise<any> {
      this.getApiValues(type);
      try {
          const url: string = this.apiUrl + endpoint;
          const stream$ = this.http.delete(url, { headers: this.apiHeader }).pipe(timeout(callTimeout));
          const results = await lastValueFrom(stream$);
          return results;
      } catch (error: any) {
          throw error;
      }
    }
  

    public async GetAsset(file: string): Promise<any> {
      try {
          const stream$ = this.http.get(`assets/${file}`);
          const results = await lastValueFrom(stream$);
          return results;
      } catch (error: any) {
          throw error;
      }
    }  

    public handleError(allow404: boolean = true): (err: any, caught: Observable<any>) => Observable<null | undefined> {
      return err => {
          console.log(err);
          if (err.status === 404 && allow404) {
              return of(undefined);
          } else {
              //important to use the rxjs error here not the standard one
              return throwError(() => err);
          }
      };
    }
    
    private getApiValues(type: ServiceType) {
        switch (type) {
            case ServiceType.API:
              this.apiUrl = this.config.apiUrl + "/api";
              break;

            case ServiceType.MetaRegistry:
              this.apiUrl = this.config.metaRegistryExportUrl + "/api";
              break;

            case ServiceType.Maps:
              this.apiUrl = this.config.mapsUrl + "/api"
              break;

            case ServiceType.OpenStreetMap:
              this.apiUrl = this.config.openStreetMapUrl;
              break;
        }
    }
}
