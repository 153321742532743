import { Component, EventEmitter, Input, Output } from '@angular/core';

import { ProjectService } from '~/services/shared/projects/project.service';
import { UserInfo } from '~/models/shared/users/user-info';

@Component({
    selector: 'project-detail-tech-review',
    templateUrl: './project-detail-tech-review.component.html',
    styleUrls: ['./project-detail-tech-review.component.scss']
})

export class ProjectDetailTechReviewComponent {
    @Input() project: any;
    @Input() isEdit: boolean = false;
    @Input() saveData: boolean = false;
    @Input() portfolioId: string | undefined = undefined;
    @Output() changeEvent: EventEmitter<any> = new EventEmitter();
    @Output() saveDataEvent: EventEmitter<any> = new EventEmitter();

    public isLoading: boolean = false;
    public projectEdit: any;

    public statusList: any = [];
    public recommendationList: any = [];

    constructor(
        private projectsService: ProjectService
    ) { }

    //******************************************************************************
    //  Page Life-cycle Methods
    //******************************************************************************
    public async ngOnChanges() {
        if (this.saveData) {
            this.saveDataEvent.emit(false);
        }

        if (this.isEdit) {
            this.isLoading = true;

            this.projectEdit = { ...this.project };

            await this.loadStatusList();
            await this.loadRecommendationList();

            this.isLoading = false;
        }
    }

    //******************************************************************************
    //  Public Methods
    //******************************************************************************
    public setStatus(event: any) {
        if (event.target.value === "") {
            this.projectEdit.technicalReviewStatus = {};
        } else {
            this.projectEdit.technicalReviewStatus = JSON.parse(event.target.value);
        }

        this.changeEvent.emit(this.projectEdit);
    }

    public setRecommendation(event: any) {
        if (event.target.value === "") {
            this.projectEdit.technicalReviewRecommendation = {};
        } else {
            this.projectEdit.technicalReviewRecommendation = JSON.parse(event.target.value);
        }

        this.changeEvent.emit(this.projectEdit);
    }

    public setNotes(field: any, event: any){
        this.projectEdit[field] = event.target.value;

        this.changeEvent.emit(this.projectEdit);
    }

    public infoChange(event: any) {
        this.projectEdit[event.field] = event.value;

        this.changeEvent.emit(this.projectEdit);
    }

    public parseMethodologyTitle() {
        const data = this.project;
        if (data.methodology) {
            const methodology = data.methodology;
            const header = `[${methodology.registry.toUpperCase()}] ${methodology.name}`;
            return methodology.description
                ? `${header}: ${methodology.description}`
                : header;
        }

        return '';
    }

    public parseMethodologyDisplayText() {
        const data = this.project;
        if (data.methodology) {
            const methodology = data.methodology;
            return `[${methodology.registry.toUpperCase()}] ${methodology.name}`;
        }
        return "--";
    }

    public setMethodology(methodologyId: number) {
        this.projectEdit.methodologyId = methodologyId;
        this.changeEvent.emit(this.projectEdit);
    }

    public userSelectionChanged(field: string, event: any) {
        let id: string | null = null;
        let name: string | null = null;

        if (event) {
            const user: UserInfo = event;
            id = user.id;
            name = user.displayName;
        }

        this.infoChange({ field: `${field}Id`, value: id });
        this.infoChange({ field: field, value: name });

    }

    public isRequired(field: string): boolean {
        return this.isEdit && this.projectsService.isRequired(this.projectEdit, field);
    } 
    //******************************************************************************
    //  Private Methods
    //******************************************************************************
    private async loadStatusList(): Promise<void> {
        this.statusList = [];

        try {
            await this.projectsService.getTechnicalReviewStatuses().then((data: any) => {
                this.statusList = data;
            });
        } catch (error: any) {
            this.statusList = [];
        }
    }

    private async loadRecommendationList(): Promise<void> {
        this.recommendationList = [];

        try {
            await this.projectsService.getTechnicalReviewRecommendations().then((data: any) => {
                this.recommendationList = data;
            });
        } catch (error: any) {
            this.recommendationList = [];
        }
    }
}
