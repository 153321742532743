<div class="content">
	<div class="header">
		<div class="title">{{ title }}</div>
	</div>

	<div class="data">
		<div class="chart">
			<policy-tracker-map class="map" [isLoading]="isLoading" (changeLoadingEvent)="isLoading = $event"></policy-tracker-map>
		</div>
		<div class="options">
			<ccms-tree-checklist #treeCheckList></ccms-tree-checklist>
		</div>
	</div>
</div>

<sdk-loading [isLoading]="isLoading"></sdk-loading>