import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { lastValueFrom } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class GraphService {
    constructor(private http: HttpClient) { }

    public async getUserPhoto(): Promise<string> {
      try {
          const url = "https://graph.microsoft.com/v1.0/me/photos/48x48/$value";
          const headers: HttpHeaders = new HttpHeaders({
              Authorization: `Bearer ${localStorage.getItem('msal.idtoken')}`
          });
          const blobValue = await lastValueFrom(this.http.get(url, { headers: headers, responseType: "blob" }));
          
          if (blobValue) {
              return new Promise<string>((resolve) => {
                  const reader: FileReader = new FileReader();
                  reader.readAsDataURL(blobValue);
                  reader.onloadend = () => {
                      if (typeof reader.result === "string") {
                          resolve(reader.result);
                      } else {
                          resolve(""); // fallback to empty string if not a string
                      }
                  };
              });
          }
  
          return ""; // return empty string if no value
      } catch (error: any) {
          return ""; // return empty string on error
      }
  }  
}
