import { Injectable } from "@angular/core";
import { Observable, from } from "rxjs";
import { JobDefinitionsResponse, JobDefinitionsWithLatestQueuedJobResponse, QueuedJob, QueuedJobsResponse, SearchJobEventLogsResponse } from "~/models/shared/job/job.model";
import { RequestParameters } from "~/models/shared/request-parameters";
import { HttpService } from "~/services/core/http.service";

@Injectable()
export class JobService {
  private uri = "/jobs";

  constructor(private readonly httpService: HttpService) {}

  public getJobs(): Observable<QueuedJobsResponse> {
    return from(this.httpService.Get(this.uri));
  }

  public getJobDefinitions(): Observable<JobDefinitionsResponse> {
    const path = this.uri + "/definitions"
    return from(this.httpService.Get(path));
  }

  public getJobDefinitionsWithLatestQueuedJob(parameters: RequestParameters): Observable<JobDefinitionsWithLatestQueuedJobResponse> {
    const path = this.uri + "/definitions/with-latest-queued-job"
    return from(this.httpService.Post(path, parameters));
  }

  public getJobEventLogs(parameters: RequestParameters): Observable<SearchJobEventLogsResponse> {
    const path = this.uri + `/logs`;
    return from(this.httpService.Post(path, parameters));
  }

  public getJobEventLogCategories(jobName: string): Observable<string[]> {
    const path = this.uri + `/logs/${jobName}/categories`;
    return from(this.httpService.Get(path));
  }

  public getQueuedJob(name: string): Observable<QueuedJob> {
    const path = this.uri + `/${name}`;
    return from(this.httpService.Get(path));
  }

  public queueJob(name: string, reset: boolean = false): Observable<QueuedJob> {
    const path = this.uri + `/${name}/run?reset=${reset}`;
    return from(this.httpService.Post(path, {}));
  }

  public cancelJob(id: number): Observable<QueuedJob> {
    const path = this.uri + `/${id}/cancel`;
    return from(this.httpService.Post(path));
  }
}
