import { Injectable } from '@angular/core';
import { HttpClient, HttpBackend } from '@angular/common/http';
import { lastValueFrom } from 'rxjs';

import { AppSettings } from '~/models/core/appsettings';

const FILE = "assets/appsettings.json";
const SETTINGS_KEY = "_AppSettings";

@Injectable({
    providedIn: 'root'
})
export class AppSettingsService {
    private http: HttpClient;

    constructor(private httpHandler: HttpBackend) {
        this.http = new HttpClient(this.httpHandler);
    }

    public async init(): Promise<boolean> {
      try {
          const data: any = await lastValueFrom(this.http.get(FILE));
          this.saveSettings(data);
          return true;
      } catch (error: any) {
          return false;
      }
    }

    public async getSettings(initialize: boolean = false): Promise<AppSettings> {
        if (initialize) {
            await this.init();
        }

        let storage = sessionStorage.getItem(SETTINGS_KEY) ?? JSON.stringify(new AppSettings);

        return JSON.parse(storage);
    }

    public saveSettings(settings: AppSettings): Promise<void> {
        if (!settings.roles) {
            settings.roles = [];
        }

        return new Promise<void>((resolve) => {
            sessionStorage.setItem(SETTINGS_KEY, JSON.stringify(settings));

            resolve();
        });
    }

    public clearSettings() {
        sessionStorage.removeItem(SETTINGS_KEY);
    }
}
