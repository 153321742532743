import { Component, EventEmitter, Input, Output } from "@angular/core";
import { MethodologyDto } from "./methodology.dto";
import { MethodologySelectorService } from "./methodology.service";
import { BehaviorSubject } from "rxjs";

@Component({
    selector: 'ccms-methodology-selector',
    templateUrl: './methodology-selector.component.html',
    styleUrls: ['./methodology-selector.component.scss']
})
export class MethodologySelectorComponent {
    private _ready = false;
    private readonly _methodologies: MethodologyDto[] = [];
    private readonly _selectedRegistrySubject = new BehaviorSubject<string | null>(null);
    private readonly _methodologiesSubject = new BehaviorSubject<MethodologyDto[]>([]);
    private readonly _selectedMethodology = new BehaviorSubject<number | null>(null);

    public $selectedRegistry = this._selectedRegistrySubject.asObservable();
    public $selectedMethodology = this._selectedMethodology.asObservable();
    public $methodologies = this._methodologiesSubject.asObservable();

    @Input() methodologyId: number | null = null;
    @Output() methodologySelected = new EventEmitter<number>();

    constructor(private readonly methodologyService: MethodologySelectorService) {
        this.methodologyService.searchMethodologies()
            .then((result) => {
                this._ready = true;
                result.forEach((m: MethodologyDto) => {
                    this._methodologies.push({
                        ...m,
                        registry: m.registry.replace(/_/g, ' ').toUpperCase()
                    });
                });
                if (this.methodologyId && this.methodologyId > 0) {
                    const methodology = this._methodologies.find((m) => {
                        return m.id === this.methodologyId;
                    });
                    if (methodology) {
                        setTimeout(() => {
                            this._selectedRegistrySubject.next(methodology.registry);
                            setTimeout(() => this._selectedMethodology.next(methodology.id), 1);
                        }, 1);
                    }
                }
            });
        this._selectedRegistrySubject.subscribe((value) => {  
            if (!value) {
                this._methodologiesSubject.next([]);
                return;
            }
            const methodologies = this.getMethodologies(value);
            methodologies.unshift({
                id: -1,
                name: 'Select methodology...'
            } as MethodologyDto)
            this._methodologiesSubject.next(methodologies);
        })
    }

    public get ready() {
        return this._ready;
    }
    
    public get registries() {
        const uniqueSet = new Set(this._methodologies.map(m => m.registry));
        const result = Array.from(uniqueSet).sort((a, b) => a.localeCompare(b));
        result.unshift('Select registry...');
        return result;
    }

    public onRegistrySelect(value: string) {
        const effectiveValue = value === 'Select registry...'
            ? null : value;
        this._selectedRegistrySubject.next(effectiveValue);
    }

    public onMethodologySelect(value: number) {
        this.methodologySelected.emit(value);
    }

    public parseMethodologyDisplayText(methodology: MethodologyDto) {
        const name = methodology.name;
        return methodology.version
            ? `${name} (${methodology.version})`
            : name;
    }
    
    private getMethodologies(registry?: string) {
        if (!registry) {
            return [ ...this._methodologies ];
        }
        const filteredResult = this._methodologies.filter((m) => m.registry == registry);
        return filteredResult.sort((a, b) => {
            if (a.name < b.name) {
                return -1;
            }
            if (a.name > b.name) {
                return 1;
            }
            return 0;
        });
    }
}