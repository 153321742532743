<div class="content">
    <div class="filters">
        <table class="table-x">
            <caption style="display: none;">Filters</caption>
            <tbody>
                <tr>
                    <th scope="col">Status</th>
                    <td>
                        <sdk-select [options]="statuses" [selectedOptions]="selectedStatus" [multiSelect]=true [multiValues]=false [noValueDisabled]=false noValueLabel="All" displayValue="name" (selectChangeEvent)="setStatus($event)"></sdk-select>
                    </td>
                </tr>
                <tr>
                    <th scope="col">Scope</th>
                    <td>
                        <sdk-select [options]="scopes" [selectedOptions]="selectedScope" [noValueDisabled]=false noValueLabel="All" (selectChangeEvent)="setScope($event)"></sdk-select>
                    </td>
                </tr>
                <tr>
                    <th scope="col">Region</th>
                    <td>
                        <sdk-select [options]="regions" [selectedOptions]="selectedRegion" [noValueDisabled]=false noValueLabel="All" (selectChangeEvent)="setRegion($event)"></sdk-select>
                    </td>
                </tr>
                <tr *ngIf="showRiskFilters">
                    <th scope="col">Funding Type</th>
                    <td>
                        <sdk-select [options]="forecastTypes" [selectedOptions]="selectedFunding" [noValueDisabled]=false noValueLabel="Funded"  (selectChangeEvent)="setFunding($event)"></sdk-select>
                    </td>
                </tr>
                <tr *ngIf="showRiskFilters">
                    <th scope="col">Breakout:</th>
                    <td>
                        <div style="display: flex; flex-wrap: wrap;">
                            <div style="flex-basis: 100%; display: flex;">
                                <div style="flex: 1; ">
                                    <label for="scope" class="label">scopes</label>
                                </div>
                                <div style="flex: 1; ">
                                    <input type="checkbox" id="scope" [checked]="scopeBreakout"  (change)="setScopeBreakout($event)" >
                                </div>
                            </div>
                            <div style="flex-basis: 100%; display: flex;">
                                <div style="flex: 1; "><label class="label" for="region">regions</label>
                                </div>
                                <div style="flex: 1; "><input type="checkbox" id="region" [checked]="regionBreakout"  (change)="setRegionBreakout($event)"></div>
                            </div>
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
    <div class="actions">
        <div class="reset" (click)="reset()">Reset</div>
        <button (click)="apply()">Apply</button>
    </div>
</div>

<sdk-loading [isLoading]="isLoading"></sdk-loading>
