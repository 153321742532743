import { Component, Input } from "@angular/core";
import { CreditsByCountry } from "~/models/shared/credits-by-country";
import { CountryCreditsDataService } from "~/services/shared/country-credits.service";

@Component({
  selector: 'ccms-country-credits',
  templateUrl: './country-credits.component.html',
  styleUrls: ['./country-credits.component.scss']
})
export class CountryCreditsComponent {
  @Input() set countryId(value: number) {
    if (!value) {
      return;
    }
   
    this.loadCredits(value);
  }

  constructor(private _countryCreditsService: CountryCreditsDataService) {}

  public credits!: CreditsByCountry;
  public isLoading!: boolean;

  public async loadCredits(countryId: number) {
    this.isLoading = true;  
    this.credits = await this._countryCreditsService.getCreditsByCountry(countryId);
    this.isLoading = false;
  }
}