import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
    selector: 'auth',
    templateUrl: './auth.component.html',
    styleUrls: ['./auth.component.scss']
})

export class AuthComponent {
    public isLoading: boolean = true;

    constructor(
        private router: Router
    ) {
    }

    //*************************************************************************
    //  Component Life-Cycle Methods
    //*************************************************************************
    public async ngOnInit() {
        let redirect = sessionStorage.getItem("_redirect");

        sessionStorage.removeItem("_redirect");

        if (redirect && redirect !== "") {
            await this.router.navigateByUrl(redirect);
		} else {
            await this.router.navigateByUrl("/");
        }

        this.isLoading = false;
    }
}
