import { Component, Input } from "@angular/core";
import { Article6MemoViewModel } from "../article6-dialog/article6-memo.viewmodel";
import { PolicyTrackerService } from "~/services/shared/policy-tracker/policy-tracker.service";
import { CountryService } from "~/services/shared/country.service";

@Component({
  selector: 'ccms-article6-panel',
  templateUrl: './article6-panel.component.html',
  styleUrls: ['./article6-panel.component.scss']
})
export class Article6PanelComponent {
  @Input() set countryId(value: number) {
    if (!value) {
      return;
    }
   
    this.load(value);
  }

  constructor(
    private _policyTrackerService: PolicyTrackerService,
    private _countryService: CountryService) {}

  public memos: Article6MemoViewModel[] = [];
 
  private async load(countryId: number) {
    const mous = await this._policyTrackerService.getArticle6MousByCountry(countryId);
    const country = await this._countryService.getCountryById(countryId);
    if (country) {
      mous.forEach(item => {
        if (item.mouCountry) {
          this.memos.push(new Article6MemoViewModel(item, country));
        }
      });
    }
  }
}