import { Paging, SearchFilter, SortOption } from "../request-parameters";

export interface QueuedJob {
  id: number;
  name: string;
  status: string;
  startTime: Date;
  endTime: Date;
  percentCompleted: number;
  initiatedBy: string;
  runAsIdentity: string;
}

export interface QueuedJobsResponse {
  currentPage: number;
  pageSize: number;
  totalCount: number;
  totalPages: number;
  values: QueuedJob[];
}

export interface JobDefinition {
  name: string;
  recurrence: string;
  runAsIdentity: string;
  canReset: boolean;
}

export interface JobDefinitionsResponse {
  currentPage: number;
  pageSize: number;
  totalCount: number;
  totalPages: number;
  values: JobDefinition[];
}

export interface JobDefinitionsWithQueuedJob {
  jobDefinition: JobDefinition;
  queuedJob: QueuedJob;
}

export interface JobDefinitionsWithLatestQueuedJobResponse {
  currentPage: number;
  pageSize: number;
  totalCount: number;
  totalPages: number;
  values: JobDefinitionsWithQueuedJob[];
}

export interface EventLog {
  source: string;
  type: string;
  category: string;
  message: string;
  timestamp: string;
}

export class JobEventLogRequestParameters {
  public Name!: string;
  public Paging: Paging = new Paging();
  public SortingOptions: Array<SortOption> = [];
  public Terms: Array<SearchFilter> = [];
  public EntityIds: Array<string> = [];
  public ActionFilter: string = "";
}

export interface PaginationInfo {
  currentPage: number;
  pageSize: number;
  totalCount: number;
  totalPages: number;
}

export interface SearchJobEventLogsResponse {
  metadata: PaginationInfo;
  values: EventLog[];
}
