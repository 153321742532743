<sdk-message [(message)]="message" [style]="messageStyle"></sdk-message>
<div class="content">
    <div class="header">
        <div class="title">Reports</div>
    </div>
    <div class="data">
        <tabs [tabs]="tabs"></tabs>
    </div>
</div>

<sdk-loading [isLoading]="isLoading"></sdk-loading>