import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { ITreeDataContract } from "~/components/shared/tree-checklist/tree-data.contract";
import { TreeDataPolicyTrackerService } from "./policy-tracker/tree-data-policy-tracker.service";
import { TreeDataMarketService } from "./markets/tree-data-market.service";

@Injectable()
export class TreeChecklistDataService {
  constructor(
    private _router: Router
  ) { }

  public getTreeDataService(): ITreeDataContract {
    const path = this._router.url;
   
    if (path.endsWith("policy-tracker")) {
      return new TreeDataPolicyTrackerService();
    }

    return new TreeDataMarketService();
  }
}