<div class="wrapper">
    <div class="tile-switcher">
        <select id="tile-select" (change)="selectMapTiles($event.target.value)" class="mapstyles-select">
            <option *ngFor="let mapTile of mapTiles" [value]="mapTile.id">
                {{mapTile.name}}
            </option>
        </select>
    </div>
    <div class="map" #map></div>
</div>
<ng-container *ngIf="alertMessage">
  <ccms-alert [type]="alertType" [message]="alertMessage" class="alert-container"></ccms-alert>
</ng-container>