<table class="table-x">
    <caption style="display: none;">details</caption>
    <tbody>
        <tr>
            <th scope="col">Total Projects <fa-icon></fa-icon>
            </th>
            <td>{{ developer?.projectCount ?? 'N/A' }}</td>
        </tr>
        <tr>
            <th scope="col">Rated Projects</th>
            <td>
                {{ developer?.ratedProjects?.length ?? 'N/A' }}
            </td>
        </tr>
        <tr>
            <th scope="col">Credits Issued</th>
            <td>
                {{ (developer?.totalCreditsGenerated | number) ?? 'N/A' }}
            </td>
        </tr>
        <tr>
            <th scope="col">Credit Delivery Rate</th>
            <td>
                {{ (developer?.creditDeliveryRate | percent: '1.2') ?? 'N/A' }}
            </td>
        </tr>
        <tr>
            <th scope="col">Website</th>
            <td>
                <a *ngIf="developer?.website !== ''"
                    href="{{developer.website}}" rel="noopener" target="_blank">{{developer.website}}</a>
                <span *ngIf="!developer?.website || developer?.website === ''">N/A</span>
            </td>
        </tr>
    </tbody>
</table>