import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { ICvxProject } from '~/models/shared/cvxproject';

@Component({
    selector: 'project-add-location',
    templateUrl: './project-add-location.component.html',
    styleUrls: ['./project-add-location.component.scss']
})

export class ProjectAddLocation implements OnInit {
    
    
    @Input() project: any;
    
    @Output() changeEvent: EventEmitter<ICvxProject> = new EventEmitter();
    @Output() saveDataEvent: EventEmitter<boolean> = new EventEmitter();
    public isLoading: boolean = false;
    public showMap: boolean = true;
    public location: string = "";
    public zoom: string = "4";

    public _location: any = null;
    public _site: any = null;

    //******************************************************************************
    //  Page Life-cycle Methods
    //******************************************************************************
    ngOnInit(): void {
        this._location = { addressCountry: this.project.country };
        if (this.project.country) {
            this._site = {
                latitude: this.project.country.latLongCoords.latitude,
                longitude: this.project.country.latLongCoords.longitude
            };
        }
        if (this._site) {
            this.project.site = this._site;
        }
        console.log('Location step visitied');
    }

    //******************************************************************************
    //  Public Methods
    //******************************************************************************
    public addressChange(field: any, value: any) {
        this._location[field] = value;

        this.infoChange({ field: "location", value: this._location });
    }

    public siteChange(field: any, value: any) {
        this._site[field] = value;

        this.infoChange({ field: "site", value: this._site });
    }

    public infoChange(event: any) {
        this.project[event.field] = event.value;

        this.changeEvent.emit(this.project);
    }

}
