import { Component, EventEmitter, Output } from '@angular/core';

@Component({
    selector: 'sdk-datagrid-export-option',
    templateUrl: './export-option.component.html',
    styleUrls: ['./export-option.component.scss']
})

export class SDKDataGridExportOptionComponent {
    @Output() closeEvent = new EventEmitter();
    @Output() applyEvent = new EventEmitter();

    public includeColumns: boolean = true;
    public includeFilters: boolean = true;
    public includeSorting: boolean = true;

    public apply() {
        let exportOptions = {
            Columns: this.includeColumns,
            Filters: this.includeFilters,
            Sorting: this.includeSorting
        };

        this.applyEvent.emit(exportOptions);
    }

    public close() {
        this.closeEvent.emit();
    }
}
