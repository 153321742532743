import { CarbonMarket } from "~/models/shared/policy-tracker/carbon-market";
import { CarbonMechanism } from "~/models/shared/policy-tracker/carbon-mechanism";
import { Icap } from "~/models/shared/policy-tracker/icap";
import { PolicyTracker } from "~/models/shared/policy-tracker/policy-tracker";

export class PolicyTrackerDetailViewModel {
  private icap: Icap;
  private market: CarbonMarket;
  private mechanism: CarbonMechanism;

  constructor(private _policyTracker: PolicyTracker) {
    this.icap = _policyTracker.icap;
    this.market = _policyTracker.carbonMarket;
    this.mechanism = _policyTracker.carbonMechanism;
  }

  public get Name() {
    if (this.icap) {
      return this.icap.etsName;
    }

    if (this.market) {
      return this.market.name;
    }

    if (this.mechanism) {
      return this.mechanism.name;
    }

    return null;
  }

  public get Jurisdictions(): string[] {
    if (this.icap) {
      return this.icap.regions;
    }

    if (this.market) {
      return [this.market.jurisdictionCovered];
    }

    if (this.mechanism) {
      let regions = [this.mechanism.jurisdictionCovered];
      if (this.mechanism.cityStateProvince) {
        regions.push(this.mechanism.cityStateProvince);
      }

      return regions;
    }

    return [];
  }

  public get Sectors(): string[] {
    if (this.icap) {
      return this.icap.sectoralCoverage;
    }

    if (this.mechanism) {
      return this.mechanism.sectors;
    }

    return [];
  }

  public get Cap(): string {
    if (this.icap) {
      return this.icap.capSummary;
    }

    if (this.market?.ghgEmissionsCovered) {
      return `${this.market.ghgEmissionsCovered} MtCO2e`;
    }

    return "";
  }

  public get Overlapping(): string {
    if (this.market) {
      return this.market.overlappingInitiatives;
    }

    if (this.mechanism) {
      return this.mechanism.creditsAccepted.join(', ');
    }

    return "";
  }
}