export class PolicyTrackerConstants {
  public static readonly EuropeanUnion = "European Union";
  public static readonly EU_ETS = "EU-ETS";
  public static readonly China = "CN-ETS";
  public static readonly Alberta = "Alberta-ETS";
  public static readonly NewZealand = "NZ-ETS";
  public static readonly RGGI = "US-RGGI-ETS";
  public static readonly Canada = "CA-ETS";
  public static readonly California = "US-CA-CaT";
  public static readonly Quebec = "CA-Quebec-ETS";
  public static readonly Austrailia = "AU-ETS";
  public static readonly Sakhalin = "Sakhalin";

  public static readonly EU_ALPHA3_CODE = "EUE";
}