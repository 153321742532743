import { Injectable } from '@angular/core';
import { Overlay, OverlayConfig, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { ConfirmPopUpComponent } from '~/components/shared/confirm-popup/confirm-popup.component';
import { Observable, finalize } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ConfirmPopupService {

  constructor(private overlay: Overlay) { }

  private overlayRef: OverlayRef | null = null;
  
  open(message: string): Observable<string> {
    
    const overlayConfig = new OverlayConfig({
      hasBackdrop: true,
      positionStrategy: this.overlay.position().global().centerHorizontally().centerVertically()
    });
  
    this.overlayRef = this.overlay.create(overlayConfig);
    this.overlayRef.backdropClick().subscribe((event) => {
      this.close();
    });
  
    const popupPortal = new ComponentPortal(ConfirmPopUpComponent);
    const popup = this.overlayRef.attach(popupPortal).instance;
    popup.message = message;
  
    // Add keydown event listener
    const keydownListener = (event: KeyboardEvent) => {
      if (event.key === 'Enter') {
        this.close();
      }
    };
    document.addEventListener('keydown', keydownListener);
  
    // Make sure to remove the keydown listener when the popup is closed
    return popup.getResult().asObservable().pipe(
      finalize(() => {
        document.removeEventListener('keydown', keydownListener);
      })
    );
  }

  close(): void {
    if (this.overlayRef) {
      this.overlayRef.detach();
      this.overlayRef = null;
    }
  }
}
