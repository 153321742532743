
import { Injectable } from "@angular/core";
import { DatePipe } from "@angular/common";
import { firstValueFrom } from "rxjs";
import { IcePriceService } from "./ice-price.service";
import { ClearBluePriceService } from "./clearblue-price.service";
import { ChartDataPoint } from "~/components/shared/market-chart/market-chart.component";
import { MarketType } from "~/models/shared/markets/market.model";
import { AirCarbonPriceService } from "./aircarbon-price.service";
import { PlattsPriceService } from "./platts-price.service";

@Injectable({
  providedIn: 'root',
})
export class PriceDataService {
  constructor(
    private _datePipe: DatePipe,
    private _icePriceService: IcePriceService,
    private _clearBluePriceService: ClearBluePriceService,
    private _airCarbonPriceService: AirCarbonPriceService,
    private _plattsPriceService: PlattsPriceService) 
  {}

  public async getChartData(marketType: number, symbol: string) : Promise<ChartDataPoint[] | null>  {
    let data: ChartDataPoint[] | null = null;
    
      switch(marketType) { 
        case MarketType.Ice: { 
          data = await this.getIceData(symbol);
          break;
        }
        case MarketType.ClearBlue: {
          data = await this.getClearBlueData(symbol);
          break;
        }
        case MarketType.AirCarbon: {
          data = await this.getAirCarbonData(symbol);
          break;
        }
        case MarketType.Platts: {
          data = await this.getPlattsData(symbol);
          break;
        }
      }

      if (data) {
        let timesSet = new Set<string>();
        data = data.filter(item => {
            if (timesSet.has(item.time)) {
                return false;
            } else {
                timesSet.add(item.time);
                return true;
            }
        });
      }

      return data;
  }

  private async getIceData(symbol: string): Promise<ChartDataPoint[]> {
    const data: ChartDataPoint[] = [];
    const contract = await firstValueFrom(this._icePriceService.getPricesBySymbol(symbol));
      
    if (contract?.prices) {
      contract.prices.forEach(entry => {
        const date = this._datePipe.transform(entry.priceDate, 'yyyy-MM-dd');
        if (date) {
          const point: {time: string, value: number} = {time: date, value: entry.price};
          data.push(point);
        }
      });
    }

    return data;
  }

  private async getClearBlueData(symbol: string): Promise<ChartDataPoint[]> {
    const data: ChartDataPoint[] = [];
    const prices = await firstValueFrom(this._clearBluePriceService.getPricesByIndexName(symbol));
      
    prices.forEach(entry => {
      const date = this._datePipe.transform(entry.date, 'yyyy-MM-dd');
      if (date) {
        const point: {time: string, value: number} = {time: date, value: entry.price};
        data.push(point);
      }
    });
    
    return data;
  }

  private async getAirCarbonData(symbol: string): Promise<ChartDataPoint[]> {
    const data: ChartDataPoint[] = [];
    const prices = await firstValueFrom(this._airCarbonPriceService.getPricesBySymbol(symbol));
      
    prices.forEach(entry => {
      const date = this._datePipe.transform(entry.priceDate, 'yyyy-MM-dd');
      if (date) {
        const point: {time: string, value: number} = {time: date, value: entry.price};
        data.push(point);
      }
    });
    
    return data;
  }

  private async getPlattsData(symbol: string): Promise<ChartDataPoint[]> {
    const data: ChartDataPoint[] = [];
    const prices = await firstValueFrom(this._plattsPriceService.getPrices(symbol));
      
    prices.forEach(entry => {
      const date = this._datePipe.transform(entry.oprDate, 'yyyy-MM-dd');
      if (date) {
        const point: { time: string, value: number } = { time: date, value: entry.close };
        data.push(point);
      }
    });
    
    return data;
  }
}