<div class="content">
  <div class="data">
    <canvas [attr.id]="id"></canvas>
  </div>
  <div class="info">
    <retired-chart-filters (startChanged)="onStartDateChanged($event)" (exportFired)="onExportFired($event)"
    (endChanged)="onEndDateChanged($event)" (filtersChanged)="onFiltersChanged($event)"></retired-chart-filters>
  </div>
</div>
<sdk-loading [isLoading]="isLoading"></sdk-loading>
 