
import { ICountry } from "~/models/shared/location";
import { Article6Mou } from "~/models/shared/policy-tracker/article6-mou";

export class Article6MemoViewModel {
  constructor(article6: Article6Mou, currentCountry: ICountry) {
    if (article6.mouLink) {
      this.hasLink = true;
      this.link = article6.mouLink;
    }

    if (article6.mouDate) {
      this.hasDate = true;
      this.date = article6.mouDate;
    }

    this.country = currentCountry.name;
    if (article6.country.id == currentCountry.id) {
      this.countryTarget = article6.mouCountry?.name;
    } else {
      this.countryTarget = article6.country.name;
    }

    if (article6.description) {
      this.hasDescription = true;
      this.description = article6.description;
    }

    this.status = article6.status.name.toLowerCase();
    if (this.status == "underconsideration") {
      this.status = "under consideration";
    }
  }

  public hasLink: boolean = false;
  public link?: string;

  public hasDate: boolean = false;
  public date?: Date;

  public country: string;
  public countryTarget: string | undefined;

  public hasDescription: boolean = false;
  public description?: string;

  public status: string;
}