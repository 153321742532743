import { Component, Input } from '@angular/core';
import { CarbonMarketStatusEnum } from '~/models/shared/policy-tracker/carbon-market-status';

@Component({
  selector: 'ccms-ets-status',
  templateUrl: './ets-status.component.html',
  styleUrls: ['./ets-status.component.scss']
})
export class EtsStatusComponent {
  @Input() set status(value: CarbonMarketStatusEnum) {
    if (!value) {
      return;
    }

    this.marketStatus = value;
    this.loadStatus();
  }
  @Input() focus: boolean = false;

  public statusIcon: string | undefined;
  public statusName: string | undefined;
  public statusClass: string | undefined;
  public marketStatus: CarbonMarketStatusEnum | undefined;

  private loadStatus() {
    switch(this.marketStatus) {
      case CarbonMarketStatusEnum.UnderConsideration:
        this.statusIcon = "ets-under-consideration";
        this.statusClass = "under-consideration";
        this.statusName = "Under consideration";
        break;
      case CarbonMarketStatusEnum.UnderDevelopment:
        this.statusIcon = "ets-under-development";
        this.statusClass = "under-development";
        this.statusName = "Under development";
        break;
      case CarbonMarketStatusEnum.Implemented:
        this.statusIcon = "ets-in-force";
        this.statusClass = "in-force";
        this.statusName = "In force";
        break;
    }
  }
}
