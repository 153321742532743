import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ICvxProject } from '~/models/shared/cvxproject';

import { ProjectService } from '~/services/shared/projects/project.service';
import { UserInfo } from '~/models/shared/users/user-info';

@Component({
    selector: 'project-add-tech-review',
    templateUrl: './project-add-tech-review.component.html',
    styleUrls: ['./project-add-tech-review.component.scss']
})

export class ProjectAddTechReview implements OnInit {
    @Input() project: any;

    @Output() changeEvent: EventEmitter<ICvxProject> = new EventEmitter();
    @Output() saveDataEvent: EventEmitter<boolean> = new EventEmitter();
    public statusList: any = [];
    public recommendationList: any = [];
    public isLoading: boolean = true;

    constructor(
        private projectsService: ProjectService) {
    }

  
   
    //******************************************************************************
    //  Page Life-cycle Methods
    //******************************************************************************
    ngOnInit(): void {
        this.loadingData();
    }

    //******************************************************************************
    //  Public Methods
    //******************************************************************************
    public setStatus(event: any) {
        if (event.target.value === "") {
            this.project.technicalReviewStatus = {};
        } else {
            this.project.technicalReviewStatus = JSON.parse(event.target.value);
        }

        this.changeEvent.emit(this.project);
    }

    public setRecommendation(event: any) {
        if (event.target.value === "") {
            this.project.technicalReviewRecommendation = {};
        } else {
            this.project.technicalReviewRecommendation = JSON.parse(event.target.value);
        }

        this.changeEvent.emit(this.project);
    }

    public setNotes(field: any, event: any) {
        this.project[field] = event.target.value;

        this.changeEvent.emit(this.project);
    }

    public infoChange(event: any) {
        this.project[event.field] = event.value;

        this.changeEvent.emit(this.project);
    }
    public setDate(event: any) {
        const date = new Date(event.value);

        this.infoChange({field: event.field, value: date});
    }
    public displayDate(): string {
        if (!this.project.technicalReviewCompletionDate) {
            return '';
        }

        return this.project.technicalReviewCompletionDate.toLocaleDateString('en-US');
    }

    public userSelectionChange(field: string, event: any) {
        let id: string | null = null;
        let name: string | null = null;

        if (event) {
            const user: UserInfo = event;
            id = user.id;
            name = user.displayName;
        }
        
        this.infoChange({ field: `${field}Id`, value: id });
        this.infoChange({ field: field, value: name });
    }
    //******************************************************************************
    //  Private Methods
    //******************************************************************************
    private async loadStatusList(): Promise<void> {
        this.statusList = [];

        try {
            await this.projectsService.getTechnicalReviewStatuses().then((data: any) => {
                this.statusList = data;
            });
        } catch (error: any) {
            this.statusList = [];
        }
    }

    private async loadRecommendationList(): Promise<void> {
        this.recommendationList = [];

        try {
            await this.projectsService.getTechnicalReviewRecommendations().then((data: any) => {
                this.recommendationList = data;
            });
        } catch (error: any) {
            this.recommendationList = [];
        }
    }

    private async loadingData(): Promise<void> {
        await this.loadStatusList();
        await this.loadRecommendationList();
        this.isLoading = false;
    }

}
