import { Component, EventEmitter, Output } from "@angular/core";

@Component({
  selector: 'dialog-cancel-button',
  templateUrl: './dialog-cancel-button.component.html',
  styleUrls: ['./dialog-cancel-button.component.scss']
})
export class DialogCancelButtonComponent {
  @Output() onCancel = new EventEmitter();

  cancel() {
    this.onCancel.emit();
  }
}