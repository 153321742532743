import { Component, ElementRef, EventEmitter, Input, OnChanges, Output, ViewChild } from '@angular/core';

@Component({
	selector: 'sdk-select',
	templateUrl: './sdk-select.component.html',
	styleUrls: ['./sdk-select.component.scss']
})

export class SDKSelectComponent {
	@Input() label: any; // Text to display to the left/top of dropdown.
	@Input() labelPosition: string = "left"; // Text located to the 'left' or 'top' position of dropdown.
	@Input() labelStyle: any; // Style applied to 'label'.
	@Input() options: any; // Values to select from.
	@Input() optionStyle: any; // Main styling for the dropdown (e.g., font, border, colors, etc.).
	@Input() optionValuesStyle: any; // Main styling for the dropdown values list (e.g., max-height, overflow, etc.).
	@Input() noValueLabel: string = "..."; // 'No Value' label.
	@Input() noValueDisabled: boolean = true; // Prevent 'No Value' from being selected.
	@Input() displayValue: any; // Property (key) to display as value if options are of type 'object'.
	@Input() multiSelect: boolean = false; // Indicates single or multiple selections.
	@Input() multiValues: boolean = true; // Show multiple values in the dropdown display or 'n selected' message after 2+ values selected.
	@Input() selectedOptions: any; // Values pre-selected in dropdown.
	@Input() hoverColor: any; // Color used when mouse hovers over values.
	@Input() selectedColor: any; // Font color used to indicate selected value (single selection dropdown ONLY).
	@Input() selectedBackground: any; // Background color used to indicate selected value (single selection dropdown ONLY).

	@Output() selectChangeEvent: EventEmitter<any> = new EventEmitter(); // Event triggered on selections.

	public showDropdown: boolean = false;

	public ngOnInit() {
		if (!this.multiSelect && this.selectedOptions !== undefined && this.selectedOptions !== "" && !Array.isArray(this.selectedOptions)) {
			let tmp = [];

			tmp.push(this.selectedOptions);

			this.selectedOptions = tmp;
		}

		setTimeout(() => {
			this.setStyle();
		}, 1);
	}

	public valueBuilder(option: any) {
		let re = /\[([^\]]+)\]/g;
		let x = this.displayValue.match(re);
		let value: any = this.displayValue;

		if (x) {
			x.forEach((segment: any) => {
				let z = option[segment.replace("[", "").replace("]", "")];
				value = value.replaceAll(segment, z);
			});
		} else {
			value = option[value];
		}

		return value;
	}

	public selectionBuilder() {
		let selection: any = "";

		if (this.multiSelect) {
			if (this.multiValues) {
				if (this.displayValue && this.displayValue !== "") {
					let tmp: any = [];

					this.selectedOptions.forEach((item: any) => {
						tmp.push(this.valueBuilder(item));
					});

					selection = tmp.join(", ");
				} else {
					selection = this.selectedOptions;
				}
			} else {
				if (this.selectedOptions.length === 1) {
					selection = (this.displayValue && this.displayValue !== "") ? this.valueBuilder(this.selectedOptions[0]) : this.selectedOptions[0];
				} else {
					selection = this.selectedOptions.length + ' selected';
				}
			}
		} else {
			selection = (this.displayValue && this.displayValue !== "") ? this.valueBuilder(this.selectedOptions[0]) : this.selectedOptions[0];
		}

		return selection;
	}

	public titleBuilder() {
		let title: any = "";
		let tmp: any = [];

		if (this.selectedOptions && this.selectedOptions.length > 0) {
			this.selectedOptions.forEach((item: any) => {
				if (this.displayValue && this.displayValue !== "") {
					tmp.push(this.valueBuilder(item));
				} else {
					tmp.push(item);
				}
			});

			title = `Selected value(s):\n${tmp.join("\n")}`;
		}

		return title;
	}

	public isSelected(option: any) {
		let ndx = (this.selectedOptions && this.selectedOptions !== "") ? this.selectedOptions?.findIndex((item: any) => JSON.stringify(item) === JSON.stringify(option)) : -1;

		return (ndx > -1) ? true : false;
	}

	public selectItem(option: any) {
		let ndx = (this.selectedOptions && this.selectedOptions !== "") ? this.selectedOptions?.findIndex((item: any) => JSON.stringify(item) === JSON.stringify(option)) : -1;

		if (ndx > -1) {
			if (this.multiSelect || !this.noValueDisabled) {
				this.selectedOptions.splice(ndx, 1);
			}
		} else {
			if (!this.multiSelect || !this.selectedOptions) {
				this.selectedOptions = [];
			}

			this.selectedOptions.push(option);
		}

		if (!this.multiSelect && this.noValueDisabled) {
			this.showDropdown = false;
		}

		this.selectChangeEvent.emit(this.selectedOptions);
	}

	public clearSelections() {
		this.selectedOptions = [];

		this.selectChangeEvent.emit(this.selectedOptions);
	}

	private setStyle() {
		let element = document.getElementById("select");

		if (element) {
			element.style.setProperty("--hover-color", this.hoverColor);
			element.style.setProperty("--selected-color", this.selectedColor);
			element.style.setProperty("--selected-background", this.selectedBackground);
		}
	}
}
