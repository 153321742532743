<mat-form-field appearance="outline" subscriptSizing="dynamic" [class.larger]="size === 'larger'" >
  <mat-label *ngIf="label">{{ label }}</mat-label>
	<mat-select (openedChange)="openedChange($event)" (selectionChange)="optionsChanged($event)" [formControl]="selectFormControl" multiple>
		<mat-select-trigger>
			{{selectFormControl.value ? selectFormControl.value[0] : ''}}
			<span *ngIf="selectFormControl.value?.length > 1" class="additional-selection">
        (+{{selectFormControl.value.length - 1}} {{selectFormControl.value?.length === 2 ? 'other' : 'others'}})
      </span>
    </mat-select-trigger>
    <div class="select-container">
      <mat-form-field class="searchbar" appearance="outline" subscriptSizing="dynamic">
          <input #search autocomplete="off" placeholder="Search" aria-label="Search" matInput [formControl]="searchTextboxControl">
          <button [disableRipple]="true" *ngIf="search.value" matSuffix mat-icon-button aria-label="Clear" (click)="clearSearch($event)">
            <mat-icon>close</mat-icon>
          </button>
      </mat-form-field>
      <mat-optgroup *ngIf="(filteredOptions | async)?.length == 0">
        <div>No results found.</div>
      </mat-optgroup>
      <div class="options">
        <mat-option (onSelectionChange)="selectionChange($event)" *ngFor="let option of filteredOptions | async" [value]="option">
          {{option}}
        </mat-option>
      </div>
    </div>
  </mat-select>
</mat-form-field>
<div *ngIf="selectedValues.length > 0">{{ selectFormControl.value }}</div>