<div [class.hidden]="showWindow" class="options">
  <div class="label">Options</div>
  <div class="icons">
      <div title="Filters" class="icon material-icons-outlined" (click)="showFilters()">filter_alt</div>
      <div title="Export" class="icon material-icons-outlined" (click)="export()" >file_download</div>
  </div>
</div>
<div [class.hidden]="!showWindow" class="window">
  <div class="header">
      <span class="title">Filters</span>
      <span title="Close" class="icon material-icons-outlined close" (click)="hideFilters()">close</span>
  </div>
  <div class="component">
    <div class="content">
      <div class="filters">
        <table class="table-x">
        <caption style="display: none;">ProjectInfo</caption>
        <tbody>
          <tr>
              <th scope="col">Date Range</th>
              <td class="date-row">
                <input [(ngModel)]="start" matInput [matDatepicker]="picker1" (dateChange)="selectedStartDateChanged($event)">
                <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                <mat-datepicker #picker1></mat-datepicker>
                <input [(ngModel)]="end" matInput [matDatepicker]="picker2" (dateChange)="selectedEndDateChanged($event)">
                <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                <mat-datepicker #picker2></mat-datepicker>
              </td>
          </tr>
          <tr>
            <th scope="col">Credits Retired For</th>
            <td>
              <searchable-multi-select [data]="retiredFor" (changed)="OnChangeRetiredFor($event)" (searchTextChanged)="handleRetiredForSearch($event)" #retiredForSelect></searchable-multi-select>
            </td>
          </tr>
          <tr>
            <th scope="col">Credits Issued By:</th>
            <td>&nbsp;</td>
          </tr>
          <tr>
            <th scope="col">Type</th>
            <td>
              <multi-select [data]="projectTypes"
              (changed)="OnChangeProjectType($event)"
              #projectTypeSelect></multi-select>
            </td>
          </tr>
          <tr>
            <th scope="col">Methodology</th>
            <td>
              <multi-select [data]="methodologies"
              (changed)="OnChangeMethodology($event)"
              #methodologySelect></multi-select>  
            </td>
          </tr>
          <tr>
            <th scope="col">Product Type</th>
            <td>
              <multi-select [data]="productTypes"
              (changed)="OnChangeProductType($event)"
              #productTypeSelect></multi-select>
            </td>
          </tr>
          <tr>
            <th scope="col">Certification</th>
            <td>
              <multi-select [data]="certifications"
              (changed)="OnChangeCertification($event)"
              #certificationSelect></multi-select>
            </td>
          </tr>
          <tr>
            <th scope="col">Region</th>
            <td>
              <multi-select [data]="regions"
              (changed)="OnChangeRegion($event)" #regionSelect></multi-select>
            </td>
          </tr>
          <tr>
            <th scope="col">Country</th>
            <td>
              <multi-select [data]="countries"
              (changed)="OnChangeCountry($event)" #countrySelect></multi-select>
            </td>
          </tr>
          <tr>
            <th scope="col">Registry</th>
            <td>
              <multi-select [data]="registries"
              (changed)="OnChangeRegistry($event)" #registrySelect></multi-select>
            </td>
          </tr>
        </tbody>
        </table>
      </div>
      <div class="actions">
        <div class="reset" (click)="resetFilters()">Reset</div>
      </div>
    </div>
  </div>
</div>
<sdk-loading [isLoading]="isLoading"></sdk-loading>



