import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { DatePipe, isPlatformBrowser } from '@angular/common';

@Component({
	selector: 'calendar',
	templateUrl: './calendar.component.html',
	styleUrls: ['./calendar.component.scss']
})

export class CalendarComponent implements OnInit {
	@Input() date: string = "";
	@Input() show: boolean = false;
	@Output() setDateEvent: EventEmitter<any> = new EventEmitter();

	@ViewChild("yearText") yearText: ElementRef | undefined;

	public showCalendar: boolean = true;
	public showMonths: boolean = false;
	public showYears: boolean = false;

	public calendarHtml: string = "";

	public year: number = 0;
	public month: string = "";
	public monthNumber: number = 0;
	public dayNumber: number = 0;
	public months: string[] = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
	public days: string[] = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

	public calDays: any;

	private newData: string = "";

	constructor(private datePipe: DatePipe) {}

	public ngOnInit() {
		this.setCalendar();
	}

	public ngOnChanges() {
		this.newData = this.date;
	}

	public setDate(date: string) {
		this.setDateEvent.emit(date);
	}

	public getToday() {
		this.newData = new Date().toString();
		this.setCalendar();
	}

	public getMonth() {
		this.showMonths = true;
		this.showCalendar = false;
	}

	public setMonth(month: any) {
		this.showCalendar = true;
		this.showMonths = false;

		this.newData = new Date(this.year, month, this.dayNumber).toString();
		this.setCalendar();
	}

	public getYear() {
		this.showYears = true;
		this.showCalendar = false;
	}

	public setYear() {
		if (this.yearText?.nativeElement.value !== "" && this.yearText?.nativeElement.value.length === 4) {
			this.showCalendar = true;
			this.showYears = false;

			this.newData = new Date(this.yearText?.nativeElement.value, this.monthNumber - 1, this.dayNumber).toString();
			this.setCalendar();
		}
	}

	public onKeyDown(event: any) {
		let text = event.target.value;

		if (event.key === "Enter") {
			this.setYear();
		} else if (
			event.key === "Backspace"
			|| event.key === "Tab"
			|| event.key === "ArrowLeft"
			|| event.key === "ArrowRight"
			|| event.key === "Meta"
			|| event.key === "Control"
		) {
			// DO NOTHING
		} else {
			if (!(event.metaKey && event.key === "v") && !(event.metaKey && event.key === "c")) {
				if (!event.key.match(/[0-9]/)
					|| text.length >= 4
				) {
					event.preventDefault();
				}
			}
		}
	}

	private setCalendar() {
		let dt = (this.newData && this.newData !== "") ? new Date(this.newData) : new Date();

		this.year = dt.getFullYear();
		this.month = this.months[dt.getMonth()];
		this.monthNumber = (dt.getMonth() + 1);
		this.dayNumber = dt.getDate();

		let fistDay = new Date(this.year, dt.getMonth(), 1).getDay();
		let totalDays = this.getTotalDays(dt.getFullYear(), dt.getMonth() + 1);
		let weeks = Math.floor(totalDays / 7);

		let isToday: boolean = false;
		let isSelected: boolean = false;

		if (fistDay > 0) weeks = weeks + 1;
		if ((totalDays + fistDay) > (weeks * 7)) weeks = weeks + 1;

		let plotDay: any = 1;

		this.calDays = [];

		for (let w = 0; w < weeks; w++) {
			for (let d = 0; d < 7; d++) {
				let currentDay = ((7 * w) + (d + 1));

				if (currentDay < (fistDay + 1)) {
					this.calDays.push({ date: "", day: ""});
				} else {
					if (plotDay <= totalDays) {
						if (this.datePipe.transform(new Date(this.year, this.monthNumber - 1, plotDay), 'yyyy-MM-dd') === this.datePipe.transform(new Date(), 'yyyy-MM-dd')) {
							isToday = true;
						} else {
							isToday = false;
						}

						if (this.datePipe.transform(new Date(this.year, this.monthNumber - 1, plotDay), 'yyyy-MM-dd') === this.datePipe.transform(this.date, 'yyyy-MM-dd')) {
							isSelected = true;
						} else {
							isSelected = false;
						}

						this.calDays.push({ date: this.datePipe.transform(new Date(this.year, this.monthNumber - 1, plotDay), 'yyyy-MM-dd'), day: plotDay.toString(), today: isToday, selected: isSelected });
						
						plotDay++;
					} else {
						this.calDays.push({ date: "", day: ""});
					}
				}
			}
		}
	}

	private getTotalDays(year: any, month: any) {
		return new Date(year, month, 0).getDate();
	}
}