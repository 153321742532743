export class ColorGenerator {
    private hue: number;
    /** Saturation in percentage */
    private saturation: number;
    /** Lightness in percentage */
    private lightness: number;

    constructor(init: Partial<{
        hue: number,
        saturation: number,
        lightness: number
    }> = {
        hue: 0,
        saturation: 50,
        lightness: 50
    }) {
        this.hue = init.hue ?? 0; // Starting hue
        this.saturation = init.saturation ?? 50;
        this.lightness = init.lightness ?? 50;
    }

    private incrementHue(): void {
        // Increment by the golden angle
        this.hue = (this.hue + 137.508) % 360;
    }

    private hslToHex(h: number, s: number, l: number): string {
        l /= 100;
        const a = s * Math.min(l, 1 - l) / 100;
        const f = (n: number) => {
            const k = (n + h / 30) % 12;
            const color = l - a * Math.max(Math.min(k - 3, 9 - k, 1), -1);
            return Math.round(255 * color).toString(16).padStart(2, '0');
        };
        return `#${f(0)}${f(8)}${f(4)}`;
    }

    public getNextColor(format: 'hex' | 'hsl' = 'hex'): string {
        this.incrementHue();
        const hslColor = `hsl(${this.hue}, ${this.saturation}%, ${this.lightness}%)`;

        if (format === 'hex') {
            return this.hslToHex(this.hue, this.saturation, this.lightness);
        }

        return hslColor;
    }
}
