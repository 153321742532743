export interface ResourcePermission { 
    resourceId?: string;
    permissionId?: string;
}

const UpdateAll = 'UpdateAll';
const DeleteAll = 'DeleteAll';
const Create = 'Create';
const ReadAll = 'ReadAll';
const Project = 'Project';
const AllResources = 'AllResources';

export const PermissionUpdate: ResourcePermission = {
  resourceId: AllResources,
  permissionId: UpdateAll
}

export const PermissionCreate: ResourcePermission = {
  resourceId: AllResources,
  permissionId: Create
}

export const PermissionDelete: ResourcePermission = {
  resourceId: AllResources,
  permissionId: DeleteAll
}

export const PermissionProjectUpdateAll: ResourcePermission = { 
  resourceId: Project,
  permissionId: UpdateAll
}

export const PermissionProjectDeleteAll: ResourcePermission = { 
  resourceId: Project,
  permissionId: DeleteAll
}

export const PermissionProjectCreate: ResourcePermission = { 
  resourceId: Project,
  permissionId: Create
}

export const PermissionProjectReadAll: ResourcePermission = { 
  resourceId: Project,
  permissionId: ReadAll
}

export interface Role { 
    name?: string;
    aadName?: string;
    permissions?: Array<ResourcePermission>;
}

export interface Permissions { 
    upn?: string;
    roles?: Array<Role>;
}