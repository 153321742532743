import { AfterViewInit, Component, ViewEncapsulation } from '@angular/core';
import { BaseType, geoEquirectangular, geoPath, interpolateBlues, json, tsv, max, rgb, select, Selection, zoom } from 'd3';
import { NumberValue, scaleSequential, ScaleSymLog, scaleSymlog } from 'd3-scale';
import { feature } from 'topojson-client';

import { HttpService } from '~/services/core/http.service';

import { MapProperties, MapMethods } from './classes/metaregistry-world-map';

@Component({
    selector: 'metaregistry-world-map',
    templateUrl: './metaregistry-world-map.component.html',
    encapsulation: ViewEncapsulation.None,
    styleUrls: ['./metaregistry-world-map.component.scss']
})
export class MetaRegistryWorldMapComponent implements AfterViewInit {
    public isLoading = true;
    public error: any;

    constructor(private httpService: HttpService) { }

    public async ngAfterViewInit() {
        let countries: any = await this.loadCountries();

        if (!this.error) {
            let num: any = max(countries.features, (d: any) => { return Number(d['properties']['projectCount']) });
            let maxProjects: number = Math.round(num / 1000) * 1000;
            let scaleProjects = scaleSymlog().domain([0.00001, maxProjects]).range([0, 1]);

            let svg: any = select('svg#metaregistrymapsvg');
            let projection = geoEquirectangular();
            let pathGenerator = geoPath().projection(projection);

            this.addColorgLegend(svg, maxProjects);

            let g: any = svg.append('g');
            const zoomBehavior = zoom()
              .scaleExtent([1, Infinity])  // set the scale extent
              .on('zoom', (event) => {
                g.attr('transform', event.transform);
              });
            svg.call(zoomBehavior);

            g.selectAll('path')
                .data(countries.features)
                .enter()
                .append('path')
                .attr('class', 'country')
                .attr('d', pathGenerator)
                .attr('fill', (d: any) => this.getColor(scaleProjects, d))
                .append('title')
                .text((d: any) => this.getToolTip(d));
        }

        this.isLoading = false;
    }

    private async loadCountries() {
        return Promise.all([
            tsv('https://unpkg.com/world-atlas@1.1.4/world/50m.tsv'),
            json('https://unpkg.com/world-atlas@1.1.4/world/50m.json'),
            await this.getCreditsByCountry()
        ]).then(([tsvData, topoJsonData, creditsByCountry]) => {
            return (this.error) ? false : this.processData(tsvData, topoJsonData, creditsByCountry);
        });
    }

    private async getCreditsByCountry(): Promise<any> {
        try {
            return await this.httpService.Get("/MetaRegistry/CreditsByCountry")
        }
        catch (error: any) {
            this.error = error.message;
        };
    }

    private processData(tsvData: any, topoJsonData: any, creditsByCountry: any) {
        const countriesById = tsvData.reduce((accumulator: any, d: any) => {
            let summary = {
                name: d.name,
                creditsRetired: 0,
                creditsRemaining: 0,
                creditsIssued: 0,
                projectCount: 0
            }

            accumulator[Number(d.iso_n3)] = summary;
            return accumulator;
        }, {});

        const credits = creditsByCountry.reduce((accumulator: any, d: any) => {
            let summary = {
                name: d.country.name,
                creditsRetired: d.creditsRetired,
                creditsRemaining: d.creditsRemaining,
                creditsIssued: d.creditsIssued,
                projectCount: d.projectCount
            }

            accumulator[d.country.id] = summary;
            return accumulator;
        }, {});

        let countries: any = feature(topoJsonData, topoJsonData.objects.countries);

        countries.features.forEach((d: any) => {
            const id = Number(d.id);
            if (typeof credits[id] === 'undefined') {
                Object.assign(d.properties, countriesById[id]);
            } else {
                Object.assign(d.properties, credits[id]);
            }
        });

        return countries;
    }

    private addColorgLegend(svg: Selection<BaseType, any, HTMLElement, any>, maxProjects: NumberValue) {
        let colorLegendId: string = "colorlegend";

        svg.append('g')
            .attr('transform', `translate(20, 135)`)
            .attr('id', colorLegendId);

        let legendProps: any = new MapProperties();
        let legend: any = MapMethods.colorLegend(scaleSequential([0, maxProjects], interpolateBlues), legendProps);

        let svg2: any = document.querySelector(`#${colorLegendId}`);
        svg2.appendChild(legend);
    }

    private getColor(scaleProjects: ScaleSymLog<number, number, never>, d: any) {
        const projectCount: number = Number(d['properties']['projectCount']);
        if (projectCount > 0) {
            return interpolateBlues(scaleProjects(projectCount));
        }

        return rgb(224, 224, 224).toString();
    }

    private getToolTip(d: any): string {
        const props = d.properties;

        if ((props.creditsIssued > 0 ||
            props.creditsRetired > 0 ||
            props.creditsRemaining > 0) &&
            props.projectCount > 0) {
            return d.properties.name + " (" + Number(props.projectCount).toLocaleString() + "):\n"
                + " - issued: " + Number(props.creditsIssued).toLocaleString() + "\n"
                + " - retired: " + Number(props.creditsRetired).toLocaleString() + "\n"
                + " - remaining: " + Number(props.creditsRemaining).toLocaleString();
        } else if (props.projectCount > 0) {
            return d.properties.name + " (" + Number(props.projectCount).toLocaleString() + ")";
        }

        return props.name;
    }
}
