import { Injectable } from "@angular/core";
import { Observable, from } from "rxjs";
import { DurableTaskResponse } from "~/models/shared/durable-task/durable-task-response.model";
import { DurableTaskStatusResponse } from "~/models/shared/durable-task/durable-task-status-response.model";
import { HttpService, ServiceType } from "~/services/core/http.service";

@Injectable()
export class DurableTaskService {
  constructor(private readonly _httpClient: HttpService){}
  
  public getTaskStatus(task: DurableTaskResponse): Observable<DurableTaskStatusResponse> {
    return from(this._httpClient.GetRaw(task.statusQueryGetUri, ServiceType.MetaRegistry));
  }
}