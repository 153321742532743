import { Injectable } from '@angular/core';

import { HttpService, ServiceType } from '../core/http.service';

import { SearchFilter, RequestParameters, SortOption } from '~/models/shared/request-parameters';
import { MetaRegistryPriceRequest } from '~/models/shared/metaregistry-price-request.model';
import { MetaRegistryPriceResponseModel } from '~/models/shared/metaregisty-price-response.model';
import { ExportMetaRegistryRequest } from '~/pages/metaregistry/export/models/export-metaregistry-request';
import { Observable, from } from 'rxjs';
import { DurableTaskResponse } from '~/models/shared/durable-task/durable-task-response.model';
import { IAlert } from '../../models/shared/alert.model';
import { FilterInfo } from '~/models/shared/filterInfo';

@Injectable()
export class MetaRegistryService {

    constructor(
        private httpService: HttpService,
    ) { }

    public buildParametersSorts(sorts: SortOption[], parameters: RequestParameters) {
        parameters.SortingOptions = [];

        if (sorts && sorts.length > 0) {
            sorts.forEach((sort: any, index: number) => {
                let direction = (sort.Sort === "asc") ? 0 : 1;
                let ndx = index + 1;

                parameters.SortingOptions.push({ field: sort.Name, direction: direction, priority: ndx });
            });
        }
    }

    public buildParametersFilters(filters: SearchFilter[], parameters: any) {
        parameters.Terms = [];
        if (filters && filters.length > 0) { // Fixing typo: `filter.length` -> `filters.length`
            filters.forEach((filter: any) => {
                parameters.Terms.push(this.buildFilterTerm(filter));
            });
        }
    }

    private buildFilterTerm(filter: any): SearchFilter {
        const filterTerm = new SearchFilter();

        if (filter.Name === "*keyword") {
            this.assignKeywordFilter(filter, filterTerm);
        } else if (filter.Name === "*any") {
            this.assignAnyFilter(filter, filterTerm);
        } else {
            this.assignGenericFilter(filter, filterTerm);
        }

        return filterTerm;
    }

    private assignKeywordFilter(filter: any, filterTerm: SearchFilter) {
        filterTerm.Field = "keyword";
        filterTerm.Value = filter.Value;
        filterTerm.Operation = filter.Operation;
    }

    private assignAnyFilter(filter: any, filterTerm: SearchFilter) {
        filterTerm.Field = "rating";
        filterTerm.Value = filter.Value ? "any" : "";
        filterTerm.Operation = "Contains";
    }

    private assignGenericFilter(filter: any, filterTerm: SearchFilter) {
        filterTerm.Field = filter.Name ? filter.Name : filter.Field;
        filterTerm.Operation = filter.Operation;
        this.assignTermValues(filter.Value, filterTerm);
    }

    private assignTermValues(filterValue: any, filterTerm: SearchFilter) {
        if (filterValue instanceof (Array)) {
            filterTerm.MultiSelectValues = filterValue;
        }
        else {
            filterTerm.Value = filterValue;
        }
    }

    public async getRegistry(parameters: RequestParameters): Promise<any> {
        try {
            return await this.httpService.Post("/MetaRegistry/Search", parameters).then((data: any) => {
                return data;
            });
        } catch (error: any) {
            throw error;
        }
    }

    public async getProject(id: string, includeCountryRisk: boolean = false): Promise<any> {
        try {
            return await this.httpService.Get(`/MetaRegistry/${id}?includeCountryRisk=${includeCountryRisk}`).then((data: any) => {
                return data;
            });
        } catch (error: any) {
            throw error;
        }
    }

    public async getIssuances(id: string, parameters: RequestParameters): Promise<any> {
        try {
            return await this.httpService.Post(`/Issuance/${id}`, parameters).then((data: any) => {
                return data;
            });
        } catch (error: any) {
            throw error;
        }
    }

    public async getMetaRegistryPrices(request: MetaRegistryPriceRequest): Promise<MetaRegistryPriceResponseModel> {
        const resourceUri = '/metaregistry/prices';
        try {
            return await this.httpService.Post(resourceUri, request).then((data: any) => {
                return data;
            });
        } catch (error: any) {
            throw error;
        }
    }

    public exportToExcel(request: ExportMetaRegistryRequest): Observable<DurableTaskResponse> {
        const serviceUri = `/ExportMetaRegistry`;
        return from(this.httpService.Post(serviceUri, request, null, ServiceType.MetaRegistry));
    }

    getAlerts(projectId: string): Observable<IAlert[]> {
        return from(this.httpService.Get(`/metaregistry/${projectId}/alerts`));
    }

    public async getFilters(fieldNames: string[]): Promise<FilterInfo[]> {
        try {
            let queryString = "";
            fieldNames.forEach((fieldName) => {
                queryString = queryString === "" ? `fields=${fieldName}` : `${queryString}&fields=${fieldName}`;
            });
            const response = await this.httpService.Get(`/metaregistry/filters?${queryString}`);
            return response;
        } catch (error: any) {
            throw error;
        }
    }

}
