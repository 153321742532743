<div class="content">
    <div class="grid">
        <sdk-datagrid fontFamily="'GothamNarrowBook', sans-serif, 'Helvetica Neue', Arial"
                      [uniqueIdentifier]="uniqueIdentifier"
                      [datasets]="datasets"
                      [name]="activeView"
                      [data]="data"
                      [fullData]="fullData"
                      [columns]="columns"
                      [options]="gridOptions"
                      [dbPage]="dbPage"
                      [dbTotal]="dbTotal"
                      [dbMAXRECORDS]="MAXRECORDS"
                      [isDataWrapped]=false
                      [minimizeOptions]=false
                      [isLoading]="isLoading"
                      [error]="error"
                      [settings]="allSettings"
                      (loadDataEvent)="loadData($event)"
                      (settingsSavedEvent)="saveSettings($event)">
        </sdk-datagrid>
    </div>

    <div *ngIf="showFooter" class="footnote">
        {{ footnote }}
    </div>
</div>
<ng-template #longTextTemplate let-value="value">
    <div class="cell-content">
        <div [title]="value" class="longtext">{{ value }}</div>
    </div>
</ng-template>

<ng-template #registryTemplate let-rowItem="rowItem">
    <div *ngIf="rowItem.registry && rowItem.registryProjectUrl && rowItem.registryProjectUrl !== ''">
        <a [href]="rowItem.registryProjectUrl" target="_blank">{{ rowItem.registry.shortName }}</a>
    </div>
    <div *ngIf="rowItem.registry && (!rowItem.registryProjectUrl || rowItem.registryProjectUrl === '')">
        {{ rowItem.registry.shortName }}
    </div>
</ng-template>

<ng-template #metaRegistryIdTemplate let-rowItem="rowItem">
    <div *ngIf="rowItem && rowItem.metaRegistryProjectId && rowItem.metaRegistryProjectId !== ''">
        <div class="link" (click)="gotoProject(rowItem.metaRegistryProjectId)">{{ rowItem.metaRegistryProjectId }}</div>
    </div>
</ng-template>