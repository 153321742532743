<ng-template #template class="content">
	<div class="charts">
		<div class="chart">
			<canvas id="riskProjectScope" data-chart-type="risk" data-chart-label="Risk by Scope"></canvas>
		</div>
		<div class="legend" id="legend">
			<ng-container *ngFor="let legendItem of  legendItems;">
				<div class="legend-item">
					<span [ngStyle]="{  
			                      'background': legendItem.fillStyle,   
			                      'border-color': legendItem.strokeStyle,  
								  'border-width': legendItem.lineWidth + 'px'
			                    }" style="height: 20px; width: 20px; margin-right: 10px; display: inline-block;">&nbsp;</span>
					<label style="display: inline-block;" (click)="legendClick($event)">{{legendItem.text}}</label>
				</div>
			</ng-container>
		</div>
	</div>
	<div class="options">
		<options [options]="options" [closeWindow]="closeOptionsWindow"></options>
	</div>
	<sdk-loading [isLoading]="isLoading"></sdk-loading>
</ng-template>