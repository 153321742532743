import { Injectable } from "@angular/core";
import { HttpService } from "~/services/core/http.service";
import { MethodologyDto } from "./methodology.dto";

@Injectable({
    providedIn: 'root'
})
export class MethodologySelectorService {
    constructor (private readonly httpService: HttpService) {
        // Intentionally blank
    }

    public async searchMethodologies(search?: string): Promise<MethodologyDto[]> {
        let requestUri = '/methodologies';
        if (search) {
            requestUri += '?search=' + search;
        }
        return this.httpService.Get(requestUri);
    }
}