import { Injectable } from "@angular/core";
import { Observable, from, lastValueFrom, map } from "rxjs";
import { PagedList } from "~/models/shared/paging/paged-list";
import { ZeroTrackerRequestOptions } from "~/models/shared/policy-tracker/zero-tracker-request-options.model";
import { ZeroTracker } from "~/models/shared/policy-tracker/zero-tracker.model";
import { HttpService } from "~/services/core/http.service";

@Injectable()
export class PolicyTrackerDetailService {
  constructor(private readonly _offsetClient: HttpService) { }
 
  private getZeroTrackers(options: ZeroTrackerRequestOptions): Observable<PagedList<ZeroTracker>> {
    const serviceUri = `/PolicyTracker/zeroTrackers`;
    return from(this._offsetClient.Post(serviceUri, options));
  }

  public async getZeroTrackerByCountry(countryId: number): Promise<PagedList<ZeroTracker>> {
    const options = new ZeroTrackerRequestOptions();
    options.Paging = {
        pageSize: 50,
        currentPage: 0
    };
    options.countryId = countryId;
    options.actorType = "Country";

    return await lastValueFrom(this.getZeroTrackers(options).pipe(map(x => x)));
  }

  public async getZeroTrackerByRegion(countryId: number, region: string): Promise<PagedList<ZeroTracker>> {
    const options = new ZeroTrackerRequestOptions();
    options.Paging = {
        pageSize: 50,
        currentPage: 0
    };
    options.countryId = countryId;
    options.name = region;

    return await lastValueFrom(this.getZeroTrackers(options).pipe(map(x => x)));
  }

  public async getZeroTrackerByName(name: string): Promise<PagedList<ZeroTracker>> {
    const options = new ZeroTrackerRequestOptions();
    options.Paging = {
        pageSize: 50,
        currentPage: 0
    };
    options.name = name;

    return await lastValueFrom(this.getZeroTrackers(options).pipe(map(x => x)));
  }
}