import { CountryRisk } from "./country-risk";

export enum CountryIdEnum {
  USA = 840,
  Canada = 124,
  China = 156,
  France = 250,
  Japan = 392,
  Korea = 410,
  NewZealand = 554,
  Russia = 643,
  Vietnam = 704,
  Spain = 724,
  Taiwan = 158,
  Mexico = 484,
  Portugal = 620
}

export class CountryProvinceNames {
  public static readonly Catalonia = "Catalonia";
  public static readonly Sakhalin = "Sakhalin";
}
export interface Country {
  name:string;
  id: number;
  alpha2Code: string;
  alpha3Code: string;
  regionCode: number;
  regionName: string;
  latitude: number;
  longitude: number;
  countryRisk: CountryRisk;
}