export class AppConfig {
    public environment: string = "";
    public tenantId : string= "";
    public clientId: string = "";
    public baseUrl: string = "";
    public apiUrl: string = "";
    public metaRegistryExportUrl: string = "";
    public mapsUrl: string = "";
    public openStreetMapUrl: string = "";
    public portfolio: string = "";
    public signpost: string = "";
    public market: string = "";
}
