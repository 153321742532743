import { AfterViewInit, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { AppComponent } from '~/app.component';

import { ProjectService } from '~/services/shared/projects/project.service';
import { ICvxProject } from '~/models/shared/cvxproject';

import { Tab } from '~/components/shared/tabs/tabs.component';

import { ProjectDetailInfoComponent } from '~/pages/projects/project-detail/info/project-detail-info.component';
import { ProjectDetailContactsComponent } from '~/pages/projects/project-detail/contacts/project-detail-contacts.component';
import { ProjectDetailLocationComponent } from '~/pages/projects/project-detail/location/project-detail-location.component';
import { ProjectDetailScreeningComponent } from '~/pages/projects/project-detail/screening/project-detail-screening.component';
import { ProjectDetailAgreementsComponent } from '~/pages/projects/project-detail/agreements/project-detail-agreements.component';
import { ProjectDetailCreditsComponent } from '~/pages/projects/project-detail/credits/project-detail-credits.component';
import { ProjectDetailForecastComponent } from '~/pages/projects/project-detail/forecast/project-detail-forecast.component';
import { ProjectDetailTechReviewComponent } from '~/pages/projects/project-detail/tech-review/project-detail-tech-review.component';
import { ProjectDetailRatingsComponent } from '~/pages/projects/project-detail/ratings/project-detail-ratings.component';

import { Observable, Subject, lastValueFrom, mergeMap, of, scan, take } from 'rxjs';
import { ICreditForecastEditEvent } from './forecast/model/credit-forecast-edit.event';
import { SaveDataEvent } from './models/save-data-event.model';
import CcmsServerError from '~/models/shared/errors/ccms-server-error';
import { UserPermissionsService } from '~/services/shared/user-permissions.service';
import { ConfirmPopupService } from '~/services/shared/confirm-popup/confirm-popup.service';
import { ProjectPriorityScoreCardComponent } from './priority-score-card/priority-score-card.component';
import { ProjectPortfolioService } from '~/services/shared/projects/project-portfolio.service';
import { ProjectsAuthorizer } from '~/models/shared/projects/projects-authorizer';
import { HttpService } from '~/services/core/http.service';
import { ProjectPortfolioLink } from '~/services/shared/projects/models/project-portfolio.model';

@Component({
    selector: 'project-detail',
    templateUrl: './project-detail.component.html',
    styleUrls: ['./project-detail.component.scss']
})

export class ProjectDetailComponent implements OnInit, AfterViewInit {
    public isEditing = false;
    public isLoading = true;
    public project: ICvxProject | null = null;
    public projectEdit: any = null;
    public activeTab: Tab | undefined;
    public urlParams: string = "";
    public message: string = "";
    public messageStyle: string = "";
    public messageTimer: number = 5; // Number of seconds to keep message on

    public showImage: boolean = false;
    public showMap: boolean = false;
    public location: string = "";
    public zoom: string = "4";
    public canEdit: boolean = false;
    public saveDisabled: boolean = false;

    private _portfolioIdSubject = new Subject<any>();
    public PortfolioId$ = this._portfolioIdSubject.asObservable();

    private _isEditSubject = new Subject<void>();
    isEdit$ = this._isEditSubject.pipe(
        scan((state) => !state, false)
    )

    private _isEditForecastSubject = new Subject<ICreditForecastEditEvent>();
    isEditForecast$ = this._isEditForecastSubject.asObservable();

    private _tabInfoFields: string[] = ['priority', 'cvxProjectType', 'product', 'investmentType', 'country', 'executionStartDate'];

    public tabs: Tab[] = [
        {
            title: "Project Info",
            type: <any>ProjectDetailInfoComponent,
            inputs: {
                'isEdit': this.isEditing,
                'saveData': false,
                'portfolioId': undefined
            },
            outputs: {
                'changeEvent': (event: any) => {
                    this.projectEdit = event;
                    this.saveDisabled = !this.projectService.isPartiallyValid(this.projectEdit, [...this._tabInfoFields, 'status', 'didNotPursueReason']);
                },
                'saveDataEvent': (event: boolean) => {
                    this.tabDataSaved(event);
                }
            }
        },
        {
            title: "Contacts",
            type: <any>ProjectDetailContactsComponent,
            inputs: {
                'isEdit': this.isEditing,
                'saveData': false,
                'portfolioId': undefined
            },
            outputs: {
                'changeEvent': (event: any) => {
                    this.projectEdit = event;
                    this.saveDisabled = !this.projectService.isPartiallyValid(this.projectEdit,[...this._tabInfoFields, 'contact', 'cvxProjectDeveloper']);
                },
                'saveDataEvent': (event: boolean) => {
                    this.tabDataSaved(event);
                }
            }
        },
        {
            title: "Screening",
            type: <any>ProjectDetailScreeningComponent,
            inputs: {
                'isEdit': this.isEditing,
                'saveData': false,
                'portfolioId': undefined
            },
            outputs: {
                'changeEvent': (event: any) => {
                    this.projectEdit = event;
                    this.saveDisabled = !this.projectService.isPartiallyValid(this.projectEdit, [...this._tabInfoFields]);
                },
                'messageEvent': (event: any) => {
                    this.setMessage(event);
                },
                'saveDataEvent': (event: SaveDataEvent) => {
                  if (!event.error) {
                    this.tabDataSaved(event);
                } else {
                    this.tabDataSaveError(event);
                }
                }
            }
        },
        {
            title: "Location",
            type: <any>ProjectDetailLocationComponent,
            inputs: {
                'isEdit': this.isEditing,
                'saveData': false,
                'portfolioId': undefined
            },
            outputs: {
                'changeEvent': (event: any) => {
                    this.projectEdit = event;
                    this.saveDisabled = !this.projectService.isPartiallyValid(this.projectEdit, [...this._tabInfoFields]);
                },
                'saveDataEvent': (event: boolean) => {
                    this.tabDataSaved(event);
                }
            }
        },
        {
            title: "Forecast",
            type: <any>ProjectDetailForecastComponent,
            inputs: {
                'editForecast': null,
                'saveData': false,
                'portfolioId': undefined
            },
            outputs: {
                'edit': (event: ICreditForecastEditEvent) => {
                    if (this.isEditing !== event.isEdit) {
                        this._isEditForecastSubject.next(event);
                    }
                },
                'changeEvent': (event: any) => {
                    this.projectEdit = event;
                    this.saveDisabled = !this.projectService.isPartiallyValid(this.projectEdit, [...this._tabInfoFields]);
                },
                'saveDataEvent': (event: SaveDataEvent) => {
                    if (!event.error) {
                        this.tabDataSaved(event);
                    } else {
                        this.tabDataSaveError(event);
                    }
                }
            }
        },
        {
            title: "Credits",
            type: <any>ProjectDetailCreditsComponent,
            inputs: {
                'isEdit': this.isEditing,
                'saveData': false,
                'portfolioId': undefined
            },
            outputs: {
                'saveError': () => {
                    // reset saveData state to false to allow triggering save retry
                    const thisTab = this.tabs.find(tab => tab.title === 'Credits');
                    if (thisTab) {
                        thisTab.inputs.saveData = false;
                    }
                },
                'changeEvent': (event: any) => {
                    this.projectEdit = event;
                    this.saveDisabled = !this.projectService.isPartiallyValid(this.projectEdit, [...this._tabInfoFields]);
                },
                'saveDataEvent': (event: boolean) => {
                    this.tabDataSaved(event);
                }
            },
        },
        {
            title: "Agreements",
            type: <any>ProjectDetailAgreementsComponent,
            inputs: {
                'isEdit': this.isEditing,
                'saveData': false,
                'portfolioId': undefined
            },
            outputs: {
                'changeEvent': (event: any) => {
                    this.projectEdit = event;
                    this.saveDisabled = !this.projectService.isPartiallyValid(this.projectEdit, [...this._tabInfoFields]);
                },
                'saveDataEvent': (event: boolean) => {
                    this.tabDataSaved(event);
                }
            },
        },
        {
            title: "Ratings",
            type: <any>ProjectDetailRatingsComponent,
            inputs: {
                'isEdit': this.isEditing,
                'saveData': false
            },
            outputs: {
                'changeEvent': (event: any) => {
                    this.projectEdit = event;
                },
                'saveDataEvent': (event: boolean) => {
                    this.tabDataSaved(event);
                }
            },
        },
        {
            title: 'Tech Review',
            type: <any>ProjectDetailTechReviewComponent,
            inputs: {
                'isEdit': this.isEditing,
                'saveData': false,
                'portfolioId': undefined
            },
            outputs: {
                'changeEvent': (event: any) => {
                    this.projectEdit = event;
                    this.saveDisabled = !this.projectService.isPartiallyValid(this.projectEdit, [...this._tabInfoFields, 'methodology']);
                },
                'saveDataEvent': (event: boolean) => {
                    this.tabDataSaved(event);
                }
            }
        },
        {
            title: 'Prioritization',
            type: <any>ProjectPriorityScoreCardComponent,
            inputs: {
                'isEdit': this.isEditing,
                'saveData': false,
                'portfolioId': undefined
            },
            outputs: {
                'changeEvent': (event: any) => {
                    this.projectEdit = event;
                    this.saveDisabled = !this.projectService.isPartiallyValid(this.projectEdit, [...this._tabInfoFields]);
                },
                'saveDataEvent': (event: SaveDataEvent) => {
                    if (!event.error) {
                        this.tabDataSaved(event);
                    } else {
                        this.tabDataSaveError(event);
                    }
                }
            }
        }
    ];

    public callbackLabel: string = "";
    private callback: string = "";
    private callbackParams: any;

    constructor(
        private app: AppComponent,
        private httpService: HttpService,
        private router: Router,
        private route: ActivatedRoute,
        private projectService: ProjectService,
        private userPermissionService: UserPermissionsService,
        private confirmPopupService: ConfirmPopupService,
        private readonly projectPortfolioService: ProjectPortfolioService,
    ) { }

    async ngOnInit() {
        let projectId: string = "";
        this.canEdit = await this.userPermissionService.canUpdateProjects();

        this.route.queryParams.subscribe((params: any) => {
            projectId = (params?.id) ? params.id : window.history.state.projectId;

            if (params?.tab) {
                this.activeTab = this.tabs.find((tab: Tab) => tab.title === params.tab);
            }
        });

        if (window.history.state.callback) {
            this.callbackLabel = window.history.state.callbackLabel ?? null;
            this.callback = window.history.state.callback ?? null;
            this.callbackParams = window.history.state.callbackParams ?? null;
        } else {
            this.callbackLabel = "Back to Projects";
            this.callback = "/projects";
        }

        if (!projectId) {
            this.back();
        } else {
            await this.loadProject(+projectId);
        }

        this.isLoading = false;
    }

    ngAfterViewInit(): void {
        this.isEdit$.subscribe(value => {
            this.isEditing = value;
            this.updateIsEditOnTabs(value);
            this.updateIsEditForecastOnTabs({ isEdit: value, forecasts: null });
        });

        this.isEditForecast$.subscribe(value => {
            this.isEditing = value.isEdit;
            this.toggleEditMode();
            this.updateIsEditOnTabs(value.isEdit);
            this.updateIsEditForecastOnTabs(value);
        });

        this.PortfolioId$.subscribe(value => {
            this.updatePortfolioIdOnTabs(value);
        });
    }

    public showMessage(event: any) {
        this.message = event;
    }

    public back() {
        this.router.navigate([this.callback], { queryParams: this.callbackParams });
    }

    public toggleEditMode() {
        this._isEditSubject.next();
    }

    confirmTabChange(event: any): Observable<boolean> {
      return new Observable(observer => {
        if (!this.isEditing) {
          observer.next(true);
          observer.complete();
        } else {
          this.confirmPopupService.open(`You may have unsaved changes on the '${event.from.title}' tab which will be lost. Are you sure you want to change tabs?`).subscribe(result => {
            if (result === 'ok') {
              observer.next(true); // Allow the tab change
            } else {
              observer.next(false); // Prevent the tab change
            }
            observer.complete();
            this.confirmPopupService.close();
          });
        }
      });
    }

    public async onTabChanged(event: any) {
        if (this.isEditing) {
          this.toggleEditMode();
        }

        if (event?.to) {
          this.activeTab = event?.to;
        }
    }

    public async save() {
        this.isLoading = true;

        if (this.projectEdit) {
            if (!this.projectEdit.isGlobalLocation
                && this.projectEdit.location?.addressCountry?.alpha3Code) {
                this.projectEdit.location.addressCountry = { alpha3Code: this.projectEdit.location.addressCountry.alpha3Code };
            }
            this.projectEdit.firstYearOfIssuance = parseInt(this.projectEdit.firstYearOfIssuance);

            let project = this.projectEdit as unknown as ICvxProject;

            try {
                this.saveProjectPortfolio(project).then(() => {
                    this.projectService
                        .saveProject(project)
                        .then((result) => {
                            if (result.warnings && result.warnings.length > 0) {
                                this.setMessage({ text: `Project "${project.name}" saved with warning.<br>${result.warnings.join(" ")}`, style: "color: white; background-color: rgb(118, 146, 49); height: 60px;", timer: 20 });
                            }

                            return result;
                        })
                        .then(async (result) => {
                            this.updateSaveDataOnTabs(true);
                            if (!result.warnings || result.warnings.length <= 0) {
                                this.setMessage({ text: `Project "${project.name}" has been saved.`, style: "color: white; background-color: rgb(118, 146, 49);" });
                            }
                        });
                });
                
            } catch (error: any) {
                this.updateSaveDataOnTabs(false);
                const ccmsError = new CcmsServerError(error);
                this.app.displayAlert("Error saving Project Data", ccmsError.errors);
            }
        } else {
            this.updateSaveDataOnTabs(true);
        }

        this.isLoading = false;
    }

    public setMessage(event: any) {
        this.message = event.text;
        this.messageStyle = event.style;
        this.messageTimer = event.timer ? event.timer : 5;
    }

    private async saveProjectPortfolio(project: ICvxProject) : Promise<void>{
        
        const projectId = project.id;
        const originalProject = this.project!;
        const oldPortfolioId = originalProject.projectPortfolioId;
        const newPortfolioId = project.projectPortfolioId;
        if (newPortfolioId == 'NEW') {
            return this.addProjectPortfolio(projectId).then((link) => {
                if (this.project) {
                    this.project.projectPortfolioId = link.portfolioId;
                }
                this._portfolioIdSubject.next(link.portfolioId);
            });
        }
        else if (newPortfolioId) {
            return this.linkProjectPortfolio(projectId, newPortfolioId).then((link) => {
                if (this.project) {
                    this.project.projectPortfolioId = link.portfolioId;
                }
                this._portfolioIdSubject.next(link.portfolioId);
            });;
        }
        else if (oldPortfolioId && !newPortfolioId) {
            return this.unlinkProjectPortfolio(oldPortfolioId).then(() => {
                this._portfolioIdSubject.next(undefined);
            });;
        }
        return Promise.resolve();
    }

    private addProjectPortfolio(projectId: number): Promise<ProjectPortfolioLink> {
        const originalProject = this.project!;
		const portfolioService = this.projectPortfolioService;
        const oldPortfolioId = originalProject.projectPortfolioId;
        let result: Observable<ProjectPortfolioLink>;
		if (oldPortfolioId) {
			result = portfolioService.unlinkProjectPortfolio(oldPortfolioId)
				.pipe(
					take(1), 
					mergeMap(() => portfolioService.linkProjectPortfolio(projectId, '')));
		}
		else {
			result = portfolioService.linkProjectPortfolio(projectId, '');
		}
        return lastValueFrom(result);
    }

    private linkProjectPortfolio(projectId: number, newPortfolioId: string): Promise<ProjectPortfolioLink> {
        const originalProject = this.project!;
		const portfolioService = this.projectPortfolioService;
        const oldPortfolioId = originalProject.projectPortfolioId;
        let result: Observable<ProjectPortfolioLink>;
        if (oldPortfolioId) {
			if (newPortfolioId == oldPortfolioId) {
                result = of({
                    projectId: projectId,
                    portfolioId: oldPortfolioId
                });
			}
			else {
                result = portfolioService.unlinkProjectPortfolio(oldPortfolioId)
                    .pipe(
                        take(1), 
                        mergeMap(() => portfolioService.linkProjectPortfolio(projectId, newPortfolioId)));
            }
		}
		else {
			result = portfolioService.linkProjectPortfolio(projectId, newPortfolioId);
		}
        return lastValueFrom(result);
    }

    private unlinkProjectPortfolio(portfolioId: string) {
        return lastValueFrom(this.projectPortfolioService.unlinkProjectPortfolio(portfolioId));
    }

    private async tabDataSaved(event: any) {
        await this.loadProject(this.project?.id);
        if (event.saveButtonFlag !== undefined) {
            this.updateSaveDataOnTabs(event.saveButtonFlag);
        } else {
            this.updateSaveDataOnTabs(event);
        }
        this.toggleEditMode();
    }

    private tabDataSaveError(event: SaveDataEvent) {
        this.setMessage({ text: `Error saving Project: "${event.error}".`, style: "color: white; background-color: rgb(151, 0, 46);" });
        this.updateSaveDataOnTabs(event.saveButtonFlag);
    }

    private async loadProject(projectId: number | undefined) {
        if (!projectId) {
            return;
        }
        this.projectEdit = null;

        await this.CheckAccess(projectId);
        try {
            await this.projectService.getProject(projectId).then((data: any) => {
                this.project = data;

                this.tabs.forEach((tab: any) => {
                    tab.inputs = tab.inputs || {};
                    tab.inputs.project = this.project;
                });

                this._portfolioIdSubject.next(this.project?.projectPortfolioId);

                this.urlParams = `id=${this.project!.id}`;
            });
        } catch (error: any) {
            console.log(error);
        }
    }

    public async CheckAccess(projectId: number) {
        const authorized = await (new ProjectsAuthorizer()).isEntityAuthorized(this.httpService, projectId);

        if (!authorized) {
            await this.router.navigate(["/access"], { state: { type: "project" } });
        }
    }

    private updateSaveDataOnTabs(value: boolean) {
        this.tabs.filter(tab => tab.inputs?.hasOwnProperty('saveData')).forEach(tab => {
            tab.inputs.saveData = value;
        });
    }

    private updateIsEditOnTabs(isEdit: boolean) {
        this.tabs.filter(tab => tab.inputs?.hasOwnProperty('isEdit')).forEach(tab => {
            tab.inputs.isEdit = isEdit;
        });
    }

    private updateIsEditForecastOnTabs(event: ICreditForecastEditEvent) {
        this.tabs.filter(tab => tab.inputs?.hasOwnProperty('editForecast')).forEach(tab => {
            tab.inputs.editForecast = event;
        });
    }

    private updatePortfolioIdOnTabs(portfolioId: string) {
        this.tabs.filter(tab => tab.inputs?.hasOwnProperty('portfolioId')).forEach(tab => {
            tab.inputs.portfolioId = portfolioId;
        });
    }
}
