import { Injectable } from "@angular/core";
import { Observable, from } from "rxjs";
import { PlattsPrice } from "~/models/shared/markets/platts-price.model";
import { HttpService } from "~/services/core/http.service";

@Injectable({
  providedIn: 'root',
})
export class PlattsPriceService {
    constructor(private readonly _httpClient: HttpService) { }
   
    public getPrices(code: string): Observable<PlattsPrice[]> {
        const priceUrl = `/plattsprice/prices?plattsCode=${code}`;
        return from(this._httpClient.Get(priceUrl));
    }

    public getPricesByDateRange(code: string, start: Date, end?: Date): Observable<PlattsPrice[]> {
        const priceUrl = `/plattsprice/pricesbydaterange?plattsCode=${code}&start=${start}&end=${end}`;
        return from(this._httpClient.Get(priceUrl));
    }

}