<div class="content">
    <div class="data">
        <ccms-credit-issuance-table #issuanceTable
            [data]="creditInventories$ | async"
            [isLoading]="isLoading$ | async"
            [isEdit]="isEdit$ | async"
            (rowChanged)="onIssuanceTableRowChanged($event)"
            (transferCredits)="transferCredits($event)"
            (retireCredits)="retireCredits($event)"
            (rowAdded)="onIssuanceTableRowAdded($event)"
            (undoRowAdded)="onIssuanceTableUndoRowAdded($event)"
            (loadData)="loadData()">
        </ccms-credit-issuance-table>
    </div>
    <div class="info">
      <tab-info [project]="project$ | async" [isEdit]="isEdit$ | async" [portfolioId]="portfolioId" (changeEvent)="infoChange($event)"></tab-info>
        <br />

        <div style="position: relative;">
        <table class="table-x">
            <caption style="display: none;">Article 6</caption>
            <tbody>
                <tr>
                    <th scope="col">Article 6 Elgible</th>
                    <td *ngIf="!isEdit">{{ project.isArticle6Eligible ? 'Yes' : 'No' }}</td>
                    <td *ngIf="isEdit" >
                        <input type="checkbox" id="article6" [checked]="project.isArticle6Eligible" (change)="eligilibityChange($event)" />
                    </td>
                </tr>
            </tbody>
        </table>
        </div>
    </div>
</div>
