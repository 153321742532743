<div class="content">
  <dialog-cancel-button (onCancel)="cancel()"></dialog-cancel-button>
  <ng-container *ngIf="error" class="alert-container">
    <ccms-alert [type]="AT.WARNING" [message]="getError()"></ccms-alert>
  </ng-container>
  <form [formGroup]="form" *ngIf="form" (ngSubmit)="onUpload()">
    <div class="header">
      Import Credit Forecasts
    </div>
    <section>
      <label>
        <div class="labels">Import file (xlsx)</div>
        <mat-hint>
            The following columns can be used in the import file: Year, Fixed Price, Discount Rate, Price Floor, Obligated Credits, Non-Obligated Credits, Prepayment, Opex, Discretionary Spend, and Non-Discretionary Spend.
            The import file must have at least a year column. 
            Download import file template <a download="CCMS-Yearly-Credit-Forecasts-Template.xlsx" target="_blank" href="assets/templates/CCMS-Yearly-Credit-Forecasts-Template.xlsx">here</a>.
        </mat-hint>
      </label>
      <div>
        <div *ngIf="!fileIsValid && file" class="text-danger">File type must be xlsx or xlsm.</div>
        <input class="element" type="file" formControlName="formFile">
      </div>
      <label>
        <div class="labels">Sheet</div>
        <mat-hint>
          Specify the position (e.g. 1) or name (e.g. Sheet1) of the worksheet which contains the credit forecasts data.
        </mat-hint>
      </label>
      <div>
        <input class="element" formControlName="sheet" matInput>
      </div>
      <div class="save-button">
        <button [disabled]="!form.valid || isLoading">
          Import
        </button>
      </div>
    </section>
  </form>
  <sdk-loading [isLoading]="isLoading"></sdk-loading>
</div>
