import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { AppSettingsService } from '~/services/core/appsettings.service';
import { AppSettings } from '~/models/core/appsettings';

@Injectable({
    providedIn: 'root'
})
export class AuthGuard {
    constructor(
        private router: Router,
        private appSettingsService: AppSettingsService,
    ) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        this.appSettingsService.getSettings().then(async (appSettings: AppSettings) => {
            if (!appSettings.user) {
                if (!sessionStorage.getItem("_redirect")) {
                    sessionStorage.setItem("_redirect", state.url);
                }

                await this.router.navigate(["/auth"]);
            } else {
                let access: boolean = false;
                let config: any = route.routeConfig;

                if (config?.roles && config.roles.length > 0) {
                    let results = config.roles.filter((role: any) => appSettings.roles.some((user: any) => role.includes(user)));

                    if (results.length > 0) {
                        access = true;
                    }
                } else {
                    access = true;
                }

                if (!access) {
                    this.router.navigate(["/access"], { state: { type: "page" } });
                }
            }
        });
    }
}
