import { Injectable } from "@angular/core";
import { Observable, ReplaySubject, from, map, shareReplay, take } from "rxjs";
import { HttpService } from "../core/http.service";
import { CreditsByCountry } from "~/models/shared/credits-by-country";

@Injectable()
export class CountryCreditsDataService {
    constructor(private readonly _httpClient: HttpService) { 
      this.countries$ = new ReplaySubject(1);
      this.hasDataLoaded = false;
    }

    countries$: ReplaySubject<any>;
    hasDataLoaded: boolean;
    
    private cache$!: Observable<Array<CreditsByCountry>>;
  
    private getCreditsForCountries(): Observable<Array<CreditsByCountry>> {
      let creditsUrl = `/MetaRegistry/CreditsByCountry`;
  
      return from(this._httpClient.Get(creditsUrl));
    }

    private getCreditsForCountriesCached(): Observable<Array<CreditsByCountry>> {  
        if (!this.cache$) {
            this.cache$ = this.getCreditsForCountries().pipe(
              shareReplay(1)
            );
          }
      
          return this.cache$;
    }

    public getCreditsByCountry(countryId: number): Promise<CreditsByCountry> {
      return new Promise((resolve, reject) => {
        this.getCreditsForCountriesCached().pipe(
          take(1),
          map((creditsByCountry: Array<CreditsByCountry>) => 
            this.processData(creditsByCountry, countryId)
          )
        ).subscribe({
          next: data => {
            if (data) {
              resolve(data);
            } else {
              reject(`No data found for country ID: ${countryId}`);
            }
          },
          error: error => reject(error)
        });
      });
    }
    
    private processData(creditsByCountry, countryId: number) {
      const credits = creditsByCountry.reduce((accumulator, d) => {
        let summary = { 
          country: d.country,
          creditsRetired: d.creditsRetired,
          creditsRemaining: d.creditsRemaining,
          creditsIssued: d.creditsIssued,
          projectCount: d.projectCount
        }

        accumulator[d.country.id] = summary;
        return accumulator;
      }, {});

      return credits[countryId];
    }
  }
