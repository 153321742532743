import { Injectable } from '@angular/core';
import { HttpClient, HttpBackend } from '@angular/common/http';
import { lastValueFrom } from 'rxjs';

import { AppConfig } from '~/models/core/appconfig';

const FILE = "assets/config.json";

@Injectable({
    providedIn: 'root'
})
export class AppConfigService {
    private config: AppConfig = new AppConfig();
    private http: HttpClient;

    constructor(private httpHandler: HttpBackend) {
        this.http = new HttpClient(this.httpHandler);
    }

    public async init(): Promise<boolean> {
      try {
          const data: any = await lastValueFrom(this.http.get(FILE));
          this.config = data;
          return true;
      } catch (error) {
          return false;
      }
    }
  
    public getConfig(key?: string | Array<string>): any {
        if (!key || (Array.isArray(key) && !key[0])) {
            return this.config;
        }

        if (!Array.isArray(key)) {
            key = key.split('.');
        }

        return key.reduce((acc: any, current: string) => acc?.[current], this.config);
    }
}