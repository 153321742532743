import { feature } from 'topojson-client';
import { CountryIdEnum } from '~/models/shared/country';
import { FeatureType } from '~/models/shared/policy-tracker/feature-type.enum';

function getChinaFeatures(china, policies) {
  let policyByRegion = {};

  china.objects.CHN_adm1.geometries.forEach(geometry => {
      const region = geometry.properties.NAME_1;
      const policy = filterPoliciesByRegion(policies, region);

      if (policy.length > 0) {
          policyByRegion[region] = policy;
      }
  });

  const chinaFeatures = feature(china, china.objects.CHN_adm1);
  chinaFeatures.features.forEach(d => {
      d.id = "China";  // replace with the actual value from CountryIdEnum.China
      const policy = policyByRegion[d.properties.NAME_1];
      if (policy) {
          Object.assign(d.properties, {
              name: d.properties.NAME_1,
              regionType: "ChinaProvinces",  // replace with the actual value from FeatureType.ChinaProvinces
              policies: policy
          });
      } else {
          Object.assign(d.properties, {
              name: d.properties.NAME_1,
              regionType: "ChinaProvinces"  // replace with the actual value from FeatureType.ChinaProvinces
          });
      }
  });

  return chinaFeatures;
}

function getCountryFeatures(tsvData, countries, policies) {
  const countriesById = tsvData.reduce((accumulator, d) => {
    let summary = { 
      name: d.name,
      regionType: FeatureType.Country,
    }

    accumulator[Number(d.iso_n3)] = summary;
    return accumulator;
  }, {});

  let policyByRegion = {};

  countries.objects.countries.geometries.forEach(geometry => {
    const id = Number(geometry.id);
    const country = countriesById[id];
    if (country) {
      let region = getCountryNameForGeoJson(country.name);
      const policy = policies.filter(x => x.country.id == id || x.regions.includes(region));
      if (policy.length > 0) {
        policyByRegion[region] = policy;
      }
    }
  });

  const countryFeatures = feature(countries, countries.objects.countries);
  countryFeatures.features = countryFeatures.features.filter(x => {
    const id = Number(x.id);
    const country = countriesById[id];
    
    // Return true unless both conditions are met, in which case return false
    return !(country.name === "Australia" && x.geometry.coordinates.length == 1);
  });
  
  
  countryFeatures.features.forEach(d => {
    const id = Number(d.id);
    const country = countriesById[id];
    let policy;
    if (country) {
      let name = getCountryNameForGeoJson(country.name);
      policy = policyByRegion[name];
    }
    if (policy) {
      Object.assign(d.properties, 
      { 
        name: country.name,
        regionType: FeatureType.Country,
        policies: policy,
        id: id
      });
    } else if (country) {
      Object.assign(d.properties, 
        { 
          name: country.name,
          regionType: FeatureType.Country,
          id: id
        });
    }
  });

  return countryFeatures;
}

function getUsaStateFeatures(states, policies) {
  let policyByRegion = {};

  states.objects.states.geometries.forEach(geometry => {
    const region = geometry.properties.name;
    const policy = filterPoliciesByRegion(policies, region);

    if (policy.length > 0) {
      policyByRegion[region] = policy;
    }
  });

  const stateFeatures = feature(states, states.objects.states);
  stateFeatures.features.forEach(d => {
    d.id = CountryIdEnum.USA.toString();
    const policy = policyByRegion[d.properties.name];
    if (policy) {
      Object.assign(d.properties, 
      { 
        regionType: FeatureType.UsaState,
        policies: policy,
        id: CountryIdEnum.USA,
        name: d.properties.name
      });
    }
  });

  return stateFeatures;
}

function filterPoliciesByRegion(data, region) {
  return data.filter(function(item) {
      return item.regions.includes(region);
  });
}

function getCountryNameForGeoJson(name) {
  if (name == "Vietnam") {
    return "Viet Nam";
  }
  if (name == "Côte d'Ivoire") {
    return "Cote d'Ivoire";
  }
  if (name == "Korea, Republic of") {
    return "Korea";
  }

  return name;
}

export { getChinaFeatures, getCountryFeatures, getUsaStateFeatures, filterPoliciesByRegion, getCountryNameForGeoJson };
