import { Component, Input } from "@angular/core";

@Component({
    selector: 'ccms-project-priority-summary',
    templateUrl: './project-priority-summary.component.html',
    styleUrls: ['./project-priority-summary.component.scss']
})
export class ProjectPrioritySummaryComponent {
    @Input() adoPriority!: number;
    @Input() priority!: number;
    @Input() priorityWithEconomics!: number;
}