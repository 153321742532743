import { Component, ElementRef, EventEmitter, Input, OnChanges, Output, ViewChild } from '@angular/core';

import { SDKDataGridSettings } from '../../models/datagrid-settings';
import { SDKDataGridColumn } from '../../models/datagrid-column';

const SESSION_KEY = "__sdk-datagrid__";

@Component({
    selector: 'sdk-datagrid-settings-option',
    templateUrl: './settings-option.component.html',
    styleUrls: ['./settings-option.component.scss']
})

export class SDKDataGridSettingsOptionComponent implements OnChanges {
    @Input() settings: SDKDataGridSettings[] = [];
    @Input() columns: SDKDataGridColumn[] = [];
    @Output() closeEvent = new EventEmitter();
    @Output() saveEvent = new EventEmitter();
    @Output() applyEvent = new EventEmitter();

    public activeSetting: any = "";
    public name: string = "";
    public viewerText: string = "";
    public error: string = "";

    private activeIndex: number = 0;

    public ngOnChanges(_args: any) {
        this.initialze();
    }

    private initialze() {
        if (this.settings.length === 0) {
            let currentSetting: SDKDataGridSettings = {
                Name: "[Current Settings]",
                Data: {
                    showNote: false,
                    columnBadge: "",
                    filterBadge: "",
                    sortBadge: "",
                    formulaBadge: "",
                    chartBadge: "",
                    columns: this.columns,
                    filters: [],
                    sorts: [],
                    formulas: [],
                    chart: ""
                }
            }

            this.settings.push(currentSetting);
        }

        this.activeIndex = this.settings.findIndex((setting: SDKDataGridSettings) => setting.Name === "[Current Settings]");

        if (this.activeIndex === -1) {
            this.activeIndex = 0;
        }

        this.activeSetting = this.settings[this.activeIndex];
        this.name = this.activeSetting.Name;

        this.setViewerText();
    }

    public setName(event: any) {
        this.name = event.trim();
    }

    public save() {
        if (this.name !== "") {
            this.settings[this.activeIndex].Name = this.name;

            this.activeSetting = this.settings[this.activeIndex];

            let ndx = this.settings.findIndex((setting: any) => setting.Name === "[Current Settings]");

            if (ndx === -1) {
                let currentSetting: SDKDataGridSettings = {...this.settings[this.activeIndex]};

                currentSetting.Name = "[Current Settings]";
    
                this.settings.unshift(currentSetting);
            }
    
            this.saveEvent.emit(this.settings);

            this.setViewerText();
        }
    }

    public remove() {
        if (this.activeSetting.Name !== "[Current Settings]") {
            if (confirm(`Delete '${this.name}' settings... Are you sure?`)) {
                this.settings.splice(this.activeIndex, 1);
    
                this.saveEvent.emit(this.settings);
    
                this.initialze();
            }
        }
    }

    public load(event: any) {
        this.activeIndex = event.target.value
        this.activeSetting = this.settings[this.activeIndex];
        this.name = this.activeSetting.Name;

        this.validateSettings();
        this.setViewerText();
    }

    public apply() {
        this.applyEvent.emit(this.activeSetting);
    }

    public close() {
        this.closeEvent.emit();
    }

    private setViewerText() {
        setTimeout(() => {
            this.viewerText = `Columns:<br />`;

            this.activeSetting.Data.columns.forEach((column: SDKDataGridColumn) => {
                this.viewerText += `&nbsp;&nbsp;- ${(column.FriendlyName !== "") ? column.FriendlyName : (column.DisplayName !== "") ? column.DisplayName : column.Name} (${column.isVisible ? "visible" : "hidden"}) ${column.notes?.startsWith("<--") ? column.notes : ''}<br />`;
            });

            this.viewerText += `<br />Sorts:<br />`;

            this.activeSetting.Data.sorts.forEach((sort: any) => {
                this.viewerText += `&nbsp;&nbsp;- ${(sort.DisplayName !== "") ? sort.DisplayName : sort.Name} (${sort.Sort})<br />`;
            });

            this.viewerText += `<br />Filters:<br />`;

            this.activeSetting.Data.filters.forEach((filter: any) => {
                if (filter.Type === "Keyword") {
                    this.viewerText += `&nbsp;&nbsp;- KEYWORD search: ${filter.Value}<br />`;
                } else if (filter.Type === "Any") {
                    this.viewerText += `&nbsp;&nbsp;- ANY ${(filter.DisplayName !== "") ? filter.DisplayName : filter.Name}<br />`;
                } else {
                    this.viewerText += `&nbsp;&nbsp;- ${(filter.DisplayName !== "") ? filter.DisplayName : filter.Name} (${filter.Operation}): ${filter.Value}<br />`;
                }
            });

            this.viewerText += `<br />Formulas:<br />`;

            this.activeSetting.Data.formulas.forEach((formula: any) => {
                let column = this.activeSetting.Data.columns.filter((column: SDKDataGridColumn) => column.Name === formula.Name)[0];
                this.viewerText += `&nbsp;&nbsp;- ${(column.FriendlyName !== "") ? column.FriendlyName : (column.DisplayName !== "") ? column.DisplayName : column.Name} (${formula.Operation})<br />`;
            });
        }, 10);
    }

    private validateSettings() {
        let tmp = this.activeSetting.Data.columns.slice(0);
        let issue: boolean = false;

        this.activeSetting.Data.columns.forEach((column: SDKDataGridColumn) => {
            let ndx = this.columns.findIndex((c: SDKDataGridColumn) => c.Name === column.Name);

            if (ndx === -1) {
                issue = true;
                column.notes = '<-- Does NOT exist!';
            }
        });

        if (issue) {
            this.error = "This is an issue with these settings!";
        }

        if (!issue) {
            if (this.activeSetting.Data.columns.length !== this.columns.length) {
                this.error = "This is an issue with these settings! Missing columns.";
            }
        }
    }
}
