import { Component, Input, TemplateRef, ViewChild } from '@angular/core';
import { DeveloperProfile } from "~/services/shared/developers-screening/models/developer-summary";
import { SDKDataGridDataset, SDKDataGridOptions, Filters, SDKDataGridSettings } from 'sdk-datagrid';
import { SettingsGrid } from '~/services/shared/grid/models/settings-grid.model';
import { GridHandlerService } from '~/services/shared/grid/grid-handler.service';

import { BaseComponent } from '~/pages/base.component';

@Component({
    selector: 'developer-projects',
    templateUrl: './developer-projects.component.html',
    styleUrls: ['./developer-projects.component.scss']
})
export class DeveloperProjectsComponent extends BaseComponent implements SettingsGrid {
    @ViewChild('metaRegistryTemplate') metaRegistryTemplate!: TemplateRef<any>;
    @ViewChild('ratingsTemplate') ratingsTemplate!: TemplateRef<any>;

    @Input() developer!: DeveloperProfile;

    public datasets: SDKDataGridDataset[] = [
        {
            Title: "Developers Screening",
            DbName: "vwMyData",
        }
    ];

    public columns: any[] = [
        { Name: "id", DisplayName: "Id", isVisible: true },
        { Name: "status", DisplayName: "Status", isVisible: true },
        { Name: "name", DisplayName: "Name", isVisible: true, dataTemplate: () => this.metaRegistryTemplate },
        { Name: "registry", DisplayName: "Registry", isVisible: true },
        { Name: "scope", DisplayName: "Scope", isVisible: true },
        { Name: "protocol", DisplayName: "Protocol", isVisible: true },
        { Name: "country", DisplayName: "Country", isVisible: true },
        { Name: "beZeroRating", DisplayName: "Ratings", isVisible: true, dataTemplate: () => this.ratingsTemplate },
    ];

    // Settings grid interface Implementation:
    public uniqueIdentifier = "developers-screening.developer-detail.projects.developer-projects";
    public allSettings: SDKDataGridSettings[] = [];
    public errorHanlder = (message, error) => {
        console.error(`${message} ${error?.message}`);
    }

    public gridOptions: SDKDataGridOptions = {
        settings: true,
        columnSettings: true,
        filtering: true,
        sorting: true,
        formulas: true,
        charts: false,
        export: true,
        expandableRows: false,
        selectableRows: false,
        autoClosePanel: true
	};

    public filterTypes: any = [Filters.Equals, Filters.NotEquals, Filters.Contains ];

    public data: any;

    public callbackLabel: string = "";
    public activeView: string = "vwMyData";

    constructor(
        private gridHandlerService: GridHandlerService
    ) {
        super();
    }

    public async ngOnInit() {
        await super.ngOnInit();
        this.gridHandlerService.loadSettings(this);
    }

    public async loadData(event: any = null) {
        this.isLoading = true;
        this.resetVariables();

        this.data = JSON.parse(JSON.stringify(this.developer.projects));

        this.isLoading = false;
    }

    public gotoProject(id: any) {
        if (id) {
            window.open("/metaregistry/detail?id=" + id, "_blank");
        }
    }

    public saveSettings(event: SDKDataGridSettings[]) {
        this.gridHandlerService.saveSettings(this, event);
    }

    private resetVariables() {
      this.data = "";
  }
}