import { CarbonMarketStatus } from "./carbon-market-status";
import { CarbonMarketType } from "./carbon-market-type";
import { JurisdictionType } from "./jurisdiction-type";


export class PolicyTrackerQuery {
  constructor() {
    this.marketTypes = [];
    this.marketStatuses = [];
    this.jurisdictionType = [];
    this.article6 = false;
  }

  public marketTypes: CarbonMarketType[];
  public marketStatuses: CarbonMarketStatus[];
  public jurisdictionType: JurisdictionType[];

  public article6: boolean;
}