<div class="content">
    <div class="data">
        <table class="table-x">
            <caption style="display: none;">Tech</caption>
            <tbody>
                <tr>
                    <th scope="col">CNE Technical Lead</th>

                    <td>
                        <user-selection [entity]="'CvxProject'" [field]="'technicalLead'" (userSelectedEvent)="userSelectionChange('technicalLead', $event)"></user-selection>
                    </td>
                </tr>
                <tr>
                    <th scope="col">External Technical Resource</th>
                
                    <td *ngIf="true"><sdk-textbox [value]="project.externalTechResource || ''" width="100%"
                            (changeCallBackEvent)="infoChange({ field: 'externalTechResource', value: $event })"></sdk-textbox>
                    </td>
                </tr>
                <tr>
                    <th scope="col">Status</th>

                    <td *ngIf="true">
                        <select (change)="setStatus($event)">
                            <option value=""
                                [selected]="!project.technicalReviewStatus || project.technicalReviewStatus.name === ''">
                                ...</option>

                            <ng-container *ngFor="let status of statusList">
                                <option [value]="status | json"
                                    [selected]="project.technicalReviewStatus?.id === status.id">{{ status.name }}
                                </option>
                            </ng-container>
                        </select>
                    </td>
                </tr>
                <tr>
                    <th scope="col">Recommendation</th>

                    <td *ngIf="true">
                        <select (change)="setRecommendation($event)">
                            <option value=""
                                [selected]="!project.technicalReviewRecommendation || project.technicalReviewRecommendation.name === ''">
                                ...</option>

                            <ng-container *ngFor="let recommendation of recommendationList">
                                <option [value]="recommendation | json"
                                    [selected]="project.technicalReviewRecommendation?.id === recommendation.id">{{
                                    recommendation.name }}</option>
                            </ng-container>
                        </select>
                    </td>
                </tr>
                <tr>
                    <th scope="col">Last Review Date</th>

                    <td *ngIf="true">
                        <sdk-textbox [value]="displayDate()" validCharacters="calendar" pattern="MM/DD/YYYY"
                            (changeCallBackEvent)="setDate({ field: 'technicalReviewCompletionDate', value: $event })"></sdk-textbox>
                    </td>
                </tr>
                <tr>
                    <th scope="col">Notes</th>

                    <td *ngIf="true"><textarea class="edit" rows="5" [value]="project.technicalReviewNotes || ''"
                            (input)="setNotes('technicalReviewNotes', $event)"></textarea></td>
                </tr>
                <tr>
                    <th scope="col">Eligibility</th>

                    <td><textarea class="edit" rows="5" [value]="project?.eligibility || ''"
                            (input)="setNotes('eligibility', $event)"></textarea></td>
                </tr>
                <tr>
                    <th scope="col">Carbon</th>
                    <td><textarea class="edit" rows="5" [value]="project?.carbon || ''"
                            (input)="setNotes('carbon', $event)"></textarea></td>
                </tr>
                <tr>
                    <th scope="col">Integrity</th>

                    <td><textarea class="edit" rows="5" [value]="project?.integrity || ''"
                            (input)="setNotes('integrity', $event)"></textarea></td>
                </tr>
                <tr>
                    <th scope="col">Feasibility</th>

                    <td><textarea class="edit" rows="5" [value]="project?.feasibility || ''"
                            (input)="setNotes('feasibility', $event)"></textarea></td>
                </tr>
            </tbody>
        </table>

        <sdk-loading [isLoading]="isLoading"></sdk-loading>
    </div>
    <div class="info">

    </div>
</div>