import { Component, Input } from '@angular/core';
import { geoMercator, geoPath, select } from 'd3';
import { CountryIdEnum } from '~/models/shared/country';
import { CarbonMarketStatus } from '~/models/shared/policy-tracker/carbon-market-status';
import { JurisdictionTypeSubnational } from '~/models/shared/policy-tracker/jurisdiction-type';
import { PolicyTracker } from '~/models/shared/policy-tracker/policy-tracker';
import { CountriesGeoService } from '~/services/shared/policy-tracker/countries-geo.service';
import { MarketStatusService } from '~/services/shared/policy-tracker/market-status.service';
import { PolicyTrackerService } from '~/services/shared/policy-tracker/policy-tracker.service';

@Component({
  selector: 'ccms-market-focus',
  templateUrl: './market-focus.component.html',
  styleUrls: ['./market-focus.component.scss']
})
export class MarketFocusComponent {
  @Input() set market(value: PolicyTracker) {
    if (!value) {
      return;
    }
   
    this.marketInput = value;
    this.setStatus();
    this.createMap();
  }

  @Input() set countryId(value: number) {
    if (!value) {
      return;
    }
   
    this.countryIdInput = value;
    this.createMap();
  }
  
  @Input()
  radius!: number;

  private marketInput!: PolicyTracker;
  private countryIdInput!: number;
  private path;
  public marketStatus!: CarbonMarketStatus;

  constructor(
    private _marketStatusService: MarketStatusService,
    private _countriesGeoService: CountriesGeoService,
    private _policyTrackerService: PolicyTrackerService) { }

  private setStatus() {
    this.marketStatus = this.marketInput.marketStatus;
  }

  private async createMap() {
    const width = 400;
    const height = 400;
    const selector = "svg#countryfocus";
    this.setSVGAttributes(selector, width, height);
    
    const allPolicies = await this._policyTrackerService.getPolicyTrackerMarkets();
    let countries = await this._countriesGeoService.getCountries(allPolicies);
    let isFrance = false;
    let regions;
    let markets;

    if (this.countryIdInput) {
      markets = await this._policyTrackerService.getPolicyTrackersByCountry(this.countryIdInput);
      let countries2 = await this._countriesGeoService.getCountries(allPolicies);
      regions = this._countriesGeoService.filterByCountry(countries2, this.countryIdInput).features;
    } else {
      if (this.marketInput.jurisdictionType.id === JurisdictionTypeSubnational.getInstance().id) {
        countries = this._marketStatusService.filterNational(countries, true);
      }

      isFrance = this.marketInput.country.id == CountryIdEnum.France;
      regions = this._marketStatusService.filterCountriesByMarket(countries, this.marketInput, true);  
    }

    let filtered = countries.features;
  
    if (regions && regions.length > 0) {
      const projection = geoMercator()
        .scale(1)
        .translate([0, 0])

      if (regions[0].id == CountryIdEnum.NewZealand.toString() 
        || regions[0].id == CountryIdEnum.Russia.toString()
        || regions[0].id == CountryIdEnum.USA.toString()) {
        projection.rotate([180,0]);
      }
        
      this.path = geoPath().projection(projection);

      const b = this.getRegionBounds(regions, this.path, isFrance);
      const s = .85 / Math.max((b[1][0] - b[0][0]) / width, (b[1][1] - b[0][1]) / height);
      const t: [number, number] = [(width - s * (b[1][0] + b[0][0])) / 2, (height - s * (b[1][1] + b[0][1])) / 2];

      projection
        .scale(s)
        .translate(t);

      const svg = select(selector);
      const g = svg.append('g');
      
      g.selectAll('path')
        .data(filtered)
        .enter()
        .append('path')
        .attr('d', this.path)
        .attr('fill', (d: any) => {
          if (markets && d.properties.id === this.countryIdInput) {
            return this._marketStatusService.getNationalColor(markets);
          } else if (!markets) {
            return this._marketStatusService.getSubnationalColor(d.properties.policies, this.marketInput);
          } else {
            return this._marketStatusService.getNationalColor();
          }
        })
        .attr('stroke', 'black');
    }
  }

  private setSVGAttributes(selector: string, width: number, height: number) {
    const svg = document.querySelector(selector);  
    if (svg) {
      svg.setAttribute("preserveAspectRatio", "xMinYMin meet");
      svg.setAttribute("viewBox", [0, 0, width, height].join(" "));
    }
  }

  private getRegionBounds(features, path, isFrance: boolean): [[number, number], [number, number]] {
    let minX = Infinity,
        minY = Infinity,
        maxX = -Infinity,
        maxY = -Infinity;

    features.forEach(feature => {
      if (feature.id != CountryIdEnum.France.toString() || isFrance) {
        const bounds = path.bounds(feature);
        minX = Math.min(minX, bounds[0][0]);
        minY = Math.min(minY, bounds[0][1]);
        maxX = Math.max(maxX, bounds[1][0]);
        maxY = Math.max(maxY, bounds[1][1]);
      }
    });

    return [[minX, minY], [maxX, maxY]];
  }
}
