<div [ngClass]="env !== 'PROD' ? 'component' : ''">
	<div class="logo">
		<img [title]="title" [alt]="title" src="assets/images/logo.png" width="100%">
	</div>
	<div class="app">
		<div class="title">{{ title }}</div>
	</div>
	<div class="user">
		<div class="welcome">Welcome<br /><span>{{ user }}</span></div>
		<div class="profile">
			<img *ngIf="photo !== ''" [src]="photo" height="100%" [alt]="user">
			<div class="initials">{{ initials }}</div>
		</div>
	</div>
</div>
