import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { MetaRegistryService } from '~/services/shared/metaregistry.service';
import { MetaRegistry } from '~/models/shared/metaregistry';

import { Tab } from '~/components/shared/tabs/tabs.component';

import { MetaRegistryDetailInfoComponent } from './info/metaregistry-detail-info.component';
import { MetaRegistryDetailLocationComponent } from './location/metaregistry-detail-location.component';
import { MetaRegistryDetailCreditsComponent } from './credits/metaregistry-detail-credits.component';
import { MetaRegistryDetailRatingsComponent } from './ratings/metaregistry-detail-ratings.component';
import { MetaRegistryDetailPricesComponent } from './prices/metaregistry-detail-prices.component';

@Component({
    selector: 'metaregistry-detail',
    templateUrl: './metaregistry-detail.component.html',
    styleUrls: ['./metaregistry-detail.component.scss']
})

export class MetaRegistryDetailComponent implements OnInit {
    public isLoading = true;
    public project: MetaRegistry | null = null;
    public activeTab: Tab | undefined;
    public urlParams: string = "";
    public message: string = "";

    public showImage: boolean = false;
    public showMap: boolean = false;
    public location: string = "";
    public zoom: string = "4";

    public tabs: Tab[] = [
        {
            title: "Project Info",
            type: <any>MetaRegistryDetailInfoComponent,
        },
        {
            title: "Location",
            type: <any>MetaRegistryDetailLocationComponent,
        },
        {
            title: "Ratings",
            type: <any>MetaRegistryDetailRatingsComponent,
            inputs: {
                'projectId': this.project?.id
            },
        },
        {
            title: "Credits",
            type: <any>MetaRegistryDetailCreditsComponent,
        },
        {
            title: "Prices",
            type: <any>MetaRegistryDetailPricesComponent,
        }
    ];

    public callbackLabel: string = "";
    private callback: string = "";
    private callbackParams: any;

    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private metaRegistryService: MetaRegistryService
    ) { }

    async ngOnInit() {
      this.initializeCallback();
  
      await this.subscribeToRouteParams();
  
      this.isLoading = false;
    }
  
    private initializeCallback() {
      if (window.history.state.callback) {
          this.callbackLabel = window.history.state.callbackLabel ?? null;
          this.callback = window.history.state.callback ?? null;
          this.callbackParams = window.history.state.callbackParams ?? null;
      } else {
          this.callbackLabel = "Back to Registry";
          this.callback = "/metaregistry";
      }
    }
  
    private async subscribeToRouteParams() {
      this.route.queryParams.subscribe((params: any) => {
          this.handleQueryParams(params);
      });
    }
  
    private async handleQueryParams(params: any) {
      let projectId: string = params?.id ?? window.history.state.projectId;
  
      if (params?.tab) {
          this.setActiveTab(params.tab);
      }
  
      if (!projectId) {
          this.back();
      } else {
          await this.loadProject(projectId);
      }
    }
  
    private setActiveTab(tabName: string) {
      this.activeTab = this.tabs.find((tab: Tab) => tab.title === tabName);
    }
  
    private async loadProject(projectId: string) {
      try {
          const data: any = await this.metaRegistryService.getProject(projectId);
          this.project = data;
  
          this.updateTabsWithProjectData();
  
          this.urlParams = `id=${this.project!.id}`;
      } catch (error: any) {
          console.error('Error loading project:', error);
      }
    }
  
    private updateTabsWithProjectData() {
      this.tabs.forEach((tab: any) => {
          tab.inputs = tab.inputs || {};
  
          if (tab.title === "Ratings") {
              tab.inputs.projectId = this.project?.id;
          } else {
              tab.inputs.project = this.project;
          }
      });
    }  

    public showMessage(event: any) {
        this.message = event;
    }

    public back() {
        this.router.navigate([this.callback], { queryParams: this.callbackParams });
    }
}
