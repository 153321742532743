<div class="content">
    <div class="header">
        <div class="totals">
            <div class="total"><span>Total Acquired:</span>{{ totalAcquired | number }}</div>
            <div class="total"><span>Total Transferred:</span>{{ totalTransferred | number }}</div>
            <div class="total"><span>Balance:</span>{{ totalBalance | number }}</div>
        </div>
    </div>
    <div class="chart">
        <abu-chart [data]="transactionData"></abu-chart>
    </div>
    <div class="footnote">
        {{ footnote }}
    </div>
</div>
