<sdk-message [(message)]="message" [style]="messageStyle"></sdk-message>

<div class="content">
    <div class="header">
        <div class="title">{{ title }}</div>
    </div>
    
    <div class="data">
        <tabs [tabs]="tabs" 
        [activeTab]="activeTab" 
        [share]="true" 
        (messageEvent)="showMessage($event)"
        (tabChanged)="onTabChanged($event)"></tabs>
    </div>
</div>
<sdk-loading [isLoading]="isLoading"></sdk-loading>
