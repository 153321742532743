import { Injectable } from '@angular/core';
import * as XLSX from 'xlsx';

@Injectable()
export class ExcelExportService {
    public export(data: object[], fileName: string, columnNames?: string[] | null) {

        const workSheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(data);
        workSheet['!cols'] = this.setColumnsAutoWidth(data);
        if (columnNames != null) {
            XLSX.utils.sheet_add_aoa(workSheet, [columnNames], { origin: "A1" });
        }
        const workBook: XLSX.WorkBook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workBook, workSheet, 'Sheet1');
        XLSX.writeFile(workBook, `${fileName}.xlsx`);
    }

    private setColumnsAutoWidth(data: any[]): XLSX.ColInfo[] {
        return Object.keys(data[0]) // enumerate list of properties from first object
            .map(function (key) {
                return {
                    // get max property (name or value whichever is greater) length from array
                    width: Math.max.apply(Math, data.map(obj => obj[key] == null ? 0 : Math.max.apply(Math, [key.toString().length, obj[key].toString().length])))
                };
            });
    }
}