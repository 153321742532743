import { Injectable } from "@angular/core";
import { rgb } from "d3";
import { FeatureType } from "../../../models/shared/policy-tracker/feature-type.enum";
import { CountriesGeoService } from "./countries-geo.service";
import { PolicyTracker } from "~/models/shared/policy-tracker/policy-tracker";
import { CarbonMarketStatus, CarbonMarketStatusEnum, CarbonMarketStatusInforce, CarbonMarketStatusScheduled, CarbonMarketStatusUnderConsideration, CarbonMarketStatusUnderDevelopment } from "~/models/shared/policy-tracker/carbon-market-status";
import { JurisdictionTypeNational, JurisdictionTypeRegional } from "~/models/shared/policy-tracker/jurisdiction-type";
import { CountryIdEnum, CountryProvinceNames } from "~/models/shared/country";
import { getCountryNameForGeoJson } from './country-feature';

@Injectable()
export class MarketStatusService {
  constructor(private _countriesGeoService: CountriesGeoService) {
    this.noStatusColor = this.rgbToHex(rgb(230, 230, 230).toString());
  }

  private readonly noStatusColor;

  public getSubnationalColor(markets: PolicyTracker[], market?: PolicyTracker) {
    let statusColor = this.noStatusColor;
    if (markets) {
      const found = this.hasMarket(markets, market);
      if (found) {
        let regionMarkets: PolicyTracker[] = markets;
        if (market) {
          regionMarkets = [market];
        }
        const status: CarbonMarketStatus | null = this.getStatusForMarkets(regionMarkets); 
        return this.getColorForStatus(status);
      }
    }
    
    return statusColor;
  }

  public getNationalColor(markets?: PolicyTracker[]) {
    let status: CarbonMarketStatus | null = null;
    let statusColor = this.noStatusColor;
    if (markets) {      
      markets.forEach(x => {
        if ((x.jurisdictionType.id === JurisdictionTypeNational.getInstance().id || x.jurisdictionType.id === JurisdictionTypeRegional.getInstance().id) && x.country.id != CountryIdEnum.Russia) { 
          const marketStatus = x.marketStatus;
          if (!status || status.id < marketStatus.id) {
            status = x.marketStatus;
          }
        }
      });  

      statusColor = this.getColorForStatus(status);
    }

    return statusColor;
  }

  private getColorForStatus(status: CarbonMarketStatus | null): string {
    if (status) {    
      switch (status.id) {
        case CarbonMarketStatusUnderConsideration.getInstance().id:
          return this.rgbToHex(rgb(189, 196, 99).toString());
        case CarbonMarketStatusScheduled.getInstance().id:
        case CarbonMarketStatusUnderDevelopment.getInstance().id:
          return this.rgbToHex(rgb(129, 160, 115).toString());
        case CarbonMarketStatusInforce.getInstance().id:
          return this.rgbToHex(rgb(95, 154, 195).toString()); 
      }
    }

    return this.noStatusColor;
  }

  private rgbToHex(rgb: string): string {
    // Extract the numbers from the input string using regex
    const regex = /rgb\((\d+),\s*(\d+),\s*(\d+)\)/;
    const result = regex.exec(rgb);
  
    if (!result) {
      throw new Error('Invalid RGB input');
    }
  
    // Convert the individual RGB components to hex
    const r = parseInt(result[1], 10);
    const g = parseInt(result[2], 10);
    const b = parseInt(result[3], 10);
  
    return `#${((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1)}`;
  }

  private hasMarket(markets: PolicyTracker[], market: PolicyTracker | undefined) : boolean {
    let found = true;
    if (market) {
      found = false;
      const matches = markets.filter(m => m.name == market.name);
      if (matches.length > 0) {
        found = true;
      }
    }

    return found;
  }

  public getMarketColor(market: PolicyTracker) {
    const etsStatus: CarbonMarketStatus | null = this.getStatusForMarkets([market]);     
    return this.getColorForStatus(etsStatus);
  }

  public getMarketStatus(status: CarbonMarketStatusEnum) : CarbonMarketStatus | undefined {
    switch (status) {
      case CarbonMarketStatusEnum.UnderConsideration:
        return CarbonMarketStatusUnderConsideration.getInstance();
        case CarbonMarketStatusEnum.Scheduled:
          return CarbonMarketStatusScheduled.getInstance();
      case CarbonMarketStatusEnum.UnderDevelopment:
        return CarbonMarketStatusUnderDevelopment.getInstance();
      case CarbonMarketStatusEnum.Implemented:
        return CarbonMarketStatusInforce.getInstance();
    }

    return undefined;
  }

  public filterCountriesByMarket(countries: any, market: PolicyTracker, isFocus?: boolean) {
    return countries.features.filter(d => { 
      let name: string | null = null;
      if (d.id == CountryIdEnum.Russia.toString() && market.regions.includes(CountryProvinceNames.Sakhalin) && isFocus) {
        name = CountryProvinceNames.Sakhalin;
      }
      if (d.id == CountryIdEnum.Taiwan.toString() && market.country.id == CountryIdEnum.Taiwan) { 
        name = market.country.name;
      }
      if (d.id == CountryIdEnum.Korea.toString() && market.country.id == CountryIdEnum.Korea) {
        name = market.country.name;
      }
      
      if (!name) {
        name = getCountryNameForGeoJson(d.properties.name);
      }
     
      if (name) {
        return market.regions.includes(name);
      }

      return null;
    });
  }

  public filterNational(countries: any, isFocus?: boolean) {
    const features = countries.features.filter(d => { 
        if (d.properties.regionType == FeatureType.Country 
          && (d.id == CountryIdEnum.China.toString() 
          || (d.id == CountryIdEnum.Japan.toString() && isFocus)
          || d.id == CountryIdEnum.Mexico.toString()
          || d.id == CountryIdEnum.Canada.toString()
          || (d.id == CountryIdEnum.Spain.toString() && isFocus)
          || d.id == CountryIdEnum.USA.toString())) {
          return false;
        } 
        return true;
    });
    
    countries.features = features;
    return countries;
  }

  public filterSpainProvinces(countries: any) {
    const features = countries.features.filter(d => { 
        if (d.properties.regionType == FeatureType.SpainProvinces
          && (d.id == CountryIdEnum.Spain.toString())) {
          return false;
        } 
        return true;
    });
    
    countries.features = features;
    return countries;
  }

  public filterSpain(countries: any) {
    const features = countries.features.filter(d => { 
        if (d.properties.regionType == FeatureType.Country
          && (d.id == CountryIdEnum.Spain.toString())) {
          return false;
        } 
        return true;
    });
    
    countries.features = features;
    return countries;
  }

  private getStatusForMarkets(markets: PolicyTracker[]): CarbonMarketStatus | null {
    let status: CarbonMarketStatus | null = null;
    markets.forEach(market => {
      if (market.country.id !== CountryIdEnum.Russia) {
        const marketStatus = market.marketStatus;
        if (!status || status.id < marketStatus.id) {
          status = market.marketStatus;
        }
      }
    });

    return status;
  }
}