import { Injectable } from "@angular/core";
import { ProjectShapefile, ProjectShapefileCreateInfo, ProjectShapefileUpdateInfo } from "./models/project-shape-file.model";
import { HttpService } from "~/services/core/http.service";

@Injectable({
    providedIn: 'root'
})
export class ProjectShapefileService {
    constructor(
        private _httpService: HttpService,
    ) {
        // Intentionally blank
    }

    public addGeoJsonFiles(
        projectId: number, data: ProjectShapefileCreateInfo[]
    ) {
        const uri = `/projects/${projectId}/shapes`;
        return this._httpService.Post(uri, data);
    }

    public addShapefile(projectId: number, file: File): any {
        const uri = `/projects/${projectId}/shapes/convert-to-geojson`
        const formData = new FormData();
        formData.append("zipFile", file);
        return this._httpService.Post(uri, formData);
    }

    public deleteShapefiles(
        projectId: number, shapeIds: number[]): Promise<void> {
        const uri = `/projects/${projectId}/shapes/multiple-delete`
        return this._httpService.Post(uri, shapeIds);
    }

    public getShapefiles(projectId: number): Promise<ProjectShapefile[]> {
        // Adding timestamp is one way to avoid caching
        const uri = `/projects/${projectId}/shapes?timestamp=${new Date().getTime()}`;
        return this._httpService.Get(uri);
    }
    
    public updateShapefile(projectId: number, data: ProjectShapefileUpdateInfo): Promise<any> {
        const uri = `/projects/${projectId}/shapes`;
        return this._httpService.Patch(uri, data);
    }
}