<div *ngIf="dataClass === 'expand' && optionTitle === 'export-mr'" class="window">
    <div class="header">
        <span class="title">Export to Excel</span>
        <span title="Close" class="icon material-icons-outlined close" (click)="close()">close</span>
    </div>
    <div class="content">
      <ng-container *ngIf="errorMessage" class="alert-container">
        <ccms-alert [type]="AT.WARNING" [message]="errorMessage"></ccms-alert>
      </ng-container>
      <div class="d-flex justify-content-between">
        <div class="pr-3">
          <p *ngIf="!isRunning">
            Start a new export based on currently selected data by clicking the Export button.
          </p>
          <p *ngIf="isRunning">
            Meta registry data is currently being exported and will be available for download shortly.
          </p>
        </div>
      </div>
      <div class="pt-3"></div>
      <div class="d-flex justify-content-between" *ngIf="excelUri">
        <p>Click to download your most recent export.</p>
        <ccms-icon-button class="small" (click)="downloadFile()" icon="icon-download">
          Download
        </ccms-icon-button>
      </div>
      <div *ngIf="isRunning">
        <mat-progress-bar class="d-flex" mode="determinate" [value]="progress"></mat-progress-bar>  
        <div>
          {{status}}
        </div>
      </div>
    </div>
    <div class="footer">
        <button class="button" type="button" (click)="onExport()" [disabled]="isRunning">Export</button>
    </div>
</div>
