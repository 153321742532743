import { Injectable } from "@angular/core";
import { from, Observable, shareReplay } from "rxjs";
import Dictionary from "~/components/shared/dictionary/dictionary";
import { ICreditsMarket } from "~/models/shared/common";
import { HttpService } from "~/services/core/http.service";

@Injectable()
export class CreditsMarketService {
    private uri = "/credits-market"
    private marketsListCache$!: Observable<ICreditsMarket[]>;

    private marketsCache$ = new Dictionary<Observable<ICreditsMarket>>();

    constructor(private _httpClient: HttpService) {
        // Intentionally blank
    }

    public getCreditsMarkets(): Observable<ICreditsMarket[]> {
      if (!this.marketsListCache$) {
        this.marketsListCache$ = this.listCreditsMarkets().pipe(
          shareReplay(1)
        );
      }
  
      return this.marketsListCache$;
    }

    private listCreditsMarkets(): Observable<ICreditsMarket[]> {
        return from(this._httpClient.Get(this.uri));
    }

    public getCreditsMarketWithPrices(marketId: number): Observable<ICreditsMarket> {
      const item = this.marketsCache$.getItem(`key-${marketId}`);
      if (!item) {
        this.marketsCache$.add(`key-${marketId}`, this.callCreditsMarketWithPrices(marketId).pipe(
          shareReplay(1)
        ));
      }
  
      return this.marketsCache$.getItem(`key-${marketId}`);
    }

    private callCreditsMarketWithPrices(marketId: number): Observable<ICreditsMarket> {
      const requestUri = `${this.uri}/${marketId}`;
      const httpClient = this._httpClient;
      
      return from(httpClient.Get(requestUri));
  }
}
