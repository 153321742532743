<div class="wrapper">
    <ng-container *ngIf="ready">
        <div class="selects-container">
            <div class="registry">
                <select id="registry-select" [value]="$selectedRegistry | async"
                    (change)="onRegistrySelect($event.target.value)">
                    <option *ngFor="let registry of registries" [value]="registry">
                        {{ registry }}
                    </option>
                </select>
            </div>
            <ng-container *ngIf="($methodologies | async)?.length > 0">
                <div class="methodologies">
                    <select id="methodology-select" [value]="$selectedMethodology | async"
                        (change)="onMethodologySelect($event.target.value)">
                        <option *ngFor="let methodology of $methodologies | async; index as i" [value]="methodology.id">
                            {{ parseMethodologyDisplayText(methodology) }}
                        </option>
                    </select>
                </div>
            </ng-container>
        </div>
    </ng-container>
</div>