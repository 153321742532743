import { NgModule } from '@angular/core';
import { CurrencyPipe, DecimalPipe } from '@angular/common';

//******************************************************************************
//  Import External Components
//******************************************************************************
import { ExternalImports } from '~/classes/core/imports/external.imports';
import { MaterialImports } from '~/classes/core/imports/material.imports';

//******************************************************************************
//  Services
//******************************************************************************
import { AppSettingsService } from '~/services/core/appsettings.service';
import { GraphService } from '~/services/core/graph.service';
import { FormatterService } from '~/services/shared/formatter.service';
import { MetaRegistryService } from '~/services/shared/metaregistry.service';
import { SupplyTradingInventoryService } from '~/services/shared/inventory/supplytrading-inventory.service';
import { ProjectService } from '~/services/shared/projects/project.service';
import { CountryService } from '~/services/shared/country.service';
import { ForecastSummaryService } from '~/services/shared/forecast-summary.service';
import { InvestmentService } from '~/services/shared/investment.service';
import { PriceDeckService } from '~/services/shared/price-deck.service';
import { SustainabilityService } from '~/services/shared/sustainability.service';
import { CountriesGeoService } from '~/services/shared/policy-tracker/countries-geo.service';
import { MarketStatusService } from '~/services/shared/policy-tracker/market-status.service';
import { PolicyTrackerMapService } from '~/services/shared/policy-tracker/policy-tracker-map.service';
import { PolicyTrackerTreeDataService } from '~/services/shared/policy-tracker/policy-tracker-tree-data.service';
import { PolicyTrackerService } from '~/services/shared/policy-tracker/policy-tracker.service';
import { ClimatePolicyService } from '~/services/shared/policy-tracker/climate-policy.service';
import { PolicyTrackerDetailService } from '~/services/shared/policy-tracker/policy-tracker-detail.service';
import { TransparencyCpiService } from '~/services/shared/policy-tracker/transparency-cpi.service';
import { CountryCreditsDataService } from '~/services/shared/country-credits.service';

//******************************************************************************
//  Core Pages
//******************************************************************************
import { AuthComponent } from '~/pages/auth/auth.component';
import { NotFoundComponent } from '~/pages/notfound/notfound.component';

//******************************************************************************
//  Core Components
//******************************************************************************
import { HeaderComponent } from '~/components/core/layout/header/header.component';
import { NavComponent } from '~/components/core/layout/nav/nav.component';
import { FooterComponent } from '~/components/core/layout/footer/footer.component';
import { AccessComponent } from '~/components/core/access/access.component';
import { AlertComponent } from '~/components/core/alert/alert.component';
import { ErrorComponent } from '~/components/core/error/error.component';

//******************************************************************************
//  App Pages
//******************************************************************************
import { HomeComponent } from '~/pages/home/home.component';
import { MetaRegistryWorldMapComponent } from '~/pages/home/components/metaregistry-world-map/metaregistry-world-map.component';
import { FeedsComponent } from '~/pages/home/components/feeds/feeds.component';

import { MetaRegistryComponent } from '~/pages/metaregistry/metaregistry.component';
import { MetaRegistryDetailComponent } from '~/pages/metaregistry/metaregistry-detail/metaregistry-detail.component';
import { MetaRegistryDetailInfoComponent } from '~/pages/metaregistry/metaregistry-detail/info/metaregistry-detail-info.component';
import { MetaRegistryDetailLocationComponent } from '~/pages/metaregistry/metaregistry-detail/location/metaregistry-detail-location.component';
import { MetaRegistryDetailCreditsComponent } from '~/pages/metaregistry/metaregistry-detail/credits/metaregistry-detail-credits.component';
import { MetaRegistryDetailCreditsGraphComponent } from '~/pages/metaregistry/metaregistry-detail/credits-graph/metaregistry-detail-credits-graph.component';
import { MetaRegistryDetailRatingsComponent } from '~/pages/metaregistry/metaregistry-detail/ratings/metaregistry-detail-ratings.component'
import { ExportWindowComponent } from '~/pages/metaregistry/export/window/export-window.component';

import { MetaRegistryRetirementsComponent } from '~/pages/metaregistry/retirements/retirements.component';

import { MarketsComponent } from '~/pages/markets/markets.component';

import { InventoryComponent } from '~/pages/inventory/inventory.component';
import { SupplyTradingInventoryComponent } from '~/pages/inventory/supplytrading/supplytrading-inventory.component';
import { AbuInventoryComponent } from '~/pages/inventory/abu/abu-inventory.component';

import { ProjectsComponent } from '~/pages/projects/projects.component';
import { ProjectDetailComponent } from '~/pages/projects/project-detail/project-detail.component';
import { ProjectDetailInfoComponent } from '~/pages/projects/project-detail/info/project-detail-info.component';
import { ProjectDetailContactsComponent } from '~/pages/projects/project-detail/contacts/project-detail-contacts.component';
import { ProjectDetailLocationComponent } from '~/pages/projects/project-detail/location/project-detail-location.component';
import { ProjectDetailScreeningComponent } from '~/pages/projects/project-detail/screening/project-detail-screening.component';
import { ProjectDetailAgreementsComponent } from '~/pages/projects/project-detail/agreements/project-detail-agreements.component';
import { ProjectDetailTechReviewComponent } from '~/pages/projects/project-detail/tech-review/project-detail-tech-review.component';
import { ProjectDetailRatingsComponent } from '~/pages/projects/project-detail/ratings/project-detail-ratings.component';
import { ProjectDetailCreditsComponent } from '~/pages/projects/project-detail/credits/project-detail-credits.component';
import { ProjectDetailForecastComponent } from '~/pages/projects/project-detail/forecast/project-detail-forecast.component';
import { WsjfScoreCardFormComponent } from '~/pages/projects/project-detail/wsjf-score-card/wsjf-score-card-form/wsjf-score-card-form.component';
import { WsjfScoreCardComponent } from '~/pages/projects/project-detail/wsjf-score-card/wsjf-score-card.component';
import { TabInfoComponent } from '~/pages/projects/project-detail/components/tab-info/tab-info.component'

import { ProjectGridComponent } from '~/pages/projects/components/project-grid/project-grid.component';
import { ProjectChartsComponent } from '~/pages/projects/components/project-charts/project-charts.component';
import { ProjectChartsFiltersComponent } from '~/pages/projects/components/project-charts/components/filters/project-charts-filters.component';
import { ProjectCreditChartsComponent } from '~/pages/projects/components/project-charts/components/credit/credit-charts.component';
import { ProjectFundingChartsComponent } from '~/pages/projects/components/project-charts/components/funding/funding-charts.component';
import { ProjectRiskChartsComponent } from '~/pages/projects/components/project-charts/components/risk/risk-charts.component';

import { PolicyTrackerComponent } from '~/pages/policy-tracker/policy-tracker.component';
import { Article6DialogComponent } from '~/pages/policy-tracker/components/article6-dialog/article6-dialog.component';
import { MarketDialogComponent } from '~/pages/policy-tracker/components/market-dialog/market-dialog.component';
import { PolicyTrackerMapComponent } from '~/pages/policy-tracker/components/policy-tracker-map/policy-tracker-map.component';
import { EtsStatusComponent } from '~/pages/policy-tracker/components/ets-status/ets-status.component';
import { CountryProfileComponent } from '~/pages/policy-tracker/detail/country-profile/country-profile.component';
import { ClimatePolicyComponent } from '~/pages/policy-tracker/detail/climate-policy/climate-policy.component';
import { PolicyTrackerDetailPage } from '~/pages/policy-tracker/detail/policy-tracker-detail.page';
import { MarketFocusComponent } from '~/pages/policy-tracker/detail/market-focus/market-focus.component';
import { TransparencyCpiComponent } from '~/pages/policy-tracker/components/transparency-cpi/transparency-cpi.component';
import { PolicyTrackerCountryDetailPage } from '~/pages/policy-tracker/country-detail/policy-tracker-country-detail.page';
import { CountryCreditsComponent } from '~/pages/policy-tracker/components/country-credits/country-credits.component';
import { Article6PanelComponent } from '~/pages/policy-tracker/components/article6-panel/article6-panel.component';

//******************************************************************************
//  App Components
//******************************************************************************
import { DialogCancelButtonComponent } from '~/components/shared/dialog-cancel-button/dialog-cancel-button-component';
import { IconButtonComponent } from '~/components/shared/icon-button/icon-button.component';
import { TreeChecklistComponent } from '~/components/shared/tree-checklist/tree-checklist.component';
import { MarketChartComponent } from '~/components/shared/market-chart/market-chart.component';
import { PriceChartLegendComponent } from '~/components/shared/price-chart-legend/price-chart-legend.component';
import { TabsComponent } from '~/components/shared/tabs/tabs.component'
import { OptionsComponent } from '~/components/shared/options/options.component'
import { RadioButtonComponent } from '~/components/shared/radio-button/radio-button.component'

//******************************************************************************
//  Pipes
//******************************************************************************
import { FileSizePipe } from '~/components/shared/file-upload/file-size.pipe';
import { PolicyTrackerLinkifyPipe } from '~/pipes/policy-tracker-linkify.pipe';
import { SanitizePipe } from '~/pipes/sanitize.pipe';
import { RetiredCreditsService } from '~/services/shared/retirements/metaregistry-retired-credits.service';
import { RetiredChartYearlyComponent } from '~/pages/metaregistry/retirements/retired-chart-yearly/retired-chart-yearly.component';

import { PortfolioEpicSelectComponent } from '~/pages/projects/project-detail/wsjf-score-card/portfolio-epic-select/portfolio-epic-select.component';
import { PriceChartComponent } from '~/components/shared/price-chart/price-chart.component';
import { TreeChecklistDataService } from '~/services/shared/tree-checklist-data.service';

import { MetaRegistryDetailPricesComponent } from '~/pages/metaregistry/metaregistry-detail/prices/metaregistry-detail-prices.component';
import { RetiredChartIssuanceComponent } from '~/pages/metaregistry/retirements/retired-chart-issuance/retired-chart-issuance.component';
import { CreditForecastsChartComponent } from '~/pages/projects/project-detail/forecast/credit-forecast-chart/credit-forecasts-chart.component';
import { CreditForecastsFormComponent } from '~/pages/projects/project-detail/forecast/credit-forecast-form/credit-forecasts-form.component';
import { CreditForecastsImportComponent } from '~/pages/projects/project-detail/forecast/credit-forecast-import/credit-forecasts-import.component';
import { UserPermissionsService } from '~/services/shared/user-permissions.service';
import { ErrorAlertComponent } from '~/components/shared/error-alert/error-alert.component';
import { CreditsMarketService } from '~/services/shared/credits-market/credits-market.service';
import { SafePipe } from '~/pipes/safe.pipe';
import { CreditIssuanceTableComponent } from '~/pages/projects/project-detail/credits/credit-issuance-table/credit-issuance-table.component';
import { AgreementService } from '~/services/shared/agreements/agreements.service';
import { CreditInventoryService } from '~/services/shared/credit-inventory/credit-inventory.service';
import { WsjfScoreCardChartComponent } from '~/pages/projects/project-detail/wsjf-score-card/wsjf-score-card-chart/wsjf-score-card-chart.component';
import { CreditTransferFormComponent } from '~/pages/projects/project-detail/credits/credit-transfer-form/credit-transfer-form.component';
import { CreditInventoryAccountSelectComponent } from '~/pages/projects/project-detail/credits/credit-inventory-account-select/credit-inventory-account-select.component';
import { KpiService } from '~/services/shared/kpi/kpi.service';
import { DurableTaskService } from '~/services/shared/durable-task/durable-task.service';
import { IconButtonMaterialComponent } from '~/components/shared/icon-button-material/icon-button-material.component';
import { SearchComponent } from '~/components/shared/search/search.component';
import { SearchResultsPage } from '~/pages/search/search-results.page';
import { RouterModule } from '@angular/router';
import { KeywordSearchService } from '~/services/shared/keyword-search.service';
import { ProjectAddComponent } from '~/pages/projects/components/project-add/project-add.component';
import { ProjectAddInfo } from '~/pages/projects/components/project-add/info/project-add-info.component';
import { ProjectAddNotes } from '~/pages/projects/components/project-add/notes/project-add-notes.component';
import { ProjectAddGoals } from '~/pages/projects/components/project-add/goals/project-add-goals.component';
import { ProjectAddContacts } from '~/pages/projects/components/project-add/contacts/project-add-contacts.component';
import { ProjectAddLocation } from '~/pages/projects/components/project-add/location/project-add-location.component';
import { ProjectAddTechReview } from '~/pages/projects/components/project-add/tech-review/project-add-tech-review.component';
import { RetiredChartFiltersComponent } from '~/pages/metaregistry/retirements/retired-chart-filters/retired-chart-filters.component';
import { MultiSelectComponent } from '~/components/shared/multi-select/multi-select.component';
import { SearchableMultiSelectComponent } from '~/components/shared/searchable-multi-select/searchable-multi-select';
import { ConfirmPopUpComponent } from '~/components/shared/confirm-popup/confirm-popup.component';
import { ConfirmPopupService } from '~/services/shared/confirm-popup/confirm-popup.service';
import { RouteChangedService } from '~/services/shared/route-changed.service';
import { ExcelExportService } from '~/services/shared/export-excel/export-excel-service';
import { JobService } from '~/services/shared/job/job.service';
import { JobsComponent } from '~/pages/admin/components/jobs/jobs.component';
import { AdminComponent } from '~/pages/admin/admin.component';
import { ExportOptionComponent } from '~/components/shared/export-option/export-option.component';
import { ProjectExportWindowComponent } from '~/pages/projects/components/project-grid/export/window/project-export-window.component';
import { JobDetailsComponent } from '~/pages/admin/components/job-details/job-details.component';
import { ColumnDialogComponent } from '~/pages/admin/components/column-dialog/column-dialog.component';
import { DeveloperDetailComponent } from '~/pages/developers-screening/developer-detail/developer-detail.component';
import { DeveloperInfoComponent } from '~/pages/developers-screening/developer-detail/info/developer-info.component';
import { DeveloperLocationComponent } from '~/pages/developers-screening/developer-detail/locations/developer-location.component';
import { DeveloperCreditsComponent } from '~/pages/developers-screening/developer-detail/credits/developer-credits.component';
import { DeveloperProjectsComponent } from '~/pages/developers-screening/developer-detail/projects/developer-projects.component';
import { DeveloperRatingsComponent } from '~/pages/developers-screening/developer-detail/ratings/developer-ratings.component';
import { RunDialogComponent } from '~/pages/admin/components/run-dialog/run-dialog.component';
import { DeveloperScreeningComponent } from '~/pages/developers-screening/developer-screening.component';
import { DevelopersScreeningService } from '~/services/shared/developers-screening/developers-screening.service';
import { IssuanceService } from '~/services/shared/issuances/issuance.service';
import { IssuanceChartComponent } from '~/pages/developers-screening/developer-detail/credits/issuance-chart/issuance-chart.component';
import { MapsService } from '~/services/shared/maps/maps.service';
import { DeveloperProjectsMapComponent } from '~/pages/developers-screening/developer-detail/locations/developer-projects-map/developer-projects-map.component';
import { FileUploadComponent } from '~/components/shared/file-upload/file-upload.component';
import { ProjectShapefileService } from '~/services/shared/projects/project-shapefile.service';
import { DeveloperSummaryComponent } from '~/pages/developers-screening/developer-detail/summary/developer-summary.component';
import { ReportsComponent } from '~/pages/powerbi/reports.component';
import { ReportViewerComponent } from '~/components/shared/powerbi-viewer/powerbi-viewer.component';
import { SignPostReportComponent } from '~/pages/powerbi/signposts/signposts.component';
import { OffsetProjectReportComponent } from '~/pages/powerbi/offset-projects/offset-projects.component';
import { MapViewerComponent } from '~/components/shared/map-viewer/map-viewer.component';
import { ProjectPortfolioIdDirective } from '~/pages/projects/components/project-portfolio-id/project-portfolio-id.directive';
import { UserSettingsService } from '~/services/shared/user-settings/user-settings.service';
import { GridHandlerService } from '~/services/shared/grid/grid-handler.service';
import { ProjectPriorityScoreCardComponent } from '~/pages/projects/project-detail/priority-score-card/priority-score-card.component';
import { ProjectPrioritySummaryComponent } from '~/pages/projects/project-detail/priority-score-card/components/project-priority-summary/project-priority-summary.component';
import { ProjectPriorityScoreCardFormComponent } from '~/pages/projects/project-detail/priority-score-card/components/project-priority-score-card-form/project-priority-score-card-form.component';
import { AbuInventoryService } from '~/services/shared/inventory/abu-inventory-service';
import { AbuSummaryComponent } from '~/pages/inventory/abu/summary/abu-summary.component';
import { AbuTransactionsComponent } from '~/pages/inventory/abu/transactions/abu-transactions.component';
import { SupplyTradingSummaryComponent } from '~/pages/inventory/supplytrading/summary/supplytrading-summary.component';
import { SupplyTradingTransactionsComponent } from '~/pages/inventory/supplytrading/transactions/supplytrading-transactions.component';
import { SupplyTradingOverviewComponent } from '~/pages/inventory/supplytrading/overview/supplytrading-overview.component';
import { SupplyTradingChartComponent } from '~/pages/inventory/supplytrading/overview/chart/supplytrading-chart.component';
import { AbuOverviewComponent } from '~/pages/inventory/abu/overview/abu-overview.component';
import { AbuChartComponent } from '~/pages/inventory/abu/overview/chart/abu-chart.component';
import { MethodologySelectorComponent } from '~/components/shared/methodology-selector/methodology-selector.component';
import { PowerBiReportService } from '~/services/shared/powerbi/report.service';
import { ReportNavigatorComponent } from '~/components/shared/powerbi-report-navigator/powerbi-report-navigator.component';
import { MarketReportComponent } from '~/pages/powerbi/market/market.component';
import { DeveloperSearchComponent } from '~/pages/projects/components/developer-search/developer-search.component';
import { ProjectDeveloperService } from '~/services/shared/projects/project-developer-service';
import { UserService } from '~/services/shared/users/user-service';
import { UserSelectionComponent } from '~/components/shared/user-selection/user-selection.component';
import { CounterComponent } from '~/components/shared/counter/counter.component';

const components = [
    HeaderComponent,
    NavComponent,
    FooterComponent,

    AccessComponent,
    AlertComponent,
    ErrorComponent,
    ErrorAlertComponent,

    AuthComponent,
    NotFoundComponent,

    HomeComponent,
    MetaRegistryWorldMapComponent,
    FeedsComponent,

    MetaRegistryComponent,
    MetaRegistryDetailComponent,
    MetaRegistryDetailInfoComponent,
    MetaRegistryDetailLocationComponent,
    MetaRegistryDetailCreditsComponent,
    MetaRegistryDetailCreditsGraphComponent,
    MetaRegistryRetirementsComponent,
    MetaRegistryDetailPricesComponent,
    MetaRegistryDetailRatingsComponent,
    ExportOptionComponent,
    ExportWindowComponent,

    MarketsComponent,

    InventoryComponent,
    SupplyTradingInventoryComponent,
    SupplyTradingOverviewComponent,
    SupplyTradingChartComponent,
    SupplyTradingSummaryComponent,
    SupplyTradingTransactionsComponent,
    AbuInventoryComponent,
    AbuOverviewComponent,
    AbuChartComponent,
    AbuSummaryComponent,
    AbuTransactionsComponent,

    ProjectsComponent,
    ProjectAddComponent,
    ProjectAddInfo,
    ProjectAddNotes,
    ProjectAddGoals,
    ProjectAddContacts,
    ProjectAddLocation,
    ProjectAddTechReview,
    ProjectDetailComponent,
    ProjectDetailInfoComponent,
    ProjectDetailContactsComponent,
    ProjectDetailLocationComponent,
    ProjectDetailScreeningComponent,
    ProjectDetailForecastComponent,
    ProjectDetailCreditsComponent,
    ProjectDetailAgreementsComponent,
    ProjectDetailTechReviewComponent,
    ProjectDetailRatingsComponent,
    TabInfoComponent,
    ProjectGridComponent,
    ProjectChartsComponent,
    ProjectChartsFiltersComponent,
    ProjectCreditChartsComponent,
    ProjectFundingChartsComponent,
    ProjectRiskChartsComponent,
    WsjfScoreCardComponent,
    WsjfScoreCardFormComponent,
    WsjfScoreCardChartComponent,
    PortfolioEpicSelectComponent,
    CreditIssuanceTableComponent,
    CreditTransferFormComponent,
    CreditInventoryAccountSelectComponent,
    MethodologySelectorComponent,
    DeveloperSearchComponent,
    UserSelectionComponent,

    PolicyTrackerComponent,
    PolicyTrackerDetailPage,
    Article6DialogComponent,
    MarketDialogComponent,
    PolicyTrackerMapComponent,
    EtsStatusComponent,
    CountryProfileComponent,
    ClimatePolicyComponent,
    PolicyTrackerCountryDetailPage,
    MarketFocusComponent,
    TransparencyCpiComponent,
    CountryCreditsComponent,
    Article6PanelComponent,

    DialogCancelButtonComponent,
    IconButtonComponent,
    IconButtonMaterialComponent,
    TreeChecklistComponent,
    MarketChartComponent,
    PriceChartLegendComponent,  
    TabsComponent,
    OptionsComponent,
    RadioButtonComponent,
    CounterComponent,

    PriceChartComponent,
    MultiSelectComponent,
    RetiredChartYearlyComponent,
    RetiredChartIssuanceComponent,
    
    CreditForecastsChartComponent,
    CreditForecastsFormComponent,
    CreditForecastsImportComponent,

    SearchComponent,
    SearchResultsPage,

    RetiredChartFiltersComponent,
    SearchableMultiSelectComponent,
    ConfirmPopUpComponent,
    AdminComponent,
    JobsComponent,

    ProjectExportWindowComponent,
    JobDetailsComponent,
    ColumnDialogComponent,
    DeveloperDetailComponent,
    DeveloperSummaryComponent,
    DeveloperInfoComponent,
    DeveloperLocationComponent,
    DeveloperCreditsComponent,
    DeveloperProjectsComponent,
    DeveloperLocationComponent,
    DeveloperRatingsComponent,
    DeveloperScreeningComponent,
    RunDialogComponent,

    IssuanceChartComponent,
    DeveloperProjectsMapComponent,
    FileUploadComponent,
    MapViewerComponent,
    ReportsComponent,
    ReportViewerComponent,
    ReportNavigatorComponent,
    SignPostReportComponent,
    OffsetProjectReportComponent,
    MarketReportComponent,
    ProjectPortfolioIdDirective,
    ProjectPriorityScoreCardComponent,
    ProjectPrioritySummaryComponent,
    ProjectPriorityScoreCardFormComponent
  ];

const services = [
    AppSettingsService,
    GraphService,
    FormatterService,
    MetaRegistryService,
    SupplyTradingInventoryService,
    AbuInventoryService,
    ProjectService,
    ProjectDeveloperService,
    CountryService,
    ForecastSummaryService,
    ExcelExportService,
    InvestmentService,
    PriceDeckService,
    SustainabilityService,
    DecimalPipe,
    TreeChecklistDataService,
    UserPermissionsService,
    CreditInventoryService,

    //policy tracker
    CountriesGeoService,
    MarketStatusService,
    PolicyTrackerMapService,
    PolicyTrackerTreeDataService,
    PolicyTrackerService,
    ClimatePolicyService,
    PolicyTrackerDetailService,
    TransparencyCpiService,
    CountryCreditsDataService,
    PolicyTrackerDetailService,
    RetiredCreditsService,

    CreditsMarketService,
    CurrencyPipe,

    AgreementService,

    KpiService,

    DurableTaskService,

    KeywordSearchService,
    ConfirmPopupService,
    RouteChangedService,

    JobService,
    DevelopersScreeningService,

    IssuanceService,
    MapsService,
    ProjectShapefileService,
    PowerBiReportService,

    UserSettingsService,
    UserService,
    GridHandlerService
];

@NgModule({
    imports: [
        RouterModule,
        ExternalImports,
        MaterialImports,
    ],
    declarations: [
        components,
        PolicyTrackerLinkifyPipe,
        SanitizePipe,
        SafePipe,
        FileSizePipe
      ],
    exports: [
        components,
    ],
    providers: [
        services,
    ],
})

export class AppImports { }
