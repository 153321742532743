import { Component } from "@angular/core";
import { BaseComponent } from "../base.component";

@Component({
  selector: 'admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.scss']
})
export class AdminComponent extends BaseComponent {

  public title: string = "Web Job Admin";

  constructor() {
    super();
  }

  public async ngOnInit() {
    await super.ngOnInit();

  }
}