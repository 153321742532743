import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { BaseComponent } from '~/pages/base.component';
import { Tab } from '~/components/shared/tabs/tabs.component';
import { Observable } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { UserPermissionsService } from '~/services/shared/user-permissions.service';
import { SupplyTradingInventory } from '~/models/shared/permissions/resource-permission';
import { SupplyTradingSummaryComponent } from './summary/supplytrading-summary.component';
import { SupplyTradingTransactionsComponent } from './transactions/supplytrading-transactions.component';
import { SupplyTradingOverviewComponent } from './overview/supplytrading-overview.component';

@Component({
    selector: 'supplytrading-inventory.component-inventory',
    templateUrl: './supplytrading-inventory.component.html',
    styleUrls: ['./supplytrading-inventory.component.scss']
})

export class SupplyTradingInventoryComponent extends BaseComponent implements OnChanges {
    @Input() parentTab?: string;
    @Output() messageEvent: EventEmitter<any> = new EventEmitter();
    public tabs: Tab[] = [
        {
            title: "Overview",
            type: <any>SupplyTradingOverviewComponent,
            resourcePermission: SupplyTradingInventory
        },
        {
            title: "Summary",
            type: <any>SupplyTradingSummaryComponent,
            resourcePermission: SupplyTradingInventory
        },
        {
            title: "Transactions",
            type: <any>SupplyTradingTransactionsComponent,
            resourcePermission: SupplyTradingInventory
        }
    ];

    public activeTab!: Tab | undefined;

    public urlParams: string = "";

    constructor(
        private route: ActivatedRoute,
        private readonly _userPermissionService: UserPermissionsService) {
        super();
    }

    //*************************************************************************
    //  Component Life-Cycle Methods
    //*************************************************************************
    public async ngOnInit() {
        await super.ngOnInit();
        await this.subscribeToRouteParams();
    }

    public async ngOnChanges(args: any) {
        if (args.parentTab?.currentValue) {
            this.urlParams = "parentTab=" + args.parentTab?.currentValue;
        }
    }

    //*************************************************************************
    //  Public Methods
    //*************************************************************************
    public confirmTabChange(event: any): Observable<boolean> {
        return new Observable(o => o.next(true));
    }

    public showMessage(event: any) {
        this.messageEvent.emit(event);
    }
    //*************************************************************************
    //  Private Methods
    private async subscribeToRouteParams() {
        this.route.queryParams.subscribe((params: any) => {
            if (params?.tab) {
                this.setActiveTab(params.tab);
            }
        });
    }

    private setActiveTab(tabName: string) {
        const tab = this.tabs.find((tab: Tab) => tab.title.startsWith(tabName));
        if (tab) {
            this._userPermissionService.getUserPermissions().subscribe({
                next: (permissions) => {
                    if (tab.resourcePermission) {
                        if (UserPermissionsService.checkPermission(permissions, tab.resourcePermission, true)) {
                            this.activeTab = tab;
                        } else {
                            this.messageEvent.emit(`Access to ${this.parentTab} ${tab.title} Denied`);
                        }
                    }
                },
                error: (error) => {
                    console.error("Error loading permissions: " + error?.message);
                }
            });
        }
    }
}