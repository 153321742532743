import { Component, EventEmitter, OnInit, Output } from '@angular/core';

import { AppSettingsService } from '~/services/core/appsettings.service';
import { AppSettings } from '~/models/core/appsettings';

@Component({
	selector: 'footer',
	templateUrl: './footer.component.html',
	styleUrls: ['./footer.component.scss']
})

export class FooterComponent implements OnInit {
	@Output() setLayoutCssClass = new EventEmitter();

	public date = new Date();
	public version: any = "0.0.0";

	constructor(private appSettingsService: AppSettingsService) { }

	//*************************************************************************
	//  Component Life-Cycle Methods
	//*************************************************************************
	public async ngOnInit() {
		let settings: AppSettings = await this.appSettingsService.getSettings();

		this.version = settings.version;
	}
}
