export function groupByProperty<T>(array: T[], propertyName: keyof T): Array<{
    key: string,
    items: T[]
}> {
    return array.reduce((result: any[], item: T) => {
        const key = String(item[propertyName]);
        const group = result.find((g) => g.key === key);

        if (group) {
            group.items.push(item);
        } else {
            result.push({ key, items: [item] });
        }

        return result;
    }, []);
}  
