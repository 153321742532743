<table class="table-x centered">
    <caption style="display: none;">Location</caption>
    <tbody>
        <tr>
            <th scope="col">
                ADO Priority
            </th>
            <th scope="col">
                Priority
            </th>
            <th scope="col">
                Priority w/ Economics
            </th>
        </tr>
        <tr>
            <td>{{ adoPriority ?? '--' }}</td>
            <td>{{ priority ?? '--' }}</td>
            <td>{{ priorityWithEconomics ?? '--' }}</td>
        </tr>
    </tbody>
</table>