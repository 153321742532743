<div class="content">
    <div class="data">
        <div class="options">
            <div title="Graph" class="icon material-icons" [ngClass]="{ active: type === '' }"
                (click)="type = ''; typeName = 'Graph'; loadData({ chart: true });">insert_chart</div>
            <div title="Issuances" class="icon material-icons" [ngClass]="{ active: type === 'Issued' }"
                (click)="type = 'Issued'; typeName = 'Issuance'; loadData();">assignment_add</div>
            <div title="Assignments" class="icon material-icons" [ngClass]="{ active: type === 'Assigned' }"
                (click)="type = 'Assigned'; typeName = 'Assignments'; loadData();">assignment_turned_in</div>
            <div title="Retirements" class="icon material-icons" [ngClass]="{ active: type === 'Retired' }"
                (click)="type = 'Retired'; typeName = 'Retirements'; loadData();">content_paste_go</div>
        </div>
        <div *ngIf="type === ''" class="graph">
            <issuance-chart [data]="fullData"></issuance-chart>

            <sdk-loading [isLoading]="isLoading"></sdk-loading>
        </div>
        <div *ngIf="type !== ''" class="grid">
            <sdk-datagrid
                [title]="typeName"
                titleStyle="text-align: left; font-size: 1.3em; font-weight: normal; color: var(--blue-dark); font-family: 'GothamBold';"
                [datasets]="datasets"
                [name]="activeView"
                [uniqueIdentifier]="uniqueIdentifier"
                [data]="data"
                [fullData]="fullData"
                [forceReload]="forceReload"
                [columns]="columns"
                [options]="gridOptions"
                [dbPage]="dbPage"
                [dbTotal]="dbTotal"
                [isDataWrapped]=false
                [minimizeOptions]=true
                [isLoading]="isLoading"
                [error]="error"
                [settings]="allSettings"
                (loadDataEvent)="loadData($event)"
                (settingsSavedEvent)="saveSettings($event)">
            </sdk-datagrid>
        </div>

        <ng-template #eligibilityTemplate let-rowItem="rowItem">
          <div (click)="$event.stopPropagation()">
              <div>
                  {{ rowItem.eligibility ? rowItem.eligibility.join(', ') : '' }}
              </div>
          </div>
        </ng-template>
    </div>
    <div class="info">
        <developer-summary [developer]="developer"></developer-summary>
    </div>
</div>