import { Component, ElementRef, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild } from "@angular/core";
import { FormControl } from "@angular/forms";
import { debounceTime, startWith, tap } from "rxjs";

@Component({
  selector: 'searchable-multi-select',
  templateUrl: 'searchable-multi-select.html',
  styleUrls: ['searchable-multi-select.scss'],
})
export class SearchableMultiSelectComponent implements OnChanges {
  @ViewChild('search') searchTextBox!: ElementRef;
  @Input('data') data!: string[];
  @Output() searchTextChanged: EventEmitter<string> = new EventEmitter<string>();
  @Output() changed: EventEmitter<any[]> = new EventEmitter<any[]>();

  selectFormControl = new FormControl();
  searchTextboxControl = new FormControl();
  selectedValues: any[] = [];
  filteredOptions!: string[];
  isLoading = false;
  currentTerm: string | null = '';

  ngOnInit() {
    this.initializeFilteredOptions();
    this.filteredOptions = this.loadOptions();
  }

  ngOnChanges(changes: SimpleChanges) {
    // When 'data' input property changes, reinitialize filteredOptions
    if (changes.data && !changes.data.isFirstChange()) {
      this.filteredOptions = this.loadOptions();
    }
    this.isLoading = false;
  }

  private initializeFilteredOptions() {
    this.searchTextboxControl.valueChanges
      .pipe(
        debounceTime(300), // 300ms delay
        startWith<string>(''),
        tap(name => this.emitSearchTerm(name?.toLowerCase()))
      ).subscribe();
  }

  private emitSearchTerm(term: string) {
    if (this.currentTerm !== term) {
      this.isLoading = true
      this.searchTextChanged.emit(term);
    }
    this.currentTerm = term;
  }
  
  private loadOptions(): string[] {
    // Set selected values to retain the selected checkbox state 
    this.setSelectedValues();
    this.selectFormControl.patchValue(this.selectedValues);
    let filteredList = this.data;
    return filteredList;
  }

 /**
  * Remove from selected values based on uncheck
  */
  selectionChange(event) {
    if (event.isUserInput && !event.source.selected) {
      let index = this.selectedValues.indexOf(event.source.value);
      this.selectedValues.splice(index, 1);
      this.changed.emit(this.selectedValues);
    }
  }

  optionsChanged(event) {
    this.setSelectedValues();
  }

  openedChange(e) {
    // Set search textbox value as empty while opening selectbox 
    this.searchTextboxControl.patchValue(this.currentTerm);
    // Focus to search textbox while clicking on selectbox
    if (e) {
      this.searchTextBox.nativeElement.focus();
    }
  }

  /**
   * Clearing search textbox value 
   */
  clearSearch(event) {
    event.stopPropagation();
    this.searchTextboxControl.patchValue('');
    this.searchTextChanged.emit('');
  }

  /**
   * Set selected values to retain the state 
   */
  setSelectedValues() {
    let pushed = false;
    if (this.selectFormControl.value && this.selectFormControl.value.length > 0) {
      this.selectFormControl.value.forEach((e) => {
        if (this.selectedValues.indexOf(e) == -1) {
          this.selectedValues.push(e);
          pushed = true;
        }
      });
    }

    if (pushed) {
      this.changed.emit(this.selectedValues);
    }
  }

  resetOptions() {
    this.selectedValues = [];
    this.selectFormControl.patchValue(this.selectedValues);
  }
}