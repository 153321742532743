import { Injectable } from '@angular/core';
import { FilterInfo } from '~/models/shared/filterInfo';
import { HttpService } from '../../core/http.service';
import { AbuInventorySearchOptions } from './models/abu-Inventory-search-options';
import { AbuInventorySearchResult } from './models/abu-Inventory-search-result';
import { InventorySummaryDto } from './models/inventory-summary-dto';

@Injectable()
export class AbuInventoryService {
    constructor(
        private readonly httpService: HttpService,
    ) {
        // Intentionally blank
    }

    public search(options: AbuInventorySearchOptions): Promise<AbuInventorySearchResult> {
        options ??= {} as AbuInventorySearchOptions;
        let requestUri = '/abu-inventory/search';
        return this.httpService.Post(requestUri, options);
    }

    public getSummary(): Promise<InventorySummaryDto> {
        let requestUri = '/abu-inventory/summary';
        return this.httpService.Get(requestUri);
    }

    public async getFilters(fieldNames: string[]): Promise<FilterInfo[]> {
        try {
            let queryString = "";
            fieldNames.forEach((fieldName) => {
                queryString = queryString === "" ? `fields=${fieldName}` : `${queryString}&fields=${fieldName}`;
            });
            const response = await this.httpService.Get(`/abu-inventory/filters?${queryString}`);
            return response;
        } catch (error: any) {
            throw error;
        }
    }
}
