export function buildParametersSorts(event: any, parameters: any) {
  if (event.sorts) {
      event.sorts.forEach((sort: any, index: number) => {
          let direction = (sort.Sort === "asc") ? 0 : 1;
          let ndx = index + 1;

          parameters.SortingOptions.push({ field: sort.Name, direction: direction, priority: ndx });
      });
  }
}

export function buildParametersFilters(event: any, parameters: any) {
    if (event.filters) {
        event.filters.forEach((filter: any) => {
            let filterValue = filter.Value;
            let multipleSelection = [];
            if (filter.Value instanceof (Array)) {
                multipleSelection = filterValue;
                filterValue = "";
            }

            parameters.Terms.push({ Field: filter.Name, Value: filterValue, MultiSelectValues: multipleSelection, Operation: filter.Operation });
      });
  }
}