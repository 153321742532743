import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild } from '@angular/core';
import { CreditForecastsWrapper } from './model/credit-forecasts-wrapper.model';
import { ICvxProject } from '~/models/shared/cvxproject';
import { CvxProjectScreening } from '~/models/shared/cvx-project-screening.model';
import CcmsServerError from '~/models/shared/errors/ccms-server-error';
import { CreditForecastUpdateWrapper } from './model/credit-forecast-update-wrapper.model';
import { CreditForecastsFormComponent } from './credit-forecast-form/credit-forecasts-form.component';
import { MatTabGroup } from '@angular/material/tabs';
import { ForecastTypeEnum } from './model/forecast-type.enum';
import { AlertType } from '~/models/shared/errors/error-alert-type.enum';
import { UserPermissionsService } from '~/services/shared/user-permissions.service';
import { ICreditForecast } from '~/models/shared/common';
import { UntypedFormGroup } from '@angular/forms';
import { ProjectService } from '~/services/shared/projects/project.service';
import { BehaviorSubject, Subject, firstValueFrom } from 'rxjs';
import { ICreditForecastEditEvent } from './model/credit-forecast-edit.event';
import { SaveDataEvent } from '../models/save-data-event.model';

@Component({
    selector: 'project-detail-forecast',
    templateUrl: './project-detail-forecast.component.html',
    styleUrls: ['./project-detail-forecast.component.scss']
})
export class ProjectDetailForecastComponent implements OnChanges {
  @Input()
  project!: ICvxProject;
	@Input() set editForecast(value: ICreditForecastEditEvent) {
		this._isEditForecastSubject.next(value);
	}
	@Input() saveData: boolean = false;
	@Input() portfolioId: string | undefined = undefined;

  @Output() edit = new EventEmitter<ICreditForecastEditEvent>();
  @Output() changeEvent: EventEmitter<any> = new EventEmitter();
  @Output() saveDataEvent: EventEmitter<SaveDataEvent> = new EventEmitter();

	@ViewChild('funded')
  funded!: CreditForecastsFormComponent;
	@ViewChild('current')
  current!: CreditForecastsFormComponent;
	@ViewChild('tabGroup')
  tabGroup!: MatTabGroup;
	@ViewChild('template', { static: true }) template;

  private _isEditForecastSubject = new BehaviorSubject<ICreditForecastEditEvent>({isEdit: false, forecasts: null});
	public isEditForecast$ = this._isEditForecastSubject.asObservable();

  private _saveDataChange$ = new Subject<boolean>();

  public isLoading: boolean = true;
  public projectEdit: any;
  public isInEdit: boolean = false;
  public projectForecastEdit!: ICvxProject;

	private forecastType!: ForecastTypeEnum;
	private _projectScreening!: CvxProjectScreening | null;

	riskFundedForecasts!: CreditForecastsWrapper;
	unriskFundedForecasts!: CreditForecastsWrapper;

	riskCurrentForecasts!: CreditForecastsWrapper;
	unriskCurrentForecasts!: CreditForecastsWrapper;

	canUpdate: boolean = false;
  
  error!: CcmsServerError | null;

	AT = AlertType;
	FT = ForecastTypeEnum;

	constructor(
    private _userPermissionsService: UserPermissionsService,
    private _projectService: ProjectService
    ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if(changes.saveData && !changes.saveData.firstChange){
      this._saveDataChange$.next(changes.saveData.currentValue);
    }

    if (this.project && !this.error) {
      this.isLoading = true;
      this.loadProject(this.project);
      this.loadOpportunityScreening(this.project);
      this.isLoading = false;
    }
  }

  async ngOnInit() {
		this.canUpdate = await this._userPermissionsService.canUpdateProjects();
		this.forecastType = ForecastTypeEnum.Funded;

    this._isEditForecastSubject.subscribe((event: ICreditForecastEditEvent) => {
      if (!event) return;
    
      if (event.isEdit) {
        this.enterEdit(event.forecasts);
      } else if (!event.isEdit && this.isInEdit) {
        this.cancelEdit();
      }
    });

    this._saveDataChange$.subscribe((value: boolean) => {
      if (!value) {
          return;
      }
  
      if (this.forecastType === ForecastTypeEnum.Funded) {
          this.funded.onSaveForm();
      } else if (this.forecastType === ForecastTypeEnum.Current) {
          this.current.onSaveForm();
      }
    });
	}

	ngAfterViewInit(): void {
		if (this.tabGroup.selectedIndex === 0) {
			this.funded.initChart();
		}

		const handleTabClick = this.tabGroup._handleClick;
		this.tabGroup._handleClick = (tab, header, index) => {
			if (this.canTabChange()) {
				handleTabClick.apply(this.tabGroup, [tab, header, index]);
			}
		};
	}

	public canTabChange(): boolean {
		if (this.isInEdit) {
      const editForm = this.getEditForm();
      const event: ICreditForecastEditEvent = {isEdit: false, forecasts: null}
			if (editForm?.dirty || this.error) {
				if (confirm("Switch tabs and lose changes to " + this.forecastType.toLowerCase() + " forecast?")) {
					this.edit.emit(event);
					return true;
				}
				return false;
			} else {
				this.edit.emit(event);
				return true;
			}
		}

		return true;
	}
  
  private async enterEdit(forecasts?: ICreditForecast[] | null) {
    this.isLoading = true;
    if (this.forecastType === ForecastTypeEnum.Funded) {
      await this.funded.enterEdit(this.project, forecasts);
    }
    if (this.forecastType === ForecastTypeEnum.Current) {
      await this.current.enterEdit(this.project, forecasts);
    }
    this.isInEdit = true;

    this.projectEdit = { ...this.project };
    this.isLoading = false;
  }

  public cancelEdit() {
    this.error = null;
    if (this.forecastType === ForecastTypeEnum.Funded) {
      this.funded.cancelEdit();
    }
    if (this.forecastType === ForecastTypeEnum.Current) {
      this.current.cancelEdit();
    }
    this.isInEdit = false;
	}

  public creditForecastsForm_OnSaved(event: CreditForecastUpdateWrapper) {
    this.error = null;
   
    this.projectForecastEdit = { ...this.project };
    if (event.forecastType === ForecastTypeEnum.Current) {
      this.projectForecastEdit.yearlyCreditForecastsCurrent = event.creditForecasts;
    } else if (event.forecastType === ForecastTypeEnum.Funded ) {
      this.projectForecastEdit.yearlyCreditForecastsFunded = event.creditForecasts;
    }
    this._projectService.saveCreditsForecast(this.project.id, event).subscribe({
			complete: () => {
        this.saveDataEvent.emit(new SaveDataEvent(false));
			},
			error: (err) => {
				this.error = new CcmsServerError(err);
        this.loadProject(this.projectForecastEdit);
        this.saveDataEvent.emit(new SaveDataEvent(false, this.error.errors));
			}
		});
	}

  private loadProject(project: ICvxProject) {
    if (project) {
			this.unriskFundedForecasts = new CreditForecastsWrapper();
			this.unriskFundedForecasts.creditForecasts = project.yearlyCreditForecastsFunded;
			this.unriskFundedForecasts.creditsMarket = project.creditsMarket;

			this.unriskCurrentForecasts = new CreditForecastsWrapper();
			this.unriskCurrentForecasts.creditForecasts = project.yearlyCreditForecastsCurrent;
			this.unriskCurrentForecasts.creditsMarket = project.creditsMarket;
		}
  }

  private async loadOpportunityScreening(project: ICvxProject) {
    this._projectScreening = await firstValueFrom(this._projectService.getOpportunityScreening(project.id));

		if (this.hasRiskForecasts) {
			const creditsMarket = this.project.creditsMarket;
			const riskFactor = this._projectScreening.confidenceEstimate / 100;
			const creditForecastsFunded = this.project.yearlyCreditForecastsFunded;
			const creditForecastsCurrent = this.project.yearlyCreditForecastsCurrent;

			const fundedForecastsRisk = new CreditForecastsWrapper();
			fundedForecastsRisk.creditForecasts = this.applyRiskFactor(creditForecastsFunded, riskFactor);
			fundedForecastsRisk.creditsMarket = creditsMarket;

			const currentForecastsRisk = new CreditForecastsWrapper();
			currentForecastsRisk.creditForecasts = this.applyRiskFactor(creditForecastsCurrent, riskFactor);
			currentForecastsRisk.creditsMarket = creditsMarket;

			this.riskFundedForecasts = fundedForecastsRisk;
			this.riskCurrentForecasts = currentForecastsRisk;
		}
  }

  private getEditForm(): UntypedFormGroup | undefined {
		if (this.forecastType === ForecastTypeEnum.Funded) {
			return this.funded.form;
		}
		if (this.forecastType === ForecastTypeEnum.Current) {
			return this.current.form;
		}

    return undefined;
	}

  get hasRiskForecasts(): boolean | null {
		const projectScreening = this._projectScreening;
		return this.project && projectScreening && projectScreening.confidenceEstimate > 0;
	}

  private applyRiskFactor(target: ICreditForecast[], riskFactor: number): ICreditForecast[] {
		const result: ICreditForecast[] = [];
		if (target) {
			target.forEach(forecast => {
				const riskForecast: ICreditForecast = {
					year: forecast.year,
					pricePerTonne: forecast.pricePerTonne,
					cvxProjectId: forecast.cvxProjectId,
					prepayment: forecast.prepayment,
					opEx: forecast.opEx,
					priceFloor: forecast.priceFloor,
					discountRate: forecast.discountRate,
					credits: forecast.credits > 0 ? forecast.credits * riskFactor : 0,
					discretionarySpend: forecast.discretionarySpend > 0 ? forecast.discretionarySpend * riskFactor : 0,
					nonDiscretionarySpend: forecast.nonDiscretionarySpend > 0 ? forecast.nonDiscretionarySpend * riskFactor : 0,
					nonObligatedCredits: forecast.nonObligatedCredits > 0 ? forecast.nonObligatedCredits * riskFactor : 0,
				}
				result.push(riskForecast);
			});
		}
		return result;
	}

  public onTabChanged(_$event) {
		if (this.tabGroup.selectedIndex === 0) {
			this.forecastType = ForecastTypeEnum.Funded;
			this.funded.initChart();
		}
		else {
			this.forecastType = ForecastTypeEnum.Current;
			this.current.initChart();
		}
	}

	copyFunded() {
    const event: ICreditForecastEditEvent = {isEdit: true, forecasts: this.unriskFundedForecasts.creditForecasts}
    this.edit.emit(event);
	}

	get isCurrentEditing() {
		if (this.current) {
			return this.current.isEdit;
		}

		return false;
	}

	get isFundedEditing() {
		if (this.funded) {
			return this.funded.isEdit;
		}

		return false;
	}

  public infoChange(event: any) {
    this.projectEdit[event.field] = event.value;

    this.changeEvent.emit(this.projectEdit);
  }
}
