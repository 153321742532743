import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnChanges, SimpleChanges } from "@angular/core";
import { CountryProfileViewModel } from "./country-profile.viewmodel";
import { DecimalPipe } from "@angular/common";
import { PolicyTrackerDetailService } from "~/services/shared/policy-tracker/policy-tracker-detail.service";
import { ZeroTracker } from "~/models/shared/policy-tracker/zero-tracker.model";
import { PolicyTrackerConstants } from "~/services/shared/policy-tracker/policy-tracker-constants";

@Component({
  selector: 'ccms-country-profile',
  templateUrl: './country-profile.component.html',
  styleUrls: ['./country-profile.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CountryProfileComponent implements OnChanges {
  @Input()
  countryId!: number;
  @Input()
  region!: string;
  @Input()
  shortenTitle!: boolean;

  constructor(
    private _policyTrackerDetailService: PolicyTrackerDetailService,
    private cd: ChangeDetectorRef,
    private decimalPipe: DecimalPipe) {
      this.viewModel = new CountryProfileViewModel(null, null);
  }

  public viewModel: CountryProfileViewModel;
  public hasData!: boolean;

  ngOnChanges(changes: SimpleChanges): void {
    this.loadView(this.countryId, this.region);
  }

  public async loadView(countryId: number, region?: string) {
    let zero: ZeroTracker;
    let response;
    if (!region && countryId) {
      response = await this._policyTrackerDetailService.getZeroTrackerByCountry(countryId);
    } else if (countryId && region) {
      if (region == PolicyTrackerConstants.EuropeanUnion) {
        response = await this._policyTrackerDetailService.getZeroTrackerByName(region);
      } else {
        response = await this._policyTrackerDetailService.getZeroTrackerByRegion(countryId, region);
      }
    } else if (region && !countryId) {
      response = await this._policyTrackerDetailService.getZeroTrackerByName(region);
    }

    zero = response?.values[0];
    if (zero) {
      this.viewModel = new CountryProfileViewModel(zero, this.decimalPipe, region);
      this.hasData = true;
    } else {
      this.hasData = false;
    }
    this.cd.detectChanges();
  }
}