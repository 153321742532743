<div class="content">
    <div class="data">
        <div class="description">
            <div class="header">Description</div>
            <div class="description-data">
                <div *ngIf="developer.description !== ''" class="text">{{ developer.description }}</div>
                <div *ngIf="!developer.description || developer.description === ''" class="text none">No description provided.</div>
            </div>
        </div>
        <br /><br />
        <div class="project-scopes">
            <div class="header">Project Summary</div>
            <div class="project-scopes-data">
                <table class="table-x" >
                    <caption style="display: none;">summary</caption>
                    <tbody>
                        <!-- HEADING -->
                        <tr>
                            <th scope="col">Scope</th>
                            <ng-container *ngFor="let registry of registries">
                                <th scope="col">{{registry}}</th>
                            </ng-container>
                            <th scope="col">Total</th>
                        </tr>
                        <!-- /HEADING -->
                        <ng-container *ngFor="let row of projectScopeSummaryByRegistry">
                            <tr>
                                <td>{{row.scope}}</td>
                                <ng-container *ngFor="let registry of registries">
                                    <td>{{row.registries[registry] ?? 0}}</td>
                                </ng-container>
                                <td>{{sumRow(row)}}</td>
                            </tr>
                        </ng-container>
                        <tr>
                            <th scope="row">Total</th>
                            <ng-container *ngFor="let registry of registries">
                                <td>{{sumRegistry(registry)}}</td>
                            </ng-container>
                            <td>{{sumTotal()}}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
    <div class="info">
        <developer-summary [developer]="developer"></developer-summary>
    </div>
</div>