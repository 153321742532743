export class Paging {
    public pageSize: number = 0;
    public currentPage: number = 0;
}

export class SortOption {
    public field: string = "";
    public priority: number = 0;
    public direction: number = 0
}

export class SearchFilter {
    public Name: string = "";
    public Field: string = "";
    public Value: string = "";
    public MultiSelectValues: string[] = [];
    public Operation: string = "";
}

export class RequestParameters {
    public Paging: Paging = new Paging();
    public SortingOptions: Array<SortOption> = [];
    public Terms: Array<SearchFilter> = [];
    public EntityIds: Array<string> = [];
    public ActionFilter: string = "";
}