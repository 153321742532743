<div class="content">
    <div class="data">
        <sdk-google-map *ngIf="showMap && _site != null" [latitude]="_site.latitude" [longitude]="_site.longitude" [zoom]="zoom" [height]="'100%'"
            [width]="'100%'" isLoading="true"></sdk-google-map>
            <br />
            <table class="table-x">
                <caption style="display: none;">Location</caption>
                <tbody>
                    <tr>
                        <th scope="col">Street Address</th>
            
                        <td *ngIf="true"><sdk-textbox 
                                (changeCallBackEvent)="addressChange('streetAddress', $event)"></sdk-textbox></td>
                    </tr>
                    <tr>
                        <th scope="col">City/Town</th>
                        <td *ngIf="true"><sdk-textbox
                                (changeCallBackEvent)="addressChange('addressLocality', $event)"></sdk-textbox></td>
                    </tr>
                    <tr>
                        <th scope="col">State/Province</th>
                        <td *ngIf="true"><sdk-textbox
                                (changeCallBackEvent)="addressChange('addressRegion', $event)"></sdk-textbox></td>
                    </tr>
                    <tr>
                        <th scope="col">Zip/Postal</th>
                        <td *ngIf="true"><sdk-textbox 
                                (changeCallBackEvent)="addressChange('postalCode', $event)"></sdk-textbox></td>
                    </tr>
                    <tr>
                        <th scope="col">Latitude</th>
                        <td *ngIf="true"><sdk-textbox [value]="_site?.latitude" validCharacters="latitude"
                                (changeCallBackEvent)="siteChange('latitude', $event)"></sdk-textbox></td>
                    </tr>
                    <tr>
                        <th scope="col">Longitude</th>
                        <td *ngIf="true"><sdk-textbox [value]="_site?.longitude" validCharacters="longitude"
                                (changeCallBackEvent)="siteChange('longitude', $event)"></sdk-textbox></td>
                    </tr>
                </tbody>
            </table>
    </div>
    <div class="info">
       
    </div>
</div>