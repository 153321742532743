import { 
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component, 
  ContentChild, 
  ElementRef, 
  EventEmitter, 
  Input, 
  Output,
  TemplateRef,
  ViewChild } from '@angular/core';
import { FileUtility } from './file.utility';
@Component({
  selector: 'ccms-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FileUploadComponent {

  private _isDirty = false;
  private readonly _defaultOptions: FileUploadComponentOptions = {
    allowedFileTypes: ['*'],
    autoUpload: false,
    multiple: false,
    sequentialUpload: false,
    showFileSize: false,
    showUploadStatus: false,
    showUploadButton: false
  };  

  @ContentChild(TemplateRef) fileListTemplate!: TemplateRef<any>;
  private _options!: FileUploadComponentOptions;
  @Input() set options(value: FileUploadComponentOptions) {
    this._options = {
      ...this._defaultOptions,
      ...value
    };
  }
  public get options(): FileUploadComponentOptions {
    const options = this._options || {};
    return {
      ...this._defaultOptions,
      ...options
    };
  }
  @Input() initialFileList: Array<FileUploadContext<any>> = [];
  /**Trigger when the  */
  @Output() removeAllFiles = new EventEmitter();
  @Output() removeFile = new EventEmitter<FileUploadContext<any>>();
  @Output() reset = new EventEmitter();
  @Output() uploadFile = new EventEmitter<FileUploadContext<any>>();
  @ViewChild('fileSelect') fileSelect!: ElementRef;

  private isUploading: boolean = false;
  private _files: Array<FileUploadContext<any>> = [];
  
  constructor(
    private readonly _changeDetector: ChangeDetectorRef
  ) {
    /** Any methods that are defined in a template needs to be bound to 'this'. */
    this.onRemoveFile = this.onRemoveFile.bind(this);
    this.isDirty = this.isDirty.bind(this);
  }

  public ngOnInit() {
    this._files = [
      ...this.initialFileList
    ];
  }

  public get canReset() {
    /**
     * Can reset is based on 3 conditions:
     * 1. _isDirty is true (manually set)
     * 2. When any files in the initialFileList is removed.
     * 3. When additional files are selected.
     */
    if (this._isDirty) {
      return true;
    }
    const initialFileList = this.initialFileList;
    const fileList = this.fileList;
    return initialFileList.length != fileList.length
      || !initialFileList.every(f => fileList.includes(f));
  }

  public get dropFileMessage() {
    const multiple = this.options?.multiple;
    const fileStr = multiple ? "files" : "file";
    let message = `Drop ${fileStr} here to upload, or`;
    return message;
  }

  public get fileList() {
    return [
      ...this._files
    ];
  }

  public isDirty(value: boolean) {
    this._isDirty = value;
  }

  public onBeginUpload() {
    this.beginUploadProcess();
  }

  public onFilesSelected(event: any) {
    const files: File[] = Array.from(event.target.files);
    const filteredFiles = files.filter(file => this.fileTypeAllowed(file.type));
    this.addFiles(filteredFiles);
    this.resetFileSelectControl();
  }

  public onRemoveAllFiles() {
    this._files = [];
    this.removeAllFiles.emit();
  }

  public onRemoveFile(file: FileUploadContext<any>) {
    const index = this._files.indexOf(file);
    if (index > -1) {
      this._files.splice(index, 1);
      this._files = [...this._files]; // force change detection
      this.removeFile.emit(file);
      this._changeDetector.detectChanges();
    }
  }

  public onReset() {
    this._isDirty = false;
    this._files = [
      ...this.initialFileList
    ];
  }

  //#region drag/drop

  private _dropZoneClass: '' | 'drag-over' | 'no-drop' = '';
  public get dropZoneClass() {
    return this._dropZoneClass;
  }

  public onDragLeave(event: DragEvent) {
    event.stopPropagation();
    event.preventDefault();
    this._dropZoneClass = '';
  }

  public onDragOver(event: DragEvent) {
    event.stopPropagation();
    event.preventDefault();
    this._dropZoneClass = '';
    if (event.dataTransfer == null) {
      return;
    }
    const files = Array.from(event.dataTransfer.items);
    // Convert allowed file types to lowercase for comparison
    const canDropFiles = this.canDropFiles(files);
    this._dropZoneClass = canDropFiles ? 'drag-over' : 'no-drop';
    // Set the drop effect to copy to indicate that the data being dragged will be copied
    event.dataTransfer.dropEffect = canDropFiles ? 'copy' : 'none';
  }

  public onDrop(event: DragEvent) {
    event.stopPropagation();
    event.preventDefault();
    this._dropZoneClass = '';    
    if (event.dataTransfer == null) {
      return;
    }
    const files = Array.from(event.dataTransfer.items);
    if (this.canDropFiles(files)) {
      this.onFilesDrop(event.dataTransfer.files);
    }
    event.dataTransfer.clearData();
  }

  //#endregion

  //#region internal

  private addFiles(files: File[] | FileList) {
    if (!files || files.length === 0) {
      return;
    }
    const multiple = this.options.multiple;
    if (!multiple) {
      const fileContext = this.wrapFile(files[0]);
      this._files = [fileContext];
    }
    else {
      Array.from(files).forEach(file => {
        const duplicate = this._files.find(ctx => 
          ctx.file instanceof File
          && ctx.file.name === file.name 
          && (ctx.file.lastModified === file.lastModified));
        if (duplicate) {
          return;
        }
        const fileContext = this.wrapFile(file);
        this._files.push(fileContext);
      });
    }
    const autoUpload = this.options.autoUpload;
    if (autoUpload) {
      this.beginUploadProcess();
    }
  }
  
  private beginUploadProcess() {
    const sequentialUpload = this.options.sequentialUpload;
    if (sequentialUpload) {
      this.uploadNextFile();
    }
    else {
      this._files.forEach(file => {
        if (file.canUpload) {
          this.uploadFileInternal(file)
        }
      });
    }
  }

  private canDropFiles(files: DataTransferItem[]) {
    const multiple = this.options.multiple;
    if (!multiple && files.length > 1) {
      return false;
    }
    return files.every(file => {
      if (file.kind === 'file') {
        return this.fileTypeAllowed(file.type);
      }
      return false;
    });
  }

  private fileTypeAllowed(type: string): boolean {
    const typeAsLowerCase = type.toLowerCase();
    const allowedFileTypes = this.options.allowedFileTypes;
    const lowerCaseAllowedTypes = allowedFileTypes.map(type => type.toLowerCase());
    const zipFilesAllowed = lowerCaseAllowedTypes.includes('.zip');
    return lowerCaseAllowedTypes.includes('*')
      || lowerCaseAllowedTypes.includes(typeAsLowerCase)
      || (zipFilesAllowed && FileUtility.isZippedFile(typeAsLowerCase));
  }

  private onFilesDrop(fileList: FileList) {
    const files = Array
      .from(fileList)
      .filter(file => this.fileTypeAllowed(file.type));
    this.addFiles(files);
  }

  private resetFileSelectControl() {
    // Check if the input element is available
    if (this.fileSelect?.nativeElement) {
      // Reset the value of the file input
      this.fileSelect.nativeElement.value = '';
    }
  }

  private uploadFileInternal(file: FileUploadContext<any>) {
    file.isUploading = true;
    this.uploadFile.emit(file);
  }

  private uploadNextFile() {
    if (this.isUploading) return;
    const nextFile = this._files.find(f => f.canUpload);
    if (nextFile) {
      this.isUploading = true;
      this.uploadFileInternal(nextFile);
    }
  }

  private wrapFile(file: File) {
    const fileContext = new FileUploadContext(file);
    fileContext.onProgress = (progress: number) => {
      if (progress >= 100) {
        this.isUploading = false;
        fileContext.isUploading = false;
        const sequentialUpload = this.options.sequentialUpload;
        if (sequentialUpload) {
          this.uploadNextFile();
        }
      }
    }
    return fileContext;
  }

  //#endregion
}

export type FileUploadComponentOptions = {
  allowedFileTypes: string[];
  autoUpload: boolean;
  multiple: boolean;
  sequentialUpload: boolean;
  showFileSize: boolean;
  showUploadButton: boolean;
  showUploadStatus: boolean;
}

export class FileUploadContext<T> {
  private readonly _file: T;
  private _error?: Error;
  private _isUploading = false;
  private _progress: number = 0;

  constructor(
    file: T,
    onProgress?: (progress: number) => void,
    onError?: (error: Error) => void
  ) {
    this._file = file;
    this.onProgress = onProgress;
    this.onError = onError;
  }

  get file() {
    return this._file;
  }

  get canUpload() {
    return this._file instanceof File;
  }

  get isUploading() {
    return this._isUploading;
  }
  set isUploading(value: boolean) {
    this._isUploading = value;
  }

  get error(): Error | undefined {
    return this._error;
  }
  set error(value: Error) {
    this._error = value;
  }

  get progress(): number {
    return this._progress ?? 0;
  }
  set progress(value: number) {
    this._progress = value;
    if (this._progress < 0) {
      this._progress = 0;
    }
    else if (this._progress > 100) {
      this._progress = 100;
    }
    if (this.onProgress) {
      this.onProgress(this._progress);
    }
  }

  onError?: (error: Error) => void;
  onProgress?: (progress: number) => void;
}
