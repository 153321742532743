import { AfterViewInit, Component, Input, Provider, forwardRef } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { BehaviorSubject, debounceTime, distinctUntilChanged } from 'rxjs';
import { CreditInventoryService } from '~/services/shared/credit-inventory/credit-inventory.service';
import { InventoryAccountQuery } from '~/services/shared/credit-inventory/models/credit-inventory-query.model';

const CONTROL_VALUE_ACCESSOR: Provider = {
	provide: NG_VALUE_ACCESSOR,
	useExisting: forwardRef(() => CreditInventoryAccountSelectComponent),
	multi: true,
};

@Component({
	selector: 'ccms-inventory-account-select',
	templateUrl: './credit-inventory-account-select.component.html',
	styleUrls: ['./credit-inventory-account-select.component.scss'],
	providers: [CONTROL_VALUE_ACCESSOR]
})
export class CreditInventoryAccountSelectComponent implements AfterViewInit {
	keyword$ = new BehaviorSubject<string>('');

	private _optionsSubject = new BehaviorSubject<string[]>([]);
	options$ = this._optionsSubject.asObservable();

	@Input()
	required = false;

	@Input()
	label = 'Account';

	value = '';
	showHint = false;
	isLoading = false;
	disabled = false;

	constructor(
		private readonly _creditInventoryService: CreditInventoryService
	) {
		// Intentionally blank
	}
	ngAfterViewInit(): void {
		this.keyword$.pipe(
			debounceTime(300),
			distinctUntilChanged()
		).subscribe({
			next: (keyword) => {
				if (!keyword) {
					this.writeValue('');
					this._optionsSubject.next([]);
				}
				else {
					this.writeValue(keyword);
					this.loadData(keyword);
				}
			}
		})
	}

	loadData(keyword?: string) {
		this.isLoading = true;
		try {
			const options = {
				name: keyword,
				paging: {
					pageSize: 100,
					currentPage: 0
				}
			} as InventoryAccountQuery;
			this._creditInventoryService.findInventoryAccount(options).subscribe(result => {
				const accountNames = result.map(item => item.name);
				this._optionsSubject.next(accountNames);
			});
		}
		catch (err) {
			console.error(err);
		}
		finally {
			this.isLoading = false;
		}
	}

	onChange: any = (_: string) => { };
	onTouched: any = () => { };

	writeValue(value: string): void {
		this.value = value;
		this.onChange(value);
	}
	registerOnChange(fn: any): void {
		this.onChange = fn;
	}
	registerOnTouched(fn: any): void {
		this.onTouched = fn;
	}
	setDisabledState?(isDisabled: boolean): void {
		this.disabled = isDisabled;
	}
}
