import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { SDKDataGridColumn } from '../../models/datagrid-column';

@Component({
    selector: 'sdk-datagrid-chart-option',
    templateUrl: './chart-option.component.html',
    styleUrls: ['./chart-option.component.scss']
})

export class SDKDataGridChartOptionComponent implements OnInit {
    @Input() columns: SDKDataGridColumn[] = [];
    @Input() chart: any = "";
    @Output() closeEvent = new EventEmitter();
    @Output() applyEvent = new EventEmitter();

    public chartType = "bar";
    public columnLabel = "";
    public columnDataX = "";
    public columnDataY = "";
    public dataOption = false;

    public ngOnInit() {
        this.initialze();
    }

    public ngOnChanges(_args: any) {
        this.initialze();
    }

    private initialze() {
        this.columns = this.columns.slice(0).filter(c => c.isVisible && !c.actionTemplate);

        if (this.chart === "") {
            this.chart = {
                Type: "bar",
                Show: false,
                Label: "",
                DataX: "",
                DataY: "",
                Option: false
            };
        }

        this.chartType = this.chart.Type;
        this.columnLabel = this.chart.Label;
        this.columnDataX = this.chart.DataX;
        this.columnDataY = this.chart.DataY;
        this.dataOption = this.chart.Option;
    }

    public getColumnOriginalName(column: SDKDataGridColumn): string {
        let originalName = column.Name;

        if (column.DisplayName && column.DisplayName !== "") {
            originalName = column.DisplayName;
        }

        return originalName;
    }

    public getValue(event: any) {
        return event.target.value;
    }

    public reset() {
        if (confirm("Reset Chart... Are you sure?")) {
            this.chart = "";

            this.initialze();
        }
    }

    public apply() {
        let show = true;

        if (['bar', 'line', 'radar'].indexOf(this.chartType) > -1 && (this.columnLabel === "" || this.columnDataX === "" || this.columnDataY === "")) show = false;
        if (['doughnut', 'pie', 'polarArea'].indexOf(this.chartType) > -1 && (this.columnLabel === "" || this.columnDataY === "")) show = false;

        this.chart = {
            Type: this.chartType,
            Show: show,
            Label: this.columnLabel,
            DataX: this.columnDataX,
            DataY: this.columnDataY,
            Option: this.dataOption
        };

        this.applyEvent.emit(this.chart);
    }

    public close() {
        this.closeEvent.emit();
    }
}
