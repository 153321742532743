<div class="content">
	<div class="header">
		<div class="back" (click)="back()"><span>◄ </span><span class="highlight">Back to Map</span></div>
		<div class="title">{{ this.viewModel?.Name }}</div>
	</div>

	<div class="details">
		<div class="data">
			<div>{{ policyTracker?.description }}</div>
			<mat-tab-group id="profileTabGroup" *ngIf="region && (region != EuropeanUnion && region != Sakhalin)" fitInkBarToContent>
				<mat-tab label="regional">
          <div class="spacer"></div>
					<ccms-country-profile [countryId]="countryId" [region]="region"></ccms-country-profile>
				</mat-tab>
				<mat-tab label="national">
          <div class="spacer"></div>
					<ccms-country-profile *ngIf="countryId" [countryId]="countryId"></ccms-country-profile>
				</mat-tab>
			</mat-tab-group>
			<mat-tab-group id="profileTabGroup" *ngIf="region && region == EuropeanUnion && countryId" fitInkBarToContent>
				<mat-tab label="national">
          <div class="spacer"></div>
					<ccms-country-profile [countryId]="countryId"></ccms-country-profile>
				</mat-tab>
				<mat-tab label="european union">
          <div class="spacer"></div>
					<ccms-country-profile [countryId]="countryId" [region]="region"></ccms-country-profile>
				</mat-tab>
			</mat-tab-group>
			<ccms-country-profile *ngIf="countryId && !region" [countryId]="countryId"></ccms-country-profile>
			<ccms-country-profile *ngIf="region && region == EuropeanUnion && !countryId" [region]="region"></ccms-country-profile>
			<ccms-country-profile *ngIf="region && region == Sakhalin" [countryId]="countryId"></ccms-country-profile>
			<mat-tab-group id="tabGroup" *ngIf="hasChart" dynamicHeight fitInkBarToContent>
				<mat-tab label="price">
          <div class="spacer"></div>
					<ccms-market-chart #marketChart></ccms-market-chart>
				</mat-tab>
				<mat-tab label="policies">
          <div class="spacer"></div>
					<ccms-climate-policy [policies]="policies"></ccms-climate-policy>
				</mat-tab>
			</mat-tab-group>
			<div *ngIf="hasPolicies && !hasChart">
				<br />
				<h3>Policies</h3>
				<ccms-climate-policy [policies]="policies"></ccms-climate-policy>
			</div>
		</div>
		<div class="info">
			<div class="price">
				<span class="detail-label">Price:</span>&nbsp;{{policyTracker?.averageSecurityMarketPrice}}
			</div>
			<div class="market-focus">
				<ccms-market-focus class="focus-map" [radius]="500" [market]="policyTracker"></ccms-market-focus>
			</div>
			<div class="lower-right-panel">
				<div>
					<div class="detail-label panel-label">Sectors</div>
					<p>
						<span *ngFor="let item of viewModel?.Sectors; last as isLast">
							{{item}}{{!isLast ? ', ' : ''}}
						</span>
					</p>
				</div>
				<div>
					<div class="detail-label panel-label">Cap</div>
					<p>
						{{viewModel?.Cap}}
					</p>
				</div>
				<div>
					<div class="detail-label panel-label">Greenhouse&nbsp;Gases</div>
					<p>
						{{policyTracker?.icap?.ghgsCovered}}
					</p>
				</div>
				<div>
					<div class="detail-label panel-label">Offsets&nbsp;and&nbsp;Credits</div>
					<p>
						{{policyTracker?.icap?.offsetsCreditsSummary}}
					</p>
				</div>
				<div>
					<div class="detail-label panel-label">Allocation</div>
					<p>
						{{policyTracker?.icap?.allowanceAllocSummary}}
					</p>
				</div>
				<div>
					<div class="detail-label panel-label">Jurisdictions&nbsp;Covered</div>
					<p>
						<span *ngFor="let item of viewModel?.Jurisdictions; last as isLast">
							{{item}}{{!isLast ? ', ' : ''}}
						</span>
					</p>
				</div>
				<div>
					<div class="detail-label panel-label">Overlapping&nbsp;Initiatives</div>
					<p>
						{{viewModel?.Overlapping}}
					</p>
				</div>
			</div>
		</div>
	</div>
</div>