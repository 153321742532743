<div *ngIf="_name !== ''" #sdkdatagrid class="sdk-datagrid">
    <div title="" class="sdk-datagrid-content" [ngClass]="dataClass">
        <!-- Header -->
        <div *ngIf="showHeader && (!_isLoading || isLoaded)" class="header">
            <div *ngIf="title !== ''" class="title" [style]="titleStyle">{{ title }}</div>
            <div class="data" *ngIf="datasets.length > 1">
                <div class="dropdown">
                    <div class="notabs" *ngIf="!useTabs">
                        <span class="bold">Data: </span>
                        <select (change)="loadDataset($event)">
                            <ng-container *ngFor="let ds of datasets">
                                <option [value]="ds.DbName" [selected]="(_name === ds.DbName) ? 'selected' : ''"> {{ ds.Title }} </option>
                            </ng-container>
                        </select>
                    </div>
                    <div class="tabs" *ngIf="useTabs">
                        <ng-container *ngFor="let ds of datasets">
                            <div class="tab" [ngClass]="_name === ds.DbName ? 'active' : ''" (click)="_name !== ds.DbName ? loadDataset(ds.DbName) : null">{{ ds.Title }}</div>
                        </ng-container>
                    </div>
                </div>
            </div>
        </div>

        <!-- Options (in header section) -->
        <div *ngIf="showOptions && _columns.length > 0 && (!_isLoading || isLoaded)" class="options-menu">
            <div *ngIf="!minimizeOptions" class="sizable">
                <div *ngIf="!_menuOptions" title="Show Options" (click)="_menuOptions = true;" class="sdk-icon">menu_open</div>
                <div *ngIf="_menuOptions" title="Hide Options" (click)="_menuOptions = false;" class="sdk-icon">close</div>
            </div>
            <div *ngIf="minimizeOptions === true" class="always-on">
                <div *ngIf="!_menuOptions" title="Show Options" (click)="_menuOptions = true;" class="sdk-icon">menu_open</div>
                <div *ngIf="_menuOptions" title="Hide Options" (click)="_menuOptions = false;" class="sdk-icon">close</div>
            </div>
        </div>

        <!-- Options -->
        <div *ngIf="showOptions && _columns.length > 0 && (!_isLoading || isLoaded)" class="options" [ngClass]="{ 'notop': !showHeader && !showOptions, 'noheader': !showHeader, 'expand-top': datasets.length > 1 && showHeader && title !== '', 'hide': minimizeOptions === true && !_menuOptions, 'hide-x': showOptions && !_menuOptions, 'min-options': minimizeOptions }">
            <div class="icons">
                <div class="label-title" title="Data Options">Options</div>
                <div *ngIf="_options.settings" class="sdk-datagrid-option" (click)="showDataOptions('settings')">
                    <div title="Settings" class="sdk-icon" [ngClass]="optionTitle === 'Settings' ? 'active' : ''">settings</div>
                </div>
                <div *ngIf="_options.expandableRows && this.detailTemplate" class="sdk-datagrid-option">
                    <div title="Expand All" (click)="expandAll()" class="sdk-icon">unfold_more</div>
                </div>
                <div *ngIf="_options.expandableRows && this.detailTemplate" class="sdk-datagrid-option">
                    <div title="Collapse All" (click)="collapseAll()" class="sdk-icon">unfold_less</div>
                </div>
                <div *ngIf="_options.columnSettings" class="sdk-datagrid-option" (click)="showDataOptions('columns')">
                    <div title="Columns" class="sdk-icon" [ngClass]="optionTitle === 'Columns' ? 'active' : ''">list_alt</div>
                    <div *ngIf="columnBadge !== ''" class="badge">
                        <div class="label">{{ columnBadge }}</div>
                    </div>
                    <div *ngIf="showNote" class="note">
                        <div title="Column * indicates custom name is used." class="sdk-icon">info</div>
                    </div>
                </div>
                <div *ngIf="_options.sorting" class="sdk-datagrid-option" (click)="showDataOptions('sorting')">
                    <div title="Sorting" class="sdk-icon" [ngClass]="optionTitle === 'Sorting' ? 'active' : ''">sort_by_alpha</div>
                    <div *ngIf="sortBadge !== ''" class="badge">
                        <div class="label">{{ sortBadge }}</div>
                    </div>
                </div>
                <div *ngIf="_options.filtering" class="sdk-datagrid-option" (click)="showDataOptions('filters')">
                    <div title="Filters" class="sdk-icon" [ngClass]="optionTitle === 'Filters' ? 'active' : ''">filter_alt</div>
                    <div *ngIf="filterBadge !== ''" class="badge">
                        <div class="label">{{ filterBadge }}</div>
                    </div>
                </div>
                <div *ngIf="_options.formulas" class="sdk-datagrid-option" (click)="showDataOptions('formulas')">
                    <div title="Formulas" class="sdk-icon" [ngClass]="optionTitle === 'Formulas' ? 'active' : ''">functions</div>
                    <div *ngIf="formulaBadge !== ''" class="badge">
                        <div class="label">{{ formulaBadge }}</div>
                    </div>
                </div>
                <div *ngFor="let option of optionAddons">
                    <ng-container [ngComponentOutlet]="option" [ndcDynamicInputs]="optionInputs" [ndcDynamicOutputs]="optionOutputs"></ng-container>
                </div>
                <div *ngIf="_options.export" class="sdk-datagrid-option" (click)="showDataOptions('export')">
                    <div title="Export" class="sdk-icon" [ngClass]="optionTitle === 'Export' ? 'active' : ''">file_download</div>
                </div>
                <div *ngIf="showReset" class="sdk-datagrid-option" (click)="clearAllOptions()">
                    <div title="Reset All Options" class="reset">RESET</div>
                </div>
                <div *ngIf="_options.charts" class="divider"></div>
                <div *ngIf="_options.charts" class="sdk-datagrid-option" (click)="toggleDataMode('data')">
                    <div title="Grid" class="sdk-icon" [ngClass]="dataMode === 'data' ? 'active' : ''">storage</div>
                </div>
                <div *ngIf="_options.charts" class="sdk-datagrid-option" (click)="showDataOptions('chart')">
                    <div title="Chart" class="sdk-icon" [ngClass]="dataMode === 'chart' ? 'active' : ''">poll</div>
                    <div *ngIf="chartBadge !== ''" class="badge">
                        <div class="label">{{ chartBadge }}</div>
                    </div>
                </div>
                <div class="divider"></div>
                <div class="sdk-datagrid-option" (click)="reloadData(true)">
                    <div title="Refresh Data" class="sdk-icon">refresh</div>
                </div>
            </div>
        </div>

        <!-- Grid -->
        <!-- Removed && !initialLoad from following *ngIf statement -->
        <div *ngIf="(!_isLoading || isLoaded) && (!error || error === '')" [id]="'grid_' + _name" (scroll)="onScroll($event)" class="grid" [ngClass]="{ 'notop': !showHeader && !showOptions, 'noheader': !showHeader, 'nooptions': ((minimizeOptions === true || !showOptions) && (!showOptions || !_menuOptions) || _columns.length === 0), 'nofooter': !showFooter, 'expand-top': showHeader && datasets.length > 1 && title !== '', 'min-options': minimizeOptions && showOptions, 'show': dataMode === 'data' }">
            <table class="dg-table">
                <caption style="display: none;">Data</caption>
                <thead *ngIf="_columns.length > 0 && hasVisibleColumns()">
                    <tr>
                        <!-- INSERT "left" action here -->
                        <ng-container *ngFor="let column of _columnActions">
                            <th *ngIf="column.actionTemplate && column.actionSide === 'left'" scope="col" [class]="'action-left'">
                                <div class="column">
                                    <span class="column-header action" [style]="columnHeaderStyle">{{ (column.FriendlyName === '') ? getColumnOriginalName(column) : column.FriendlyName }}</span>
                                </div>
                            </th>
                        </ng-container>

                        <ng-container *ngFor="let column of _columns">
                            <th *ngIf="column.isVisible" scope="col" [class]="column.actionSide !== '' ? 'action-' + column.actionSide : ''" [ngClass]="{ 'required': editRowIndex > -1 && column.required && column.required === true }" [title]="(column.FriendlyName !== '') ? 'Orig. Name:\n' + getColumnOriginalName(column) : ''">
                                <div class="column" (click)="inlineSort(column)">
                                    <div *ngIf="_options.selectableRows && isFirstColumn(column)" title="Select All" class="header-checkbox" [ngClass]="selectAll? 'checked' : ''" (click)="toggleSelectAll($event)">
                                        <div *ngIf="selectAll" class="checkmark">&#x2713;</div>
                                        <div *ngIf="selectPartial" class="partialSelection"></div>
                                    </div>
                                    <span class="column-header" [style]="columnHeaderStyle" [ngStyle]="{ 'color': editRowIndex > -1 && column.required && column.required === true && column.requiredColor ? column.requiredColor: 'auto'}">{{ (column.FriendlyName === '') ? getColumnOriginalName(column) : column.FriendlyName + " *" }}</span>
                                    <span *ngIf="editRowIndex > -1 && column.notes !== ''" [title]="column.notes" class="sdk-icon header-info" [ngStyle]="{ 'color': editRowIndex > -1 && column.required && column.required === true && column.requiredColor ? column.requiredColor: 'unset'}">info</span>
                                    <span *ngIf="column.Sort !== ''" class="sdk-icon">{{ (column.Sort === 'desc') ? 'expand_more' : 'expand_less' }}</span>
                                </div>
                            </th>
                        </ng-container>

                        <!-- INSERT "right" action here -->
                        <ng-container *ngFor="let column of _columnActions">
                            <th *ngIf="column.actionTemplate && column.actionSide === 'right'" scope="col" [class]="'action-right'">
                                <div class="column">
                                    <span class="column-header action" [style]="columnHeaderStyle">{{ (column.FriendlyName === '') ? getColumnOriginalName(column) : column.FriendlyName }}</span>
                                </div>
                            </th>
                        </ng-container>
                    </tr>
                </thead>

                <tbody *ngIf="!_isLoading && isLoaded && (!hasData || (hasData && data.length === 0))" class="data-error text-left">
                    <tr>
                        <td colspan="1000" [ngClass]="{'text-left': hasVisibleColumns()}">No records found.</td>
                    </tr>
                </tbody>
                <tbody *ngIf="!_isLoading && hasData && data.length > 0 && !hasVisibleColumns()" class="data-error">
                    <tr>
                        <td colspan="1000">No columns selected.</td>
                    </tr>
                </tbody>
                <tbody class="alternate" *ngIf="hasData && hasVisibleColumns()">
                    <ng-container *ngFor="let d of _data; let i = index">
                        <tr [ngClass]="{ 'row-action': selectedRowActionEvent.observed }" (click)="takeAction({ data: d, index: i })">
                            <!-- INSERT "left" action here -->
                            <ng-container *ngFor="let column of _columnActions">
                                <td *ngIf="column.actionTemplate && column.actionSide === 'left'" class="action-left nowrap">
                                    <ng-container [ngTemplateOutlet]="column.actionTemplate()" [ngTemplateOutletContext]="{rowItem:d, activeRow:_activeRow, index:i, columnItem:column}"></ng-container>
                                </td>
                            </ng-container>

                            <ng-container *ngIf="!d.isEdit">
                                <ng-container *ngFor="let column of _columns">
                                    <td *ngIf="column.isVisible" [class]="column.actionSide !== '' ? 'action-' + column.actionSide : ''" [ngClass]="{ 'nowrap': !isDataWrapped }" [style]="!isDataWrapped ? 'vertical-align: middle;' : 'vertical-align: top;'">
                                        <div *ngIf="_options.selectableRows && isFirstColumn(column)" title="Select Row" class="row-checkbox" [ngClass]="isSelected(d)? 'checked' : ''" (click)="toggleSelected($event, d)">
                                            <div *ngIf="isSelected(d)" class="checkmark">&#x2713;</div>
                                        </div>
                                        <div *ngIf="_options.expandableRows && this.detailTemplate && isFirstColumn(column)" class="columnAdjust" (click)="toggleExpanded(d)">
                                            <span class="sdk-icon expander">{{d.Expanded? 'expand_more' : 'chevron_right'}}</span>
                                        </div>
                                        <div *ngIf="!column.dataTemplate" class="columnAdjust">
                                            <span>{{ column.formatter? column.formatter(d[column.Name]) : d[column.Name] }}</span>
                                        </div>
                                        <ng-container *ngIf="column.dataTemplate" [ngTemplateOutlet]="column.dataTemplate()" [ngTemplateOutletContext]="{rowItem:d, index:i, value:d[column.Name], columnItem:column}"></ng-container>
                                    </td>
                                </ng-container>
                            </ng-container>

                            <ng-container *ngIf="d.isEdit">
                                <ng-container *ngFor="let column of _columns">
                                    <td *ngIf="column.isVisible && column.allowEdit" class="edit" [ngClass]="{ 'nowrap': !isDataWrapped }">
                                        <div *ngIf="!column.editTemplate" class="value"><sdk-textbox class="sdk-textbox" padding="5px" [validCharacters]="column.validCharacters ?? ''" [pattern]="column.pattern ?? ''" [height]="column.height === '' ? 'unset' : column.height" [width]="column.width === '' ? 'unset' : column.width" [border]="column.border" [style]="column.style" [value]="_activeRow[column.Name]" (changeCallBackEvent)="_activeRow[column.Name] = $event"></sdk-textbox></div>
                                        <ng-container *ngIf="column.editTemplate" [ngTemplateOutlet]="column.editTemplate()" [ngTemplateOutletContext]="{rowItem:_activeRow, index:i, columnItem:column}"></ng-container>
                                    </td>
                                    <td *ngIf="column.isVisible && !column.allowEdit">
                                        <div *ngIf="!column.dataTemplate" class="columnAdjust">
                                            <span>{{ column.formatter? column.formatter(d[column.Name]) : d[column.Name] }}</span>
                                        </div>
                                        <ng-container *ngIf="column.dataTemplate" [ngTemplateOutlet]="column.dataTemplate()" [ngTemplateOutletContext]="{rowItem:d, index:i, value:d[column.Name], columnItem:column}"></ng-container>
                                    </td>
                                </ng-container>
                            </ng-container>

                            <!-- INSERT "right" action here -->
                            <ng-container *ngFor="let column of _columnActions">
                                <td *ngIf="column.actionTemplate && column.actionSide === 'right'" class="action-right nowrap">
                                    <ng-container [ngTemplateOutlet]="column.actionTemplate()" [ngTemplateOutletContext]="{rowItem:d, activeRow:_activeRow, index:i, columnItem:column}"></ng-container>
                                </td>
                            </ng-container>
                        </tr>
                        <tr *ngIf="_options.expandableRows && detailTemplate && d.Expanded">
                            <td [attr.colspan]="_columns.length" class="row-detail">
                                <ng-container [ngTemplateOutlet]="detailTemplate" [ngTemplateOutletContext]="{rowItem:d}"></ng-container>
                            </td>
                        </tr>
                    </ng-container>
                </tbody>

                <tfoot *ngIf="_columns.length > 0 && _options.formulas && _formulas.length > 0">
                    <tr>
                        <ng-container *ngFor="let column of _columnActions">
                            <th *ngIf="column.actionTemplate && column.actionSide === 'left'" scope="col" [class]="'action-left'">
                                <div class="column"></div>
                            </th>
                        </ng-container>

                        <ng-container *ngFor="let column of _columns">
                            <th *ngIf="column.isVisible" scope="col" class="nowrap">
                                <ng-container *ngFor="let formula of _formulas">
                                    <div *ngIf="column.Name !== formula.Name"></div>
                                    <div *ngIf="column.Name === formula.Name" class="column-formula">
                                        <div class="column-footer">
                                            <span class="footer-label">{{ formula.Operation }}: </span>
                                            <span *ngIf="['Avg', 'Sum'].indexOf(formula.Operation) > -1">{{ formula.Value | number: '1.0-' + formula.Format }}</span>
                                            <span *ngIf="['Rng'].indexOf(formula.Operation) > -1">{{ formula.Value }}</span>
                                        </div>
                                    </div>
                                </ng-container>
                            </th>
                        </ng-container>

                        <ng-container *ngFor="let column of _columnActions">
                            <th *ngIf="column.actionTemplate && column.actionSide === 'right'" scope="col" [class]="'action-right'">
                                <div class="column"></div>
                            </th>
                        </ng-container>
                    </tr>
                </tfoot>
            </table>
        </div>

        <!-- Chart -->
        <div class="chart" [ngClass]="{ 'notop': !showHeader && !showOptions, 'noheader': !showHeader, 'nooptions': ((minimizeOptions === true || !showOptions) && (!showOptions || !_menuOptions) || _columns.length === 0), 'nofooter': !showFooter, 'expand-top': datasets.length > 1 && showHeader && title !== '', 'show': dataMode === 'chart'}">
            <div *ngIf="!chart.Show" class="placeholder-icon">
                <div class="sdk-icon centered">poll</div>
            </div>
            <div class="chart-container">
                <canvas #chartCanvas></canvas>
            </div>
        </div>

        <!-- Error -->
        <div *ngIf="error" class="system-error" [ngClass]="{ 'notop': !showHeader && !showOptions, 'noheader': !showHeader, 'nooptions': ((minimizeOptions === true || !showOptions) && (!showOptions || !_menuOptions) || _columns.length === 0), 'nofooter': !showFooter }">
            <div class="bold">ERROR:</div>
            <div [innerHTML]="error"></div>
        </div>

        <!-- Footer -->
        <div *ngIf="showFooter && (!_isLoading || isLoaded) && !initialLoad" class="footer" [ngClass]="{ 'nooptions': (minimizeOptions === true) && (!showOptions || _columns.length === 0 || !_menuOptions) }">
            <div *ngIf="showPaging && _total > 0 && dataMode !== 'chart'" class="left">
                <select (change)="setRows($event)">
                    <option *ngFor="let row of rowValues" [value]="row" [selected]="(rows === row) ? 'selected' : ''">{{ row }}</option>
<!-- 
                    <option value="10" [selected]="(rows === 10) ? 'selected' : ''">10</option>
                    <option value="25" [selected]="(rows === 25) ? 'selected' : ''">25</option>
                    <option value="50" [selected]="(rows === 50) ? 'selected' : ''">50</option>
                    <option value="100" [selected]="(rows === 100) ? 'selected' : ''">100</option>
                    <option value="500" [selected]="(rows === 500) ? 'selected' : ''">500</option>
                    <option value="1000" [selected]="(rows === 1000) ? 'selected' : ''">1000</option> -->
                </select>
                <span style="padding-left: 5px;">/ Page</span>
            </div>
            <div *ngIf="showPaging && _total > 0 && dataMode !== 'chart'" class="center">
                <div class="paging">
                    <table>
                        <tbody>
                            <tr>
                                <td><div class="sdk-icon" [disabled]="page < 2" [ngClass]="(page - 1) === 0 ? 'paging-btn-disabled' : 'paging-btn'" (click)="((page - 1) === 0) ? false : getPage(1)">keyboard_double_arrow_left</div></td>
                                <td><div class="sdk-icon" [disabled]="page < 2" [ngClass]="(page - 1) === 0 ? 'paging-btn-disabled' : 'paging-btn'" (click)="((page - 1) === 0) ? false : getPage(page - 1)">navigate_before</div></td>
                                <td>
                                    <select (change)="getPage($event)">
                                        <ng-container *ngFor="let pg of totalPages; let index = index">
                                            <option value="{{ index + 1 }}" [selected]="index + 1 === page">{{ pg }}</option>
                                        </ng-container>
                                    </select>
                                </td>
                                <td><div class="sdk-icon" [disabled]="page >= totalPages.length" [ngClass]="page >= totalPages.length ? 'paging-btn-disabled' : 'paging-btn'" (click)="page >= totalPages.length ? false : getPage(page + 1)">navigate_next</div></td>
                                <td><div class="sdk-icon" [disabled]="page >= totalPages.length" [ngClass]="page >= totalPages.length ? 'paging-btn-disabled' : 'paging-btn'" (click)="page >= totalPages.length ? false : getPage(totalPages.length)">keyboard_double_arrow_right</div></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div *ngIf="_total > 0" class="right">
                <span style="font-weight: 600; padding-right: 5px;">Total:</span>{{ _total | number:'1.0-0': 'en-US' }}
            </div>
        </div>
    </div>

    <sdk-loading title="" [isLoading]="_isLoading" [showTimer]="showLoadingTimer" [style]="loadingStyle" [id]="_name"></sdk-loading>

    <div title="" *ngIf="showOptions && (!_isLoading || isLoaded) && !initialLoad" class="sdk-datagrid-windows" [ngClass]="dataClass">
        <sdk-datagrid-settings-option *ngIf="optionTitle === 'Settings'"
            [settings]="settings"
            [columns]="_originalColumns"
            (closeEvent)="closeDataOptions()"
            (saveEvent)="saveSettings($event)"
            (applyEvent)="applySettings($event)"></sdk-datagrid-settings-option>

        <sdk-datagrid-columns-option *ngIf="optionTitle === 'Columns'"
            [originalColumns]="_originalColumns"
            [columns]="_columns"
            (closeEvent)="closeDataOptions()"
            (applyEvent)="applyColumnOptions($event)"></sdk-datagrid-columns-option>

        <sdk-datagrid-filter-option *ngIf="optionTitle === 'Filters'"
            [columns]="_columns"
            [filters]="filters"
            [filterTypes]="filterTypes"
            [customFilters]="customFilters"
            (closeEvent)="closeDataOptions()"
            (applyEvent)="applyFilterOptions($event)"></sdk-datagrid-filter-option>

        <sdk-datagrid-sort-option *ngIf="optionTitle === 'Sorting'"
            [columns]="_columns"
            [sorts]="sorts"
            (closeEvent)="closeDataOptions()"
            (applyEvent)="applySortOptions($event)"></sdk-datagrid-sort-option>

        <sdk-datagrid-formula-option *ngIf="optionTitle === 'Formulas'"
            [columns]="_columns"
            [formulas]="_formulas"
            [originalFormulas]="formulas"
            (closeEvent)="closeDataOptions()"
            (applyEvent)="applyFormulaOptions($event)"></sdk-datagrid-formula-option>

        <sdk-datagrid-export-option *ngIf="optionTitle === 'Export'"
            (closeEvent)="closeDataOptions()"
            (applyEvent)="applyExportOptions($event)"></sdk-datagrid-export-option>

        <sdk-datagrid-chart-option *ngIf="optionTitle === 'Chart'"
            [columns]="_columns"
            [data]="fullData"
            [chart]="chart"
            (closeEvent)="closeDataOptions()"
            (applyEvent)="applyChartOptions($event, autoClosePanel)"></sdk-datagrid-chart-option>

        <div *ngFor="let window of windowAddons">
            <ng-container [ngComponentOutlet]="window" [ndcDynamicInputs]="windowInputs" [ndcDynamicOutputs]="windowOutputs"></ng-container>
        </div>
    </div>
</div>
