<div class="container justify-content-center">
  <div class="svg-container">
  <svg id="countryfocus" class="map" [attr.clip-path]="'url(#circle-clip)'">
      <defs>
        <clipPath id="circle-clip">
          <circle cx="50" cy="50" r="50" />
        </clipPath>
      </defs>
  </svg>
  </div>
  <div class="spacer"></div>
  <ccms-ets-status [status]="marketStatus?.id" focus="true"></ccms-ets-status>
</div>


