<div class="spacer"></div>
<ccms-credit-forecasts-chart #chart
	[riskForecastData]="riskForecastData$ | async"
	[unriskForecastData]="unriskCreditForecasts"
	[forecastType]="forecastType"
	[creditsMarket]="creditsMarket"
	[hidden]="isEdit || unriskCreditForecasts.length === 0"></ccms-credit-forecasts-chart>
<div class="spacer"></div>
<form [formGroup]="form" *ngIf="form">
	<div *ngIf="isEdit" class="edit-header">
    <div class="edit-buttons">
      <div class="add-forecast">
			  <ccms-icon-button class="small" icon="icon-expand-plus" (click)="addRow()"
				  aria-label="Add credit forecast">Row</ccms-icon-button>
	    </div>
		  <div class="left-spacer">
				<ccms-icon-button class="small" icon="icon-upload" (click)="showImportDialog()"
					aria-label="Import credit forecasts">Rows</ccms-icon-button>
		  </div>
    </div>
		<mat-form-field class="credits-market" appearance="outline">
			<mat-label>credits market</mat-label>
				<mat-select formControlName="creditsMarket" (selectionChange)="onCreditsMarketChange($event)"
					matTooltip="{{getSelectedCreditsMarket()}}">
				<mat-option *ngFor="let market of creditsMarkets" [value]="market.id" [matTooltip]="market.name">
					{{market.name}}
				</mat-option>
			</mat-select>
		</mat-form-field>
	</div>
  <div class="form-grid">
	<table mat-table [dataSource]="unriskCreditForecasts">
		<caption>Credits Forecast {{forecastType}}</caption>
		<ng-container [matColumnDef]="col.key" *ngFor="let col of columnsSchema">
			<th mat-header-cell *matHeaderCellDef>
				{{ col.label }}
			</th>
			<td mat-footer-cell *matFooterCellDef>
				<span *ngIf="col.key === 'year'" class="footer-row">
					Total
				</span>
				<span *ngIf="col.key === 'credits'" class="footer-row">
					{{totalCredits | number}}
				</span>
				<span *ngIf="col.key === 'nonObligatedCredits'" class="footer-row">
					{{totalNonObligatedCredits | number}}
				</span>
				<span *ngIf="col.key === 'fundingAmount'" class="footer-row">
					{{totalFundingAmount | currency:'USD':true:'1.0-0'}}
				</span>
				<span *ngIf="col.key === 'discretionarySpend'" class="footer-row">
					{{totalDiscretionarySpend | currency:'USD':true:'1.0-0'}}
				</span>
				<span *ngIf="col.key === 'nonDiscretionarySpend'" class="footer-row">
					{{totalNonDiscretionarySpend | currency:'USD':true:'1.0-0'}}
				</span>
				<span *ngIf="col.key === 'opEx'" class="footer-row">
					{{totalOpEx | currency:'USD':true:'1.0-0'}}
				</span>
				<span *ngIf="col.key === 'prepayment'" class="footer-row">
					{{totalPrepayment | currency:'USD':true:'1.0-0'}}
				</span>
			</td>
			<td mat-cell *matCellDef="let element; let i = index;">
				<div *ngIf="isEdit" [ngSwitch]="col.type">
					<mat-form-field *ngSwitchCase="'number'" class="form-input" subscriptSizing="dynamic" appearance="outline">
						<input type="text" formControlName="{{col.key}}-{{element.rowId}}" matInput class="no-brand"
            (keydown)="onKeyDown($event, col.key, element.rowId)"
            (keyup)="onKeyUp($event, col.key, element.rowId)"
            (onblur)="onBlur($event, col.key, element.rowId)"/>
					</mat-form-field>
					<mat-form-field *ngSwitchCase="'funding-amount'" class="form-input" subscriptSizing="dynamic">
						<input type="text" formControlName="{{col.key}}-{{element.rowId}}" matInput
							value="{{fundingAmountByYear.get(element.year)}}" />
					</mat-form-field>
					<mat-form-field *ngSwitchCase="'market-price'" class="form-input" subscriptSizing="dynamic">
						<input type="text" formControlName="{{col.key}}-{{element.rowId}}" matInput
							value="{{prices.get(element.year)}}" />
					</mat-form-field>
					<div class="btn-delete" *ngSwitchCase="'deleteButton'" subscriptSizing="dynamic">
						<span class="icon-trash-can delete-red" (click)="deleteRow(element.rowId)" matTooltip="delete"
							aria-label="delete"></span>
					</div>
				</div>
				<div *ngIf="!isEdit" [ngSwitch]="col.key">
					<span *ngSwitchCase="'year'">
						{{element.year}}
					</span>
					<span *ngSwitchCase="'credits'">
						{{element.credits | number }}
					</span>
					<span *ngSwitchCase="'pricePerTonne'">
						{{element.pricePerTonne | currency:'USD':true:'1.0-2'}}
					</span>
					<span *ngSwitchCase="'marketPrice'">
						{{prices.get(element.year)}}
					</span>
					<span *ngSwitchCase="'fundingAmount'">
						{{element.fundingAmount | currency:'USD':true:'1.0-0'}}
					</span>
					<span *ngSwitchCase="'prepayment'">
						{{element.prepayment | currency:'USD':true:'1.0-0'}}
					</span>
					<span *ngSwitchCase="'opEx'">
						{{element.opEx | currency:'USD':true:'1.0-0'}}
					</span>
					<span *ngSwitchCase="'priceFloor'">
						{{element.priceFloor | currency:'USD':true:'1.0-2'}}
					</span>
					<span *ngSwitchCase="'discountRate'">
						{{convertPercentage(element.discountRate) | percent:'1.0-2'}}
					</span>
					<span *ngSwitchCase="'discretionarySpend'">
						{{element.discretionarySpend | currency:'USD':true:'1.0-0'}}
					</span>
					<span *ngSwitchCase="'nonDiscretionarySpend'">
						{{element.nonDiscretionarySpend | currency:'USD':true:'1.0-0'}}
					</span>
					<span *ngSwitchCase="'nonObligatedCredits'">
						{{element.nonObligatedCredits | number }}
					</span>
				</div>
			</td>
		</ng-container>
		<tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
		<tr mat-row *matRowDef="let row; columns: displayedColumns; let i = index;"></tr>
		<tr mat-footer-row *matFooterRowDef="displayedColumns"></tr>
	</table>
  </div>
</form>