<div class="content">
    <div class="header">
        <div class="totals">
            <div class="total"><span>Total Purchased:</span>{{ totalPurchased | number }}</div>
            <div class="total"><span>Total Sold:</span>{{ totalSold | number }}</div>
            <div class="total"><span>Balance:</span>{{ totalBalance | number }}</div>
        </div>
    </div>
    <div class="data">
        <div class="summary-grid">
            <sdk-datagrid fontFamily="'GothamNarrowBook', sans-serif, 'Helvetica Neue', Arial"
                          [uniqueIdentifier]="uniqueIdentifier"
                          [datasets]="datasets"
                          [name]="activeView"
                          [data]="data"
                          [fullData]="fullData"
                          [columns]="columns"
                          [options]="gridOptions"
                          [dbPage]="dbPage"
                          [dbTotal]="dbTotal"
                          [dbMAXRECORDS]="MAXRECORDS"
                          [isDataWrapped]=false
                          [minimizeOptions]=false
                          [isLoading]="isLoading"
                          [error]="error"
                          [detailTemplate]="rowDetail"
                          (loadDataEvent)="loadData($event)">
            </sdk-datagrid>
        </div>
    </div>
    <div class="footnote">
        {{ footnote }}
    </div>
</div>

<ng-template #rowDetail let-rowAccount="rowItem">
    <div class="detail">
        <div class="detail-title">Last&nbsp;up&nbsp;to&nbsp;{{detailTransactionsCount}}&nbsp;transactions</div>
        <div class="detail-table">
            <table class="table table-striped">
                <caption style="display: none;">Account Summary</caption>
                <thead>
                    <tr>
                        <th>
                            <div title="Transaction Date" class="longtext">Transaction Date</div>
                        </th>
                        <th>
                            <div title="Deal Type" class="longtext">Deal Type</div>
                        </th>
                        <th>
                            <div title="Counterparty" class="longtext">Counterparty</div>
                        </th>
                        <th>
                            <div title="Product" class="longtext">Product</div>
                        </th>
                        <th>
                            <div title="Credits" class="longtext">Credits</div>
                        </th>
                        <th>
                            <div title="Price Per Ton" class="longtext">Price Per Ton</div>
                        </th>
                        <th>
                            <div title="Credit Source" class="longtext">Credit Source</div>
                        </th>
                        <th>
                            <div title="Registry" class="longtext">Registry</div>
                        </th>
                        <th>
                            <div title="Meta Registry" class="longtext">Meta Registry</div>
                        </th>
                        <th>
                            <div title="Change Date" class="longtext">Change Date</div>
                        </th>
                        <th>
                            <div title="Deal Number" class="longtext">Deal Number</div>
                        </th>
                        <th>
                            <div title="Price Estimate" class="longtext">Price Estimate</div>
                        </th>
                        <th>
                            <div title="BeZero Rating" class="longtext">BeZero Rating</div>
                        </th>
                        <th>
                            <div title="Sylvera Rating" class="longtext">Sylvera Rating</div>
                        </th>
                        <th>
                            <div title="Calyx GHG Rating" class="longtext">Calyx GHG Rating</div>
                        </th>
                        <th>
                            <div title="Cvx Company" class="longtext">Cvx Company</div>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let transaction of getAcccountTransactions(rowAccount.accountName) | async">
                        <td>
                            <div [title]="transaction.transactionDate | date:dateFormat" class="longtext">{{ transaction.transactionDate | date:dateFormat }}</div>
                        </td>
                        <td>
                            <div [title]="transaction.dealTypeId" class="longtext">{{ transaction.dealTypeId }}</div>
                        </td>
                        <td>
                            <div [title]="transaction.counterparty" class="longtext">{{ transaction.counterparty }}</div>
                        </td>
                        <td>
                            <div [title]="transaction.product" class="longtext">{{ transaction.product }}</div>
                        </td>
                        <td>
                            <div [title]="transaction.credits" class="longtext">{{ transaction.credits | number: numberFormat  }}</div>
                        </td>
                        <td>
                            <div [title]="transaction.pricePerTon" class="longtext">{{ transaction.pricePerTon | currency : 'USD'  }}</div>
                        </td>
                        <td>
                            <div [title]="transaction.creditSourceId" class="longtext">{{ transaction.creditSourceId }}</div>
                        </td>
                        <td>

                            <div *ngIf="transaction.registry && transaction.registry.websiteUrl && transaction.registry.websiteUrl !== ''">
                                <a [href]="transaction.registry.websiteUrl" target="_blank">{{ transaction.registry.longName }}</a>
                            </div>
                            <div *ngIf="!transaction.registry || !transaction.registry.websiteUrl || transaction.registry.websiteUrl === ''">
                                {{ transaction.registry.longName }}
                            </div>
                        </td>
                        <td>
                            <div *ngIf="!transaction.metaRegistryProjectId || transaction.metaRegistryProjectId == ''" [title]="transaction.metaRegistryProjectId" class="longtext">{{ transaction.metaRegistryProjectId }}</div>
                            <div *ngIf="transaction.metaRegistryProjectId && transaction.metaRegistryProjectId !== ''" class="longtext">
                                <div class="link" (click)="gotoProject(rowAccount.accountName, transaction)">{{ transaction.metaRegistryProjectId }}</div>
                            </div>
                        </td>
                        <td>
                            <div [title]="transaction.changeDate" class="longtext">{{ transaction.changeDate  | date:dateFormat  }}</div>
                        </td>
                        <td>
                            <div [title]="transaction.dealNumber" class="longtext">{{ transaction.dealNumber }}</div>
                        </td>
                        <td>
                            <div [title]="transaction.directPriceEstimate" class="longtext">{{ transaction.directPriceEstimate | currency : 'USD'   }}</div>
                        </td>
                        <td>
                            <div [title]="transaction.beZeroRating" class="longtext">{{ transaction.beZeroRating }}</div>
                        </td>
                        <td>
                            <div [title]="transaction.sylveraRating" class="longtext">{{ transaction.sylveraRating }}</div>
                        </td>
                        <td>
                            <div [title]="transaction.calyxGhgRating" class="longtext">{{ transaction.calyxGhgRating }}</div>
                        </td>
                        <td>
                            <div [title]="transaction.cvxCompany" class="longtext">{{ transaction.cvxCompany }}</div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</ng-template>