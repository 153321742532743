import { Component, ElementRef, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild } from "@angular/core";
import { FormControl } from "@angular/forms";
import { Observable, map, startWith } from "rxjs";

@Component({
  selector: 'multi-select',
  templateUrl: 'multi-select.component.html',
  styleUrls: ['multi-select.component.scss'],
})
export class MultiSelectComponent implements OnChanges {
  @ViewChild('search') searchTextBox!: ElementRef;
  @Input('data') data!: string[];
  @Input('label') label!: string;
  @Input('size') size!: string;
  @Output() changed: EventEmitter<any[]> = new EventEmitter<any[]>();
  
  selectFormControl = new FormControl();
  searchTextboxControl = new FormControl();
  selectedValues: any[] = [];

  filteredOptions!: Observable<any[]>;

  ngOnInit() {
    this.initializeSearch();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.data && !changes.data.isFirstChange()) {
      this.initializeSearch();
    }
  }

  private initializeSearch() {
     /**
     * Set filter event based on value changes 
     */
     this.filteredOptions = this.searchTextboxControl.valueChanges
     .pipe(
       startWith<string>(''),
       map(name => this._filter(name))
     );
  }

  /**
   * Used to filter data based on search input 
   */
  private _filter(name: string): string[] {
    const filterValue = name.toLowerCase();
    // Set selected values to retain the selected checkbox state 
    this.setSelectedValues();
    this.selectFormControl.patchValue(this.selectedValues);
    let filteredList = this.data.filter(option => option?.toLowerCase().startsWith(filterValue));
    return filteredList;
  }

/**
 * Remove from selected values based on uncheck
 */
  selectionChange(event) {
    if (event.isUserInput && !event.source.selected) {
      let index = this.selectedValues.indexOf(event.source.value);
      this.selectedValues.splice(index, 1);
      this.changed.emit(this.selectedValues);
    }
  }

  optionsChanged(event) {
    this.setSelectedValues();
  }

  openedChange(e) {
    // Set search textbox value as empty while opening selectbox 
    this.searchTextboxControl.patchValue('');
    // Focus to search textbox while clicking on selectbox
    if (e) {
      this.searchTextBox.nativeElement.focus();
    }
  }

  /**
   * Clearing search textbox value 
   */
  clearSearch(event) {
    event.stopPropagation();
    this.searchTextboxControl.patchValue('');
  }

  /**
   * Set selected values to retain the state 
   */
  setSelectedValues() {
    let pushed = false;
    if (this.selectFormControl.value && this.selectFormControl.value.length > 0) {
      this.selectFormControl.value.forEach((e) => {
        if (this.selectedValues.indexOf(e) == -1) {
          this.selectedValues.push(e);
          pushed = true;
        }
      });
    }

    if (pushed) {
      this.changed.emit(this.selectedValues);
    }
  }

  resetOptions() {
    this.selectedValues = [];
    this.selectFormControl.patchValue(this.selectedValues);
  }
}