<div class="content">
    <sdk-message [(message)]="message" [style]="messageStyle"></sdk-message>
    <div class="header">
        <div *ngIf="callbackLabel !== ''" class="back" (click)="back()"><span class="arrow">◄</span><span class="highlight">{{ callbackLabel }}</span></div>
        <div class="title">{{ title }}</div>
    </div>
    <div class="data">
        <tabs [tabs]="tabs"
              [activeTab]="activeTab"
              [share]="true"
              [beforeTabChange]="confirmTabChange.bind(this)"
              (messageEvent)="showMessage($event)">
        </tabs>
    </div>
</div>
