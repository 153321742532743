import { Injectable } from '@angular/core';
import { Observable, from } from 'rxjs';
import { ClearBluePrice } from '~/models/shared/markets/clearblue-price';
import { HttpService } from '~/services/core/http.service';

@Injectable({
  providedIn: 'root',
})
export class ClearBluePriceService {
    constructor(private readonly _httpClient: HttpService) { }

    public getPricesByIndexName(indexName: string): Observable<Array<ClearBluePrice>> {
        const priceUrl = `/ClearBlue/Prices?indexName=${indexName}`;
        return from(this._httpClient.Get(priceUrl));
    }

    public getPricesByIndexNameDateRange(indexName: string, startDate: Date, endDate: Date){
        const priceUrl = `/ClearBlue/PricesByDateRange?indexName=${indexName}&startDate=${startDate}&endDate=${endDate}`;
        return from(this._httpClient.Get(priceUrl));
    }

    public getPricesByIndexNameMonthly(indexName: string, numberMonthsToReturn: number) {
        let priceUrl = `/ClearBlue/EndOfMonthPrice?indexName=${indexName}&numberOfMonths=${numberMonthsToReturn}`;
        return from(this._httpClient.Get(priceUrl));
    }
}