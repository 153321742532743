<div *ngIf="isLoggedIn">
	<header [title]="title" (reloadPage)="reloadPage()" [user]="user" [photo]="photo"></header>
  <search [expanded]="search" (collapse)="handleCollapse()"></search>
	<nav #nav [class]="layoutCssClass" [expand]="expand" (setLayoutClass)="setLayoutClass()" (reloadPage)="reloadPage()" (showSearch)="showSearch($event)"></nav>

	<content [class]="layoutCssClass">
		<router-outlet></router-outlet>
	</content>

	<footer></footer>
</div>

<div *ngIf="modal" class="modal-window">
	<div class="locked"></div>
	<ng-container [ngComponentOutlet]="modal.type" [ndcDynamicInputs]="modal.inputs" [ndcDynamicOutputs]="modal.outputs"></ng-container>
</div>

<div *ngIf="!isLoggedIn && layoutCssClass !== 'noaccess'">
    <div class="auth">Authenticating...</div>
</div>

<access *ngIf="layoutCssClass === 'noaccess'"></access>
<error *ngIf="error !== ''" [message]="error"></error>

<div *ngIf="showAlert" class="modal-window">
	<div class="locked"></div>
	<alert
		[title]="alertTitle"
		[message]="alertMessage"
		[continueText]="alertContinueText"
		[cancelText]="alertCancelText"
		[message]="alertMessage"
		(continueEvent)="alertContinue()"
		(cancelEvent)="alertCancel()"></alert>
</div>

<sdk-loading [isLoading]="isLoading"></sdk-loading>