<ng-template #template class="content">
	<div class="charts">
		<div class="chart">
			<canvas id="creditSumProjectScope" data-chart-type="credit" data-chart-label="Credit by Scope"></canvas>
		</div>
		<div class="chart">
			<canvas id="creditSumRegion" data-chart-type="credit" data-chart-label="Credit by Region"></canvas>
		</div>
	</div>
	<div class="options">
		<options [options]="options" [closeWindow]="closeOptionsWindow"></options>
	</div>
	<sdk-loading [isLoading]="isLoading"></sdk-loading>
</ng-template>