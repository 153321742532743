import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { moveItemInArray } from '@angular/cdk/drag-drop';

import { SDKDataGridColumn } from '../../models/datagrid-column';
import { FormulaOperation, SDKDataGridFormula } from '../../models/datagrid-formula';

@Component({
    selector: 'sdk-datagrid-formula-option',
    templateUrl: './formula-option.component.html',
    styleUrls: ['./formula-option.component.scss']
})

export class SDKDataGridFormulaOptionComponent implements OnChanges {
    @Input() columns: SDKDataGridColumn[] = [];
    @Input() formulas: SDKDataGridFormula[] = [];
    @Input() originalFormulas: SDKDataGridFormula[] = [];
    @Output() closeEvent = new EventEmitter();
    @Output() applyEvent = new EventEmitter<SDKDataGridColumn[]>();

    public columnList: SDKDataGridColumn[] = [];
    public formulaList: SDKDataGridFormula[] = [];

    public ngOnChanges(_args: any) {
        this.initialze();
    }

    public initialze() {
        this.formulaList = [];
        
        if (this.formulas) {
            this.formulas.slice(0).forEach((formula: any) => {
                let formulaNdx: number = this.originalFormulas.findIndex((f: any) => f.ID === formula.ID && f.Name === formula.Name);

                this.formulaList.push({
                    ID: (formulaNdx === -1) ? formula.ID : "_original_",
                    Name: formula.Name,
                    Operation: formula.Operation,
                    Format: formula.Format,
                    Value: formula.Value
                });
            });
        }

        this.columnList = this.columns.slice(0).filter(c => c.isVisible && !c.actionTemplate);
    }

    public getColumnOriginalName(column: SDKDataGridColumn): string {
        let originalName = column.Name;

        if (column.DisplayName && column.DisplayName !== "") {
            originalName = column.DisplayName;
        }

        return originalName;
    }

    public addFormula() {
        this.formulaList.push({
            ID: new Date().getTime().toString(),
            Name: "",
            Operation: FormulaOperation.None,
            Format: 0,
            Value: 0
        });
    }

    public deleteFormula(formula: SDKDataGridFormula) {
        this.formulaList = this.formulaList.filter(f => f.ID !== formula.ID);
    }

    public addColumn(event: any, formula: SDKDataGridFormula) {
        let ndx = this.formulaList.findIndex((f: SDKDataGridFormula) => f.ID === formula.ID);

        if (ndx > -1) {
            this.formulaList[ndx].Name = event.target.value;
        }
    }

    public addOperation(event: any, formula: SDKDataGridFormula) {
        let ndx = this.formulaList.findIndex((f: SDKDataGridFormula) => f.ID === formula.ID);

        if (ndx > -1) {
            this.formulaList[ndx].Operation = event.target.value;
        }
    }

    public addFormat(event: any, formula: SDKDataGridFormula) {
        let ndx = this.formulaList.findIndex((f: SDKDataGridFormula) => f.ID === formula.ID);

        if (ndx > -1) {
            this.formulaList[ndx].Format = (event.target.value === "" ? 0 : event.target.value);
        }
    }

    public drop(event: any) {
        moveItemInArray(this.formulaList, event.previousIndex, event.currentIndex);
    }

    public reset() {
        if (confirm("Reset Formulas... Are you sure?")) {
            this.formulaList = [];

            this.originalFormulas.slice(0).forEach((formula: any) => {
                this.formulaList.push({
                    ID: "_original_",
                    Name: formula.Name,
                    Operation: formula.Operation,
                    Format: formula.Format,
                    Value: formula.Value
                });
            });
        }
    }

    public apply() {
        const tmp: SDKDataGridFormula[] = [];

        this.originalFormulas.slice(0).forEach((formula: any) => {
            tmp.push({
                ID: formula.ID,
                Name: formula.Name,
                Operation: formula.Operation,
                Format: formula.Format,
                Value: formula.Value
            });
        });

        this.formulaList.filter((formula: any) => formula.ID !== "_original_").forEach((formula: any) => {
            if (!(formula.Name === "" || formula.Operation === "")) {
                formula.Value = "";
                
                tmp.push(formula);
            }
        });

        this.formulaList = tmp;

        this.applyEvent.emit(this.formulaList);
    }

    public close() {
        this.closeEvent.emit();
    }
}
