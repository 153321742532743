import { Component, Input, TemplateRef, ViewChild } from "@angular/core";
import { Filters, SDKDataGridDataset, SDKDataGridOptions, SDKDataGridSettings } from "sdk-datagrid";
import { BaseComponent } from "~/pages/base.component";
import { DeveloperProfile } from "~/services/shared/developers-screening/models/developer-summary";
import { FormatterService } from "~/services/shared/formatter.service";
import { IssuanceSearchOptions, IssuanceService } from "~/services/shared/issuances/issuance.service";
import { SettingsGrid } from '~/services/shared/grid/models/settings-grid.model';
import { GridHandlerService } from '~/services/shared/grid/grid-handler.service';

@Component({
    selector: 'developer-credits',
    templateUrl: './developer-credits.component.html',
    styleUrls: ['./developer-credits.component.scss']
})

export class DeveloperCreditsComponent extends BaseComponent implements SettingsGrid {
    @Input() developer!: DeveloperProfile;
    @ViewChild('eligibilityTemplate') eligibilityTemplate!: TemplateRef<any>;

	public isLoading: boolean = true;
	public activeView: string = "Issuance";
	public datasets: SDKDataGridDataset[] = [
		{
			Title: "Issuance",
			DbName: "Issuance",
		}
	];
	public columns: any[] = [
		{ Name: "registry", DisplayName: "Registry" },
		{ Name: "id", DisplayName: "ID" },
		{ Name: "name", DisplayName: "Name" },
    { Name: "productType", DisplayName: "ProductType" },
		{ Name: "vintage", DisplayName: "Vintage", isVisible: true },
		{ Name: "quantity", DisplayName: "Quantity", isVisible: true, formatter: (value: any) => this.formatterService.formatNumber(value) },
		{ Name: "action", DisplayName: "Action", isVisible: true },
    { Name: "eligibility", DisplayName: "Program Eligibility", isVisible: true, dataTemplate: () => this.eligibilityTemplate},
		{ Name: "serialNumber", DisplayName: "Serial Number" },
		{ Name: "issuanceDate", DisplayName: "Issuance Date", isVisible: true, formatter: (value: any) => this.formatterService.formatDate(value, "MM/dd/yyyy") },
		{ Name: "retirementDate", DisplayName: "Retirement Date", isVisible: true, formatter: (value: any) => this.formatterService.formatDate(value, "MM/dd/yyyy") },
		{ Name: "projectType", DisplayName: "Project Type", isVisible: true },
		{ Name: "certification", DisplayName: "Certification" },
		{ Name: "notes", DisplayName: "Notes" },
		{ Name: "retiredFor", DisplayName: "Retired For" },
	];

	// Settings grid interface Implementation:
	public uniqueIdentifier = "developers-screening.developer-detail.credits.developer-credits";
	public allSettings: SDKDataGridSettings[] = [];
	public errorHanlder = (message, error) => {
		console.error(`${message} ${error?.message}`);
	}

	public gridOptions: SDKDataGridOptions = {
		settings: true,
		columnSettings: true,
		filtering: true,
		sorting: true,
		formulas: true,
		charts: false,
		export: true,
		expandableRows: false,
		selectableRows: false,
		autoClosePanel: true
	};
	public filterTypes: any = [Filters.Equals, Filters.Contains];

	public data: string = "";
	public fullData: string = "";
	public forceReload: boolean = false;
	public MAXRECORDS: number = 1000;
	public dbPage: number = 0;
	public dbTotal: number = 0;
	public error: string = "";

	public type: string = "";
	public typeName: string = "Graph";

	constructor(
        private readonly issuanceService: IssuanceService,
		private readonly formatterService: FormatterService,
		private gridHandlerService: GridHandlerService
	) {
        super();
    }

    public async ngOnInit() {
		this.loadData({ chart: true });
	}
    
    public async ngOnChanges() { 
		await this.loadData();
		this.gridHandlerService.loadSettings(this);
    }

    public async loadData(event: any = null) {
        if (Object.keys(this.developer).length === 0) {
            return;
        }
		
		this.isLoading = true;

		let parameters = this.buildParameters(event);
		let fullLoad: boolean = false;

		if (event && (event.chart !== undefined || event.export !== undefined)) {
			fullLoad = true;
            parameters.take = 999999;
            parameters.skip = 0;
		}

		try {
            await this.issuanceService.search(parameters).then((data: any) => {
				if (data) {
                    if (fullLoad) {
                        this.fullData = data.values;
                    } else {
						this.resetVariables();

                        this.data = data.values;
                        this.dbPage = parseInt(data.currentPage);
                        this.dbTotal = parseInt(data.totalCount);
                        this.error = "";
                    }
                }
			});
		} catch (error: any) {
			this.error = error.message;
		}

		this.forceReload = false;
		this.isLoading = false;
	}

	public saveSettings(event: SDKDataGridSettings[]) {
		this.gridHandlerService.saveSettings(this, event);
	}

    private buildParameters(event: any = null): IssuanceSearchOptions {
		let parameters = {} as IssuanceSearchOptions;
        parameters.type = this.type;
        parameters.projectIds = this.developer.projects.map(project => project.id);
		parameters.sortOptions = [];

		if (event) {
			this.buildParametersPage(event, parameters);
			this.buildParametersRows(event, parameters);
			this.buildParametersSorts(event, parameters);
		}
		return parameters;
	}

	private buildParametersPage(event: any, parameters: any) {
		if (event.page) {
			const page = parseInt(event.page);
            parameters.skip = event.rows * (page - 1);
		}
	}

	private buildParametersRows(event: any, parameters: any) {
        parameters.take = this.MAXRECORDS;
	}

	private buildParametersSorts(event: any, parameters: any) {
		if (event.sorts) {
			event.sorts.forEach((sort: any) => {
                parameters.sortOptions.push({
                    property: sort.Name,
                    isDescending: sort.Sort === "desc"
                })
			});
		}
	}

	private resetVariables() {
		this.data = "";
		this.fullData = "";
		this.dbPage = 0;
		this.dbTotal = 0;
		this.error = "";
	}
}