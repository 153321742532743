<div class="content">
    <div class="header">
        <div class="title">{{ title }}</div>
    </div>
    
    <div class="grid">
        <sdk-datagrid
            fontFamily="'GothamNarrowBook', sans-serif, 'Helvetica Neue', Arial"
            [uniqueIdentifier]="uniqueIdentifier"
            [datasets]="datasets"
            [name]="activeView"
            [data]="data"
            [fullData]="fullData"
            [columns]="columns"
            [customFilters]="customFilters"
            [options]="gridOptions"
            [optionAddons]="[exportOptionComponent]"
            [windowAddons]="[exportWindowComponent]"
            [showHeader]=false
            [minimizeOptions]=false
            [showPanelOverlay]=false
            [autoClosePanel]=false
            [dbPage]="dbPage"
            [dbTotal]="dbTotal"
            [dbMAXRECORDS]="MAXRECORDS"
            [isDataWrapped]=true
            [isLoading]="isLoading"
            [error]="error"
            [rowValues]="[10, 25, 50, 100]"
            [defaultRow]="50"
            [settings]="allSettings"
            (loadDataEvent)="loadData($event)"
            (selectedRowActionEvent)="showDetail($event)"
            (settingsSavedEvent)="saveSettings($event)">
        </sdk-datagrid>
    </div>

    <ng-template #registryTemplate let-rowItem="rowItem">
        <div (click)="$event.stopPropagation()">
            <div *ngIf="rowItem && rowItem.registryUrl && rowItem.registryUrl !== ''">
                <a [href]="rowItem.registryUrl" target="_blank">{{ rowItem.registry }}</a>
            </div>
            <div *ngIf="rowItem && (!rowItem.registryUrl || rowItem.registryUrl === '')">
                {{ rowItem.registry }}
            </div>
        </div>
    </ng-template>

    <ng-template #websiteTemplate let-rowItem="rowItem">
        <div (click)="$event.stopPropagation()">
            <div *ngIf="rowItem && rowItem.projectWebsite && rowItem.projectWebsite !== ''">
                <a [href]="rowItem.projectWebsite" target="_blank">LINK</a>
            </div>
            <div *ngIf="rowItem && (!rowItem.projectWebsite || rowItem.projectWebsite === '')">
                -
            </div>
        </div>
    </ng-template>

    <ng-template #programsTemplate let-rowItem="rowItem">
      <div (click)="$event.stopPropagation()">
          <div>
            {{ getTotalEligibility(rowItem).join(', ') }}
          </div>
      </div>
    </ng-template>
  
    <ng-template #accreditationsTemplate let-rowItem="rowItem">
      <div (click)="$event.stopPropagation()">
          <div>
            {{ getAccreditations(rowItem.accreditations).join(', ') }}
          </div>
      </div>
    </ng-template>

    <ng-template #sylveraRating let-rowItem="rowItem">
        <div>
            {{rowItem.sylveraRating}}{{rowItem.isProvisionalSylveraRating ? '*' : ''}}
        </div>
    </ng-template>


    <div class="footnote">
      {{ footnote }}
    </div>
</div>
