import * as d3 from 'd3'

export class MapProperties {
    public title = "Projects";
    public tickSize = 6;
    public width = 36 + this.tickSize;
    public height = 320;
    public marginTop = 10;
    public marginRight = 10 + this.tickSize;
    public marginBottom = 20;
    public marginLeft = 5;
    public ticks = this.height / 64;
    public tickFormat = null;
    public tickValues = null;
}

export class MapMethods {
    public static colorLegend(color: any, props: MapProperties): SVGSVGElement {
        const svg: any = d3.create("svg")
            .attr("width", props.width)
            .attr("height", props.height)
            .attr("viewBox", [0, 0, props.width, props.height])
            .style("overflow", "visible")
            .style("display", "block");

        let tickAdjust = (g: any) => g.selectAll(".tick line").attr("x1", props.marginLeft - props.width + props.marginRight);
        let x;

        // Continuous
        if (color.interpolate) {
            const n = Math.min(color.domain().length, color.range().length);

            x = color.copy().rangeRound(d3.quantize(d3.interpolate(props.height - props.marginBottom, props.marginTop), n));

            svg.append("image")
                .attr("x", props.marginLeft)
                .attr("y", props.marginTop)
                .attr("width", props.width - props.marginLeft - props.marginRight)
                .attr("height", props.height - props.marginTop - props.marginBottom)
                .attr("preserveAspectRatio", "none")
                .attr("xlink:href", this.ramp(color.copy().domain(d3.quantize(d3.interpolate(0, 1), n))).toDataURL());
        }

        // Sequential
        else if (color.interpolator) {
            x = this.colorSequential(color, svg, props);
        }

        // Threshold
        else if (color.invertExtent) {
            x = this.colorThreshold(color, svg, props);
        }

        // Ordinal
        else {
            x = d3.scaleBand()
                .domain(color.domain())
                .rangeRound([props.height - props.marginBottom, props.marginTop]);

            svg.append("g")
                .selectAll("rect")
                .data(color.domain())
                .join("rect")
                .attr("y", x)
                .attr("x", props.marginLeft)
                .attr("height", Math.max(0, x.bandwidth() - 1))
                .attr("width", props.width - props.marginLeft - props.marginRight)
                .attr("fill", color);

            tickAdjust = () => { };
        }

        svg.append("g")
            .attr("transform", `translate(${props.width - props.marginRight},0)`)
            .call(d3.axisRight(x)
                .ticks(props.ticks, typeof props.tickFormat === "string" ? props.tickFormat : null)
                .tickFormat(typeof props.tickFormat === "function" ? props.tickFormat : null)
                .tickSize(props.tickSize)
                .tickValues(props.tickValues)
            )
            .call(tickAdjust)
            .call((g: any) => g.select(".domain").remove())
            .call((g: any) => g.append("text")
                .attr("x", props.marginLeft - props.width + props.marginRight)
                .attr("y", 0)
                .attr("fill", "currentColor")
                .attr("text-anchor", "start")
                .attr("font-weight", "bold")
                .attr("color", "black")
                .text(props.title));

        return svg.node();
    }

    private static colorThreshold(color: any, svg: any, props: any) {
        const quantiles = color.quantiles ? color.quantiles() : color.domain();
        const thresholds = color.thresholds ? color.thresholds() : quantiles;

        const tickFormatLogic = typeof props.tickFormat === "string" ? d3.format(props.tickFormat) : props.tickFormat;
        const thresholdFormat = props.tickFormat === undefined ? (d: any) => d : tickFormatLogic;

        let x = d3.scaleLinear()
            .domain([-1, color.range().length - 1])
            .rangeRound([props.height - props.marginBottom, props.marginTop]);

        svg.append("g")
            .selectAll("rect")
            .data(color.range())
            .join("rect")
            .attr("y", (_d: any, i: any) => x(i))
            .attr("x", props.marginLeft)
            .attr("height", (_d: any, i: any) => x(i - 1) - x(i))
            .attr("width", props.width - props.marginRight - props.marginLeft)
            .attr("fill", "red");

        props.tickValues = d3.range(thresholds.length);
        props.tickFormat = (i: any) => thresholdFormat(thresholds[i], i);

        return x;
    }

    private static colorSequential(color: any, svg: any, props: any) {
        let x = Object.assign(color.copy().interpolator(d3.interpolateRound(props.height - props.marginBottom, props.marginTop)),
            {
                range() { return [props.height - props.marginBottom, props.marginTop]; }
            });

        svg.append("image")
            .attr("x", props.marginLeft)
            .attr("y", props.marginTop)
            .attr("width", props.width - props.marginLeft - props.marginRight)
            .attr("height", props.height - props.marginTop - props.marginBottom)
            .attr("preserveAspectRatio", "none")
            .attr("xlink:href", this.ramp(color.interpolator()).toDataURL());

        // scaleSequentialQuantile doesn’t implement ticks or tickFormat.
        if (!x.ticks) {
            if (props.tickValues === undefined) {
                const n = Math.round(props.ticks + 1);
                props.tickValues = d3.range(n).map(i => d3.quantile(color.domain(), i / (n - 1)));
            }

            if (typeof props.tickFormat !== "function") {
                props.tickFormat = d3.format(props.tickFormat === undefined ? ",f" : props.tickFormat);
            }
        }

        return x;
    }

    private static ramp(color: any, n = 256): HTMLCanvasElement {
        const canvas = document.createElement("canvas");
        canvas.width = 1;
        canvas.height = n;
        const context: any = canvas.getContext("2d");
        for (let i = 0; i < n; ++i) {
            context.fillStyle = color(i / (n - 1));
            context.fillRect(0, n - i, 1, 1);
        }
        return canvas;
    }
}
