import { NgModule, APP_INITIALIZER, NO_ERRORS_SCHEMA } from '@angular/core';
import { HTTP_INTERCEPTORS, HttpClientModule, HttpClientJsonpModule } from '@angular/common/http';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { MsalGuard, MsalInterceptor, MsalBroadcastService, MsalModule, MsalService, MSAL_GUARD_CONFIG, MSAL_INSTANCE, MSAL_INTERCEPTOR_CONFIG, MsalRedirectComponent } from '@azure/msal-angular';

//******************************************************************************
//  App Framework
//******************************************************************************
import { AppComponent } from '~/app.component';

//******************************************************************************
//  Services
//******************************************************************************
import { AppConfigService } from '~/services/core/appconfig.service';

//******************************************************************************
//  Imports
//******************************************************************************
import { AppImports } from '~/classes/core/imports/app.imports';
import { ExternalImports } from '~/classes/core/imports/external.imports';

//******************************************************************************
//  Routes
//******************************************************************************
import { AppRouting } from '~/classes/core/routes/app.routing';

//******************************************************************************
//  Auth Classes (Guards/Factories)
//******************************************************************************
import { AuthGuard } from '~/classes/core/auth/auth.guard';
import * as auth from '~/classes/core/auth/auth.factories';
import { MatIconRegistry } from '@angular/material/icon';

const authFactories = new auth.AuthFactories();
const isIE = window.navigator.userAgent.indexOf("MSIE ") > -1 || window.navigator.userAgent.indexOf("Trident/") > -1; // Remove this line to use Angular Universal

@NgModule({
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        HttpClientModule,
        HttpClientJsonpModule,
        MsalModule,

        AppImports,
        ExternalImports,
        AppRouting
    ],
    declarations: [
        AppComponent
    ],
    providers: [
        AppConfigService,
        AuthGuard,
        {
            provide: APP_INITIALIZER,
            useFactory: authFactories.initializerFactory,
            deps: [AppConfigService],
            multi: true
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: MsalInterceptor,
            multi: true
        },
        {
            provide: MSAL_INSTANCE,
            useFactory: authFactories.MSALInstanceFactory,
            deps: [AppConfigService]
        },
        {
            provide: MSAL_GUARD_CONFIG,
            useFactory: authFactories.MSALGuardConfigFactory
        },
        {
            provide: MSAL_INTERCEPTOR_CONFIG,
            useFactory: authFactories.MSALInterceptorConfigFactory,
            deps: [AppConfigService]
        },
        MsalService,
        MsalGuard,
        MsalBroadcastService,
    ],
    bootstrap: [
        AppComponent,
        MsalRedirectComponent,
    ],
    schemas: [
        NO_ERRORS_SCHEMA
    ]
})
export class AppModule { 
  constructor(iconRegistry: MatIconRegistry) {
		iconRegistry.setDefaultFontSetClass('material-icons');
	}
}
