<ng-container *ngIf="isEdit; else readOnly">
    <form [formGroup]="form">
        <table class="table-x">
            <caption style="display: none;">WSJF Score Card</caption>
            <tbody>
                <tr>
                    <th scope="col">Portfolio:</th>
                    <td>
                        <ng-container *ngTemplateOutlet="adoProjectSelect"></ng-container>
                    </td>
                </tr>
                <tr>
                    <th scope="col">Busines Value:</th>
                    <td>
                        <select formControlName="businessValue">
                            <option value="2">2</option>
                            <option value="4">4</option>
                            <option value="6">6</option>
                            <option value="8">8</option>
                            <option value="10">10</option>
                        </select>
                    </td>
                </tr>
                <tr>
                    <th scope="col">Time Criticality:</th>
                    <td>
                        <select formControlName="timeCriticality">
                            <option value="1">1</option>
                            <option value="2">2</option>
                            <option value="4">4</option>
                            <option value="6">6</option>
                            <option value="8">8</option>
                            <option value="10">10</option>
                        </select>
                    </td>
                </tr>
                <tr>
                    <th scope="col">RROE:</th>
                    <td>
                        <select formControlName="riskReductionOpportunityEnablementValue">
                            <option value="1">1</option>
                            <option value="2">2</option>
                            <option value="4">4</option>
                            <option value="6">6</option>
                            <option value="8">8</option>
                            <option value="10">10</option>
                        </select>
                    </td>
                </tr>
                <tr>
                    <th scope="col">Estimated Effort:</th>
                    <td>
                        <select formControlName="estimatedImplementationEffort">
                            <option value="1">1</option>
                            <option value="2">2</option>
                            <option value="3">3</option>
                            <option value="4">4</option>
                            <option value="5">5</option>
                            <option value="6">6</option>
                            <option value="7">7</option>
                            <option value="8">8</option>
                            <option value="9">9</option>
                            <option value="10">10</option>
                            <option value="11">11</option>
                            <option value="12">12</option>
                            <option value="13">13</option>
                            <option value="14">14</option>
                            <option value="15">15</option>
                        </select>
                    </td>
                </tr>
                <tr>
                    <th scope="col">WSJF:</th>
                    <td>
                        <span>{{ form.controls.wsjfScore.value | number : '1.2-2' || '--' }}</span>
                    </td>
                </tr>
            </tbody>
        </table>
    </form>
</ng-container>
<ng-template #readOnly>
    <div>
        <table class="table-x">
            <caption style="display: none;">WSJF Score Card</caption>
            <tbody>
                <tr>
                    <th scope="col">Portfolio ID:</th>
                    <td>
                        <ng-container *ngIf="portfolioId; then adoProjectLinkTemplate"></ng-container>
                        <ng-container *ngIf="!portfolioId">
                            --
                        </ng-container>
                    </td>
                </tr>
                <tr>
                    <th scope="col">Busines Value:</th>
                    <td>{{ form.controls.businessValue?.value || '--' }}</td>
                </tr>
                <tr>
                    <th scope="col">Time Criticality:</th>
                    <td>{{ form.controls.timeCriticality.value || '--' }}</td>
                </tr>
                <tr>
                    <th scope="col">RROE:</th>
                    <td>{{ form.controls.riskReductionOpportunityEnablementValue.value || '--' }}</td>
                </tr>
                <tr>
                    <th scope="col">Estimated Effort:</th>
                    <td>{{ form.controls.estimatedImplementationEffort.value || '--' }}</td>
                </tr>
                <tr>
                    <th scope="col">WSJF:</th>
                    <td>{{ form.controls.wsjfScore.value | number : '1.2-2' || '--' }}</td>
                </tr>
            </tbody>
        </table>
    </div>
</ng-template>


<ng-template #adoProjectTemplate>
    <ng-content *ngIf="portfolioId; then adoProjectLinkTemplate; else adoProjectSelect"></ng-content>
</ng-template>

<ng-template #adoProjectLinkTemplate>
    <span [projectPortfolioId]="portfolioId"></span>
</ng-template>

<ng-template #adoProjectSelect>
    <ccms-portfolio-epic-select
        [initialValue]="portfolioId"
        (changed)="onPortfolioIdChanged($event)"></ccms-portfolio-epic-select>
</ng-template>