export class PolicyTree {
  public static readonly ROOT_MARKET_TYPE = "Market Type";
  public static readonly ROOT_MARKET_STATUS = "Market Status";
  public static readonly ROOT_JURISDICTION_TYPE = "Jurisdiction Type";
  
  public static readonly LEAF_MARKET_ETS: string = "ETS";
  public static readonly LEAF_MARKET_TAX: string = "Carbon Tax";
  public static readonly LEAF_MARKET_CREDITS: string = "Carbon Credits";

  public static readonly LEAF_STATUS_UNDER_CONSIDERATION: string = "Under Consideration";
  public static readonly LEAF_STATUS_UNDER_DEVELOPMENT: string = "Under Development";
  public static readonly LEAF_STATUS_INFORCE: string = "In force"

  public static readonly LEAF_JURISDICTION_NATIONAL: string = "National";
  public static readonly LEAF_JURISDICTION_SUBNATIONAL: string = "Subnational";
  public static readonly LEAF_JURISDICTION_REGIONAL: string = "Regional";

  public static readonly ROOT_ARTICLE6: string = "Article 6 Memos";
}