import { AfterViewInit, Component, ElementRef, Input, OnDestroy, ViewChild } from "@angular/core";
import { draw } from 'patternomaly'
import { ChartDataset } from "chart.js";
import Chart from "chart.js/auto";
import { BehaviorSubject, Observable } from "rxjs";
import { MatTabGroup } from "@angular/material/tabs";
import { CreditForecastViewModel } from "../model/credit-forecast.viewmodel";
import { ForecastTypeEnum } from "../model/forecast-type.enum";
import { ICreditsMarket } from "~/models/shared/common";

const CREDITS_LABEL_TEXT = 'Credits';

@Component({
	selector: 'ccms-credit-forecasts-chart',
	templateUrl: './credit-forecasts-chart.component.html',
	styleUrls: ['./credit-forecasts-chart.component.scss']
})
export class CreditForecastsChartComponent implements AfterViewInit, OnDestroy {
	
	@ViewChild('chartGroup')
  chartGroup!: MatTabGroup;

	private _riskForecastChart: Chart | undefined;
	private _riskForecastData!: CreditForecastViewModel[];
	private _riskForecastDataSubject: BehaviorSubject<CreditForecastViewModel[]>;
	@ViewChild('riskChart') riskChartRef: ElementRef<HTMLElement> | undefined;

	private _unriskForcastChart: Chart | undefined;
	private _unriskForecastData: CreditForecastViewModel[] | undefined;
	private _unriskForecastDataSubject: BehaviorSubject<CreditForecastViewModel[]>;
	@ViewChild('unriskChart') unriskChartRef: ElementRef<HTMLElement> | undefined;

	public riskForecastData$: Observable<CreditForecastViewModel[]>;
	public unriskForecastData$: Observable<CreditForecastViewModel[]>;

	private _hasRiskDataSubject: BehaviorSubject<boolean>;
	public hasRiskData$: Observable<boolean>;
 
  // totals
  public totalCredits!: string;
  public totalCreditsRisk!: string;
  public totalFunding!: string;
  public averagePrice!: string;  
  public volumetricExposure!: string;
  public nonDiscretionarySpend!: string;

	@Input() set riskForecastData(value: CreditForecastViewModel[]) {
		this._riskForecastDataSubject.next(value);
	}
	@Input() set unriskForecastData(value: CreditForecastViewModel[]) {
		this._unriskForecastDataSubject.next(value);
	}
	@Input() set forecastType(value: ForecastTypeEnum) {
		if (value) {
			this.forecastTypeInternal = value;
			this.chartId = "forecastCreditsChart" + this.forecastTypeInternal;
		}
	}
	@Input() set creditsMarket(value: ICreditsMarket) {
		this.creditsMarketInternal = value;
	}

	public showRisk: boolean = false;

	constructor() {
		this._riskForecastDataSubject = new BehaviorSubject<CreditForecastViewModel[]>([]);
		this.riskForecastData$ = this._riskForecastDataSubject.asObservable();
		
		this._unriskForecastDataSubject = new BehaviorSubject<CreditForecastViewModel[]>([]);
		this.unriskForecastData$ = this._unriskForecastDataSubject.asObservable();
		
		this._hasRiskDataSubject = new BehaviorSubject<boolean>(false);
		this.hasRiskData$ = this._hasRiskDataSubject.asObservable();
	}

  ngOnDestroy(): void {
    this._riskForecastChart?.destroy();
		this._unriskForcastChart?.destroy();
  }

	ngAfterViewInit(): void {
		this.riskForecastData$.subscribe(value => {
			if (value?.length > 0) {
				this._riskForecastData = value;
				this._hasRiskDataSubject.next(value?.length > 0);
				this.initializeChart();
			}
			else {
				this._hasRiskDataSubject.next(false);
			}
			this.showRisk = (this._riskForecastData != null && this._riskForecastData != undefined);
		});

		this.unriskForecastData$.subscribe(value => {
			if (value?.length > 0) {
        this.calculateTotalCredits(value);
        this.calcAveragePrice(value);
        this.calcTotalCreditsRisk();
        this.calcTotalFunding(value);
        this.calcVolumetricExposure(value);
        this.calcNonDiscretionarySpend(value);

				this._unriskForecastData = value;
				this.initializeChart();
			}
		});
		this.initializeChart();
	}

	forecastTypeInternal: ForecastTypeEnum | undefined;
	chartId!: string;
	creditsMarketInternal!: ICreditsMarket;

	private currencyFormatter = new Intl.NumberFormat('en-US', {
		style: 'currency',
		currency: 'USD',
		maximumFractionDigits: 4, // (causes 2500.99 to be printed as $2,501)
	});
	private numberFormatter = new Intl.NumberFormat('en-US', { maximumFractionDigits: 0 });

	public initializeChart() {
		this._riskForecastChart?.destroy();
		this._unriskForcastChart?.destroy();
		this.onChartTabChanged();
	}

	public onChartTabChanged() {
    if (this.chartGroup) {
		  if (this.chartGroup.selectedIndex === 0) {
			  this._unriskForcastChart?.destroy();
			  this._unriskForcastChart = this.renderChart(this.unriskChartRef, this._unriskForecastData);
		  }
		  else if (this.chartGroup.selectedIndex === 1) {
			  this._riskForecastChart?.destroy();
			  this._riskForecastChart = this.renderChart(this.riskChartRef, this._riskForecastData);
		  }
    }
	}

	private renderChart(elementRef: ElementRef | undefined, data: CreditForecastViewModel[] | undefined): Chart | undefined {
		const canvas = elementRef?.nativeElement as HTMLCanvasElement;
		if (!canvas || !data) {
			return undefined;
		}

		const sorted = [...data].sort((a, b) => (a.year < b.year ? -1 : 1));
		const creditObligatedMappedData = sorted.map(element => ({ x: element.year, y: element.credits ?? 0 }));
		const creditNonObligatedMappedData = sorted.map(element => ({ x: element.year, y: element.nonObligatedCredits ?? 0 }));
		const nonDiscrentionaryFundingMappedData = sorted.map(element => ({ x: element.year, y: element.nonDiscretionarySpend ?? 0 }));
		const discrentionaryFundingMappedData = sorted.map(element => ({ x: element.year, y: element.discretionarySpend ?? 0 }));

		const creditDataSet: ChartDataset = {
			label: `Obligated ${CREDITS_LABEL_TEXT}`,
			data: creditObligatedMappedData,
			backgroundColor: '#0066B2',
			order: 1,
			yAxisID: "creditAxis",
			stack: 'credit'
		};
		const nonObligatedCreditDataSet: ChartDataset = {
			label: `Non-Obligated ${CREDITS_LABEL_TEXT}`,
			data: creditNonObligatedMappedData,
			backgroundColor: draw('diagonal', '#0066B2'),
			order: 1,
			yAxisID: "creditAxis",
			stack: 'credit'
		};
		const nonDiscrentionaryFundingDataSet: ChartDataset = {
			label: "Non-Discretionary Funding",
			data: nonDiscrentionaryFundingMappedData,
			backgroundColor: "#BA3093",
			order: 2,
			yAxisID: "fundingAxis",
			stack: 'funding'
		};
		const discrentionaryFundingDataSet: ChartDataset = {
			label: "Discrentionary Funding",
			data: discrentionaryFundingMappedData,
			backgroundColor: draw('diagonal', "#BA3093"),
			order: 3,
			yAxisID: "fundingAxis",
			stack: 'funding'
		};

		const chartData: ChartDataset[] = [];
		chartData.push(creditDataSet);
		chartData.push(nonObligatedCreditDataSet);
		chartData.push(nonDiscrentionaryFundingDataSet);
		chartData.push(discrentionaryFundingDataSet);

		return new Chart(canvas, {
			type: 'bar',
			options: {
				responsive: true,
				plugins: {
					legend: {
            position: 'bottom',
						display: true,
					},
					tooltip: {
						intersect: false,
						callbacks: {
							label: (context): string => {
								return context.dataset.label + ": " + context.formattedValue;
							}
						}
					}
				},
				scales: {
					creditAxis: {
						position: 'left',
						ticks: {
							callback: function (value, _index, _values) {
								return value.toLocaleString('en-US');
							}
						},
						bounds: 'data',
						title: {
							text: CREDITS_LABEL_TEXT,
							display: true,
							font: {
								weight: 'bold',
								family: "GothamNarrowBold, Arial, sans- serif",
								size: 14
							},
							color: '#212529'
						},
						stacked: true
					},
					fundingAxis: {
						ticks: {
							callback: (value, _index, _values) => {
								return this.currencyFormatter.format(+value);
							}
						},
						bounds: 'data',
						position: 'right',
						title: {
							text: "Funding",
							display: true,
							font: {
								weight: 'bold',
								family: "GothamNarrowBold, Arial, sans- serif",
								size: 14
							},
							color: '#212529'
						},
						stacked: true
					},
					x: {
            position: 'top',
            title: {
              text: 'Forecast',
              display: true,
              font: {
                  weight: 'bold',
                  family: "GothamNarrowBold, Arial, sans- serif",
                  size: 14
              },
              color: '#212529'
            },
						stacked: true
					}
				}
			},
			data: {
				labels: data.map(forecast => forecast.year),
				datasets: chartData
			}
		});
	}

	public calcTotalFunding(forecasts: CreditForecastViewModel[]) {
		let total = 0;
		forecasts.forEach((element) => { total += element.fundingAmount ?? 0; });
		this.totalFunding = this.currencyFormatter.format(total);
	}

	public calcAveragePrice(forecasts: CreditForecastViewModel[]) {
		let totalCredits = 0;
		forecasts.forEach((element) => { totalCredits += element.credits ?? 0; });

		let totalFunding = 0;
		forecasts.forEach((element) => { totalFunding += element.fundingAmount ?? 0; });

		const avgPrice = totalFunding / totalCredits;
		if (totalCredits === 0) {
			this.averagePrice = this.currencyFormatter.format(0);
      return;
		}
		this.averagePrice = this.currencyFormatter.format(avgPrice);
	}

  private calculateTotalCredits(forecasts: CreditForecastViewModel[]) {
    let credits = 0;
    forecasts.forEach(element => {
      credits += (element.credits ?? 0) + (element.nonObligatedCredits ?? 0);
    });
  
    this.totalCredits = this.numberFormatter.format(credits);
  }

	public calcVolumetricExposure(forecasts: CreditForecastViewModel[]) {
		let credits = 0;
		forecasts.forEach((element) => { credits += element.credits ?? 0; });

		this.volumetricExposure = this.numberFormatter.format(credits);
	}

	public calcNonDiscretionarySpend(forecasts: CreditForecastViewModel[]) {
		let spend = 0;
		forecasts.forEach((element) => { spend += element.nonDiscretionarySpend ?? 0; });

		this.nonDiscretionarySpend = this.currencyFormatter.format(spend);
	}

	public calcTotalCreditsRisk() {
		if (!this.showRisk) {
			this.totalCreditsRisk = "";
      return;
		}

		this.totalCreditsRisk = this.numberFormatter.format(this._riskForecastData.reduce((sum, current) => sum + ((current.credits ?? 0) + (current.nonObligatedCredits ?? 0)), 0));
	}
}