import { Component, EventEmitter, Output } from "@angular/core";
import { ActivatedRoute } from "@angular/router";

import { Tab } from '~/components/shared/tabs/tabs.component';

import { ProjectCreditChartsComponent } from "./components/credit/credit-charts.component";
import { ProjectFundingChartsComponent } from "./components/funding/funding-charts.component";
import { ProjectRiskChartsComponent } from "./components/risk/risk-charts.component";

@Component({
	selector: 'project-charts',
	templateUrl: './project-charts.component.html',
	styleUrls: ['./project-charts.component.scss']
})
export class ProjectChartsComponent {
    @Output() setMessageEvent: EventEmitter<any> = new EventEmitter();

    public activeTab: Tab | undefined;
    public urlParams: string = "view=charts";

	public message: string = "";
	public messageStyle: string = "";

    public tabs: Tab[] = [
        {
            title: "Credit",
            type: <any>ProjectCreditChartsComponent,
        },
        {
            title: "Funding",
            type: <any>ProjectFundingChartsComponent,
        },
        {
            title: "Risk",
            type: <any>ProjectRiskChartsComponent,
        }
    ];

	constructor(
        private route: ActivatedRoute,
	) { }

    async ngOnInit() {
        this.route.queryParams.subscribe((params: any) => {
            if (params?.tab) {
                this.activeTab = this.tabs.find((tab: Tab) => tab.title === params.tab);
            }
        });
    }

    public showMessage(event: any) {
        this.setMessageEvent.emit({ text: event });
    }
}
