import { Component, ElementRef, EventEmitter, Input, OnChanges, Output, ViewChild } from '@angular/core';

@Component({
	selector: 'sdk-message',
	templateUrl: './sdk-message.component.html',
	styleUrls: ['./sdk-message.component.scss']
})

export class SDKMessageComponent implements OnChanges {
	@Input() message: string = "";
	@Input() height: string = "";
	@Input() style: string = "";
	@Input() timer: number = 5;
	@Output() messageChange: EventEmitter<any> = new EventEmitter();

	@ViewChild('sdkmessage') sdkmessage!: ElementRef<any>;

	public showMessage: boolean = false;
	public _message: string = "";

	private _timer: any;

	ngAfterViewInit() {
		setTimeout(() => {
			if(this.sdkmessage) {
				if (this.height !== "") {
					this.sdkmessage.nativeElement.style.setProperty("--message-height", this.height);
					this.sdkmessage.nativeElement.style.height = this.height;
				}

				if (!this.sdkmessage.nativeElement.style.height) {
					this.sdkmessage.nativeElement.style.height = "35px";
				}

				let messageHeight = parseInt(this.sdkmessage.nativeElement.style.height) * -1;

				this.sdkmessage.nativeElement.style.setProperty("--message-top", `${messageHeight}px`);
			}
		}, 1);
	}

	public ngOnChanges(changes: any): void {
		if (changes.message) {
			this._message = changes.message.currentValue;

			if (this._message && this._message !== "") {
				this.stopTimer();
				this.showMessage = true;
				this.startTimer();
			} else {
				this.showMessage = false;
				this._message = "";
				this.stopTimer();
			}
		}
	}

	public startTimer() {
		this._timer = setInterval(() => {
			this.clearMessage();
		}, (this.timer * 1000));
	}

	public clearMessage() {
		this.stopTimer();

		this._message = "";
		this.messageChange.emit();
	}

	public stopTimer() {
		clearInterval(this._timer);
	}
}
