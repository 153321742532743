import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { PolicyTrackerQuery } from '~/models/shared/policy-tracker/policy-tracker-query.model';

@Injectable()
export class PolicyTrackerMapService {
  private redrawRequestedSource = new Subject<PolicyTrackerQuery>();

  // Observable to be used in the map component
  public redrawRequested$ = this.redrawRequestedSource.asObservable();

  // Function to be called from the parent component
  requestRedraw(query: PolicyTrackerQuery) {
    this.redrawRequestedSource.next(query);
  }
}
