import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild, ViewContainerRef } from "@angular/core";
import { ColorType, createChart, IChartApi, ISeriesApi, LineStyle, LineStyleOptions, SeriesOptionsMap, TimeRange } from 'lightweight-charts';
import { debounceTime, firstValueFrom, fromEvent, Subject } from "rxjs";
import { Router } from "@angular/router";
import Dictionary from "~/components/shared/dictionary/dictionary";
import { PriceDataService } from "~/services/shared/markets/price-data.service";
import { ResizeEventService } from "~/services/shared/resize-event.service";
import { Markets } from "~/services/shared/markets/markets";
import { ChartDataPoint } from "~/components/shared/market-chart/market-chart.component";
import { IMarket } from "~/models/shared/markets/market.model";
import { getChartPriceSeries, updateChartLogicalRange, updateChartVisibleRange } from "../../../pages/markets/state/chart-price-series.repository";

@Component({
	selector: 'ccms-price-chart',
	templateUrl: './price-chart.component.html',
	styleUrls: ['./price-chart.component.scss']
})
export class PriceChartComponent implements OnInit, OnDestroy {
	@Input() set fitAllContent(fitAll: boolean) {
		this.fitAllContentInput = fitAll;
		this.setChartTimeRange();
	}
  @Output() changeLoadingEvent: EventEmitter<boolean> = new EventEmitter();

	@ViewChild('template', { static: true }) template;

	seriesAdded: Subject<string> = new Subject<string>();
	seriesRemoved: Subject<string> = new Subject<string>();
	seriesSelected: Subject<string> = new Subject<string>();
	seriesUnselected: Subject<string> = new Subject<string>();

	chart!: IChartApi;
	series!: Dictionary<ISeriesApi<keyof SeriesOptionsMap>>;
	fitAllContentInput: boolean = false;
	ignoreTimeScaleChanges: boolean = false;
  isLoading: boolean = false;

	constructor(
		private _priceDataService: PriceDataService,
		private viewContainerRef: ViewContainerRef,
		private router: Router,
		private _resizeEventService: ResizeEventService
	) {
		fromEvent(window, 'resize').pipe(debounceTime(10))
			.subscribe((event) => {
				console.log("window resize");
				this.resizeChart();
			});

		this.router.events.subscribe((val) => {
			this.resizeChart();
		});
	}

	ngOnDestroy(): void {
		this.seriesAdded.unsubscribe();
		this.seriesRemoved.unsubscribe();
		this.seriesSelected.unsubscribe();
		this.seriesUnselected.unsubscribe();

		this.chart.timeScale().unsubscribeVisibleTimeRangeChange(this.visibleRangeChange);
		this.chart.timeScale().unsubscribeVisibleLogicalRangeChange(this.logicalRangeChange);
	}

	ngOnInit(): void {
		this.viewContainerRef.createEmbeddedView(this.template);
		this.viewContainerRef.element.nativeElement.remove();

		this.series = new Dictionary<ISeriesApi<keyof SeriesOptionsMap>>();

		this.seriesAdded.subscribe({
      next: (v) => {
          this.chartData(v);
      }
  });
		this.seriesRemoved.subscribe({
			  next: (v) => { 
          this.removeSeries(v);
      }
		});

		this.seriesSelected.subscribe({
			next: (v) => {
        this.setSeriesSelected(v);
      }
		});
		this.seriesUnselected.subscribe({
			next: (v) => { 
        this.setSeriesUnselected(v);
      }
		});

		const chartOptions = {
			layout: { textColor: 'black', background: { type: ColorType.Solid, color: 'white' } },
			timeScale: { minBarSpacing: 0.001 },
		};

		const chart = document.getElementById('chartCarbon');
		if (chart) {
			this.chart = createChart(chart, chartOptions);

			setTimeout(() => {
				this.chart.timeScale().subscribeVisibleTimeRangeChange(
					this.visibleRangeChange
				);
				this.chart.timeScale().subscribeVisibleLogicalRangeChange(
					this.logicalRangeChange
				);
			}, 1000);
		}

		this._resizeEventService.getResizeEvent().subscribe({
      next: (event) => {
          console.log(event);
          const interval = 100;
          
          const intervalCallback = () => {
              this.resizeChart().catch(error => {
                  console.error('Error resizing chart:', error);
              });
          };
          
          const intervalId = setInterval(intervalCallback, interval);
          
          setTimeout(() => {
              clearInterval(intervalId);
          }, interval);
      }
    });
	}

	public async setChartTimeRange() {
		if (this.chart && this.fitAllContentInput) {
			this.chart.timeScale().fitContent();
			return;
		}

		try {
			const chartState = await firstValueFrom(getChartPriceSeries());

			if (this.chart && chartState?.chartVisibleRange?.from) {
				const x = chartState.chartVisibleRange;
				this.chart.timeScale().setVisibleRange(x);
			}

			if (this.chart && chartState?.chartLogicalRange) {
				this.chart.timeScale().setVisibleLogicalRange({ from: chartState.chartLogicalRange.from, to: chartState.chartLogicalRange.to })
			}
		} catch (e) {}
	}

	private visibleRangeChange(x: TimeRange | null) {
		if (!x) {
			return;
		}
		updateChartVisibleRange(x);
	}

	private logicalRangeChange(x: { from: number, to: number } | null) {
		if (x) {
			updateChartLogicalRange({ from: x.from, to: x.to });
		}
	}

	private async chartData(name: string) {
    this.isLoading = true;
    try {
		  const market = Markets.getMarketByName(name);

		  if (market) {
			  this.changeLoadingEvent.emit(true);

			  const data = await this._priceDataService.getChartData(market.marketType, market.symbol);
			  if (data) {
				  this.addSeries(market, data)
				  this.setChartTimeRange();
			  }

			  this.changeLoadingEvent.emit(false);
		  }
    } finally {
      this.isLoading = false;
    }
	}

	private async addSeries(market: IMarket, points: ChartDataPoint[]) {
		if (!points || points.length == 0) {
			return;
		}

		const lineSeries = this.chart.addLineSeries({
			color: Markets.getColor(market)
		});
		lineSeries.applyOptions(this.seriesNormalOptions);

		lineSeries.setData(points);
		this.series.add(market.symbol, lineSeries);
	}

	private removeSeries(node: string) {
		let wasRemoved = false;
		const market = Markets.getMarketByName(node);
		if (!market) {
			return;
		}

		const remove = this.series.getItem(market.symbol);
		try {
			if (remove) {
				this.ignoreTimeScaleChanges = true;
				this.chart.removeSeries(remove);
				wasRemoved = true;
			}
		} catch (ex) {
			console.log(ex);
		}

		if (wasRemoved) {
			this.setChartTimeRange().then(() => {
				setTimeout(() => {
					this.series.removeItem(market.symbol);
					this.ignoreTimeScaleChanges = false;
				}, 50)
			});
		}
	}

	async resizeChart() {
		if (!this.chart) {
			return;
		}

		try {
			this.ignoreTimeScaleChanges = true;
			const container = document.querySelector('.chart-container');
			if (container) {
				const rect = container.getBoundingClientRect();

				this.chart.applyOptions({
					width: rect.width,
					height: rect.height
				});
			}
		} finally {
			this.ignoreTimeScaleChanges = false;
		}

		if (this.fitAllContentInput) {
			this.chart.timeScale().fitContent();
		}
	}

	private get seriesSelectedOptions(): LineStyleOptions {
		const lineStyleOptions = <LineStyleOptions>{};
		lineStyleOptions.lineStyle = LineStyle.LargeDashed;
		lineStyleOptions.lineWidth = 4;

		return lineStyleOptions;
	}

	private get seriesNormalOptions(): LineStyleOptions {
		const lineStyleOptions = <LineStyleOptions>{};
		lineStyleOptions.lineStyle = LineStyle.Solid;
		lineStyleOptions.lineWidth = 2;

		return lineStyleOptions;
	}


	private setSeriesSelected(symbol: string) {
		const market = Markets.getMarketBySymbol(symbol);
		if (market) {
			const series = this.series.getItem(market.symbol);
			if (series) {
				series.applyOptions(this.seriesSelectedOptions);
			}
		}
	}

	private setSeriesUnselected(symbol: string) {
		const market = Markets.getMarketBySymbol(symbol);
		if (market) {
			const series = this.series.getItem(market.symbol);
			if (series) {
				series.applyOptions(this.seriesNormalOptions);
			}
		}
	}
}