<div *ngIf="feedData" class="feeds-content">
	<div class="title">Carbon Pulse</div>
	<div class="feeds">
		<ng-container *ngFor="let item of feedData">
			<div [title]="item.summary">
				<a [href]="item.uri" target="_blank">{{ item.title }}</a>
				<br /><br />
				Published: {{ getDate(item.published) | date:'dd MMM yyyy' }}
			</div>
			<hr />
		</ng-container>
	</div>
</div>

<div *ngIf="error" class="rss-content">
	<div class="title">RSS Feed</div>
	<div class="feeds error">
		<strong>Error reading feed:</strong>
		<br />
		{{ error }}
	</div>
</div>

<sdk-loading [isLoading]="isLoading"></sdk-loading>