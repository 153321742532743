import { Component } from '@angular/core';
import { SDKDataGridDataset, SDKDataGridOptions, SDKDataGridColumn } from 'sdk-datagrid';
import { BaseComponent } from '~/pages/base.component';
import { FormatterService } from '~/services/shared/formatter.service';
import { AbuInventoryService } from '~/services/shared/inventory/abu-inventory-service';
import { AbuInventorySearchOptions } from '~/services/shared/inventory/models/abu-Inventory-search-options';
import { InventoryAccountSummary } from '~/services/shared/inventory/models/inventory-account-summary';
import { from, map, Observable } from 'rxjs';
import { AbuInventoryDto } from '~/services/shared/inventory/models/abu-inventory-dto';
import { ActivatedRoute, Router } from '@angular/router';
import { InventorySummaryDto } from '~/services/shared/inventory/models/inventory-summary-dto';

@Component({
    selector: 'abu-summary',
    templateUrl: './abu-summary.component.html',
    styleUrls: ['./abu-summary.component.scss']
})

export class AbuSummaryComponent extends BaseComponent {
    public footnote: string = "";
    public MAXRECORDS: number = 1000;
    public activeView: string = "AbuInventory/Summary";
    public datasets: SDKDataGridDataset[] = [
        {
            Title: "Inventory",
            DbName: "AbuInventory/Summary",
        }
    ];
    public data: string = "";
    public columns: SDKDataGridColumn[] = [
        { Name: "accountName", DisplayName: "Account Name", isVisible: true },
        { Name: "accountNumber", DisplayName: "Account Number", isVisible: true },
        { Name: "acquired", DisplayName: "Acquired", isVisible: true, formatter: (value: any) => this.formatterService.formatNumber(value) },
        { Name: "transferred", DisplayName: "Transferred", isVisible: true, formatter: (value: any) => this.formatterService.formatNumber(value) },
        { Name: "balance", DisplayName: "Balance", isVisible: true, formatter: (value: any) => this.formatterService.formatNumber(value) },
        { Name: "unit", DisplayName: "Unit", isVisible: true }
    ];

    public uniqueIdentifier = "inventory.abu.summary";

    public gridOptions: SDKDataGridOptions = {
          settings: false,
		  columnSettings: false,
		  filtering: false,
		  sorting: false,
		  formulas: false,
		  charts: false,
		  export: true,
		  expandableRows: true,
		  selectableRows: false,
          autoClosePanel: true
    };

    public fullData: string = "";
    public dbPage: number = 0;
    public dbTotal: number = 0;
    public error: string = "";

    public transactionMap: Map<string, Observable<AbuInventoryDto[]>> = new Map<string, Observable<AbuInventoryDto[]>>();
    public detailTransactionsCount = 200;

    public dateFormat = 'MM/dd/yyyy';
    public numberFormat = '1.0-5';

    public totalAcquired: number = 0;
    public totalTransferred: number = 0;
    public totalBalance: number = 0;

    private activeAccount = "";
    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private formatterService: FormatterService,
        private abuInventoryService: AbuInventoryService
    ) {
        super();
    }

    //*************************************************************************
    //  Component Life-Cycle Methods
    //*************************************************************************
    public async ngOnInit() {
        await super.ngOnInit();
        await this.subscribeToRouteParams();
    }

    //*************************************************************************
    //  Public Methods
    //*************************************************************************
    public async loadData(event: any = null) {
        this.isLoading = true;

        try {
            this.MAXRECORDS = event.rows;

            this.resetVariables();
            const summaryResult = await this.abuInventoryService.getSummary();
            if (summaryResult) {
                let summaries = JSON.parse(JSON.stringify(summaryResult.accountSummaries));
                if (this.activeAccount && this.activeAccount !== '') {
                    summaries.forEach((summary) => {
                        if (summary.accountNumber && summary.accountNumber === this.activeAccount) {
                            summary["Expanded"] = true;
                        }
                    });
                }
                this.data = summaries;
                this.dbPage = 1;
                this.dbTotal = summaryResult.count;
                this.error = "";
                this.footnote = `Inventory as of ${this.formatterService.formatDate(new Date(), "dd MMM yyyy hh:mm:ss (z)")}`;

                if (summaryResult.accountSummaries && summaryResult.accountSummaries.length > 0) {
                    this.updateTotals(summaryResult);
                    this.initializeTransactionsMap(summaryResult.accountSummaries);
                }
            }
        } catch (error: any) {
            this.error = error.message;
        }

        this.isLoading = false;
    }

    public getAcccountTransactions(accountNumber: string): Observable<AbuInventoryDto[]> {
        const found = this.transactionMap.get(accountNumber);
        return found ? found : from([]);
    }

    public getSerialRange(transaction: AbuInventoryDto): string {
        return `${transaction.serialRangeStart} - ${transaction.serialRangeEnd}`;
    }

    public gotoProject(accountNumber: string, transaction: any) {
        if (transaction?.metaRegistryProjectId) {
            this.router.navigate(["/metaregistry/detail"], { state: { callback: '/inventory', callbackParams: { 'tab': 'Summary', 'parentTab': 'ABU', 'activeAccount': accountNumber }, callbackLabel: 'Back to ABU Inventory', projectId: transaction.metaRegistryProjectId } });
        }
    }
    //*************************************************************************
    //  Private Methods
    //*************************************************************************
    private async subscribeToRouteParams() {
        this.route.queryParams.subscribe((params: any) => {
            if (params?.activeAccount) {
                this.activeAccount = params?.activeAccount;
            }
        });
    }

    private updateTotals(summaryResult: InventorySummaryDto) {
        this.totalAcquired = summaryResult.accountSummaries.reduce((prev, next) => prev + next.acquired, 0);
        this.totalTransferred = summaryResult.accountSummaries.reduce((prev, next) => prev + next.transferred, 0);
        this.totalBalance = this.totalAcquired - this.totalTransferred;

    }

    private resetVariables() {
        this.data = "";
        this.fullData = "";
        this.dbPage = 0;
        this.dbTotal = 0;
        this.error = "";
    }

    private initializeTransactionsMap(accountSummaries: InventoryAccountSummary[]) {
        accountSummaries.forEach(summary => {
            if (summary) {
                const queryOptions: AbuInventorySearchOptions = {
                    accountNumber: summary.accountNumber,
                    sortOptions: [{
                        property: 'transactionCompletionDate',
                        isDescending: true
                    }],
                    skip: 0,
                    take: this.detailTransactionsCount // limit how many we load
                };

                let trnsactionObservable$ = from(this.abuInventoryService.search(queryOptions))
                    .pipe(
                        map(result => result.values)
                    );
                this.transactionMap.set(summary.accountNumber, trnsactionObservable$);
            }
        });

    }
}