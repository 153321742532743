import { AfterViewInit, Component, ElementRef, Input, ViewChild } from '@angular/core';
import { Observable } from 'rxjs';
import { ChartDataset } from "chart.js";
import Chart from "chart.js/auto";
import { WsjfSummary } from '~/services/shared/kpi/models/wsjf-summary.model';
import { Observe } from '~/classes/shared/observe.decorator';
import annotationPlugin from 'chartjs-plugin-annotation';

@Component({
	selector: 'ccms-wsjf-score-card-chart',
	templateUrl: './wsjf-score-card-chart.component.html',
	styleUrls: ['./wsjf-score-card-chart.component.scss']
})
export class WsjfScoreCardChartComponent implements AfterViewInit {
	private chart: Chart | undefined;
	private numberFormatter = new Intl.NumberFormat('en-US', { maximumFractionDigits: 2 });
	@ViewChild('chart') chartCanvas: ElementRef<HTMLElement> | undefined;

	@Input() dataContext!: WsjfSummary;
	@Observe('dataContext') dataContext$!: Observable<WsjfSummary>;

	constructor() {
		Chart.register(annotationPlugin);
	}
	//#region Component Lifecycle

	ngAfterViewInit(): void {
		this.dataContext$.subscribe((context) => {
			this.chart?.destroy();
			if (!context) {
				return;
			}
			const dataset = this.parseWsjfSummary(context);
			this.chart = this.renderChart(this.chartCanvas, dataset);
		});
	}

	private parseWsjfSummary(data: WsjfSummary): ChartDataset[] {
		const result: ChartDataset[] = [];
		let dataset: any;
		if (data.referenceProject) {
			const datapoint = data.referenceProject;
			dataset = {
				label: 'This project',
				labels: [datapoint.name],
				backgroundColor: 'blue',
				data: [{ x: datapoint.value, y: datapoint.effort }],
				pointRadius: 5
			}
			result.push(dataset);
		}
		if (data.topProjectsByCountry?.length > 0) {
			dataset = {
				label: 'Projects from same country',
				labels: [],
				backgroundColor: 'orange',
				data: [],
				pointRadius: 5
			};
			data.topProjectsByCountry.forEach(item => {
				dataset.labels.push(item.name);
				dataset.data.push({
					x: item.value, y: item.effort
				})
			});
			result.push(dataset);
		}
		if (data.topProjectsByProjectType?.length > 0) {
			dataset = {
				label: 'Projects with same project type',
				labels: [],
				backgroundColor: 'green',
				data: [],
				pointRadius: 5
			}
			data.topProjectsByProjectType.forEach(item => {
				dataset.labels.push(item.name);
				dataset.data.push({
					x: item.value, y: item.effort
				})
			});
			result.push(dataset);
		}

		return result;
	}

	//#endregion

	private renderChart(canvas: ElementRef | undefined, datasets: ChartDataset[]): Chart | undefined {
		if (!canvas || !datasets) {
			return;
		}
		return new Chart(canvas?.nativeElement as HTMLCanvasElement, {
			type: 'scatter',
			data: {
				datasets: datasets
			},
			options: {
				maintainAspectRatio: false,
				scales: {
					x: {
						title: {
							display: true,
							text: 'Value'
						},
						min: 0,
						max: 30
					},
					y: {
						title: {
							display: true,
							text: 'Effort'
						},
						min: 0,
						max: 10,
						reverse: true
					}
				},
				plugins: {
					legend: {
						display: true
					},
					annotation: {
						annotations: {
							line1: {
								type: 'line',
								yMin: 5,
								yMax: 5,
								borderColor: 'rgb(255, 99, 132)',
								borderWidth: 1,
							},
							line2: {
								type: 'line',
								xMin: 15,
								xMax: 15,
								borderColor: 'rgb(255, 99, 132)',
								borderWidth: 1,
							}
						}
					},
					tooltip: {
						callbacks: {
							label: (ctx): string => {
								console.log(ctx);
								let label = ctx.dataset['labels'][ctx.dataIndex];
								const wsjf = ctx.parsed.x / ctx.parsed.y;
								
								label += "\r\nWSJF: " + this.numberFormatter.format(wsjf);
								return label;
							}
						}
					}
				}
			},
			plugins: [

			]
		});
	}
}
