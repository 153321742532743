import { createStore } from '@ngneat/elf';
import { withEntities, selectAllEntities, updateEntities } from '@ngneat/elf-entities';
import { map, Observable } from 'rxjs';
import {
  persistState,
  localStorageStrategy,
} from '@ngneat/elf-persist-state';
import { PriceSeriesState } from './price-series.state';
import { TimeRange } from 'lightweight-charts';
import { TodoItemFlatNode } from '~/components/shared/tree-checklist/tree-checklist.component';
import { Markets } from '~/services/shared/markets/markets';

export const DEFAULT_CHART = "default";

const defaultNodes = [
  new TodoItemFlatNode(Markets.EuEts.name, 1),
  new TodoItemFlatNode(`${Markets.VCU_V21_V25.name} (${Markets.VCU_V21_V25.symbol})`, 1)
];

const initialChartSeries : PriceSeriesState[] = [
  {id: DEFAULT_CHART, defaultSeries: defaultNodes, selectedSeries: [], nodeExpansion: [], chartLogicalRange: null, chartVisibleRange: null, fitAllContent: true}
]

const store = createStore(
  { name: 'carbon-markets-series' },
  withEntities<PriceSeriesState>({initialValue: initialChartSeries})
);

export const persist = persistState(store, 
  {  
    storage: localStorageStrategy
  },
);

const chartSeries$ = store.pipe(selectAllEntities());

export function getChartPriceSeries(id: PriceSeriesState['id'] = DEFAULT_CHART): Observable<PriceSeriesState | undefined> {
  return chartSeries$.pipe(
    map(chart => { 
      return chart.find(chart => chart.id == id)
    })
  );
}

export function updateSelectedPriceSeries(chartSeries: TodoItemFlatNode[], id: PriceSeriesState['id'] = DEFAULT_CHART) {
  store.update(
    updateEntities(id, (entity) => ({
      ...entity,
      selectedSeries: chartSeries
    }))
  );
}

export function updateExpansionNodes(nodeExpansion: TodoItemFlatNode[], id: PriceSeriesState['id'] = DEFAULT_CHART) {
  store.update(
    updateEntities(id, (entity) => ({
      ...entity,
      nodeExpansion: nodeExpansion
    }))
  );
}

export function updateChartLogicalRange(chartLogicalRange: {from: number, to: number}, id: PriceSeriesState['id'] = DEFAULT_CHART) {
  store.update(
    updateEntities(id, (entity) => ({
      ...entity,
      chartLogicalRange: chartLogicalRange
    }))
  );
}

export function updateChartVisibleRange(chartVisibleRange: TimeRange, id: PriceSeriesState['id'] = DEFAULT_CHART) {
  store.update(
    updateEntities(id, (entity) => ({
      ...entity,
      chartVisibleRange: chartVisibleRange
    }))
  );
}

export function updateFitAllContent(fitAllContent: boolean, id: PriceSeriesState['id'] = DEFAULT_CHART) {
  store.update(
    updateEntities(id, (entity) => ({
      ...entity,
      fitAllContent: fitAllContent
    }))
  );
}