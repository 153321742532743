<div class="content">
    <div class="data">
        <section *ngIf="!isEdit">
            <h1 class="header" style="padding-top: 0;">
                Summary
            </h1>
            <ccms-project-priority-summary 
                [adoPriority]="project.priority"
                [priority]="priorityScoreCard?.priority"
                [priorityWithEconomics]="priorityScoreCard?.priorityWithEconomics"></ccms-project-priority-summary>
        </section>
        <section>
            <h1 class="header">Priority Ratings</h1>
            <p>
                Initial screening <strong>Priority Score</strong> based on the average of each of the 5 scores below excluding Economics. Ex (1+2+2+1+3)/5 = 1.8.
            </p>
            <p>
                <strong>Priority Score with Economics</strong> is based on the (Initial Screening Priority Score * 60%) + (Economics Score * 40%).
            </p>
            <ccms-project-priority-score-card-form #scoreCard [isEdit]="isEdit" [context]="priorityScoreCard"></ccms-project-priority-score-card-form>
        </section>
        <ng-container *ngIf="error">
            <ccms-alert [type]="AT.WARNING" 
                [message]="error.errors" class="alert-container"></ccms-alert>
        </ng-container>
    </div>
    <div class="info">
        <tab-info [project]="project" [isEdit]="isEdit" [portfolioId]="portfolioId" (changeEvent)="infoChange($event)"></tab-info>

        <br />

        <ng-container *ngIf="error">
            <ccms-alert [type]="AT.WARNING" [message]="error.errors" class="alert-container"></ccms-alert>
        </ng-container>
    </div>
</div>