import { Component, Input } from "@angular/core";
import { Observable } from "rxjs";
import { Observe } from "~/classes/shared/observe.decorator";
import { DeveloperProfile } from "~/services/shared/developers-screening/models/developer-summary";
import { ProjectSummary } from "~/services/shared/developers-screening/models/project-summary";

export type CountryProjectsSummary = {
    country: string;
    creditsIssued: number;
    creditsRetired: number;
    creditsRemaining: number;
    projects: ProjectSummary[];
    expanded: boolean;
}
@Component({
    selector: 'developer-location',
    templateUrl: './developer-location.component.html',
    styleUrls: ['./developer-location.component.scss']
})
export class DeveloperLocationComponent {
    @Input() developer!: DeveloperProfile;
    public projects: ProjectSummary[] = [];
    public creditsByCountry: CountryProjectsSummary[] = [];
    @Observe('creditsByCountry') creditsByCountry$!: Observable<CountryProjectsSummary[]>;

    public async ngOnChanges() { 
        await this.loadData();
    }

    public toggleRow(countryData: CountryProjectsSummary) {
        countryData.expanded = !countryData.expanded;
    }

    private async loadData(): Promise<void> {
        if (Object.keys(this.developer).length === 0) {
            return;
        }
        const developer = this.developer;
        this.projects = this.developer.projects;
        this.creditsByCountry = this.groupProjectsByCountry(developer.projects)
            .sort((a, b) => {
                const aProjectsCount = a.projects.length;
                const bProjectsCount = b.projects.length;
                return aProjectsCount < bProjectsCount ? 1
                    : aProjectsCount > bProjectsCount ? -1
                    : 0;
            });
    }

    private groupProjectsByCountry(projects: ProjectSummary[]): CountryProjectsSummary[] {
        const countryMap = new Map<string, CountryProjectsSummary>();
        projects.forEach(project => {
          if (!countryMap.has(project.country)) {
            countryMap.set(project.country, { 
                country: project.country, 
                creditsIssued: 0,
                creditsRetired: 0,
                creditsRemaining: 0,
                projects: [], 
                expanded: false });
          }
          const countryEntry = countryMap.get(project.country)!;
          countryEntry.creditsIssued += project.creditsIssued;
          countryEntry.creditsRetired += project.creditsRetired;
          countryEntry.creditsRemaining += project.creditsRemaining;
          countryEntry.projects.push(project);
        });
        return Array.from(countryMap.values());
    }
}