import { Injectable } from "@angular/core";
import { ProjectDeveloperAccount } from "../../../models/shared/project-developer-account";
import { HttpService } from "../../core/http.service";

@Injectable()
export class ProjectDeveloperService {
    constructor(
        private readonly httpService: HttpService,
    ){
        // Intentionally blank
    }

    public searchDevelopers(serchTerm: string): Promise<ProjectDeveloperAccount[]>{
        let requestUri = '/project-developers/search';

        const body = {
            Terms: [
                serchTerm
            ]
        }
        return this.httpService.Post(requestUri, body);
    }

    public async createDeveloper(newAccount: ProjectDeveloperAccount): Promise<ProjectDeveloperAccount> {

        try {
            let requestUri = '/project-developers';

            return await this.httpService.Post(requestUri, newAccount).then((data: any) => {
                return data;
            }).catch((error: any) => {
                throw error;
            });
        } catch (error) {
            throw error;
        }
       
    }
}