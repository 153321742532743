<div class="content">
	<div class="header">
		<div class="back" (click)="back()"><span>◄ </span><span class="highlight">Back to Map</span></div>
		<div class="title">{{ countryName }}</div>
	</div>

	<div class="details">
		<div class="data">
			<ccms-country-profile [countryId]="countryId" [shortenTitle]="true"></ccms-country-profile>
			<br />
			<h3>Policies</h3>
			<ccms-climate-policy [policies]="policies"></ccms-climate-policy>
		</div>
		<div class="info">
			<div class="market-focus">
				<ccms-market-focus class="focus-map" [radius]="500" [countryId]="countryId"></ccms-market-focus>
			</div>
			<div class="lower-right-panel">
				<ccms-transparency-cpi class="cpi" [countryId]="countryId"></ccms-transparency-cpi>
				<ccms-country-credits [countryId]="countryId"></ccms-country-credits>
				<ccms-article6-panel [countryId]="countryId"></ccms-article6-panel>
			</div>
		</div>
	</div>
</div>