<div style="position: relative;" class="bg-white p-2">
    <h5 class="text-center">{{ form.transferType }} Credits</h5>
    <form [formGroup]="form">
        <div class="content" [class.row-1]="form.transferType === 'Retire'">
            <div style="justify-self: end; margin-right: 8px">
                <ccms-inventory-account-select
                    *ngIf="form.transferType == 'Transfer'" formControlName="toAccount"
                    label="to" required="true">
                </ccms-inventory-account-select>
            </div>
            <div style="margin-left: 8px">
                <mat-form-field appearance="outline" style="width: 250px;">
                    <mat-label>amount</mat-label>
                    <input formControlName="amount" matInput appCommaNumber>
                    <mat-hint *ngIf="form.maxAmount > 0">
                        <small>
                            Enter amount between 1 - {{ form.maxAmount }}
                        </small>
                    </mat-hint>
                </mat-form-field>
            </div>
        </div>
    </form>
    <div class="action">
        <div title="Save" class="icon material-icons-outlined done"
            *ngIf="form.valid && form.dirty"
            (click)="$event.stopPropagation(); onSave()">
            done</div>
        <div title="Cancel" class="icon material-icons-outlined cancel"
            (click)="$event.stopPropagation(); onCancel()">close</div>
    </div>
</div>