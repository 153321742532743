import { Injectable } from '@angular/core';

import { HttpService } from '../core/http.service';

@Injectable()
export class SustainabilityService {

    constructor(
        private httpService: HttpService,
    ) { }

    public async getTypes(): Promise<any> {
        try {
            return await this.httpService.Get(`/sustainable-development-goals`).then((data: any) => {
                return data;
            });
        } catch (error: any) {
            throw error;
        }
    }
}
