import { NgModule } from '@angular/core';
import { CommonModule, CurrencyPipe, DatePipe, DecimalPipe } from '@angular/common';
import { ClipboardModule } from '@angular/cdk/clipboard';

//******************************************************************************
//  NPM (Packages) External
//******************************************************************************
import { DynamicModule } from 'ng-dynamic-component';

import { SDKDatagridModule } from 'sdk-datagrid';
import { SDKGoogleMapModule } from 'sdk-google-map';
import { SDKLoadingModule } from 'sdk-loading';
import { SDKMessageModule } from 'sdk-message';
import { SDKSelectModule } from 'sdk-select';
import { SDKTextboxModule } from 'sdk-textbox';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

const packages = [
    DynamicModule,
    CommonModule,
    CurrencyPipe,
    DatePipe,
    DecimalPipe,
    ClipboardModule,

    SDKDatagridModule,
    SDKGoogleMapModule,
    SDKLoadingModule,
    SDKMessageModule,
    SDKSelectModule,
    SDKTextboxModule,
    FontAwesomeModule
];

@NgModule({
    imports: [
        CommonModule
    ],
    exports: [
        packages
    ],
})

export class ExternalImports { }
