import { Injectable } from '@angular/core';
import { Observable, map, shareReplay, lastValueFrom, from } from 'rxjs';
import { HttpService } from '../core/http.service';
import { UserPermissions } from '~/models/shared/permissions/user-permissions';
import { PermissionCreate, PermissionDelete, PermissionProjectCreate, PermissionProjectDeleteAll, PermissionProjectUpdateAll, PermissionUpdate, ResourcePermission } from '~/models/shared/permissions';

@Injectable()
export class UserPermissionsService {

    constructor(private readonly _offsetClient: HttpService) {}

    private cache$!: Observable<UserPermissions>;

    private static RoleAdmin = "Admin";
    private static RoleInventoryManager = "InventoryManager";
    private static RoleProjectManager = "ProjectManager";
    private static RoleProjectReader = "ProjectReader";

    static InventoryRoles: string[] = [UserPermissionsService.RoleAdmin, UserPermissionsService.RoleInventoryManager];
    static ProjectRoles: string[] = [UserPermissionsService.RoleAdmin, UserPermissionsService.RoleProjectManager, UserPermissionsService.RoleProjectReader];
  
    public getUserPermissions(): Observable<UserPermissions> {  
        if (!this.cache$) {
            this.cache$ = this.requestPermissions().pipe(
              shareReplay(1)
            );
          }
      
          return this.cache$;
    }

    private requestPermissions() {
      let registryUrl = `/UserPermissions`;
      return from(this._offsetClient.Get(registryUrl));
    }

    public async hasProjectAccess(): Promise<boolean> {
      let permissions =  await lastValueFrom(this.getUserPermissions().pipe(map(x => x)));
      if (permissions?.roles) {
        for (let role of permissions.roles) {
          if (UserPermissionsService.ProjectRoles.find(x => x === role.name)) {
            return true;
          }
        }
      }
      return false;  
    }

    public async hasAdminAccess(): Promise<boolean> {
      let permissions =  await lastValueFrom(this.getUserPermissions().pipe(map(x => x)));
      if (permissions?.roles) {
        for (let role of permissions.roles) {
          if (UserPermissionsService.RoleAdmin == role.name) {
            return true;
          }
        }
      }
      return false;  
    }

    public async hasInventoryAccess(): Promise<boolean> {
      let permissions =  await lastValueFrom(this.getUserPermissions().pipe(map(x => x)));
      if (permissions?.roles) {
        for (let role of permissions.roles) {
          if (UserPermissionsService.InventoryRoles.find(x => x === role.name)) {
            return true;
          }
        }
      }
      return false;  
    }

    public async canUpdateProjects(): Promise<boolean> {
      const permissions = await this.fetchUserPermissions();
      if (!permissions?.roles) {
        return false;
      }
    
      for (let role of permissions.roles) {
        if (this.hasProjectUpdatePermission(role.permissions)) {
          return true;
        }
      }
    
      return false;
    }
    
    public async canCreateProjects(): Promise<boolean> {
      const permissions = await this.fetchUserPermissions();
      if (!permissions?.roles) {
        return false;
      }
    
      for (let role of permissions.roles) {
        if (this.hasProjectCreationPermission(role.permissions)) {
          return true;
        }
      }
    
      return false;
    }

    public static checkPermission(userPermissions: UserPermissions, resourcePermission: ResourcePermission, adminOverride: boolean = false): boolean {
        if (userPermissions?.roles) {
            for (let role of userPermissions.roles) {
                if (adminOverride && role?.name == UserPermissionsService.RoleAdmin) {
                    return true;
                }

                if (role?.permissions?.find(p => p.resourceId == resourcePermission.resourceId && p.permissionId == resourcePermission.permissionId)) {
                    return true;
                }
            }
        }

        return false;
    }

    private async fetchUserPermissions(): Promise<any> {
      return lastValueFrom(this.getUserPermissions().pipe(map(x => x)));
    }
    
    private hasProjectCreationPermission(permissions: any[]): boolean {
      if (!permissions) {
        return false;
      }
    
      for (let permission of permissions) {
        if (this.isPermissionMatch(permission, PermissionProjectCreate) ||
            this.isPermissionMatch(permission, PermissionCreate)) {
          return true;
        }
      }
    
      return false;
    }

    private hasProjectUpdatePermission(permissions: any[]): boolean {
      if (!permissions) {
        return false;
      }
    
      for (let permission of permissions) {
        if (this.isPermissionMatch(permission, PermissionProjectUpdateAll) ||
            this.isPermissionMatch(permission, PermissionUpdate)) {
          return true;
        }
      }
    
      return false;
    }
    
    private isPermissionMatch(permission: any, targetPermission: any): boolean {
      return permission.resourceId === targetPermission.resourceId && 
             permission.permissionId === targetPermission.permissionId;
    }    
  
    public async canDeleteProjects(): Promise<boolean> {
      const permissions = await lastValueFrom(this.getUserPermissions().pipe(map(x => x)));
      
      return this.hasDeletePermission(permissions);
    }
  
    private hasDeletePermission(permissions: any): boolean {
      if (!permissions?.roles) return false;
  
      return permissions.roles.some(role => this.roleHasDeletePermission(role));
    }
  
    private roleHasDeletePermission(role: any): boolean {
      if (!role.permissions) return false;
  
      return role.permissions.some(permission => 
          this.isPermissionMatch(permission, PermissionProjectDeleteAll) ||
          this.isPermissionMatch(permission, PermissionDelete)
      );
    } 
}