import { Component, EventEmitter, Input, Output, TemplateRef, ViewChild } from '@angular/core';

import { SDKDataGridDataset } from 'sdk-datagrid';

import { Clone } from '~/classes/shared/clone';

import { MetaRegistryService } from '~/services/shared/metaregistry.service';
import { FormatterService } from '~/services/shared/formatter.service';

import { RequestParameters, SearchFilter } from '~/models/shared/request-parameters';

@Component({
    selector: 'project-detail-ratings',
    templateUrl: './project-detail-ratings.component.html',
    styleUrls: ['./project-detail-ratings.component.scss']
})

export class ProjectDetailRatingsComponent {
    @Input() project: any;
    @Input() isEdit: boolean = false;
    @Input() saveData: boolean = false;
    @Output() changeEvent: EventEmitter<any> = new EventEmitter();
    @Output() saveDataEvent: EventEmitter<any> = new EventEmitter();

    @ViewChild('actionLeft') actionLeft!: TemplateRef<any>;

    public isLoading: boolean = false;
    public projectEdit: any;
    public doSearch: boolean = false;
    public searchTerm: string = "";

    public MAXRECORDS: number = 1000;
    public activeView: string = "MetaRegistry/Search";
    public datasets: SDKDataGridDataset[] = [
        {
            Title: "MetaRegistry",
            DbName: "MetaRegistry/Search",
        }
    ];
    public data: string = "";
    public columns: any[] = [
        { Name: "Action", DisplayName: "Action", isVisible: true, actionSide: "left", actionTemplate: () => this.actionLeft },
        { Name: "id", DisplayName: "ID", isVisible: true },
        { Name: "name", DisplayName: "Name", isVisible: true },
        { Name: "registry", DisplayName: "Registry", isVisible: true },
        { Name: "status", DisplayName: "Status", isVisible: true },
        { Name: "scope", DisplayName: "Scope", isVisible: true },
        { Name: "developer", DisplayName: "Developer", isVisible: true },
        { Name: "creditsRemaining", DisplayName: "Credits Remaining", isVisible: true, formatter: (value: any) => this.formatterService.formatNumber(value) },
        { Name: "beZeroRating", DisplayName: "BeZero Rating", isVisible: true },
        { Name: "sylveraRating", DisplayName: "Sylvera Rating", isVisible: true },
        { Name: "calyxProjectGhgRating", DisplayName: "Calyx GHG Rating", isVisible: true },
        { Name: "directPriceEstimate", DisplayName: "Price Estimate", isVisible: true, formatter: (value: any) => this.formatterService.formatCurrency(value) },
    ];
    public dbPage: number = 0;
    public dbTotal: number = 0;
    public error: string = "";

    private searchTimer: any;

    constructor(
        private metaRegistryService: MetaRegistryService,
        private formatterService: FormatterService,
    ) { }

    //******************************************************************************
    //  Page Life-cycle Methods
    //******************************************************************************
    public async ngOnChanges() {
        if (this.saveData) {
            this.saveDataEvent.emit(false);
        }

        if (this.isEdit) {
            this.isLoading = true;

            this.projectEdit = Clone.deepCopy(this.project);

            this.isLoading = false;
        }
    }

    //******************************************************************************
    //  Public Methods
    //******************************************************************************
    public searchProjects(event: any) {
        this.doSearch = false;

        if (this.searchTimer) {
            clearTimeout(this.searchTimer);
        }

        if (event.toString().length > 1) {
            this.searchTimer = setTimeout(() => {
                this.searchTerm = event;
                this.doSearch = true;
            }, 500);
        }
    }

    public async loadData(event: any = null) {
        this.isLoading = true;

        this.MAXRECORDS = event.rows; // Overrides total records due to slowness in API.

        let parameters: RequestParameters = this.buildParameters(event);

        try {
            if (!parameters.Paging.pageSize) {
                parameters.Paging.pageSize = 100;
            }

            await this.metaRegistryService.getRegistry(parameters).then((data: any) => {
                if (data) {
                    this.resetVariables();

                    this.data = data.values;
                    this.dbPage = parseInt(data.metadata.currentPage);
                    this.dbTotal = parseInt(data.metadata.totalCount);
                    this.error = "";
                }
            });
        } catch (error: any) {
            this.error = error.message;
        }

        this.isLoading = false;
    }

    public setProject(record: any) {
        if (record) {
            this.projectEdit.metaRegistryProjectId = record.id;

            this.changeEvent.emit(this.projectEdit);

            this.searchTerm = "";
            this.doSearch = false;
        }
    }

    public clearProject() {
        this.projectEdit.metaRegistryProjectId = null;

        this.changeEvent.emit(this.projectEdit);
    }

    //*************************************************************************
    //  Private Methods
    //*************************************************************************
    private buildParameters(event: any = null): any {
        let parameters: RequestParameters = new RequestParameters();

        parameters.Paging = {
            pageSize: this.MAXRECORDS,
            currentPage: 1
        };

        parameters.SortingOptions = [];
        parameters.Terms = [];

        if (event) {
            this.buildParametersPage(event, parameters);
            this.buildParametersRows(event, parameters);
            this.metaRegistryService.buildParametersSorts(event, parameters);
            this.buildParametersFilters(parameters);
        }

        return parameters;
    }

    private buildParametersPage(event: any, parameters: any) {
        if (event.page) {
            parameters.Paging.currentPage = parseInt(event.page);
        }
    }

    private buildParametersRows(event: any, parameters: any) {
        parameters.Paging.pageSize = this.MAXRECORDS;
    }

    private buildParametersFilters(parameters: RequestParameters) {
      const filter = new SearchFilter();
      filter.Field = "keyword";
      filter.Value = this.searchTerm;
      filter.Operation = "contains";
      const filters: SearchFilter[] = [];
      filters.push(filter);
      this.metaRegistryService.buildParametersFilters(filters, parameters);
    }

    private resetVariables() {
        this.data = "";
        this.dbPage = 0;
        this.dbTotal = 0;
        this.error = "";
    }
}
