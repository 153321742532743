import { NgModule } from '@angular/core';
import { SDKGoogleMapComponent } from './sdk-google-map.component';

import { SDKLoadingModule } from 'sdk-loading';

@NgModule({
  declarations: [
    SDKGoogleMapComponent
  ],
  imports: [
    SDKLoadingModule,
  ],
  exports: [
    SDKGoogleMapComponent
  ]
})
export class SDKGoogleMapModule { }
