<table mat-table [dataSource]="dataSource" multiTemplateDataRows 
  aria-describedby="job definitions" matSort matSortDisableClear (matSortChange)="onSortChange($event)" class="jobs-table">

  <!-- JobId Column -->
  <ng-container matColumnDef="Name">
    <th mat-header-cell *matHeaderCellDef class="header-cell" mat-sort-header>Job Name</th>
    <td mat-cell *matCellDef="let job">
      <div class="columnAdjust" (click)="expandRow(job)">
        <span *ngIf="job.expanded" class="dg-icon expander material-icons-outlined">expand_more</span>
        <span *ngIf="!job.expanded" class="dg-icon expander material-icons-outlined">chevron_right</span>
        {{job.jobDefinition.name}}
      </div>
    </td>
  </ng-container>

  <ng-container matColumnDef="Recurrence">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Job Schedule</th>
    <td mat-cell *matCellDef="let job">{{job.jobDefinition.recurrence}}</td>
  </ng-container>

  <ng-container matColumnDef="Status">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Job Status</th>
    <td mat-cell *matCellDef="let job">{{job?.queuedJob?.status}}</td>
  </ng-container>

  <ng-container matColumnDef="StartTime">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Start Time</th>
    <td mat-cell *matCellDef="let job">{{job?.queuedJob?.startTime | date:'medium'}}</td>
  </ng-container>

  <ng-container matColumnDef="EndTime">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>End Time</th>
    <td mat-cell *matCellDef="let job">{{job?.queuedJob?.endTime | date:'medium'}}</td>
  </ng-container>

  <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
  <ng-container matColumnDef="expandedDetail">
    <td mat-cell *matCellDef="let job" [attr.colspan]="displayedColumns.length">
      <div class="detail-element" [@detailExpand]="job.expanded ? 'expanded' : 'collapsed'">
        <ccms-job-details [jobDefinition]="job.jobDefinition" [jobInstance]="job.queuedJob" [isExpanded]="job.expanded" (refreshEvent)="refreshJob($event)"></ccms-job-details>
      </div>
    </td>
  </ng-container>

  <ng-container matColumnDef="JobActions">
    <th mat-header-cell *matHeaderCellDef>Actions</th>
    <td mat-cell *matCellDef="let job">
      <span matTooltip="cancel" class="dg-icon material-icons-outlined" *ngIf="canCancelJob(job?.queuedJob?.status)" (click)="stopJob(job?.queuedJob?.id)">stop</span>
      <span matTooltip="run" class="dg-icon material-icons-outlined" *ngIf="!isJobRunning(job?.queuedJob?.status)" (click)="startJob(job?.jobDefinition)">play_arrow</span>
    </td>
  </ng-container>
  
  <!-- Update the displayedColumns array to include the new column -->
  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row class="element-row" 
    *matRowDef="let row; columns: displayedColumns;"></tr>
  <tr mat-row class="detail-row" *matRowDef="let row; columns: ['expandedDetail']"></tr>
</table>
<sdk-loading [isLoading]="isLoading"></sdk-loading>
