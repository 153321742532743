<div class="tabs">
    <div *ngFor="let tab of tabs" class="tab" (click)="setActiveTab(tab)" [ngClass]="{'active': activeTab && activeTab.title === tab.title, 'share': share}">
        <div *ngIf="share && activeTab && activeTab.title === tab.title" title="Share Information" class="icon material-icons-outlined" [cdkCopyToClipboard]="sharedUrl" [cdkCopyToClipboardAttempts]="2" (click)="copied()">share</div>
        <div class="title">{{ tab.title }}</div>
    </div>
    <div class="tab empty"></div>
</div>
<div *ngIf="activeTab && activeTab.type" class="info">
    <ng-container [ngComponentOutlet]="activeTab.type" [ndcDynamicInputs]="activeTab.inputs" [ndcDynamicOutputs]="activeTab.outputs"></ng-container>
</div>

