import { StringUtility } from "~/classes/shared/string-utility";
import { ProjectSummary } from "./project-summary";

export interface DeveloperProfileDto {
    id: number;
    name: string;
    description: string;
    website: string;
    country: string;
    yearFounded: number;
    projectCount: number;
    beZeroRatedProjectCount: number;
    calyxGhgRatedProjectCount: number;
    sylveraRatedProjectCount: number;
    totalCreditsGenerated: number;
    creditDeliveryRate: number;
    filteredCreditDeliveryRate: number;
    protocols: Array<string>;
    accreditations: Array<string>;
    programs: Array<string>;
    projects: ProjectSummary[];
    relatedDevelopers: string[];
}

export class DeveloperProfile {
    private readonly _data!: DeveloperProfileDto;
    private readonly _str = StringUtility;

    constructor(data: DeveloperProfileDto) {
        this._data = {
            ...data,
            beZeroRatedProjectCount: data.projects.filter(p => !this._str.IsNullOrWhiteSpace(p.beZeroRating)).length,
            calyxGhgRatedProjectCount: data.projects.filter(p => !this._str.IsNullOrWhiteSpace(p.calyxGhgRating)).length,
            sylveraRatedProjectCount: data.projects.filter(p => !this._str.IsNullOrWhiteSpace(p.sylveraRating)).length
        };
    }

    get id() {
        return this._data.id;
    }
    get name() {
        return this._data.name;
    }
    get description() {
        return this._data.description;
    }
    get website() {
        return this._data.website;
    }
    get country() {
        return this._data.country;
    }
    get yearFounded() {
        return this._data.yearFounded;
    }
    get projectCount() {
        return this._data.projectCount;
    }
    get beZeroRatedProjectCount() {
        return this._data.beZeroRatedProjectCount;
    }
    get calyxGhgRatedProjectCount() {
        return this._data.calyxGhgRatedProjectCount;
    }
    get sylveraRatedProjectCount() {
        return this._data.sylveraRatedProjectCount;
    }
    get totalCreditsGenerated() {
        return this._data.totalCreditsGenerated;
    }
    get creditDeliveryRate() {
        return this._data.creditDeliveryRate;
    }
    get filteredCreditDeliveryRate() {
        return this._data.filteredCreditDeliveryRate;
    }
    get accreditations() {
        return this._data.accreditations;
    }
    get programs() {
        return this._data.programs;
    }
    get projects() {
        return this._data.projects;
    }
    get relatedDevelopers() {
        return this._data.relatedDevelopers;
    }
    get ratedProjects() {
        return this._data.projects.filter(project => 
            !this._str.IsNullOrWhiteSpace(project.beZeroRating)
            || !this._str.IsNullOrWhiteSpace(project.calyxGhgRating)
            || !this._str.IsNullOrWhiteSpace(project.sylveraRating))
    }
}