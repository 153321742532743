import { Filters} from '../classes/filters';

export enum FilterType {
    Textbox = "text",
    Checkbox = "checkbox",
    Dropdown = "list"
}

export class SDKDataGridCustomFilter {
    public Name: string = ""; // Unique filter name.
    public DisplayName?: string = "";  // Name to display to user.
    public Type?: FilterType = FilterType.Textbox;

    // START: sdk-select properties for Dropdown filters.
    public FilterMultiSelect?: boolean = false; // Allows multiple filter values to be selected.
    public FilterTypes?: any[] = []; // Allows you to define specific filter types. NOTE: If FilterMultiSelect = true, this property is forced to [Filters.Equals, Filters.NotEquals].
    public FilterValues?: any[] = []; // Allows you to define specific filter values.
    // END: sdk-select properties for Dropdown filters.
}