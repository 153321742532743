<div class="content">
    <div class="data">
        <ccms-map-viewer
            [location]="mapLocation"
            [place]="place"
            [dataSources]="selectedMapDataSources">
        </ccms-map-viewer>
        <img *ngIf="showImage" alt="No Location" src="assets/images/project.png" width="100%" />
    </div>
    <div class="info">
        <tab-info [project]="project" [isEdit]="isEdit" [portfolioId]="portfolioId" (changeEvent)="infoChange($event)"></tab-info>

        <br />

        <div style="position: relative;">
            <table class="table-x">
                <caption style="display: none;">Location</caption>
                <tbody>
                    <tr>
                        <th scope="col">Street Address</th>
                        <td *ngIf="!isEdit">{{ project.location?.streetAddress || '-' }}</td>
                        <td *ngIf="isEdit"><sdk-textbox [value]="location?.streetAddress" (changeCallBackEvent)="addressChange('streetAddress', $event)"></sdk-textbox></td>
                    </tr>
                    <tr>
                        <th scope="col">City/Town</th>
                        <td *ngIf="!isEdit">{{ project.location?.addressLocality || '-' }}</td>
                        <td *ngIf="isEdit"><sdk-textbox [value]="location?.addressLocality" (changeCallBackEvent)="addressChange('addressLocality', $event)"></sdk-textbox></td>
                    </tr>
                    <tr>
                        <th scope="col">State/Province</th>
                        <td *ngIf="!isEdit">{{ project.location?.addressRegion || '-' }}</td>
                        <td *ngIf="isEdit"><sdk-textbox [value]="location?.addressRegion" (changeCallBackEvent)="addressChange('addressRegion', $event)"></sdk-textbox></td>
                    </tr>
                    <tr>
                        <th scope="col">Zip/Postal</th>
                        <td *ngIf="!isEdit">{{ project.location?.postalCode || '-' }}</td>
                        <td *ngIf="isEdit"><sdk-textbox [value]="location?.postalCode" (changeCallBackEvent)="addressChange('postalCode', $event)"></sdk-textbox></td>
                    </tr>
                    <tr>
                        <th scope="col">Latitude</th>
                        <td *ngIf="!isEdit">{{ project.site?.latitude || '-' }}</td>
                        <td *ngIf="isEdit"><sdk-textbox [value]="_site?.latitude" validCharacters="latitude" (changeCallBackEvent)="siteChange('latitude', $event)"></sdk-textbox></td>
                    </tr>
                    <tr>
                        <th scope="col">Longitude</th>
                        <td *ngIf="!isEdit">{{ project.site?.longitude || '-' }}</td>
                        <td *ngIf="isEdit"><sdk-textbox [value]="_site?.longitude" validCharacters="longitude" (changeCallBackEvent)="siteChange('longitude', $event)"></sdk-textbox></td>
                    </tr>
                </tbody>
            </table>
        </div>

        <br />

        <ng-container *ngIf="isEdit; else defaultMapFieldTemplate">
            <div class="header">Maps</div>
            <ccms-file-upload
                [options]="fileUploadOptions"
                [initialFileList]="projectShapefileList"
                (removeFile)="onFileUploaderRemoveFile($event)"
                (reset)="onFileUploaderReset()">
                <ng-template let-$fileList="fileList" let-$isDirty="isDirty" let-$onRemoveFile="onRemoveFile" file-list>
                    <ul class="file-list">
                        <li *ngFor="let fileItem of $fileList; let i = index" class="file-item">
                            <div class="file-info">
                                <span class="file-name"> 
                                    {{ fileItem.file.name }}
                                    <ng-container *ngIf="isDefaultShapefile(fileItem)">
                                        <span class="default-shapefile-badge">default</span>
                                    </ng-container>
                                    <ng-container *ngIf="canSetAsDefaultShapefile(fileItem)">
                                        (<a href="#" class="mark-default-link" 
                                            (click)="$isDirty(true);setDefaultShapefile(fileItem);$event.preventDefault()">mark as default</a>)
                                    </ng-container>
                                </span>
                            </div>                            
                            <span matTooltip="remove" class="remove-file-btn icon-trash-can" aria-label="remove"
                              (click)="$onRemoveFile(fileItem)"></span>
                        </li>
                    </ul>
                </ng-template>
            </ccms-file-upload>
        </ng-container>
        <ng-template #defaultMapFieldTemplate>
            <div *ngIf="hasShapefiles" class="map-files">
                <div class="header">Maps</div>
                <ul class="map-file-list">
                    <li *ngFor="let shapefile of mapDataSources; let i = index" class="map-file-item">
                        <span class="icon material-icons-outlined view-toggle-btn"
                            (click)="toggleShapefile(i)">
                            {{ isMapFileVisible(i) ? 'visibility_on' : 'visibility_off' }}
                        </span>
                        <span class="map-file-info">{{ shapefile.name }}</span>
                        <span (click)="downloadShapefile(i)"
                            class="icon material-icons-outlined download-file-btn">
                            download
                        </span>
                    </li>
                </ul>
            </div>
        </ng-template>
    </div>
</div>
