<div class="content">
    <div class="grid">
        <sdk-datagrid fontFamily="'GothamNarrowBook', sans-serif, 'Helvetica Neue', Arial"
                      [uniqueIdentifier]="uniqueIdentifier"
                      [datasets]="datasets"
                      [name]="activeView"
                      [data]="data"
                      [fullData]="fullData"
                      [columns]="columns"
                      [customFilters]="customFilters"
                      [filterTypes]="filterTypes"
                      [formulas]="formulas"
                      [options]="gridOptions"
                      [showHeader]=false
                      [minimizeOptions]=false
                      [editRowIndex]="editRowIndex"
                      [dbPage]="dbPage"
                      [dbTotal]="dbTotal"
                      [dbMAXRECORDS]="MAXRECORDS"
                      [isDataWrapped]=false
                      [isLoading]="isLoading"
                      [error]="error"
                      [optionAddons]="[exportOptionComponent]"
                      [windowAddons]="[exportWindowComponent]"
                      [settings]="allSettings"
                      (loadDataEvent)="loadData($event)"
                      (selectedRowActionEvent)="showDetail($event)"
                      (settingsSavedEvent)="saveSettings($event)">
        </sdk-datagrid>
    </div>

    <div class="footnote">
        {{ footnote }}
    </div>

    <ng-template #dataTemplate let-value="value">
        <div class="row-action">
            <div [title]="value" class="longtext">{{ value }}</div>
        </div>
    </ng-template>

    <ng-template #developerTemplate let-rowItem="rowItem">
        <div *ngIf="rowItem.cvxProjectDeveloper" class="row-action">
            <span>{{ rowItem.cvxProjectDeveloper.name }}</span>
        </div>
    </ng-template>

    <ng-template #developerEditTemplate let-rowItem="rowItem">
        <developer-search [currentDeveloper]="rowItem.cvxProjectDeveloper" (developerSelectedEvent)="rowItem.cvxProjectDeveloper = $event"> </developer-search>
    </ng-template>

    <ng-template #statusTemplate let-rowItem="rowItem">
        <div class="row-action">
            <ng-container *ngIf="rowItem.status?.id !== 11">
                <span>{{ rowItem.status.name }}</span>
            </ng-container>
            <ng-container *ngIf="rowItem.status?.id === 11 && rowItem?.didNotPursueReason?.id !== 7">
                <span>{{ rowItem.status.name }} - {{rowItem.didNotPursueReason?.name}}</span>
            </ng-container>
            <ng-container *ngIf="rowItem.status?.id === 11 && rowItem?.didNotPursueReason?.id === 7">
                <span [title]="rowItem.didNotPursueReasonOther">{{ rowItem.status.name }} - {{rowItem.didNotPursueReason?.name}}</span>
            </ng-container>
        </div>
    </ng-template>

    <ng-template #statusEditTemplate let-rowItem="rowItem">
        <div class="row-edit-action" style="margin: 0 10px;">
            <select (change)="setStatus(rowItem, $event)">
                <option value="" [selected]="!rowItem.status || rowItem.status === ''">...</option>
                <optgroup *ngFor="let category of projectStatusCategoryList" label="{{category}}">
                    <ng-container *ngFor="let status of projectStatusList">
                        <option *ngIf="category === status.category" [value]="status | json" [selected]="rowItem.status.name === status.name">{{ status.name }}</option>
                    </ng-container>
                </optgroup>
            </select>
            <ng-container>
                <div fxLayout="row" fxLayoutGap="10px" *ngIf="rowItem.status?.id === 11">
                    <div fxFlex="50" [ngClass]="{ 'required label': true }">"Did Not Pursue" Reason</div>
                    <div fxFlex="50">
                        <select class="required value" (change)="setDNPStatus(rowItem, $event)">
                            <option value="" [selected]="!rowItem.didNotPursueReason || rowItem.didNotPursueReason === ''">
                                ...
                            </option>

                            <ng-container *ngFor="let status of projectDNPStatusList">
                                <option [value]="status | json" [selected]="rowItem.didNotPursueReason?.name === status.name">
                                    {{
                                    status.name
                                    }}
                                </option>
                            </ng-container>
                        </select>
                    </div>
                </div>
                <div fxLayout="row" fxLayoutGap="10px" *ngIf="rowItem?.didNotPursueReason?.id === 7">
                    <div fxFlex="50" [ngClass]="{ 'required label': true }">"Other" Reason</div>
                    <div fxFlex="80">
                        <textarea class="edit" rows="5" style="width: 100%;" [value]="rowItem.didNotPursueReasonOther"
                                  (input)="setOtherReason(rowItem, $event)"></textarea>
                    </div>
                </div>
            </ng-container>
        </div>
    </ng-template>

    <ng-template #contactEditTemplate let-rowItem="rowItem">
        <div class="row-edit-action">
            <user-selection [entity]="entity"
                            [field]="'contact'"
                            [selectedUserName]="rowItem.contact"
                            (userSelectedEvent)="userSelectionChange('contact', rowItem,  $event)">
            </user-selection>
        </div>
    </ng-template>

    <ng-template #legalLeadEditTemplate let-rowItem="rowItem">
        <div class="row-edit-action">
            <user-selection [entity]="entity"
                            [field]="'legalLead'"
                            [selectedUserName]="rowItem.legalLead"
                            (userSelectedEvent)="userSelectionChange('legalLead', rowItem,  $event)">
            </user-selection>
        </div>
    </ng-template>

    <ng-template #originatorEditTemplate let-rowItem="rowItem">
        <div class="row-edit-action">
            <user-selection [entity]="entity"
                            [field]="'originator'"
                            [selectedUserName]="rowItem.originator"
                            (userSelectedEvent)="userSelectionChange('originator', rowItem,  $event)">
            </user-selection>
        </div>
    </ng-template>

    <ng-template #termSheetLeadEditTemplate let-rowItem="rowItem">
        <div class="row-edit-action">
            <user-selection [entity]="entity"
                            [field]="'termSheetLead'"
                            [selectedUserName]="rowItem.termSheetLead"
                            (userSelectedEvent)="userSelectionChange('termSheetLead', rowItem,  $event)">
            </user-selection>
        </div>
    </ng-template>

    <ng-template #negotiatorEditTemplate let-rowItem="rowItem">
        <div class="row-edit-action">
            <user-selection [entity]="entity"
                            [field]="'negotiator'"
                            [selectedUserName]="rowItem.negotiator"
                            (userSelectedEvent)="userSelectionChange('negotiator', rowItem,  $event)">
            </user-selection>
        </div>
    </ng-template>

    <ng-template #technicalLeadEditTemplate let-rowItem="rowItem">
        <div class="row-edit-action">
            <user-selection [entity]="entity"
                            [field]="'technicalLead'"
                            [selectedUserName]="rowItem.technicalLead"
                            (userSelectedEvent)="userSelectionChange('technicalLead', rowItem,  $event)">
            </user-selection>
        </div>
    </ng-template>

    <ng-template #projectTypeTemplate let-rowItem="rowItem">
        <div *ngIf="rowItem.cvxProjectType" class="row-action">
            <div *ngIf="rowItem.cvxProjectType.cvxProjectScope" style="display: inline-block;">
                {{ rowItem.cvxProjectType.cvxProjectScope.longName }}&nbsp;-&nbsp;
            </div>
            <span>{{ rowItem.cvxProjectType.name }}</span>
        </div>
    </ng-template>

    <ng-template #projectTypeEditTemplate let-rowItem="rowItem">
        <div class="row-edit-action" style="margin: 0 10px;">
            <select (change)="setProjectType(rowItem, $event)">
                <option value="" [selected]="!rowItem.cvxProjectType || rowItem.cvxProjectType === ''">...</option>

                <ng-container *ngFor="let type of projectTypeList">
                    <optgroup [label]="type.name">
                        <ng-container *ngFor="let subtype of type.cvxProjectTypes">
                            <option [value]="type.name + ',' + subtype.id" [selected]="rowItem.cvxProjectType && rowItem.cvxProjectType.cvxProjectScopeName === type.name && rowItem.cvxProjectType.name === subtype.name">{{ subtype.name }}</option>
                        </ng-container>
                    </optgroup>
                </ng-container>
            </select>
        </div>
    </ng-template>

    <ng-template #countryTemplate let-rowItem="rowItem" let-value="value">
        <div *ngIf="rowItem.country" class="row-action">
            <div *ngIf="rowItem.country" style="display: inline-block;">
                {{ rowItem.country.name }}
            </div>

        </div>
        <div *ngIf="rowItem.isGlobalLocation" style="display: inline-block;">
            {{ globalLocation.name }}
        </div>
    </ng-template>

    <ng-template #countryEditTemplate let-rowItem="rowItem">
        <div class="row-edit-action">
            <table class="table-edit">
                <caption style="display: none;">Data</caption>
                <tbody>
                    <tr>
                        <th scope="col" colspan="100" class="required label">Country</th>
                    </tr>
                    <tr>
                        <td colspan="100">
                            <div>
                                <select (change)="setCountry(rowItem, $event)">
                                    <option value="" [selected]="!rowItem.country || rowItem.country === ''">...</option>

                                    <ng-container *ngFor="let country of countryList">
                                        <option *ngIf="rowItem.isGlobalLocation == null" [value]="country | json" [selected]="rowItem.country && rowItem.country !== undefined && rowItem.country.alpha3Code === country.alpha3Code">{{ country.name }}</option>
                                        <option *ngIf="rowItem.isGlobalLocation" [value]="country | json" [selected]="country.id === globalLocation.id">{{ country.name }}</option>
                                    </ng-container>
                                </select>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </ng-template>

    <ng-template #descriptionEditTemplate let-rowItem="rowItem">
        <div class="row-action description-edit">
            <div style="padding: 10px;">
                <textarea class="text" [attr.maxlength]="maxDescriptionLength" rows="3" [value]="rowItem.description" (input)="setDescription(rowItem, $event)"></textarea>
            </div>
            <div class="counter">
                <counter [current]="rowItem && rowItem.description? rowItem.description.length : 0" [max]="maxDescriptionLength"></counter>
            </div>
        </div>
       
    </ng-template>

    <ng-template #methodologyTemplate let-rowItem="rowItem">
        <div class="row-action">
            <div [title]="parseMethodologyTitle(rowItem)" class="longtext">
                {{ parseMethodologyDisplayText(rowItem) }}
            </div>
        </div>
    </ng-template>

    <ng-template #methodologyEditTemplate let-rowItem="rowItem">
        <div class="row-edit-action" style="width: 500px;">
            <div>
                <ccms-methodology-selector [methodologyId]="rowItem.methodology?.id"
                                           (methodologySelected)="setMethodology(rowItem, $event)"></ccms-methodology-selector>
            </div>
        </div>
    </ng-template>

    <ng-template #ownerTemplate let-rowItem="rowItem">
        <div *ngIf="rowItem.owners" class="row-action">
            <ng-container *ngFor="let owner of rowItem.owners">
                <div style="white-space: nowrap;">
                    <div style="display: inline-block;" [title]="(rowItem.owners.length === 1) ? owner.percentOwned + '%' : ''">{{ owner.accountName }}</div>
                    <div *ngIf="rowItem.owners.length > 1" style="display: inline-block;">
                        &nbsp;-&nbsp;{{ owner.percentOwned }}%
                    </div>
                </div>
            </ng-container>
        </div>
    </ng-template>

    <ng-template #ownerEditTemplate let-rowItem="rowItem">
        <div class="row-edit-action">
            <table class="table-edit">
                <caption style="display: none;">Data</caption>
                <tbody>
                    <tr>
                        <th scope="col">Name</th>
                        <th scope="col">%</th>
                        <th scope="col">Delete</th>
                    </tr>
                    <tr *ngFor="let owner of rowItem.owners">
                        <td><div><sdk-textbox [value]="owner.accountName" width="125px" (changeCallBackEvent)="setOwner(rowItem, owner.accountId, 'accountName', $event)"></sdk-textbox></div></td>
                        <td><div><sdk-textbox validCharacters="decimal" pattern="1.0-2" width="50px" [value]="owner.percentOwned" (changeCallBackEvent)="setOwner(rowItem, owner.accountId, 'percentOwned', $event)"></sdk-textbox></div></td>
                        <td style="text-align: center;"><div title="Delete" class="icon material-icons-outlined cursor delete" (click)="deleteOwner(rowItem, owner.accountId)">delete</div></td>
                    </tr>
                    <tr>
                        <td colspan="100"><div><button class="button small" (click)="addOwner(rowItem)">+ Owner</button></div></td>
                    </tr>
                </tbody>
            </table>
        </div>
    </ng-template>

    <ng-template #executionStartDateTemplate let-rowItem="rowItem">
        <div class="row-action">
            <span>{{ (rowItem.executionStartDate ? rowItem.executionStartDate.split('T')[0] : '') | date:'MM/dd/yyyy' }}</span>
        </div>
    </ng-template>

    <ng-template #ndaExecutionDateTemplate let-rowItem="rowItem">
        <div class="row-action">
            <span>{{ (rowItem.ndaExecutionDate ? rowItem.ndaExecutionDate.split('T')[0] : '') | date:'MM/dd/yyyy' }}</span>
        </div>
    </ng-template>

    <ng-template #actionRight let-rowItem="rowItem" let-activeRow="activeRow" let-index="index">
        <div *ngIf="!rowItem.isEdit && canUpdate" class="action">
            <div title="Edit" class="icon material-icons-outlined" (click)="$event.stopPropagation(); editProject(index)">edit</div>
            <div title="Delete" class="icon material-icons-outlined delete" (click)="$event.stopPropagation(); deleteProject(rowItem)">delete</div>
        </div>
        <div *ngIf="rowItem.isEdit && canUpdate" class="action">
            <div title="Save" class="icon material-icons-outlined done" (click)="$event.stopPropagation(); saveProject(activeRow)">done</div>
            <div title="Cancel" class="icon material-icons-outlined cancel" (click)="$event.stopPropagation(); cancelEdit()">close</div>
        </div>
    </ng-template>

    <ng-template #customerProductTemplate let-rowItem="rowItem">
        <div class="row-action">
            <span>{{ rowItem.product?.name }}</span>
        </div>
    </ng-template>

    <ng-template #customerProductEditTemplate let-rowItem="rowItem">
        <div class="row-edit-action">
            <select (change)="setProduct(rowItem, $event)">
                <option value="" [selected]="!rowItem.product || rowItem.product === ''">...</option>

                <ng-container *ngFor="let product of customerProductList">
                    <option [value]="product | json" [selected]="rowItem.product?.id === product.id">{{ product.name }}</option>
                </ng-container>
            </select>
        </div>
    </ng-template>

    <ng-template #investmentTypeTemplate let-rowItem="rowItem">
        <div class="row-action">
            <span>{{ rowItem.investmentType?.name }}</span>
        </div>
    </ng-template>

    <ng-template #investmentTypeEditTemplate let-rowItem="rowItem">
        <div class="row-edit-action">
            <select (change)="setInvestmentType(rowItem, $event)">
                <option value="" [selected]="!rowItem.investmentType || rowItem.investmentType === ''">...</option>

                <ng-container *ngFor="let investmentType of investmentTypeList">
                    <option [value]="investmentType | json" [selected]="rowItem.investmentType?.name === investmentType.name">
                        {{ investmentType.name }}
                    </option>
                </ng-container>
            </select>
        </div>
    </ng-template>

    <ng-template #technicalReviewStatusTemplate let-rowItem="rowItem">
        <div class="row-action">
            <span>{{ rowItem.technicalReviewStatus?.name }}</span>
        </div>
    </ng-template>

    <ng-template #technicalReviewStatusEditTemplate let-rowItem="rowItem">
        <div class="row-edit-action">
            <select (change)="setTechnicalReviewStatus(rowItem, $event)">
                <option value="" [selected]="!rowItem.technicalReviewStatus || rowItem.technicalReviewStatus === ''">...</option>

                <ng-container *ngFor="let technicalReviewStatus of technicalReviewStatusList">
                    <option [value]="technicalReviewStatus | json" [selected]="rowItem.technicalReviewStatus?.name === technicalReviewStatus.name">
                        {{ technicalReviewStatus.name }}
                    </option>
                </ng-container>
            </select>
        </div>
    </ng-template>

    <ng-template #technicalReviewRecommendationTemplate let-rowItem="rowItem">
        <div class="row-action">
            <span>{{ rowItem.technicalReviewRecommendation?.name }}</span>
        </div>
    </ng-template>

    <ng-template #technicalReviewRecommendationEditTemplate let-rowItem="rowItem">
        <div class="row-edit-action">
            <select (change)="setTechnicalReviewRecommendation(rowItem, $event)">
                <option value="" [selected]="!rowItem.technicalReviewRecommendation || rowItem.technicalReviewRecommendation === ''">
                    ...
                </option>

                <ng-container *ngFor="let technicalReviewRecommendation of technicalReviewRecommendationList">
                    <option [value]="technicalReviewRecommendation | json"
                            [selected]="rowItem.technicalReviewRecommendation?.name === technicalReviewRecommendation.name">
                        {{
                        technicalReviewRecommendation.name
                        }}
                    </option>
                </ng-container>
            </select>
        </div>
    </ng-template>

    <ng-template #wsjfBusinessValueTemplate let-rowItem="rowItem">
        <div class="row-action">
            <span>{{ rowItem.wsjfScorecard?.businessValue }}</span>
        </div>
    </ng-template>

    <ng-template #wsjfBusinessValueEditTemplate let-rowItem="rowItem">
        <div class="row-edit-action" style="margin: 0 10px;">
            <span>{{ rowItem.wsjfScorecard?.businessValue }}</span>
        </div>
    </ng-template>

    <ng-template #wsjfTimeCriticalityTemplate let-rowItem="rowItem">
        <div class="row-action">
            <span>{{ rowItem.wsjfScorecard?.timeCriticality }}</span>
        </div>
    </ng-template>

    <ng-template #wsjfTimeCriticalityEditTemplate let-rowItem="rowItem">
        <div class="row-edit-action" style="margin: 0 10px;">
            <span>{{ rowItem.wsjfScorecard?.timeCriticality }}</span>
        </div>
    </ng-template>

    <ng-template #wsjfRiskReductionTemplate let-rowItem="rowItem">
        <div class="row-action">
            <span>{{ rowItem.wsjfScorecard?.riskReductionOpportunityEnablementValue }}</span>
        </div>
    </ng-template>

    <ng-template #wsjfRiskReductionEditTemplate let-rowItem="rowItem">
        <div class="row-edit-action" style="margin: 0 10px;">
            <span>{{ rowItem.wsjfScorecard?.riskReductionOpportunityEnablementValue }}</span>
        </div>
    </ng-template>

    <ng-template #wsjfEstimatedEffortTemplate let-rowItem="rowItem">
        <div class="row-action">
            <span>{{ rowItem.wsjfScorecard?.estimatedImplementationEffort }}</span>
        </div>
    </ng-template>

    <ng-template #wsjfEstimatedEffortEditTemplate let-rowItem="rowItem">
        <div class="row-edit-action" style="margin: 0 10px;">
            <span>{{ rowItem.wsjfScorecard?.estimatedImplementationEffort }}</span>
        </div>
    </ng-template>

    <ng-template #wsjfEstimatedEffortEditTemplate let-rowItem="rowItem">
        <div class="row-edit-action" style="margin: 0 10px;">
            <span>{{ rowItem.wsjfScorecard?.estimatedImplementationEffort }}</span>
        </div>
    </ng-template>

    <ng-template #wsjfScoreTemplate let-rowItem="rowItem">
        <div class="row-action">
            <span>{{ rowItem.wsjfScorecard?.wsjfScore | number : '1.2-2' }}</span>
        </div>
    </ng-template>

    <ng-template #wsjfScoreEditTemplate let-rowItem="rowItem">
        <div class="row-edit-action" style="margin: 0 10px;">
            <span>{{ rowItem.wsjfScorecard?.wsjfScore | number : '1.2-2' }}</span>
        </div>
    </ng-template>

    <ng-template #projectPortfolioIdTemplate let-rowItem="rowItem">
        <span [projectPortfolioId]="rowItem.projectPortfolioId"></span>
    </ng-template>

    <ng-template #projectPortfolioIdEditTemplate let-rowItem="rowItem">
        <div class="row-edit-action">
            <div>
                <ccms-portfolio-epic-select [initialValue]="rowItem.projectPortfolioId"
                                            (changed)="rowItem.projectPortfolioId = $event;"></ccms-portfolio-epic-select>
            </div>
        </div>
    </ng-template>

    <ng-template #projectTypeRatingEdit let-rowItem="rowItem">
        <div class="priority-rating row-edit-action">
            <input type="number" min="1" max="3" [(ngModel)]="rowItem.projectTypeRating" />
            <span class="material-icons-outlined info-icon" title="{{ getPriorityScoreCardToolTip('projectType') }}">info</span>
        </div>
    </ng-template>

    <ng-template #geographyRatingEdit let-rowItem="rowItem">
        <div class="priority-rating row-edit-action">
            <input type="number" min="1" max="3" [(ngModel)]="rowItem.geographyRating" />
            <span class="material-icons-outlined info-icon" title="{{ getPriorityScoreCardToolTip('geography') }}">info</span>
        </div>
    </ng-template>

    <ng-template #developerRatingEdit let-rowItem="rowItem">
        <div class="priority-rating row-edit-action">
            <input type="number" min="1" max="3" [(ngModel)]="rowItem.developerRating" />
            <span class="material-icons-outlined info-icon" title="{{ getPriorityScoreCardToolTip('developer') }}">info</span>
        </div>
    </ng-template>

    <ng-template #fungibilityRatingEdit let-rowItem="rowItem">
        <div class="priority-rating row-edit-action">
            <input type="number" min="1" max="3" [(ngModel)]="rowItem.fungibilityRating" />
            <span class="material-icons-outlined info-icon" title="{{ getPriorityScoreCardToolTip('fungibility') }}">info</span>
        </div>
    </ng-template>

    <ng-template #scaleRatingEdit let-rowItem="rowItem">
        <div class="priority-rating row-edit-action">
            <input type="number" min="1" max="3" [(ngModel)]="rowItem.scaleRating" />
            <span class="material-icons-outlined info-icon" title="{{ getPriorityScoreCardToolTip('scale') }}">info</span>
        </div>
    </ng-template>

    <ng-template #economicsRatingEdit let-rowItem="rowItem">
        <div class="priority-rating row-edit-action">
            <input type="number" min="1" max="3" [(ngModel)]="rowItem.economicsRating" />
            <span class="material-icons-outlined info-icon" title="{{ getPriorityScoreCardToolTip('economics') }}">info</span>
        </div>
    </ng-template>

    <ng-template #initialScreeningPriorityEdit let-rowItem="rowItem">
        <div class="row-edit-action" style="margin: 0 10px;">
            <span>{{ rowItem.initialScreeningPriority }}</span>
        </div>
    </ng-template>

    <ng-template #priorityWithEconomicsEdit let-rowItem="rowItem">
        <div class="row-edit-action" style="margin: 0 10px;">
            <span>{{ rowItem.priorityWithEconomics }}</span>
        </div>
    </ng-template>
</div>
