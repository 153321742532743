import { Component, EventEmitter, OnInit, Output, ViewChild } from "@angular/core";
import { MultiSelectComponent } from "~/components/shared/multi-select/multi-select.component";
import { SearchableMultiSelectComponent } from "~/components/shared/searchable-multi-select/searchable-multi-select";
import { IRetirementCreditFilter } from "~/services/shared/retirements/metaregistry-retired-credits.model";
import { RetiredCreditsService } from "~/services/shared/retirements/metaregistry-retired-credits.service";

@Component({
  selector: 'retired-chart-filters',
  templateUrl: './retired-chart-filters.component.html',
  styleUrls: ['./retired-chart-filters.component.scss']
})
export class RetiredChartFiltersComponent implements OnInit {
  @Output() startChanged: EventEmitter<Date> = new EventEmitter<Date>();
  @Output() endChanged: EventEmitter<Date> = new EventEmitter<Date>();
  @Output() filtersChanged: EventEmitter<IRetirementCreditFilter[]> = new EventEmitter<IRetirementCreditFilter[]>;
  @Output() exportFired: EventEmitter<any> = new EventEmitter<any>();


  
  @ViewChild('projectTypeSelect') projectTypeSelect!: MultiSelectComponent;
  @ViewChild('methodologySelect') methodologySelect!: MultiSelectComponent;
  @ViewChild('productTypeSelect') productTypeSelect!: MultiSelectComponent;
  @ViewChild('certificationSelect') certificationSelect!: MultiSelectComponent;
  @ViewChild('regionSelect') regionSelect!: MultiSelectComponent;
  @ViewChild('countrySelect') countrySelect!: MultiSelectComponent;
  @ViewChild('registrySelect') registrySelect!: MultiSelectComponent;
  @ViewChild('retiredForSelect') retiredForSelect!: SearchableMultiSelectComponent;

  private selectedFilters: IRetirementCreditFilter[] = [];
  public projectTypes: string[] = [];
  public methodologies: string[] = [];
  public productTypes: string[] = [];
  public certifications: string[] = [];
  public regions: string[] = [];
  public countries: string[] = [];
  public registries: string[] = [];
  public retiredFor: string[] = []

  private projectTypeFieldName!: string;
  private methodologyFieldName!: string;
  private productTypeFieldName!: string;
  private certificationFieldName!: string;
  private regionFieldName!: string;
  private countryFieldName!: string;
  private registryFieldName!: string;
  private retiredForFieldName: string = "RetiredFor";

  private takeRetiredFor = 100;

  constructor(private readonly retiredCreditsService: RetiredCreditsService) {
  }

  public async ngOnInit(): Promise<void> {
    this.isLoading = true;
    this.initDates();
    this.loadFilters();
  }
  
  public start!: Date;
  public end!: Date;

  public showWindow = false;
  public isLoading = false;

  public selectedStartDateChanged(event: any) {
    this.start = event.value;
    this.startChanged.emit(this.start);
    console.log(event);
  }

  public selectedEndDateChanged(event: any) {
    this.end = event.value;
    this.endChanged.emit(this.end);
    console.log(event);
  }

  public showFilters() {
    this.showWindow = true;
  }

  public hideFilters() {
    this.showWindow = false;
  }

  public resetFilters() {
    this.initDates();
    this.selectedFilters = [];
    this.productTypeSelect.resetOptions();
    this.methodologySelect.resetOptions();
    this.productTypeSelect.resetOptions();
    this.projectTypeSelect.resetOptions();
    this.certificationSelect.resetOptions();
    this.regionSelect.resetOptions();
    this.countrySelect.resetOptions();
    this.registrySelect.resetOptions();
    this.retiredForSelect.resetOptions();
    this.filtersChanged.emit(this.selectedFilters);
  }

  handleRetiredForSearch(newValue: string) {
    this.retiredCreditsService.getOwnerFilters(this.takeRetiredFor, newValue)
      .subscribe((response) => {
        this.retiredFor = response;
      });
  }

  private initDates() {
    this.start = new Date(new Date().getFullYear() - 5, new Date().getMonth(), new Date().getDate());
    this.end = new Date();
    this.startChanged.emit(this.start);
    this.endChanged.emit(this.end);
  }

  private loadFilters() {
    this.retiredCreditsService.getOwnerFilters(this.takeRetiredFor)
      .subscribe((response) => {
        this.retiredFor = response;
        this.isLoading = false;
      });
    this.retiredCreditsService.getProjectTypeFilters()
        .subscribe((response) => {
            this.projectTypes = response.map(item => item.value);
            this.projectTypeFieldName = response[0].fieldName;
        });
    this.retiredCreditsService.getProductTypeFilters()
        .subscribe(response => {
            this.productTypes = response.map(item => item.value);
            if (response[0]) {
              this.productTypeFieldName = response[0].fieldName;
            }
        });
    this.retiredCreditsService.getMethodologyFilters()
        .subscribe(response => {
            this.methodologies = response.map(item => item.value);
            this.methodologyFieldName = response[0].fieldName;
        });
    this.retiredCreditsService.getCertificationFilters()
        .subscribe(response => {
            this.certifications = response.map(item => item.value);
            if (response[0]) {
              this.certificationFieldName = response[0].fieldName;
            }
        });
    this.retiredCreditsService.getRegions()
        .subscribe(response => {
            this.regions = response.map(item => item.value);
            this.regionFieldName = response[0].fieldName;
        });
    this.retiredCreditsService.getCountries()
        .subscribe(response => {
            this.countries = response.map(item => item.value);
            if (response[0]) {
             this.countryFieldName = response[0].fieldName;
            }
        });
    this.retiredCreditsService.getRegistries()
        .subscribe(response => {
            this.registries = response.map(item => item.value);
            this.registryFieldName = response[0].fieldName;
        });
  }

  public OnChangeRetiredFor(ids: any[]) {
    if (ids === null || ids === undefined || ids.length < 1) {
        this.selectedFilters = this.selectedFilters.filter(element => element.fieldName != this.retiredForFieldName);
    }
    else {
      const filters = ids.map(pt => {
        return {
            displayName: '',
            value: pt,
            fieldName: this.retiredForFieldName
        };
      });
    
      this.updateFilterParameters(ids, filters);
    }

    this.filtersChanged.emit(this.selectedFilters);
  }

  public OnChangeProjectType(ids: any[]) {
    if (ids === null || ids === undefined || ids.length < 1) {
        this.selectedFilters = this.selectedFilters.filter(element => element.fieldName != this.projectTypeFieldName);
    }
    else {
      const filters = ids.map(pt => {
        return {
            displayName: '',
            value: pt,
            fieldName: this.projectTypeFieldName
        };
      });
    
      this.updateFilterParameters(ids, filters);
    }

    this.filtersChanged.emit(this.selectedFilters);
  }

  public OnChangeProductType(ids: any[]) {
    if (ids === null || ids === undefined || ids.length < 1) {
        this.selectedFilters = this.selectedFilters.filter(element => element.fieldName != this.productTypeFieldName);
    }
    else {
      const filters = ids.map(pt => {
        return {
            displayName: '',
            value: pt,
            fieldName: this.productTypeFieldName
        };
      });
      
      this.updateFilterParameters(ids, filters);
    }

    this.filtersChanged.emit(this.selectedFilters);
  }

  public OnChangeMethodology(ids: any[]) {
    if (ids === null || ids === undefined || ids.length < 1) {
        this.selectedFilters = this.selectedFilters.filter(element => element.fieldName != this.methodologyFieldName);
    }
    else {
      const filters = ids.map(pt => {
        return {
            displayName: '',
            value: pt,
            fieldName: this.methodologyFieldName
        };
      });

      this.updateFilterParameters(ids, filters);
    }

    this.filtersChanged.emit(this.selectedFilters);
  }

  public OnChangeCertification(ids: any[]) {
    if (ids === null || ids === undefined || ids.length < 1) {
        this.selectedFilters = this.selectedFilters.filter(element => element.fieldName != this.certificationFieldName);
    }
    else {
      const filters = ids.map(pt => {
        return {
            displayName: '',
            value: pt,
            fieldName: this.certificationFieldName
        };
      });
      
      this.updateFilterParameters(ids, filters);
    }

    this.filtersChanged.emit(this.selectedFilters);
  }

  public OnChangeRegion(ids: any[]): void {
    if (ids === null || ids === undefined || ids.length < 1) {
      this.selectedFilters = this.selectedFilters.filter(element => element.fieldName != this.regionFieldName);
    }
    else {
      const filters = ids.map(pt => {
        return {
            displayName: '',
            value: pt,
            fieldName: this.regionFieldName
        };
      });

      this.updateFilterParameters(ids, filters);
    }

    this.filtersChanged.emit(this.selectedFilters);
  }

  public OnChangeCountry(ids: any[]): void {
    if (ids === null || ids === undefined || ids.length < 1) {
      this.selectedFilters = this.selectedFilters.filter(element => element.fieldName != this.countryFieldName);
    }
    else {
      const filters = ids.map(pt => {
        return {
            displayName: '',
            value: pt,
            fieldName: this.countryFieldName
        };
      });

      this.updateFilterParameters(ids, filters);
    }

    this.filtersChanged.emit(this.selectedFilters);
  }

  public OnChangeRegistry(ids: any[]): void {
    if (ids === null || ids === undefined || ids.length < 1) {
      this.selectedFilters = this.selectedFilters.filter(element => element.fieldName != this.registryFieldName);
    }
    else {
      const filters = ids.map(pt => {
        return {
            displayName: '',
            value: pt,
            fieldName: this.registryFieldName
        };
      });

      this.updateFilterParameters(ids, filters);
    }

    this.filtersChanged.emit(this.selectedFilters);
  }

  public export() {
    this.exportFired.emit();
  }

  private updateFilterParameters(ids: any[], filters: IRetirementCreditFilter[]) {
    ids.forEach(id => {
      this.selectedFilters.push(...filters.filter(c => c.value == id));
    });
  }
}