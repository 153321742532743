import { Injectable } from "@angular/core";
import { Observable, from, lastValueFrom, map } from "rxjs";
import { ClimatePolicy } from "~/models/shared/policy-tracker/climate-policy.model";
import { HttpService } from "~/services/core/http.service";

@Injectable()
export class ClimatePolicyService {
  constructor(private readonly _offsetClient: HttpService) { }

  private getClimatePolicies(alpha3Code: string, region?: string): Observable<ClimatePolicy[]> {
    let serviceUri = `/PolicyTracker/climatePolicies?countryAlpha3Code=${alpha3Code}`;
    if (region) {
      serviceUri = serviceUri + `&region=${region}`;
    }

    return from(this._offsetClient.Get(serviceUri));
  }

  public async getClimatePoliciesByCountry(alpha3Code: string): Promise<ClimatePolicy[]> {
    return await lastValueFrom(this.getClimatePolicies(alpha3Code).pipe(map(x => x)));
  }

  public async getClimatePoliciesByCountryAndRegion(alpha3Code: string, region: string): Promise<ClimatePolicy[]> {
    return await lastValueFrom(this.getClimatePolicies(alpha3Code, region).pipe(map(x => x)));
  }
}