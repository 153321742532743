<div class="sdk-datagrid-window">
    <div class="header">
        <span class="title">Filters</span>
        <span title="Close" class="sdk-icon close" (click)="close()">close</span>
    </div>
    <div class="actions">
        <button class="button button-left" type="button" (click)="addFilter()">Add filter</button>
    </div>
    <div cdkDropList class="content" (cdkDropListDropped)="drop($event)">
        <div *ngIf="filterList.length === 0" class="no-records">
            <span>Add a filter.</span>
        </div>
        
        <div class="box" [ngClass]="{ 'no-selection': ['System', 'Custom'].includes(filter.Type), 'checkbox': filter.Type === 'Any' }" *ngFor="let filter of filterList" cdkDragBoundary=".list" cdkDrag>
            <div *ngIf="filter.Type === 'Adhoc'" class="delete">
                <span class="sdk-icon" (click)="deleteFilter(filter)">delete</span>
            </div>
            <div>
                <div *ngIf="filter.Type === 'Adhoc'" class="column">
                    <select class="select" (change)="addColumn($event, filter)">
                        <option selected disabled value="">Select column...</option>
                        <ng-container *ngFor="let column of columnList">
                            <option [value]="column.Name" [selected]="(filter.Name === column.Name) ? 'selected' : ''">{{ column.FriendlyName === '' ? getColumnOriginalName(column) : column.FriendlyName }}</option>
                        </ng-container>
                    </select>
                </div>
                <div *ngIf="['System', 'Custom'].includes(filter.Type) && ['text', 'list'].includes(filter.ValueType)" class="column no-delete">
                    <div class="name">{{ filter.DisplayName }}</div>
                </div>
                <div *ngIf="['Custom'].includes(filter.Type) && ['text'].includes(filter.ValueType) && filter.Operation === ''" class="operation-value no-delete-operation">
                    <div class="value no-operation">
                        <input [value]="filter.Value" (input)="addValue($event, filter)" />
                    </div>
                </div>
                <div *ngIf="['Custom'].includes(filter.Type) && filter.ValueType === 'checkbox'" class="column-checkbox">
                    <div class="all-select" (click)="toggleAny(filter)">
                        <input type="checkbox" class="select" [checked]="filter.Value === 'true' ? 'checked' : ''" />
                        <span>{{ filter.DisplayName }}</span>
                    </div>
                </div>
                <div *ngIf="['System', 'Adhoc'].includes(filter.Type) || (['Custom'].includes(filter.Type) && filter.Operation !== '')" class="operation-value" [ngClass]="{ 'no-delete': ['System', 'Custom'].includes(filter.Type) }">
                    <select *ngIf="['System', 'Custom', 'Adhoc'].includes(filter.Type)" class="operation" (change)="addOperation($event, filter)">
                        <option selected disabled value="">Select operator...</option>
                        <option *ngIf="filterExists('Equals', filter)" value="Equals" [selected]="(filter.Operation === 'Equals') ? 'selected' : ''">Equals</option>
                        <option *ngIf="filterExists('NotEquals', filter)" value="NotEquals" [selected]="(filter.Operation === 'NotEquals') ? 'selected' : ''">Not Equals</option>
                        <option *ngIf="filterExists('Empty', filter)" value="Empty" [selected]="(filter.Operation === 'Empty') ? 'selected' : ''">Empty</option>
                        <option *ngIf="filterExists('NotEmpty', filter)" value="NotEmpty" [selected]="(filter.Operation === 'NotEmpty') ? 'selected' : ''">Not Empty</option>
                        <option *ngIf="filterExists('Contains', filter)" value="Contains" [selected]="(filter.Operation === 'Contains') ? 'selected' : ''">Contains</option>
                        <option *ngIf="filterExists('NotContains', filter)" value="NotContains" [selected]="(filter.Operation === 'NotContains') ? 'selected' : ''">Not Contains</option>
                        <option *ngIf="filterExists('GreaterThan', filter)" value="GreaterThan" [selected]="(filter.Operation === 'GreaterThan') ? 'selected' : ''">Greater Than</option>
                        <option *ngIf="filterExists('GreaterThanOrEqual', filter)" value="GreaterThanOrEqual" [selected]="(filter.Operation === 'GreaterThanOrEqual') ? 'selected' : ''">Greater Than Or Equal</option>
                        <option *ngIf="filterExists('LessThan', filter)" value="LessThan" [selected]="(filter.Operation === 'LessThan') ? 'selected' : ''">Less Than</option>
                        <option *ngIf="filterExists('LessThanOrEqual', filter)" value="LessThanOrEqual" [selected]="(filter.Operation === 'LessThanOrEqual') ? 'selected' : ''">Less Than Or Equal</option>
                        <option *ngIf="filterExists('InList', filter)" value="InList" [selected]="(filter.Operation === 'InList') ? 'selected' : ''">In List (x, x, ...)</option>
                    </select>
                    <div class="value">
                        <input *ngIf="filter.ValueType === 'text'" [value]="filter.Value" (input)="addValue($event, filter)" />

                        <sdk-select *ngIf="filter.ValueType === 'list' && !filter.MultiSelect"
                            [options]="filter.ValueList"
                            optionStyle="border-radius: 4px;"
                            optionValuesStyle="max-height: 150px; overflow: hidden; overflow-y: auto;"
                            noValueLabel="[All values]"
                            [noValueDisabled]=false
                            [selectedOptions]="filter.Value"
                            (selectChangeEvent)="selectedValue($event, filter)">
                        </sdk-select>

                        <sdk-select *ngIf="filter.ValueType === 'list' && filter.MultiSelect"
                            [options]="filter.ValueList"
                            optionStyle="border-radius: 4px;"
                            optionValuesStyle="max-height: 150px; overflow: hidden; overflow: auto;"
                            noValueLabel="[All values]"
                            [noValueDisabled]=false
                            [multiSelect]=filter.MultiSelect
                            [multiValues]=false
                            [selectedOptions]="filter.Value"
                            (selectChangeEvent)="selectedValue($event, filter)">
                        </sdk-select>
                    </div>
                </div>
            </div>
            <div class="move" cdkDragHandle>
                <span class="sdk-icon">drag_handle</span>
            </div>
        </div>
    </div>
    <div class="footer">
        <div class="reset" (click)="reset()">Reset</div>
        <button class="button" type="button" (click)="apply()">Apply</button>
    </div>
</div>
