export class SDKDataGridOptions {
    settings?: boolean = false;
    columnSettings?: boolean = true;
    filtering?: boolean = true;
    sorting?: boolean = true;
    formulas?: boolean = false;
    charts?: boolean = true;
    export?: boolean = true;
    expandableRows?: boolean = false;
    selectableRows?: boolean = false;
    autoClosePanel?: boolean = false;
}
