import { Component, OnInit, OnDestroy, ComponentRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { MsalService, MsalBroadcastService } from '@azure/msal-angular';
import { AccountInfo, InteractionStatus } from '@azure/msal-browser';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';

import { Variables, VariableType } from '~/classes/core/user/variables';

import { GraphService } from '~/services/core/graph.service';
import { AppSettingsService } from '~/services/core/appsettings.service';
import { HttpService } from '~/services/core/http.service';

import { AppSettings } from '~/models/core/appsettings';
import { NavComponent } from './components/core/layout/nav/nav.component';

export interface Modal {
    type?: ComponentRef<any>;
    inputs?: any;
    outputs?: any
}

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
    @ViewChild('nav') nav!: NavComponent;
    private appSettings: AppSettings = new AppSettings();

    public error: string = "";
    public title: string = "";
    public user: string = "";
    public photo: string = "";
    public expand: boolean = false;
    public search: boolean = false;

    public layoutCssClass: string = "";

    public isLoggedIn: boolean = false;
    public isLoading: boolean = true;

    // Alert
    public showAlert: boolean = false;
    public alertTitle: string = "Alert";
    public alertMessage: string = "";
    public alertContinueText: string = "OK";
    public alertCancelText: string = "";

    // Modals
    public modal: Modal | undefined;

    private readonly _destroying$ = new Subject<void>();

    constructor(
        private authService: MsalService,
        private msalBroadcastService: MsalBroadcastService,
        private graphService: GraphService,
        private router: Router,
        private appSettingsService: AppSettingsService,
        private httpService: HttpService,
    ) {
        window.onbeforeunload = null;
        sessionStorage.removeItem("__allowNavigate__");

        setTimeout(() => {
            this.layoutCssClass = Variables.get("Nav.LayoutCssClass", VariableType.Local) ?? "";
            this.setLayoutClass(false);
        }, 1500);
    }

    //*************************************************************************
    //  Component Life-Cycle Methods
    //*************************************************************************
    public async ngOnInit() {
        await this.appSettingsService.getSettings(true).then(async (appSettings: AppSettings) => {
            if (appSettings) {
                this.appSettings = appSettings;
                this.title = this.appSettings.title;

                this.getMSALAccounts();
            } else {
                this.error = "Unable to load application settings.";
                this.isLoading = false;
            }
        });
    }

    public ngOnDestroy(): void {
        this._destroying$.next(undefined);
        this._destroying$.complete();
    }

    //*************************************************************************
    //  Public Methods
    //*************************************************************************
    public setLayoutClass(change: boolean = true) {
        if (change) {
            if (this.layoutCssClass !== "") {
                this.layoutCssClass = "";
            } else {
                this.layoutCssClass = "expand";
            }
        }

        if (this.layoutCssClass === "") {
            this.expand = false;
        } else {
            setTimeout(() => {
                this.expand = true;
            }, 100);
        }

        Variables.set("Nav.LayoutCssClass", this.layoutCssClass, VariableType.Local);
    }

    public async reloadPage(page = "") {
        let currentUrl = page === "" ? this.router.url : page;

        this.router.navigate([currentUrl]);
    }

    public showSearch(flag: boolean) {
      this.search = flag;
    }

    public handleCollapse(): void {
      this.nav.toggleSearch();
    }

    //*************************************************************************
    //  Alert Methods
    //*************************************************************************
    public displayAlert(title: string, message: string) {
        this.alertTitle = title;
        this.alertMessage = message;
        this.showAlert = true;
    }

    public alertContinue() {
        this.showAlert = false;
    }

    public alertCancel() {
        this.showAlert = false;
    }

    //*************************************************************************
    //  Private Methods
    //*************************************************************************
    private getMSALAccounts() {
      this.msalBroadcastService.inProgress$
          .pipe(
              filter((status: InteractionStatus) => status === InteractionStatus.None),
              takeUntil(this._destroying$)
          )
          .subscribe(() => {
              let account: AccountInfo | null = this.authService.instance.getActiveAccount();
  
              if (account === null) {
                  account = this.getAccountInfo();
              }
  
              this.setAccountInfo(account).catch(err => {
                  console.error('Error setting account info:', err);
              });
          });
    }
  

    private getAccountInfo() {
        let accounts = this.authService.instance.getAllAccounts();
        let account: AccountInfo | null = null;

        if (accounts.length > 0) {
            this.authService.instance.setActiveAccount(accounts[0]);
            account = accounts[0];
        }

        return account;
    }

    private async setAccountInfo(account: AccountInfo | null) {
        this.appSettings.user = account;

        let allowAccess: boolean = this.appSettings.openAccess;

        let roles = await this.httpService.GetAsset("roles.json");

        if (account?.idTokenClaims?.roles) {
            this.appSettings.roles = account.idTokenClaims.roles;

            allowAccess = account.idTokenClaims.roles.filter((role: any) => roles.includes(role)).length >= 1 ? true : this.appSettings.openAccess;
        }

        if (allowAccess && account) {
            this.user = account.name!;
            this.photo = await this.graphService.getUserPhoto();

            this.layoutCssClass = "";
            this.isLoggedIn = true;

            await this.appSettingsService.saveSettings(this.appSettings);
        } else {
            this.layoutCssClass = "noaccess";
            this.isLoggedIn = false;
        }

        this.isLoading = false;
    }
}
