import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { SDKDataGridSettings } from "sdk-datagrid";
import { UserSettingsService } from "../user-settings/user-settings.service";
import { SettingsGrid } from "./models/settings-grid.model";

@Injectable()
export class GridHandlerService {

    constructor(
        private userSettingsService: UserSettingsService,
        private httpClient: HttpClient    ) {
    }

    /**
     * To create custom view, look for the
     * save view event and log it as JSON.stringify.
     * Use that JSON and create a JSON file in
     * '/assets/datagrids/{your-file-name}.json'.
     * @param grid 
     * @param builtInSettingsPaths This is a path starting from the 'assets' folder.
     * For example to reference a json file 'my-file.json' inside assets, use
     * '/assets/my-file.json'.
     */
    public loadSettings(grid: SettingsGrid, builtInSettingsPaths: string[] = []) {

        if (builtInSettingsPaths && builtInSettingsPaths !== null && builtInSettingsPaths.length > 0) {
            this.loadBuiltInSettings(grid, builtInSettingsPaths);
        }

        this.userSettingsService.GetSettings(grid.uniqueIdentifier).subscribe({
            next: (settings) => {
                const userSavedSettings = settings.length == 0 ? [] : JSON.parse(settings[0].data);
                const allSettings = grid.builtInSettings && grid.builtInSettings != null ? [...userSavedSettings, ...grid.builtInSettings] : userSavedSettings;
                this.refreshFilterValues(grid, allSettings);

                grid.allSettings = allSettings;
            },
            error: (error) => {
                if (grid.errorHanlder && grid.errorHanlder != null) {
                    grid.errorHanlder("Error loading user settings", error);
                }
            }
        });
    }

    public saveSettings(grid: SettingsGrid, event: SDKDataGridSettings[]) {
        const userSavedSettings = grid.builtInSettings && grid.builtInSettings != null ? event.filter(setting => grid.builtInSettings?.find(builtInSetting => setting.Name === builtInSetting.Name) === undefined) : event;
        this.userSettingsService.SaveSetting({
            name: grid.uniqueIdentifier,
            data: JSON.stringify(userSavedSettings)
        }).subscribe({
            error: (error) => {
                if (grid.errorHanlder && grid.errorHanlder != null) {
                    grid.errorHanlder("Error saving user settings", error);
                }
            }
        });
    }

    private loadBuiltInSettings(grid: SettingsGrid, filePaths: string[]) {
        if (!grid.builtInSettings || grid.builtInSettings === null) {
            grid.builtInSettings = [];
        }

        filePaths.forEach((path) => {
            this.httpClient.get(path).subscribe({
                next: (item) => {
                    grid.builtInSettings?.push(item as SDKDataGridSettings);
                },
                error: (error) => {
                    if (grid.errorHanlder && grid.errorHanlder != null) {
                        grid.errorHanlder("Error loading built in settings", error);
                    }
                }
            });
        });
    }

    private refreshFilterValues(grid: SettingsGrid, settings: SDKDataGridSettings[]) {
        if (settings && grid.multiSelectFilters && grid.multiSelectFilters.length > 0) {
            settings.forEach(setting => {
                this.refreshSettingColumns(grid, setting);

                this.refreshSettingFilters(grid, setting);
            });
        }
    }

    private refreshSettingColumns(grid: SettingsGrid, setting: SDKDataGridSettings) {
        setting.Data?.columns?.forEach(column => {
            if (column.FilterMultiSelect) {
                const filterInfo = grid.multiSelectFilters?.find(f => f.fieldName === column.Name);
                if (filterInfo) {
                    column.FilterValues = filterInfo.filterValues;
                }
            }
        });
    }

    private refreshSettingFilters(grid: SettingsGrid, setting: SDKDataGridSettings) {
        setting.Data?.filters?.forEach(filter => {
            if (filter.MultiSelect) {
                const filterInfo = grid.multiSelectFilters?.find(f => f.fieldName === filter.Name);
                if (filterInfo) {
                    filter.ValueList = filterInfo.filterValues;
                }
            }
        });
    }
}