import Chart, { BarElement, ChartMeta, Point } from "chart.js/auto";
import chroma from "chroma-js";
import ChartDataLabels, { Context } from 'chartjs-plugin-datalabels';
import zoomPlugin from 'chartjs-plugin-zoom';



export class RetiredChartUtility {

    private brandColorMap: Map<number, string> = new Map([
        [1, '#0B2D71'],
        [2, '#0066B2'],
        [3, '#009DD9'],
        [4, '#BA3093'],
    ]);
    public fontSize: number = 10;
    public defaultBarWidth: number = 124.0;

    constructor() {
        Chart.register(zoomPlugin);
        Chart.register(ChartDataLabels);
    }

    public getColor(domain: number, range: number[]): string {
        const brandScale = chroma.scale(Array.from(this.brandColorMap.values())).domain(range);
        return brandScale(domain).hex();
    }

    /**
     * @remarks Creates an instance of chartJS bar chart with stacked y and x axis for the given canvas DOM id.
     * 
     * ***/
    public createChart(yAxisTitle: string, xAxisTitle: string, chartTitle: string, canvasId: string,
    stackx: boolean = true, stacky: boolean = true): Chart {

        return new Chart(canvasId, {
            type: 'bar',
            options: {
                responsive: true,
                maintainAspectRatio: false,
                scales: {
                    y: {
                        ticks: {
                            callback: function (value, _index, _values) {
                                return value.toLocaleString('en-US');
                            }
                        },
                        bounds: 'ticks',
                        title: {
                            text: yAxisTitle,
                            display: true,
                            font: {
                                weight: 'bold',
                                family: "GothamNarrowBold, Arial, sans- serif",
                                size: 14
                            },
                            color: '#212529'
                        },
                        stacked: stacky
                    },
                    x: {
                        bounds: 'data',
                        stacked: stacky,
                        title: {
                            text: xAxisTitle,
                            display: true,
                            font: {
                                weight: 'bold',
                                family: "GothamNarrowBold, Arial, sans- serif",
                                size: 14
                            },
                            color: '#212529'
                        }
                    }
                },
                interaction: {
                    mode: 'point'
                },
                plugins: {
                    legend: {
                        position: 'bottom',
                        display: true
                    },
                    title: {
                        display: true,
                        text: chartTitle,
                        font: {
                            weight: 'bold',
                            family: "GothamNarrowBold, Arial, sans- serif",
                            size: 14
                        },
                        color: '#212529'
                    },
                    tooltip: {
                        callbacks: {
                            label: (context): string => {
                                return context.formattedValue;
                            },
                            title: (context): string => {
                                return context[0].dataset.label!;
                            }
                        }
                    },
                    datalabels: {
                     
                        font:  (context) => {
                            const chart = context.chart;
                            const meta = chart.getDatasetMeta(context.datasetIndex);
                            const model: BarElement = meta.data[context.dataIndex] as BarElement;
                            
                            // passing true you can get the final value of element property
                            // at the end of animation
                            const { width } = model.getProps(['width'], true);
                            const size = Math.round(this.fontSize * (width / this.defaultBarWidth));
                           
                            
                            return {
                                size: size,
                                family: "Arial, sans- serif"
                            }
                        }
                    }
                },
            },
            data: {
                datasets: []
            }
        });
    }

    public checkHeight(context: Context, fontSize:number, defaultBarWidth: number): boolean {
        const chart = context.chart;
        const currentBar: ChartMeta = chart.getDatasetMeta(context.datasetIndex);
        const segment: BarElement = currentBar.data[context.dataIndex] as BarElement;

        // passing true you can get the final value of element property
        // at the end of animation
        const { width, height } = segment.getProps(['width', 'height'], true);
        const size = Math.round(fontSize * (width / defaultBarWidth));
        const credit: Point = context.dataset.data[context.dataIndex] as Point;

        const canvasContext = chart.canvas.getContext('2d');
        const oldFont = canvasContext!.font;
        canvasContext!.font = `${size} Arial, sans- serif`;
        const textSize = canvasContext!.measureText(credit.y.toLocaleString());
        let actualHeight = textSize.actualBoundingBoxAscent + textSize.actualBoundingBoxDescent;
        canvasContext!.font = oldFont;

        if (height <= actualHeight) {
            return false;
        }

        return true;
        
    }

    public NotVisible(context: Context): boolean{
        const chart = context.chart;
        const credit: Point = context.dataset.data[context.dataIndex] as Point;
        const min = Math.min(...chart.scales['x'].ticks.map(t => +(t.label ?? '0')));
        const max = Math.max(...chart.scales['x'].ticks.map(t => +(t.label ?? '0')));
        return (credit.x < min || credit.x > max);
    }
   
}
