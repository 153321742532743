import { Component, Input, OnDestroy } from "@angular/core";
import { Chart, ChartDataset, Point } from "chart.js/auto";
import { RetiredChartUtility } from "~/pages/metaregistry/retirements/retired-charts-util";
import { BaseComponent } from "~/pages/base.component";
import { IRetiredCreditInfo, IRetirementCredit, IRetirementCreditFilter } from "~/services/shared/retirements/metaregistry-retired-credits.model";
import { RetiredCreditsService } from "~/services/shared/retirements/metaregistry-retired-credits.service";
import { Subject, debounceTime } from "rxjs";
import { ExcelExportService } from "~/services/shared/export-excel/export-excel-service";

@Component({
    selector: 'ccms-retired-chart-yearly',
    templateUrl: './retired-chart-yearly.component.html',
    styleUrls: ['./retired-chart-yearly.component.scss']
})
export class RetiredChartYearlyComponent extends BaseComponent implements OnDestroy {
    @Input() id!: string;
    @Input() chartTitle: string = '';
    @Input() xaxisTitle!: string;
    @Input() yaxisTitle!: string;

    private _util: RetiredChartUtility = new RetiredChartUtility();
    private _chart!: Chart ;

    public start!: Date;
    public end!: Date;
    private filters: IRetirementCreditFilter[] = [];
    private _data!: any;

    private filterChartDebounce: Subject<void> = new Subject<void>();

    constructor(private readonly retiredCreditsService: RetiredCreditsService,
        private readonly exportService: ExcelExportService) {
        super();
    }

    public async ngOnInit(): Promise<void> {
      await super.ngOnInit();
      
      this.filterChartDebounce.pipe(
        debounceTime(200)
      ).subscribe(() => {
        this.filterChart();
      });
  
      this.loadChart();
    }

    ngOnDestroy(): void {
      if (this._chart) {
        this._chart.destroy();
      }

      this.filterChartDebounce.unsubscribe();
    }

    onStartDateChanged(date: Date) {
      this.start = date;
      this.filterChartDebounce.next();
    }
    
    onEndDateChanged(date: Date) {
      this.end = date;
      this.filterChartDebounce.next();
    }    

    onFiltersChanged(filters: IRetirementCreditFilter[]) {
      this.filters = filters;
      this.filterChartDebounce.next();
    }

    onExportFired(event: any) {
        if (this._data?.credits?.length > 0) {
            const properties = Object.keys(this._data.credits[0]);
            
            this.exportService.export(this._data.credits, 'retired-year-quarter', properties);
        }
    }

    private loadChart(): void {
        this.createChart();
        this.retiredCreditsService.listRetiredCredits(this.start, this.end)
            .subscribe({
                next: (retirementInfo: IRetirementCredit) => {
                    this.plotCreditsByYear(retirementInfo.credits, this._chart);
                    this.isLoading = false;
                    this._data = retirementInfo;
                }
            });
    }

    private createChart() {
      if (this._chart) {
        this._chart.destroy();
      }
      this._chart = this._util.createChart(this.yaxisTitle, this.xaxisTitle, this.chartTitle, this.id);
    }

    private plotCreditsByYear(retiredCredits: IRetiredCreditInfo[], chart: Chart) {
        const years = [... new Set(retiredCredits.map(element => element.year))]
            .sort((a, b) => a - b);
        const dataSet: ChartDataset[] = this.mapYearQuarterData(retiredCredits, years);
        chart.data.labels = years;
        chart.data.datasets = dataSet;
        chart.update();
    }

    private mapYearQuarterData(retiredCredits: IRetiredCreditInfo[], years: number[]): ChartDataset[] {
        const dataSet: ChartDataset[] = [];

        [1, 2, 3, 4].forEach(quarter => {
            let source = retiredCredits.filter(element => element.quarter == quarter)
                .map(element => ({ x: element.year, y: element.retired }));
            //for each year, if we are missing a quarter, create one with zero value
            years.forEach(year => {
                if (source.find(element => element.x == year) == undefined) {
                    source.push({ x: year, y: 0 });
                }
            });
            dataSet.push({
                label: `Q${quarter}`,
                backgroundColor: this._util.getColor(quarter, [1, 4]),
                data: [...source].sort((a, b) => a.x - b.x),
                order: quarter,
                datalabels: {
                    display: (context) => {
                        const credit: Point = context.dataset.data[context.dataIndex] as Point;
                        const last: Point = context.dataset.data[context.dataset.data.length - 1] as Point;

                        if (this._util.NotVisible(context)) {
                            return false;
                        }
                        //if point is the last segment in the bar, ignore height and return true
                        if (credit.x == last.x && credit.y == last.y) {
                            return true;
                        }

                        return this._util.checkHeight(context, this._util.fontSize, this._util.defaultBarWidth);
                    },
                    labels: {
                        totals: {
                            color: 'black',
                            formatter: function (value, context) {
                                let year = value.x;
                                let total = retiredCredits.filter(element => element.year == year).reduce((sum, current) => sum + current.retired, 0);


                                return quarter == 4 ? total.toLocaleString() : null;
                            },
                            align: 'end',
                            anchor: 'end',
                            font: {
                                weight: "bold",
                                family: "GothamNarrowBold, Arial, sans- serif"
                            }
                        },
                        value: {
                            color: 'white',
                            align: 'center',
                            anchor: 'center',
                            formatter: function (value, context) {
                                return value.y > 0 ? value.y.toLocaleString() : null;
                            }
                        }
                    }
                }
            });
        });
        return dataSet;
    }

    public filterChart() {
        this.isLoading = true;
        this.createChart();
        this.retiredCreditsService.filterRetiredCredits(this.start, this.end, this.filters)
            .subscribe(response => {
                this.plotCreditsByYear(response.credits, this._chart);
                this.isLoading = false;
                this._data = response;
            });
    }
}
