import { Component, Input } from '@angular/core';
import { IBeZeroRatingDetails } from '~/models/shared/common';

import { HttpService } from '~/services/core/http.service';

@Component({
    selector: 'metaregistry-detail-ratings',
    templateUrl: './metaregistry-detail-ratings.component.html',
    styleUrls: ['./metaregistry-detail-ratings.component.scss']
})

export class MetaRegistryDetailRatingsComponent {
    @Input() projectId: any;

    public isLoading: boolean = true;
    public project: any;
    public hoverText: any;

    constructor(
        private httpService: HttpService
    ) { }

    //******************************************************************************
    //  Page Life-cycle Methods
    //******************************************************************************
    public async ngOnInit() {
        if (this.projectId) {
            await this.loadData();
            await this.loadHoverData();
        }

        this.isLoading = false;
    }

    //******************************************************************************
    //  Public Methods
    //******************************************************************************
    public async loadData() {
        try {
            await this.httpService.Get(`/MetaRegistry/${this.projectId}?includeCountryRisk=true`).then((data: any) => {
                this.project = data;
            });
        } catch (error: any) {
        }
    }

    public async loadHoverData() {
        try {
            await this.httpService.GetAsset("ratings/data.json").then((data: any) => {
                this.hoverText = data;
            });
        } catch (error: any) {
        }
    }

    public getLabelHoverText(source: string, label: string) {
        let text: any = "";
        let sourceNdx = this.hoverText.sources.findIndex((o: any) => o.source === source);

        if (sourceNdx > -1) {
            let labelNdx = this.hoverText.sources[sourceNdx].labels.findIndex((o: any) => o.hasOwnProperty(label));

            if (labelNdx > -1) {
                text = this.hoverText.sources[sourceNdx].labels[labelNdx][label];
            }
        }

        return text;
    }

    public getRatingHoverText(source: string, rating: string, value?: any) {
        if (!value || value === "") return "";
        if (source === 'BeZeroRisk') {
          value = this.getRatingNumberFromBeZero(value);
          source = 'BeZero';
        }

        let text: any = "";
        let sourceNdx = this.hoverText.sources.findIndex((o: any) => o.source === source);

        if (sourceNdx > -1) {
            let ratingNdx = this.hoverText.sources[sourceNdx].ratings.findIndex((o: any) => o.hasOwnProperty(rating));

            if (ratingNdx > -1) {
                let obj: any = this.hoverText.sources[sourceNdx].ratings[ratingNdx];

                text = Object.entries(obj[rating]).filter(([k, v]) => {
                    return (k.toString() === value.toString()) ? v : null;
                })[0];
            }
        }

        return text ? text[1]: "";
    }

    public convertRating(value: any) {
        if (value == null || value == undefined) return value;

        switch (value.toString()) {
            case "5":
                return "High";

            case "4":
                return "Medium - High";

            case "3":
                return "Medium";

            case "2":
                return "Low - Medium";

            case "1":
                return "Low";

            case "0":
                return "-";
        }

        return value;
    }

    public convertSylveraCarbonScore(value: number) {
        let percentage = value * 100;

        if (percentage === 0) {
            return this.convertRating(0);
        }
        if (percentage <= 30) {
            return this.convertRating(1);
        }
        if (percentage <= 50) {
            return this.convertRating(2);
        }
        if (percentage <= 80) {
            return this.convertRating(3);
        }
        if (percentage <= 95) {
            return this.convertRating(4);
        }
        if (percentage > 95) {
            return this.convertRating(5);
        }

        return "-";
    }

    public convertBezeroRating(rating: IBeZeroRatingDetails, key: string) {
        const factor = rating.riskFactors.find(riskFactor => riskFactor.name === key);

        let value = factor?.score;
        if (value) {
          let newScale = this.getRatingNumberFromBeZero(value);
          return this.convertRating(this.flipRating(newScale));
        }
       
        return "-";
    }

    getRatingNumberFromBeZero(value: number): number {
      if (value === 0) {
        return 0;
      }
      if (value === .3) {
        return 1;
      }
      if (value === .5) {
        return 2;
      }
      if (value === .7) {
        return 3;
      }
      if (value === .9) {
        return 4;
      }
      if (value === 1) {
        return 5;
      }
     
      return 0; 
    }

    flipRating(value: number) {
      if (value === 0) {
        return 0;
      }
      if (value === 5) {
        return 1;
      }
      if (value === 4) {
        return 2;
      }
      if (value === 3) {
        return 3;
      }
      if (value === 2) {
        return 4;
      }
      if (value === 1) {
        return 5;
      }

      return 0;
    }

    public getBezeroRating(rating: IBeZeroRatingDetails, key: string) {
      if (rating && rating.riskFactors) {
        const factor = rating.riskFactors.find(riskFactor => riskFactor.name === key);

        if (factor?.score === null || factor?.score === undefined || factor?.score === 0)
        {
          return '';
        }

        return factor?.score;
      }

      return '';
    }
}
