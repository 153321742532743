import {
    Component, 
    ElementRef, 
    EventEmitter, 
    Input, 
    Output, 
    SimpleChanges, 
    ViewChild 
} from "@angular/core";
import { 
    LngLatBoundsLike, 
    Map, 
    MapLayerMouseEvent, 
    Marker, 
    Popup
} from 'maplibre-gl';
import * as turf from '@turf/turf';
import { GeoCoordinates } from "./geo-coordinate";
import { PlaceInfo } from "~/models/shared/maps/placeInfo.model";
import { MapsService } from "~/services/shared/maps/maps.service";
import { StringUtility } from "~/classes/shared/string-utility";
import { ColorGenerator } from "~/classes/shared/color-generator";
import { AlertType } from "~/models/shared/errors/error-alert-type.enum";

@Component({
    selector: 'ccms-map-viewer',
    templateUrl: './map-viewer.component.html',
    styleUrls: ['./map-viewer.component.scss']
})
export class MapViewerComponent {
    private static readonly BOUNDARY_PADDING = 100;
    private static readonly INITIAL_ZOOM = 3;

    /** Use to randomized shapefile layer line/point color */
    private readonly _colorGenerator = new ColorGenerator({
        saturation: 100
    });

    /** Internal MapDataSource collection. */
    private _dataSources: MapDataSource[] = [];
    /** Timeout to debounce onDataSourcesChanged handler  */
    private _onDataSourcesChangedTimer: NodeJS.Timeout | null = null;
    /** Tracks map event binding in order to unbind when the layer is removed. */
    private _eventListeners: Record<string, Record<string, any>> = {
        'mouseenter': {},
        'mouseleave': {}
    };
    private _map: Map | undefined;
    private _mapDrawn = false;
    /** Tooltip popup */
    private _popup: Popup;

    @ViewChild('map')
    private readonly _container!: ElementRef<HTMLElement>;

    @Input() dataSources: MapDataSource[] = [];
    @Input() location!: GeoCoordinates | null;
    @Input() place!: string | null;

    @Output() mapDrawn = new EventEmitter();

    public alertMessage: string | null = null;
    public alertType: AlertType = AlertType.SUCCESS;
    
    public readonly mapTiles = [
        { id: 'SpatialOnDemand:bing_hybrid_layer', name: 'Bing Hybrid Layer' },
        { id: 'SpatialOnDemand:Maxar_Global_Mosaic', name: 'Maxar Global Mosaic' },
        { id: 'SpatialOnDemand:Maxar_Metro', name: 'Maxar Highest Resolution' },
        { id: 'SpatialOnDemand:Maxar_Vivid_Standard', name: 'Maxar Vivid Standard' },
        { id: 'SpatialOnDemand:Sentinel_2_True_Color', name: "Sentinel 2" },
        { id: 'SpatialOnDemand:Maxar_Historical_2020', name: 'Maxar Historical 2020' },
        { id: 'SpatialOnDemand:Maxar_Historical_2021', name: 'Maxar Historical 2021' }
    ];

    public selectMapTiles(layerId: string) {
        if (!this._map) {
            console.error("Map object is not initialized.");
            return;
        }

        const map = this._map;
        this.mapTiles.forEach(layer => {
            map.setLayoutProperty(layer.id, 'visibility', layer.id === layerId ? 'visible' : 'none');
        });
    }

    //#region Life Cycle

    constructor(
        private readonly _mapService: MapsService
    ) {
        this._popup = new Popup({
            closeButton: false,
            closeOnClick: false
        });
    }
    
    public ngAfterViewInit() {
        this.initializeMap();
    }

    public ngOnChanges(event: SimpleChanges) {
        if (event.dataSources) {
            this.onDataSourcesChanged();
        }
    }

    //#endregion

    //#region Public

    public addDataSource(datasource: MapDataSource): number {
        if (this.dataSources.includes(datasource)) {
            return -1;
        }
        this.dataSources.push(datasource);
        this.onDataSourcesChanged();
        return this.dataSources.length;
    }

    public removeDataSource(idx: number) {
        if (idx < 0 || idx >= this.dataSources.length) {
            console.error("Index out of bounds.");
            return;
        }
        this.dataSources.splice(idx, 1);
        this.onDataSourcesChanged();
    }

    //#endregion

    //#region Internals

    private addMaxarLayer(layerId: string) {
        if (!this._map) {
            console.error("Map object is not initialized.");
            return;
        }

        this._map.addLayer({
          id: layerId,
          type: 'raster',
          source: {
            type: 'raster',
            tiles: [ MapViewerComponent.getMaxarDatasource(layerId) ],
            tileSize: 256
          },
          minzoom: 0,
          maxzoom: 22,
          layout: {
            visibility: 'none'
          }
        });
    }

    private addPointLayer(id: string, color: string) {
        if (!this._map) {
            console.warn("Map object is not initialized.");
            return;
        }

        const map = this._map;
        const actualLayerId = `${id}-point`;
        map.addLayer({
            id: actualLayerId,
            type: 'circle',
            source: id,
            filter: ['==', ['geometry-type'], 'Point'],  // Filter to include only Point features
            paint: {
                'circle-radius': 6,
                'circle-color': color
            }
        });
        const layerOnMouseEnter = (e: MapLayerMouseEvent) => {
            this.onMouseEnter(id, e);
        };
        const layerOnMouseLeave = () => {
            this.onMouseLeave();
        };
        this._eventListeners['mouseenter'][actualLayerId] = layerOnMouseEnter;
        this._eventListeners['mouseleave'][actualLayerId] = layerOnMouseLeave;
        map.on('mouseenter', actualLayerId, layerOnMouseEnter);
        map.on('mouseleave', actualLayerId, layerOnMouseLeave);
    }

    private addPolygonLayer(id: string, color: string) {
        if (!this._map) {
            console.warn("Map object is not initialized.");
            return;
        }

        const map = this._map;
        const actualLayerId = `${id}-polygon`;
        map.addLayer({
            id: actualLayerId,
            type: 'line',
            source: id,
            paint: {
                'line-color': color,
                'line-width': 4,
                'line-opacity': 1
            }
        });
        const layerOnMouseEnter = (e: MapLayerMouseEvent) => {
            this.onMouseEnter(id, e);
        };
        const layerOnMouseLeave = () => {
            this.onMouseLeave();
        }
        this._eventListeners['mouseenter'][actualLayerId] = layerOnMouseEnter;
        this._eventListeners['mouseleave'][actualLayerId] = layerOnMouseLeave;
        map.on('mouseenter', actualLayerId, layerOnMouseEnter);
        map.on('mouseleave', actualLayerId, layerOnMouseLeave);
    }

    private drawGeoJsonLayer(id: string, data: any, color: string | null = null): Promise<void> {
      return new Promise((resolve, reject) => {
          if (!id || !data) {
              reject(new Error("Invalid parameters."));
              return;
          }
          if (!this._map) {
              reject(new Error("Map object is not initialized."));
              return;
          }
  
          const map = this._map;
          map.addSource(id, {
              type: 'geojson',
              data
          });
          const layerColor = color ?? this._colorGenerator.getNextColor();
          this.addPolygonLayer(id, layerColor);
          this.addPointLayer(id, layerColor);
  
          // Listen for the 'idle' event on the map
          map.once('idle', () => {
              resolve();
          });
      });
    }
  
    private drawShapefile(dataSource: MapDataSource): Promise<void> {
      return new Promise((resolve, reject) => {
          const id = dataSource.id;
          if (!('color' in dataSource)) {
              // Annotate dataSource with color so that it's consistent on toggle
              dataSource['color'] = this._colorGenerator.getNextColor();
          }
          const color = dataSource['color'];
          if (dataSource.type === 'geojson') {
              this.drawGeoJsonLayer(id, dataSource.data, color).then(resolve).catch(reject);
          } else {
              reject(new Error("Unsupported data source type: " + dataSource.type));
          }
      });
    }

    private async drawMapAsync() {
        await this.drawMapTilesAsync();
        this.onMapDrawnAsync();
    }

    private drawMapTilesAsync(): Promise<void> {
        if (!this._map) {
            console.error("Map object is not initialized.");
            return Promise.resolve();
        }

        const map = this._map;
        return new Promise((resolve) => {
            this.mapTiles.forEach(layer => {
                this.addMaxarLayer(layer.id);
            });
            const mapTilesRendered = () => {
                map.off("render", mapTilesRendered);
                this.selectMapTiles(this.mapTiles[0].id);
                resolve();
            };
            map.on("render", mapTilesRendered);
        });
    }

    private static getMaxarDatasource(layerId: string) {
        return `https://spatialondemand.maxar.com/wmts/93fdfac5-3e2c-4326-b7ff-f62d964a5f19/sod_wmts/ows?SERVICE=WMTS&REQUEST=GetTile&VERSION=1.0.0&LAYER=${layerId}&STYLE=default&FORMAT=image/png&TILEMATRIXSET=GLOBAL_WEBMERCATOR&TILEMATRIX={z}&TILEROW={y}&TILECOL={x}`;
    }

    private initializeMap(): void {
        const map = this._map = new Map({
            container: this._container.nativeElement,
            style: { // map style specification
                "version": 8,
                "sources": {},
                "layers": []
            },
            zoom: MapViewerComponent.INITIAL_ZOOM
        });
        map.on('load', () => {
            this.drawMapAsync();
        });
    }

    private onDataSourcesChanged() {
        /** Uses debounce pattern */
        if (this._onDataSourcesChangedTimer) {
            clearTimeout(this._onDataSourcesChangedTimer);
        }
        
        this._onDataSourcesChangedTimer = setTimeout(() => {
          this.handleDataSourcesChange();
        }, 300);
    }

    private async handleDataSourcesChange() {
      if (!this._mapDrawn) {
        // trigger it again until the map is drawn.
        this.onDataSourcesChanged();
        return;
      }
      const dataSourcesToRemove = this._dataSources.filter(ds => !this.dataSources.includes(ds));
      const dataSourcesToAdd = this.dataSources.filter(ds => !this._dataSources.includes(ds));
      dataSourcesToRemove.forEach(ds => {
        const idx = this._dataSources.indexOf(ds);
        if (idx === -1) {
          return;
        }
        this.removeShapefileLayer(ds);
        this._dataSources.splice(idx, 1);
      });
      dataSourcesToAdd.forEach(ds => {
        this._dataSources.push(ds);
        const idx = this._dataSources.length;
        // If datasource name is null/empty use collection index.
        if (StringUtility.IsNullOrWhiteSpace(ds.id)) {
          ds.id = idx.toString();
        }
        this.drawShapefile(ds);
      });
    
      if (this._map) {
        this._map.once('idle', () => {
          console.log('Map has finished redrawing');
          this.setMapBoundaryAsync();
        });
      }
      this._onDataSourcesChangedTimer = null;
    }
    
    private async onMapDrawnAsync() {
        if (!this._map) {
            console.warn("Map object is not initialized.");
            return;
        }

        await this.setMapBoundaryAsync();
        this._mapDrawn = true;
        this.mapDrawn.emit();
    }

    private onMouseEnter(id: string, e: MapLayerMouseEvent) {
        if (!this._map || !e.features || e.features.length === 0) {
            return;
        }
        const map = this._map;
        map.getCanvas().style.cursor = 'pointer';
        // Create and populate the popup
        const feature = e.features[0];
        const properties = feature.properties;
        let description = Object.entries(properties)
            .map(([key, value]) => `<strong>${key}</strong>: ${value}`)
            .join('<br>');
        const layerId = `<strong>${id}</strong>`;
        // Set the location and content of the popup
        if (StringUtility.IsNullOrWhiteSpace(description)) {
            description = layerId;
        }
        else {
            description = layerId + "<br>" + description;
        }
        this._popup.setLngLat(e.lngLat)
            .setHTML(description)
            .addTo(map);
    }

    private onMouseLeave() {
        if (!this._map) {
            return;
        }

        this._map.getCanvas().style.cursor = '';
        this._popup.remove();
    }

    private removePointLayer(id: string) {
        if (!this._map) {
            console.warn("Map object is not initialized.");
            return;
        }

        const map = this._map;
        const actualLayerId = `${id}-point`;
        map.removeLayer(actualLayerId);
        const mouseEnterEventListener = this._eventListeners['mouseenter'][actualLayerId];
        const mouseLeaveEventListener = this._eventListeners['mouseleave'][actualLayerId];
        map.off('mouseenter', actualLayerId, mouseEnterEventListener);
        map.off('mouseleave', actualLayerId, mouseLeaveEventListener);
    }

    private removePolygonLayer(id: string) {
        if (!this._map) {
            console.warn("Map object is not initialized.");
            return;
        }

        const map = this._map;
        const actualLayerId = `${id}-polygon`;
        map.removeLayer(actualLayerId);
        const mouseEnterEventListener = this._eventListeners['mouseenter'][actualLayerId];
        const mouseLeaveEventListener = this._eventListeners['mouseleave'][actualLayerId];
        map.off('mouseenter', actualLayerId, mouseEnterEventListener);
        map.off('mouseleave', actualLayerId, mouseLeaveEventListener);
    }

    private removeShapefileLayer(dataSource: MapDataSource) {
      if (!this._map) {
            console.error("Map object is not initialized.");
            return;
      }
        
      try {
        this.alertMessage = null;

        const id = dataSource.id;
        this.removePointLayer(id);
        this.removePolygonLayer(id);
        this._map.removeSource(id);

        // Listen for the 'idle' event
        this._map.once('idle', () => {
          console.log('Map has finished redrawing');
          this.setMapBoundaryAsync();
        });
      } catch (error) {
        console.error("Error removing shapefile layer: ", error);
      }
    }

    private resolveLocation(location: string): Promise<PlaceInfo[] | null> {
        return this._mapService.searchPlace(location);
    }

    private async setMapBoundaryAsync() {
      try {
        console.log("Setting map boundary.");
        if (this._dataSources?.length > 0) {
            await this.setDataSourcesMapBoundaryAsync();
        }
        else if (this.location || this.place) {
            await this.setLocationMapBoundaryAsync();
        }
        else {
            this.setDefaultMapBoundary();
        }
        
      } catch (error) { 
          const errorMessage = "Error setting map boundary: " + error;
          this.alertType = AlertType.WARNING;
          this.alertMessage = errorMessage;
        }
    }

    private setDataSourcesMapBoundaryAsync(): Promise<void> {
        if (!this._map) {
            console.warn("Map object is not initialized.");
            return Promise.resolve();
        }
        const map = this._map;
        // Not sure what the lower/upper bounds are, so using arbitrary value of 999.
        // Because values can be both negative and positive, set initial value on the
        // opposite end of the number line. So for min, set initial value on the positive
        // side and max set initial value on the negative side.
        let minX = 999;
        let minY = 999;
        let maxX = -999;
        let maxY = -999;
        // Calculate the boundary for each datasource and take the overall min/max values.
        this._dataSources.forEach(ds => {
            const bbox = turf.bbox(ds.data);
            minX = Math.min(minX, bbox[0]);
            minY = Math.min(minY, bbox[1]);
            maxX = Math.max(maxX, bbox[2]);
            maxY = Math.max(maxY, bbox[3]);
        });
        map.fitBounds([minX, minY, maxX, maxY], { padding: MapViewerComponent.BOUNDARY_PADDING });
        return new Promise((resolve) => {
            const onMoveEnd = () => {
                map.off('moveend', onMoveEnd);
                resolve();
            };
            map.on('moveend', onMoveEnd);
        })
    }

    private setDefaultMapBoundary() {
        if (!this._map) {
            console.warn("Map object is not initialized.");
            return;
        }
        this._map.setCenter([0, 0]);
    }

    private async setLocationMapBoundaryAsync() {
        if (!this._map) {
            console.warn("Map object is not initialized.");
            return;
        }
        if (!this.location && !this.place) {
            return;
        }
        const map = this._map;
        const location: GeoCoordinates | null = this.location;
        if (location) {
            const geoCoord = location;
            if (!this._mapDrawn) {
                const marker = new Marker();
                marker.setLngLat([geoCoord.longitude, geoCoord.latitude]);
                marker.addTo(map);
            }
            map.setCenter([geoCoord.longitude, geoCoord.latitude]);
        }

        if (this.place) {
          const placeInfo = await this.resolveLocation(this.place);
          if (!placeInfo) {
            console.warn("Unable to resolve place: ", this.place);
          } else {
            this.setPlaceBounds(placeInfo);
          }
        }
    }

    private setPlaceBounds(place: PlaceInfo[]) {
        if (!this._map) {
            console.error("Map object is not initialized.");
            return;
        }

        if (place == null) {
            console.warn("Place is null/undefined.");
            return;
        }

        const place0 = place[0];
        if (!place0 || place0.boundingbox.length !== 4) {
            return;
        }

        const boundingBox: LngLatBoundsLike = [
            +place0.boundingbox[2], // minX
            +place0.boundingbox[0], // minY
            +place0.boundingbox[3], // maxX
            +place0.boundingbox[1]  // maxY
        ];
        
        this._map.fitBounds(boundingBox, { padding: MapViewerComponent.BOUNDARY_PADDING } );
    }

    //#endregion
}

export type MapDataSource = {
    id: string;
    name: string;
    type: 'geojson';
    data: any;
    sasLink?: string;
}