<div *ngIf="!error" class="registry-content">
    <svg id="metaregistrymapsvg"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="-20 10 1000 500"
        version="1.1"
        class="map">
    </svg>
</div>
<div *ngIf="error" class="error">{{ error }}</div>

<sdk-loading [isLoading]="isLoading"></sdk-loading>
