export interface CarbonMarketType {
  id: number;
  name: string;
}

export class CarbonMarketTypeEts implements CarbonMarketType {
  id: number;
  name: string;

  private constructor() {
    this.id = 1;
    this.name = "ETS";
  }

  public static getInstance(): CarbonMarketType {
    return new CarbonMarketTypeEts();
  }
}

export class CarbonMarketTypeTax implements CarbonMarketType {
  id: number;
  name: string;

  private constructor() {
    this.id = 2;
    this.name = "Carbon Tax";
  }

  public static getInstance(): CarbonMarketType {
    return new CarbonMarketTypeTax();
  }
}

export class CarbonMarketTypeCredits implements CarbonMarketType {
  id: number;
  name: string;

  private constructor() {
    this.id = 3;
    this.name = "Carbon Credits";
  }

  public static getInstance(): CarbonMarketType {
    return new CarbonMarketTypeCredits();
  }
}
