<div class="sdk-datagrid-window">
    <div class="header">
        <span class="title">Settings</span>
        <span title="Close" class="sdk-icon close" (click)="close()">close</span>
    </div>
    <div class="actions">
        <select class="select" (change)="load($event)">
            <ng-container *ngFor="let setting of settings; let ndx = index">
                <option [value]="ndx">{{ setting.Name }}</option>
            </ng-container>
        </select>
    </div>
    <div class="content">
        <div class="settings">
            <div class="name">
                <label *ngIf="error !== ''" class="error">{{ error }}</label>
                <sdk-textbox *ngIf="error === ''" hint="Enter Settings Name..." [value]="name === '[Current Settings]' ? '' : name" (changeCallBackEvent)="setName($event)"></sdk-textbox>
            </div>
            <div class="values">
                <div [innerHTML]="viewerText"></div>
            </div>
        </div>
    </div>
    <div class="footer">
        <div class="reset" [disabled]="activeSetting.Name === '[Current Settings]' ? 'disabled' : ''" [ngClass]="{ 'disabled': activeSetting.Name === '[Current Settings]' }" (click)="remove()">Delete</div>
        <button *ngIf="error === ''" class="button" type="button" (click)="save()">Save</button>
        <button *ngIf="error === ''" class="button" type="button" (click)="apply()">Load</button>
    </div>
</div>
