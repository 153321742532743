import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'metaregistry-detail-info',
    templateUrl: './metaregistry-detail-info.component.html',
    styleUrls: ['./metaregistry-detail-info.component.scss']
})

export class MetaRegistryDetailInfoComponent implements OnInit {
    @Input() project: any;

    public formatDirectPriceEstimate(value: number): string {
        if (value === 0)
            return '';
        else
            return value.toString();
    }

	//******************************************************************************
	//  Page Life-cycle Methods
	//******************************************************************************
    public ngOnInit () {
        if (this.project?.sustainableDevelopmentGoals) {
            this.project.sustainableDevelopmentGoals.sort((a: any, b: any) => (a.id < b.id ? -1 : 1));
        }
    }
}
