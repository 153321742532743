import { DecimalPipe } from "@angular/common";
import { ZeroTracker } from "~/models/shared/policy-tracker/zero-tracker.model";

export class CountryProfileViewModel {
 
  constructor(zeroTracker: ZeroTracker | null, decimalPipe: DecimalPipe | null, region?: string) {
    if (!zeroTracker) {
      return;
    }
    if (decimalPipe) {
      this.decimalPipe = decimalPipe;
    }
    this.initializeNameAndRegion(zeroTracker, region);
    this.setGhgEmissions(zeroTracker);
    this.setPopulation(zeroTracker);
    this.setGdp(zeroTracker);
    this.setNdcPlan(zeroTracker);
    this.setInterimTarget(zeroTracker);
    this.setEndTarget(zeroTracker);
    this.setNotesAndSourceUrl(zeroTracker);
  }

  private initializeNameAndRegion(zeroTracker: ZeroTracker, region?: string) {
    if (region) {
      this.name = region;
      this.isRegion = true;
    } else {
      this.name = zeroTracker.country.name;
    }
  }

  private setGhgEmissions(zeroTracker: ZeroTracker) {
    this.ghgEmissions = zeroTracker.ghg;
    this.ghgEmissionsFormatted = this.getInMillions(this.ghgEmissions, 0);
  }

  private setPopulation(zeroTracker: ZeroTracker) {
    this.population = zeroTracker.population;
    if (this.population < 1000000000) {
      this.populationFormatted = this.getInMillions(this.population, 0) + " m";
    } else {
      this.populationFormatted = this.getInBillions(this.population, 2) + " bn";
    }
  }

  private setGdp(zeroTracker: ZeroTracker) {
    this.gdp = zeroTracker.gdpReal;
    if (this.gdp <  1000000000000) {
      this.gdpFormatted = this.getInBillions(this.gdp, 0) + " bn";
    } else {
      this.gdpFormatted = this.getInTrillions(this.gdp, 1) + " tn";
    }
  }

  private setNdcPlan(zeroTracker: ZeroTracker) {
    this.ndcPlan = zeroTracker.hasPlan;
    if (!this.ndcPlan) {
      this.ndcPlan = "no data";
    }
  }

  private setInterimTarget(zeroTracker: ZeroTracker) {
    this.interimTarget = zeroTracker.interimTarget;
    this.interimTargetYear = zeroTracker.interimTargetYear;
    this.interimTargetText = zeroTracker.interimTargetText;
    this.interimTargetBaselineYear = zeroTracker.interimTargetBaselineYear;
    if (zeroTracker.interimTargetPercentageReduction) {
      this.interimTargetReduction = zeroTracker.interimTargetPercentageReduction + '%';
    }
    if (this.interimTarget && this.interimTargetReduction) {
      this.interimTargetSummary = this.interimTarget + " of " + this.interimTargetReduction;
    }
    if (this.interimTargetBaselineYear) {
      this.interimTargetBaselineSummary = "baseline set " + this.interimTargetBaselineYear;
    }
  }

  private setEndTarget(zeroTracker: ZeroTracker) {
    this.endTarget = zeroTracker.endTarget;
    this.endTargetYear = zeroTracker.endTargetYear;
    this.endTargetStatusYear = zeroTracker.endTargetStatusYear;
    this.endTargetText = zeroTracker.endTargetText;
    this.endTargetStatus = zeroTracker.endTargetStatus;
    this.endTargetPercentageReduction = zeroTracker.endTargetPercentageReduction;
    this.endTargetBaselineYear = zeroTracker.endTargetBaselineYear;
    this.setEndTargetStatusSummary();
    this.setEndTargetSummary();
  }

  private setEndTargetStatusSummary() {
    if (this.endTargetStatus && this.endTargetStatusYear) {
      this.endTargetStatusSummary = '';
      if (this.endTargetBaselineYear) {
        this.endTargetStatusSummary = "baseline set " + this.endTargetBaselineYear + ", ";
      }
      this.endTargetStatusSummary = this.endTargetStatusSummary + this.endTargetStatus + " on " + this.endTargetStatusYear; 
    }
  }

  private setEndTargetSummary() {
    if (this.endTarget) {
      this.endTargetSummary = this.endTarget;
      if (this.endTargetPercentageReduction) {
        this.endTarget = this.endTarget + " of " + this.endTargetPercentageReduction + "%";
      }
    }
  }

  private setNotesAndSourceUrl(zeroTracker: ZeroTracker) {
    this.notes = zeroTracker.targetNotes;
    this.sourceUrl = zeroTracker.sourceUrl;
  }


  formatNumberWithCommas(value: number): string | null {
    return this.decimalPipe.transform(value, '1.0-0');
  }

  private getInTrillions(value: number, decimals: number): string {
    const trillions = value / 1_000_000_000_000;
    const multiplier = Math.pow(10, decimals);

    const roundedTrillions = Math.ceil(trillions * multiplier) / multiplier;
    const formattedValue = roundedTrillions.toFixed(decimals);

    return `${formattedValue}`;
  }

  private getInMillions(value: number, decimals: number): string {
    const millions = value / 1_000_000;
    const multiplier = Math.pow(10, decimals);

    const roundedMillions = Math.ceil(millions * multiplier) / multiplier;
    const formattedValue = roundedMillions.toFixed(decimals);

    return `${formattedValue}`;
  }

  private getInBillions(value: number, decimals: number): string {
    const millions = value / 1_000_000_000;
    const multiplier = Math.pow(10, decimals);

    const roundedMillions = Math.ceil(millions * multiplier) / multiplier;
    const formattedValue = roundedMillions.toFixed(decimals);

    return `${formattedValue}`;
  }

  private decimalPipe!: DecimalPipe;

  public name!: string;
  public isRegion!: boolean;
  public ghgEmissions!: number;
  public ghgEmissionsFormatted!: string;
  public population!: number;
  public populationFormatted!: string
  public gdp!: number;
  public gdpFormatted!: string;
  public ndcPlan!: string;
  public interimTarget!: string;
  public interimTargetYear!: number;
  public endTarget!: string;
  public endTargetYear!: number;
  public endTargetStatusYear!: number;
  public endTargetText!: string;
  public endTargetStatus!: string;
  public endTargetPercentageReduction!: number;
  public endTargetBaselineYear!: number;
  public notes!: string;
  public sourceUrl!: string;
  public interimTargetText!: string;
  public interimTargetReduction!: string;
  public interimTargetBaselineYear!: number;
  public interimTargetSummary!: string;
  public interimTargetBaselineSummary!: string;
  public endTargetStatusSummary!: string;
  public endTargetSummary!: string;
}