import { HttpEvent, HttpEventType } from "@angular/common/http";
import { Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output } from "@angular/core";
import { UntypedFormGroup } from "@angular/forms";
import { CreditForecastsImportForm } from "./credit-forecasts-import-form";
import { ForecastTypeEnum } from "../model/forecast-type.enum";
import { ICreditForecast } from "~/models/shared/common";
import CcmsServerError from "~/models/shared/errors/ccms-server-error";
import { ProjectService } from "~/services/shared/projects/project.service";
import { AlertType } from "~/models/shared/errors/error-alert-type.enum";
import { ICreditForecastsUploadResponse } from "~/models/shared/credit-forecasts-upload-response.model";

@Component({
  selector: 'ccms-credit-forecasts-import',
  templateUrl: './credit-forecasts-import.component.html',
  styleUrls: ['./credit-forecasts-import.component.scss']
})
export class CreditForecastsImportComponent implements OnInit {
  @Input()
  projectId!: number;
  @Input()
  forecastType!: ForecastTypeEnum;
  @Output() onCancel = new EventEmitter();
  @Output() onImport = new EventEmitter<ICreditForecast[]>();

  onChange!: Function;
  file: File | null = null;
  isLoading!: boolean;
  error!: CcmsServerError | null;

  constructor(
    private host: ElementRef<HTMLInputElement>,
    private readonly _projectService: ProjectService) {
  }

  importForm!: CreditForecastsImportForm;
  form!: UntypedFormGroup;
  progress: number = 0;
  validFileType: boolean = true;
	AT = AlertType;
  private uploaded!: boolean;
    
  ngOnInit(): void {
    this.importForm = new CreditForecastsImportForm();
    this.form = this.importForm.form;
  }

  @HostListener('change', ['$event.target.files']) 
  emitFiles(event: FileList) {
    if (!event) {
      return;
    }
    const file = event?.item(0);
    this.file = file;
    this.error = null;
    this.progress = 0;
  }

  onUpload() {
    this.error = null;
    this.isLoading = true;
    this.progress = 0;
    this.uploaded = false;
    if (this.file) {
      const obsv = this._projectService.uploadCreditForecastsData(this.projectId, this.forecastType, this.file);
   
      obsv.subscribe({
        next: (event) => {
          this.uploadProgress(event);
        },
        error: (err) => {
          this.error = new CcmsServerError(err);
          this.isLoading = false;
        }
      });
    }
  }

  private uploadProgress(event: HttpEvent<ICreditForecastsUploadResponse>) {
    if (event.type === HttpEventType.UploadProgress && event.loaded && event.total) {
      this.progress = Math.round((100 * event.loaded) / event.total);
    }

    if (event.type === HttpEventType.Response && !this.uploaded) {
      this.uploaded = true;
      const sasUrl = event.body;
      if (sasUrl) {
        const obsv = this._projectService.parseCreditForecastsData(this.projectId, this.forecastType, this.form.controls["sheet"].value, sasUrl);
        obsv.subscribe({
            next: (event) => {
            this.clear();
            this.onImport.emit(event)
            this.isLoading = false;
          },
          error: (err) => {
            this.error = new CcmsServerError(err);
            this.isLoading = false;
          }
        });
      }
    }
  }

  cancel(): void {
    this.clear();
    this.onCancel.emit();
  }

  private clear() {
     // clear file input
     this.progress = 0;
     this.host.nativeElement.value = '';
     this.file = null;
  }

  public getError(): string | null {
    if (this.error) { 
      return this.error.errors;
    }

    return null;
  }

  get fileIsValid() {
    return this.importForm.form.controls['formFile'].valid;
  }
}