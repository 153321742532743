import { Component, EventEmitter, Inject, Output } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'run-dialog',
  templateUrl: './run-dialog.component.html',
  styleUrls: ['./run-dialog.component.scss']
})
export class RunDialogComponent {
  @Output() onClose = new EventEmitter<boolean>();
  
  resetState = false;

  constructor(private dialogRef: MatDialogRef<RunDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {}

  run(): void {
    this.onClose.emit(this.resetState);

    this.dialogRef.close({ action: 'run', resetState: this.resetState });
  } 
}
