import { Component, Input, ViewChild, OnChanges, SimpleChanges } from "@angular/core";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { Overlay, OverlayConfig, OverlayRef } from "@angular/cdk/overlay";
import { ComponentPortal } from "@angular/cdk/portal";
import { ClimatePolicy } from "~/models/shared/policy-tracker/climate-policy.model";
import Dictionary from "~/components/shared/dictionary/dictionary";
import { PopupContentDialog } from "../popup-content-dialog/popup-content-dialog.component";

@Component({
  selector: 'ccms-climate-policy',
  templateUrl: './climate-policy.component.html',
  styleUrls: ['./climate-policy.component.scss']
})
export class ClimatePolicyComponent implements OnChanges {
  @Input() policies: ClimatePolicy[] | undefined;
  @ViewChild(MatSort) sort!: MatSort;
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  
  dataSource!: MatTableDataSource<ClimatePolicy>;
  displayedColumns: string[] = ['policyTitle', 'policyStatusName', 'sector', 'decisionDate'];

  constructor(private overlay: Overlay) { }

  private overlays = new Dictionary<OverlayRef>();

  openDialog(event: MouseEvent, policy2: ClimatePolicy) {
    if (!this.overlays.containsKey(policy2.policyId.toString())) {
      const overlayRef = this.createDialogOverlay(event, "climate-policy-overlay-panel");
      let componentRef = overlayRef.attach(new ComponentPortal(PopupContentDialog));

      if (policy2.policyDescription) {
        componentRef.instance.data = policy2.policyDescription;
      } else {
        componentRef.instance.data = "no data";
      }

      this.overlays.add(policy2.policyId.toString(), overlayRef);
    }
  }

  onMouseExit(policy2: ClimatePolicy) {
    const key = policy2.policyId.toString();
    if (this.overlays.containsKey(key)) {
      const overlay = this.overlays.getItem(key);
      overlay.detach();
      this.overlays.removeItem(key)
    }
  }

  private createDialogOverlay(event: MouseEvent, panelClass: string): OverlayRef {
    const overlayConfig = new OverlayConfig({
      hasBackdrop: false,
      backdropClass: 'cdk-overlay-transparent-backdrop',
      panelClass: panelClass,
      positionStrategy: this.overlay.position()
        .flexibleConnectedTo({ x: event.clientX, y: event.clientY })
        .withPositions([
          {
            originX: 'center',
            originY: 'bottom',
            overlayX: 'center',
            overlayY: 'top',
            offsetY: 3
          }
        ])
    });

    const overlayRef = this.overlay.create(overlayConfig);

    return overlayRef;
  }
  
  ngOnChanges(changes: SimpleChanges) {
    if (changes.policies) {
      this.dataSource = new MatTableDataSource(this.policies);
      if (this.policies)
      // We delay assigning paginator and sort to next tick to ensure they're available.
      Promise.resolve().then(() => {
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;
      });
    }
  }
}
