<div class="content">
    <div *ngIf="!isLoading && !project.metaRegistryProjectId && !isEdit" class="nodata">No Ratings data available for this project.</div>
    <div *ngIf="!isLoading && project.metaRegistryProjectId && !isEdit">
        <metaregistry-detail-ratings [projectId]="project.metaRegistryProjectId"></metaregistry-detail-ratings>
    </div>
    <div *ngIf="isEdit" class="selection">
        <div *ngIf="projectEdit.metaRegistryProjectId" class="data">
            <div class="label">Selected Project:</div>
            <div class="project">{{ projectEdit.metaRegistryProjectId }}</div>
            <div title="Remove Project" class="icon material-icons-outlined delete" (click)="clearProject()">delete</div>
        </div>

        <div>
            <div class="header">MetaRegistry Project Link</div>
            <div><sdk-textbox style="display: inline-flex" width="250px" [value]="searchTerm" (changeCallBackEvent)="searchProjects($event)"></sdk-textbox></div>
        </div>

        <div *ngIf="doSearch" class="grid">
            <sdk-datagrid
                fontFamily="'GothamNarrowBook', sans-serif, 'Helvetica Neue', Arial"
                [uniqueIdentifier]="'projects.project-detail.ratings.project-detail-ratings'"
                [datasets]="datasets"
                [name]="activeView"
                [data]="data"
                [columns]="columns"
                [dbPage]="dbPage"
                [dbTotal]="dbTotal"
                [dbMAXRECORDS]="MAXRECORDS"
                [isDataWrapped]=true
                [showHeader]=false
                [showOptions]=false
                [isLoading]="isLoading"
                [error]="error"
                (loadDataEvent)="loadData($event)">
            </sdk-datagrid>

            <ng-template #actionLeft let-rowItem="rowItem" let-activeRow="activeRow">
                <div class="action">
                    <div title="Add" class="icon material-icons-outlined" (click)="$event.stopPropagation(); setProject(rowItem)">add</div>
                </div>
            </ng-template>
        </div>
    </div>    
</div>
