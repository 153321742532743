export enum MarketType {
  Ice = 1,
  ClearBlue = 2,
  AirCarbon = 3,
  Platts = 4
}

export interface IMarket {
  marketType: MarketType;
  name: string;
  symbol: string;
}