import { Component, EventEmitter, OnInit, Output } from "@angular/core";
import { Subject } from "rxjs";

export interface SeriesLegend {
  name: string;
  symbol: string;
  color: string;
}

@Component({
  selector: 'ccms-price-chart-legend',
  templateUrl: './price-chart-legend.component.html',
  styleUrls: ['./price-chart-legend.component.scss']
})
export class PriceChartLegendComponent implements OnInit
{
  @Output() onHoverStart = new EventEmitter<string>();
  @Output() onHoverEnd = new EventEmitter<string>();

  seriesAdded: Subject<SeriesLegend> = new Subject<SeriesLegend>();
  seriesRemoved: Subject<SeriesLegend> = new Subject<SeriesLegend>();

  markets: SeriesLegend[] = [];
  colors: object = {};

  ngOnInit(): void {
    this.subscribe();
  }

  public subscribe() {
    this.seriesAdded.subscribe({
      next: (v) => this.addSeries(v)
    });

    this.seriesRemoved.subscribe({
      next: (v) => this.removeSeries(v)
    });
  }

  private addSeries(legend: SeriesLegend) {
    this.colors[legend.name] = legend.color;
    this.markets.push(legend);
  }

  private removeSeries(legend: SeriesLegend) {
    const marketIndex = this.markets.findIndex(x => x.symbol === legend.symbol);
    if (marketIndex !== -1) {
        this.markets.splice(marketIndex, 1);
    }
  }

  public mouseEnter(symbol: string) {
    this.onHoverStart.emit(symbol);
  }

  public mouseLeave(symbol: string) {
    this.onHoverEnd.emit(symbol);
  }
}