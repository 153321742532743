<div class="content">
    <div class="data">

        <br />
        <table class="table-z">
            <caption style="display: none;">Numbers</caption>
            <tbody>
                <tr>
                    <th scope="col"><span class="label">Total Funding/Spending</span></th>
                    <td><sdk-textbox style="display: inline-flex" width="100px" validCharacters="decimal"
                            (changeCallBackEvent)="infoChange({ field: 'fundingOrSpending', value: $event })"></sdk-textbox>
                        MM</td>

                    <th scope="col"><span class="label">Total Carbon Credits Forecast</span></th>
                    <td><sdk-textbox style="display: inline-flex" width="100px" validCharacters="decimal"
                            (changeCallBackEvent)="infoChange({ field: 'totalCreditsForecast', value: $event })"></sdk-textbox>
                        MMTCO2e</td>
                </tr>
                <tr>
                    <th scope="col"><span class="label">Contracted Lifetime Vol</span></th>
                    <td><sdk-textbox style="display: inline-flex" width="100px" validCharacters="decimal"
                            (changeCallBackEvent)="infoChange({ field: 'contractedLifetimeVolume', value: $event })"></sdk-textbox>
                        MTCO2e</td>

                    <th scope="col"><span class="label">Max Access Lifetime Vol</span></th>
                    <td><sdk-textbox style="display: inline-flex" width="100px"  validCharacters="custom" pattern="^\d{1,2}(\.\d{0,2})?$"
                            (changeCallBackEvent)="infoChange({ field: 'maximumAccessLifetimeVolume', value: $event })"></sdk-textbox>
                        MTCO2e</td>
                </tr>
            </tbody>
        </table>

        <br />

        <table class="table-x">
            <caption style="display: none;">Notes</caption>
            <tbody>
                <tr>
                    <th scope="col">Status Notes</th>
                    <td><textarea class="edit" rows="5" (input)="setNotes('projectStatusNotes', $event)"></textarea>
                    </td>
                </tr>
                <tr>
                    <th scope="col">Notes</th>
                    <td><textarea class="edit" rows="5" (input)="setNotes('notes', $event)"></textarea>
                    </td>
                </tr>
                <tr>
                    <th scope="col">Barriers</th>
                    <td><textarea class="edit" rows="5" (input)="setNotes('barriers', $event)"></textarea></td>
                </tr>
            </tbody>
        </table>

        <sdk-loading [isLoading]="isLoading"></sdk-loading>
    </div>


</div>