import { Component, ComponentRef, Input } from '@angular/core';

export interface Tab {
    title: string;
    type?: ComponentRef<any>;
    inputs?: any;
    outputs?: any
}

@Component({
    selector: 'options',
    templateUrl: './options.component.html',
    styleUrls: ['./options.component.scss']
})

export class OptionsComponent {
    @Input() options: any[] = [];
    @Input() closeWindow: boolean = false;

    public showWindow: boolean = false;
    public optionTitle: string = "";
    public optionsComponent: any = null;

    public ngOnChanges(_args: any) {
        if(_args.closeWindow) {
            this.showWindow = false;
        }
    }

    public setWindow(option: any) {
        this.optionTitle = option.title;
        this.optionsComponent = option;
        this.showWindow = true;
    }

    public close() {
        this.showWindow = false;
    }
}
