import { CurrencyPipe } from "@angular/common";
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from "@angular/core";
import { UntypedFormGroup } from "@angular/forms";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { BehaviorSubject, firstValueFrom, Observable } from "rxjs";
import { CreditForecastsWrapper } from "../model/credit-forecasts-wrapper.model";
import { CreditForecastViewModel } from "../model/credit-forecast.viewmodel";
import { ForecastTypeEnum } from "../model/forecast-type.enum";
import { CreditForecastUpdateWrapper } from "../model/credit-forecast-update-wrapper.model";
import { CreditForecastsChartComponent } from "../credit-forecast-chart/credit-forecasts-chart.component";
import { ICreditForecast, ICreditsMarket, ICreditsMarketPrice } from "~/models/shared/common";
import Dictionary from "~/components/shared/dictionary/dictionary";
import { CreditsMarketService } from "~/services/shared/credits-market/credits-market.service";
import { CreditForecastsForm } from "./credit-forecasts-form";
import { CreditForecastsImportComponent } from "../credit-forecast-import/credit-forecasts-import.component";
import { ICvxProject } from "~/models/shared/cvxproject";

@Component({
	selector: 'ccms-credit-forecasts-form',
	templateUrl: './credit-forecasts-form.component.html',
	styleUrls: ['./credit-forecasts-form.component.scss']
})
export class CreditForecastsFormComponent implements OnInit {
	@Input() set riskForecastData(value: CreditForecastsWrapper) {
		if (value) {	
			const data: CreditForecastViewModel[] = [];
			value.creditForecasts.forEach(element => {
				data.push(new CreditForecastViewModel(element))
			});
			this._riskForecastDataSubject.next(data);
		}
	}
	@Input() set unriskForecastData(value: CreditForecastsWrapper) {
		if (value) {
      this.unriskCreditForecasts = [];
      value.creditForecasts.forEach(element => {
        this.unriskCreditForecasts.push(new CreditForecastViewModel(element))
      });
			this.creditsMarket = value.creditsMarket;
			this.loadPrices(value.creditsMarket?.marketPrices);
      this.reloadForm();
      this.calcTotals();
		}
	}
	@Input()
  projectId!: number;
	@Input()
  forecastType!: ForecastTypeEnum;
	@Output() onSave = new EventEmitter<CreditForecastUpdateWrapper>();

	@ViewChild('chart')
  chart!: CreditForecastsChartComponent;
	@ViewChild('template', { static: true }) template;

	columnsSchema: any = CreditForecastsForm.COLUMNS_SCHEMA;

	private _riskForecastDataSubject: BehaviorSubject<CreditForecastViewModel[]>;
	riskForecastData$: Observable<CreditForecastViewModel[]>;
	riskCreditForecasts: CreditForecastViewModel[] = [];
	unriskCreditForecasts: CreditForecastViewModel[] = [];
	creditsForecastForm!: CreditForecastsForm;
	form!: UntypedFormGroup;
	isEdit: boolean = false;
	creditsMarkets: ICreditsMarket[] = [];
	creditsMarketPrices: Dictionary<ICreditsMarketPrice> = new Dictionary<ICreditsMarketPrice>();
	creditsMarket!: ICreditsMarket | null;

	totalPrepayment!: number;
	totalOpEx!: number;
	totalNonDiscretionarySpend!: number;
	totalDiscretionarySpend!: number;
	totalFundingAmount!: number;
	totalNonObligatedCredits!: number;
	totalCredits!: number;
	fundingAmountByYear: Map<number, string> = new Map<number, string>();
	prices: Map<number, string> = new Map<number, string>();

	constructor(
		private readonly _creditsMarketService: CreditsMarketService,
		private currencyPipe: CurrencyPipe,
		private readonly _dialog: MatDialog
	) {
		this._riskForecastDataSubject = new BehaviorSubject<CreditForecastViewModel[]>([]);
		this.riskForecastData$ = this._riskForecastDataSubject.asObservable();
	}

	async ngOnInit() {
		const markets = await firstValueFrom(this._creditsMarketService.getCreditsMarkets());
		this.creditsMarkets = [...markets];
		const modal = <ICreditsMarket>{};
		modal.id = 0;
		modal.name = 'None'
		this.creditsMarkets.unshift(modal);
	}

	public get displayedColumns(): string[] {
		const withMarket = [CreditForecastsForm.KEY_YEAR, CreditForecastsForm.KEY_FUNDING_AMOUNT, CreditForecastsForm.KEY_PRICE, CreditForecastsForm.KEY_MARKET_PRICE, CreditForecastsForm.KEY_DISCOUNT_RATE, CreditForecastsForm.KEY_PRICE_FLOOR, CreditForecastsForm.KEY_CREDITS, CreditForecastsForm.KEY_NON_OBLIGATED_CREDITS, CreditForecastsForm.KEY_PREPAYMENT, CreditForecastsForm.KEY_OPEX, CreditForecastsForm.KEY_DISCRETIONARY_SPEND, CreditForecastsForm.KEY_NON_DISCRETIONARY_SPEND];
		const without = withMarket.filter(col => col !== CreditForecastsForm.KEY_MARKET_PRICE);

    if (this.isEdit) {
		  if (this.creditsMarketPrices) {
			  return [...withMarket, CreditForecastsForm.KEY_DELETE];
		  } else {
			  return [...without, CreditForecastsForm.KEY_DELETE];
		  }
    } else {
      if (this.creditsMarketPrices) {
			  return [...withMarket];
		  } else {
			  return [...without];
		  }
    }
	}

	private calcTotals() {
		this.totalPrepayment = this.getTotalPrepayment();
		this.totalOpEx = this.getTotalOpEx();
		this.totalNonDiscretionarySpend = this.getTotalNonDiscretionarySpend();
		this.totalDiscretionarySpend = this.getTotalDiscretionarySpend();
		this.totalFundingAmount = this.getTotalFundingAmount();
		this.totalNonObligatedCredits = this.getTotalNonObligatedCredits();
		this.totalCredits = this.getTotalCredits();
		this.populateFundingAmountMap();
	}

	public async onCreditsMarketChange(event) {
		if (event.value == 0) {
			this.clearPrices();
			this.creditsMarket = null;
			return;
		}

		const market = await firstValueFrom(this._creditsMarketService.getCreditsMarketWithPrices(event.value));
		this.creditsMarket = market;
		this.loadPrices(market.marketPrices);

		for (const element of this.unriskCreditForecasts) {
      if (element.year) {
			  this.form.get(CreditForecastsForm.getMarketPriceId(element.rowId))?.setValue(this.getMarketPrice(element.year));
      }
		}
	}

	public getSelectedCreditsMarket(): string {
		const name = this.creditsMarket?.name;
		if (name) {
			return name;
		}

		return 'not selected';
	}

	public loadPrices(prices: ICreditsMarketPrice[]) {
		if (!prices) {
			return;
		}
		this.creditsMarketPrices = new Dictionary<ICreditsMarketPrice>();
		this.prices = new Map<number, string>();
		prices.forEach(price => {
			this.creditsMarketPrices.add(CreditForecastsFormComponent.getYearId(price.year), price);
			
      const marketPrice = this.getMarketPrice(price.year);
      if (marketPrice) {
        this.prices.set(price.year, marketPrice);
      }
		});
	}

	public clearPrices() {
		this.creditsMarketPrices = new Dictionary<ICreditsMarketPrice>();
	}

	public getMarketPrice(year: number): string | null | undefined {
		return CreditForecastsForm.getMarketPrice(year, this.creditsMarketPrices, this.currencyPipe);
	}

	private populateFundingAmountMap() {
		if (!this.isEdit) {
			return;
		}

		this.fundingAmountByYear.clear();
		const funding = new Map<number, string>();
		for (const item of this.unriskCreditForecasts) {
			const year = this.form.get(CreditForecastsForm.getYearId(item.rowId))?.value;
			const discretionarySpend = parseFloat(this.removeCommas(this.form.get(CreditForecastsForm.getDiscretionarySpendId(item.rowId))?.value));
			const nonDiscretionarySpend = parseFloat(this.removeCommas(this.form.get(CreditForecastsForm.getNonDiscretionarySpendId(item.rowId))?.value));

			let fundingAmount = 0;
			if (discretionarySpend) {
				fundingAmount += discretionarySpend;
			}
			if (nonDiscretionarySpend) {
				fundingAmount += nonDiscretionarySpend
			}
			funding.set(year, fundingAmount.toLocaleString("en-US", {maximumFractionDigits: 0}));
		}
		this.fundingAmountByYear = funding;
	}

	private getTotalCredits() {
		let creditsTotal: number = 0;
		if (!this.isEdit) {
			for (const item of this.unriskCreditForecasts) {
        if (item.credits) {
				  creditsTotal += item.credits;
        }
			}

			return creditsTotal;
		}

		for (const item of this.unriskCreditForecasts) {
			const credits = this.form.get(CreditForecastsForm.getCreditsId(item.rowId))?.value;
			if (credits) {
				creditsTotal += +this.removeCommas(credits);
			}
		}

		return creditsTotal;
	}

	private getTotalNonObligatedCredits() {
		let creditsTotal: number = 0;
		if (!this.isEdit) {
			for (const item of this.unriskCreditForecasts) {
        if (item.nonObligatedCredits) {
				  creditsTotal += item.nonObligatedCredits;
        }
			}

			return creditsTotal;
		}

		for (const item of this.unriskCreditForecasts) {
			const credits = this.form.get(CreditForecastsForm.getNonObligatedCreditsId(item.rowId))?.value;
			if (credits) {
				creditsTotal += +this.removeCommas(credits);
			}
		}

		return creditsTotal;
	}

	private getTotalFundingAmount() {
		let fundingAmountTotal: number = 0;
		if (!this.isEdit) {
			for (const item of this.unriskCreditForecasts) {
        if (item.fundingAmount)
				fundingAmountTotal += item.fundingAmount;
			}

			return fundingAmountTotal;
		}

		for (const item of this.unriskCreditForecasts) {
			const discretionarySpend = parseFloat(this.removeCommas(this.form.get(CreditForecastsForm.getDiscretionarySpendId(item.rowId))?.value));
			const nonDiscretionarySpend = parseFloat(this.removeCommas(this.form.get(CreditForecastsForm.getNonDiscretionarySpendId(item.rowId))?.value));

			let fundingAmount = 0;
			if (discretionarySpend) {
				fundingAmount += discretionarySpend;
			}
			if (nonDiscretionarySpend) {
				fundingAmount += nonDiscretionarySpend
			}
			fundingAmountTotal += fundingAmount;
		}

		return fundingAmountTotal;
	}

	private getTotalDiscretionarySpend() {
		let spendTotal: number = 0;
		if (!this.isEdit) {
			for (const item of this.unriskCreditForecasts) {
        if (item.discretionarySpend) {
				  spendTotal += item.discretionarySpend;
        }
			}

			return spendTotal;
		}

		for (const item of this.unriskCreditForecasts) {
			const spend = this.form.get(CreditForecastsForm.getDiscretionarySpendId(item.rowId))?.value;
			if (spend) {
				spendTotal += +this.removeCommas(spend);
			}
		}

		return spendTotal;
	}

	private getTotalNonDiscretionarySpend() {
		let spendTotal: number = 0;
		if (!this.isEdit) {
			for (const item of this.unriskCreditForecasts) {
        if (item.nonDiscretionarySpend) {
				  spendTotal += item.nonDiscretionarySpend;
        }
			}

			return spendTotal;
		}

		for (const item of this.unriskCreditForecasts) {
			const spend = this.form.get(CreditForecastsForm.getNonDiscretionarySpendId(item.rowId))?.value;
			if (spend) {
				spendTotal += +this.removeCommas(spend);
			}
		}

		return spendTotal;
	}

	private getTotalOpEx() {
		let spendTotal: number = 0;
		if (!this.isEdit) {
			for (const item of this.unriskCreditForecasts) {
        if (item.opEx) {
				  spendTotal += item.opEx;
        }
			}

			return spendTotal;
		}

		for (const item of this.unriskCreditForecasts) {
			const spend = this.form.get(CreditForecastsForm.getOpExId(item.rowId))?.value;
			if (spend) {
				spendTotal += +this.removeCommas(spend);
			}
		}

		return spendTotal;
	}

	private getTotalPrepayment() {
		let spendTotal: number = 0;
		if (!this.isEdit) {
			for (const item of this.unriskCreditForecasts) {
        if (item.prepayment) {
				  spendTotal += item.prepayment;
        }
			}

			return spendTotal;
		}

		for (const item of this.unriskCreditForecasts) {
			const spend = this.form.get(CreditForecastsForm.getPrepaymentId(item.rowId))?.value;
			if (spend) {
				spendTotal += +this.removeCommas(spend);
			}
		}

		return spendTotal;
	}

	async onSaveForm() {
		const creditsForecast: ICreditForecast[] = [];
		for (const item of this.unriskCreditForecasts) {
			const forecast = {} as ICreditForecast;
			forecast.cvxProjectId = this.projectId;

			this.getFormValues(forecast, item);
			creditsForecast.push(forecast);
		}

		this.form.markAsPristine();
		const wrapper = new CreditForecastUpdateWrapper();
		wrapper.creditForecasts = creditsForecast;
		wrapper.forecastType = this.forecastType;
		wrapper.creditsMarketId = this.form.get(CreditForecastsForm.CreditsMarketId)?.value;
		if (wrapper.creditsMarketId) {
			this.creditsMarket = await firstValueFrom(this._creditsMarketService.getCreditsMarketWithPrices(wrapper.creditsMarketId));
		}

		this.onSave.emit(wrapper);
	}

	private getFormValues(forecast: ICreditForecast, item: CreditForecastViewModel): void {
		forecast.year = this.form.get(CreditForecastsForm.getYearId(item.rowId))?.value;

		const price = this.form.get(CreditForecastsForm.getPriceId(item.rowId))?.value;
		if (price) {
			forecast.pricePerTonne = price;
		}

		const credits = this.form.get(CreditForecastsForm.getCreditsId(item.rowId))?.value;
		if (credits) {
			forecast.credits = Math.trunc(this.removeCommas(credits));
		}

		const prepayment = this.form.get(CreditForecastsForm.getPrepaymentId(item.rowId))?.value;
		if (prepayment) {
			forecast.prepayment = parseFloat(this.removeCommas(prepayment));
		}

		const opEx = this.form.get(CreditForecastsForm.getOpExId(item.rowId))?.value;
		if (opEx) {
			forecast.opEx = parseFloat(this.removeCommas(opEx));
		}

		const priceFloor = this.form.get(CreditForecastsForm.getPriceFloorId(item.rowId))?.value;
		if (priceFloor) {
			forecast.priceFloor = +priceFloor;
		}

		const discountRate = this.form.get(CreditForecastsForm.getDiscountRateId(item.rowId))?.value;
		if (discountRate) {
			forecast.discountRate = +discountRate;
		}

		const discretionarySpend = this.form.get(CreditForecastsForm.getDiscretionarySpendId(item.rowId))?.value;
		if (discretionarySpend) {
			forecast.discretionarySpend = parseFloat(this.removeCommas(discretionarySpend));
		}

		const nonDiscretionarySpend = this.form.get(CreditForecastsForm.getNonDiscretionarySpendId(item.rowId))?.value;
		if (nonDiscretionarySpend) {
			forecast.nonDiscretionarySpend = parseFloat(this.removeCommas(nonDiscretionarySpend));
		}

		const nonObligatedCredits = this.form.get(CreditForecastsForm.getNonObligatedCreditsId(item.rowId))?.value;
		if (nonObligatedCredits) {
			forecast.nonObligatedCredits = Math.trunc(this.removeCommas(nonObligatedCredits));
		}
	}

	private removeCommas(x: any) {
		if (!x) {
			return null;
		}
		return x.toString().replace(/,/g, '')
	}

	public async enterEdit(project: ICvxProject, forecasts?: ICreditForecast[] | null) {
    this.isEdit = true;
    if (forecasts) {
      setTimeout(() => {
        for (const element of forecasts) {
          const forecast = new CreditForecastViewModel(element);
          this.unriskCreditForecasts = this.creditsForecastForm.addRow(this.creditsMarketPrices, element.year, forecast);
        }
        this.calcTotals();
        this.form.markAsDirty();
      }, 10);
    } else {
      if (this.unriskCreditForecasts.length === 0) {
        setTimeout(() => {
          this.addRow();
        }, 10);
      }
    }

    if (project.creditsMarket) {
		  this.creditsMarket = await firstValueFrom(this._creditsMarketService.getCreditsMarketWithPrices(project.creditsMarket.id));	
    }
	}

	public async cancelEdit() {
		this.isEdit = false;
	}

	private reloadForm() {
		if (this.creditsMarket?.id) {
			this.loadPrices(this.creditsMarket?.marketPrices);
		} else {
			this.clearPrices();
		}
		this.creditsForecastForm = new CreditForecastsForm(this.unriskCreditForecasts, this.creditsMarketPrices, this.currencyPipe);
		this.form = this.creditsForecastForm.form;
		if (this.creditsMarket?.id) {
			this.form.get(CreditForecastsForm.CreditsMarketId)?.setValue(this.creditsMarket.id);
		}
    this.calcTotals();
	}

	public initChart() {
		this.chart.initializeChart();
	}

	addRow(): CreditForecastViewModel[] {
		this.unriskCreditForecasts = this.creditsForecastForm.addRow(this.creditsMarketPrices);
		this.form.markAsDirty();

    return this.unriskCreditForecasts;
	}

	async showImportDialog() {
		const dialog = await this.initializeImportDialog();
		const form = dialog.componentInstance;
		form.projectId = this.projectId;
		form.forecastType = this.forecastType;

		form.onCancel.subscribe(() => {
			dialog.close();
		});

		form.onImport.subscribe((result: ICreditForecast[]) => {
			dialog.close();
			this.importData(result);
		});
	}

	importData(forecasts: ICreditForecast[]) {
		this.isEdit = true;
		for (const element of forecasts) {
			const existing = this.unriskCreditForecasts.find(x => x.year === element.year);
			const forecast = new CreditForecastViewModel(element);
      let isEmptyRow = false;
      if (forecast.pricePerTonne && forecast.pricePerTonne > 0 && this.isNullOrZero(forecast.credits) 
          && this.isNullOrZero(forecast.prepayment) 
          && this.isNullOrZero(forecast.opEx)
          && this.isNullOrZero(forecast.discountRate) 
          && this.isNullOrZero(forecast.discretionarySpend)
          && this.isNullOrZero(forecast.nonDiscretionarySpend)
          && this.isNullOrZero(forecast.nonObligatedCredits)
          && this.isNullOrZero(forecast.priceFloor)) {
            isEmptyRow = true;
      }
      if (existing && !isEmptyRow) {
				forecast.rowId = existing.rowId;
				this.creditsForecastForm.updateRow(forecast);
			} else if (existing && isEmptyRow) {
        this.unriskCreditForecasts = this.creditsForecastForm.deleteRow(existing.rowId);
      } 
      else {
        if (!isEmptyRow) {
				  this.unriskCreditForecasts = this.creditsForecastForm.addRow(this.creditsMarketPrices, element.year, forecast);
        }
      }
		}
		this.calcTotals();
		this.form.markAsDirty();
	}

  private isNullOrZero(value: number | null | undefined): boolean {
    if (!value || value === 0) {
      return true;
    }

    return false;
  }

	private initializeImportDialog(): Promise<MatDialogRef<CreditForecastsImportComponent, any>> {
		return Promise.resolve(this._dialog.open(CreditForecastsImportComponent));
	}

	deleteRow(rowId: string) {
		this.unriskCreditForecasts = this.creditsForecastForm.deleteRow(rowId);
		this.calcTotals();
		this.form.markAsDirty();
	}
  
	public static getYearId(year: number): string {
		return `year-${year}`;
	}

	public convertPercentage(value: number): number | null {
		if (!value) {
			return null;
		}
		return value / 100;
	}

  public onKeyDown(event, col: string, rowId: string) {
		this.creditsForecastForm.onKeyDown(event, this.GetFieldKey(col, rowId));
	}

	public onKeyUp(event, col: string, rowId: string) {
		this.calcTotals();
		this.creditsForecastForm.onKeyUp(event, this.GetFieldKey(col, rowId));
	}

	public onBlur(event, col: string, rowId: string) {
		this.creditsForecastForm.onBlur(event, this.GetFieldKey(col, rowId));
	}

  private GetFieldKey(col: string, rowId: string): string | null {
		let key: string | null = null;
		switch (col) {
      case CreditForecastsForm.KEY_DISCOUNT_RATE:
        key = CreditForecastsForm.getDiscountRateId(rowId);
        break;
      case CreditForecastsForm.KEY_PRICE_FLOOR:
        key = CreditForecastsForm.getPriceFloorId(rowId);
        break;
      case CreditForecastsForm.KEY_PRICE:
        key = CreditForecastsForm.getPriceId(rowId);
        break;
			case CreditForecastsForm.KEY_FUNDING_AMOUNT:
				key = CreditForecastsForm.getFundingAmountId(rowId);
				break;
			case CreditForecastsForm.KEY_CREDITS:
				key = CreditForecastsForm.getCreditsId(rowId);
				break;
			case CreditForecastsForm.KEY_DISCRETIONARY_SPEND:
				key = CreditForecastsForm.getDiscretionarySpendId(rowId);
				break;
			case CreditForecastsForm.KEY_NON_DISCRETIONARY_SPEND:
				key = CreditForecastsForm.getNonDiscretionarySpendId(rowId);
				break;
			case CreditForecastsForm.KEY_NON_OBLIGATED_CREDITS:
				key = CreditForecastsForm.getNonObligatedCreditsId(rowId);
				break;
			case CreditForecastsForm.KEY_PREPAYMENT:
				key = CreditForecastsForm.getPrepaymentId(rowId);
				break;
			case CreditForecastsForm.KEY_OPEX:
				key = CreditForecastsForm.getOpExId(rowId);
				break;
		}

		return key;
	}
}
