import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, ParamMap, Router } from "@angular/router";
import { ClimatePolicy } from "~/models/shared/policy-tracker/climate-policy.model";
import { CountryService } from "~/services/shared/country.service";
import { ClimatePolicyService } from "~/services/shared/policy-tracker/climate-policy.service";


@Component({
	templateUrl: './policy-tracker-country-detail.page.html',
	styleUrls: ['./policy-tracker-country-detail.page.scss']
})
export class PolicyTrackerCountryDetailPage implements OnInit {
  constructor(
    private router: Router,
    private _route: ActivatedRoute,
    private _countryService: CountryService,
    private _climatePolicyService: ClimatePolicyService
  ){}
  
  countryName!: string;
  countryId!: number;
  public policies!: ClimatePolicy[];

  ngOnInit(): void {
    this._route.paramMap.subscribe((params: ParamMap) => {
      const alpha3Code = params.get('alpha3Code');
      if (alpha3Code) {
        this.load(alpha3Code);
      }
    });
  }

  public back() {
    this.router.navigate(["/policy-tracker"]);
}

  private async load(alpha3Code: string) {
    const country = await this._countryService.getCountryByAlpha3Code(alpha3Code);
    if (country) {
      this.countryName = country.name;
      this.countryId = country.id;
    }

    this.policies  = await this._climatePolicyService.getClimatePoliciesByCountry(alpha3Code);
  }
}