import { Component, Input } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

import { BaseComponent } from '~/pages/base.component';

@Component({
    selector: 'ccms-powerbi-viewer',
    templateUrl: './powerbi-viewer.component.html',
    styleUrls: ['./powerbi-viewer.component.scss']
})

export class ReportViewerComponent extends BaseComponent {
    constructor(private sanitizer: DomSanitizer) {
        super();
    }
    @Input() reportId!: string;
    @Input() width: string = "100%";
    @Input() height: string = "100%";
    public source: any;

    //*************************************************************************
    //  Component Life-Cycle Methods
    //*************************************************************************
    public async ngOnInit() {
       
        await super.ngOnInit();

        this.isLoading = false;
        
    }

    public async ngOnChanges() {
        const safeUrl: SafeResourceUrl = this.sanitizer.bypassSecurityTrustResourceUrl(`https://app.powerbi.com/reportEmbed?reportId=${this.reportId}&autoAuth=true&ctid=fd799da1-bfc1-4234-a91c-72b3a1cb9e26`);
        this.source = safeUrl;
    }


}
