import { Component, Input, OnChanges } from '@angular/core';

import { Logging } from './classes/logging';

const SESSION_KEY = "__sdk-loading__";

@Component({
	selector: 'sdk-loading',
	templateUrl: './sdk-loading.component.html',
	styleUrls: ['./sdk-loading.component.scss']
})

export class SDKLoadingComponent implements OnChanges {
	@Input() isLoading: boolean = false;
	@Input() showTimer: boolean = false;
	@Input() showEllipsis: boolean = false;
	@Input() id: string = "";
	@Input() style: string = "";

	public _isLoading: boolean = false;
	public status: string = ""
	public statusLabel: string = ""
	public diff: number = 0;
	public progress: number = 0;

	private page: string = window.location.pathname;
	private interval: number = 0;
	private counter: any = null;

	public ngOnChanges(changes: any): void {
		if (!changes.id || (changes.id && this.id !== "")) {
			if (this.isLoading) {
				this._isLoading = true;
				this.status = "";
				this.statusLabel = "";
				this.diff = 0;
				this.progress = 0;
				this.interval = 0;

				this.startLog();
				this.showProgress();
			}
		}

		if (changes.isLoading && !this.isLoading) {
			this.stopLog();
		}
	}

	private showProgress() {
		this.counter = setInterval(() => {
			this.progress++;
			this.diff = (this.progress * this.interval);

			this.statusLabel = "color: white;"

			if (this.diff <= 200) {
				this.status = `background-color: green; width: ${this.diff}px`;
			}

			if (this.diff > 200 && this.diff <= 400) {
				this.status = `background-color: orange; width: ${this.diff - 200}px`;
			}

			if (this.diff > 400 && this.diff <= 600) {
				this.status = `background-color: red; width: ${this.diff - 400}px`;
			}

			if (this.diff > 600) {
				this.status = `background-color: red; width: 200px`;
			}
		}, 1000);
	}

	/**************************************************************************
	* Logging
	**************************************************************************/
	private startLog() {
		this.page = this.page.toString().split("?")[0];
		this.id = this.id.toString().replace(/\//g, "_").split("?")[0];

		let log: any = Logging.getRequestLog(SESSION_KEY, this.page + this.id);
		let start: Date = new Date();

		if (log.avg) {
			this.interval = Math.floor(200 / parseInt(log.avg));
		} else {
			this.interval = Math.floor(200 / 20);
		}

		log.start = start;
		log.stop = null;

		Logging.setRequestLog(SESSION_KEY, log);
	}

	private stopLog() {
		clearInterval(this.counter);

		if (this.diff <= 200) {
			this.status = `background-color: green; width: 200px`;
			this.statusLabel = "color: white;"
		}

		setTimeout(() => {
			this._isLoading = false;

			this.page = this.page.toString().split("?")[0];
			this.id = this.id.toString().replace(/\//g, "_").split("?")[0];

			let log: any = Logging.getRequestLog(SESSION_KEY, this.page + this.id);
			let stop: Date = new Date();

			log.stop = stop;

			let duration: number = Math.ceil((+new Date(log.stop) - +new Date(log.start)) / 1000);

			if (log.avg === null) {
				log.avg = duration;
			} else if (log.avg > 30) {
				log.avg = 20;
			} else {
				log.avg = Math.ceil((parseInt(log.avg) + duration) / 2);
			}

			Logging.setRequestLog(SESSION_KEY, log);
		}, 500);
	}
}
