import { DatePipe, DecimalPipe } from "@angular/common";
import { Component, Input, OnChanges, SimpleChanges, ViewChild } from "@angular/core";
import chroma from "chroma-js";
import { ChartDataPoint, MarketChartComponent, SeriesSelectedBehavior } from "~/components/shared/market-chart/market-chart.component";
import { MetaRegistryService } from "~/services/shared/metaregistry.service";
import { MetaRegistryPrice, MetaRegistryPriceResponseModel } from "~/models/shared/metaregisty-price-response.model";
import { MetaRegistryPriceRequest } from "~/models/shared/metaregistry-price-request.model";
import { Paging } from "~/models/shared/request-parameters";

interface VintageAggregate {
  vintage: string;
  price: number;
  averagePrice: number;
  high52: number;
  low52: number;
}

@Component({
  selector: 'metaregistry-detail-prices',
  templateUrl: './metaregistry-detail-prices.component.html',
  styleUrls: ['./metaregistry-detail-prices.component.scss']
})
export class MetaRegistryDetailPricesComponent implements OnChanges {
  @Input() project: any;
  @ViewChild('marketChart')
  marketChart!: MarketChartComponent;

  constructor(
    private _datePipe: DatePipe, 
    private readonly _metaRegistryService: MetaRegistryService,
    private decimalPipe: DecimalPipe) {}
  
  private allPrices: Map<string, MetaRegistryPrice[]> = new Map<string, MetaRegistryPrice[]>();
  private vintageMetadataMap!: Map<string, VintageAggregate>;
  public hasPrices: boolean = true;
  public isLoading = false;

  public vintage!: string | null;
  public price!: string | null;
  public avgPrice!: string | null;
  public high52!: string | null;
  public low52!: string | null;

  public seriesSelectedBehavior: SeriesSelectedBehavior = SeriesSelectedBehavior.HideOthers;

  async ngOnChanges(changes: SimpleChanges) {
    if (this.project) {
      this.isLoading = true;
      await this.loadPrices(1);
      let sortedEntries = Array.from(this.allPrices.entries()).sort((a, b) => b[0].localeCompare(a[0]));
      this.allPrices = new Map(sortedEntries);
      this.loadCharts(this.allPrices);
      this.vintageMetadataMap = this.calculateAggregates(this.allPrices);
      this.setVintage();
      this.isLoading = false;
    }
  }
  
  private async loadPrices(page: number) {
    const response = await this.getPricesPage(page);

    response.values.forEach(value => {
      Object.entries(value.vintagePriceDictionary).forEach(([key, prices]) => {
        if (this.allPrices.has(key)) {
          this.allPrices.get(key)!.push(...prices);
        } else {
          this.allPrices.set(key, [...prices]);
        }
      });
    });

    if (response.metadata.currentPage < response.metadata.totalPages) {
      await this.loadPrices(response.metadata.nextPage);
    }
  }

  private loadCharts(pricesMap: Map<string, MetaRegistryPrice[]>) {
    if (pricesMap.size === 0) {
      this.hasPrices = false;
    }
    const colors = chroma.scale('Paired').colors(pricesMap.size);
  
    pricesMap.forEach((prices, vintage) => {
      const chartDataPoints: ChartDataPoint[] = prices.reduce((accumulator, price) => {
        const date = new Date(price.date);
        const finalDate = this._datePipe.transform(date, 'yyyy-MM-dd')
        if (finalDate) {
          accumulator.push({
            time: finalDate,
            value: price.price
          });
        }
        return accumulator;
      }, [] as ChartDataPoint[]);
        
      const index = this.getKeyPosition(pricesMap, vintage);
      if (index != null) {
        this.marketChart.seriesAdded.next({id: vintage, color: colors[index], data: chartDataPoints});
      }
    });
  }

  private getKeyPosition(map: Map<string, MetaRegistryPrice[]>, targetKey: string): number | null {
    let index = 0;
    for (let key of map.keys()) {
      if (key === targetKey) {
        return index;
      }
      index++;
    }
    return null; // return null if the key is not found
  }

  private getPricesPage(page: number): Promise<MetaRegistryPriceResponseModel> {
    const request = new MetaRegistryPriceRequest();
    request.Paging = new Paging();
    request.Paging.currentPage = page;
    request.Paging.pageSize = 100;
    request.projectId = this.project.id;

    return this._metaRegistryService.getMetaRegistryPrices(request);
  }

  private calculateAggregates(allPrices: Map<string, MetaRegistryPrice[]>): Map<string, VintageAggregate> {
    const vintageAggregates: Map<string, VintageAggregate> = new Map();
  
    allPrices.forEach((prices, vintage) => {
      // Filter prices from the last 52 weeks
      const oneYearAgo = new Date();
      oneYearAgo.setFullYear(oneYearAgo.getFullYear() - 1);
      const recentPrices = prices.filter(price => new Date(price.date) >= oneYearAgo);
  

      // Calculate aggregates
      const price = recentPrices[recentPrices.length - 1].price;
      const averagePrice = recentPrices.reduce((sum, price) => sum + price.price, 0) / recentPrices.length;
      const high52 = Math.max(...recentPrices.map(price => price.price));
      const low52 = Math.min(...recentPrices.map(price => price.price));
  
      // Save the results
      vintageAggregates.set(vintage, { price, vintage, averagePrice, high52, low52 });
    });
  
    return vintageAggregates;
  }

  private setVintage(vintage?: string) {
    let aggregate: VintageAggregate | undefined;
    if (!vintage && this.vintageMetadataMap.size > 0) {
      aggregate = this.vintageMetadataMap.values().next().value;
    } else {
      if (vintage) {
        aggregate = this.vintageMetadataMap.get(vintage);
      }
    }

    if (aggregate) {
      this.setAggregateFields(aggregate);
    }
  }

  private setAggregateFields(aggregates: VintageAggregate) {
    this.vintage = aggregates.vintage;
    this.price = this.decimalPipe.transform(aggregates.price, '1.0-2');
    this.avgPrice = this.decimalPipe.transform(aggregates.averagePrice, '1.0-2');
    this.high52 = this.decimalPipe.transform(aggregates.high52, '1.0-2');
    this.low52 = this.decimalPipe.transform(aggregates.low52, '1.0-2');
  }
  
  onSeriesSelectedStart(symbol: string) {
    this.setVintage(symbol);
  }

  onSeriesSelectedEnd(symbol: string) {
    this.setVintage();
  }
}