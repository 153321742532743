import { Component } from '@angular/core';

import { BaseComponent } from '../base.component';

@Component({
    selector: 'notfound',
    templateUrl: './notfound.component.html',
    styleUrls: ['./notfound.component.scss']
})

export class NotFoundComponent extends BaseComponent {
    constructor() {
        super();
    }

    //*************************************************************************
    //  Component Life-Cycle Methods
    //*************************************************************************
    public async ngOnInit() {
        await super.ngOnInit();

        this.isLoading = false;
    }
}
