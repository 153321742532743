export const FileUtility = {
    isZippedFile: (type: string): boolean => {
        const typeAsLowerCase = type.toLowerCase();
        const allowedZippedFiles = [
          'zip',
          'application/zip',
          'application/x-zip',
          'application/x-zip-compressed'
        ]
        return allowedZippedFiles.includes(typeAsLowerCase);
    }
}