import { NgModule } from "@angular/core";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatDialogModule } from "@angular/material/dialog";
import { MatTreeModule } from "@angular/material/tree";
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatTableModule } from "@angular/material/table";
import { MatPaginatorModule } from "@angular/material/paginator";
import { MatTabsModule } from "@angular/material/tabs";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatNativeDateModule, MatOptionModule } from "@angular/material/core";
import { MatInputModule } from '@angular/material/input';  
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatSelectModule } from '@angular/material/select';
import { ScrollingModule, CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import { MatStepperModule } from "@angular/material/stepper";
import { MatSortModule } from "@angular/material/sort";

const MATERIAL = [
  FormsModule,
  ReactiveFormsModule,
  MatDialogModule,
  MatTreeModule,
  MatCheckboxModule,
  MatButtonModule,
  MatIconModule,
  MatTooltipModule,
  MatTableModule,
  MatPaginatorModule,
  MatTabsModule,
  MatFormFieldModule,
  MatOptionModule,
  MatAutocompleteModule,
  MatProgressSpinnerModule,
  MatInputModule,
  MatNativeDateModule,
  MatDatepickerModule,
  ReactiveFormsModule,
  MatSelectModule,
  ScrollingModule,
  CdkVirtualScrollViewport,
  MatProgressBarModule,
  MatStepperModule,
  MatSortModule,
  MatProgressSpinnerModule
];

@NgModule({
  imports: [ MATERIAL ],
  exports: [ MATERIAL ],
})
export class MaterialImports { }
