import { Injectable } from "@angular/core";
import { HttpService } from "~/services/core/http.service";
import { Issuance } from "./models/issuance";

@Injectable()
export class IssuanceService {
    constructor(
        private readonly httpService: HttpService,
    ) {
        // Intentionally blank
    }

    public search(options: IssuanceSearchOptions): Promise<Issuance> {
        options ??= {} as IssuanceSearchOptions;
        let requestUri = '/issuance/search';
        return this.httpService.Post(requestUri, options);
    }
}

export interface IssuanceSearchOptions {
    type?: string;
    projectIds?: string[];
    sortOptions: Array<{
      property: string;
      isDescending: boolean;
    }>;
    skip: number;
    take: number;
}