import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { PlaceInfo } from "~/models/shared/maps/placeInfo.model";
import { GeoFile } from "~/models/shared/metaregistry";
import { HttpService, ServiceType } from "~/services/core/http.service";

@Injectable()
export class MapsService {
  constructor(
    private readonly httpService: HttpService,
    private httpClient: HttpClient
  ) {
    // Intentionally blank
  }

  public downloadJsonFromSas(sasLink: string): Observable<any> {
    return this.httpClient.get<any>(sasLink, { responseType: 'json' });
  }

  public async getMapsForProject(project: string): Promise<GeoFile[] | null> {
    let requestUri = `/MetaRegistry/${project}/getMaps`;

    return await this.httpService.Get(requestUri, ServiceType.API).then((data: any) => {
      return data;
    });
  }

  public async getProvisionalMapsForProject(project: string): Promise<GeoFile[] | null> {
    let requestUri = `/MetaRegistry/${project}/getProvisionalMaps`;

    return await this.httpService.Get(requestUri, ServiceType.API).then((data: any) => {
      return data;
    });
  }

  public async getGeojsonForProject(project: string): Promise<any> {
    let requestUri = `/get_geojson?project_name=${project}`;

    return await this.httpService.Get(requestUri, ServiceType.Maps).then((data: any) => {
      return data;
    });
  }

  public async promoteMap(project: string, mapUri: string): Promise<string> {
    let requestUri = `/MetaRegistry/${project}/promoteMap?mapUri=${mapUri}`;
   
    return await this.httpService.Patch(requestUri, ServiceType.API).then((data: any) => {
      return data;
    });
  }

  public async demoteMap(project: string, mapUri: string): Promise<string> {
    let requestUri = `/MetaRegistry/${project}/demoteMap?mapUri=${mapUri}`;
   
    return await this.httpService.Patch(requestUri, ServiceType.API).then((data: any) => {
        return data;
    });
  }

  public async searchPlace(query: string, type: 'simple' | 'structured' = 'simple'): Promise<PlaceInfo[] | null> {
    let searchUrl = `/search?format=json&q=${query}`;
    if (type === 'structured') {
      searchUrl = `/search?${query}`;
    }
    
    return await this.httpService.Get(searchUrl, ServiceType.OpenStreetMap).then((data: any) => {
      return data;
    });
  }
}