<div class="container article6-popup">
	<dialog-cancel-button (onCancel)="close()"></dialog-cancel-button>
	<h4 *ngIf="memos.length === 0" class="header" mat-dialog-title>{{country.name}}</h4>
	<mat-dialog-content>
		<p class="content-intro"></p>
		<div *ngIf="memos.length === 0">
			<p>No Article 6 memos found.</p>
		</div>

		<h4 *ngIf="memos.length > 1" class="content-title">Article 6 memos for {{country.name}}:</h4>
		<div *ngIf="memos.length > 1" class="list-wrapper">
			<ul>
				<li *ngFor="let memo of memos">
					<span *ngIf="!memo.hasLink">{{memo.countryTarget}}</span>
					<a *ngIf="memo.hasLink" [href]="memo.link" target="_blank" rel="noopener">{{ memo.countryTarget }}</a>
					&nbsp;({{ memo.status }}<span *ngIf="memo.hasDate">&nbsp;{{ memo.date | date:'shortDate' }}</span>)
					<div *ngIf="memo.hasDescription" class="indent-descr">{{memo.description}}</div>
				</li>
			</ul>
		</div>

		<div *ngIf="memos.length === 1">
			<h4 class="content-title">Article 6 memo</h4>
			<a *ngIf="memos[0].hasLink" [href]="memos[0].link" target="_blank" rel="noopener">{{memos[0].country}} to {{memos[0].countryTarget}}</a>
			<span *ngIf="!memos[0].hasLink">{{memos[0].country}} to {{memos[0].countryTarget}}</span>
			&nbsp;({{ memos[0].status }}<span *ngIf="memos[0].hasDate">&nbsp;{{ memos[0].date | date:'shortDate' }}</span>)
			<div *ngIf="memos[0].hasDescription">{{memos[0].description}}</div>
		</div>
	</mat-dialog-content>
</div>