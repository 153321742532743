import { Directive, ElementRef, Input, OnInit, Renderer2 } from "@angular/core";
import { environment } from "@env/environment";

@Directive({
    selector: '[projectPortfolioId]'
})
export class ProjectPortfolioIdDirective implements OnInit {
    private readonly portfolioAdoBoardBaseurl = environment.portfolioAdoBoardBaseurl;

    @Input() projectPortfolioId!: string;

    constructor(
        private readonly _el: ElementRef,
        private readonly _renderer: Renderer2
    ) {
        // Intentionally blank
    }

    ngOnInit() {
        if (!this.projectPortfolioId) {
            return;
        }
        let link: HTMLLinkElement;
        if (this._el.nativeElement.tagName === 'A') {
            link = this._el.nativeElement as HTMLLinkElement;
        }
        else {
            link = this._renderer.createElement('a');
        }
        const href = `${this.portfolioAdoBoardBaseurl}/${this.projectPortfolioId}`;
        this._renderer.setAttribute(link, 'href', href);
        this._renderer.setAttribute(link, 'target', '_blank');
        this._renderer.appendChild(link, this._renderer.createText(this.projectPortfolioId));
        this._renderer.appendChild(this._el.nativeElement, link);
    }
}