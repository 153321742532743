import { Params, Route } from "@angular/router";
import { ISearchResultViewModel } from "./search-result-viewmodel-interface";
import { SearchResult } from "~/models/shared/search/search-result";
import { ROUTE_ID_INVENTORY, ROUTE_ID_METAREGISTRY_DETAIL, ROUTE_ID_PROJECT_DETAIL } from "~/classes/core/routes/app.routing";

const SEARCH_TITLE_MAX_LENGTH = 150;

export class SearchResultViewModel implements ISearchResultViewModel {
    constructor(
        private readonly _context: SearchResult,
        private readonly _routes: Route[]
    ) {
        // Intentionally blank
    }

    public get url(): string | undefined {
        switch(this.entityType) {
          case "Project":
            return `${this.getRouteById(ROUTE_ID_PROJECT_DETAIL)?.path}`
          case "MetaRegistry":
            return `${this.getRouteById(ROUTE_ID_METAREGISTRY_DETAIL)?.path}`
          case "SupplyTradingInventory":
          case "AbuInventory":
            return `${this.getRouteById(ROUTE_ID_INVENTORY)?.path}`
          default:
            return undefined;
        }
    }

    public get queryParams(): Params | undefined {
        switch (this.entityType) {
            case "Project":
            case "MetaRegistry":
                return { id: this._context.id }
            case "SupplyTradingInventory":
                return {
                    tab: "Transactions",
                    parentTab: "Supply & Trading",
                    ids: this._context.id
                }
            case "AbuInventory":
                return {
                    tab: "Transactions",
                    parentTab: "ABU",
                    ids: this._context.id
                }
            default:
                return undefined;
        }
    }
    
    public get id(): string {
        return this._context.id;
    }

    public get entityType(): string {
        return this._context.entityType;
    }

    get text(): string {
        return this.truncate(this._context.text, SEARCH_TITLE_MAX_LENGTH);
    }

    private truncate(str: string, length: number): string {
        return (str.length > length) ? str.slice(0, length - 1) + '...' : str;
    };

    private getRouteById(id: string): Route | undefined {
      let found!: Route;
      for (let route of this._routes) {
        if (route.data && route.data["id"] == id) {
            found = route;
            break;
        }
      };

      return found;
    }
}

