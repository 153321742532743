import { Component, Input, OnDestroy } from "@angular/core";
import { Chart, ChartDataset, ChartMeta, Point } from "chart.js/auto";
import { RetiredChartUtility } from "~/pages/metaregistry/retirements/retired-charts-util";
import { BaseComponent } from "~/pages/base.component";
import { IIssuanceCreditInfo, IRetirementCreditFilter } from "~/services/shared/retirements/metaregistry-retired-credits.model";
import { RetiredCreditsService } from "~/services/shared/retirements/metaregistry-retired-credits.service";
import { Subject, debounceTime } from "rxjs";
import { ExcelExportService } from "~/services/shared/export-excel/export-excel-service";

@Component({
    selector: 'ccms-retired-chart-issuance',
    templateUrl: './retired-chart-issuance.component.html',
    styleUrls: ['./retired-chart-issuance.component.scss']
})
export class RetiredChartIssuanceComponent extends BaseComponent implements OnDestroy {
    @Input() id!: string;
    @Input() chartTitle: string = '';
    @Input() xaxisTitle!: string;
    @Input() yaxisTitle!: string;

    private _util: RetiredChartUtility = new RetiredChartUtility();
    private _chart!: Chart ;
    public start!: Date;
    public end!: Date;
    private filters: IRetirementCreditFilter[] = [];

    private filterChartDebounce: Subject<void> = new Subject<void>();
    private _data: any;

    constructor(private readonly retiredCreditsService: RetiredCreditsService,
        private readonly exportService: ExcelExportService) {
        super();
    }

    public async ngOnInit(): Promise<void> {
        await super.ngOnInit();
       
        this.filterChartDebounce.pipe(
          debounceTime(200)
        ).subscribe(() => {
          this.filterChart();
        });
        
        this.loadChart();
    }

    ngOnDestroy(): void {
      if (this._chart) {
        this._chart.destroy();
      }

      this.filterChartDebounce.unsubscribe();
    }

    onStartDateChanged(date: Date) {
      this.start = date;
      this.filterChartDebounce.next();
    }
    
    onEndDateChanged(date: Date) {
      this.end = date;
      this.filterChartDebounce.next();
    }    

    onFiltersChanged(filters: IRetirementCreditFilter[]) {
      this.filters = filters;
      this.filterChartDebounce.next();
    }

    onExportFired(event: any) {
        if (this._data) {
            let flatten: any[] = this._data.flatMap(item =>
                item.credits.map(credit => ({
                    issuedYear: credit.issuedYear,
                    retiredYear: credit.retiredYear,
                    quantity: credit.quantity
                }))
            );
            const properties = Object.keys(flatten[0]);
            this.exportService.export(flatten, 'retired-by-issuance', properties);
        }
      
    }

    private loadChart(): void {
        this.isLoading = true;
        this.createChart();
        this.retiredCreditsService.getRetiredIssuances(this.start, this.end).subscribe(response => {
            this._data = response;
            this.plotCreditsByIssuance(response, this._chart);
            this.isLoading = false;
        });
    }

    private createChart() {
      if (this._chart) {
        this._chart.destroy();
      }
      this._chart = this._util.createChart(this.yaxisTitle, this.xaxisTitle, this.chartTitle, this.id);
    }

    private plotCreditsByIssuance(response: IIssuanceCreditInfo[], chart: Chart) {
        let dataSets: ChartDataset[] = [];
        const issuedYears = new Set([...response.map(element => element.issuedYear)
            .sort((a, b) => a - b)]);
        const labels = Array.from(issuedYears);
        const min = Math.min(...labels);
        const max = Math.max(...labels);
        response
            .filter(element => element.issuedYear !== undefined && element.issuedYear !== null)
            .sort((a, b) => a.issuedYear - b.issuedYear)
            .forEach(creditInfo => {
                dataSets.push({
                    label: creditInfo.issuedYear.toString(),
                    backgroundColor: this._util.getColor(creditInfo.issuedYear, [min, max]),
                    order: creditInfo.issuedYear,
                    data: this.mapIssuanceData(creditInfo, issuedYears),
                    datalabels: {
                        display: (context) => {
                            try {

                                if (this._util.NotVisible(context)) {
                                    return false;
                                }
                                const currentBar: ChartMeta = context.chart.getDatasetMeta(context.datasetIndex);
                                const latestOrder = Math.max(...context.chart.data.datasets.map(ds => ds.order ?? 0));
                                if (currentBar.order == latestOrder) {

                                    return true;
                                }
                                return this._util.checkHeight(context, this._util.fontSize, this._util.defaultBarWidth);

                            }
                            catch (e) {
                                console.error(e);
                                return true;
                            }
                        },
                        labels: {
                            totals: {
                                color: 'black',
                                formatter: function (value, context) {
                                    let year = value.x;

                                    let total = response.flatMap(element => element.credits)
                                        .filter(element => element.retiredYear == year)
                                        .reduce((sum, current) => sum + current.quantity, 0);


                                    return context.datasetIndex == labels.length - 1 ? total.toLocaleString() : null;
                                },
                                align: 'end',
                                anchor: 'end',
                                font: {
                                    weight: "bold",
                                    family: "GothamNarrowBold, Arial, sans- serif"
                                }
                            },
                            value: {
                                color: 'white',
                                align: 'center',
                                anchor: 'center',
                                formatter: function (value) {
                                    return value.y > 0 ? value.y.toLocaleString() : null;
                                }
                            }
                        }
                    }

                });
            });
        chart.data.labels = labels;
        chart.data.datasets = dataSets;
        chart.update();
    }
    
    private mapIssuanceData(info: IIssuanceCreditInfo, issuedYears: Set<number>): Point[] {
        const points: any[] = [];

        //1. determine which data points we are missing from our set.
        //2. generate place holder points
        //3. merge place holder points with our normal points
        //4. sort everything before returning
        const exists = new Set([...info.credits.map(element => element.retiredYear)]);
        const missing = Array.from(issuedYears).filter(element => !exists.has(element));
        missing.forEach(m => points.push({ x: m, y: 0 }));
        info.credits.forEach(element => points.push({ x: element.retiredYear, y: element.quantity }));

        return points.sort((a, b) => a.x - b.x);
    }

    public filterChart() {
      this.isLoading = true;
      this.createChart();
      this.retiredCreditsService.filterRetiredIssuances(this.start, this.end, this.filters)
          .subscribe(response => {
            this._data = response;
            this.plotCreditsByIssuance(response, this._chart);
            this.isLoading = false;
      });
    }
}