import {Component, Injectable, InjectionToken} from '@angular/core';
import {SelectionModel} from '@angular/cdk/collections';
import {FlatTreeControl} from '@angular/cdk/tree';
import {MatTreeFlattener, MatTreeFlatDataSource} from '@angular/material/tree';
import {of as ofObservable, Observable, BehaviorSubject, Subject} from 'rxjs';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { TreeChecklistDataService } from '~/services/shared/tree-checklist-data.service';

/**
 * Node for to-do item
 */
export interface TodoItemNode {
  children: TodoItemNode[];
  item: string;
}

/** Flat to-do item node with expandable and level information */
export class TodoItemFlatNode {
  constructor(item: string = '', level: number = 0, expandable: boolean = false, isExpanded = false, allComplete = false) {
    this.item = item;
    this.level = level;
    this.expandable = expandable;
    this.isExpanded = isExpanded;
    this.allComplete = allComplete;
  }

  item: string;
  level: number;
  expandable: boolean;
  isExpanded: boolean;
  allComplete: boolean | undefined;
}

export const TREE_DATA_TOKEN = 
      new InjectionToken("TREE_DATA_TOKEN");
      
/**
 * Checklist database, it can build a tree structured Json object.
 * Each node in Json object represents a to-do item or a category.
 * If a node is a category, it has children items and new items can be added under the category.
 */
@Injectable()
export class ChecklistDatabase {
  dataChange: BehaviorSubject<TodoItemNode[]> = new BehaviorSubject<TodoItemNode[]>([]);

  get data(): TodoItemNode[] { return this.dataChange.value; }

  constructor(treeDataService: TreeChecklistDataService) {
    this.initialize(treeDataService.getTreeDataService().getTreeData());
  }

  initialize(treeData: object) {
    // Build the tree nodes from Json object. The result is a list of `TodoItemNode` with nested
    //     file node as children.
    const data = this.buildFileTree(treeData, 0);

    // Notify the change.
    this.dataChange.next(data);
  }

  /**
   * Build the file structure tree. The `value` is the Json object, or a sub-tree of a Json object.
   * The return value is the list of `TodoItemNode`.
   */
  buildFileTree(value: any, level: number) {
    let data: any[] = [];
    for (let k in value) {
      let v = value[k];
      let node: TodoItemNode = {
        children: [],
        item: "",
      };      
      node.item = `${k}`;
      if (v === null || v === undefined) {
        // no action
      } else if (typeof v === 'object') {
        node.children = this.buildFileTree(v, level + 1);
      } else {
        node.item = v;
      }
      data.push(node);
    }
    return data;
  }
}

/**
 * @title Tree with checkboxes
 */
@Component({
  selector: 'ccms-tree-checklist',
  templateUrl: 'tree-checklist.component.html',
  styleUrls: ['tree-checklist.component.scss'],
  providers: [ChecklistDatabase]
})
export class TreeChecklistComponent {
  toggleExpanded: Subject<TodoItemFlatNode> = new Subject<TodoItemFlatNode>();

  /** Map from flat node to nested node. This helps us finding the nested node to be modified */
  flatNodeMap: Map<TodoItemFlatNode, TodoItemNode> = new Map<TodoItemFlatNode, TodoItemNode>();

  /** Map from nested node to flattened node. This helps us to keep the same object for selection */
  nestedNodeMap: Map<TodoItemNode, TodoItemFlatNode> = new Map<TodoItemNode, TodoItemFlatNode>();

  /** A selected parent node to be inserted */
  selectedParent: TodoItemFlatNode | null = null;

  /** The new item's name */
  newItemName: string = '';

  treeControl: FlatTreeControl<TodoItemFlatNode>;

  treeFlattener: MatTreeFlattener<TodoItemNode, TodoItemFlatNode>;

  dataSource: MatTreeFlatDataSource<TodoItemNode, TodoItemFlatNode>;

  /** The selection for checklist */
  checklistSelection = new SelectionModel<TodoItemFlatNode>(true /* multiple */);

  constructor(private database: ChecklistDatabase) {
    this.treeFlattener = new MatTreeFlattener(this.transformer, this.getLevel,
      this.isExpandable, this.getChildren);
    this.treeControl = new FlatTreeControl<TodoItemFlatNode>(this.getLevel, this.isExpandable);
    this.dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);

    this.database.dataChange.subscribe(data => {
      this.dataSource.data = data;
    });
  }

  getLevel = (node: TodoItemFlatNode) => { return node.level; };

  isExpandable = (node: TodoItemFlatNode) => { return node.expandable; };

  getChildren = (node: TodoItemNode): Observable<TodoItemNode[]> => {
    return ofObservable(node.children);
  }

  hasChild = (_: number, _nodeData: TodoItemFlatNode) => { return _nodeData.expandable; };

  hasNoContent = (_: number, _nodeData: TodoItemFlatNode) => { return _nodeData.item === ''; };

  /**
   * Transformer to convert nested node to flat node. Record the nodes in maps for later use.
   */
  transformer = (node: TodoItemNode, level: number) => {
    let flatNode = this.nestedNodeMap.has(node) && this.nestedNodeMap.get(node)!.item === node.item
      ? this.nestedNodeMap.get(node)!
      : new TodoItemFlatNode();
    flatNode.item = node.item;
    flatNode.level = level;
    flatNode.expandable = node.children && node.children.length > 0;
    this.flatNodeMap.set(flatNode, node);
    this.nestedNodeMap.set(node, flatNode);
    return flatNode;
  }

  getCompleteForNode(node: TodoItemFlatNode): boolean {
    if (!node.allComplete) {
      return false;
    }
    return node.allComplete;
  }

  /** Whether all the descendants of the node are selected */
  descendantsAllSelected(node: TodoItemFlatNode): boolean {
    const descendants = this.treeControl.getDescendants(node);
    return descendants.every(child => this.checklistSelection.isSelected(child));
  }

  /** Whether part of the descendants are selected */
  descendantsPartiallySelected(node: TodoItemFlatNode): boolean {
    const descendants = this.treeControl.getDescendants(node);
    return descendants.some(child => this.checklistSelection.isSelected(child));
  }

  getIsIndeterminate(node: TodoItemFlatNode): boolean {
    const descendants = this.treeControl.getDescendants(node);
    const result = descendants.some(child => this.checklistSelection.isSelected(child));
    return result && !node.allComplete;
  }

  toggleNestedNode(node: TodoItemFlatNode) {
    this.checklistSelection.toggle(node);

    const parent = this.findNodeByItem(node.item);
    if (parent) {
      parent.allComplete = this.childrenAllComplete(parent);
    }
  }

  branchSelectionToggle(event: MatCheckboxChange | null, node: TodoItemFlatNode): void {
    if (node.level > 0 || !event) {
      this.checklistSelection.toggle(node);
      return;
    }
    this.checklistSelection.toggle(node);
    const isSelected = event.checked;
    const descendants = this.treeControl.getDescendants(node);
    if (isSelected) {
      this.checklistSelection.select(...descendants);
    } else {
      this.checklistSelection.deselect(...descendants);
    }
    node.allComplete = isSelected;
  }

  branchSelectionToggleExternal(item: string): void {
    const realNode = this.getActualNode(item);
    if (realNode) {
      this.branchSelectionToggle(null, realNode);
    }
  }

  initBranches(): void {
    this.treeControl.dataNodes.forEach(x => {
      x.allComplete = this.childrenAllComplete(x);
    });
  }

  expandableClicked(event: any, node: TodoItemFlatNode): void {
    node.isExpanded = this.treeControl.isExpanded(node);

    this.toggleExpanded.next(node);
  }

  toggleExpandNode(node: TodoItemFlatNode): void {
    const realNode = this.getActualNode(node.item);
    if (realNode && node.isExpanded) {
      this.treeControl.expand(realNode);
    } else if (realNode) {
      this.treeControl.collapse(realNode);
    }
  }

  private childrenAllComplete(node: TodoItemFlatNode): boolean {
    const descendants = this.treeControl.getDescendants(node);
    return descendants.every(child => this.checklistSelection.isSelected(child));
  }

  public getActualNodes(): TodoItemFlatNode[] {
    let nodes: TodoItemFlatNode[] = [];
    this.nestedNodeMap.forEach(node => {
      nodes.push(node);
    });

    return nodes;
  }

  private getActualNode(name: string): TodoItemFlatNode | null {
    let actual: TodoItemFlatNode | null = null;
    this.nestedNodeMap.forEach(key => {
      if (key.item === name) {
        actual = key;
      }
    });

    return actual;
  }

  private findNodeByItem(searchItem: string): TodoItemFlatNode | undefined {
    for (let node of this.dataSource.data) {
      for (let child of node.children) {
        if (child.item === searchItem) {
          return this.nestedNodeMap.get(node);
        }
      }
    }
    return undefined;
  }
}