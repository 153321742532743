<div class="content">
    <div class="nav-container">
       <ccms-powerbi-navigator 
                   class="navigator" 
                   [reportName]="'signposts'" 
                   [title]="title"
                   [selectedId]="reportId"
                   (selectedValueChanged)="selectedValueChanged($event)">
       </ccms-powerbi-navigator>
    </div>
    <div class="info">
        <ccms-powerbi-viewer class="viewer" [reportId]="this.reportId"></ccms-powerbi-viewer>
    </div>
</div>