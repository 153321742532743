import { Injectable } from "@angular/core";
import { HttpService } from "~/services/core/http.service";
import { UserSetting } from "~/models/shared/user-settings/user-setting";
import { from, Observable } from "rxjs";

@Injectable()
export class UserSettingsService {

    private _baseServiceUri = "/user-settings";

    constructor(private readonly httpService: HttpService){ 
    }

    public GetSettings(settingName: string): Observable<UserSetting[]> {
        let queryString = `names=${settingName}`;
        return from(this.httpService.Get(`${this._baseServiceUri}?${queryString}`))
    }

    public SaveSetting(setting: UserSetting): Observable<void> {
        return from(this.httpService.Put(this._baseServiceUri, setting));
    }
}