import { Component, TemplateRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';

import { Filters, FilterType, SDKDataGridColumn, SDKDataGridCustomFilter, SDKDataGridDataset, SDKDataGridOptions, SDKDataGridSettings } from 'sdk-datagrid';

import { BaseComponent } from '~/pages/base.component';

import { MetaRegistryService } from '~/services/shared/metaregistry.service';
import { FormatterService } from '~/services/shared/formatter.service';

import { RequestParameters } from '~/models/shared/request-parameters';

import { ExportWindowComponent } from './export/window/export-window.component';
import { arraysAreEqual, getGridPager, setGridPager } from '~/services/shared/state/grid/grid-pager.repository';
import { GridName } from '~/services/shared/state/grid/grid-name.enum';
import { firstValueFrom } from 'rxjs';
import { RouteChangedService } from '~/services/shared/route-changed.service';
import { ExportOptionComponent } from '~/components/shared/export-option/export-option.component';
import { Accreditation, MetaRegistry } from '~/models/shared/metaregistry';
import { FilterInfo } from '~/models/shared/filterInfo';
import { SettingsGrid } from '~/services/shared/grid/models/settings-grid.model';
import { GridHandlerService } from '~/services/shared/grid/grid-handler.service';

@Component({
    selector: 'metaregistry',
    templateUrl: './metaregistry.component.html',
    styleUrls: ['./metaregistry.component.scss']
})

export class MetaRegistryComponent extends BaseComponent implements SettingsGrid {
    @ViewChild('registryTemplate') registryTemplate!: TemplateRef<any>;
    @ViewChild('websiteTemplate') websiteTemplate!: TemplateRef<any>;
    @ViewChild('programsTemplate') programsTemplate!: TemplateRef<any>;
    @ViewChild('accreditationsTemplate') accreditationsTemplate!: TemplateRef<any>;
    @ViewChild('sylveraRating') sylveraRatingTemplate!: TemplateRef<any>;

    public title: string = "Meta Registry Projects";
    public footnote: string = "";
    public MAXRECORDS: number = 100;
    public activeView: string = "MetaRegistry/Search";
    public datasets: SDKDataGridDataset[] = [
        {
            Title: "MetaRegistry",
            DbName: "MetaRegistry/Search",
        }
    ];
    public data: string = "";
    private _filterTypes: any = [Filters.Equals, Filters.NotEquals, Filters.Contains, Filters.GreaterThanOrEqual];
    public columns: SDKDataGridColumn[] = [
        { Name: "id", DisplayName: "ID", FilterTypes: this._filterTypes },
        { Name: "name", DisplayName: "Name", isVisible: true, FilterTypes: this._filterTypes },
        { Name: "registry", DisplayName: "Registry", FilterMultiSelect: true, FilterValues: [], isVisible: true, dataTemplate: () => this.registryTemplate, FilterTypes: this._filterTypes },
        { Name: "status", DisplayName: "Status", FilterMultiSelect: true, FilterValues: [], isVisible: true, FilterTypes: this._filterTypes },
        { Name: "scope", DisplayName: "Scope", FilterMultiSelect: true, FilterValues: [], isVisible: true, FilterTypes: this._filterTypes },
        { Name: "developer", DisplayName: "Developer", isVisible: true, FilterTypes: this._filterTypes },
        { Name: "programs", DisplayName: "Program Eligibility", dataTemplate: () => this.programsTemplate, FilterTypes: this._filterTypes },
        
        { Name: "accreditations", DisplayName: "Certifications", dataTemplate: () => this.accreditationsTemplate, FilterTypes: this._filterTypes },  
        { Name: "type", DisplayName: "Type", FilterMultiSelect: true, FilterValues: [], FilterTypes: this._filterTypes },
        { Name: "protocol", DisplayName: "Protocol", FilterTypes: this._filterTypes },
        { Name: "region", DisplayName: "Region", FilterTypes: this._filterTypes },
        { Name: "country", DisplayName: "Country", FilterMultiSelect: true, FilterValues: [], FilterTypes: this._filterTypes },
        { Name: "state", DisplayName: "State", FilterTypes: this._filterTypes },
        { Name: "siteLocation", DisplayName: "Site Location", FilterTypes: this._filterTypes },
        { Name: "creditsIssued", DisplayName: "Credits Issued", formatter: (value: any) => this.formatterService.formatNumber(value), FilterTypes: this._filterTypes },
        { Name: "creditsRetired", DisplayName: "Credits Retired", formatter: (value: any) => this.formatterService.formatNumber(value), FilterTypes: this._filterTypes },
        { Name: "creditsRemaining", DisplayName: "Credits Remaining", isVisible: true, formatter: (value: any) => this.formatterService.formatNumber(value), FilterTypes: this._filterTypes },
        { Name: "firstYear", DisplayName: "First Year", FilterTypes: this._filterTypes },
        { Name: "owner", DisplayName: "Owner", FilterTypes: this._filterTypes },
        { Name: "operator", DisplayName: "Operator", FilterTypes: this._filterTypes },
        { Name: "designee", DisplayName: "Designee", FilterTypes: this._filterTypes },
        { Name: "verifier", DisplayName: "Verifier", FilterTypes: this._filterTypes },
        { Name: "estimatedAnnualReductions", DisplayName: "Estimated Annual Reductions", formatter: (value: any) => this.formatterService.formatNumber(value), FilterTypes: this._filterTypes },
        { Name: "peRs", DisplayName: "PERs", FilterTypes: this._filterTypes },
        { Name: "arbId", DisplayName: "ARB Id", FilterTypes: this._filterTypes },
        { Name: "ccbCertifications", DisplayName: "CCB Certifications", FilterTypes: this._filterTypes },
        { Name: "projectWebsite", DisplayName: "Project Website", dataTemplate: () => this.websiteTemplate, FilterTypes: this._filterTypes },
        { Name: "beZeroRating", DisplayName: "BeZero Rating", isVisible: true, FilterTypes: this._filterTypes },
        { Name: "sylveraRating", DisplayName: "Sylvera Rating", isVisible: true, dataTemplate: () => this.sylveraRatingTemplate, FilterTypes: this._filterTypes },
        { Name: "calyxGhgRating", DisplayName: "Calyx GHG Rating", isVisible: true, FilterTypes: [Filters.Equals, Filters.NotEquals, Filters.Contains, Filters.NotContains] },
        { Name: "sustainableDevelopmentGoalsCount", DisplayName: "SDG(s)", isVisible: true, FilterTypes: this._filterTypes },
        { Name: "directPriceEstimate", DisplayName: "Price Estimate", isVisible: true, formatter: (value: any) => this.formatDirectPriceEstimate(value), FilterTypes: this._filterTypes },    
        { Name: "hasMap", DisplayName: "Has Map", isVisible: true, FilterTypes: this._filterTypes },
        { Name: "hasUncuratedMap", DisplayName: "Has Uncurated Map", isVisible: true, FilterTypes: this._filterTypes },
        { Name: "article6Ready", DisplayName: "Article 6 Ready", isVisible: true, FilterTypes: [Filters.Equals] },
        { Name: "article6Aligned", DisplayName: "Article 6 Aligned", isVisible: true, FilterTypes: [Filters.Equals] },
        { Name: "article64Registered", DisplayName: "Article 6.4 Registered", isVisible: true, FilterTypes: [Filters.Equals] },
        { Name: "article64TransitionRequested", DisplayName: "Article 6.4 Transition Requested", isVisible: true, FilterTypes: [Filters.Equals, Filters.NotEquals ] }
    ];    

    // Settings grid interface Implementation:
    public uniqueIdentifier = "metaregistry.metaregistry";
    public allSettings: SDKDataGridSettings[] = [];
    public multiSelectFilters: FilterInfo[] = [];
    public errorHanlder = (message, error) => {
        console.error(`${message} ${error?.message}`);
    }

    public gridOptions: SDKDataGridOptions = {
        settings: true,
        columnSettings: true,
        filtering: true,
        sorting: true,
        formulas: false,
        charts: false,
        export: false,
        expandableRows: false,
        selectableRows: false,
        autoClosePanel: true
    };
   

    public customFilters: SDKDataGridCustomFilter[] = [
        { Name: "*keyword", DisplayName: "* Keyword Search", Type: FilterType.Textbox, FilterTypes: [Filters.Contains, Filters.Equals, Filters.NotEquals] },
        { Name: "*any", DisplayName: "Any Rating", Type: FilterType.Checkbox },
    ]
    
    public fullData: string = "";
    public dbPage: number = 0;
    public dbTotal: number = 0;
    public error: string = "";

    public exportOptionComponent = ExportOptionComponent;
    public exportWindowComponent = ExportWindowComponent;

    constructor(
        private router: Router,
        private metaRegistryService: MetaRegistryService,
        private formatterService: FormatterService,
        private gridHandlerService: GridHandlerService,
        private routeChangedService: RouteChangedService
    ) {
        super();
    }

    //*************************************************************************
    //  Component Life-Cycle Methods
    //*************************************************************************
    public async ngOnInit() {
        await super.ngOnInit();
        await this.loadMultiSelectFilters();
        this.gridHandlerService.loadSettings(this);
    }

    //*************************************************************************
    //  Public Methods
    //*************************************************************************
    public async loadData(event: any = null) {
        this.isLoading = true;
        this.MAXRECORDS = event.rows; // Overrides total records due to slowness in API.

        let page = await this.getCurrentPageFromSettings(event);
        let parameters: RequestParameters = this.buildParameters(event, page);
        let fullLoad: boolean = false;

        if (event && (event.chart !== undefined || event.export !== undefined)) {
            fullLoad = true;

            parameters.Paging = {
                pageSize: 999999,
                currentPage: 1
            };
        }

        try {
            const data = await this.metaRegistryService.getRegistry(parameters);

            if (data) {

                if (this.multiSelectFilters.length == 0) {
                    await this.loadMultiSelectFilters();
                }
                this.setMultiSelectFilters();

                if (fullLoad) {
                    this.fullData = data.values;
                } else {
                    this.resetVariables();

                    this.data = data.values;

                    this.dbPage = parseInt(data.metadata.currentPage);
                    this.dbTotal = parseInt(data.metadata.totalCount);
                    this.error = "";

                    setGridPager(GridName.MetaRegistry, data.metadata.currentPage, event?.rows, event?.filters);           
                }

                this.footnote = `Meta Registry as of ${this.formatterService.formatDate(new Date(), "dd MMM yyyy hh:mm:ss (z)")}`;
            }
        } catch (error: any) {
            this.error = error.message;
        }

        this.isLoading = false;
    }

    public showDetail(record: any) {
        if (record) {
            this.router.navigate(["/metaregistry/detail"], { state: { projectId: record.data.id } });
        }
    }

    public getAccreditations(accreditations: Accreditation[]): string[] {
      const list: string[] = [];
      accreditations.forEach(accreditation => {
        list.push(this.getAccreditation(accreditation))
      });

      return list;
    }

    public getAccreditation(accreditation: Accreditation): string {
      if (accreditation.distinctions.length > 0) {
        return accreditation.id + " (" + this.getDistinctionNames(accreditation) + ")";
      } else {
        return accreditation.id;
      }
    }

    public getDistinctionNames(accreditation: Accreditation): string {
      return accreditation.distinctions.map(distinction => distinction.id).join(', ');
    }

    public getTotalEligibility(record: MetaRegistry): string[] {
      const seen = new Set<string>();
  
      if (record.programs.length > 0) {
        record.programs.forEach(program => {
          seen.add(program.id);  
        });
      }
      for (const credit of record.creditsEligibility) {
        if (!seen.has(credit)) {
          seen.add(credit);
        }
      }
      
      return [...seen];
    }

    public formatDirectPriceEstimate(value: number): string {
      if (value === 0)
          return '';
      else
          return this.formatterService.formatCurrency(value);
    }

    public saveSettings(event: SDKDataGridSettings[]) {
        this.gridHandlerService.saveSettings(this, event);
    }

    //*************************************************************************
    //  Private Methods
    //*************************************************************************    
    private async getCurrentPageFromSettings(event: any = null) {
      const pagerSettings = await firstValueFrom(getGridPager(GridName.MetaRegistry));
      if (pagerSettings) {
        if (arraysAreEqual(pagerSettings.filters, event?.filters) && pagerSettings.pageSize === event?.rows) {
          return pagerSettings.pageIndex;
        } 
      }

      return 1;
    }

    private buildParameters(event: any, page: number | null): any {
        let parameters: RequestParameters = new RequestParameters();

        parameters.Paging = {
            pageSize: this.MAXRECORDS,
            currentPage: 1
        };

        if (event) {
            this.buildParametersPage(event, page, parameters);
            this.buildParametersRows(event, parameters);
            this.metaRegistryService.buildParametersSorts(event.sorts, parameters);
            this.metaRegistryService.buildParametersFilters(event.filters, parameters);
        }

        return parameters;
    }

    private buildParametersPage(event: any, page: number | null, parameters: any) {
        if ((page && page > 0) && parseInt(event.page) === 1 && this.routeChangedService.getRouteChanged()) {
          parameters.Paging.currentPage = page;
          this.routeChangedService.clearRouteChange();
        } else if (event.page) {
            parameters.Paging.currentPage = parseInt(event.page);
        }
    }

    private buildParametersRows(event: any, parameters: any) {
        parameters.Paging.pageSize = this.MAXRECORDS;
    }

    private resetVariables() {
        this.data = "";
        this.fullData = "";
        this.dbPage = 0;
        this.dbTotal = 0;
        this.error = "";
    }

    private async loadMultiSelectFilters() {
        try {
            const multiSelectColumns: any[] = this.columns.filter(column => column.FilterMultiSelect);
            this.multiSelectFilters = await this.metaRegistryService.getFilters(multiSelectColumns.map(column => column.Name));
        } catch (error: any) {
            console.error("Error loading filters: " + error.message);
        } 
    }

    private setMultiSelectFilters() {
        const multiSelectColumns: any[] = this.columns.filter(column => column.FilterMultiSelect);

        multiSelectColumns?.forEach((multiSelectColumn) => {
            const filter = this.multiSelectFilters.find(filterInfo => filterInfo.fieldName.toLowerCase() === multiSelectColumn.Name.toLowerCase());
            const filterValues = filter?.filterValues;
            multiSelectColumn.FilterValues.length = 0;
            filterValues?.forEach(value => multiSelectColumn.FilterValues.push(value));
        });
    }
}
