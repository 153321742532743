import { Injectable } from "@angular/core";
import { from, lastValueFrom, map, Observable, shareReplay } from "rxjs";
import { Article6Mou } from "~/models/shared/policy-tracker/article6-mou";
import { Icap } from "~/models/shared/policy-tracker/icap";
import { PolicyTracker } from "~/models/shared/policy-tracker/policy-tracker";
import { PolicyTrackerQuery } from "~/models/shared/policy-tracker/policy-tracker-query.model";
import { HttpService } from "~/services/core/http.service";

@Injectable()
export class PolicyTrackerService {
  constructor(private httpService: HttpService) { }
    
  private icapCache$: Observable<Icap[]> | undefined;
  private marketsCache$: Observable<PolicyTracker[]> | undefined;
  private article6MousCache$: Observable<Article6Mou[]> | undefined;

  private getIcapAll(): Observable<Icap[]> {  
    if (!this.icapCache$) {
      this.icapCache$ = this.requestListIcap().pipe(
        shareReplay(1)
      );
    }
    
    return this.icapCache$;
  }

  private requestListIcap(): Observable<Icap[]> {
    const resourceUri = `/PolicyTracker/icap`;
    return from(this.httpService.Get(resourceUri));
  }

  public async getIcap(): Promise<Icap[]> {
    return  await lastValueFrom(this.getIcapAll().pipe(map(x => x)));
  }

  public async getIcapById(id: number): Promise<Icap> {
    const list = await lastValueFrom(this.getIcapAll().pipe(map(x => x)));

    const foundIcap = list.find(item => item.id === id);

    if (!foundIcap) {
      throw new Error(`Icap with id ${id} not found.`);
    } 

    return foundIcap;
  }

  public async getIcapByCountryId(id: number): Promise<Icap> {
    const list = await lastValueFrom(this.getIcapAll().pipe(map(x => x)));

    const foundIcap = list.find(item => item.country.id === id);

    if (!foundIcap) {
      throw new Error(`Icap with country.id ${id} not found.`);
    } 

    return foundIcap;
  }

  private requestListPolicyTrackerMarkets() {
    const resourceUri = `/PolicyTracker/markets`;
    return from(this.httpService.Get(resourceUri));
  }

  private getPolicyTrackerMarketsAll(): Observable<PolicyTracker[]> {  
    if (!this.marketsCache$) {
      this.marketsCache$ = this.requestListPolicyTrackerMarkets().pipe(
        shareReplay(1)
      );
    }
    
    return this.marketsCache$;
  }

  public async getPolicyTrackerMarkets(): Promise<PolicyTracker[]> {
    return  await lastValueFrom(this.getPolicyTrackerMarketsAll().pipe(map(x => x)));
  }

  public async getPolicyTrackerByName(name: string): Promise<PolicyTracker> {
    const list = await lastValueFrom(this.getPolicyTrackerMarketsAll().pipe(map(x => x)));

    const found = list.find(item => item.name === name);

    if (!found) {
      throw new Error(`Icap with id ${name} not found.`);
    } 

    return found;
  }
  
  public async getPolicyTrackersByCountry(countryId: number): Promise<PolicyTracker[]> {
    const list = await lastValueFrom(this.getPolicyTrackerMarketsAll().pipe(map(x => x)));

    const found = list.filter(item => item.country.id === countryId);

    return found;
  }

  public async queryPolicyTrackerMarkets(query: PolicyTrackerQuery): Promise<PolicyTracker[]> {
    const list = await lastValueFrom(this.getPolicyTrackerMarketsAll().pipe(map(x => x)));

    const filtered = list.filter((policyTracker) =>
      query.marketTypes.some((marketType) => marketType.id === policyTracker.marketType.id) &&
      query.marketStatuses.some((marketStatus) => marketStatus.id === policyTracker.marketStatus.id) &&
      query.jurisdictionType.some((jurisdictionType) => jurisdictionType.id === policyTracker.jurisdictionType.id)
    );

    return filtered;
  }

  public async queryPolicyTrackerMarketByName(name: string): Promise<PolicyTracker> {
    const list = await lastValueFrom(this.getPolicyTrackerMarketsAll().pipe(map(x => x)));

    const filtered = list.filter(policyTracker => policyTracker.name == name);

    return filtered[0];
  }

  private requestListArticle6Mous() {
    const resourceUri = `/PolicyTracker/article6mous`;
    return from(this.httpService.Get(resourceUri));
  }

  private getListArticle6MouAll(): Observable<Article6Mou[]> {  
    if (!this.article6MousCache$) {
      this.article6MousCache$ = this.requestListArticle6Mous().pipe(
        shareReplay(1)
      );
    }
    
    return this.article6MousCache$;
  }

  public async getArticle6MouAll(): Promise<Article6Mou[]> {
    return await lastValueFrom(this.getListArticle6MouAll().pipe(map(x => x)));
  }

  public async getArticle6MousByCountry(countryId: number): Promise<Article6Mou[]> {
    const mous = await lastValueFrom(this.getListArticle6MouAll().pipe(map(x => x)));

    const attached: Article6Mou[] = [];
    mous.forEach(m => {
      if ((m.mouCountry && m.mouCountry.id == countryId) || m.country.id == countryId) {
        attached.push(m);
      }
    });

    return attached;
  }

  public async getArticle6MouMap(): Promise<Map<number, Article6Mou[]>> {
    const article6MouMap = new Map<number, Article6Mou[]>();

    const mous = await this.getArticle6MouAll();
    mous.forEach((mou: Article6Mou) => {
      // Process the 'country' field
      const countryKey = mou.country.id;
    
      if (!article6MouMap.has(countryKey)) {
        article6MouMap.set(countryKey, []);
      }
    
      article6MouMap.get(countryKey)?.push(mou);
    
      // Process the 'mouCountry' field
      if (mou.mouCountry) {
      const mouCountryKey = mou.mouCountry.id;
    
        if (countryKey !== mouCountryKey) {
          if (!article6MouMap.has(mouCountryKey)) {
            article6MouMap.set(mouCountryKey, []);
          }
    
          article6MouMap.get(mouCountryKey)?.push(mou);
        }
      }
    });

    return article6MouMap;
  }
}