import { PipeTransform } from "@angular/core";

import { ICvxProject, ICvxProjectPipe } from "~/models/shared/cvxproject";

export class PipeCvxProject implements PipeTransform {
    public transform(project: ICvxProject): ICvxProjectPipe {
        let investmentType = project.investmentType;

        return {
            name: project.name,
            cvxProjectType: project.cvxProjectType,
            description: project.description,
            location: project.location,
            site: project.site,
            cvxProjectDeveloper: project.cvxProjectDeveloper,
            operator: project.operator || null,
            status: project.status.name || null,
            didNotPursueReasonId: project.didNotPursueReason?.id,
            didNotPursueReasonOther: project.didNotPursueReasonOther,
            totalCreditsForecast: project.totalCreditsForecast,
            executionStartDate: project.executionStartDate,
            ndaExecutionDate: project.ndaExecutionDate,
            firstYearOfIssuance: project.firstYearOfIssuance || null,
            fundingOrSpending: project.fundingOrSpending,
            contactId: project.contactId,
            contact: project.contact || null,
            verifier: project.verifier || null,
            investmentType: investmentType?.name || null,
            methodologyId: project.methodologyId,
            metaregistryProjectId: project.metaRegistryProjectId,
            isArticle6Eligible: project.isArticle6Eligible,
            isAvoidance: project.isAvoidance,
            isRemoval: project.isRemoval,
            sustainableDevelopmentGoalInputs: project.sustainableDevelopmentGoals,
            owners: project.owners,
            barriers: project.barriers,
            priority: project.priority,
            legalLeadId: project.legalLeadId,
            legalLead: project.legalLead,
            originatorId: project.originatorId,
            originator: project.originator,
            projectStatusNotes: project.projectStatusNotes,
            notes: project.notes,
            product: project.product,
            termSheetLeadId: project?.termSheetLeadId,
            termSheetLead: project?.termSheetLead,
            negotiatorId: project?.negotiatorId,
            negotiator: project?.negotiator,
            contractedLifetimeVolume: project?.contractedLifetimeVolume,
            maximumAccessLifetimeVolume: project?.maximumAccessLifetimeVolume,
            projectLife: project?.projectLife,
            technicalLeadId: project?.technicalLeadId,
            technicalLead: project?.technicalLead,
            technicalReviewNotes: project?.technicalReviewNotes,
            technicalReviewCompletionDate: project?.technicalReviewCompletionDate,
            technicalReviewStatus: project?.technicalReviewStatus,
            technicalReviewRecommendation: project?.technicalReviewRecommendation,
            eligibility: project?.eligibility,
            carbon: project?.carbon,
            integrity: project?.integrity,
            feasibility: project?.feasibility,
            isGlobalLocation: project.isGlobalLocation,
            externalTechResource: project.externalTechResource
            
        } as ICvxProjectPipe
    }
}