import { AbstractControl, FormControl, FormGroup, Validators } from "@angular/forms";

export class CreditForecastsImportForm {
  public form: FormGroup;

  constructor() {
    const fileTypes = this.requiredFileType(["xlsx", "xlsm"]);
    const controls = {
      formFile: new FormControl(null, [Validators.required, fileTypes]),
      sheet: new FormControl(1)
    }

    this.form = new FormGroup(controls);
  }

  requiredFileType(fileTypes: string[]) {
    return function (control: AbstractControl): {requiredFileType: boolean} | null {
      const file = control.value;
      if (file) {
        let match = false;
        const extension = file.split('.')[1]?.toLowerCase();
        fileTypes.forEach(type => {
          if (type.toLowerCase() === extension) {
            match = true;
          }  
        });

        if (!match) {
          return {
            requiredFileType: true
          };
        }
      }
  
      return null;
    };
  }
}