import { Component, EventEmitter, Input, OnInit, Output, Renderer2 } from '@angular/core';

import { SDKDataGridColumn } from 'sdk-datagrid';
import { DurableTaskResponse } from '~/models/shared/durable-task/durable-task-response.model';
import { AlertType } from '~/models/shared/errors/error-alert-type.enum';
import { DurableTaskRuntimeStatus } from '~/services/shared/durable-task/durable-task-runtime-status.constants';
import { DurableTaskService } from '~/services/shared/durable-task/durable-task.service';
import { MetaRegistryService } from '~/services/shared/metaregistry.service';
import { MetaRegistryExportCommon } from '../common/MetaRegistryExportCommon';
import { metaRegistryExportTask$, updateMetaRegistryExportTask } from '../state/meta-registry-export.repository';
import { ExportProgressStatus } from '../models/export-progress-status.model';
import CcmsServerError from '~/models/shared/errors/ccms-server-error';
import { RequestParameters } from '~/models/shared/request-parameters';
import { ExportMetaRegistryRequest } from '../models/export-metaregistry-request';
import { firstValueFrom } from 'rxjs';

@Component({
    selector: 'export-window',
    templateUrl: './export-window.component.html',
    styleUrls: ['./export-window.component.scss']
})
export class ExportWindowComponent implements OnInit {
    @Input() dataClass: string = ""; // Used to indicate if window is open/closed.
    @Input() optionTitle: string = ""; // Unique name for option.
    @Input() datasets: any[] = []; // Data array.
    @Input() columns: SDKDataGridColumn[] = []; // Columns defines for data.
    @Input() filters: any[] = []; // Filters defines for data.
    @Input() sorts: any[] = []; // Sorting defines for data.

    @Output() applyEvent = new EventEmitter<any>(); // Apply option.
    @Output() closeEvent = new EventEmitter<any>(); // Close window.

  public close() {
    this.closeEvent.emit();
  }

  public async apply() {
    this.applyEvent.emit();
    this.closeEvent.emit();
  }
    
  constructor(
    private readonly _metaRegistryService: MetaRegistryService,
    private readonly _durableTaskService: DurableTaskService,
    private renderer: Renderer2) {
  }
  
  isRunning; boolean = true;
  errorMessage!: string | null | undefined;
  excelUri!: string | null;
  timerId!: any;
  
  progress: number = 0;
  AT = AlertType;
  task!: DurableTaskResponse | null;
  status!: string; 
    
  async ngOnInit() {
    this.task = await firstValueFrom(metaRegistryExportTask$);
    if (this.task) {
      this.updateStatus();
      this.setTimer();
    } else {
      this.isRunning = false;
    }
  }
  
  onExport() {
    this.excelUri = null;
    this.progress = 0;
    this.isRunning = true;
    this.errorMessage = null;
    this.status = "Sending meta data export request";

    const exportRequest = new ExportMetaRegistryRequest();
    exportRequest.MetaRegistrySearchQuery = this.buildParameters();
    const names = this.columns.filter(column => column.isVisible).map(column => column.Name);
    exportRequest.SelectedColumns = names;

    this._metaRegistryService.exportToExcel(exportRequest).subscribe({
        next: (event) => {
          this.task = event;
          console.log(event);
          updateMetaRegistryExportTask(event);
          this.updateStatus();
          if (!this.timerId) {
            this.setTimer();
          }
        },
        error: (err) => {
          this.setError(null, err);
          this.isRunning = false;
        }
    });
  }

  private buildParameters(): any {
    let parameters: RequestParameters = new RequestParameters();

    this._metaRegistryService.buildParametersSorts(this.sorts, parameters);
    this._metaRegistryService.buildParametersFilters(this.filters, parameters);
    
    return parameters;
  }

  private updateStatus() {
    if (!this.task) {
      return;
    }
  
    this._durableTaskService.getTaskStatus(this.task).subscribe({
      next: (event) => this.handleTaskStatus(event),
      error: (err) => this.handleTaskError(err),
    });
  }
  
  private handleTaskStatus(event) {
    switch (event.runtimeStatus) {
      case DurableTaskRuntimeStatus.Pending:
        this.handlePendingStatus();
        break;
      case DurableTaskRuntimeStatus.Running:
        this.handleRunningStatus(event);
        break;
      case DurableTaskRuntimeStatus.Completed:
        this.handleCompletedStatus(event);
        break;
      case DurableTaskRuntimeStatus.Failed:
        this.handleFailedStatus(event);
        break;
      default:
        // handle default case
        break;
    }
  }
  
  private handlePendingStatus() {
    this.isRunning = true;
    this.status = "Export task is pending execution.";
  }
  
  private handleRunningStatus(event) {
    this.isRunning = true;
    const status = this.parseProgressEvent(event.customStatus);
    if (status) {
      if (status.progressType === "Query") {
        this.progress = status.progressPercentage * 100;
      }
      this.status = status.progressMessage;
      if (status.progressPercentage === 1 && status.progressType === "Excel") {
        this.status = "Stand by, export is completing now.";
      }
    }
  }
  
  private handleCompletedStatus(event) {
    this.isRunning = false;
    this.excelUri = event.output;
    this.progress = 1;
    this.status = "Export completed.";
    this.clearTimer();
    if (MetaRegistryExportCommon.hasExportExpired(event)) {
      updateMetaRegistryExportTask(null);
      this.excelUri = null;
    }
  }
  
  private handleFailedStatus(event) {
    this.isRunning = false;
    this.excelUri = null;
    this.setError(null, event.output);
    this.clearTimer();
  }
  
  private handleTaskError(err) {
    this.clearTimer();
    this.isRunning = false;
    this.setError(null, err);
    updateMetaRegistryExportTask(null);
    console.log(err);
  }
  
  parseProgressEvent(event: string): ExportProgressStatus | undefined {
    if (!event) {
      return;
    }
    const jsonObject = JSON.parse(event);

    const progress: ExportProgressStatus = jsonObject;
    return progress;
  }

  private clearTimer() {
    clearInterval(this.timerId);
    this.timerId = undefined;
  }

  private setTimer() {
    this.timerId = setInterval(this.updateStatus.bind(this), 2000);
  }

  private setError(error: CcmsServerError | null, message: any = null) {   
    this.errorMessage = error?.errors;

    this.errorMessage = message;
  }

  downloadFile() {
    const link = this.renderer.createElement('a');
    link.setAttribute('target', '_blank');
    link.setAttribute('href', this.excelUri);
    link.setAttribute('download', `MetaRegistryExport.csv`);
    link.click();
    link.remove();
 }
}
