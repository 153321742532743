<div class="content" [ngClass]="!expanded? 'collapsed' : ''">
    <div class="header">
        <div class="icon material-icons-outlined expandToggle"
             [title]="expanded? 'Hide Menu' : 'Show Menu'"
             (click)="toggleExpand()">
            {{ directionIcon }}
        </div>
        <div *ngIf="expanded" class="title">{{title}}</div>
    </div>
    <div *ngIf="expanded" class="list">
        <div *ngFor="let report of reportList" 
            class="listItem" 
            [class.selected]="report.id === selectedId"
            [title]="report.name"
            (click)="handleClick($event, report.id)">
              {{report.name}}
        </div>
    </div>
</div>