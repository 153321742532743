<div class="epic-select">
  <input type="text" class="keyword-input" placeholder="Seach portfolio epic.."
    (input)="onKeywordChanged($event.target.value)"
    (focus)="$event.target.select()" 
    [value]="value$ | async"
    [disabled]="addNewProject$ | async" 
    [matAutocomplete]="auto">
  <mat-autocomplete #auto="matAutocomplete" (optionSelected)="writeValue($event.option.value)">
    <mat-option *ngIf="showHint" [value]="" disabled>
      <mat-spinner *ngIf="isLoading">Please wait...</mat-spinner>
      <span class="" *ngIf="!isLoading">
        Search by item ID or title...
      </span>
    </mat-option>
    <mat-option *ngFor="let option of options$ | async" [value]="option.id" [title]="option.description"
      [disabled]="!option.enabled">
      <span>({{ option.id }}) {{ option.title }}</span>
    </mat-option>
  </mat-autocomplete>
</div>

<label class="mt-2">
  <input #addNewProjectCheckbox type="checkbox" 
    name="checkbox" style="vertical-align: middle; margin-bottom: .15rem;"
    [checked]="addNewProject$ | async" 
    (change)="onAddNewprojectChanged(addNewProjectCheckbox.checked)" />
  Add new portfolio epic
</label>