
import { Component, ElementRef, EventEmitter, Injector, Input, OnDestroy, OnInit, Output, ValueProvider, ViewChild, ViewContainerRef } from "@angular/core";
import { geoEquirectangular, geoPath, select, zoom } from "d3";
import { CountriesGeoService } from "../../../../services/shared/policy-tracker/countries-geo.service";
import * as d3 from 'd3';
import chroma from "chroma-js";
import { ComponentPortal } from '@angular/cdk/portal';
import { Overlay, OverlayConfig, OverlayRef } from '@angular/cdk/overlay';
import { MarketStatusService } from '../../../../services/shared/policy-tracker/market-status.service';
import { PolicyTrackerMapService } from '../../../../services/shared/policy-tracker/policy-tracker-map.service';
import { Subscription, firstValueFrom } from 'rxjs';
import { FeatureType } from '../../../../models/shared/policy-tracker/feature-type.enum';
import { Router } from '@angular/router';
import { PolicyTrackerService } from "~/services/shared/policy-tracker/policy-tracker.service";
import { CountryService } from "~/services/shared/country.service";
import { PolicyTree } from "~/services/shared/policy-tracker/tree.constants";
import { PolicyTrackerQuery } from "~/models/shared/policy-tracker/policy-tracker-query.model";
import { CountryIdEnum } from "~/models/shared/country";
import { getMapFacets } from "../../state/policy-tracker-map.repository";
import { Article6Mou } from "~/models/shared/policy-tracker/article6-mou";
import { CarbonMarketStatus, CarbonMarketStatusEnum } from "~/models/shared/policy-tracker/carbon-market-status";
import { PolicyTracker } from "~/models/shared/policy-tracker/policy-tracker";
import { Icap } from "~/models/shared/policy-tracker/icap";
import { Article6DialogComponent } from "../article6-dialog/article6-dialog.component";
import { MarketDialogComponent } from "../market-dialog/market-dialog.component";
import { ICountry } from "~/models/shared/location";
import { getCountryNameForGeoJson } from '~/services/shared/policy-tracker/country-feature';

@Component({
	selector: 'policy-tracker-map',
	templateUrl: './policy-tracker-map.component.html',
	styleUrls: ['./policy-tracker-map.component.scss']
})
export class PolicyTrackerMapComponent implements OnInit, OnDestroy {
	@ViewChild('template', { static: true }) template;
	@ViewChild('map') map: ElementRef | undefined;
	@Input() isLoading: boolean = true;
    @Output() changeLoadingEvent: EventEmitter<boolean> = new EventEmitter();

	public isLoaded: boolean = false;

	constructor(
		private viewContainerRef: ViewContainerRef,
		private overlay: Overlay,
		private injector: Injector,
		private _countriesGeoService: CountriesGeoService,
		private _policyTrackerService: PolicyTrackerService,
		private _marketStatusService: MarketStatusService,
		private _policyTrackerMapService: PolicyTrackerMapService,
		private _countryService: CountryService,
		private _router: Router) { }

	private readonly markerRadius = 10;
	private readonly legendIconHeight = 18;
	private readonly legendMargin = 10;
	private redrawSubscription: Subscription | undefined;
	private g;
	private projection;
	private markets;

	private readonly strokeWidthHighlight = 1;
	private readonly strokeHighlight = 'red';
	private readonly strokeWidthNormal = 0.5;
	private readonly strokeNormal = 'black';
	private zoom;
	private transform;
	private article6Map;
	private d3e;
	private query;
	private pathGenerator;
	private countryMap;
	private subNational: boolean = false;
	private national: boolean = false;
  private svgCache = new Map<string, HTMLElement>();

	ngOnInit() {
		this.viewContainerRef.createEmbeddedView(this.template);
		this.viewContainerRef.element.nativeElement.remove();

		this.redrawSubscription = this._policyTrackerMapService.redrawRequested$.subscribe((query) => {
			this.drawMap(query);
		});

		this.zoom =
			zoom()
				.scaleExtent([1, Infinity])
				.on('zoom', (event) => {
					this.transform = event.transform;
					this.zoomed(event);
				});
	}

	ngOnDestroy(): void {
		if (this.redrawSubscription) {
			this.redrawSubscription.unsubscribe();
		}
	}

	private async drawMap(query: PolicyTrackerQuery) {
		this.changeLoadingEvent.emit(true);

		this.query = query;

		await this.loadDataMaps();

		this.subNational = await this.isJurisdictionSelected(PolicyTree.LEAF_JURISDICTION_SUBNATIONAL);
		this.national = await this.isJurisdictionSelected(PolicyTree.LEAF_JURISDICTION_NATIONAL);

		const allPolicies = await this._policyTrackerService.queryPolicyTrackerMarkets(query);
		this.markets = await this.getMarketLocationData(allPolicies);
		const countries = await this._countriesGeoService.getCountries(allPolicies);
		const filtered = await this.filterMap(countries);

		this.configureMap();

		this.g = this.d3e.append('g');
		this.g.selectAll('path')
			.data(filtered.features)
			.enter()
			.append('path')
			.attr("id", d => this.getCountryCssId(d.properties.name))
			.attr('class', 'country')
			//.attr('cursor', (d: any) => this.getCountryCursor(d))
			.attr('d', this.pathGenerator)
			.attr('fill', (d: any) => this.getCountryFill(d))
			.attr('fill-opacity', (d: any) => this.getCountryFillOpacity(d))
			.on('click', (event) => this.handleCountryClick.call(this, event))
			.on("mouseover", (event) => this.handleCountryMouseOver.call(this, event, query.article6))
			.on("mouseout", (event) => this.handleCountryMouseOut.call(this, event, query.article6))
			.append('title')
			.text((d: any) => this.getCountryText(d));

		this.drawLegend(this.d3e);
    await this.primeSvgCache();
		this.plotMarkets(this.markets, this.g, countries);

		this.restoreZoom();

		this.changeLoadingEvent.emit(false);
		this.isLoaded = true;
	}

  private async primeSvgCache() {
    for(let market of this.markets) {
      const status = this._marketStatusService.getMarketStatus(market.status.id);
      await this.loadSvgIcon(status?.iconPath);
    }
  }

	private configureMap() {
		const width = 960;
		const height = 500;
		this.d3e = this.getMap(width, height);
		this.projection = this.getProjection(width, height);
		this.pathGenerator = geoPath().projection(this.projection);
		this.d3e.call(this.zoom);
	}

	private async loadDataMaps() {
		if (!this.article6Map) {
			this.article6Map = await this._policyTrackerService.getArticle6MouMap();
		}
		if (!this.countryMap) {
			this.countryMap = await this._countryService.getCountryMap();
		}
	}

	private async filterMap(countries: any): Promise<any> {
		const regional = await this.isJurisdictionSelected(PolicyTree.LEAF_JURISDICTION_REGIONAL);

		let filtered = countries;
		if (!this.national) {
			filtered = this._marketStatusService.filterNational(countries);
		}
		if (regional && this.subNational) {
			filtered = this._marketStatusService.filterSpainProvinces(countries);
		}
		if (this.subNational && !regional) {
			filtered = this._marketStatusService.filterSpain(countries);
		}

		return filtered;
	}

	private getMap(width: number, height: number) {
		const mapSelector = "svg#policytrackermapsvg";

		const svg1 = document.querySelector(mapSelector);
		if (svg1) {
			svg1.setAttribute("preserveAspectRatio", "xMinYMin meet");
			svg1.setAttribute("viewBox", [0, 0, width, height].join(" "));
		}

		const d3e = select(mapSelector);
		if (d3e) {
			d3e.selectAll("g > *").remove()
		}

		return d3e;
	}

	private getProjection(width: number, height: number): d3.GeoProjection {
		const projection = geoEquirectangular();
		const x = width / 2 - 22;
		projection
			.scale(160)
			.translate([x, height / 2]);

		return projection;
	}

	private getCountryCursor(d: any) {
		const countryId = Number(d.id);
		const mous = this.article6Map.get(countryId);
		if (this.query.article6 && mous && mous.length > 0) {
			return 'pointer'
		}
		return 'grab';
	}

	private getCountryText(d: any) {
		const countryId = Number(d.id);
		if (this.query.article6) {
			return this.getCountryTitle(countryId, true);
		}

		return this.getCountryTitle(countryId, false);
	}

	private getCountryFill(d: any) {
		const regionType = d.properties.regionType;
		const markets = d.properties.policies;
		const countryId = Number(d.id);
		let fillColor;
		if (regionType === FeatureType.Country) {
			fillColor = this._marketStatusService.getNationalColor(markets);
		} else {
			fillColor = this._marketStatusService.getSubnationalColor(markets);
		}
		return this.getRegionFill(countryId, fillColor);
	}

	private getCountryFillOpacity(d: any) {
		const countryId = Number(d.id);
		const markets = d.properties.policies;
		const regionType = d.properties.regionType;
		if (regionType === FeatureType.Country && markets?.length >= 0 && this.national) {
			if (countryId === CountryIdEnum.Russia) {
				return 1;
			}
			return 0.5;
		}
		return 1;
	}

	private getCountryTitle(countryId: number, article6: boolean): string {
		const country = this.countryMap.get(countryId);
		const mous = this.article6Map.get(countryId);
		if (article6 && mous && mous.length > 0) {
			return `${country?.name} (click for article 6)`;
		}

		return `${country?.name} (click for details)`;
	}

	private getRegionFill(countryId: number, fillColor: string) {
		const mous = this.article6Map.get(countryId);
		if (mous && mous.length > 0 && this.query.article6) {
			const patternID = this.createPatternWithBackground(this.d3e, countryId, fillColor);
			return `url(#${patternID})`;
		} else {
			return fillColor;
		}
	}

	private createPatternWithBackground(svg, countryID: number, backgroundColor: string) {
		const patternID = `diagonalHatch-${countryID}-${backgroundColor.slice(1)}`;

		let pattern;
		pattern = select(`#patternDefs #${patternID}`);
		if (pattern.empty()) {
			// Create the pattern
			pattern = svg
				.select('#patternDefs')
				.append('pattern')
				.attr('id', patternID)
				.attr('width', 2)
				.attr('height', 2)
				.attr('patternTransform', 'rotate(45 0 0)')
				.attr('patternUnits', 'userSpaceOnUse');

			// Add a rect with the background color to the pattern
			pattern
				.append('rect')
				.attr('width', 10)
				.attr('height', 10)
				.attr('fill', backgroundColor);

			// Add the diagonal lines to the pattern
			pattern
				.append('line')
				.attr('x1', 0)
				.attr('y1', 0)
				.attr('x2', 0)
				.attr('y2', 10)
				.attr('style', 'stroke:black; stroke-width:1');
		}

		return patternID;
	}

	private async handleCountryClick(event) {
		const data = event.currentTarget.__data__;
		const countryId = Number(data.id);

		const mous = this.article6Map.get(countryId);
		if (this.query.article6 && mous && mous.length > 0) {
			this.handleCountryClickArticle6(event);
			return;
		}

		const country = await this._countryService.getCountryById(countryId);
		if (country) {
			this._router.navigateByUrl(`/policy-tracker/country/${country.alpha3Code}`);
		}
	}

	private async handleCountryClickArticle6(event) {
		if (!this.query.article6) {
			return;
		}
		const data = event.currentTarget.__data__;

		const countryId = Number(data.id);
		const country = await this._countryService.getCountryById(countryId);
		if (country && !this.hasMouArcs(countryId)) {
			const mous = await this._policyTrackerService.getArticle6MousByCountry(countryId);
			this.showArticle6Popup(event, country, mous);
			this.drawMouArcsByCountry(countryId, this.g, this.pathGenerator);

			const title = `${country.name} (click to clear article 6)`;
			const countryElement = select(`#${this.getCountryCssId(country.name)} title`);
			if (!countryElement.empty()) {
				countryElement.text(title);
			} else {
				const countries = this.d3e.selectAll('.country');
				const provinces = countries.filter(d => d.properties.id === countryId);
				provinces.select('title').text(title);
			}
		} else if (country) {
			this.eraseMouArcs(countryId);
			const countryElement = select(`#${this.getCountryCssId(country.name)} title`);
			if (!countryElement.empty()) {
				countryElement.text(this.getCountryTitle(countryId, true));
			}
		}
	}

	private restoreZoom() {
		if (this.transform) {
			this.g.transition()
				.duration(500)
				.call(this.zoom.transform, this.transform);
		}
	}

	private zoomed(event) {
		const transform = event.transform;
		this.g.attr("transform", transform);

		this.scaleMarketMarkets(transform);
	}

	private scaleMarketMarkets(transform) {
		const side = this.markerRadius * 2;
		const scaledSide = side / Math.sqrt(transform.k);

		if (this.markets) {
			this.markets.forEach(market => {
				const location = this.getProjectedLocation(market, scaledSide);

				this.g.selectAll(`[data-market-id="${market.name}"]`)
					.attr("x", location[0])
					.attr("y", location[1])
					.attr('width', scaledSide)
					.attr('height', scaledSide);

				this.g.selectAll(`[data-market-id-overlay="${market.name}"]`)
					.attr("x", location[0])
					.attr("y", location[1])
					.attr('width', scaledSide)
					.attr('height', scaledSide);
			});
		}
	}

	private getProjectedLocation(market, side): [number, number] {
		const imageCoordinates = [market.coordinates[0], market.coordinates[1]];
		const projectedCoordinates = this.projection(imageCoordinates);
		const x = projectedCoordinates[0] - side / 2;
		const y = projectedCoordinates[1] - side / 2;

		return [x, y];
	}

	private handleCountryMouseOver(event, article6: boolean) {
		if (!article6) {
			return;
		}
		const data = event.target.__data__;

		const countryId = Number(data.id);
		const mous = this.article6Map.get(countryId);
		if (mous) {
			mous.forEach(m => {
				this.toggleArc(m, true);
			});
		}
	}

	private handleCountryMouseOut(event, article6: boolean) {
		if (!article6) {
			return;
		}
		const data = event.target.__data__;

		const countryId = Number(data.id);
		const mous = this.article6Map.get(countryId);
		if (mous) {
			mous.forEach(m => {
				this.toggleArc(m, false);
			});
		}
	}

	private async isJurisdictionSelected(jurisdiction: string): Promise<boolean> {
		const facets = await firstValueFrom(getMapFacets());

		let foundJurisdiction = false;
		if (facets) {
			facets.selectedFacets.forEach(x => {
				if (x.item === jurisdiction) {
					foundJurisdiction = true;
				}
			})
		}

		return foundJurisdiction;
	}

	private async drawMouArcsByCountry(countryId: number, svg, path) {
		const mous = this.article6Map.get(countryId);

		mous.forEach(mou => {
			if (mou.mouCountry) {
				const startPoint: [number, number] = [mou.mouCountry.longitude, mou.mouCountry.latitude];
				const endPoint: [number, number] = [mou.country.longitude, mou.country.latitude];

				this.drawMouArc(countryId, svg, path, mou, startPoint, endPoint);
			}
		});
	}

	private drawMouArc(countryId: number, svg, path, mou: Article6Mou, startPoint: [number, number], endPoint: [number, number]) {
		const greatArcGenerator = d3.geoInterpolate(startPoint, endPoint);

		const numPoints = 100; // Increase this number for smoother arcs
		const arcPoints = Array.from({ length: numPoints }, (_, i) => greatArcGenerator(i / (numPoints - 1)));

		// Draw the arc
		svg.append('path')
			.datum({ type: "LineString", coordinates: arcPoints, mou: mou })
			.attr('d', path)
			.attr('id', this.getArcCssId(mou))
			.attr('fill', 'none')
			.attr('stroke', this.strokeNormal)
			.attr('stroke-width', this.strokeWidthNormal)
			.attr('stroke-dasharray', '3,3')
			.on('click', (event) => this.handleArticle6ArcClick.call(this, event, mou))
			.on("mouseover", (event) => this.handleArticle6ArcMouseover.call(this, countryId, mou))
			.on("mouseout", (event) => this.handleArticle6ArcMouseout.call(this, countryId, mou))
			.append('title')
			.text(() => this.getArticle6MouTip(mou));
	}

	private eraseMouArcs(countryId: number) {
		const mous = this.article6Map.get(countryId);
		mous.forEach(mou => {
			if (mou.mouCountry) {
				const arcId = this.getArcCssId(mou)
				const arc = select(`#${arcId}`);
				if (!arc.empty()) {
					arc.remove();
				}
			}
		});
	}

	private hasMouArcs(countryId: number): boolean {
		const mous = this.article6Map.get(countryId);
		let hasArcs = false;
		mous.forEach(mou => {
			if (mou.mouCountry) {
				const arcId = this.getArcCssId(mou)
				const arc = select(`#${arcId}`);
				if (!arc.empty()) {
					hasArcs = true;
				}
			}
		});

		return hasArcs;
	}

	private getArticle6MouTip(mou: Article6Mou) {
		let tip: string = `${mou.mouCountry?.name} to ${mou.country.name}`;
		if (mou.mouLink) {
			tip = tip + ' (click for more)';
		}

		return tip;
	}

	private async handleArticle6ArcClick(event, mou: Article6Mou) {
		const country = await this._countryService.getCountryById(mou.country.id);
		if (country) {
			this.showArticle6Popup(event, country, [mou]);
		}
	}

	private async handleArticle6ArcMouseover(countryId: number, mou: Article6Mou) {
		this.toggleArc(mou, true);
	}

	private async handleArticle6ArcMouseout(countryId: number, mou: Article6Mou) {
		this.toggleArc(mou, false);
	}

	private toggleArc(mou: Article6Mou, highlight: boolean) {
		if (mou.mouCountry) {
			const arc = this.getArcCssId(mou);
			if (!highlight) {
				this.g.select(`#${arc}`)
					.attr('stroke-width', this.strokeWidthNormal)
					.attr('stroke', this.strokeNormal);
			} else {
				this.g.select(`#${arc}`)
					.attr('stroke-width', this.strokeWidthHighlight)
					.attr('stroke', this.strokeHighlight);
			}
		}
	}

	private drawLegend(svg) {
		const icons = [
			{
				uri: this._marketStatusService.getMarketStatus(CarbonMarketStatusEnum.Implemented)?.iconPath,
				description: this._marketStatusService.getMarketStatus(CarbonMarketStatusEnum.Implemented)?.name,
			},
			{
				uri: this._marketStatusService.getMarketStatus(CarbonMarketStatusEnum.UnderDevelopment)?.iconPath,
				description: this._marketStatusService.getMarketStatus(CarbonMarketStatusEnum.UnderDevelopment)?.name,
			},
			{
				uri: this._marketStatusService.getMarketStatus(CarbonMarketStatusEnum.UnderConsideration)?.iconPath,
				description: this._marketStatusService.getMarketStatus(CarbonMarketStatusEnum.UnderConsideration)?.name,
			}
		];

		const iconGroup = svg.append("g")
			.attr("transform", `translate(${this.legendMargin},${this.legendMargin})`);

		iconGroup.selectAll("image")
			.data(icons)
			.enter()
			.append("image")
			.attr("id", d => d.id)
			.attr("href", d => d.uri)
			.attr("width", this.legendIconHeight)
			.attr("height", this.legendIconHeight)
			.attr("y", (d, i) => i * (this.legendIconHeight + 5));

		iconGroup.selectAll("text")
			.data(icons)
			.enter()
			.append("text")
			.attr("x", this.legendIconHeight + (this.legendMargin - 4))
			.attr("y", (d, i) => i * (this.legendIconHeight + 5) + this.legendIconHeight * 0.75)
			.text(d => d.description)
			.attr("font-size", "10px")
			.attr("font-weight", "bolder");
	}

	private getCountryCssId(name: string) {
		return this.transformToCssId(getCountryNameForGeoJson(name));
	}

	private getArcCssId(mou: Article6Mou) {
		return `arc_${mou.country.id}_${mou.mouCountry?.id}`;
	}

	private transformToCssId(id: string) {
		return this.removeSpaces(id).replace(/'/g, "")
	}

	private removeSpaces(input: string): string {
		return input.replace(/\s+/g, '');
	}

	private async plotMarkets(markets, g, countries) {
		await Promise.all(markets.map(async market => {
			const status = this._marketStatusService.getMarketStatus(market.status.id);
			let svgPath: string | undefined = status?.iconPath;

			await this.loadMarketImage(
				g,
				svgPath,
				market,
				countries
			);
		}));
	}

	private async loadMarketImage(g, imageUrl, market, countries) {
		const image = await this.loadSvgIcon(imageUrl);

		// Add the image to the SVG
		this.addImageToSvg(g, image, countries, market);
	}

	private async loadSvgIcon(filename) {
    if (this.svgCache.has(filename)) {
        return this.svgCache.get(filename);
    }

    const svg = await d3.xml(filename);
    const result = svg.documentElement;

    this.svgCache.set(filename, result);
    
    return result;
}

	private addImageToSvg(g, image, countries, market) {
		const radius = this.markerRadius;
		const regions = this._marketStatusService.filterCountriesByMarket(countries, market.policy);

		const side = radius * 2;
		const location = this.getProjectedLocation(market, side);

		const gMarkers = g.append("g").attr("class", "g_main");
		gMarkers.append(() => this.cloneSVG(image))
			.attr("x", location[0])
			.attr("y", location[1])
			.attr("width", side)
			.attr("height", side)
			.attr("data-market-id", market.policy.name)
			.attr("class", "market-image");

		const overlay = gMarkers.append('rect')
			.attr('class', 'click-capture')
			.style('visibility', 'hidden')
			.attr('x', location[0])
			.attr('y', location[1])
			.attr('width', side)
			.attr('height', side)
			.attr("data-market-id-overlay", market.policy.name);

		overlay.on('click', (event: any) => this.showMarketPopup(event, market.policy));
		overlay.on('mouseover', () => this.highlightCountries(g, true, regions, market.policy))
		overlay.on('mouseout', () => this.highlightCountries(g, false, regions, market.policy))
			.append('title')
			.text(market.title);
	}

  private cloneSVG(svg) {
    return svg.cloneNode(true);
  }

	private async highlightCountries(g, highlight: boolean, regions, market: PolicyTracker) {
		regions.forEach(async region => {
			let fill: string | null = null;
			const countryId = Number(region.id);
			if (highlight) {
				fill = this.getRegionFill(countryId, this.getDarkerColor(this._marketStatusService.getSubnationalColor(region.properties.policies, market), 1));
			} else {
				fill = this.getRegionFill(countryId, this._marketStatusService.getSubnationalColor(region.properties.policies));
			}

			const name = this.getCountryCssId(region.properties.name);
			g.select(`#${name}`)
				.attr('fill', fill);
		});

		let strokeWidth: number | null = null;
		let stroke: string | null = null;

		if (highlight) {
			strokeWidth = this.strokeWidthHighlight;
			stroke = this.strokeHighlight;
		} else {
			strokeWidth = this.strokeWidthNormal;
			stroke = this.strokeNormal;
		}

		if (market.article6Mous) {
			market.article6Mous.forEach(m => {
				const arc = this.getArcCssId(m);
				g.select(`#${arc}`)
					.attr('stroke-width', strokeWidth)
					.attr('stroke', stroke);
			});
		}
	}

	private async getMarketLocationData(policies: PolicyTracker[]) {
		const markets = [] as { name: string; title: string; status: CarbonMarketStatus; regions: string[]; coordinates: number[]; icapMarket: Icap; policy: PolicyTracker; }[];
		policies.forEach(market => {
			const item = { name: market.name, title: market.title, status: market.marketStatus, regions: market.regions, coordinates: [market?.longitude, market?.latitude], icapMarket: market.icap, policy: market }
			markets.push(item);
		});

		return markets;
	}

	private getDarkerColor(color, amount) {
		// Create a chroma color object from the input color
		const chromaColor = chroma(color);

		// Darken the color by the specified amount
		const darkerColor = chromaColor.darken(amount);

		// Return the darker color as a string in the desired format (e.g., 'hex', 'rgb', 'hsl')
		return darkerColor.hex();
	}

	private showArticle6Popup(event: MouseEvent, country: ICountry, mous: Article6Mou[]): void {
		const overlayRef = this.createDialogOverlay(event, 'article6-overlay-panel');

		const dataProvider: ValueProvider = { provide: 'ARTICLE6_DATA', useValue: mous };
		const countryProvider: ValueProvider = { provide: 'COUNTRY_DATA', useValue: country };
		const injector = Injector.create({ parent: this.injector, providers: [dataProvider, countryProvider] });

		const portal = new ComponentPortal(Article6DialogComponent, null, injector);
		const popupComponentRef = overlayRef.attach(portal);

		popupComponentRef.instance.onClose.subscribe(() => {
			overlayRef.detach();
		});
	}

	private showMarketPopup(event: MouseEvent, market: PolicyTracker): void {
		const overlayRef = this.createDialogOverlay(event, 'market-overlay-panel');

		const marketProvider: ValueProvider = { provide: 'MARKET_DATA', useValue: market };
		const injector = Injector.create({ parent: this.injector, providers: [marketProvider] });

		const portal = new ComponentPortal(MarketDialogComponent, null, injector);
		const popupComponentRef = overlayRef.attach(portal);

		popupComponentRef.instance.market = market;
		popupComponentRef.instance.onClose.subscribe(() => {
			overlayRef.detach();
		});
	}

	private createDialogOverlay(event: MouseEvent, panelClass: string): OverlayRef {
		const overlayConfig = new OverlayConfig({
			hasBackdrop: true,
			backdropClass: 'cdk-overlay-transparent-backdrop',
			panelClass: panelClass,
			positionStrategy: this.overlay.position()
				.flexibleConnectedTo({ x: event.clientX, y: event.clientY })
				.withPositions([
					{
						originX: 'center',
						originY: 'bottom',
						overlayX: 'center',
						overlayY: 'top',
						offsetY: 3
					}
				])
		});

		const overlayRef = this.overlay.create(overlayConfig);

		overlayRef.backdropClick().subscribe(() => {
			overlayRef.detach();
		});

		return overlayRef;
	}
}