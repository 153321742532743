import { DatePipe, DecimalPipe, CurrencyPipe } from '@angular/common';
import { Injectable } from '@angular/core';

@Injectable()
export class FormatterService {
    private _locale = "en-US";

    public formatDateString(dateString: string, format: string = "yyyy-MM-dd"): string {
        const datePipe = new DatePipe(this._locale);

        return datePipe.transform(dateString, format) ?? "";
    }

    public formatDate(date: Date, format: string = 'yyyy-MM-dd'): string {
        const datePipe = new DatePipe(this._locale);
        
        return datePipe.transform(date, format) ?? "";
    }

    public formatDateUtc(date: Date, format: string = 'yyyy-MM-dd'): string {
        const datePipe = new DatePipe(this._locale);
        
        return datePipe.transform(date, format, 'UTC') ?? "";
    }

    public formatNumber(value: any, format: string = "1.0-5"): string {
        let returnValue = "";

        if (value !== null && value !== "") {
            const decimalPipe = new DecimalPipe(this._locale);

            returnValue = decimalPipe.transform(value, format) ?? "";
        }

        return returnValue;
    }

    public formatCurrency(value: any): string {
        let returnValue = "";

        if (value !== null && value !== "") {
            const currencyPipe = new CurrencyPipe(this._locale);

            returnValue = currencyPipe.transform(value) ?? "";
        }

        return returnValue;
    }
}
