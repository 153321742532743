import { Injectable } from "@angular/core";
import { HttpService, ServiceType } from "~/services/core/http.service";
import { SignPostReport } from "./models/signposts.models";

@Injectable()
export class PowerBiReportService {

    constructor(
        private httpService: HttpService,
    ) { }

    public async getReportConfig(reportName: string): Promise<SignPostReport[] | null> {
        let requestUri = `/powerbi/config?reportName=${reportName}`;

        return await this.httpService.Get(requestUri, ServiceType.API).then((data: any) => {
            return data;
        });
    }
}