<ng-container *ngIf="!isEdit; else EditForm">
    <table class="table-x">
        <caption style="display: none;">Priority Score Card</caption>
        <tbody>
            <tr>
                <th scope="col">Project Type <span class="material-icons-outlined info-icon"
                    title="{{ getToolTip('projectType') }}">info</span></th>
                <td>
                    {{ context?.projectType ?? '--' }}
                </td>
            </tr>
            <tr>
                <th scope="col">Geography/Customer <span class="material-icons-outlined info-icon"
                    title="{{ getToolTip('geography') }}">info</span></th>
                <td>{{ context?.geography ?? '--' }}</td>
            </tr>
            <tr>
                <th scope="col">Developer <span class="material-icons-outlined info-icon"
                    title="{{ getToolTip('developer') }}">info</span></th>
                <td>{{ context?.developer ?? '--' }}</td>
            </tr>
            <tr>
                <th scope="col">Offset Optionality/Fungibility <span class="material-icons-outlined info-icon"
                    title="{{ getToolTip('fungibility') }}">info</span></th>
                <td>{{ context?.fungibility ?? '--' }}</td>
            </tr>
            <tr>
                <th scope="col">Scale <span class="material-icons-outlined info-icon"
                    title="{{ getToolTip('scale') }}">info</span></th>
                <td>{{ context?.scale ?? '--' }}</td>
            </tr>
            <tr>
                <th scope="col">Economics <span class="material-icons-outlined info-icon"
                    title="{{ getToolTip('economics') }}">info</span></th>
                <td>{{ context?.economics ?? '--' }}</td>
            </tr>
        </tbody>
    </table>
</ng-container>
<ng-template #EditForm>
    <form [formGroup]="form">
        <table class="table-x table-form">
            <caption style="display: none;">Priority Score Card</caption>
            <tbody>
                <tr>
                    <th scope="col">
                        <label for="projectType">Project Type</label>
                    </th>
                    <td>
                        <label class="radio-list-item">
                            <input type="radio" formControlName="projectType" [value]="1">
                            <span>1 - {{ getRatingDescriptions('projectType')[0]}}</span>
                        </label>
                        <label class="radio-list-item">
                            <input type="radio" formControlName="projectType" [value]="2">
                            <span>2 - {{ getRatingDescriptions('projectType')[1]}}</span>
                        </label>
                        <label class="radio-list-item" style="border-bottom:none;">
                            <input type="radio" formControlName="projectType" [value]="3">
                            <span>3 - {{ getRatingDescriptions('projectType')[2]}}</span>
                        </label>
                        <ng-container *ngIf="this.form.get('projectType')?.value">
                            <button class="mt-2 py-1"
                                style="font-size:15px"
                                (click)="clearValue('projectType')">
                                clear
                            </button>
                        </ng-container>
                    </td>
                </tr>
                <tr>
                    <th scope="col">
                        <label for="geography">Geography/Customer</label>
                    </th>
                    <td>
                        <label class="radio-list-item">
                            <input type="radio" formControlName="geography" [value]="1">
                            <span>1 - {{ getRatingDescriptions('geography')[0]}}</span>
                        </label>
                        <label class="radio-list-item">
                            <input type="radio" formControlName="geography" [value]="2">
                            <span>2 - {{ getRatingDescriptions('geography')[1]}}</span>
                        </label>
                        <label class="radio-list-item" style="border-bottom:none;">
                            <input type="radio" formControlName="geography" [value]="3">
                            <span>3 - {{ getRatingDescriptions('geography')[2]}}</span>
                        </label>
                        <ng-container *ngIf="this.form.get('geography')?.value">
                            <button class="mt-2 py-1"
                                style="font-size:15px"
                                (click)="clearValue('geography')">
                                clear
                            </button>
                        </ng-container>
                    </td>
                </tr>
                <tr>
                    <th scope="col">
                        <label for="developer">Developer</label>
                    </th>
                    <td>
                        <label class="radio-list-item">
                            <input type="radio" formControlName="developer" [value]="1">
                            <span>1 - {{ getRatingDescriptions('developer')[0]}}</span>
                        </label>
                        <label class="radio-list-item">
                            <input type="radio" formControlName="developer" [value]="2">
                            <span>2 - {{ getRatingDescriptions('developer')[1]}}</span>
                        </label>
                        <label class="radio-list-item" style="border-bottom:none;">
                            <input type="radio" formControlName="developer" [value]="3">
                            <span>3 - {{ getRatingDescriptions('developer')[2]}}</span>
                        </label>
                        <ng-container *ngIf="this.form.get('developer')?.value">
                            <button class="mt-2 py-1"
                                style="font-size:15px"
                                (click)="clearValue('developer')">
                                clear
                            </button>
                        </ng-container>
                    </td>
                </tr>
                <tr>
                    <th scope="col">
                        <label for="fungibility">Offset Optionality/Fungibility</label>
                    </th>
                    <td>
                        <label class="radio-list-item">
                            <input type="radio" formControlName="fungibility" [value]="1">
                            <span>1 - {{ getRatingDescriptions('fungibility')[0]}}</span>
                        </label>
                        <label class="radio-list-item">
                            <input type="radio" formControlName="fungibility" [value]="2">
                            <span>2 - {{ getRatingDescriptions('fungibility')[1]}}</span>
                        </label>
                        <label class="radio-list-item" style="border-bottom:none;">
                            <input type="radio" formControlName="fungibility" [value]="3">
                            <span>3 - {{ getRatingDescriptions('fungibility')[2]}}</span>
                        </label>
                        <ng-container *ngIf="this.form.get('fungibility')?.value">
                            <button class="mt-2 py-1"
                                style="font-size:15px"
                                (click)="clearValue('fungibility')">
                                clear
                            </button>
                        </ng-container>
                    </td>
                </tr>
                <tr>
                    <th scope="col">
                        <label for="scale">Scale</label>
                    </th>
                    <td>
                        <label class="radio-list-item">
                            <input type="radio" formControlName="scale" [value]="1">
                            <span>1 - {{ getRatingDescriptions('scale')[0]}}</span>
                        </label>
                        <label class="radio-list-item">
                            <input type="radio" formControlName="scale" [value]="2">
                            <span>2 - {{ getRatingDescriptions('scale')[1]}}</span>
                        </label>
                        <label class="radio-list-item" style="border-bottom:none;">
                            <input type="radio" formControlName="scale" [value]="3">
                            <span>3 - {{ getRatingDescriptions('scale')[2]}}</span>
                        </label>
                        <ng-container *ngIf="this.form.get('scale')?.value">
                            <button class="mt-2 py-1"
                                style="font-size:15px"
                                (click)="clearValue('scale')">
                                clear
                            </button>
                        </ng-container>
                    </td>
                </tr>
                <tr>
                    <th scope="col">
                        <label for="economics">Economics</label>
                    </th>
                    <td>
                        <label class="radio-list-item">
                            <input type="radio" formControlName="economics" [value]="1">
                            <span>1 - {{ getRatingDescriptions('economics')[0]}}</span>
                        </label>
                        <label class="radio-list-item">
                            <input type="radio" formControlName="economics" [value]="2">
                            <span>2 - {{ getRatingDescriptions('economics')[1]}}</span>
                        </label>
                        <label class="radio-list-item" style="border-bottom:none;">
                            <input type="radio" formControlName="economics" [value]="3">
                            <span>3 - {{ getRatingDescriptions('economics')[2]}}</span>
                        </label>
                        <ng-container *ngIf="this.form.get('economics')?.value">
                            <button class="mt-2 py-1"
                                style="font-size:15px"
                                (click)="clearValue('economics')">
                                clear
                            </button>
                        </ng-container>
                    </td>
                </tr>
            </tbody>
        </table>
    </form>
</ng-template>