<div class="content">
    <div class="grid">
        <sdk-datagrid fontFamily="'GothamNarrowBook', sans-serif, 'Helvetica Neue', Arial"
                      [uniqueIdentifier]="uniqueIdentifier"
                      [datasets]="datasets"
                      [name]="activeView"
                      [data]="data"
                      [fullData]="fullData"
                      [columns]="columns"
                      [filterTypes]="filterTypes"
                      [options]="gridOptions"
                      [dbPage]="dbPage"
                      [dbTotal]="dbTotal"
                      [dbMAXRECORDS]="MAXRECORDS"
                      [showHeader]=false
                      [minimizeOptions]=false
                      [isDataWrapped]=true
                      [isLoading]="isLoading"
                      [error]="error"
                      [settings]="allSettings"
                      (loadDataEvent)="loadData($event)"
                      (settingsSavedEvent)="saveSettings($event)">
        </sdk-datagrid>
    </div>

    <div class="footnote">
        {{ footnote }}
    </div>

    <ng-template #registryTemplate let-rowItem="rowItem.registry">
        <div *ngIf="rowItem && rowItem.websiteUrl && rowItem.websiteUrl !== ''">
            <a [href]="rowItem.websiteUrl" target="_blank">{{ rowItem.longName }}</a>
        </div>
        <div *ngIf="rowItem && (!rowItem.websiteUrl || rowItem.websiteUrl === '')">
            {{ rowItem.longName }}
        </div>
    </ng-template>

    <ng-template #metaRegistryTemplate let-rowItem="rowItem">
        <div *ngIf="rowItem && rowItem.metaRegistryProjectId && rowItem.metaRegistryProjectId !== ''">
            <div class="link" (click)="gotoProject(rowItem.metaRegistryProjectId)">{{ rowItem.metaRegistryProjectId }}</div>
        </div>
    </ng-template>
</div>
