<div #component class="sdk_textbox" style="position: relative; padding: 0;">
    <div *ngIf="!multiLine" [ngStyle]="{ 'margin': margin ?? '0', 'border': 'unset', 'height': height ?? 'unset', 'width': width ?? 'unset' }">
        <i *ngIf="validCharacters === 'calendar' && showCalendarIcon" class="sdk-icon" (click)="showComponent($event); text.focus(); lockCalendar = true" (mouseout)="lockCalendar = false; showCalendar ? text.focus() : false;">calendar_month</i>
        <input #text type="text" [ngStyle]="{ 'padding': padding ?? '5px', 'margin': '0', 'border': border ?? '1px solid rgb(200, 200, 200)', 'height': '100%', 'width': '100%' }" [class]="class" [style]="style" [placeholder]="hint" (keydown)="onKeyDown($event)" (focus)="text.select()" (blur)="onBlur($event)" (input)="onInput($event)" (cut)="onCut($event)" (paste)="onPaste($event)" (click)="!showCalendarIcon ? showComponent($event) : false" [value]="value" />
        <calendar *ngIf="showCalendar && validCharacters?.toLocaleLowerCase() === 'calendar'" #calendar [date]="calendarDate" [show]="showCalendar" (setDateEvent)="setDate($event)" (mouseover)="lockCalendar = true" (mouseout)="lockCalendar = false; text.focus()"></calendar>
    </div>
    <div *ngIf="multiLine" [ngStyle]="{ 'margin': margin ?? '0', 'border': 'unset', 'height': height ?? 'unset', 'width': width ?? 'unset' }">
        <textarea #text [ngStyle]="{ 'padding': padding ?? '5px', 'margin': '0', 'border': border ?? '1px solid rgb(200, 200, 200)', 'height':'100%', 'width': '100%' }" [class]="class" [style]="style" [placeholder]="hint" (keydown)="onKeyDown($event)" (focus)="text.select()" (blur)="onBlur($event)" (input)="onInput($event)" (cut)="onCut($event)" (paste)="onPaste($event)" (click)="!showCalendarIcon ? showComponent($event) : false" [value]="value"></textarea>
    </div>
</div>
