<div *ngIf="memos?.length > 0">
	<div class="header">Article 6 memos</div>
	<ul>
		<li *ngFor="let memo of memos">
			<span *ngIf="!memo.hasLink">{{memo.countryTarget}}</span>
			<a *ngIf="memo.hasLink" [href]="memo.link" target="_blank" rel="noopener">{{ memo.countryTarget }}</a>
			&nbsp;({{ memo.status }}<span *ngIf="memo.hasDate">&nbsp;{{ memo.date | date:'shortDate' }}</span>)
			<div *ngIf="memo.hasDescription" class="indent-descr">{{memo.description}}</div>
		</li>
	</ul>
</div>