import { Injectable } from "@angular/core";
import { Observable, from } from "rxjs";
import { HttpService } from '~/services/core/http.service';
import { IIssuanceCreditInfo, IProjectTypeCreditInfo, IRetirementCredit, IRetirementCreditFilter } from "./metaregistry-retired-credits.model";

@Injectable()
export class RetiredCreditsService {
    constructor(private _httpClient: HttpService) {
    }

    filterRetiredIssuances(start: Date, end: Date, selectedFilters: IRetirementCreditFilter[]): Observable<IIssuanceCreditInfo[]> {
        const url = `/RetirementCredits/credits/issued?start=${start.toLocaleDateString()}&end=${end.toLocaleDateString()}`;
        return from(this._httpClient.Post(url, selectedFilters));
    }

    public getRetiredCreditsByProjectType(start: Date, end: Date): Observable<IProjectTypeCreditInfo[]> {
        const url = `/RetirementCredits/credits/project-type?start=${start.toLocaleDateString()}&end=${end.toLocaleDateString()}`;

        return from(this._httpClient.Get(url));
    }
    public getRetiredIssuances(start?: Date, end?: Date): Observable<IIssuanceCreditInfo[]> {
        const url = `/RetirementCredits/credits/issued?start=${start?.toLocaleDateString()}&end=${end?.toLocaleDateString()}`;

        return from(this._httpClient.Get(url));
    }

    public getRegistries(): Observable<IRetirementCreditFilter[]> {
        const url = `/RetirementCredits/filters/registries`;

        return this.getFilters(url);
    }
    public getCountries(): Observable<IRetirementCreditFilter[]> {
        const url = `/RetirementCredits/filters/countries`;

        return this.getFilters(url);
    }
    public getRegions(): Observable<IRetirementCreditFilter[]> {
        const url = `/RetirementCredits/filters/regions`;

        return this.getFilters(url);
    }

    public listRetiredCredits(start?: Date, end?: Date): Observable<IRetirementCredit> {
        const url = `/RetirementCredits/credits?start=${start?.toLocaleDateString()}&end=${end?.toLocaleDateString()}`;

        return from(this._httpClient.Get(url));
    }

    public filterRetiredCredits(start: Date, end: Date, filters: IRetirementCreditFilter[]): Observable<IRetirementCredit> {
        const url = `/RetirementCredits/credits?start=${start.toLocaleDateString()}&end=${end.toLocaleDateString()}`;

        return from(this._httpClient.Post(url, filters));
    }

    public getOwnerFilters(take: number, term?: string, ): Observable<string[]> {
        let url: string;
        if (term) {
          url = `/RetirementCredits/filters/owners?term=${term}&take=${take}`;
        } else {
          url = `/RetirementCredits/filters/owners?take=${take}`;
        }

        return from(this._httpClient.Get(url));
    }

    public getCertificationFilters(): Observable<IRetirementCreditFilter[]> {
        const url = `/RetirementCredits/filters/certifications`;

        return this.getFilters(url);
    }
    public getMethodologyFilters(): Observable<IRetirementCreditFilter[]> {
        const url = `/RetirementCredits/filters/methodologies`;

        return this.getFilters(url);
    }
    public getProjectTypeFilters(): Observable<IRetirementCreditFilter[]> {
        const url = `/RetirementCredits/filters/project-types`;

        return this.getFilters(url);
    }
    public getProductTypeFilters(): Observable<IRetirementCreditFilter[]> {
        const url = `/RetirementCredits/filters/product-types`;

        return this.getFilters(url);
    }

    private getFilters(url): Observable<IRetirementCreditFilter[]> {
        console.log(url);
        return from(this._httpClient.Get(url));
    }
}
